<template>
<section class="event-speakers">

  <ioio-modal
    class="speaker-modal"
    :show.sync="isCreateSpeakerModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">
      <h3 class="speaker-modal__title">Add Speaker</h3>
    </template>

    <header class="speaker-modal__upload">
      <img
        v-if="imgSrc"
        :src="imgSrc"
        class="speaker-modal__avatar"
      />
      <div
        class="speaker-modal__upload-thumb"
        v-else
      >
        <ioio-icon
          icon="fas-user"
          class=""
        />
      </div>

      <div class="flex flex-col justify-center">
        <div class="speaker-modal__upload-disclamer">
          At least 256 x 256px PNG or JPEG file
        </div>
        <div>
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-8"
            @click.stop.prevent="openAssignImagesModal"
          >
            <span>{{ imgSrc ? 'Change Photo' : 'Add Photo' }}</span>
          </ioio-button>
        </div>
      </div>
    </header>


    <section class="speaker-modal__form">

        <ioio-field
          type="text"
          required
          topic="newSpeakerForm"
          size="medium"
          label="Name"
          placeholder="Name"
          :maximum=100
          v-model="newSpeakerData.name"
        ></ioio-field>


        <ioio-field
          type="email"
          required
          topic="newSpeakerForm"
          size="medium"
          label="Email"
          placeholder="Email"
          :maximum=64
          v-model="newSpeakerData.email"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Phone"
          placeholder="Phone"
          :maximum=64
          v-model="newSpeakerData.phone"
          class="col-span-2"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Company"
          placeholder="Company"
          :maximum=64
          v-model="newSpeakerData.meta.company"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Position"
          placeholder="Position"
          :maximum=64
          v-model="newSpeakerData.meta.companyPosition" class=""
        ></ioio-field>

        <ioio-field
          type="textarea"
          topic="newSpeakerForm"
          size="medium"
          label="Bio"
          placeholder="Bio"
          :maximum=500
          v-model="newSpeakerData.meta.bio" class="col-span-2"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Twitter"
          icon="fab-twitter"
          placeholder="Twitter"
          v-model="newSpeakerData.meta.twitterAccount"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Linkedin"
          icon="fab-linkedin-in"
          placeholder="Enter Linkedin URL"
          v-model="newSpeakerData.meta.linkedinUrl"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Website"
          icon="globe"
          placeholder="Enter Website URL"
          v-model="newSpeakerData.meta.websiteUrl"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="newSpeakerForm"
          size="medium"
          label="Facebook"
          icon="fab-facebook-f"
          placeholder="Enter Facebook URL"
          v-model="newSpeakerData.meta.facebookUrl"
        ></ioio-field>
    </section>

    <template slot="footer">

      <footer class="flex justify-end mt-4" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelCreateNewSpeaker">Cancel</ioio-button>

        <ioio-button
          topic="newSpeakerForm"
          @click.stop.prevent="createSpeaker">Add Speaker</ioio-button>
      </footer>
      <div v-else class="overflow-hidden mt1">
        <ioio-loader isShown></ioio-loader>
      </div>
    </template>
  </ioio-modal>

  <ioio-modal
    class="speaker-modal"
    :show.sync="isEditSpeakerModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">
      <h3 class="speaker-modal__title">Edit Speaker</h3>
    </template>

    <header class="speaker-modal__upload">
      <img
        v-if="imgSrc"
        :src="imgSrc"
        class="speaker-modal__avatar"
      />
      <div
        class="speaker-modal__upload-thumb"
        v-else
      >
        <ioio-icon icon="fas-user"/>
      </div>

      <div class="flex flex-col justify-center">
        <div class="speaker-modal__upload-disclamer">
          At least 256 x 256px PNG or JPEG file
        </div>
        <div>
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-8"
            @click.stop.prevent="openAssignImagesModal"
          >
            <span>{{ imgSrc ? 'Change Photo' : 'Add Photo' }}</span>
          </ioio-button>
        </div>
      </div>
    </header>


    <section class="speaker-modal__form">

        <ioio-field
          type="text"
          required
          topic="editSpeakerForm"
          size="medium"
          label="Name"
          placeholder="Name"
          v-model="editedSpeakerData.name"
        ></ioio-field>


        <ioio-field
          type="email"
          required
          topic="editSpeakerForm"
          size="medium"
          label="Email"
          placeholder="Email"
          v-model="editedSpeakerData.email"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Phone"
          placeholder="Phone"
          v-model="editedSpeakerData.phone"
          class="col-span-2"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Company"
          placeholder="Company"
          v-model="editedSpeakerData.meta.company"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Position"
          placeholder="Position"
          v-model="editedSpeakerData.meta.companyPosition" class=""
        ></ioio-field>

        <ioio-field
          type="textarea"
          topic="editSpeakerForm"
          size="medium"
          label="Bio"
          placeholder="Bio"
          v-model="editedSpeakerData.meta.bio" class="col-span-2"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Twitter"
          icon="fab-twitter"
          placeholder="Twitter"
          v-model="editedSpeakerData.meta.twitterAccount"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Linkedin"
          icon="fab-linkedin-in"
          placeholder="Enter Linkedin URL"
          v-model="editedSpeakerData.meta.linkedinUrl"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Website"
          icon="globe"
          placeholder="Enter Website URL"
          v-model="editedSpeakerData.meta.websiteUrl"
        ></ioio-field>

        <ioio-field
          type="text"
          topic="editSpeakerForm"
          size="medium"
          label="Facebook"
          icon="fab-facebook-f"
          placeholder="Enter Facebook URL"
          v-model="editedSpeakerData.meta.facebookUrl"
        ></ioio-field>
    </section>

    <template slot="footer">

      <footer class="flex justify-between mt-4" v-if="!isRequestPending">

        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="triggerDeleteSpeaker">Delete</ioio-button>
        <div>
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-4"
            @click.stop.prevent="cancelEditSpeaker">Cancel</ioio-button>

          <ioio-button
            topic="editSpeakerForm"
            @click.stop.prevent="editSpeaker">Save</ioio-button>
        </div>
      </footer>
      <div v-else class="overflow-hidden mt1">
        <ioio-loader isShown></ioio-loader>
      </div>
    </template>
  </ioio-modal>

  <assign-images-modal-component
    raiseFlagName="isAssignImagesOpened"
    :getAssignedImages="getAssignedImages"
    :initialImages="assignedImagesMap"
    singleChoise
    addLabel="Select Image"
  />

  <header class="event-speakers__header">

    <h1 class="event-speakers__header-title">Speakers</h1>

    <aside class="event-speakers__header-tools">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
      />

      <ioio-button
        type="primary"
        class="ml-2"
        iconLeft="plus"
        @click="openCreateModal()"
      >
        Add speaker
      </ioio-button>
    </aside>
  </header>

  <ioio-table class="event-speakers__table">
    <template slot="thead">
      <tr class="event-speakers__table-header">
        <th>
          Name
        </th>
        <th>
          Company
        </th>
        <th>
          Role
        </th>
        <th>
        </th>
      </tr>
    </template>
    <template
      slot="tbody"
      v-if="!isRequestPending && filteredEventSpeakers.length">
      <tr
        class="event-speakers__table-body"
        v-for="(speaker, i) in filteredEventSpeakers"
        :key="i"
      >
        <td class="flex items-center text-sm text-blue-grey-900">
          <img
            v-if="speaker.meta.avatar && speaker.meta.avatar.guid"
            :src="speaker.meta.avatar.src"
            class="event-speakers__avatar"
          />
          <div
            class="event-speakers__thumb"
            v-else
          >
            <ioio-icon icon="fas-user"/>
          </div>
          <div v-if="speaker.name.length < textWidth/10">{{ speaker.name }}</div>
          <ioio-tooltip v-else :text="speaker.name" initialPostion="top-right" class="w-7/12">
            <div class="truncate">{{ speaker.name }}</div>
          </ioio-tooltip>
        </td>
        <td class="flex items-center text-sm font-normal text-blue-grey-400">
          <div v-if="!speaker.meta.company || speaker.meta.company.length < textWidth*0.15" class="w-10/12">{{ speaker.meta.company || '--' }}</div>
          <ioio-tooltip v-else :text="speaker.meta.company || '--' " class="w-full">
            <div class="truncate w-10/12">{{ speaker.meta.company || '--' }}</div>
          </ioio-tooltip>
        </td>
        <td class="flex items-center text-sm font-normal text-blue-grey-400">
          <div v-if="!speaker.meta.companyPosition || speaker.meta.companyPosition < textWidth*0.15 " class="w-10/12">{{ speaker.meta.companyPosition || '--' }}</div>
          <ioio-tooltip v-else :text="speaker.meta.companyPosition || '--' " class="w-full">
            <div class="truncate w-10/12">{{ speaker.meta.companyPosition || '--' }}</div>
          </ioio-tooltip>
        </td>
        <td class="flex items-center justify-end">
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-2 whitespace-nowrap"
            iconLeft="copy"
            @click.stop.prevent="copyToClipboard('join-speaker-url-' + speaker.guid)"
          >
            Copy access link
          </ioio-button>
          <ioio-button
            type="secondary"
            variant="outline"
            class="whitespace-nowrap"
            @click="openEditSpeakerModal(speaker)"
          >
            Edit
          </ioio-button>
          <input
            class="copy-to-clipboard-input"
            type="text"
            :id="'join-speaker-url-' + speaker.guid"
            :value="getJoinLink(speaker)"
          >
        </td>
      </tr>

    </template>

    <template
      slot="tbody"
      v-else-if="!isRequestPending && !filteredEventSpeakers.length">
      <tr class="is-one-column">
        <td colspan="4" class="flex flex-col items-center">
          <img
            class="mb-5"
            src="~@assets/eventStyling/pages/events_creation/speaker.svg"
            alt=""
          />
          <p>You haven't added any speakers yet.</p>
          <ioio-button
            type="primary"
            variant="outline"
            class="mt-5"
            @click="openCreateModal()"
          >
            Add speaker
          </ioio-button>
        </td>
      </tr>

    </template>
    <template
      slot="tbody"
      v-else>
      <tr>
        <td colspan="4"><ioio-loader isShown /></td>
      </tr>

    </template>
  </ioio-table>

  <footer class="flex justify-center py1">
    <div v-if="!isRequestPending && filteredEventSpeakers.length" class="">

      <pagination-component
        :page-selected="eventSpeakersSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="eventSpeakersSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </div>
  </footer>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({

    isRequestPending: false,
    speakers: [],

    newSpeakerData: {
      name: '',
      email: '',
      role: 'talent',
      phone: '',
      meta: {
        bio: '',
        company: '',
        twitterAccount: '',
        linkedinUrl: '',
        websiteUrl: '',
        facebookUrl: '',
        avatar: {},
      }
    },

    isCreateSpeakerModalVisible: false,

    editedSpeakerData: {
      name: '',
      email: '',
      role: '',
      phone: '',
      meta: {
        bio: '',
        company: '',
        twitterAccount: '',
        linkedinUrl: '',
        websiteUrl: '',
        facebookUrl: '',
        avatar: {},
      }
    },

    isEditSpeakerModalVisible: false,


    assignedImagesGuids: [],
    assignedImagesMap: {}
  }),
  props: {

    eventDetails: Object
  },

  mounted() {

    window.a = this;

    this.copyElementInnerText = copyElementInnerText;
  },

  created() {

    const eventGuid = this.$route.query.id;

    this.triggerGetSpeakers(eventGuid);
  },

  beforeDestroy() {

    this.unstageFromFiltering('eventSpeakersLibrary');
  },

  methods: {

    copyToClipboard(elementId) {

      if (!this.eventDetails.cloudMixerBaseDomain) {

        this.$toasted.error('Setup your event experience before you can copy the access link.')
        return;
      }

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    getJoinLink(speaker) {

      return `${this.eventDetails.cloudMixerBaseDomain}/talent/index.html?eventId=${this.eventDetails.guid}&accessCode=${speaker.accessCode}&attendeeId=${speaker.guid}#/dressing-room`;
    },

    openAssignImagesModal() {

      this.setRedirectGuard({
        redirectMsg: 'Are you sure you want to leave this page?',
        redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
      });

      this.toggleAssignImagesModalFlag({

        division: 'isAssignImagesOpened',
        newVal: true
      });
    },

    getAssignedImages(images, imagesMap) {

      this.assignedImagesGuids = images;
      this.assignedImagesMap = imagesMap;

      this.toggleAssignImagesModalFlag({

        division: 'isAssignImagesOpened',
        newVal: false
      });
    },

    triggerDeleteSpeaker() {

      // NOTE: possibly add confirm

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const params = {

        eventGuid,
        attendeeGuid: this.editedSpeakerData.guid
      };

      this.deleteAttendee(params)
        .then(() => {

          this.$toasted.success(`Speaker ${this.$options.filters.truncate(this.editedSpeakerData.name, 64, "...")} was deleted successfully!`);

          this.cancelEditSpeaker();

          this.triggerGetSpeakers(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });
    },

    openEditSpeakerModal(speaker) {

      this.assignedImagesGuids = [];

      this.editedSpeakerData = {

        ...speaker,
        meta: {
          ...speaker.meta
        }
      };

      if (speaker.meta.avatar && speaker.meta.avatar.guid) {

        this.assignedImagesGuids = [ speaker.meta.avatar.guid ];

        this.assignedImagesMap = {

          [speaker.meta.avatar.guid]: {

            guid: speaker.meta.avatar.guid,
            cdnPath: speaker.meta.avatar.src
          }
        };
      }

      this.isEditSpeakerModalVisible = true;
    },

    cancelEditSpeaker() {

      this.setRedirectGuard(false);

      this.isEditSpeakerModalVisible = false;

      this.editedSpeakerData = {

        name: '',
        email: '',
        role: '',
        phone: '',
        meta: {
          bio: '',
          company: '',
          twitterAccount: '',
          linkedinUrl: '',
          websiteUrl: '',
          facebookUrl: '',
          avatar: {},
        }
      };
    },

    editSpeaker() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const {
        name,
        email,
        role,
        phone,
        meta,
        guid
      } = this.editedSpeakerData;

      const payload = {

        eventGuid,
        attendeeGuid: guid,
        data: {
          name,
          email,
          role,
          phone,
          meta: {
            ...meta,
            avatar: {
              src: this.imgSrc,
              guid: this.assignedImagesGuids[0]
            }
          }
        },
      };


      this.updateAttendee(payload)
        .then((resp) => {

          this.$toasted.success(`Speaker ${this.$options.filters.truncate(payload.data.name, 64, "...")} was successfully updated!`);

          this.cancelEditSpeaker();

          this.triggerGetSpeakers(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });

    },

    openCreateModal() {

      this.assignedImagesGuids = [];
      this.assignedImagesMap = {};

      this.isCreateSpeakerModalVisible = true;
    },

    cancelCreateNewSpeaker() {

      this.isCreateSpeakerModalVisible = false;

      this.newSpeakerData = {

        name: '',
        email: '',
        role: 'talent',
        phone: '',
        meta: {
          bio: '',
          company: '',
          twitterAccount: '',
          linkedinUrl: '',
          websiteUrl: '',
          facebookUrl: '',
          avatar: {},
        }
      };
    },

    createSpeaker() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const payload = {

        data: {
          ...this.newSpeakerData,
          meta: {
            ...this.newSpeakerData.meta,
            avatar: {
              src: this.imgSrc,
              guid: this.assignedImagesGuids[0]
            }
          }
        },
        eventGuid
      };


      this.addAttendee(payload)
        .then((resp) => {

          this.$toasted.success(`Speaker ${this.$options.filters.truncate(payload.data.name, 64, "...")} was successfully created!`);

          this.cancelCreateNewSpeaker();

          this.triggerGetSpeakers(eventGuid);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },



    triggerGetSpeakers(eventGuid) {

      this.isRequestPending = true;

      this.getAttendees({ eventGuid }).then(resp => {

        this.speakers = resp;

        this.stageForFiltering({
          items: this.speakers,
          division: 'eventSpeakersLibrary'
        });

        this.applyFiltering({

          filterType: 'eventSpeakersLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'eventSpeakersLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'eventSpeakersLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    ...mapMutations({
      toggleAssignImagesModalFlag: 'events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE',
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
    }),
    ...mapActions({

      getAttendees: "events/getAttendees",
      addAttendee: "events/addAttendee",
      updateAttendee: "events/updateAttendee",
      deleteAttendee: "events/deleteAttendee",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
    })
  },
  computed: {
    ...mapGetters({

      filteredEventSpeakers: "filterAndPaginate/filteredEventSpeakers",
      filteredItemsCount: "filterAndPaginate/filteredEventSpeakersItemsCount",
      eventSpeakersSearchOptions: "filterAndPaginate/eventSpeakersSearchOptions",
    }),

    textWidth () {
      return window.innerWidth / 8;
    },

    imgSrc() {

      if (this.assignedImagesMap[this.assignedImagesGuids[0]]) {

        return this.assignedImagesMap[this.assignedImagesGuids[0]].cdnPath;
      }

      return '';
    }
  },
}
</script>

<style lang="scss">

.event-speakers {
  @apply
  mx-auto
  max-w-7xl
  p-12;

  &__header {
    @apply
    flex
    items-center
    justify-between
    my-4;
  }

  &__header-title {
    @apply
    text-2xl;
  }

  &__header-tools {
    @apply flex;
  }

  &__table {
    @apply w-full overflow-x-auto;

    tr {
      @apply
      w-full
      grid
      grid-cols-4;

      &.is-one-column {
        @apply grid-cols-1;
      }

    }

    th, td {
      @apply
      text-base
      font-medium
      text-left
      text-blue-grey-400;
    }

    th {
      @apply text-blue-grey-900;
    }
  }

  &__avatar,
  &__thumb {
    @apply
    w-12
    min-w-12
    h-12
    mr-4
    object-cover
    overflow-hidden
    rounded-full
    border
    border-dark-blue-100;
  }
  &__thumb {
    @apply
    flex
    mr-4
    text-base
    items-center
    justify-center
    text-dark-blue-500
    bg-dark-blue-100;
  }
  .copy-to-clipboard-input {
    /**
    * Hide the visibility via z-index, since hiding it in
    * any other way will make the copy to clipboard not work.
    */
    position: fixed;
    z-index: -1;
  }
}

.speaker-modal {
  &__upload {
    @apply
    flex
    w-full
    py-5;
  }

  &__avatar,
  &__upload-thumb {
    @apply
    w-20
    h-20
    object-cover
    overflow-hidden
    rounded-full
    border
    border-blue-grey-100;
  }
  &__upload-thumb {
    @apply
    flex
    mr-7
    text-2xl
    items-center
    justify-center
    text-dark-blue-500
    bg-dark-blue-100;
  }

  &__upload-disclamer {
    @apply
    text-black-400
    mb-2;
  }
  &__form {
    @apply
    grid
    grid-cols-2
    gap-5;
  }
}

</style>
