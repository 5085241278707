<template>
<div class="download-preview-proxy-url-vod-component">
  <li class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer"
    v-if="stagedVod.status !== 'error'"
    @click="handleOnActionClick('download')">
    <ioio-icon icon="far-download" class="mr-2 h-3.5 w-3.5"/> {{ downloadLabel }}
  </li>
  <li class="p1 hover-red hover-bg-red+5 flex items-center size-2 weight+1 pointer"
    v-if="stagedVod.status === 'encode-later'"
    @click="handleOnActionClick('preview')">
    <ioio-icon icon="fas-eye" class="mr-2 h-3.5 w-3.5"/> {{ previewLabel }}
  </li>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    proxyUrl: '',
  }),
  props: {
    stagedVod: Object,
    onPreview: Function,
    previewLabel: {
      type: String,
      default: 'Preview Asset'
    },
    downloadLabel: {
      type: String,
      default: 'Download'
    }
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      makeGetVodSignedUrlRequest: "channelManager/makeGetVodSignedUrlRequest"
    }),

    handleOnActionClick(actionType) {

      if (actionType === 'preview') {

        const proxyUrl = this.stagedVod.sourceProxy &&
          this.stagedVod.sourceProxy.proxyUrl;

        this.onPreview(proxyUrl, this.stagedVod);

      } else {

        this.download(this.stagedVod.name)
      }
    },

    download(filename) {

      this.makeGetVodSignedUrlRequest(this.stagedVod.guid).then(response => {

        const signerUrl = response.downloadLink;

        const element = document.createElement('a');

        element.setAttribute('href', signerUrl);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    }

  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>



</style>
