<template>
<div class="mediatailor-settings-component">


  <mediatailor-delete-error-modal-component />

  <header class="flex justify-between border-bottom pl2 pb2 mb2">

    <h4 class="m0">Mediatailors</h4>
    <button-component
      v-if="!isAddMediatailorFormVisible"
      @click="() => openAddMediatailorForm()">
      Add New Mediatailor
    </button-component>

  </header>

  <section v-if="!isMediatailorDataUpdating">

    <save-new-mediatailor-component
      v-if="isAddMediatailorFormVisible"
      :onCloseAddMediatailorForm="closeAddMediatailorForm"
      :editedMediatailor="editedMediatailor"
      :onSaveMediatailor="updateMediatailorList"/>

    <section v-else-if="availableMediatailors.length">

      <div class="box bg-white rounded-3 mb2">
        <div class="table">
          <table>
            <thead>
              <tr>
                <th class="width+2">Name</th>
                <th class="width+1">Type</th>
                <th class="width+3">Ad Server</th>
                <th class="width+3">Master Endpoint</th>
                <th class="width+1"></th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(mediatailor, index) in availableMediatailors" :key="mediatailor.guid">
                <td class="">
                  <div class="size-3 truncate">{{ mediatailor.name }}</div>
                </td>
                <td class="">
                  <div class="size-2 ">{{ mediatailor.type }}</div>
                </td>
                <td class="">
                  <div class="size-2 truncate">{{ mediatailor.adServer }}</div>
                </td>
                <td class="">
                  <div class="size-2 truncate">{{ mediatailor.masterEndpoint }}</div>
                </td>
                <td>
                  <div class="relative">


                    <button-component
                      variant="minimal"
                      size="size-s"
                      class="enable-btn"
                      shape="circle"
                      @click.stop="() => actionsVisibleForGuid = mediatailor.guid"
                      >
                      <ioio-icon icon="fas-ellipsis" class="h-4"/>
                    </button-component>
                    <dropdown-component v-if="actionsVisibleForGuid === mediatailor.guid" align="align-right" :onClose="() => actionsVisibleForGuid = false">
                      <ul class="list-reset m0">
                        <li
                          @click="openAddMediatailorForm(mediatailor)"
                          class="p1 hover-blue hover-bg-blue+5 flex items-center size-2 weight+1 pointer">Edit
                        </li>
                        <li
                          @click="triggerDeleteMediatailor(mediatailor)"
                          class="p1 hover-blue hover-bg-blue+5 red flex items-center size-2 weight+1 pointer">Delete
                        </li>
                      </ul>
                    </dropdown-component>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <h3 v-else class="size-1">No mediatailors have been saved.</h3>
  </section>
  <logo-loader-component width="50px" height="50px" v-else class="mt3"/>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isMediatailorDataUpdating: false,

    isAddMediatailorFormVisible: false,

    actionsVisibleForGuid: false,
  }),
  props: {},

  created() {

    this.updateMediatailorList();
  },

  mounted() {
    window.m = this;
  },

  methods: {

    updateMediatailorList() {

      this.isMediatailorDataUpdating = true;

      this.getMediatailors()
        .finally(() => {

          this.isMediatailorDataUpdating = false;
        });
    },

    triggerDeleteMediatailor(mediatailor) {

      this.isMediatailorDataUpdating = true;

      this.actionsVisibleForGuid = false;

      this.deleteMediatailor({ guid: mediatailor.guid })
        .then(() => {

          this.updateMediatailorList();
        })
        .catch(errPayload => {

          const isUseChannels = errPayload.error.channels;

          this.$modal.show('mediatailor-delete-error', isUseChannels);
        })
        .finally(() => {

          this.isMediatailorDataUpdating = false;
        });
    },

    closeAddMediatailorForm() {

      this.isAddMediatailorFormVisible = false;
    },

    openAddMediatailorForm(mediatailor = {}) {

      console.log(mediatailor);

      this.editedMediatailor = {
        ...mediatailor
      };

      this.isAddMediatailorFormVisible = !this.isAddMediatailorFormVisible;
      this.actionsVisibleForGuid = false;

    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getMediatailors: "channelManager/makeGetMediatailorsRequest",
      deleteMediatailor: "channelManager/deleteMediatailor",
    })
  },
  computed: {
    ...mapGetters({

      availableMediatailors: "channelManager/mediatailors",
    })
  },
}
</script>

<style lang="scss">



.mediatailor-settings-component {

  .table, table {

    table-layout : fixed;
  }

  th, label {
    @apply
    font-medium;
  }
}
</style>
