<template>
<div class="library-tab-distributions-component">

  <h1 class="border-bottom pb2 mt1 mb2 size+1">Send for Distribution</h1>

  <vue-form v-if="!isRequestPending" :state="selectDistributorFormState" @submit.prevent class="overflow-y p2 flex items-start justify-center select-dist-form bg-blue+5 rounded-2">
    <validate class="mr2 width+3" tag="label">
      <span class="block mb1">Send for Distribution</span>

      <select class="select m0 ratio1-1" id="type" v-model="selectedDistributorGuid" name="selectedDistributorGuid" required>
        <option
        :key="item.guid"
        :value="item.guid"
        v-for="item in possibleDistributors"
        >{{ item.title }}</option>
      </select>
      <field-messages name="selectedDistributorGuid" show="$submitted || $dirty && $touched">
        <div slot="required">Distributor is a required field</div>
      </field-messages>
    </validate>

    <check-component
      type="checkbox"
      label="Publish"
      class="mr3 size-0 published-check"
      :val="isNewVodDistributionPublished"
      :on-change="toggleIsVodDistributionPublished" />

    <check-component
      v-if="isNewSyndicationDistributorSling"
      type="checkbox"
      label="Force Upload"
      class="mr3 size-0 force-upload-check"
      :val="isNewVodDistributionForceUploadChecked"
      :on-change="toggleIsVodDistributionForceUploadChecked" />

    <validate class="mr2 width+3" tag="label" v-if="isNewSyndicationDistributorSinclair">
      <span class="block mb1">Channel</span>

      <select class="select m0 ratio1-1" id="type" v-model="newVodDistributionChannelSelected" name="newVodDistributionChannelSelected">
        <option
        :key="ch.name"
        :value="ch.name"
        v-for="ch in possibleSyndicationChannels"
        >{{ ch.name }}</option>
      </select>
      <field-messages name="newVodDistributionChannelSelected" show="$submitted || $dirty && $touched">
      </field-messages>
    </validate>

    <button-component
      variant="primary"
      intent="success"
      size="size-m"
      style="margin-top: 29px"
      @click.stop.prevent="sendToDistribution"
    >Submit</button-component>
  </vue-form>

  <h1 class="border-bottom size-1 p2 mb2">Distributions</h1>

  <div class="table" v-if="!isRequestPending">

    <table>
      <thead>
        <tr>
          <th class="width+3">Distributor</th>
          <th class="width+4">Last Submission</th>
          <th class="width+3">Status</th>
        </tr>
      </thead>
      <tbody>


        <tr v-for="(submission, index) in parsedSubmissionsList" :key="submission.guid">

          <td>
            <span class="size-2">
              <img
                v-if="distributorMap[submission.distributorGuid].icon"
                :src="require(`@/assets/SVGs/${distributorMap[submission.distributorGuid].icon}.svg`)"
                height="30"
                width="90"
                class="mr-2"/>
              <h3 v-else>{{ distributorMap[submission.distributorGuid].name }}</h3>
            </span>
          </td>
          <td>
            <span class="size-2">{{
              moment.tz(submission.createdAt, localTimezone).format("MMM Do YYYY h:mma z Z") }}
            </span>
          </td>
          <td>
            <span class="size-2" :style="{
              cursor: (submission.status === 'error' && submission.error) ?
                'help': 'default'
              }"
              v-tooltip="{
                content: submission.error || '',
              }"
              >{{ submission.status }}
            </span>


          </td>

        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="overflow-hidden">
    <logo-loader-component width="40px" height="40px" class="mt3"/>
  </div>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { getLocalTimezone } from "@utils/helpers";

import { distributorMap } from "@utils/constants";

export default {
  data: () => ({

    selectDistributorFormState: {},
    possibleDistributors: [],

    possibleSyndicationChannels: [], // Only for Sinclair

    selectedDistributorGuid: '',
    isNewVodDistributionPublished: false,
    newVodDistributionChannelSelected: '',
    isNewVodDistributionForceUploadChecked: false,
    parsedSubmissionsList: [],

    isRequestPending: false,
    distributorMap
  }),
  created() {

    this.moment = moment;
    this.localTimezone = getLocalTimezone();

    this.updateDistributionListForAsset();

    this.getDistributions().then(list => {

      this.possibleDistributors = list.filter(d => d.enabled && d.guid != "xumo" );

      this.setPossibleSyndicationChannels(this.possibleDistributors);
    });
  },
  mounted() {

    window.a = this;
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getSyndicationAssetsForVod: "syndication/getSyndicationAssetsForVod",

      getDistributions: "syndication/getDistributions",
      sendAssetToDistribution: "syndication/sendAssetToDistribution",
    }),

    setPossibleSyndicationChannels(distributorsList) {

      // Only for Sinclair
      const sinclairDistributor =
        distributorsList.find(d => d.guid === 'sinclair');

      this.possibleSyndicationChannels = sinclairDistributor.sinclairSettings.channels;
    },

    toggleIsVodDistributionPublished() {

      this.isNewVodDistributionPublished =
        !this.isNewVodDistributionPublished;
    },

    toggleIsVodDistributionForceUploadChecked() {

      this.isNewVodDistributionForceUploadChecked =
        !this.isNewVodDistributionForceUploadChecked;
    },

    updateDistributionListForAsset() {

      this.isRequestPending = true;

      let parsedSubmissionsList = [];

      this.getSyndicationAssetsForVod({ guid: this.editedVod.guid })
        .then((distributorsSubmissionData) => {

          distributorsSubmissionData.forEach(distData => {

            const distSubmissions = [...distData.submissions];

            distSubmissions.forEach(s => {

              s.distributorGuid = distData.distributorGuid;
            })

            const joinedSubmissions = parsedSubmissionsList.concat(distSubmissions);

            parsedSubmissionsList = joinedSubmissions;
          });

          this.parsedSubmissionsList =
            parsedSubmissionsList.sort((a, b) => b.createdAt - a.createdAt);
        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },

    sendToDistribution() {

      this.selectDistributorFormState._submit();

      if (this.selectDistributorFormState.$invalid) {

        return;
      }

      this.isRequestPending = true;

      const payload = {

        distGuid: this.selectedDistributorGuid,
        srcGuid: this.editedVod.guid,

        data: {
          published: this.isNewVodDistributionPublished,
        }
      };

      this.isNewSyndicationDistributorSinclair && (
        payload.data.channel = this.newVodDistributionChannelSelected
      );

      this.isNewSyndicationDistributorSling && (
        payload.data.forceUpload = this.isNewVodDistributionForceUploadChecked
      );

      this.sendAssetToDistribution(payload).then(() => {

        this.$toasted.info('Video asset sent to distribution.');

        // Null the create form
        this.selectedDistributorGuid = '';
        this.isNewVodDistributionPublished = false;
        this.newVodDistributionChannelSelected = '';
        this.isNewVodDistributionForceUploadChecked = false;

      }).finally(() => {

        this.isRequestPending = false;
      });
    },
  },
  computed: {
    ...mapGetters({

      editedVod: 'channelManager/editedVod',
      msgBusMsgList: 'app/msgBusMsgList',
    }),

    isNewSyndicationDistributorSinclair() {

      return this.selectedDistributorGuid === 'sinclair';
    },

    isNewSyndicationDistributorSling() {

      return this.selectedDistributorGuid === 'sling';
    }
  },

  watch: {

    editedVod() {

      this.updateDistributionListForAsset();
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'source_syndication_job_finished') {

        if (newestMsg.data.errorMessage) {

          this.$toasted.error(newestMsg.data.errorMessage);

        } else {

          this.$toasted.success('Video asset syndication job finished successfully.');
        }

        this.updateDistributionListForAsset();
      }
    }
  }
}
</script>

<style lang="scss">



.library-tab-distributions-component {

  label, th {
    @apply font-medium;
  }

  .select-dist-form {

    border: 2px dashed var(--sceneBrColor);
  }

  .published-check.check-component, .force-upload-check.check-component {

    display: block;
    height: 65px;

    span {

      top: 38px;
      left: 36px;
    }
  }
}

</style>
