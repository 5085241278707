<template>
<div class="video-crop-component">
  <ioio-modal
    class=""
    :show.sync="isRedirectModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" v-if="clip.meta">
      <div class="pb-3">
        You will be redirected to the {{clip.meta.title}} preview {{ latestClipValues.length ? `and ${editedVod.meta.title} will not be clipped if you proceed.` : '.' }}
      </div>
    </template>

    <h3 class="">
      Do wish to continue?
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isRedirectModalOpened=false"
        >
          No
        </ioio-button>
        <ioio-button
          @click.stop.prevent="redirectToDetails"
        >
          Yes, continue
        </ioio-button>
      </div>
    </template>

  </ioio-modal>

  <ioio-modal
    size="large"
    :show.sync="isLegendModalOpened"
  >
    <template slot="header" >
      <div class="pb-3">
        Video clipper legend
      </div>
    </template>

    <div class="p-4 legend bg-black-300 rounded-md">

      <h3 class="m-0 mb-4">Create a new video asset by adding one or more ranges in the video. Ranges will be joined together and the new video will appear in your Library.</h3>

      <section class="flex flex-wrap">
        <div v-for="(action, name) in clipperActions"
          class="mt-4 w-1/3 flex items-center"
          :key="name"
        >
          <ioio-icon v-if="action.icon" :icon="action.icon" class="w-6 h-6"></ioio-icon>
          <p class="m-0" v-else>{{ action.text }}</p>
          <h6 class="m-0 ml-4">{{ action.legend }}</h6>
        </div>
      </section>

    </div>
  </ioio-modal>

  <section class="mx-52">
    <video-player-component
      shouldLoadSynchronously
      ref="clipperPreviewVid"
      :previewVod="editedVod"
      :thumbnail="editedVodSelectedThumb"
      :vodSettings="vodSettings"
    />
  </section>

  <section class="clip-timeline flex px-4 mt-6" @click="adjustPLayback" id="clipTimeline" ref="clipTimeline">
    <div class="clip-playhead" :style="{ left: `${currentPlayheadOffset}%` }">
      <div class="tooltip clipper-tooltip">
        <div class="tooltip-inner">{{currentCursorParsedTime}}</div>
      </div>
    </div>
  </section>

  <section class="pt-6 pb-2 slider-section px-4 mt-2">
    <veeno v-if="isClipperVisible"
      ref="clipperSlider"
      :tooltips="tipsOptsSafe"
      behaviour= 'tap-drag'
      :step="1"
      :pipsy = "{
        mode: 'range',
        density: 1
      }"
      :connect="connectionsSafe"
      :handles="handlesSafe"
      :range="range"
      @change="onSliderChange"
    />
  </section>

  <!-- Clipper Controls Bar Start -->
  <div class="clip-ctrs-container px-4">

    <section class="flex justify-between my-2">
      <ioio-button
        variant="text"
        rounded
        type="primary"
        class="mt-2 mr-2"
        @click="isLegendModalOpened = true"
      >
        <ioio-icon icon="circle-info" class="w-6 h-6"></ioio-icon>
      </ioio-button>

      <!-- First level controls -->
      <div>
        <ioio-tooltip v-for="action,i in clipperActions.slice(0,7)" :key="i" :text="action.legend">
          <ioio-button
            variant="text-boxed"
            type="secondary"
            class="mt-2"
            :class="action.fn === 'addRange' ? 'ml-6' : ''"
            @click="() => triggerAction(action.fn)"
          >
            <ioio-icon :icon="action.icon" class="w-6 h-6"></ioio-icon>
          </ioio-button>
        </ioio-tooltip>
        <ioio-tooltip text='Control time range'>
          <ioio-button
            variant="text-boxed"
            type="primary"
            class="mt-2 mr-2"
            @click="isControlRangeOpen = !isControlRangeOpen"
          >
            <ioio-icon :icon="isControlRangeOpen ? 'fa-chevrons-up' : 'fa-chevrons-down'" class="w-6 h-6"></ioio-icon>
          </ioio-button>
        </ioio-tooltip>
      </div>
      <button-component
        variant="primary"
        class="mt-1"
        size="size-m"
        @click="onClippingDone"
        >Next
      </button-component>

    </section>

    <section class="second-row flex justify-between" v-if="isControlRangeOpen">

      <aside class="flex">
        <!-- moveRangeStart-->
        <ioio-tooltip
          v-for="action,i in clipperActions.slice(8,14)"
          :key="i"
          :text="action.legend"
          initialPostion="top-right"
        >
          <ioio-button
            variant="text-boxed"
            type="secondary"
            class="mt-2"
            @click="() => triggerAction(action.fn, action.param)"
          >
            <p>{{action.text }}</p>
          </ioio-button>
        </ioio-tooltip>
      </aside>

      <aside class="flex">
        <!-- moveRangeEnd -->
        <ioio-tooltip
          v-for="action,i in clipperActions.slice(14,20)"
          :key="i"
          :text="action.legend"
          initialPostion="top-left"
        >
          <ioio-button
            variant="text-boxed"
            type="secondary"
            class="mt-2"
            @click="() => triggerAction(action.fn, action.param)"
          >
            <p>{{action.text}}</p>
          </ioio-button>
        </ioio-tooltip>
      </aside>

    </section>

  </div>
  <!-- Clipper Controls Bar End -->

  <section class="flex flex-col mt-2 border-t border-solid max-h-32" v-if="fromSelectedVodClips.length && !isClippingInProgress">
    <h3 class="m-0 mt-2">Clips</h3>

    <div
      class="fileitem flex mt-2 cursor-pointer"
      v-for="clip in fromSelectedVodClips"
      :key="clip.guid"
      @click="openClipForEdit(clip)"
    >
      <div
        v-if="clip.meta && clip.meta.imageUrl"
          class="rounded-sm relative"
          style="width: 96px; height: 54px"
          :style="{
            background: ` url('${
              clip.meta && clip.meta.imageUrl
            }?d=100x56&q=80&f=jpeg') no-repeat center center / cover`
          }"
        >
          <tag-component
            v-if="clip.meta && clip.meta.type === 'ad'"
            class="fileitem-type"
            :variant="clip.meta && clip.meta.type"
            size="size-s"
          >{{ clip.meta && clip.meta.type }}</tag-component>

          <tag-component
            class="fileitem-duration"
            variant="duration"
            size="size-s"
          >{{ parseDuration(clip.srcDuration) }}
          </tag-component>
        </div>

        <div
          v-else
          class="fileitem-image"
          style="width: 96px; height: 54px"
        >
          <ioio-icon icon="fas fa-video" class=""/>
        </div>

      <div class="fileitem-info flex flex-col flex-auto ml-4">
        <h4 class="fileitem-title text-xs truncate pr-8">
          {{ clip.meta && clip.meta.title | truncate(64, "...") }}
        </h4>
        <div class="flex items-center">
          <tag-component
            v-if="clip.meta && clip.meta.group"
            class="fileitem-group mr-2"
          >{{ clip.meta.group || "No group" }}</tag-component>
          <tag-component v-if="clip.meta && clip.meta.genre" class="fileitem-genre">{{
            clip.meta.genre
          }}</tag-component>
        </div>

        <div v-if="clip.status !== 'complete'">
          <tag-component variant="error">{{ clip.status}}</tag-component>
        </div>
      </div>
    </div>
  </section>
  <div class="relative min-h-28" v-else-if="isClippingInProgress">
    <ioio-loader isShown />
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    name: 'VideoCrop',

    clipperActions: [
      {
        fn: 'selectPrevRange',
        icon: 'fas-backward-fast',
        legend: 'Select previous range'
      },
      {
        fn: 'setRangeStartAt',
        icon: 'fas-forward',
        legend: 'Set range start at playhead'
      },
      {
        fn: 'playSelectedRange',
        icon: 'fas-play',
        legend: 'Play selected range'
      },
      {
        fn: 'setRangeEndAt',
        icon: 'fas-stop',
        legend: 'Set range end at playhead'
      },
      {
        fn: 'selectNextRange',
        icon: 'fas-forward-fast',
        legend: 'Select next range'
      },
      {
        fn: 'addRange',
        icon: 'circle-plus',
        legend: 'Add range at playhead'
      },
      {
        fn: 'deleteRange',
        icon: 'fas-trash-can',
        legend: 'Delete selected range'
      },
      {
        icon: 'fa-chevrons-down',
        legend: 'Control time range'
      },
      {
        fn: 'moveRangeStartBack',
        text: '-10m',
        legend: 'Move range start Back',
        param: 600
      },

      {
        fn: 'moveRangeStartForward',
        text: '+10m',
        legend: 'Move range start Forward',
        param: 600
      },

      {
        fn: 'moveRangeStartBack',
        text: '-1m',
        legend: 'Move range start Back',
        param: 60
      },
      {
        fn: 'moveRangeStartForward',
        text: '+1m',
        legend: 'Move range start Forward',
        param: 60
      },
      {
        fn: 'moveRangeStartBack',
        text: '-1s',
        legend: 'Move range start Back',
        param: 1
      },
      {
        fn: 'moveRangeStartForward',
        text: '+1s',
        legend: 'Move range start Forward',
        param: 1
      },

      {
        fn: 'moveRangeEndBack',
        text: '-10m',
        legend: 'Move range end Back',
        param: 600
      },

      {
        fn: 'moveRangeEndForward',
        text: '+10m',
        legend: 'Move range end Forward',
        param: 600
      },

      {
        fn: 'moveRangeEndBack',
        text: '-1m',
        legend: 'Move range end Back',
        param: 60
      },

      {
        fn: 'moveRangeEndForward',
        text: '+1m',
        legend: 'Move range end Forward',
        param: 60
      },

      {
        fn: 'moveRangeEndBack',
        text: '-1s',
        legend: 'Move range end Back',
        param: 1
      },

      {
        fn: 'moveRangeEndForward',
        text: '+1s',
        legend: 'Move range end Forward',
        param: 1
      },
    ],

    isControlRangeOpen: false,
    currentPlayheadOffset: 0,
    currentCursorParsedTime: "00:00:00",
    clipTimeline: null,

    /***** Clipper dynamic options *****/

    // after the initial dynamic options are set,
    // use isClipperVisible to show the clipper with the proper settings
    isClipperVisible: false,

    range: {
      'min': 0,
      'max': 10 // based on videoLength
    },
    handles: [],
    connections: [], // handles count + 1

    /***** END Clipper dynamic options *****/

    /***** Clipper formating options *****/
    tipsOpts: {
      // 'to' the formatted value. Receives a number.
      to: function (value) {

        return getHHMMSSFromMS(value * 1000, 'fullHour');
      }
    },

    /***** Clipper formating options END *****/

    /***** Clipper service values *****/
    latestClipValues: [], // to be updated every time onSliderChange is called
    latestUsedHandleIndex: null, // to be updated every time onSliderChange is called
    latestUsedRangeIndex: null, // to be updated every time onSliderChange is called
    latestCalcPlayheadSecond: null, // use to adjust the playhead

    /***** END Clipper service values *****/

    isClippingInProgress: false,
    isLegendModalOpened: false,
    openMetaData: false,
    fromSelectedVodClips: [],
    isRedirectModalOpened: false,
    clip: {}

  }),
  props: {
    metaData: {
      type: Object|Boolean
    }
  },

  mounted() {

    window.s = this;

    this.setupVideoAndSlider();

    this.clipTimeline = this.$refs.clipTimeline;

    this.getSelectedVodClips();
  },

  methods: {

    getSelectedVodClips() {

      this.isClippingInProgress = true;

      const from = 0;
      const size = 10;

      const searchOptions = {

        type: {
         "resource": "source"
        },

        options: {
          from,
          size,
          query:  {
            "term": {
              "parentGuid.keyword": this.editedGuid
            }
          },
          sort: [
            {
              createdAt: {
                order: "desc"
              }
            }
          ]
        }
      };

      this.getVodElastic(searchOptions)
        .then((response) => {

          this.fromSelectedVodClips = response.hits.hits.map(el => el = el._source);
        })
        .finally(() => {

          this.isClippingInProgress = false;
        });

    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms, 'fullHour');
    },

    clearClipperState() {

      this.latestClipValues = [];
      this.latestUsedHandleIndex = null;
      this.latestUsedRangeIndex = null;
      this.latestCalcPlayheadSecond = null;
      this.currentPlayheadOffset = 0;
      this.currentCursorParsedTime = "00:00:00";
      this.range= {
        'min': 0,
        'max': 10 // based on videoLength
      },
      this.handles= [];
      this.setupVideoAndSlider();
    },


    /** Clipper actions Start **/
    triggerAction(action, param) {

      this[action] && this[action](param);
    },

    addRange() {
      // calc if the range can fit (if the currentSecond is not within a range)
      // if YES, calc where the new range should go in

      const updatedHandles = [...this.latestClipValues];

      const currentSecond = Math.round(this.$refs.clipperPreviewVid.$refs.videoPlayer.currentTime);

      let canRangeBeAdded = false;
      let newHandleIndex = null;

      const valuesLength = this.latestClipValues.length;

      // Range will fit at the start
      if (this.latestClipValues[0] === undefined || currentSecond < this.latestClipValues[0]) {

        canRangeBeAdded = true;

        updatedHandles.unshift(currentSecond, currentSecond);
        newHandleIndex = 0;

      } else if (this.latestClipValues[valuesLength - 1] === undefined ||
        currentSecond > this.latestClipValues[valuesLength - 1]) {

        // Range will fit at the end.
        canRangeBeAdded = true;

        updatedHandles.push(currentSecond, currentSecond);
        newHandleIndex = updatedHandles.length - 2;

      } else {

        // If range doesn't fit the above, check in between spaces
        for (let i = 1; i < valuesLength; i += 2) {

          if (currentSecond >= this.latestClipValues[i] &&
              currentSecond <= this.latestClipValues[i + 1]) {

            // Range will fit in between other ranges. The splice in index is i+1
            canRangeBeAdded = true;
            newHandleIndex = i + 1;

            updatedHandles.splice(newHandleIndex, 0, currentSecond, currentSecond);

            break;
          }
        }
      }

      if (!canRangeBeAdded) {

        this.$toasted.error('A range can not be added on top of an existing range.');
        return;
      }

      this.updateHandlesAndHelperProps(updatedHandles, newHandleIndex);

      this.setupSliderOptions(false, updatedHandles);
    },

    deleteRange() {

      // null relevant values to their initial states for the component;
      // remove the range from handles and such
      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to delete it.');
        return;
      }

      this.latestClipValues.splice(this.latestUsedRangeIndex * 2, 2);

      this.latestUsedHandleIndex = null;
      this.latestUsedRangeIndex = null;

      this.latestCalcPlayheadSecond = null;

      let sliderUpdateValues = this.latestClipValues;

      if (!this.latestClipValues.length) {

        // The inial span of the slider will be the full length of the video
        // NOTE: It was not successful to only show a blank slider
        // and add the first range later.
        sliderUpdateValues = [0, Math.floor(this.editedVod.srcDuration / 1000)];
      }

      this.setupSliderOptions(false, sliderUpdateValues);
    },

    playSelectedRange() {

      this.$refs.clipperPreviewVid.$refs.videoPlayer.pause();

      this.setupCustomSeekbar(false);

      this.$refs.clipperPreviewVid.$refs.videoPlayer.currentTime = this.latestCalcPlayheadSecond;
      this.$refs.clipperPreviewVid.$refs.videoPlayer.play();

      this.setupCustomSeekbar();
    },

    selectPrevRange() {

      const prevHandleIndex = this.latestUsedRangeIndex * 2 - 2;

      if (prevHandleIndex < 0) {

        this.$toasted.error('There is no previous range');
        return;
      }

      this.updateHandlesAndHelperProps(this.latestClipValues, prevHandleIndex);
    },

    selectNextRange() {

      const nextHandleIndex = this.latestUsedRangeIndex * 2 + 2;

      if (nextHandleIndex > this.latestClipValues.length - 1) {

        this.$toasted.error('There is no next range');
        return;
      }

      this.updateHandlesAndHelperProps(this.latestClipValues, nextHandleIndex);
    },


    // direction: [back/forward]
    _moveRangeStart(seconds, direction) {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to move.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2;

      const rangeSecondToMove = Math.round(this.latestClipValues[absoluteHandleIndex]);

      let boundingSecond;

      if (direction === 'back') {

        const prevHandleIndex = absoluteHandleIndex - 1;

        if (prevHandleIndex < 0) {

          boundingSecond = 0;

        } else {

          boundingSecond = this.latestClipValues[prevHandleIndex];
        }

        const newRangeSecond = rangeSecondToMove - seconds;

        // check if it can be done without overlapping
        if (newRangeSecond >= boundingSecond) {


          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }

      if (direction === 'forward') {

        const nextHandleIndex = absoluteHandleIndex + 1;

        if (nextHandleIndex > this.latestClipValues.length - 1) {

          boundingSecond = Math.floor(this.editedVod.srcDuration / 1000);

        } else {

          boundingSecond = this.latestClipValues[nextHandleIndex];
        }

        const newRangeSecond = rangeSecondToMove + seconds;

        // check if it can be done without overlapping
        if (newRangeSecond <= boundingSecond) {

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }
    },

    // direction: [back/forward]
    _moveRangeEnd(seconds, direction) {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to move.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2 + 1;

      const rangeSecondToMove = Math.round(this.latestClipValues[absoluteHandleIndex]);

      let boundingSecond;

      if (direction === 'back') {

        const prevHandleIndex = absoluteHandleIndex - 1;

        if (prevHandleIndex < 0) {

          boundingSecond = 0;

        } else {

          boundingSecond = this.latestClipValues[prevHandleIndex];
        }

        const newRangeSecond = rangeSecondToMove - seconds;

        // check if it can be done without overlapping
        if (newRangeSecond >= boundingSecond) {

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }

      if (direction === 'forward') {

        const nextHandleIndex = absoluteHandleIndex + 1;

        if (nextHandleIndex > this.latestClipValues.length - 1) {

          boundingSecond = Math.floor(this.editedVod.srcDuration / 1000);

        } else {

          boundingSecond = this.latestClipValues[nextHandleIndex];
        }

        const newRangeSecond = rangeSecondToMove + seconds;

        // check if it can be done without overlapping
        if (newRangeSecond <= boundingSecond) {

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }
    },

    moveRangeStartBack(seconds) {

      this._moveRangeStart(seconds, 'back');
    },

    moveRangeStartForward(seconds) {

      this._moveRangeStart(seconds, 'forward');
    },

    moveRangeEndBack(seconds) {

      this._moveRangeEnd(seconds, 'back');
    },

    moveRangeEndForward(seconds) {

      this._moveRangeEnd(seconds, 'forward');
    },

    setRangeStartAt() {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to set.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2;

      let boundingSecondStart;
      let boundingSecondEnd = Math.round(this.latestClipValues[absoluteHandleIndex + 1]);

      const vid = this.$refs.clipperPreviewVid.$refs.videoPlayer;
      const currentPlayheadPos = Math.floor(vid.currentTime);


      const prevHandleIndex = absoluteHandleIndex - 1;

      if (prevHandleIndex < 0) {

        boundingSecondStart = 0;

      } else {

        boundingSecondStart = this.latestClipValues[prevHandleIndex];
      }

      if (currentPlayheadPos <= boundingSecondEnd && currentPlayheadPos >= boundingSecondStart) {

        const updatedClipValues = [...this.latestClipValues];

        updatedClipValues.splice(absoluteHandleIndex, 1, currentPlayheadPos);

        this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

        this.setupSliderOptions(false, updatedClipValues);

      } else {

        this.$toasted.error('Range can not be moved this way.');
      }
    },

    setRangeEndAt() {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to set.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2 + 1;

      let boundingSecondStart = Math.round(this.latestClipValues[absoluteHandleIndex - 1]);
      let boundingSecondEnd;

      const vid = this.$refs.clipperPreviewVid.$refs.videoPlayer;
      const currentPlayheadPos = Math.floor(vid.currentTime);

      const nextHandleIndex = absoluteHandleIndex + 1;

      if (nextHandleIndex > this.latestClipValues.length - 1) {

        boundingSecondEnd = Math.floor(this.editedVod.srcDuration / 1000);

      } else {

        boundingSecondEnd = this.latestClipValues[nextHandleIndex];
      }

      if (currentPlayheadPos <= boundingSecondEnd && currentPlayheadPos >= boundingSecondStart) {

        const updatedClipValues = [...this.latestClipValues];

        updatedClipValues.splice(absoluteHandleIndex, 1, currentPlayheadPos);

        this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

        this.setupSliderOptions(false, updatedClipValues);

      } else {

        this.$toasted.error('Range can not be moved this way.');
      }
    },


    /** Clipper actions END **/



    setupCustomSeekbar(shouldCancel) {

      const vid = this.$refs.clipperPreviewVid.$refs.videoPlayer;

      if (shouldCancel) {

        vid.ontimeupdate = null;
      }

      const updateInterval = 1;

      let prevVal = 0;

      if (vid.ontimeupdate) return;

      vid.ontimeupdate = () => {

        const percentage = ( vid.currentTime / vid.duration ) * 100;

        prevVal += updateInterval;

        this.currentPlayheadOffset = percentage;

        this.currentCursorParsedTime =
          this.parseDuration(vid.currentTime * 1000) == 0 ? "00:00:00" : this.parseDuration(vid.currentTime * 1000);
      };
    },

    adjustPLayback(e) {

      if (e.target.id !== 'clipTimeline') {

        return;
      }

      const vid = this.$refs.clipperPreviewVid.$refs.videoPlayer;

      const wasVidPlaying = !!(vid.currentTime > 0 &&
                              !vid.paused &&
                              !vid.ended &&
                              vid.readyState > 2);

      vid.pause();

      const newDesiredPlayPosPercentage = 100 / (this.$refs.clipTimeline.offsetWidth / e.offsetX);

      const newDesiredPlayPosMs = this.editedVod.srcDuration / 100 * newDesiredPlayPosPercentage;

      vid.currentTime = newDesiredPlayPosMs / 1000;

      if (wasVidPlaying) {

        vid.play();
      }

      this.currentPlayheadOffset = newDesiredPlayPosPercentage;
    },

    onClippingDone() {

      if (!this.latestClipValues.length) {

        this.$toasted.error('Please select a range to be cut.');

        return;
      }

      this.openMetaData = true;
      this.$emit('open')
    },

    uploadCropVod() {

      const data = this.metaData;

      const clips = [];

      for (let i = 0; i < this.latestClipValues.length; i += 2) {

        const from = Math.round(this.latestClipValues[i]);
        const to = Math.round(this.latestClipValues[i + 1]);

        if (from === to) {

          continue;
        }

        clips.push({

          from,
          to
        });
      }

      const payload = {
        vodGuid: this.editedVod.guid,
        data: {
          clips,
          metaGuid: data.guid
        }
      };

      this.isClippingInProgress = true;
      this.$emit('inProgress', true);

      this.clipVod(payload)
        .then(() => {

          this.$toasted.success('Video was clipped successfully.');

          this.openMetaData = false;

          this.setRedirectGuard(false);
        })
        .finally(() => {

          this.clearClipperState();
          this.isClippingInProgress = false;
          this.$emit('inProgress', false);
        });

    },

    setActiveRangeClass() {

      const allConnectedElms = document.getElementsByClassName('noUi-connect');

      //update the active range's class PoC
      const affectedRangeEl = allConnectedElms[this.latestUsedRangeIndex];

      for (let el of allConnectedElms) {

        el.classList.remove('active');
      }

      affectedRangeEl.classList.add('active');
    },

    onSliderChange(props) {

      const { positions, unencoded, handle } = props;

      this.updateHandlesAndHelperProps(unencoded, handle);

      this.setPlayheadAtHandle(handle);
    },

    setPlayheadAtHandle(handle) {

      const newSecond = this.latestClipValues[handle];

      const vid = this.$refs.clipperPreviewVid.$refs.videoPlayer;

      const wasVidPlaying = !!(vid.currentTime > 0 &&
                              !vid.paused &&
                              !vid.ended &&
                              vid.readyState > 2);

      this.$refs.clipperPreviewVid.$refs.videoPlayer.pause();

      this.$refs.clipperPreviewVid.$refs.videoPlayer.currentTime = newSecond;

      if (wasVidPlaying) {

        vid.play();
      }
    },

    updateHandlesAndHelperProps(newValues, latestHandle) {

      this.latestClipValues = [...newValues];
      this.latestUsedHandleIndex = latestHandle;
      this.latestUsedRangeIndex = Math.floor(latestHandle / 2); // 1 range for every 2 handles

      this.latestCalcPlayheadSecond = this.latestClipValues[this.latestUsedRangeIndex * 2];

      setTimeout(() => {

        this.setActiveRangeClass();
      }, 100);
    },

    setupSliderOptions(range, handles) {

      // This method will result in a brief UI flash, while the slider is hidden
      // and then shown again with the updated values. Maybe a proper update can
      // be accomplished using the buid in NoUI method set(), but at the time of
      // initial implementation it was NOT successful, hence this approach was used

      this.isClipperVisible = false;

      setTimeout(() => {

        range && (this.range = {
          'min': 0,
          'max': Math.floor(this.editedVod.srcDuration / 1000) // based on videoLength
        });

        if (handles) {

          const connections = [];

          handles.forEach((h, i) => {

            if (i % 2 === 0) {

              connections.push(false);

            } else {

              connections.push(true);
            }
          });

          connections.push(false);

          this.handles = [...handles];
          this.connections = connections; // handles count + 1
        }

        this.isClipperVisible = true;
      }, 0)
    },

    setupVideoAndSlider() {

      // The inial span of the slider will be the full length of the video
      // NOTE: It was not successful to only show a blank slider
      // and add the first range later.
      this.setupSliderOptions(true, [0, Math.floor(this.editedVod.srcDuration / 1000)]);

      this.setupCustomSeekbar();
    },

    openClipForEdit(vod) {

      if (vod.status === "error") {

        this.$toasted.error('The details of video with status `Error` can not be displayed.');

        return;
      }

      this.clip = vod;

      this.isRedirectModalOpened = true;
    },

    redirectToDetails() {

      if (this.isActiveConfirmGuardSet) {

        this.setRedirectGuard(false);

      };

      this.isRedirectModalOpened = false;

        setTimeout(() => {

          this.$emit('close', this.clip);
      });


    },

    ...mapMutations({
      updateEditedVod: "library/EDIT_VOD_DATA",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",

    }),
    ...mapActions({
      clipVod: "channelManager/clipVod",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
      getVodElastic: "channelManager/getVodElastic",
    })
  },
  computed: {
    ...mapGetters({
      editedVod: 'library/editedVod',
      editedGuid: 'library/editedVodGuid',
      vodSettings: 'channelManager/vodSettings',
      msgBusMsgList: 'app/msgBusMsgList',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    handlesSafe() {

      // NoUI slider throws an error if an empty handles array is passed => pass null
      return this.handles.length ? this.handles : null;
    },
    connectionsSafe() {

      // NoUI slider throws an error if an empty handles array is passed => pass null
      return this.connections.length ? this.connections : null;
    },

    tipsOptsSafe() {

      const tipsArr = this.handles.map(h => this.tipsOpts);

      return tipsArr;
    },

    editedVodSelectedThumb() {

      return this.editedVod.meta.imageUrl;
    }

  },

  watch: {

    editedGuid() {

      this.setupVideoAndSlider();

      this.clearClipperState();

      this.getSelectedVodClips();
    },

    metaData() {

      if (this.metaData) {

        this.uploadCropVod();
      }
    },

    latestClipValues() {

      if (this.latestClipValues.length) {

        this.setRedirectGuard({
          redirectMsg: 'Your video will not be clipped if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    },
    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === "vod_status_change" && newestMsg.data.parentGuid === this.editedGuid) {

        this.getSelectedVodClips();
      }
    }
  }

}
</script>

<style lang="scss">
.video-crop-component{
  position: relative;

   /* Slider overwrite styles */
  .noUi-connect {

    @apply bg-black-400;

    &.active {

      @apply bg-yellow-300;
    }
  }

  .noUi-horizontal .noUi-handle {

    right: -6px !important;
    width: 12px;

    &:before {

      left: 3px;
    }

    &:after {

      left: 6px;
    }
  }

  .noUi-handle, .noUi-base, .noUi-connects {

    cursor: pointer;
  }
  .noUi-horizontal .noUi-tooltip {

    @apply bg-black-400;

    bottom: 30px;
    padding: 1px 5px;
    line-height: 15px;
    font-size: 12px;
    color: white;
    border: none;
  }

  .noUi-pips.noUi-pips-horizontal {

    top: -55px;
  }

  .noUi-marker.noUi-marker-horizontal.noUi-marker-large {

    top: 0;
  }

  .noUi-value.noUi-value-horizontal.noUi-value-large {

    display: none;
    top: -35px;
  }

  /* END Slider overwrite styles */

  .clip-timeline {

    position: relative;
    height: 30px;
    width: 100%;

    z-index: 1;
    cursor: pointer;
  }

  .clip-playhead {

    width: 2px;
    height: 100%;
    background: red;
    position: relative;
    left: 0;

  }

  .clip-ctrs-container {

    position: relative;
  }

  .ds-btn.is-medium{
    &.legend-btn {

      position: absolute;
      right: 0.5rem;
      min-width: 1.5rem;
    }
  }

  .ds-tooltip__el{
    z-index:10;
  }
  .slider-section{
    height: 60px;
  }
  .fileitem {
    display: flex;
    margin-bottom: 2px;
    border-radius: 0px;
    padding: 0.5rem;
    position: relative;

    &:hover {
      @apply
        bg-black-50;
    }
    .fileitem-title {
      word-wrap: break-word;
      margin: 0;
      margin-bottom: 0.25rem;
    }
    .fileitem-info {
        width: 100%;
        display: flex;
    }
    .fileitem-type {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
    }
    .fileitem-duration {
      position: absolute;
      bottom: 0;
    }

    .fileitem-group,
    .fileitem-genre {

      display: inline-flex;
    }
    .fileitem-image{
      @apply
      border
      border-black-200
      rounded-sm
      flex
      items-center
      justify-center;
    }
  }

}
.clipper-tooltip {

  position: absolute;
  will-change: transform;
  top: 13px;
  left: -28px;
  .tooltip-inner {
    line-height: 15px;
    font-size: 12px;
    padding: 1px 5px;
    border-radius: 2px;
    background: red;
    color: white;
  }

  .tooltip-arrow {

    display: none;
  }
}

</style>
