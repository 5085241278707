/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.555 11.168l-15-10A1 1 0 004 2v20a1 1 0 001.555.832l15-10a1.001 1.001 0 000-1.664z" _fill="#002c65"/>'
  }
})
