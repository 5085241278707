/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'set-end': {
    width: 17,
    height: 10,
    viewBox: '0 0 17 10',
    data: '<path pid="0" _stroke="#4F4F4F" d="M16.5 6v3M17 9.5H0"/><rect pid="1" x="6" width="6" height="6" rx=".5" _fill="#4F4F4F"/>'
  }
})
