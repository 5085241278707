<template>
<div class="as">
  <div class="as__title">
    Recent activity
    <!-- <ioio-button
      type="minimal"
      class="as__title-button"
    >
      See all
    </ioio-button> -->
  </div>
  <div class="as__list">
    <div
      class="as__item"
      v-for="activity in activityStreamItems"
      :key="activity.guid"
      @click="navigate(getRoute(activity))"
      :class="{ 'cursor-pointer': getRoute(activity) }"
    >

      <div
        class="as__avatar"
        :style="{ backgroundColor: getRandomColor() }"
      >
        {{ getNameInitials(activity.actor.userData.name) }}
      </div>

      <div class="as__activity">
        <div class="as__activity-text">
          <span>{{ activity.actor.userData.name }}</span>

          <p v-html="DOMPurify.sanitize(getActivityInnerHtml(activity))"></p>
        </div>
        <div class="as__activity-date">
          {{ moment(activity.createdAt).format('MM-DD-YYYY, h:mm a') }}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";
import * as DOMPurify from 'dompurify';

import { getNameInitials, getRandomColor } from '@utils/helpers';

export default {
  data: () => ({

    moment,
    DOMPurify,

    getNameInitials,
    getRandomColor,

    activityStreamItems: [],


    activityTitleConfig: {

      'caption': {
        create: (activity) => `created CC for <span>${activity.target.additionalData.sourceName}</span> VOD`,
        redirectRoute: (activity) => `/vod/library?id=${activity.target.additionalData.sourceGuid}`
      },


      'caption-entity': {

        update: (activity) => `edited CC for <span>${activity.target.additionalData.sourceName}</span> VOD`,
        redirectRoute: (activity) => `/vod/library?id=${activity.target.additionalData.sourceGuid}`
      },


      'caption-translation': {

        create: (activity) => `created CC translation for <span>${activity.target.additionalData.sourceName}</span> VOD`,
        redirectRoute: (activity) => `/vod/library?id=${activity.target.additionalData.sourceGuid}`
      },


      'channel': {

        create: (activity) => `created <span>${activity.requestData.name}</span> channel`,
        update: (activity) => `edited <span>${activity.requestData.name}</span> channel`,

        delete: (activity) => `deleted ${activity.target.additionalData.channelName} channel`,

        redirectRoute: (activity) => {

          if (activity.activity === 'delete') {

           return false;
          }

          return `/vod?channel-guid=${activity.target.guid}`;
        }
      },


      'playlist': {

        create: (activity) => `created <span>${activity.requestData.name}</span> playlist`,
        update: (activity) => `edited <span>${activity.requestData.name}</span> playlist`,

        delete: (activity) => `deleted <span>${activity.target.additionalData.playlistName}</span> playlist`,

        redirectRoute: (activity) => {

          if (activity.activity === 'delete') {

            return false;
          }

          return `/vod/library/playlists?id=${activity.target.guid}`;
        }
      },


      'schedules': {

        create: (activity) => `edited the program in <span>${activity.target.additionalData.channelName}</span> channel`,

        redirectRoute: (activity) => `/vod?channel-guid=${activity.target.additionalData.channelGuid}`
      },


      'setting': {

        update: (activity) => `updated settings`,
      },


      'settings': {

        update: (activity) => `updated settings`,
      },


      'source': {

        create: (activity) => `uploaded <span>${activity.requestData.meta.title}</span> video`,

        redirectRoute: (activity) => `/vod/library?id=${activity.target.guid}`
      },


      'source-archive': {

        create: (activity) => `archived <span>${activity.target.additionalData && activity.target.additionalData.sourceName || ''}</span> video`,
      },


      'source-meta': {

        update: (activity) => `edited <span>${activity.requestData.title}</span> video data`,

        redirectRoute: (activity) => `/vod/library?id=${activity.target.guid}`
      },


      'image': {

        create: (activity) => `uploaded <span>${activity.requestData.meta.title}</span> image`,

        redirectRoute: (activity) => `/images-library?id=${activity.target.guid}`
      },


      'attendee': {

        create: (activity) => `added <span>${activity.requestData.name}</span> to <span>${activity.target.additionalData && activity.target.additionalData.eventName || ''}</span> event`,

        update: (activity) => `updated <span>${activity.requestData.name}</span> 's details in <span>${activity.target.additionalData && activity.target.additionalData.eventName || ''}</span> event`,

        delete: (activity) => `removed <span>${activity.target.additionalData && activity.target.additionalData.attendeeName || 'attendee'}</span> from <span>${activity.target.additionalData && activity.target.additionalData.eventName || ''}</span> event`,

        redirectRoute: (activity) => {

          if (activity.activity === 'delete') {

            return false;
          }

          const sectionId =
            activity.requestData.role === 'talent' ?
            'speakers' : 'attendees';

          return `/events-list/details?id=${activity.pathParameters.guid}&sectionId=${sectionId}`;
        }
      },


      'attendees-import': {

        create: (activity) => `imported attendees in <span>${activity.target.additionalData.eventName}</span> event`,

        redirectRoute: (activity) => `/events-list/details?id=${activity.pathParameters.guid}&sectionId=attendees`
      },


      'event': {

        create: (activity) => `created <span>${activity.requestData.name}</span> event`,

        update: (activity) => `updated <span>${activity.requestData.name}</span> event`,

        delete: (activity) => `removed <span>${activity.target.additionalData.eventName}</span> event`,

        redirectRoute: (activity) => {

          if (activity.activity === 'delete') {

            return false;
          }

          return `/events-list/details?id=${activity.target.guid}&sectionId=overview`;
        }
      },


      'notification-subscription': {

        create: (activity) => `subscribed for notifications`
      },


      'user': {

        create: (activity) => `created user ${activity.requestData.name}`
      },
    }
  }),
  props: {},
  created() {
    window.qq = this;

    this.loadActivityStreamData();
  },
  methods: {

    getActivityInnerHtml(activity) {

      const selectedConfig = this.activityTitleConfig[activity.target.type];
      const activityDivision = activity.activity;

      return (
        selectedConfig &&
        selectedConfig[activityDivision](activity) ||
        'No mapping available'
      );
    },

    getRoute(activity) {

      const targetType = this.activityTitleConfig[activity.target.type];

      return targetType.redirectRoute && targetType.redirectRoute(activity);
    },

    navigate(route) {

      if (!route) {

        return;
      }

      this.$pushRoute(route);
    },

    loadActivityStreamData() {

      const query = {

        "query": {
          "bool": {
            "must": {

              "match": {
                "actor.type": "user"
              },
            },

            "filter": [
              {
                "bool": {
                  "should": [

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "caption" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ],
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "caption-entity" }
                          },
                          {
                            "match": { "activity": "update" }
                          }
                        ],
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "caption-translation" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ],
                      }
                    },

                    // All channel activities should be mapped for now
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "channel" }
                        },
                      }
                    },

                    // All playlist activities should be mapped for now
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "playlist" }
                        },
                      }
                    },


                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "schedule" }
                          },
                          {
                            "match": { "activity": "delete" }
                          }
                        ],
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "schedules" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ],
                      }
                    },

                    // All `setting` activities should be mapped for now
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "setting" }
                        },
                      }
                    },

                    // All `settings` activities should be mapped for now
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "settings" }
                        },
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "source" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ],
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "source-archive" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ],
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "source-meta" }
                          },
                          {
                            "match": { "activity": "update" }
                          }
                        ],
                      }
                    },


                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "image" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ]
                      }
                    },

                    // Should match every `attendee` type
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "attendee" }
                        }
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "attendees-import" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ]
                      }
                    },

                    // Should match every `event` type
                    {
                      "bool": {
                        "must": {
                          "match": { "target.type.keyword": "event" }
                        }
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "notification-subscription" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ]
                      }
                    },

                    {
                      "bool": {
                        "must": [
                          {
                            "match": { "target.type.keyword": "user" }
                          },
                          {
                            "match": { "activity": "create" }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        },
        "sort": [
          {
            "createdAt": {
              "order": "desc"
            }
          }
        ]
      };

      this.getActivityStream({ orgGuid: this.organizationSelected.guid, query }).then(resp => {

        const activities = resp.hits.hits.map(i => {

          return i._source;
        });

        // this.activityStreamItems = activities.splice(0, 6); // NOTE only first 6 are shown in the design

        this.activityStreamItems = activities;
      });
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getActivityStream: 'app/getActivityStream'
    })
  },
  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected",
    }),
  },
}
</script>

<style lang="scss">
.as {
  &__title {
    @apply
    flex
    justify-between
    items-center
    text-lg
    mt-8
    mb-7;

    &-button.ds-btn.is-minimal {
      @apply text-blue-600;
    }
  }

  &__list {
    @apply flex flex-col;
  }

  &__item {
    @apply mb-4 flex border-b border-black-100;

    &:last-child {
      @apply border-none;
    }
  }

  &__avatar {
    @apply
    w-10
    h-10
    mr-4
    flex
    flex-shrink-0
    justify-center
    items-center
    text-lg
    text-white
    rounded-full
    bg-red-500;
  }

  &__activity {
    @apply
    flex
    flex-col
    text-lg;
  }

  &__activity-text {
    @apply
    mb-2
    font-normal
    text-sm;

    span {
      @apply font-medium;
    }
  }

  &__activity-date {
    @apply text-xs text-black-400 mb-2;
  }
}

</style>
