<template>
<section class="delete-confirm-modal-component">

  <div
    v-if="dialogNewButton"
    @click.stop.prevent="openDeleteModal"
  >
    <!-- The slot will allow for a custom open btn to be added to the component
     (just as markup, the actual opening is triggered from above - toggleArchiveModalOpened)
    -->
    <slot>

      <ioio-button
        v-if="isDialogOpenBtnVisible && !dialogOpenBtnIcon"
        type="danger"
        variant="tonal"
        :size="dialogOpenBtnSize"
      >
        {{ dialogOpenBtnLabel }}
      </ioio-button>

      <ioio-button
        v-if="isDialogOpenBtnVisible && dialogOpenBtnIcon"
        type="minimal"
        :size="dialogOpenBtnSize"
      >
        <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>
      </ioio-button>
    </slot>
  </div>

   <!-- Old Buttons NEED to remove when change all buttons-->
  <div v-else>
    <button-component
      v-if="isDialogOpenBtnVisible && !dialogOpenBtnIcon"
      variant="primary"
      intent="danger"
      :size="dialogOpenBtnSize"
      class="dialog-open-btn"
      :class="dialogOpenBtnClass"
      @click.stop.prevent="openDeleteModal"
    >{{ dialogOpenBtnLabel }}

    </button-component>

    <button-component
      v-if="isDialogOpenBtnVisible && dialogOpenBtnIcon"
      variant="minimal"
      intent="danger"
      :size="dialogOpenBtnSize"
      class="dialog-open-btn"
      :class="dialogOpenBtnClass"
      @click.stop.prevent="openDeleteModal"
    >
      <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>

    </button-component>
  </div>

  <ioio-modal
    class=""
    :show.sync="isModalOpened"
    size="medium"
    noScroll
    headerClasses=""
    footerClasses=""
  >
    <template slot="header">
      {{ delConfirmLabel }}
    </template>

    <h2 class="mt-5" v-if="delConfirmSecondaryLabel">{{ delConfirmSecondaryLabel }}</h2>



    <template  slot="footer">
      <logo-loader-component width="50px" height="50px" v-if="isLoadingIndicatorVisible"/>

      <footer
      v-else
      class="flex justify-end mt-4"
      >
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          size="small"
          @click.stop.prevent="closeDeleteModal">Cancel</ioio-button>

        <ioio-button
          type="danger"
          variant="tonal"
          size="small"
          @click.stop.prevent="onDeleteConfirm">{{ delConfirmBtnLabel }}</ioio-button>

      </footer>
    </template>

  </ioio-modal>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isModalOpened:false,
  }),
  props: {

    //Need to remove this props when remove old buttons
    dialogNewButton: {

      type: Boolean
    },

    // NOTE: Needed when there are more the one modals on a page!
    // If there are more than one modals with the same name on a given page,
    // they will all open up at once, leading to unexpected behavior.
    dialogId: {
      // type: String,
      default: '1',
    },

    dialogOpenBtnIcon: {

      type: Boolean
    },

    dialogOpenBtnLabel: {
      type: String,
      default: 'Delete',
    },

    dialogOpenBtnSize: {
      type: String,
      default: 'size-xs',
    },

    dialogWidth: {
      type: String,
      default: '500px',
    },

    dialogHeight: {
      type: String,
      default: 'auto',
    },

    dialogOpenBtnClass: {
      type: String
    },

    isDialogOpenBtnVisible: {
      type: Boolean
    },

    delConfirmLabel: {

      type: String,
      default: 'Are you sure you want to delete?'
    },

    delConfirmSecondaryLabel: {

      type: String
    },

    delConfirmBtnLabel: {
      type: String,
      default: 'Delete',
    },

    isDelConfirmBtnVisible: {
      type: Boolean
    },

    onDeleteConfirm: {
      type: Function
    },

    onDeleteReject: {
      type: Function
    },

    isLoadingIndicatorVisible: {

      type: Boolean,
      default: false
    }
  },
  methods: {

    openDeleteModal() {

      this.isModalOpened = true;
    },

    closeDeleteModal() {

      this.onDeleteReject && this.onDeleteReject();

      this.isModalOpened = false;
    },

    confirmDelete() {

      this.onDeleteConfirm && this.onDeleteConfirm();

      this.isModalOpened = false;
    }
  }
}
</script>

<style lang="scss">



.delete-confirm-modal-component {

  /* Needed so that it aligns with adjascent elements outside the component */
  .dialog-open-btn {

    float: left;
  }
  .red {
    color: #e9222b;
  }
}

</style>
