import * as DOMPurify from 'dompurify';

// NOTE: ToastSanitizeWrapper will modify the original vue-toasted
// plugin methods in order to sanitize them and prevent SSX attacks.
// Continue using the vue-toasted in a normal way

export default {

  install(Vue) {

    const originalInfo = Vue.prototype.$toasted.info.bind(Vue.prototype);
    const originalError = Vue.prototype.$toasted.error.bind(Vue.prototype);
    const originalSuccess = Vue.prototype.$toasted.success.bind(Vue.prototype);
    const originalShow = Vue.prototype.$toasted.show.bind(Vue.prototype);
    const originalRegister = Vue.prototype.$toasted.register.bind(Vue.prototype);

    Vue.prototype.$toasted = {

      ...Vue.prototype.$toasted,

      // safe methods bellow
      info(message, options) {

        const cleanMsg = DOMPurify.sanitize(message);

        originalInfo(cleanMsg, options);
      },

      error: (message, options) => {

        const cleanMsg = DOMPurify.sanitize(message);

        originalError(cleanMsg, options);
      },

      success: (message, options) => {

        const cleanMsg = DOMPurify.sanitize(message);

        originalSuccess(cleanMsg, options);
      },

      show: (message, options) => {

        const cleanMsg = DOMPurify.sanitize(message);

        originalShow(cleanMsg, options);
      },

      register: (name, message, options) => {

        const cleanMsg = DOMPurify.sanitize(message);

        originalRegister(name, cleanMsg, options);
      }
    };
  }
};
