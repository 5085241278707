<template>
  <div class="header-component flex flex-auto">

    <div class="flex items-center" v-if="sidebarMenuEnabled">

      <button-component variant="default" shape="circle" @click="toggleSidebarOpened">

        <ioio-icon icon="far-bars" class="w-3.5 h-3.5"/>
      </button-component>
      <div v-if="selectedChannelName.length < 39" class="mx1 w-auto max-w-xs" >{{ selectedChannelName }}</div>
      <ioio-tooltip v-else class="z-5" :text="selectedChannelName" initialPostion="bottom-right" >
        <div class="mx1 truncate w-auto max-w-xs">{{ selectedChannelName }}</div>
      </ioio-tooltip>
      <channel-settings-component class="mr1" />

      <tag-component :variant="selectedChannelStatus">{{ selectedChannelStatus }}</tag-component>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
  }),
  props: {
    sidebarMenuEnabled: Boolean
  },

  created() {

  },

  methods: {
    ...mapMutations({
      toggleSidebarOpened: "app/TOGGLE_SIDEBAR_OPENED",
    }),
    ...mapActions({
    }),

  },
  computed: {
    ...mapGetters({
      channelsList: "channelManager/channelsList",
      channelsMeta: "channelManager/channelsMeta",
    }),

    selectedChannelName() {

      return this.channelsMeta.selected && this.channelsMeta.selected.name || '';
    },

    selectedChannelStatus() {
      const isActive = this.channelsMeta.selected.active;

      return isActive === 1 ? "active" : isActive === 0 ? "inactive" : "";
    }
  }
};
</script>

<style lang="scss" scoped>

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";

/* header styles */
.header-component {
  @include overwrite-styleberry-styles-htags-font-size;
  @include overwrite-styleberry-styles-htags-margin;
  @include overwrite-styleberry-styles-htags-font-weight;
  height: 3rem;
  color: var(--windowColor);
  /* z-index: 2; */
  .z-5 {
    z-index:5;
  }
}
</style>
