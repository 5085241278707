export const AUTH = {

  url: 'https://uni18hhlzb.execute-api.eu-west-1.amazonaws.com/prod',

  // NOTE: this is the stage url and is left, so that one can easily
  // switch to it for dev purposes
  // url: 'https://avi42mk1j3.execute-api.us-west-2.amazonaws.com/stg'
};

export const SOCKET = {

  url: 'wss://xvuq4ytnsg.execute-api.eu-west-1.amazonaws.com/prod',

  // NOTE: this is the stage url and is left, so that one can easily
  // switch to it for dev purposes
  // url: 'wss://209j9huh44.execute-api.us-west-2.amazonaws.com/stg'
};
