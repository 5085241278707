<template>
  <div class="library-nav-component flex-none">

    <button-component variant="default" shape="circle" class="nav-open-btn" @click="toggleNavOpened">
      <ioio-icon icon="far-bars" class="w-3.5 h-3.5"/>
    </button-component>

    <nav class="flex flex-column p1 border-right" :class="{closed: !isNavOpened}">
      <span
        v-for="item,i in navItemsConfig"
        :key="i"
        v-if="$auth(item.permission)"
        class="nav-item link-reset pointer p1 pl2 size-1 rounded-3 flex items-center mb-025"
        :class="
          item.redirectUrl === activeNavPath ? 'box bg-white blue-3' : 'gray-1'
        "
        @click="navigate(item.redirectUrl)"
        v-tooltip="item.label"
      >
        <svg-icon
          class="mr1"
          :class="[item.name === 'ingest' ? 'upload-icon' : 'svg-icon']"
          :name="item.icon"
        ></svg-icon
        ><span class="nav-item-label">{{ item.label }}</span>
      </span>
    </nav>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  data: () => ({

    activeNavPath: '',
    navItemsConfig: [{
      redirectUrl: '/vod/library',
      name: 'library',
      icon: 'folder-play',
      label: 'Video Assets'
    }, {
      redirectUrl: '/vod/library/playlists',
      name: 'playlists',
      icon: 'playlist',
      label: 'Playlists'
    }, {
      redirectUrl: '/vod/library/workflows',
      name: 'workflows',
      icon: 'node',
      label: 'Workflow Archives',
      permission: 'WORKFLOW'
    }, {
      redirectUrl: '/vod/library/syndication',
      name: 'syndication',
      icon: 'call_split',
      label: 'Syndication',
      permission: 'syndication_lists_list'
    }, {
      redirectUrl: '/images-library',
      name: 'images',
      icon: 'image',
      label: 'Images',
      permission: 'images_list'
    }],

    isNavOpened: false
  }),

  created() {
    this.activeNavPath = this.$route.path;

    if (this.availableDeployments.some(deployment => deployment.type === 'ingest')) {

      this.navItemsConfig.push({
        redirectUrl: '/vod/library/ingest',
        name: 'ingest',
        icon: 'cloud_upload',
        label: 'Ingest',
        permission: ['sources_list','sources_ingest']
      });
    }
  },
   computed: {
    ...mapGetters({
      availableDeployments: "app/availableDeployments"
    })
  },
  methods: {
    navigate(navItemLink) {
      // Navigate to the requested state
      this.$pushRoute(navItemLink);
    },

    toggleNavOpened() {

      this.isNavOpened = !this.isNavOpened;
    }
  }
};
</script>

<style lang="scss">


.library-nav-component {

  height: 100%;
  width: 64px;
  position: relative;

  nav {

    position: absolute;
    z-index: 1;
    top: 0;
    width: 200px;
    height: 100%;
    padding-top: 60px;
    background: var(--sceneBgColor);
    box-shadow: 0 2px 8px rgba(0,0,0,.125);

    &.closed {

      width: 64px;

      .nav-item-label {

        display: none;
      }

      .nav-item {

        margin-top: 0;
        padding: 4px;

        .svg-icon {
          width: 20px;
          height: 20px;
          margin: 0 auto;
        }
        .upload-icon {
          width: 36px;
          height: 36px;
          margin: 0 auto;
        }
        svg {
          max-height: none;
        }
      }
    }

    .svg-icon {
      width: 16px;
      height: 16px;
      transition: all 0.15s ease-out;
    }

    .upload-icon {
      width: 30px;
      height: 30px;
      transition: all 0.15s ease-out;
      margin-left: -0.6rem;
      margin-right: 0.3rem;
    }
  }

  .nav-open-btn.btn {

    position: relative;
    z-index: 2;
    top: 12px;
    left: 18px;
  }

  .nav-item {

    height: 38px;
    margin-bottom: 0.25rem;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;

    &.active {
      background: var(--selectedBgColor);
      color: var(--selectedColor);
    }
    &:hover:not(.active) {
      background: var(--color-blue-gray-50);
    }
  }
}
</style>
