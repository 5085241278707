<template>
<ul class="multiple-check-component option-container list-reset flex">
  <li class="option mr1"
    v-for="(option, index) in options" @click="() => toggleOption(index)"
    :class="{ 'active bg-blue+1 white': option.value, 'bg-gray+4': !option.value, 'pointer': !isStatic }"
    >{{ isBasic ? option : option.label }}</li>
</ul>
</template>

<script>

export default {
  data: () => ({

  }),
  props: {
    options: Array,
    isStatic: Boolean,
    isBasic: Boolean,
    onUpdate: Function
  },

  mounted() {
    this.m = this;
  },
  methods: {

    toggleOption(index){

      if (this.isStatic) {

        return;
      }

      const optionsCopy = this.options.slice();

      const updatedOption = {

        ...this.options[index],
        value: !this.options[index].value
      };

      optionsCopy.splice(index, 1, {
        ...updatedOption
      });

      this.onUpdate(optionsCopy);
    }
  },
}
</script>

<style lang="scss" scoped>

.option {

  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
}

</style>
