/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stack': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M12 0l12 7-12 7L0 7z"/><path pid="1" data-color="color-2" d="M12 21.315l-9.699-5.657L0 17l12 7 12-7-2.301-1.342z"/><path pid="2" data-color="color-2" d="M12 16.315l-9.699-5.657L0 12l12 7 12-7-2.301-1.342z"/></g>'
  }
})
