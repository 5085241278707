/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-dots': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#444"><circle pid="0" cx="12" cy="12" r="3"/><circle pid="1" cx="3" cy="12" r="3"/><circle pid="2" cx="21" cy="12" r="3"/></g>'
  }
})
