/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slice': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M10.465 9.121l-9.172 9.172a1 1 0 00.818 1.701l9-1A1 1 0 0012 18v-3.586l1.879-1.879-3.414-3.414z"/><path pid="1" d="M22.702 1.921C21.217.623 18.75.835 17.356 2.23l-5.477 5.477 4.707 4.707a.997.997 0 001.414 0l4.776-4.776c.653-.653 1.124-1.493 1.221-2.411a3.822 3.822 0 00-1.295-3.306z"/></g>'
  }
})
