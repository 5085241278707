<template>

  <section class="deployment-process-component mb1">
    Total selected: {{ deployments.length }}

    <section class="deploy-form">
      <header class="flex justify-between mt2 mb1">
        <h2 class="size+1 mb2 mt2">Deploy</h2>

        <button-component variant="primary" intent="warning" size="size-xs mt2" @click.stop.prevent="clearGitlabCredentials" v-if="isAuthSuccessful">Clear Gitlab Credentials</button-component>
      </header>

      <vue-form :state="authFormState" @submit.prevent v-show="!isAuthSuccessful" class="flex">
        <validate class="m0 mr2 ratio4-6" tag="label">
          <span class="mb1 block">Token</span>
          <input
              class="m0 input"
              type="password"
              placeholder="Token"
              required
              name="token"
              v-model="token"/>
          <field-messages name="token" show="$submitted || $dirty && $touched">
            <div slot="required">Token is a required field</div>
          </field-messages>
        </validate>

        <button-component variant="primary" intent="success" size="size-m ratio2-6" style="margin-top: 28px" @click.stop.prevent="authenticateToGitlab">Authenticate</button-component>
      </vue-form>

      <vue-form :state="deployFormState" @submit.prevent v-show="isAuthSuccessful">
        <section class="flex justify-between">
          <div class="form-group ratio3-10">
            <label>
              Deploy Source Type
              <span>*</span>
            </label>

            <div class="flex">
              <div class="mr2">
                <label>
                  <input type="radio" name="status" v-model="deploySourceType" id="deploySourceType" value="branch">
                  Branch
                </label>
              </div>

              <div>
                <label>
                  <input type="radio" name="status" v-model="deploySourceType" id="isInactive" value="tag">
                  Tag
                </label>
              </div>
            </div>
          </div>

          <validate class=" ratio4-10 mr3" v-if="deploySourceType === 'branch'">
            
            <span class="">Branch <span class="text-danger">*</span></span>
            <ioio-dropdown 
              style="max-width: 235px;"                     
              v-model="selectedBranchForDeploy"
              :items="branches"
              :multiple="true"
              :multiselect="false"
              placeholder="Select one"      
              titleProp="name"
              valueProp="name" 
              required
              name="selectedBranchForDeploy"            
            />       
            
            <field-messages name="selectedBranchForDeploy" show="$submitted || $dirty && $touched">
              <div slot="required">Branch is a required field</div>
            </field-messages>
          </validate>

          <validate class=" ratio4-10 mr3" v-if="deploySourceType === 'tag'">
            <span class="">Tag <span class="text-danger">*</span></span>

            <ioio-dropdown 
              style="max-width: 235px;"                     
              v-model="selectedTagForDeploy"
              :items="tags"
              :multiple="true"
              :multiselect="false"
              placeholder="Select one"      
              titleProp="name"
              valueProp="name" 
              required
              name="selectedTagForDeploy"            
            />            

            <field-messages name="selectedTagForDeploy" show="$submitted || $dirty && $touched">
              <div slot="required">Tag is a required field</div>
            </field-messages>
          </validate>

          <validate class=" ratio4-10 mr3">
            <span class="">Scripts Branch <span class="text-danger">*</span></span>

            <ioio-dropdown 
              style="max-width: 235px;"                     
              v-model="selectedRefBranchForDeploy"
              :items="deploymentScriptsBranches"
              :multiple="true"
              :multiselect="false"
              placeholder="Select one"      
              titleProp="name"
              valueProp="name" 
              required
              name="selectedRefBranchForDeploy"            
            />            

            <field-messages name="selectedRefBranchForDeploy" show="$submitted || $dirty && $touched">
              <div slot="required">Ref Branch is a required field</div>
            </field-messages>
          </validate>

          <validate class=" ratio4-10" tag="label">
            <span class="">Trigger Token<span class="text-danger">*</span></span>

            <select
                style="height: 43px;"
                v-model="triggerToken"
                required
                name="selectedTriggerToken"
            >
              <option
                  v-for="trigger in triggers"
                  :key="trigger.token"
                  :value="trigger.token"
              >{{ trigger.token | truncate(4, "*******") }}</option>
            </select>

            <field-messages name="selectedTriggerToken" show="$submitted || $dirty && $touched">
              <div slot="required">Trigger Token is a required field</div>
            </field-messages>
          </validate>

        </section>
        <footer class="flex justify-end" v-if="!isDeployRequestPending">
          <button-component variant="primary" intent="success" size="size-m" class="ratio1-4 mt1" @click.stop.prevent="triggerDeploy">Deploy</button-component>
        </footer>

        <div v-else class="overflow-hidden">
          <logo-loader-component width="32px" height="38px" class=""/>
        </div>
      </vue-form>
    </section>
  </section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: ''
    },
    deployments: {
      type: Array,
      required: false,
      default: []
    },
  },
  data: () => ({
    // NOTE: leave hardcoded for now
    baseUrl: 'https://gitlab.next-stream.com/api/v4',
    projectId: 24,

    token: '',
    isAuthSuccessful: false,

    branches: [],
    deploymentScriptsBranches: [],

    tags: [],
    triggers: [],
    triggerToken: '',

    deploySourceType: 'branch',

    authFormState: {},
    deployFormState: {},

    selectedBranchForDeploy: {},
    selectedTagForDeploy: {},

    selectedRefBranchForDeploy: {
      name: 'master'
    },

    activeJobs: [],
    selectedPipeDetails: {},

    isDeployRequestPending: false
  }),

  created() {
    this.token = this.gitlabTokenInStore || '';

    if (this.gitlabTokenInStore) {
      this.authenticateToGitlab();
    }
  },

  mounted() {
    window.d = this;
  },

  methods: {
    deploySingleDeployment(deployment) {
      const { token, baseUrl, projectId } = this;

      const selectedVersion = this.deploySourceType === 'branch' ? this.selectedBranchForDeploy : this.selectedTagForDeploy;

      const payload = {
        requestData: {
          token,
          baseUrl
        },

        data: {
          projectId,
          deployOpts: {

            ref: this.selectedRefBranchForDeploy.name,

            token: this.triggerToken,
            variables: {
              COMMAND: deployment.commands.deploy.replace('CODE_VERSION', selectedVersion.name),
              PROJECT: deployment.type,
              deployment: deployment.guid
            }
          }
        }
      };

      this.isDeployRequestPending = true;

      this.executeDeployScript(payload)
          .then(() => {
            this.$toasted.success(`The deployment of "${ this.$options.filters.truncate(deployment.organizationName, 64, "...")}" "${this.$options.filters.truncate(deployment.name, 64, "...")}"  was triggered successfully.`);
          })
          .finally(() => {
            this.isDeployRequestPending = false;
          });
    },

    updateGitLabData() {
      const { token, baseUrl, projectId } = this;

      const forDeploymentProjectId = this.gitlabProjectIdMap[this.type];

      const perProjectParams = {

        requestData: {

          token,
          baseUrl
        },

        data: {
          projectId: forDeploymentProjectId
        }
      };

      const deploymentScriptsProjectParams = {

        requestData: {

          token,
          baseUrl
        },

        data: {
          projectId: this.projectId
        }
      };

      const triggerParams = {
        ...perProjectParams,
        data: {
          projectId
        }
      };

      const promises = [

        this.getGitlabBranches(perProjectParams),

        this.getGitlabBranches(deploymentScriptsProjectParams),

        this.getGitlabTags(perProjectParams),

        this.getGitlabTriggers(triggerParams)
      ];

      Promise.all(promises)
          .then(responses => {

            this.branches = responses[0];
            this.deploymentScriptsBranches = responses[1];
            this.tags = responses[2];

            // filter only valid tokens (applicable to the current auth token)
            this.triggers = responses[3].filter(trigger => trigger.token.length > 5);

            this.triggerToken = this.triggers[0] && this.triggers[0].token || ''; // preselect the first available
          });

    },

    authenticateToGitlab() {
      const { token } = this;

      if (!this.gitlabTokenInStore) {
        this.authFormState._submit();
        this.authFormState._validate();

        if (this.authFormState.$invalid) {
          return;
        }

        if (token) {
          this.setGitlabTokenInStore(token);
        }
      }

      this.isAuthSuccessful = true;

      this.updateGitLabData();
    },

    clearGitlabCredentials() {
      this.isAuthSuccessful = false;
      this.token = '';
      this.authFormState._reset();
      this.deployFormState._reset();

      this.setGitlabTokenInStore(null);
    },

    triggerDeploy() {
      this.deployFormState._submit();
      this.deployFormState._validate();

      if (this.deployFormState.$invalid) return;

      if (!this.deployments.length) {
        this.$toasted.error('Select at least one deployment');
        return;
      }

      const selectedVersion = this.deploySourceType === 'branch' ? this.selectedBranchForDeploy : this.selectedTagForDeploy;

      if (!selectedVersion.name) {
        this.$toasted.error('Please select "Tag" or "Branch" first');
        return;
      }

      let txt = `You are about to deploy ${this.deploySourceType} "${selectedVersion.name}" to ${this.deployments.length} deployments... Are you sure about that?`;
      if (confirm(txt) === true) {
        this.deployments.forEach(deployment => {
          this.deploySingleDeployment(deployment);
        });
      }
    },
    ...mapMutations({
      setGitlabTokenInStore: "app/SET_GITLAB_TOKEN",
    }),
    ...mapActions({
      getGitlabBranches: 'app/getGitlabBranches',
      getGitlabTags: 'app/getGitlabTags',
      getGitlabTriggers: 'app/getGitlabTriggers',
      executeDeployScript: 'app/executeDeployScript',
    })
  },
  computed: {
    ...mapGetters({
      gitlabTokenInStore: 'app/gitlabToken',
      gitlabProjectIdMap: 'app/gitlabProjectIdMap',
    })
  },

  watch: {
    type: function(newVal, oldVal) {
      this.selectedBranchForDeploy = {};
      this.selectedTagForDeploy = {};
      if (this.gitlabTokenInStore) {
        this.updateGitLabData();
      }
    },
  }
}
</script>

<style lang="scss">

.deployment-process-component {

   /**
    * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
    * Tailwind classes are present in the HTML, so deleting the following lines,
    * when styleberry is deprecated should still work.
    **/
  .multiselect__option .multiselect__option--highlight .multiselect__option--selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .multiselect__option.multiselect__option--highlight, .multiselect__option.multiselect__option--highlight:after {
    @apply text-blue-grey-800;
  }

  .multiselect__tag {
    color: #35495e;
  }


  .multiselect__single {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
</style>
