"use strict"

const table = [
    //  [  state          , action      , nextState           , functionName,    chainAction(optional) ]
    // START
        [ 'none'         , 'start'     , 'fileBrowser'      , 'start' ]
    // File Browser
      , [ 'fileBrowser'  , 'createNew' , 'editor'           , 'newSubtitles' ]
      , [ 'fileBrowser'  , 'edit'      , 'editor'           , 'openFile'     ]
      , [ 'fileBrowser'  , 'exit'      , 'end'              , 'end'          ]
    // EDITOR
      , [ 'editor'          , 'save'           ,  'fileBrowser'     , 'applyChanges'      ]
      , [ 'editor'          , 'cancel'         ,  'fileBrowser'     , 'cancelChanges'     ]

    // *** RESET - can start from any state
    , [ 'editor'          , 'reset'           ,  'none'          , 'reset'       ]
    , [ 'fileBrowser'     , 'reset'           ,  'none'          , 'reset'       ]
]


const logic = {
              init : 'none'
            , table
            , stateData : {
                                vttFiles                : {}    // vtt files as a object
                              , videoURL                : null  // url link to video if available
                              , audioDuration: 0
                        }
        }




export { logic }