<template>
<div class="library-tab-clipper-component">


  <modal
    name="vod-clip-meta"
    width="800px"
    height="auto"
    :scrollable="true"
    classes="v--modal vod-clip-meta-modal p3 flex flex-column"
    :clickToClose="false"
    style="z-index: 10001"
  >
    <section>
      <h1 class="m0">Add Meta to Clipped VOD</h1>

      <vod-meta-form-component ref="vodMetaForm" class="mt3" />

      <footer class="flex justify-end mt2">
        <article class="flex" v-if="!isClippingInProgress">

          <button-component
            variant="primary"
            class="mr2"
            @click="onClipDoneAndMetaSaved"
            >Create Video
          </button-component>

          <button-component
            variant="default"
            @click="$modal.hide('vod-clip-meta')"
          >Cancel</button-component>
        </article>

        <div v-else class="overflow-hidden" style="width: 70px; height: 30px">
          <logo-loader-component />
        </div>
      </footer>
    </section>

    <div class="flex items-center justify-end mb1">

    </div>

  </modal>


  <modal
    name="clip-actions-legend"
    width="1000px"
    style="z-index: 10001"
    height="auto"
    :scrollable="true"
    classes="v--modal clip-actions-legend-modal p2 flex flex-column"
  >

    <header class="flex justify-end">

      <button-component
        class="close-modal-btn"
        variant="minimal"
        shape="circle"
        @click="$modal.hide('clip-actions-legend')">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
      </button-component>
    </header>

    <footer class="p2 mx3 mb3 legend bg-gray+3 rounded-2">

    <h3 class="m0 size-1 mb2">Create a new video asset by adding one or more ranges in the video. Ranges will be joined together and the new video will appear in your Library.</h3>

    <section class="flex flex-wrap">
      <div v-for="(action, name) in clipperActions"
        class="mt2 width+3 flex items-center"
        v-tooltip="action.legend"
        :key="name"
        >
        <svg-icon v-if="action.icon" :name="action.icon" original class="" width="24px" height="24px"></svg-icon>
        <p class="m0" v-else>{{ action.text }}</p>
        <h6 class="m0 ml2">{{ action.legend }}</h6>
      </div>
    </section>
  </footer>


  </modal>

  <section class="mb3" style="margin: 0px 250px;">

    <video

      ref="clipperPreviewVid"

      id="video-container"
      width="100%"
      controls
      :poster="editedVodSelectedThumb"
    ></video>

  </section>

  <section class="clip-timeline flex px2" @click="adjustPLayback" id="clipTimeline" ref="clipTimeline">
    <div class="clip-playhead" :style="{ left: `${currentPlayheadOffset}%` }"
      v-tooltip="{
        content: currentCursorParsedTime,
        trigger: 'manual',
        classes: 'clipper-tooltip',
        show: true
      }">
    </div>
  </section>

  <section class="py3 slider-section px2" style="height: 85px">
    <veeno v-if="isClipperVisible"
      ref="clipperSlider"
      :tooltips="tipsOptsSafe"
      behaviour= 'tap-drag'
      :step="1"
      :pipsy = "{
        mode: 'range',
        density: 1
      }"
      :connect="connectionsSafe"
      :handles="handlesSafe"
      :range="range"

      @change="onSliderChange"

    />
  </section>


  <!-- Clipper Controls Bar Start -->
  <footer class="clip-ctrs-container">

    <section class="flex justify-center">

      <button-component
        variant="minimal"
        class="legend-btn mt1 mr1"
        shape="circle"
        @click="$modal.show('clip-actions-legend')"
      >i</button-component>


      <!-- selectPrevRange -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['selectPrevRange'].legend"
        @click="() => triggerAction(clipperActions['selectPrevRange'].fn)"
        >
        <svg-icon :name="clipperActions['selectPrevRange'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>


      <!-- setRangeStartAt -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['setRangeStartAt'].legend"
        @click="() => triggerAction(clipperActions['setRangeStartAt'].fn)"
        >
        <svg-icon original :name="clipperActions['setRangeStartAt'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>


      <!-- playSelectedRange -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['playSelectedRange'].legend"
        @click="() => triggerAction(clipperActions['playSelectedRange'].fn)"
        >
        <svg-icon :name="clipperActions['playSelectedRange'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>


      <!-- setRangeEndAt -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['setRangeEndAt'].legend"
        @click="() => triggerAction(clipperActions['setRangeEndAt'].fn)"
        >
        <svg-icon original :name="clipperActions['setRangeEndAt'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>


      <!-- selectNextRange -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['selectNextRange'].legend"
        @click="() => triggerAction(clipperActions['selectNextRange'].fn)"
        >
        <svg-icon :name="clipperActions['selectNextRange'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>


      <!-- addRange -->
      <button-component
        variant="minimal"
        class="mt1 ml3"
        size="size-m"
        v-tooltip="clipperActions['addRange'].legend"
        @click="() => triggerAction(clipperActions['addRange'].fn)"
        >
        <svg-icon :name="clipperActions['addRange'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>
      <!-- deleteRange -->
      <button-component
        variant="minimal"
        class="mt1"
        size="size-m"
        v-tooltip="clipperActions['deleteRange'].legend"
        @click="() => triggerAction(clipperActions['deleteRange'].fn)"
        >
        <svg-icon :name="clipperActions['deleteRange'].icon" class="" width="24px" height="24px"></svg-icon>
      </button-component>
    </section>

    <section class="second-row flex justify-between">

      <aside class="flex">
        <!-- moveRangeStart10mBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart10mBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart10mBack'].fn, 600)"
          >

          <p>{{ clipperActions['moveRangeStart10mBack'].text }}</p>
        </button-component>

        <!-- moveRangeStart1mBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart1mBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart1mBack'].fn, 60)"
          >

          <p>{{ clipperActions['moveRangeStart1mBack'].text }}</p>
        </button-component>

        <!-- moveRangeStart1sBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart1sBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart1sBack'].fn, 1)"
          >

          <p>{{ clipperActions['moveRangeStart1sBack'].text }}</p>
        </button-component>


        <!-- moveRangeStart1sForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart1sForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart1sForward'].fn, 1)"
          >

          <p>{{ clipperActions['moveRangeStart1sForward'].text }}</p>
        </button-component>


        <!-- moveRangeStart1mForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart1mForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart1mForward'].fn, 60)"
          >

          <p>{{ clipperActions['moveRangeStart1mForward'].text }}</p>
        </button-component>


        <!-- moveRangeStart10mForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeStart10mForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeStart10mForward'].fn, 600)"
          >
          <p>{{ clipperActions['moveRangeStart10mForward'].text }}</p>
        </button-component>
      </aside>

      <aside class="flex">
        <!-- moveRangeEnd10mBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd10mBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd10mBack'].fn, 600)"
          >
          <p>{{ clipperActions['moveRangeEnd10mBack'].text }}</p>
        </button-component>

        <!-- moveRangeEnd1mBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd1mBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd1mBack'].fn, 60)"
          >
          <p>{{ clipperActions['moveRangeEnd1mBack'].text }}</p>
        </button-component>

        <!-- moveRangeEnd1sBack -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd1sBack'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd1sBack'].fn, 1)"
          >
          <p>{{ clipperActions['moveRangeEnd1sBack'].text }}</p>
        </button-component>


        <!-- moveRangeEnd1sForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd1sForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd1sForward'].fn, 1)"
          >
          <p>{{ clipperActions['moveRangeEnd1sForward'].text }}</p>
        </button-component>


        <!-- moveRangeEnd1mForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd1mForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd1mForward'].fn, 60)"
          >
          <p>{{ clipperActions['moveRangeEnd1mForward'].text }}</p>
        </button-component>


        <!-- moveRangeEnd10mForward -->
        <button-component
          variant="minimal"
          class="mt1"
          size="size-m"
          v-tooltip="clipperActions['moveRangeEnd10mForward'].legend"
          @click="() => triggerAction(clipperActions['moveRangeEnd10mForward'].fn, 600)"
          >
          <p>{{ clipperActions['moveRangeEnd10mForward'].text }}</p>
        </button-component>
      </aside>


    </section>

  </footer>
  <!-- Clipper Controls Bar End -->

  <footer class="flex justify-end">

    <button-component
      variant="primary"
      class="mt2"
      size="size-m"

      @click="onClippingDone"
      v-if="!isClippingInProgress"
      >Next
    </button-component>

    <div v-else class="overflow-hidden pt1" style="width: 70px; height: 52px">
      <logo-loader-component />
    </div>
  </footer>



  <footer class="flex flex-column ovefrlow-auto mt2" style="max-height: 120px;" v-if="fromSelectedVodClips.length">
    <h3 class="m0 mb1" style="margin-top: -50px">Clips</h3>

    <div
      class="fileitem flex mt1 pointer"
      v-for="clip in fromSelectedVodClips"
      :key="clip.guid"
      @click="openClipForEdit(clip)"
    >
      <div v-if="clip.status !== 'encode-later'">
        <div
          v-if="clip.meta && clip.meta.imageUrl"
          class="fileitem-image flex-none relative bg-gray+4"
          style="width: 96px; height: 54px"
          :style="{
            background: `url('${
              clip.meta && clip.meta.imageUrl
            }?q=80&f=jpeg') no-repeat center center / cover`
          }"
        >
          <tag-component
            v-if="clip.meta && clip.meta.type === 'ad'"
            class="fileitem-type"
            :variant="clip.meta && clip.meta.type"
            size="size-s"
            >{{ clip.meta && clip.meta.type }}</tag-component
          >
          <tag-component
            class="fileitem-duration"
            variant="duration"
            size="size-s"
            >{{ parseDuration(clip.srcDuration) }}</tag-component
          >
        </div>

        <div
          v-else
          class="fileitem-image flex-none flex items-center justify-center relative bg-gray+4"
          style="width: 96px; height: 54px"
        >
          <ioio-icon
            icon="far-image"
            class="w-6 h-6 text-black-400"
          />
        </div>
      </div>

      <div class="fileitem-info flex flex-column flex-auto ml2">
        <h4 class="fileitem-title size-2 truncate pr3">
          {{ clip.meta && clip.meta.title | truncate(64, "...") }}
        </h4>
        <div class="flex items-center">
          <tag-component
            v-if="clip.meta && clip.meta.group"
            class="fileitem-group mr1"
            >{{ clip.meta.group || "No group" }}</tag-component
          >
          <tag-component v-if="clip.meta && clip.meta.genre" class="fileitem-genre">{{
            clip.meta.genre
          }}</tag-component>
        </div>

        <div v-if="clip.status !== 'complete'">
          <tag-component variant="error">{{ clip.status}}</tag-component>
        </div>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Hls from "hls.js";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    name: 'LibraryTabClipper',

    hls: null,
    hlsjsConfig: {
      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },


    clipperActions: {
      playSelectedRange: {
        fn: 'playSelectedRange',
        icon: 'Play',
        legend: 'Play selected range'
      },
      selectPrevRange: {
        fn: 'selectPrevRange',
        icon: 'Previous',
        legend: 'Select previous range'
      },
      selectNextRange: {
        fn: 'selectNextRange',
        icon: 'Next',
        legend: 'Select next range'
      },
      deleteRange: {
        fn: 'deleteRange',
        icon: 'Delete',
        legend: 'Delete selected range'
      },
      addRange: {
        fn: 'addRange',
        icon: 'plus-sign',
        legend: 'Add range at playhead'
      },
      setRangeStartAt: {
        fn: 'setRangeStartAt',
        icon: 'set-start',
        legend: 'Set range start at playhead'
      },
      setRangeEndAt: {
        fn: 'setRangeEndAt',
        icon: 'set-end',
        legend: 'Set range end at playhead'
      },

      moveRangeStart10mBack: {
        fn: 'moveRangeStartBack',
        text: '-10m',
        legend: 'Move range start Back'
      },

      moveRangeStart10mForward: {
        fn: 'moveRangeStartForward',
        text: '+10m',
        legend: 'Move range start Forward'
      },

      moveRangeStart1mBack: {
        fn: 'moveRangeStartBack',
        text: '-1m',
        legend: 'Move range start Back'
      },
      moveRangeStart1mForward: {
        fn: 'moveRangeStartForward',
        text: '+1m',
        legend: 'Move range start Forward'
      },
      moveRangeStart1sBack: {
        fn: 'moveRangeStartBack',
        text: '-1s',
        legend: 'Move range start Back'
      },
      moveRangeStart1sForward: {
        fn: 'moveRangeStartForward',
        text: '+1s',
        legend: 'Move range start Forward'
      },

      moveRangeEnd10mBack: {
        fn: 'moveRangeEndBack',
        text: '-10m',
        legend: 'Move range end Back'
      },

      moveRangeEnd10mForward: {
        fn: 'moveRangeEndForward',
        text: '+10m',
        legend: 'Move range end Forward'
      },

      moveRangeEnd1mBack: {
        fn: 'moveRangeEndBack',
        text: '-1m',
        legend: 'Move range end Back'
      },

      moveRangeEnd1mForward: {
        fn: 'moveRangeEndForward',
        text: '+1m',
        legend: 'Move range end Forward'
      },

      moveRangeEnd1sBack: {
        fn: 'moveRangeEndBack',
        text: '-1s',
        legend: 'Move range end Back'
      },

      moveRangeEnd1sForward: {
        fn: 'moveRangeEndForward',
        text: '+1s',
        legend: 'Move range end Forward'
      },
    },


    currentPlayheadOffset: 0,
    currentCursorParsedTime: 0,
    clipTimeline: null,

    /***** Clipper dynamic options *****/

    // after the initial dynamic options are set,
    // use isClipperVisible to show the clipper with the proper settings
    isClipperVisible: false,

    range: {
      'min': 0,
      'max': 10 // based on videoLength
    },
    handles: [],
    connections: [], // handles count + 1

    /***** END Clipper dynamic options *****/

    /***** Clipper formating options *****/
    tipsOpts: {
      // 'to' the formatted value. Receives a number.
      to: function (value) {

        return getHHMMSSFromMS(value * 1000, 'fullHour');
      }
    },

    /***** Clipper formating options END *****/

    /***** Clipper service values *****/
    latestClipValues: [], // to be updated every time onSliderChange is called
    latestUsedHandleIndex: null, // to be updated every time onSliderChange is called
    latestUsedRangeIndex: null, // to be updated every time onSliderChange is called
    latestCalcPlayheadSecond: null, // use to adjust the playhead

    /***** END Clipper service values *****/

    isClippingInProgress: false

  }),
  props: {},

  mounted() {

    window.s = this;


    this.setupVideoAndSlider();

    this.clipTimeline = this.$refs.clipTimeline;
  },

  methods: {

    parseDuration(ms) {

      return getHHMMSSFromMS(ms, 'fullHour');
    },

    clearClipperState() {

      this.latestClipValues = [];
      this.latestUsedHandleIndex = null;
      this.latestUsedRangeIndex = null;
      this.latestCalcPlayheadSecond = null;
      this.currentPlayheadOffset = 0;
      this.currentCursorParsedTime = 0;
    },


    /** Clipper actions Start **/
    triggerAction(action, param) {

      this[action] && this[action](param);
    },

    addRange() {
      // calc if the range can fit (if the currentSecond is not within a range)
      // if YES, calc where the new range should go in

      const updatedHandles = [...this.latestClipValues];

      const currentSecond = Math.round(this.$refs.clipperPreviewVid.currentTime);

      let canRangeBeAdded = false;
      let newHandleIndex = null;

      const valuesLength = this.latestClipValues.length;

      // Range will fit at the start
      if (this.latestClipValues[0] === undefined || currentSecond < this.latestClipValues[0]) {

        canRangeBeAdded = true;

        updatedHandles.unshift(currentSecond, currentSecond);
        newHandleIndex = 0;

      } else if (this.latestClipValues[valuesLength - 1] === undefined ||
        currentSecond > this.latestClipValues[valuesLength - 1]) {

        // Range will fit at the end.
        canRangeBeAdded = true;

        updatedHandles.push(currentSecond, currentSecond);
        newHandleIndex = updatedHandles.length - 2;

      } else {

        // If range doesn't fit the above, check in between spaces
        for (let i = 1; i < valuesLength; i += 2) {

          if (currentSecond >= this.latestClipValues[i] &&
              currentSecond <= this.latestClipValues[i + 1]) {

            // Range will fit in between other ranges. The splice in index is i+1
            canRangeBeAdded = true;
            newHandleIndex = i + 1;

            updatedHandles.splice(newHandleIndex, 0, currentSecond, currentSecond);

            break;
          }
        }
      }

      if (!canRangeBeAdded) {

        this.$toasted.error('A range can not be added on top of an existing range.');
        return;
      }

      this.updateHandlesAndHelperProps(updatedHandles, newHandleIndex);

      this.setupSliderOptions(false, updatedHandles);
    },

    deleteRange() {

      // null relevant values to their initial states for the component;
      // remove the range from handles and such
      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to delete it.');
        return;
      }

      this.latestClipValues.splice(this.latestUsedRangeIndex * 2, 2);

      this.latestUsedHandleIndex = null;
      this.latestUsedRangeIndex = null;

      this.latestCalcPlayheadSecond = null;

      let sliderUpdateValues = this.latestClipValues;

      if (!this.latestClipValues.length) {

        // The inial span of the slider will be the full length of the video
        // NOTE: It was not successful to only show a blank slider
        // and add the first range later.
        sliderUpdateValues = [0, Math.floor(this.editedVod.srcDuration / 1000)];
      }

      this.setupSliderOptions(false, sliderUpdateValues);
    },

    playSelectedRange() {

      this.$refs.clipperPreviewVid.pause();

      this.setupCustomSeekbar(false);

      this.$refs.clipperPreviewVid.currentTime = this.latestCalcPlayheadSecond;
      this.$refs.clipperPreviewVid.play();

      this.setupCustomSeekbar();
    },

    selectPrevRange() {

      const prevHandleIndex = this.latestUsedRangeIndex * 2 - 2;

      if (prevHandleIndex < 0) {

        this.$toasted.error('There is no previous range');
        return;
      }

      this.updateHandlesAndHelperProps(this.latestClipValues, prevHandleIndex);
    },

    selectNextRange() {

      const nextHandleIndex = this.latestUsedRangeIndex * 2 + 2;

      if (nextHandleIndex > this.latestClipValues.length - 1) {

        this.$toasted.error('There is no next range');
        return;
      }

      this.updateHandlesAndHelperProps(this.latestClipValues, nextHandleIndex);
    },


    // direction: [back/forward]
    _moveRangeStart(seconds, direction) {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to move.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2;

      const rangeSecondToMove = Math.round(this.latestClipValues[absoluteHandleIndex]);

      let boundingSecond;

      if (direction === 'back') {

        const prevHandleIndex = absoluteHandleIndex - 1;

        if (prevHandleIndex < 0) {

          boundingSecond = 0;

        } else {

          boundingSecond = this.latestClipValues[prevHandleIndex];
        }

        console.log('boundingS', boundingSecond);
        const newRangeSecond = rangeSecondToMove - seconds;

        // check if it can be done without overlapping
        if (newRangeSecond >= boundingSecond) {

          console.log('MOVE')

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }

      if (direction === 'forward') {

        const nextHandleIndex = absoluteHandleIndex + 1;

        if (nextHandleIndex > this.latestClipValues.length - 1) {

          boundingSecond = Math.floor(this.editedVod.srcDuration / 1000);

        } else {

          boundingSecond = this.latestClipValues[nextHandleIndex];
        }

        console.log('boundingS', boundingSecond);

        const newRangeSecond = rangeSecondToMove + seconds;

        // check if it can be done without overlapping
        if (newRangeSecond <= boundingSecond) {

          console.log('MOVE')

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }
    },

    // direction: [back/forward]
    _moveRangeEnd(seconds, direction) {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to move.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2 + 1;

      const rangeSecondToMove = Math.round(this.latestClipValues[absoluteHandleIndex]);

      let boundingSecond;

      if (direction === 'back') {

        const prevHandleIndex = absoluteHandleIndex - 1;

        if (prevHandleIndex < 0) {

          boundingSecond = 0;

        } else {

          boundingSecond = this.latestClipValues[prevHandleIndex];
        }

        console.log('boundingS', boundingSecond);

        const newRangeSecond = rangeSecondToMove - seconds;

        // check if it can be done without overlapping
        if (newRangeSecond >= boundingSecond) {

          console.log('MOVE')

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }

      if (direction === 'forward') {

        const nextHandleIndex = absoluteHandleIndex + 1;

        if (nextHandleIndex > this.latestClipValues.length - 1) {

          boundingSecond = Math.floor(this.editedVod.srcDuration / 1000);

        } else {

          boundingSecond = this.latestClipValues[nextHandleIndex];
        }

        const newRangeSecond = rangeSecondToMove + seconds;

        // check if it can be done without overlapping
        if (newRangeSecond <= boundingSecond) {

          const updatedClipValues = [...this.latestClipValues];

          updatedClipValues.splice(absoluteHandleIndex, 1, newRangeSecond);

          this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

          this.setupSliderOptions(false, updatedClipValues);

        } else {

          this.$toasted.error('Range can not be moved this way.');
        }
      }
    },

    moveRangeStartBack(seconds) {

      this._moveRangeStart(seconds, 'back');
    },

    moveRangeStartForward(seconds) {

      this._moveRangeStart(seconds, 'forward');
    },

    moveRangeEndBack(seconds) {

      this._moveRangeEnd(seconds, 'back');
    },

    moveRangeEndForward(seconds) {

      this._moveRangeEnd(seconds, 'forward');
    },

    setRangeStartAt() {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to set.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2;

      let boundingSecondStart;
      let boundingSecondEnd = Math.round(this.latestClipValues[absoluteHandleIndex + 1]);

      const vid = this.$refs.clipperPreviewVid;
      const currentPlayheadPos = Math.floor(vid.currentTime);


      const prevHandleIndex = absoluteHandleIndex - 1;

      if (prevHandleIndex < 0) {

        boundingSecondStart = 0;

      } else {

        boundingSecondStart = this.latestClipValues[prevHandleIndex];
      }

      if (currentPlayheadPos <= boundingSecondEnd && currentPlayheadPos >= boundingSecondStart) {

        const updatedClipValues = [...this.latestClipValues];

        updatedClipValues.splice(absoluteHandleIndex, 1, currentPlayheadPos);

        this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

        this.setupSliderOptions(false, updatedClipValues);

      } else {

        this.$toasted.error('Range can not be moved this way.');
      }
    },

    setRangeEndAt() {

      if (this.latestUsedRangeIndex === null) {

        this.$toasted.error('Select a range in order to set.');
        return;
      }

      const absoluteHandleIndex = this.latestUsedRangeIndex * 2 + 1;

      let boundingSecondStart = Math.round(this.latestClipValues[absoluteHandleIndex - 1]);
      let boundingSecondEnd;

      const vid = this.$refs.clipperPreviewVid;
      const currentPlayheadPos = Math.floor(vid.currentTime);

      const nextHandleIndex = absoluteHandleIndex + 1;

      if (nextHandleIndex > this.latestClipValues.length - 1) {

        boundingSecondEnd = Math.floor(this.editedVod.srcDuration / 1000);

      } else {

        boundingSecondEnd = this.latestClipValues[nextHandleIndex];
      }

      if (currentPlayheadPos <= boundingSecondEnd && currentPlayheadPos >= boundingSecondStart) {

        const updatedClipValues = [...this.latestClipValues];

        updatedClipValues.splice(absoluteHandleIndex, 1, currentPlayheadPos);

        this.updateHandlesAndHelperProps(updatedClipValues, this.latestUsedHandleIndex);

        this.setupSliderOptions(false, updatedClipValues);

      } else {

        this.$toasted.error('Range can not be moved this way.');
      }
    },


    /** Clipper actions END **/



    setupCustomSeekbar(shouldCancel) {

      const vid = this.$refs.clipperPreviewVid;

      if (shouldCancel) {

        vid.ontimeupdate = null;
      }

      const updateInterval = 1;

      let prevVal = 0;

      if (vid.ontimeupdate) return;

      vid.ontimeupdate = () => {

        const percentage = ( vid.currentTime / vid.duration ) * 100;

        prevVal += updateInterval;

        this.currentPlayheadOffset = percentage;

        this.currentCursorParsedTime =
          this.parseDuration(vid.currentTime * 1000);
      };
    },

    adjustPLayback(e) {

      console.log(e.target)
      if (e.target.id !== 'clipTimeline') {

        return;
      }

      const vid = this.$refs.clipperPreviewVid;

      const wasVidPlaying = !!(vid.currentTime > 0 &&
                              !vid.paused &&
                              !vid.ended &&
                              vid.readyState > 2);

      vid.pause();

      console.log(e.offsetX, this.$refs.clipTimeline.offsetWidth);
      const newDesiredPlayPosPercentage = 100 / (this.$refs.clipTimeline.offsetWidth / e.offsetX);

      const newDesiredPlayPosMs = this.editedVod.srcDuration / 100 * newDesiredPlayPosPercentage;

      console.log(newDesiredPlayPosMs, newDesiredPlayPosPercentage);

      vid.currentTime = newDesiredPlayPosMs / 1000;

      if (wasVidPlaying) {

        vid.play();
      }

      this.currentPlayheadOffset = newDesiredPlayPosPercentage;
    },

    onClippingDone() {

      if (!this.latestClipValues.length) {

        this.$toasted.error('Please select a range to be cut.');

        return;
      }

      this.$modal.show('vod-clip-meta');
    },

    onClipDoneAndMetaSaved() {

      const meta = this.$refs.vodMetaForm.onSubmit();

      if (!meta.promise) {

        return;
      }

      this.isClippingInProgress = true;

      meta.promise().then((data) => {

        const clips = [];

        for (let i = 0; i < this.latestClipValues.length; i += 2) {

          const from = Math.round(this.latestClipValues[i]);
          const to = Math.round(this.latestClipValues[i + 1]);

          if (from === to) {

            continue;
          }

          clips.push({

            from,
            to
          });
        }

        const payload = {
          vodGuid: this.editedVod.guid,
          data: {
            clips,
            metaGuid: data.guid
          }
        };

        this.isClippingInProgress = true;

        this.clipVod(payload)
          .then(() => {

            this.$toasted.success('Video was clipped successfully.');

            this.$modal.hide('vod-clip-meta');

            this.setRedirectGuard(false);
          })
          .finally(() => {

            this.isClippingInProgress = false;
          });
      });

    },

    loadVidPlayback() {

      if (Hls.isSupported()) {

        const videoPlaybackUrl = this.fullPreviewVodData.hlsUrl;

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;

          if (this.fullPreviewVodData.status !== 'complete' &&
            this.fullPreviewVodData.status !== 'encode-later') {

            return;
          }
        }

        const video = this.$refs.clipperPreviewVid;

        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        if (!videoPlaybackUrl) {

          return;
        }

        /**
         * Test if the videoPlaybackUrl is .mp4 instead of .m3u8
         */
        if (this.singleSourcePlayed && this.singleSourcePlayed.isMp4) {

          const source = document.createElement('source');

          source.src = videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);

          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });


        this.hls.loadSource(videoPlaybackUrl);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          this.hls.attachMedia(video);

          this.$refs.clipperPreviewVid.muted = true;
        });
      }
    },

    delayedLoadVidPlayback() {

      /**
        * A timeout is required, so that the video tag has time to render
        */
      setTimeout(() => {

        this.loadVidPlayback();
      }, 300)
    },

    setActiveRangeClass() {

      const allConnectedElms = document.getElementsByClassName('noUi-connect');

      //update the active range's class PoC
      const affectedRangeEl = allConnectedElms[this.latestUsedRangeIndex];

      for (let el of allConnectedElms) {

        el.classList.remove('active');
      }

      affectedRangeEl.classList.add('active');
    },

    onSliderChange(props) {

      console.log('onSliderChange', props)

      const { positions, unencoded, handle } = props;

      this.updateHandlesAndHelperProps(unencoded, handle);

      this.setPlayheadAtHandle(handle);
    },

    setPlayheadAtHandle(handle) {

      const newSecond = this.latestClipValues[handle];

      const vid = this.$refs.clipperPreviewVid;

      const wasVidPlaying = !!(vid.currentTime > 0 &&
                              !vid.paused &&
                              !vid.ended &&
                              vid.readyState > 2);

      this.$refs.clipperPreviewVid.pause();

      this.$refs.clipperPreviewVid.currentTime = newSecond;

      if (wasVidPlaying) {

        vid.play();
      }
    },

    updateHandlesAndHelperProps(newValues, latestHandle) {

      this.latestClipValues = [...newValues];
      this.latestUsedHandleIndex = latestHandle;
      this.latestUsedRangeIndex = Math.floor(latestHandle / 2); // 1 range for every 2 handles

      this.latestCalcPlayheadSecond = this.latestClipValues[this.latestUsedRangeIndex * 2];

      setTimeout(() => {

        this.setActiveRangeClass();
      }, 100);
    },

    setupSliderOptions(range, handles) {

      // This method will result in a brief UI flash, while the slider is hidden
      // and then shown again with the updated values. Maybe a proper update can
      // be accomplished using the buid in NoUI method set(), but at the time of
      // initial implementation it was NOT successful, hence this approach was used

      this.isClipperVisible = false;

      setTimeout(() => {

        range && (this.range = {
          'min': 0,
          'max': Math.floor(this.editedVod.srcDuration / 1000) // based on videoLength
        });

        if (handles) {

          const connections = [];

          handles.forEach((h, i) => {

            if (i % 2 === 0) {

              connections.push(false);

            } else {

              connections.push(true);
            }
          });

          connections.push(false);

          this.handles = [...handles];
          this.connections = connections; // handles count + 1
        }

        this.isClipperVisible = true;
      }, 0)
    },

    setupVideoAndSlider() {

      const singleSrcData = {

        ...this.editedVod
      };

      if (this.editedVod.status !== 'complete') {

        singleSrcData.isMp4 = true;

        this.editedVod.sourceProxy &&
          (singleSrcData.hlsUrl = this.editedVod.sourceProxy.proxyUrl);
      }

      this.setSingleSourcePlayed(singleSrcData);

      this.loadVidPlayback();

      // The inial span of the slider will be the full length of the video
      // NOTE: It was not successful to only show a blank slider
      // and add the first range later.
      this.setupSliderOptions(true, [0, Math.floor(this.editedVod.srcDuration / 1000)]);

      this.setupCustomSeekbar();
    },

    openClipForEdit(vod) {

      if (vod.status === "error") {

        this.$toasted.error('The details of video with status `Error` can not be displayed.');

        return;
      }

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.updateEditedVod({
            ...vod,
            meta: {

              ...vod.meta
            },
          })
        });

        this.raiseRedirectFlag(true);

      } else {

        this.updateEditedVod({
          ...vod,
          meta: {

            ...vod.meta
          },
        })
      }


    },

    ...mapMutations({
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
      updateEditedVod: "channelManager/UPDATE_EDITED_VOD",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      clipVod: "channelManager/clipVod",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({
      editedVod: 'channelManager/editedVod',
      editedVodGuid: 'channelManager/editedVodGuid',
      singleSourcePlayed: 'channelManager/singleSourcePlayed',
      fromSelectedVodClips: 'channelManager/fromSelectedVodClips',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    handlesSafe() {

      // NoUI slider throws an error if an empty handles array is passed => pass null
      return this.handles.length ? this.handles : null;
    },
    connectionsSafe() {

      // NoUI slider throws an error if an empty handles array is passed => pass null
      return this.connections.length ? this.connections : null;
    },

    tipsOptsSafe() {

      const tipsArr = this.handles.map(h => this.tipsOpts);

      return tipsArr;
    },

    editedVodSelectedThumb() {

      return this.editedVod.meta.imageUrl;
    },

    fullPreviewVodData() {

      return this.singleSourcePlayed || { status: 'complete' };
    },
  },

  watch: {

    editedVodGuid() {

      this.setupVideoAndSlider();

      this.clearClipperState();
    },

    fullPreviewVodData(newVal, oldVal) {

      if (newVal.guid === oldVal.guid) {

        if (newVal.status === 'complete' &&
          oldVal.status !== 'complete') {

          this.delayedLoadVidPlayback();

          return;
        }

        // Preview can only be done via proxy since the VOD is NOT encoded
        // The proxyUrl was received by the socket => use it for preview
        if (newVal.sourceProxy && newVal.sourceProxy.proxyUrl &&
          !(oldVal.sourceProxy && oldVal.sourceProxy.proxyUrl)) {

          this.setSingleSourcePlayed({

            ...this.fullPreviewVodData,
            isMp4: true,
            hlsUrl: newVal.sourceProxy.proxyUrl
          });

          this.delayedLoadVidPlayback();
        }
      }
    },

    latestClipValues() {

      if (this.latestClipValues.length) {

        this.setRedirectGuard({
          redirectMsg: 'Your video will not be clipped if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    }
  }
}
</script>

<style lang="scss">


.library-tab-clipper-component {

  /* Slider overwrite styles */
  .noUi-connect {

    background: var(--color-gray-500);

    &.active {

      background: var(--color-orange-500);
    }
  }

  .noUi-horizontal .noUi-handle {

    right: -6px !important;
    width: 12px;

    &:before {

      left: 3px;
    }

    &:after {

      left: 6px;
    }
  }

  .noUi-handle, .noUi-base, .noUi-connects {

    cursor: pointer;
  }
  .noUi-horizontal .noUi-tooltip {

    bottom: 30px;
    padding: 1px 5px;
    line-height: 15px;
    font-size: 12px;
    background: #0000007d;
    color: white;
    border: none;
  }

  .noUi-pips.noUi-pips-horizontal {

    top: -55px;
  }

  .noUi-marker.noUi-marker-horizontal.noUi-marker-normal {

    // top: -5px;
  }

  .noUi-marker.noUi-marker-horizontal.noUi-marker-large {

    top: 0;
  }

  .noUi-value.noUi-value-horizontal.noUi-value-large {

    display: none;
    top: -35px;
  }

  /* END Slider overwrite styles */

  .clip-timeline {

    position: relative;
    height: 30px;
    width: 100%;

    z-index: 1;
    cursor: pointer;
  }

  .clip-playhead {

    width: 2px;
    height: 100%;
    background: red;
    position: relative;
    left: 0;
    // transition: left 1s linear;
  }

  .clip-ctrs-container {

    position: relative;
  }

  .legend-btn {

    position: absolute;
    right: 0;
    border: 1px solid var(--color-gray-400);
    font-size: 20px;
  }

}
.clipper-tooltip {

  .tooltip-inner {
    line-height: 15px;
    font-size: 12px;
    padding: 1px 5px;
    border-radius: 2px;
    background: red;
    color: white;
  }

  .tooltip-arrow {

    display: none;
  }
}

</style>
