/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-out': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M0 16l6-5v4h8v2H6v4l-6-5z"/><path pid="1" d="M0 4v7h2V7h20v14H8v2h15a1 1 0 001-1V4a1 1 0 00-1-1h-5V1a1 1 0 00-2 0v2H8V1a1 1 0 00-2 0v2H1a1 1 0 00-1 1z"/></g>'
  }
})
