<template>
<modal
  name="duplicate-workflow"
  width="600"
  height="auto"
  :scrollable="true"
  classes="duplicate-workflow-modal-component v--modal px-8 pt-4 pb-8"
  @before-open="beforeDuplicateWorkflowModalOpen"
>
  <h3 class="mb3">Would you like to duplicate workflow {{ workflowForDuplication.name }}?</h3>


  <section>

    <div class="mt-6">
      <p class="m0 gray size-3 ls0 caps">
        Start Date &amp; Time
      </p>
      <p class="m0 time-display" style="margin-top: 0">
        {{
          workflowSchedule.startDateTime
            ? moment.tz(workflowSchedule.startDateTime, localTimezone).format("MMM Do YYYY h:mma z Z")
            : "Not set"
        }}
      </p>
    </div>
    <div class="mb-6">
      <p class="m0 gray size-3 ls0 caps">Schedule End Date</p>
      <p class="m0 time-display" style="margin-top: 0">
        {{
          workflowSchedule.recurrence && workflowSchedule.recurrence.endDate
            ? moment.tz(workflowSchedule.recurrence.endDate, localTimezone).format("MMM Do YYYY")
            : "Not set"
        }}
      </p>
    </div>

    <div class="mb1" v-if="workflowSchedule.duration">
      <p class="m0 gray size-3 ls0 caps">Duration</p>
      <p class="m0">
        {{ parseDuration(workflowSchedule.duration) }}
      </p>
    </div>
    <div class="mb3" v-if="workflowSchedule.recurrence">
      <p class="m0 gray size-3 ls0 caps">Recurrence</p>
      <p class="m0" v-if="workflowSchedule.recurrence.unit === 'WEEK'">
        {{ workflowSchedule.recurrence.days.join(', ') }}
      </p>

      <p class="m0" v-if="workflowSchedule.recurrence.unit === 'DAY'">Every Day</p>
    </div>



    <vue-form :state="formState" @submit.prevent>

      <check-component
        type="checkbox"
        :label="addCopyScheduleLabel"
        class="size-0 width+4 mb2"
        :val="copySchedule"
        :on-change="() => toggleCopySchedule()" />

      <workflow-schedule-select-component v-if="copySchedule" :initialValues="workflowSchedule" ref="scheduleSelect" class="mt2"/>

    </vue-form>
  </section>
  <footer class="flex justify-end mr-3 mt-2">
    <div v-if="!isRequestPending">

      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-4"
        size="small"
        @click.stop.prevent="cancelDuplicate">Cancel
      </ioio-button>

      <ioio-button
        type="primary"
        size="small"
        @click.stop.prevent="confirmDuplicate"
      >Duplicate
      </ioio-button>
    </div>
    <logo-loader-component width="28px" height="28px" v-if="isRequestPending"/>
  </footer>

</modal>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";

import moment from "moment-timezone";

import { getLocalTimezone, getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    workflowForDuplication: {},

    isRequestPending: false,

    copySchedule: false,
    offset: 0,

    formState: {},
  }),
  props: {
    onConfirm: Function,
    onCancel: Function,
    liveType: String
  },

  created() {


    this.moment = moment;
    this.localTimezone = getLocalTimezone();
  },

  mounted() {

    window.d = this;
  },

  methods: {

    ...mapActions({

      duplicateWorkflow: 'live/duplicateWorkflow',
      updateWorkflowSchedule: 'live/updateWorkflowSchedule',
      duplicateWorkflowElemental: 'elementalLive/duplicateWorkflow',
      updateWorkflowScheduleElemental: 'elementalLive/updateWorkflowSchedule'
    }),

    parseDuration(s) {

      const calcedDurationInMS = s * 1000;

      return getHHMMSSFromMS(calcedDurationInMS);
    },

    toggleCopySchedule() {

      this.copySchedule = !this.copySchedule;
    },

    beforeDuplicateWorkflowModalOpen(event) {

      this.workflowForDuplication = event.params;

      if (this.workflowForDuplication && this.workflowForDuplication.schedule) {

        this.copySchedule = true;
      }
    },

    confirmDuplicate() {

      let payload = {
        id: this.workflowForDuplication.id,
        originalName: this.workflowForDuplication.name
      };

      if (this.copySchedule) {

        console.log(this.$refs.scheduleSelect.getValues());

        var {

          startDateTime,
          duration,
          recurrence,
          timezoneSelected

        } = this.$refs.scheduleSelect.getValues();

        var {

          endDate,
          unit,
          days
        } = recurrence || {};
      }


      const now = new Date();

      const startAsDate = startDateTime && new Date(startDateTime);
      const endAsDate = endDate && new Date(endDate);

      let errFound = false;

      if (
        (startAsDate &&
        endAsDate &&
        endAsDate <= startAsDate) ||
        startAsDate < now
      ) {

        errFound = true;
        this.$toasted.error('End Date should not be before Start Date and both should be in the future.');
      }

      if (duration < 5 || duration > 720) {

        errFound = true;
        this.$toasted.error('Duration should not be lower than 5 and higher than 720 minutes');
      }

      if (errFound) {

        return;
      }


      this.isRequestPending = true;

      this[this.liveType === 'elemental' ? 'duplicateWorkflowElemental' : 'duplicateWorkflow'](payload).then((resp) => {


        if (this.copySchedule) {

          var updateWorkflowId = resp.id;

          const schedulePayload = { };

          if (startDateTime) {

            schedulePayload.startDateTime = `${startDateTime}[${timezoneSelected}]`;
          }

          if (duration) {

            schedulePayload.duration = duration;
          }

          if (recurrence) {

            schedulePayload.recurrence = {
              // DAY, WEEK
              unit
            };


            if (unit === 'WEEK') {

              // Will return ['MON', 'WED'..]
              const applicableDays = days.reduce((filtered, day) => {

                if (day.value) {

                  filtered.push(day.abbr);
                }

                return filtered;
              }, []);

              schedulePayload.recurrence.days = applicableDays;
            }

            if (endDate) {

              schedulePayload.recurrence.endDate =
                this.moment(endDate).format('YYYY-MM-DD');
            }
          }

          return this[this.liveType === 'elemental' ? 'updateWorkflowScheduleElemental' : 'updateWorkflowSchedule']({

              workflowId: resp.id,
              data: schedulePayload,
              originalName: this.workflowForDuplication.name

            }).then(() => {

              // Redirect
              this.onConfirm && this.onConfirm(resp.id);
              this.$modal.hide('duplicate-workflow');
            });
        }


        // Redirect
        this.onConfirm && this.onConfirm(resp.id);
        this.$modal.hide('duplicate-workflow');
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    cancelDuplicate() {

      this.$modal.hide('duplicate-workflow');

      this.onCancel && this.onCancel();
    },
  },

  computed: {

    workflowSchedule() {

      return this.workflowForDuplication.schedule || { };
    },

    addCopyScheduleLabel() {

      return this.workflowForDuplication.schedule ? 'Copy Schedule Options' : 'Add Schedule';
    }
  }
}
</script>

<style lang="scss">
.duplicate-workflow-modal-component {

  padding: 2.25rem !important;
  border-radius: 16px !important;

  label {
    @apply
    font-medium;
  }

  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/

  @import "@indexScss";

  /* @include overwrite-styleberry-styles-tags; */
  @include overwrite-styleberry-styles-inputs;
  @include overwrite-styleberry-styles-htags-margin;
  @include overwrite-styleberry-styles-htags-font-size;

  .vdatetime input {

    padding: 0.5rem;
  }
}
</style>
