<template>
<tr v-if="!isDefaultLanguagesUpdating" class="auto-translated-languages-component">
  <td>
    <span class="size-3 truncate">
      Caption Auto Translated Languages
    </span>
  </td>
  <td>
    <check-component
      :disabled="!autoSubs"
      type="checkbox"
      class="size-0"
      :val="defaultData.value"
      :on-change="() => defaultData.value = !defaultData.value"/>
  </td>
  <td>
    <modal
    name="edit-languages"
    width="600px"
    height="auto"
    :scrollable="true"
    classes="v--modal"
    :clickToClose="false"
    >
      <article class="bg-gray+5 m2 mt3 rounded-3">
        <section class="p2">
          <header class="flex justify-between mb3">

            <h3 class="m0">Edit Languages</h3>

            <button-component
              class="close-delete-modal-btn"
              variant="minimal"
              shape="circle"
              @click="closeModal">
                <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
            </button-component>
          </header>
          <div class="box bg-white rounded-3 mb2">
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <th class="width+8">Language</th>
                    <th class="width+2">Add/Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="flex items-center">
                      <span class="">Add new Language</span>
                      <select
                        class="select"
                        style="min-width: 30%; margin-bottom: 0; margin-left: 2rem;"
                        id="selectedLanguage"
                        v-model="selectedLanguage"
                        placeholder="Languages"
                        name="selectedLanguage">
                        <option
                          v-for="(language, i) in allowedLanguages"
                          :key="i"
                          :value="language.abbr"
                        >{{ language.label }}</option>
                      </select>
                    </td>
                    <td>
                      <button-component
                        variant="primary"
                        intent="success"
                        size="size-s"
                        class="ml-auto mr3"
                        @click="addLanguage()">Add
                      </button-component>
                    </td>
                  </tr>
                  <tr v-for="(language,i) in selectedLanguages" :key="i">
                    <td>
                      <span>{{language}}</span>
                    </td>
                    <td>
                      <button-component
                        variant="primary"
                        intent="danger"
                        size="size-s"
                        class="ml-auto mr3"
                        @click="deleteLanguage(i)">Delete
                      </button-component>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <footer class="mt3">
            <section class="flex justify-end" v-if="!isDefaultLanguagesDataUpdating">
              <button-component
                variant="primary"
                size="size-m"
                class="ml2"
                @click="updateDefaultLanguagesSettings()">Update
              </button-component>
            </section>
            <div v-else class="overflow-hidden">
              <logo-loader-component width="40px" height="40px" />
            </div>
          </footer>
        </section>
      </article>
    </modal>
  </td>
  <td>
    <ioio-button
      type="primary"
      variant="solid"
      size="xs"
      class="w-20"
      @click="editLanguage">Languages
    </ioio-button>
  </td>
  <td>
    <ioio-button
      :disabled="!autoSubs"
      type="primary"
      variant="solid"
      size="xs"
      class="btn-green"
      @click="updateDefaultData()">Update
    </ioio-button>
  </td>
</tr>
<tr v-else>
  <td colspan="5">
    <logo-loader-component width="50px" height="50px" class="mt3"/>
  </td>
</tr>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {languagesTranslateConfig} from "@utils/constants"

export default {
  data: () => ({
    isDefaultLanguagesUpdating: false,
    isDefaultLanguagesDataUpdating: false,
    defaultLanguages: [],
    selectedAbbr: [],
    selectedLanguages: [],
    selectedLanguage: '',
    defaultData: {},
    isLoading: true
  }),
  props: {
    autoSubs: Boolean
  },

  created () {

    this.$emit('loading', true);

    this.getDefaultLanguagesSettings();

    this.getDefaultData();
  },

  methods: {
    getDefaultData() {

      this.getBrokerSettings('auto-translate').then((defaultData) => {

        this.defaultData = defaultData;

      })
      .finally(() => {

        this.isLoading == false ? (this.$emit('loading', false), this.isDefaultLanguagesUpdating = false) : this.isLoading = false;
      });

    },

    getDefaultLanguagesSettings() {

      this.getBrokerSettings('caption-auto-translate-languages').then((defaultLanguagesData) => {

        this.defaultLanguages = defaultLanguagesData.value.slice();

        this.selectedAbbr = defaultLanguagesData.value;
      })
      .finally(() => {

        this.makeDefaultLanguages();

        this.isDefaultLanguagesDataUpdating = false;

        this.isLoading == false ? (this.$emit('loading', false), this.isDefaultLanguagesUpdating = false) : this.isLoading = false;
      });

    },

    makeDefaultLanguages() {

      let languages = [];

      this.selectedAbbr.forEach(abbr => {

        languages.push(languagesTranslateConfig[abbr]);
      });

      this.selectedLanguages = languages;
    },

    async updateDefaultLanguagesSettings() {

      this.isDefaultLanguagesDataUpdating = true;

      const updateVal = this.selectedAbbr;

      await this.updateBrokerSettings({
        params: 'caption-auto-translate-languages',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultLanguagesSettings();

    },

    async updateDefaultData(){

      if (!this.defaultLanguages.length) {

        this.$toasted.error("You haven't added any languages for auto translate.");

        return
      };

      this.isDefaultLanguagesUpdating = true;

      const updateVal = this.defaultData.value;

      await this.updateBrokerSettings({
        params: 'auto-translate',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getDefaultData();
    },

    editLanguage() {

      this.selectedLanguage = this.allowedLanguages[0].abbr;

      this.makeDefaultLanguages();

      this.toggleEditLanguagesModalOpened(true);
    },

    deleteLanguage(index) {

      let list = this.selectedAbbr;

      list.splice(index,1);

      this.selectedAbbr = list;

      this.makeDefaultLanguages()

      this.selectedLanguage = this.allowedLanguages[0].abbr;
    },

    addLanguage() {

      let list = this.selectedAbbr;

      list.push(this.selectedLanguage);

      this.selectedAbbr = list;

      this.makeDefaultLanguages();

      this.selectedLanguage = this.allowedLanguages[0].abbr;
    },

    toggleEditLanguagesModalOpened(show) {

      this.$modal[show ? 'show': 'hide']('edit-languages');
    },

    closeModal() {

      this.toggleEditLanguagesModalOpened(false);

      this.selectedAbbr = this.defaultLanguages.slice();
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {

    allowedLanguages() {
      let languages = Object.keys(languagesTranslateConfig)

      .reduce((value, key) => {

        if(this.selectedAbbr && !this.selectedAbbr.includes(key)) {

          value.push({ abbr: key, label: languagesTranslateConfig[key]});
        }

        return value;

      }, []);


      return languages;
    },
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
  watch: {
    autoSubs() {
      if(!this.autoSubs) {
        this.defaultData.value = false;
        this.updateDefaultData();
      }
    }
  }
}
</script>

<style lang="scss">
  .btn-green{
    &.ds-btn.is-primary.is-solid{
      @apply
      bg-green-500;
    }
  }
</style>
