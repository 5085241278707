<template>
  <ioio-logical-edit
    @changed="onLogicalEditChange"
    :detectChanges="!isFormChanged"
    class="library-vod-details-edit-component flex flex-auto flex-column"
  >
    <ioio-tabs
      class="flex flex-auto flex-column"
      @input="setSelectedTabName"
      ref="tabForm"
    >
      <form class="overflow-scroll">
        <ioio-tab
          name="General"
          :selected="selectedTabName === 'General'"
          class="main-edit-tab text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
        >
          <ioio-field
            topic="editVod"
            label="Title"
            type="text"
            required
            size="medium"
            placeholder="Title of video"
            v-model="editedVod.meta.title"
            class="mb-4"
          />

          <ioio-field
            topic="editVod"
            @input="slugChanged"
            required
            label="Slug"
            type="text"
            regex="[a-z0-9-]+"
            maximum="500"
            size="medium"
            placeholder="Slug of video"
            v-model="editedVod.slug"
            class="mb-2"
          />
          <span class="mb-4"
            >*The slug can only consist of lowercase letters, numbers and
            dashes</span
          >

          <ioio-field
            topic="editVod"
            label="Description"
            type="textarea"
            size="medium"
            placeholder="Description"
            v-model="editedVod.meta.description"
            class="mb-4"
          />

          <label class="ds-fields__label">Thumbnail</label>

          <ioio-tabs
            class="w-full"
            v-if="editedVod.status === 'complete'"
            @input="setThumbnailSelectedTabName"
          >
            <ioio-tab
              name="Video"
              :selected="selectedThumbnailTab === 'Video'"
              class="text-xs text-black-600 w-full"
            >
              <div class="thumb-preview">
                <img :src="editedVodSelectedThumb" @error="onThumbLoadErr" />
                <ioio-icon
                  icon="fas fa-image"
                  class="h-7 w-7 text-black-200"
                  v-if="!editedVodSelectedThumb"
                />
              </div>

              <thumb-selector-component
                :thumbs="editedVodThumbs"
                :selectedThumb="editedVodSelectedThumb"
                :onSelect="onThumbSelect"
                v-if="selectedThumbnailTab === 'Video'"
              />
            </ioio-tab>

            <ioio-tab
              name="URL link"
              class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
            >
              <div class="thumb-preview">
                <img :src="editedVodSelectedThumb" @error="onThumbLoadErr" />
                <ioio-icon
                  icon="fas fa-image"
                  class="h-7 w-7 text-black-200"
                  v-if="!editedVodSelectedThumb"
                />
              </div>

              <ioio-field
                type="text"
                label="Paste URL"
                size="medium"
                class="mb-8"
                placeholder="https://"
                v-model="thumbSelectUrl"
                @input="() => onThumbSelect(thumbSelectUrl)"
              />
            </ioio-tab>

            <ioio-tab
              name="Gallery"
              class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
            >
              <assign-images-modal-component
                :getAssignedImages="getAssignedOverlayImages"
                :initialImages="selectedThumbFromLibrary"
                singleChoise
                addLabel="Select Image"
                raiseFlagName="isAssignImagesOpened"
                width="1100"
                height="700"
              />

              <div class="thumb-preview">
                <img :src="editedVodSelectedThumb" @error="onThumbLoadErr" />
                <section class="flex flex-column items-center justify-center">
                  <ioio-icon
                    icon="fas fa-image"
                    class="h-7 w-7 text-black-200 mb-3"
                    v-if="!editedVodSelectedThumb"
                  />
                  <ioio-button
                    type="primary"
                    variant="outline"
                    size="medium"
                    @click="toggleSelectThumbModalOpened(true)"
                    >Browse gallery
                  </ioio-button>
                </section>
              </div>
            </ioio-tab>
          </ioio-tabs>
          <p v-else>Only encoded videos can have thumbnails assigned</p>

          <assign-images-to-vod-component
            class="my-8"
            :editedVod="editedVod"
            @change="changedCropImages"
          />
        </ioio-tab>

        <ioio-tab
          name="Details"
          class="main-edit-tab text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
        >
          <ioio-dropdown
            topic="editVod"
            label="Type"
            fullWidth
            :items="possibleVodTypes"
            titleProp="label"
            valueProp="value"
            v-model="editedVod.meta.type"
            size="medium"
            class="mb-5"
          />

          <ioio-tags
            topic="editVod"
            size="medium"
            label="Tags"
            class="mb-5"
            v-model="editedVod.meta.tags"
            :tags="editedVod.meta.tags"
          />

          <ioio-tags
            topic="editVod"
            size="medium"
            label="Categories"
            class="mb-5"
            v-model="editedVod.meta.categories"
            :tags="editedVod.meta.categories"
          />

          <ioio-field
            label="Group"
            type="text"
            topic="editVod"
            size="medium"
            placeholder="Group"
            v-model="editedVod.meta.group"
            class="mb-5 autocomplete"
          />

          <ioio-field
            label="Genre"
            type="text"
            topic="editVod"
            size="medium"
            placeholder="Genre"
            v-model="editedVod.meta.genre"
            class="mb-5 autocomplete"
          />

          <ioio-radio
            topic="editVod"
            class="w-full mb-5"
            :options="accessPrivacyOptions"
            name="privacy"
            size="medium"
            v-model="privacy"
            :checkedVal="privacy"
            @input="(e) => onPrivacyInput(e)"
            label="Privacy"
            disclaimer="Set who this video is available to"
          />

          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900">Featured video</div>
              <div class="text-xs text-blue-grey-400">
                This video will appear as featured in hubs
              </div>
            </aside>

            <ioio-toggle
              topic="editVod"
              size="medium"
              v-model="editedVod.meta.featured"
              :labelLeft="editedVod.meta.featured ? 'On' : 'Off'"
            >
            </ioio-toggle>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900">List in Video Portals</div>
              <div class="text-xs text-blue-grey-400">
                This video will appear in video portal results
              </div>
            </aside>

            <ioio-toggle
              size="medium"
              topic="editMultipleVods"
              v-model="editedVod.meta.listedInPortals"
              :labelLeft="editedVod.meta.listedInPortals ? 'On' : 'Off'"
            >
            </ioio-toggle>
          </div>

          <div class="text-sm text-black-900">Expires at</div>
          <div class="text-xs text-blue-grey-400 mb-3">
            This video will expire after the selected date and hour
          </div>

          <section class="flex" v-if="selectedTabName === 'Details'">
            <ioio-field
              topic="editVod"
              type="date"
              size="medium"
              placeholder="Choose date"
              v-model="editedVod.meta.expires"
              class="w-full mr-4"
            />

            <ioio-field
              topic="editVod"
              type="time"
              size="medium"
              placeholder="Choose end time"
              v-model="editedVod.meta.expires"
              class="w-full"
            />
          </section>
        </ioio-tab>
        <ioio-tab
          name="Metadata"
          class="main-edit-tab text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
          :selected="selectedTabName === 'Metadata'"
        >
          <custom-meta-fields-component
            :editedAsset="editedVod.meta"
            metaResourceType="video"
            formTopic="editVod"
          />
        </ioio-tab>
        <ioio-tab
          name="Hubs"
          class="main-edit-tab text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 pt-2"
          :selected="selectedTabName === 'Hubs'"
        >
          <library-vod-hubs-tab-edit-component
            :editedVodMeta="editedVod.meta"
            :editedVodGuid="editedVodGuid"
            :onHubsChanged="onLogicalEditChange"
          />
        </ioio-tab>

        <button type="submit" @click.prevent="save()" class="h-0"></button>
      </form>
    </ioio-tabs>
  </ioio-logical-edit>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isFormDirty: false,

    selectedTabName: 'General',

    selectedThumbnailTab: 'Video',

    thumbSelectUrl: '',

    selectedThumbFromLibrary: {},

    privacy: 'false',

    isSlugChanged: false,
    isFormChanged: false
  }),

  props: {
    editedVod: Object,
    editedVodGuid: String,
    editedVodSelectedThumb: String,
    editedVodThumbs: Array,
    editVodData: Function,
    save: Function
  },

  mounted() {
    window.ed = this;
    let inputs = document.querySelectorAll("div.autocomplete > div > input");
    inputs.forEach((el) =>{
      let name = el.placeholder.toLowerCase();
      el.setAttribute("autocomplete", "on");
      el.setAttribute("name", name);
      el.setAttribute("id", name);
    })

    this.updateInitialOptions();
  },

  methods: {

    slugChanged() {

      this.isSlugChanged = true;
      this.$emit('slugChanged');
    },

    onLogicalEditChange(a) {

      this.isFormDirty = true;
      if (this.isSlugChanged) {

        this.isSlugChanged = false;
      } else {

        this.isFormChanged = true;
      }
    },

    updateInitialOptions() {

      this.privacy = `${this.editedVodPrivacy}`;

      if (!this.editedVod.meta.categories) {

        this.editedVod.meta.categories = [];
      }

      if (!this.editedVod.meta.tags) {

        this.editedVod.meta.tags = [];
      }

      if (this.editedVod.meta.expires) {

        this.editedVod.meta.expires = new Date(this.editedVod.meta.expires).toISOString();
      }
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;
    },

    setThumbnailSelectedTabName(name) {

      this.selectedThumbnailTab = name;
    },

    onPrivacyInput(input) {

      const privacy = input === 'true' ? true : false;

      this.editVodData({
        ...this.editedVod,
        meta: {
          ...this.editedVod.meta,
          privacy
        }
      });
    },

    toggleSelectThumbModalOpened(newVal) {

      this.toggleAssignImagesModalFlag({

        division: 'isAssignImagesOpened',
        newVal
      });
    },

    getAssignedOverlayImages(images, imagesMap) {

      const selectedImage = imagesMap[images[0]] || {};

      this.selectedThumbFromLibrary = {

        [selectedImage.guid]: {
          guid: selectedImage.guid,
          srcUrl: selectedImage.cdnPath
        }
      };

      this.onThumbSelect(selectedImage.cdnPath);

      this.toggleSelectThumbModalOpened(false);
    },

    onThumbLoadErr() {

      this.onThumbSelect('');
    },

    onThumbSelect(thumb) {

      this.editVodData({

        ...this.editedVod,

        meta: {

          ...this.editedVod.meta,
          imageUrl: thumb
        }
      });

      this.onLogicalEditChange();

      // Get other controls inline
      this.thumbSelectUrl = thumb;

      this.selectedThumbFromLibrary = {};
    },

    changedCropImages() {

      this.onLogicalEditChange();
    },

    ...mapMutations({

      // NOTE: use the events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE
      // flag only temporary, since the upload modal will be refactored
      toggleAssignImagesModalFlag: 'events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE'
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      possibleVodTypes: "channelManager/possibleVodTypesArr",
      accessPrivacyOptions: 'channelManager/accessPrivacyOptions'
    }),

    editedVodPrivacy() {

      return this.editedVod.meta.privacy;
    }
  },
  watch: {

    editedVodGuid() {

      this.updateInitialOptions();
    }
  }
}
</script>

<style lang="scss">
.library-vod-details-edit-component {
  .main-edit-tab {
    min-height: 22rem;
  }
}


</style>