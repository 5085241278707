/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subtitles': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 0h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0zm0 14h16V2H2v12zm2-8h2v2H4V6zm8 4H4v2h8v-2zm2 0h2v2h-2v-2zm2-4H8v2h8V6z" _fill="#4F4F4F"/>'
  }
})
