<template>
<section class="library-tab-insights-container h-screen flex flex-col flex-auto">

  <header class="mb-8 mt-4 mx-4">

    <section class="flex preview-vid-container">

      <video
        ref="previewVid"
        src=""
        id="video-container"
        controls
        height="100%"
        class="mr-10"></video>

      <aside class="overflow-auto flex flex-column flex-auto video-info">
        <h1 class="m0 mb-4 font-semibold text-2xl">{{ videoMeta && videoMeta.title || statsVodTitle }}</h1>
        <p class="m0 mb-4 text-base font-normal text-black-500 flex-auto">{{ videoMeta.description || 'No description' }}</p>
        <footer class="flex justify-between">

          <h6 class="m0 text-sm font-normal text-black-500">Created <span class="text-black-600">{{ new Date(visibleVodDetails.createdAt).toLocaleString() }}</span></h6>

        </footer>
      </aside>

    </section>

    <insights-general-stats-component
      class="mt-8"
      :allTimeViews="allTimeViews"
      :avgWatchTime="avgWatchTime"
      :avgCompletion="avgCompletion"
      :totalWatchTime="totalWatchTime" />

  </header>

  <section class="flex flex-col flex-auto px-4">
    <section class="flex justify-between items-center mb-8">
      <header class="flex items-center">
        <ioio-dropdown-base
          class="w-64 mr-4"
          alignX="right"
          :overflow="true">

          <template slot="title" classes="text-black-900" >
            <div class="ds-dropdown__title"  ref="dropdown">
              {{selectedPeriod}}
              <ioio-icon icon="fal-chevron-down" />
            </div>
          </template>

          <div
            v-for="option in activityDropdownItems"
            :key="option.label"
            class="dropdown__menu-item">

            <ioio-button
              type="minimal"
              size="medium"
              @click="onActivityPeriodChange(option)">
              {{ option.label }}
            </ioio-button>
          </div>

          <div @click.stop="">
            <ioio-field
              :minimum="minDate"
              :maximum="maxDate"
              v-model="dateRange"
              placeholder='Choose dates'
              type="daterange"
              :hasClearBtn="false"
              @input="onActivityPeriodChange">
            </ioio-field>
          </div>

        </ioio-dropdown-base>

        <ioio-dropdown
          :items="devicesDropdownItems"
          titleProp="label"
          valueProp="device"
          v-model="selectedDeviceOption"
          @change="onSelectedDeviceOptionsChange"
          size="medium"
          class="mr-4 w-64"
          fullWidth
        />
      </header>

      <aside class="report-actions">

        <!--
        <ioio-button
          type="primary"
          size="medium"
          :fullWidth="false"
          iconLeft=""
          iconRight=""
          classes="mr-4"
          :disabled="false"
        >
          Share
        </ioio-button>

          Hidden for now - it is not sure how data should be presented in the CSV

          <ioio-button
          type="primary"
          variant="outline"
          size="medium"
          :fullWidth="false"
          iconLeft=""
          iconRight=""
          classes=""
          :disabled="false"
        >
          Export to CSV
        </ioio-button> -->
      </aside>
    </section>

    <section class="mb-8 flex">
      <aside class="shadow-md border rounded border-black-100 mr-8 flex-1 p-4 bg-white">
        <header class="flex justify-between p-4">
          <aside>
            <h3 class="m0 text-2xl text-black-600 font-semibold">Views</h3>
          </aside>
          <aside class="flex items-center" v-if="viewsChartSeries.length && !isChartLoading">

              <h3 class="m0 mr-4 text-2xl text-black-900 font-normal">{{ viewsAdditionalData.value }}</h3>
              <h6 v-if="viewsAdditionalData.up" class="m0 text-green-500 text-xs font-normal">
                <ioio-icon icon="fa-arrow-up"  />
                +<span>{{ viewsAdditionalData.percent }}%</span>
              </h6>
              <h6 v-else class="m0 text-red-500 text-xs font-normal">
                <ioio-icon icon="fa-arrow-down"  />
                +<span>{{ viewsAdditionalData.percent }}%</span>
              </h6>
            </aside>
        </header>
        <apexchart width="100%" height="380" :options="viewsChartOptions" :series="viewsChartSeries" v-if="viewsChartSeries.length && !isChartLoading"></apexchart>

        <p class="py-12 px-4" v-else-if="!viewsChartSeries.length && !isChartLoading">No information found.</p>

        <div v-else style="overflow:hidden" class="flex items-center flex-auto">
          <logo-loader-component width="100px" height="100px" />
        </div>
      </aside>

      <aside class="shadow-md border rounded border-black-100 flex-1 p-4 bg-white">
        <header class="flex justify-between p-4">
          <aside>
            <h3 class="m0 text-2xl text-black-600 font-semibold">Video engagement</h3>
          </aside>
        </header>
        <apexchart width="100%" height="380" :options="videoEngagementChartOptions" :series="videoEngagementChartSeries" v-if="videoEngagementChartSeries.length && !isChartLoading"></apexchart>

        <p class="py-12 px-4" v-else-if="!videoEngagementChartSeries.length && !isChartLoading">No information found.</p>


        <div v-else style="overflow:hidden" class="flex items-center flex-auto">
          <logo-loader-component width="100px" height="100px" />
        </div>
      </aside>
    </section>

    <location-stats-widget-component
      :locationData="locationData"
      class="mb-8 shadow-md border rounded border-black-100 bg-white"/>

    <devices-stats-widget-component
      :deviceTableStats="deviceTableStats"
      :deviceChartOptions="deviceChartOptions"
      :deviceChartSeries="deviceChartSeries"
      class="mb-8 shadow-md border rounded border-black-100 bg-white"/>

  </section>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Hls from "hls.js";
import moment from "moment-timezone";

import {
  viewsChartOptions,
  videoEngagementChartOptions,
  deviceChartOptions } from './charts.config.js'

export default {
  data: () => ({

    activityDropdownItems: [
      { "t_r": '7d', "label": "Last 7 days" },
      { "t_r": '15d', "label": "Last 15 days" },
      { "t_r": '30d', "label": "Last 30 days" },
    ],
    activityPeriod: '7d',
    selectedPeriod: 'Last 7 days',
    devicesDropdownItems: [
      { "device": '', "label": "All devices" },
      { "device": 'PC', "label": "PC" },
      { "device": 'Mobile', "label": "Mobile" },
      { "device": 'Tablet', "label": "Tablet" },
    ],
    selectedDeviceOption: '',

    viewsChartOptions: viewsChartOptions,
    viewsChartSeries: [],

    viewsAdditionalData: {},

    videoEngagementChartOptions: videoEngagementChartOptions,

    videoEngagementChartSeries: [],

    videoEngagementAdditionalData: {},

    deviceTableStats: [],
    deviceChartOptions: deviceChartOptions,

    deviceChartSeries: [],

    locationData: [],

    isChartLoading: false,

    channelsStats: [],

    allTimeViews: 0,
    avgWatchTime: 0,
    avgCompletion: 0,
    totalWatchTime: 0,
    statsVodTitle: '',

    hls: null,
    moment: {},
    dateRange:[]
  }),

  created() {

    this.getVodStats();

    this.moment = moment;
  },

  mounted() {

    window.v= this;
    this.loadVidPlayback();
  },

  props: {

    visibleVodDetails: {
      type: Object
    }
  },

  methods: {

    onSelectedDeviceOptionsChange() {

      this.getVodStats();
    },

    onActivityPeriodChange(value) {

      if (Array.isArray(value)) {

        if (value.length < 2) {

          return;
        } else {

          this.dateRange = value;
          this.activityPeriod = `${this.moment(value[0]).format('YYYY-MM-DD')}_${this.moment(value[1]).format('YYYY-MM-DD')}`;
          this.selectedPeriod = `${this.moment(value[0]).format('DD/MM/YYYY')} to ${this.moment(value[1]).format('DD/MM/YYYY')}`;
          this.$refs.dropdown.click();
        };

      } else {

        this.activityPeriod = value.t_r;
        this.selectedPeriod = value.label;
        this.dateRange = [];
      }


      this.getVodStats();
    },

    loadVidPlayback() {

      if (Hls.isSupported()) {

        const videoPlaybackUrl = this.fullPreviewVodData.hlsUrl;

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;

          if (this.fullPreviewVodData.status !== 'complete' &&
            this.fullPreviewVodData.status !== 'encode-later') {

            return;
          }
        }

        const video = this.$refs.previewVid;

        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        if (!videoPlaybackUrl) {

          return;
        }

        /**
         * Test if the videoPlaybackUrl is .mp4 instead of .m3u8
         */
        if (this.singleSourcePlayed && this.singleSourcePlayed.isMp4) {

          const source = document.createElement('source');

          source.src = videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);

          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });

        this.hls.loadSource(videoPlaybackUrl);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          this.hls.attachMedia(video);

          this.$refs.previewVid.muted = true;
        });
      }
    },

    getVodStats() {

      if (!this.visibleVodDetails.guid) {

        return;
      }

      const statsParams = {

        org_id: this.organizationSelected.guid,
        v_guid: this.visibleVodDetails.guid,
        t_r: this.activityPeriod
      };

      this.selectedDeviceOption && (statsParams.device = this.selectedDeviceOption);

      this.isChartLoading = true;

      this.getVodByIdStats(statsParams)
        .then(stats => {

          // No records found -> null the chartsData
          if (stats.views_chart.data_available === false) {

            this.viewsChartSeries = [];

          } else {

            // Update viewsChart
            this.viewsChartOptions = {
              ...this.viewsChartOptions,
              xaxis: {
                ...this.viewsChartOptions.xaxis,
                categories: [
                  ...stats.views_chart.DTO.categories
                ]
              },
              yaxis: {
                ...this.viewsChartOptions.yaxis,
                ...stats.views_chart.DTO.yaxis
              }
            }

            this.viewsChartSeries = [
              ...stats.views_chart.DTO.series
            ];

            this.viewsAdditionalData = {

              ...stats.views_chart.total_views
            };
          }
          // END Update viewsChart

          // No records found -> null the chartsData
          if (stats.video_engagement.data_available === false) {

            this.videoEngagementChartSeries = [];

          } else {

            // Update videoEngagementChartOptions
            this.videoEngagementChartOptions = {
              ...this.videoEngagementChartOptions,
              xaxis: {
                ...this.videoEngagementChartOptions.xaxis,
                categories: [
                  ...stats.video_engagement.DTO.categories
                ]
              },
              yaxis: {
                ...this.videoEngagementChartOptions.yaxis,
                ...stats.video_engagement.DTO.yaxis
              }
            }

            this.videoEngagementChartSeries = [
              ...stats.video_engagement.DTO.series
            ];
          }
          // END Update videoEngagementChartOptions

          // No records found -> null the chartsData
          if (stats.device_usage.data_available === false) {

            this.deviceChartSeries = [];

          } else {

            // Update device Stats
            this.deviceTableStats = [...stats.device_usage.data];

            this.deviceChartOptions = {
              ...this.deviceChartOptions,
              xaxis: {
                ...this.deviceChartOptions.xaxis,
                categories: [
                  ...stats.device_usage.DTO.categories
                ]
              },
              yaxis: {
                ...this.deviceChartOptions.yaxis,
                ...stats.device_usage.DTO.yaxis
              }
            }

            this.deviceChartSeries = [
              ...stats.device_usage.DTO.series
            ];
          }
          // END Update device Stats


          // Update location Stats
          this.locationData = [
            ...stats.locations
          ];
          // END Update location Stats


          this.allTimeViews = stats.all_time_data.total_views;
          this.avgWatchTime = stats.all_time_data.avg_watch_time;
          this.avgCompletion = stats.all_time_data.avg_comp;
          this.totalWatchTime = stats.all_time_data.total_watch_time;
          this.statsVodTitle = stats.vod_title || '';
        })
        .finally(() => {

          this.isChartLoading = false;
        });
    },

    delayedLoadVidPlayback() {

      /**
        * A timeout is required, so that the video tag has time to render
        */
      setTimeout(() => {

        this.loadVidPlayback();
      }, 300)
    },

    ...mapMutations({
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
    }),
    ...mapActions({
      getVodByIdStats: "statistics/getVodByIdStats",
    })
  },
  computed: {
    ...mapGetters({

      organizationSelected: "app/organizationSelected",
      singleSourcePlayed: 'channelManager/singleSourcePlayed',
    }),

    videoMeta() {

      return this.visibleVodDetails.meta || {};
    },

    fullPreviewVodData() {

      return this.singleSourcePlayed || { status: 'complete' };
    },

    maxDate() {

      return  this.moment().subtract(1, 'days').format('YYYY-MM-DD');
    },

    minDate() {

      return this.moment(this.visibleVodDetails.createdAt).format('YYYY-MM-DD');
    }
  },

  watch: {

    visibleVodDetails() {

      this.activityPeriod = '7d';
      this.selectedPeriod = 'Last 7 days';
      this.dateRange = [];

      this.getVodStats();

      const singleSrcData = {

        ...this.visibleVodDetails
      };

      if (this.visibleVodDetails.status !== 'complete') {

        singleSrcData.isMp4 = true;

        this.visibleVodDetails.sourceProxy &&
          (singleSrcData.hlsUrl = this.visibleVodDetails.sourceProxy.proxyUrl);
      }

      this.setSingleSourcePlayed(singleSrcData);

      this.loadVidPlayback();
    },

    fullPreviewVodData(newVal, oldVal) {

      if (newVal.guid === oldVal.guid) {

        if (newVal.status === 'complete' &&
          oldVal.status !== 'complete') {

          this.delayedLoadVidPlayback();

          return;
        }

        // Preview can only be done via proxy since the VOD is NOT encoded
        // The proxyUrl was received by the socket => use it for preview
        if (newVal.sourceProxy && newVal.sourceProxy.proxyUrl &&
          !(oldVal.sourceProxy && oldVal.sourceProxy.proxyUrl)) {

          this.setSingleSourcePlayed({

            ...this.fullPreviewVodData,
            isMp4: true,
            hlsUrl: newVal.sourceProxy.proxyUrl
          });

          this.delayedLoadVidPlayback();
        }
      }
    },
  }
}
</script>

<style lang="scss" >
.library-tab-insights-container {

  .preview-vid-container {

  height: 200px;
  }

  .video-info {

  width: 500px;
  max-width: 500px;
  }

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
  .border {
  border: 1px solid rgba(238, 239, 241, var(--tw-border-opacity)) !important;
  }
  .flex-auto {
  min-height: auto !important;
  }
  .bg-white {

  background-color: white !important;
  }

  .input.ds-fields__input{
    @apply h-10;

  }
}
</style>
