/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cut': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M14 7h4v2h-4zM14 21h4v2h-4zM8 21h4v2H8zM22 13h2v4h-2zM23 7h-3v2h2v2h2V8a1 1 0 00-1-1zM22 21h-2v2h3a1 1 0 001-1v-3h-2v2zM4 19H2v3a1 1 0 001 1h3v-2H4v-2z"/><path pid="1" d="M8.909 9.31l6.446 5.454a1.001 1.001 0 001.291-1.527L10.457 8l6.189-5.236a1 1 0 10-1.291-1.527L8.909 6.69 5.798 4.058C5.923 3.728 6 3.374 6 3c0-1.654-1.346-3-3-3S0 1.346 0 3s1.346 3 3 3c.55 0 1.059-.159 1.502-.419L7.361 8l-2.858 2.419A2.961 2.961 0 003 10c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.374-.077-.728-.202-1.058L8.909 9.31zM2 3a1 1 0 112 0 1 1 0 01-2 0zm1 11a1 1 0 110-2 1 1 0 010 2z"/></g>'
  }
})
