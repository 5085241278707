/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '-10m': {
    width: 23,
    height: 8,
    viewBox: '0 0 23 8',
    data: '<path pid="0" d="M.45 3.769h3.303v.72H.45v-.72zM5.038 6.28h1.395V1.519l-1.539.477-.207-.531L6.64.592 7.28.7v5.58h1.25V7H5.038v-.72zm6.754.792c-.78 0-1.365-.288-1.755-.864-.384-.576-.576-1.377-.576-2.403s.192-1.824.576-2.394c.384-.57.97-.855 1.755-.855.786 0 1.371.285 1.755.855.384.57.576 1.368.576 2.394s-.195 1.827-.585 2.403c-.384.576-.966.864-1.746.864zm0-.72c.462 0 .816-.213 1.062-.639.246-.432.37-1.068.37-1.908 0-.834-.124-1.464-.37-1.89-.246-.426-.6-.639-1.062-.639-.456 0-.81.213-1.062.639-.246.426-.369 1.056-.369 1.89 0 .84.123 1.476.37 1.908.251.426.605.639 1.061.639zm8.996-4.059c.522 0 .915.141 1.18.423.263.276.395.684.395 1.224V7h-.846V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.522.066-.756.198a1.456 1.456 0 00-.549.531c.006.054.01.138.01.252V7h-.847V4.003c0-.366-.072-.63-.216-.792-.138-.168-.363-.252-.675-.252-.27 0-.519.066-.747.198a1.55 1.55 0 00-.558.522V7h-.846V2.374h.693l.09.63a1.92 1.92 0 011.53-.711c.726 0 1.203.27 1.431.81.408-.54.942-.81 1.602-.81z" _fill="#000"/>'
  }
})
