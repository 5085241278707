/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#444" d="M23 4H12.5L9.8.4A1 1 0 009 0H1a1 1 0 00-1 1v22a1 1 0 001 1h22a1 1 0 001-1V5a1 1 0 00-1-1zM9 19V9l7 5-7 5z"/>'
  }
})
