<template>
<video
  :ref="videoPlayerRef"
  id="video-container"
  width="100%"
  controls
  :poster="thumbnail"
  class="hls-video-player-component"
></video>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Hls from "hls.js";

export default {
  data: () => ({

    hls: null,
    hlsjsConfig: {
      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity,
      capLevelToPlayerSize: true
    },
  }),
  props: {

    videoPlaybackUrl: String,

    // NOTE: must be provided if multiple players are loaded on the same page
    uniqueId: {
      type: String|Number,
      default: 1
    },

    shouldLoadSynchronously: Boolean,
    thumbnail: String,
    startPlaybackOnLoad: Boolean,
  },
  mounted() {

    if (this.shouldLoadSynchronously) {

      this.loadVidPlayback();
    }
  },

  beforeDestroy() {

    this.unloadHlsPlayer();
  },

  methods: {

    loadVidPlayback() {

      if (Hls.isSupported()) {


        // Null the prev instance
        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }

        const video = this.$refs[this.videoPlayerRef];


        // The source will not be able to play neither through hls nor mp4
        if (!this.videoPlaybackUrl) {

          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({

          ...this.hlsjsConfig
        });

        this.hls.loadSource(this.videoPlaybackUrl);

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          this.hls.attachMedia(video);

          this.hls.media.muted = true;

          this.startPlaybackOnLoad && this.hls.media.play();
        });
      }
    },

    unloadHlsPlayer() {

      if (this.hls) {

        this.hls.stopLoad();
        this.hls.destroy();
        this.hls = null;
      }
    },
  },

  watch: {

    videoPlaybackUrl(newVal, oldVal) {

      this.loadVidPlayback();
    },
  },

  computed: {

    videoPlayerRef() {

      return `videoPlayer-${this.uniqueId}`;
    }
  }
}
</script>

<style lang="scss">


</style>
