function end ( task, dependencies, stateData, data ) {
    const 
          { exportVTT } = dependencies
        , { containerID, vttFiles, editTask } = stateData
        , { changes } = data
        ;

    if ( !changes ) {
            editTask.done ( false )
        }
    else {
            let vtt = exportVTT ( vttFiles );
            editTask.done ( vtt )
        }
    task.done ({ 
                      success  : true 
                    , response : { containerID }
                })
} // end func.
 
 
 
 export { end }
 
 
 