<template>
  <div v-if="selectedListing.schedules" class="playlist-component flex-auto flex flex-column relative overflow-hidden bg-white">

    <modal
      name="save-as-playlist"
      width="600"
      height="600"
      classes="v--modal p2"
      style="overflow: auto"
    >

      <vue-form :state="formState" @submit.prevent class="overflow-y h100 p2">

        <h4 class="mb-4">{{ this.selectedListing.schedules.length }} Videos will be saved as a Playlist.</h4>

        <validate class="">
          <label class="text-danger">Display Name <span class="text-danger">*</span></label>
          <input
            class="truncate"
            type="text"
            placeholder="Display Name"
            v-model="newPlaylist.name"
            required
            maxlen="100"
            name="newPlaylistName"/>
          <field-messages name="newPlaylistName" show="$submitted || $dirty && $touched">
            <div slot="required">Display Name is a required field</div>
            <div slot="maxlen">Display name length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <validate>
          <label>Playlist Name</label>
          <input
            type="text"
            placeholder="Playlist Name"
            v-model="newPlaylist.title"
            maxlen="100"
            name="playlistName"
          >
          <field-messages name="playlistName" show="$submitted || $dirty && $touched">
            <div slot="maxlen">Playlist Name length should not be more than 100 characters</div>
          </field-messages>
        </validate>
        <validate>
          <label>Description</label>
          <input
            type="text"
            placeholder="Description"
            v-model="newPlaylist.description"
            name="description"
            maxlen="500"
          >
          <field-messages name="description" show="$submitted || $dirty && $touched">
            <div slot="maxlen">Description length should not be more than 500 characters</div>
          </field-messages>
        </validate>

        <div>
          <label>Image url</label>
          <input
            type="text"
            placeholder="Image url"
            v-model="newPlaylist.imageUrl"
          >
        </div>

        <div>
          <label>Color</label>

          <swatches
            v-model="newPlaylist.color"
            :colors="possiblePlaylistColors"
            inline
          >
          </swatches>
        </div>

        <div>
          <label>External URL</label>
          <input
            type="text"
            placeholder="External URL"
            v-model="newPlaylist.meta.externalUrl"
          >
        </div>

        <custom-meta-fields-legacy-component
          class="pt2 mt3 mb-4 border-top"
          :editedAsset="newPlaylist.meta"
          metaResourceType="playlist" />

        <footer v-if="!isCreatePlaylistInProgress">
          <button-component
            variant="primary"
            @click="saveNewPlaylist"
            class="mr-4"
          >Create</button-component>
          <button-component
            variant="default"
            @click.stop.prevent="closeSaveAsPlaylistModal"
          >Cancel</button-component>
        </footer>
        <loader-component
          size="size-xs"
          width="41px" v-else/>
      </vue-form>

    </modal>

    <div class="timeline-vertical absolute left-0 top-0 h100 z2"></div>

    <div class="header flex-none bg-white px2 z3 relative">

      <div class="header-title flex items-center justify-between pb1">
      <h3 class="m0 pr2 size-inherit truncate">
        {{ selectedListing.title }}
      </h3>
      <div class="flex-none items-center">
        <button-component
          class="mr1 flex-none"
          size="size-s"
          @click='openDialog("edit-listing")'
          :class="!isSelectedListingApplicableForEditing ? 'disabled' : ''"
          >
          <ioio-icon icon="fas-pen" class="w-4 h-4 mr-1"/>
          Edit
        </button-component>

        <button-component
          class="flex-none"
          size="size-s"
          @click='openSaveSchedulesAsPlaylistModal'
          >
          Save As Playlist
        </button-component>
      </div>
    </div>
      <div class="flex-none z3 relative rounded-2" :style="{ backgroundColor: selectedListing.color}" style="height: 4px"></div>
      <div class="flex items-center py1 size-1">
          <span class="flex-none"><b>{{selectedListing.schedules.length || "0"}}</b> items</span>
        <span class="size-2 flex-auto border-left px1 ml1 leading+1" v-if="selectedListing.description">

          {{ selectedListing.description | truncate(100, '...') }}
        </span>

        </div>
    </div>
    <div class="flex-auto z2 relative overflow-y">



      <div class="flex relative h100" @mouseleave.prevent="() => showAvailableEditOptions(null)">


        <DraggableHolderComponent
          group-name="library-playlist"
          @drop="addScheduleToTimeline"
          :should-accept-drop="shouldAcceptDrop"
          :get-child-payload="getChildPayload"
          :get-ghost-parent="getGhostParent"
          drag-class="schedule-ghost-element p1"
          class="schedules-list flex-auto flex flex-column relative width+10 px2"

        >
          <DraggableComponent v-for="(schedule, i) in selectedSchedules" :key="i"
            class="schedule-tile-box-wrapper"
          >
            <div
              class="schedule-tile-box width+10 bg-white overflow-hidden rounded-2 lift+2"
              :style="{ height: `${scheduleTileHeight}px`, '--playlistColor': selectedListing.color }"
              :class="getScheduleClass(schedule)"
              @mouseenter.prevent="() => showAvailableEditOptions(schedule, i)">
              <header class="schedule-edit-options" v-if="scheduleHoverIndex === i">

                <button-component
                  variant="minimal"
                  size="size-xs"
                  v-for="option in availableScheduleOptions"
                  :key="option.icon"
                  @click="triggerScheduleAction([option.action], [i])"
                >
                  <ioio-icon :icon="option.icon"/>
                </button-component>
              </header>
              <div class="schedule-marker" :class="[i === 0 ? 'schedule-start-end' : '', schedule.type ]">
                <span v-if="i === 0" class="schedule-start-marker">{{parsedDuration(0)}}</span>
                <span>{{getScheduleEndMarker(schedule)}}</span>
                <div class="schedule-marker-bg"></div>
              </div>

              <div class="flex width+10">
                <thumbnail-component :type="schedule.type" :duration="parsedDuration(schedule.totalDuration)" :image="schedule.meta.imageUrl"/>
                <div class="ml2 schedule-meta flex flex-column overflow-hidden">

                  <div v-if="schedule.type === 'live'">
                    <h4 class="m0 mb1 truncate pr3">{{ schedule.url }}</h4>
                    <h6 class="m0 truncate pr3">Slate source: {{ schedule.meta.title || schedule.meta.name }}</h6>
                  </div>
                  <h4 class="m0 truncate pr3" v-else>{{ schedule.meta.title || schedule.meta.name }}</h4>
                  <div class="flex-none">
                    <tag-component v-if="schedule.meta.group" class="fileitem-group">
                      {{ schedule.meta.group || "No group" }}
                    </tag-component>
                    <tag-component v-if="schedule.meta.genre" class="fileitem-genre">
                      {{schedule.meta.genre || "No genre"}}
                    </tag-component>
                  </div>
                </div>
              </div>
            </div>
          </DraggableComponent>
        </DraggableHolderComponent>
      </div>
    </div>
    <div class="schedule-bg absolute top-0 left-0 width+10 h100 z1"></div>
  </div>
  <div
    v-else
    class="playlist-component empty flex-auto flex flex-column items-center justify-center relative overflow-hidden">
    <div v-if="channelsList.length">

      Please select a Playlist from the Timeline

    </div>
    <div v-else>
      There are no channels available.
      <button-component
        variant="default"
        class="ml1 items-center"
        @click="openDialog('create-channel')"
      >
        <ioio-icon
          icon="fas-circle-plus"
          class="h-3.5 text-green-600 mr-2"
        />
        <span>Create New</span>
      </button-component>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { getHHMMSSFromMS, paletteColors } from "@utils/helpers";

export default {
  data: () => ({
    scheduleTileHeight: 88,
    dragOrigin: "playlist",
    // isReadyToDeleteSchedule: false
    scheduleHoverIndex: null,
    availableScheduleOptions: [{
      icon: 'fas-xmark',
      action: 'deleteSchedule'
    }],

    newPlaylist: {
      meta: {}
    },

    formState: {},
    isCreatePlaylistInProgress: false,

    possiblePlaylistColors: [...paletteColors()],
  }),

  props: {},

  mounted() {
    window.p = this;
  },

  methods: {
    ...mapMutations({
      triggerScheduleSwap: "channelManager/TRIGGER_SCHEDULE_SWAP",
      triggerScheduleAdd: "channelManager/TRIGGER_SCHEDULE_ADD",
      triggerMultipleSchedulesAdd: "channelManager/TRIGGER_MULTIPLE_SCHEDULES_ADD",
      triggerScheduleDelete: "channelManager/TRIGGER_SCHEDULE_DELETE",
      triggerSchedulesStartsAdjustment:
        "channelManager/TRIGGER_SCHEDULES_STARTS_ADJUSTMENT",
      updateListingInPlace: "channelManager/UPDATE_LISTING_IN_PLACE",

    }),
    ...mapActions({
      updateListingsAndCalcTimeline: "channelManager/updateListingsAndCalcTimeline",
      makeSavePlaylistRequest: "channelManager/makeSavePlaylistRequest"
    }),

    openDialog(type) {

      this.$modal.show(type);
    },

    showAvailableEditOptions(schedule, index) {

      if (!schedule) {

        this.scheduleHoverIndex = null;
      }

      /**
       * Prevent deleting the last schedule in a listing
       */
      if (this.selectedSchedules.length < 2) {

        return;
      }

      /**
       * Check if the  user is hovering a schedule in a disabled
       * period (past time || in the buffer period) and prevent
       * showing the edit options if so.
       */
      const now = new Date().getTime();

      const timeThatWillBeDeletedFrom = this.selectedListing.start;

      if (timeThatWillBeDeletedFrom < now + this.timelineWriteBufferPeriod) {
        return;
      }


      this.scheduleHoverIndex = index;
    },

    parsedDuration(ms) {
      return getHHMMSSFromMS(ms);
    },

    getScheduleEndMarker(schedule) {
      const scheduleEnd = schedule.start + schedule.totalDuration;

      const fromListingStartToScheduleEnd =
        scheduleEnd - this.selectedListing.start;

      return this.parsedDuration(fromListingStartToScheduleEnd);
    },

    getChildPayload(removedIndex) {
      return {
        item: this.selectedSchedules[removedIndex],
        origin: this.dragOrigin,
        removedIndex
      };
    },

    getGhostParent() {
      return document.body;
    },

    triggerScheduleAction(actionName, args) {

      this[actionName](...args);

      this.showAvailableEditOptions(null);
    },

    deleteSchedule(removedIndex) {

      /**
       * Set the affectedPeriod, based on the before-delete listing.end
       * since it will be adjusted with this.updateListingInPlace(...)
       */
      const affectedPeriod = {
        start: this.selectedListing.start,
        end: this.selectedListing.end
      };

      this.triggerScheduleDelete(removedIndex);

      this.triggerSchedulesStartsAdjustment();

      this.updateListingInPlace({
        atIndex: this.selectedListing.absoluteIndex,
        newListingData: this.selectedListing
      });

      this.updateListingsAndCalcTimeline(affectedPeriod);
    },

    addScheduleToTimeline(dragData) {
      if (dragData.addedIndex === null) {
        return;
      }

      /**
      Check if the  user is adding an item in a disabled
      period (past time || in the buffer period)
      */
      const now = new Date().getTime();

      const timeThatWillBeWrittenOn = this.selectedListing.start;

      if (timeThatWillBeWrittenOn < now + this.timelineWriteBufferPeriod) {

        this.$toasted.error('You can not make changes to a running or protected playlist.');
        return;
      }

      if (dragData.payload.item.status === 'error') {

        this.$toasted.error('A video with status `Error` can not be used inside a listing.');
        return;
      }

      if (dragData.payload.item.status === 'encode-later') {

        this.$toasted.error('A video with status `Encode Later` can not be used inside a listing.');
        return;
      }

      const newFutureEnd =
        dragData.payload.item.srcDuration + this.selectedListing.end;

      const nextListing = this.allListings[
        this.selectedListing.absoluteIndex + 1
      ];

      if (nextListing && newFutureEnd > nextListing.start) {

        this.$toasted.error('Schedule won\'t fit. Make place by moving the next listing');

        return;
      }

      const lastAvailableTime =
        this.loadedListingsAndSchedulesBounds.visibleEndBound;

      const itemTotalDuration = dragData.payload.item.srcDuration;

      const currentListingEnd =
        this.selectedListing.end

      if (currentListingEnd + itemTotalDuration > lastAvailableTime) {

        this.$toasted.error('Your listing can not overflow outside the visible timeline.');
        return;
      }

      dragData.payload.item = {
        ...dragData.payload.item,
        totalDuration:
          dragData.payload.item.totalDuration ||
          dragData.payload.item.srcDuration,
        type: dragData.payload.item.type || dragData.payload.item.meta.type
      };
      /**
       * The drop handler is triggered from a swap
       */
      if (dragData.removedIndex !== null) {
        this.triggerScheduleSwap(dragData);

        this.triggerSchedulesStartsAdjustment();

        this.updateListingInPlace({
          atIndex: this.selectedListing.absoluteIndex,
          newListingData: this.selectedListing
        });

        const affectedPeriod = {
          start: this.selectedListing.start,
          end: this.selectedListing.end
        };

        this.updateListingsAndCalcTimeline(affectedPeriod);
        return;
      }

      /**
       * The drop handler is triggered from adding a playlist from outside
       */
      if (dragData.payload.item.meta.sources) {

        this.triggerMultipleSchedulesAdd({
          addedIndex: dragData.addedIndex,
          schedules: dragData.payload.item.meta.sources
        });

      } else {

        /**
         * The drop handler is triggered from adding new schedule from outside
         */
        this.triggerScheduleAdd(dragData);
      }

      this.triggerSchedulesStartsAdjustment();

      this.updateListingInPlace({
        atIndex: this.selectedListing.absoluteIndex,
        newListingData: this.selectedListing
      });

      const affectedPeriod = {
        start: this.selectedListing.start,
        end: newFutureEnd
      };

      this.updateListingsAndCalcTimeline(affectedPeriod);
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {

      if (payload &&
        (payload.origin === "library" ||
        payload.origin === "playlist" ||
        payload.origin === 'live-tab')) {

        return true;
      }

      return false;
    },

    getScheduleClass(schedule) {
      const isSchedulePlaying =
        this.currentlyPlayingSchedule &&
        this.currentlyPlayingSchedule.guid === schedule.guid &&
        this.currentlyPlayingSchedule.start === schedule.start;

      return isSchedulePlaying ? "schedule-playing" : "";
    },

    openSaveSchedulesAsPlaylistModal() {

      const sources = this.selectedListing.schedules.map(s => {

        return {
          guid: s.guid,
          type: s.type
        };
      });

      const selectedListingExtUrl =
        this.selectedListing.meta && this.selectedListing.meta.externalUrl || '';

      this.newPlaylist = {

        title: '',
        imageUrl: this.selectedListing.imageUrl,
        description: this.selectedListing.description,
        color: '',
        name: this.selectedListing.title,
        meta: selectedListingExtUrl ? {
          externalUrl: selectedListingExtUrl
        } : {},
        sources,
      };

      this.$modal.show('save-as-playlist');
    },

    closeSaveAsPlaylistModal() {

      this.$modal.hide('save-as-playlist');
      this.newPlaylist = {
        meta: {}
      };
    },

    saveNewPlaylist() {

      if(this.formState.$invalid) {

        return;
      }

      this.isCreatePlaylistInProgress = true;

      this.makeSavePlaylistRequest(this.newPlaylist).then(s => {


        this.$toasted.success('Playlist saved successfully.');
        this.closeSaveAsPlaylistModal();
      })
      .finally(() => {

        this.isCreatePlaylistInProgress = false;
      });
    }
  },
  computed: {
    ...mapGetters({
      allListings: "channelManager/selectedChannelListingsAbsolute",
      selectedListing: "channelManager/selectedListing",
      timelineWriteBufferPeriod: "channelManager/timelineWriteBufferPeriod",
      currentlyPlayingListing: "playhead/currentlyPlayingListing",
      currentlyPlayingSchedule: "playhead/currentlyPlayingSchedule",
      channelsList: "channelManager/channelsList",
      loadedListingsAndSchedulesBounds: "channelManager/loadedListingsAndSchedulesBounds",
    }),

    selectedSchedules() {
      return this.selectedListing.schedules;
    },

    isSelectedListingApplicableForEditing() {
      const firstApplicableStamp =
        new Date().getTime() + this.timelineWriteBufferPeriod;

      return this.selectedListing.start > firstApplicableStamp;
    }
  }
};
// document
//   .querySelector(":root")
//   .style.setProperty("--playlistColor", );
</script>

<style lang="scss" scoped>


.schedule-ghost-element.schedule-tile-box {

  .schedule-marker, .schedule-edit-options {

    display: none;
  }
}

.playlist-component {
  --schedule-timeline-width: 5rem;

  .header {
    border-bottom: 1px solid #0003;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.125);
    height: 6rem;
  }

  .header-title { padding-top: .625rem; font-size: 1.125rem }
  .schedule-ghost-element { overflow: hidden; }
  .smooth-dnd-container .smooth-dnd-draggable-wrapper { overflow: visible; }
  &.empty, .schedule-bg { background: var(--sceneBgColor); }
  .timeline-vertical {
    width: 4rem;
    text-align: right;
    padding: 1rem 0.25rem 0.25rem;
  }

  .schedules-list,
  .smooth-dnd-container {
    height: 100%;
    padding-left: var(--schedule-timeline-width);
  }
  .vertical.smooth-dnd-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .schedule-tile-box {
    min-height: 88px;
    margin-bottom: 2px;
    display: flex !important;
    padding: 0.5rem;
  }

  .schedule-tile-box-wrapper {
    position: relative;
    &:last-of-type {
      margin-bottom: 100px;
    }
  }

  .schedule-edit-options {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .schedule-marker {
    display: block;
    position: absolute;
    left: calc(var(--schedule-timeline-width) * -1);
    top:2px;
    width: var(--schedule-timeline-width);
    height: 88px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }
  .schedule-marker .schedule-marker-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .schedule-marker.ad .schedule-marker-bg {
    background: var(--color-yellow-200);
  }

  .schedule-start-end:before,
  .schedule-marker:after {
    content: "";
    width: var(--schedule-timeline-width);
    background: var(--sceneBrColor);
    height: 1px;
    position: absolute;
    left: 0;
    border-radius: 4px;
    bottom: 0;
    z-index: 1;
  }

  .schedule-marker:after { bottom: 0; z-index: 2; }
  .schedule-start-end:before { top: 0; }

  .schedule-marker span {
    position: absolute;
    bottom: -8px;
    z-index: 3;
    background: var(--windowBgColor);
    border: 1px solid var(--sceneBrColor);
    border-radius: 4px;
    padding: 0.0875rem 0.325rem;
    right: 8px;
    box-shadow: 0 2px 5px 0 rgba(43, 45, 80, 0.08);
  }

  .schedule-marker span.schedule-start-marker {
    position: absolute;
    top: -10px;
    bottom: auto;
    right: 8px;
    left: auto;
  }

  .schedule-playing {
    box-shadow: 0 0 0 1px var(--playlistColor), 0 0 0 2px var(--playlistColor) inset;
    z-index: 9;
    .schedule-marker .schedule-marker-bg {
      background: var(--playlistColor);
      box-shadow: 0 -2px 0 2px var(--playlistColor);
    }
  }

  /**
   * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
   * Tailwind classes are present in the HTML, so deleting the following lines,
   * when styleberry is deprecated should still work.
  **/

  @import "@indexScss";

  .v--modal.v--modal-box {

    @include overwrite-styleberry-styles-inputs;
  }
}
</style>
