/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter-tool': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.886 1.536A1 1 0 0023 1H1a1 1 0 00-.823 1.569L9 15.312V22a1 1 0 001 1h4a1 1 0 001-1v-6.688l8.822-12.743a.998.998 0 00.064-1.033z" _fill="#5c7499"/>'
  }
})
