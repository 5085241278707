<template>
<div class="dashboard-sidenav-component width+2 flex-none">

  <nav class="flex flex-column">
    <div class="px2 pb2">
      <h4 class="size0">{{ organizationSelected && organizationSelected.name }}</h4>
    </div>

    <a
      class="link-reset p1 pl2 size0 rounded-3 flex items-center mb-025"
      :class="activeItem === item.name ? 'box bg-white gray-3' : 'gray-1'"
      v-for="(item,i) in applicableNavItems"
      :key="i"
      href="" @click="(e) => navigate(e, item.redirectUrl)">
      <ioio-icon :icon="item.icon" class="h-4 w-5 mr-2"/>
      {{ item.label }}
    </a>

  </nav>
</div>
</template>

<script>

import {
  mapGetters
} from "vuex";

export default {
  data: () => ({
    name: 'DashboardSidenav',
    sideNavItems: [
      // {
      //   iconName: 'house',
      //   name: 'home',
      //   label: 'Home',
      //   redirectUrl: '/'
      // },
      {
        iconName: 'multiple-19',
        icon: 'fas-user-group',
        name: 'manageUsers',
        label: 'Users',
        redirectUrl: '/manage/users'
      },
      {
        iconName: 'privacy',
        icon: 'fas-user-gear',
        name: 'manageRolesAndPermissions',
        label: 'Roles & Permissions',
        redirectUrl: '/manage/roles-and-permissions'
      },

      {
        iconName: 'key',
        icon: 'fas-key',
        name: 'apiKeys',
        label: 'API Keys',
        redirectUrl: '/manage/api-keys'
      },

      {
        iconName: 'settings-gear',
        icon: 'fas-gear',
        name: 'settings',
        label: 'Organisational',
        redirectUrl: '/manage/settings'
      },
      {
        iconName: 'notifications',
        icon: 'fas-paper-plane',
        name: 'manageNotifications',
        label: 'Notifications',
        redirectUrl: '/manage/notifications'
      },
      {
        iconName: 'meta-fields',
        icon: 'fas-table-rows',
        name: 'manageMetaFields',
        label: 'Meta Fields',
        redirectUrl: '/manage/meta-fields'
      },
      // {
      //   iconName: 'managed-inputs',
      //   name: 'managedInputs',
      //   label: 'Managed Inputs',
      //   redirectUrl: '/manage/managed-inputs'
      // },

      {
        iconName: 'grid',
        icon: 'fas-grid',
        name: 'integrationSettings',
        label: 'Integrations',
        redirectUrl: '/manage/integrations'
      },

      {
        iconName: 'call_split',
        icon: 'fas-split',
        name: 'manageSyndication',
        label: 'Syndication',
        redirectUrl: '/manage/syndication',
        permission: 'syndication_lists_list'
      },
    ]
  }),
  props: {
    activeItem: {
      type: String,
      required: true,
      default: "home"
    },
  },

  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected"
    }),

    applicableNavItems() {

      return this.sideNavItems.filter(item => this.$auth(item.permission));
    }
  },
  methods: {
    navigate(e, link) {

      e.preventDefault();

      this.$pushRoute(link);
    },

  }
}
</script>

<style lang="scss" scoped>
/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

@import "@indexScss";

.dashboard-sidenav-component {
  @include overwrite-styleberry-styles-htags-font-size;
  @include overwrite-styleberry-styles-htags-margin;

  @apply
  pr-4;

  @media (min-width: 1530px) {
    @apply
    px-4;
  }
  .box {

    margin-top: 0 !important;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
}




</style>
