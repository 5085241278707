/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-playlist': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" d="M21 3H3V2a1 1 0 011-1h16a1 1 0 011 1z" data-color="color-2"/><path pid="1" d="M23 5H1a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V6a1 1 0 00-1-1zM10 18v-8l6 4z"/></g>'
  }
})
