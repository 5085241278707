<template>
<div class="subtitles-editor-launcher-component">

  <section class="subs-editor-holder" :class="{ active: isAdvacedEditorActive }">

    <section id="subs-editor-container"></section>
  </section>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import askForPromise from "ask-for-promise";

import { subtitlesEditor } from "../../external-libraries/subs-editor/main.js"

export default {
  data: () => ({

    subtitlesEditor,
    editPromiseTask: null,
    isAdvacedEditorActive: false,



    editedSubsFile: null,
    onEditComplete: null,
    storedCaptionsData: null
  }),

  props: {
    editedVod: Object,
    onEditCancel: Function,

  },

  mounted() {
    window.la = this;

    this.$root.$on('openPopup', (data) => this.loadSubsEditor(data));
  },

  methods: {

    setupSubsEditorTask(nameKey, vttInput) {

      console.log(nameKey, vttInput);
      const videoUrl = this.editedVod.sourceProxy && this.editedVod.sourceProxy.proxyUrl;

      if (!videoUrl) {

        this.$toasted.error('Subtitles editor is only available for videos with proxyUrl.');
        return;
      }

      this.isAdvacedEditorActive = true;

      this.editPromiseTask = askForPromise();

      this.editPromiseTask.onComplete(response => {

        let responseVttText = '';

        if (response) {

          // Get the first response file, since currently only one is supported
          responseVttText = response[Object.keys(response)[0]];

          const editedFile = new File([responseVttText], `${nameKey}.vtt`, {
            type: ""
          });


          // here
          this.onEditComplete && this.onEditComplete(editedFile);

        } else {

          this.onEditCancel && this.onEditCancel();
          // this.resetUploadInputState();
        }

        this.isStageFileDisabled = false;

        // close the editor
        this.isAdvacedEditorActive = false;
      });

      let components = {};

      this.subtitlesEditor ( {

        task: this.editPromiseTask,
        containerID: 'subs-editor-container',
        vtt: vttInput,
        videoURL: videoUrl,
        audioDuration: this.audioDuration,
        components
      });
    },

    download(downloadLink) {

      return this.getCaptionsVttFile({ url: downloadLink });
    },

    loadSubsEditor(data) {

      this.editedSubsFile = data.editedSubsFile;
      this.onEditComplete = data.onEditComplete;
      this.storedCaptionsData = data.storedCaptionsData;

      const vttInput = { };


      let nameKey = 'newCaptionsFile';

      if (this.editedSubsFile) {

        const vttDownloadUrl = `${this.storedCaptionsData.cdnPath}${this.editedSubsFile.vtt}`;

        //TODO: @BE @Simeon sometimes the CDN returns CORS error from the browser for no reason (http || https) ???

        this.download(vttDownloadUrl).then((fileContent) => {

          nameKey = this.editedSubsFile.meta.screenName || this.editedSubsFile.guid; // use guid for backwards compatibility

          vttInput[nameKey] = fileContent;

          this.setupSubsEditorTask(nameKey, vttInput);
        });

      } else {

        this.setupSubsEditorTask(nameKey, vttInput);
      }
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getCaptionsVttFile: 'channelManager/getCaptionsVttFile'
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    }),

    audioDuration() {

      return (
        this.editedVod &&
        this.editedVod.mediaInfo &&
        this.editedVod.mediaInfo.audio &&
        this.editedVod.mediaInfo.audio[0] &&
        this.editedVod.mediaInfo.audio[0].duration
      );
    }
  },
}
</script>

<style lang="scss">


</style>
