/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-pause': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M7 1H2a1 1 0 00-1 1v20a1 1 0 001 1h5a1 1 0 001-1V2a1 1 0 00-1-1z"/><path pid="1" data-color="color-2" d="M22 1h-5a1 1 0 00-1 1v20a1 1 0 001 1h5a1 1 0 001-1V2a1 1 0 00-1-1z"/></g>'
  }
})
