<template>
<section class="event-appearance-component flex flex-auto flex-column items-center">
  <section class="flex flex-auto flex-column ratio7-10 py2 " v-if="!isFrameOpened">

    <h1 class="size+2 m0 my2">Appearance</h1>

    <p v-if="siteBuilderSettings === false">No Site builder is configured for this organization.</p>

    <!--section class="rounded-3 px3 py1 box bg-white flex justify-center" style="height: 350px">
      <section class="ratio1-2 flex flex-column justify-center items-center">
        <header>
          <h2 class="size+1 mb0 center">Setup the event appearance for your attendees</h2>
        </header>

        <p class="size-1 center">You need to setup an event experience before people can register and attend your stream on IO Connect</p>

        <footer class="flex justify-between my2">
          <button-component size="size-m" variant="primary" class="mt1" @click="openSiteBuilder()">Get Started</button-component>
        </footer>
      </section>
    </section-->

  </section>

  <section class="frame-app" v-if="isFrameOpened">



    <div class="frame-wrapper" :class="{ 'fullscreen': isAppFullscreen }">

      <ioio-frame-vue ref="ioioEle" v-if="show" origin preload @load="onLoad" @error="onError" @status="onStatus" @close="onClose" :src="srcUrl" />

    </div>
  </section>


</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {

   data() {

    return {

      isFrameOpened: false,

      isAppFullscreen: false,

      show: true,
      error: false, // https://dhypu6xzel13i.cloudfront.net
      srcUrl: '',

      bodyTag: null,
      htmlTag: null,
    }
  },

  props: {
    siteBuilderSettings: [Object, Boolean]
  },

  created() {

  },

  mounted(){

    this.reset();

    window.a = this;

    this.bodyTag = document.body;
    this.htmlTag = document.documentElement;

    this.openSiteBuilder();
  },

  methods: {

    toggleOverflowOnBody(newVal) {

      this.bodyTag.style.overflow = newVal;
      this.htmlTag.style.overflow = newVal;
    },

    openSiteBuilder() {

      this.isFrameOpened = true;
    },

    onLoad() {
      console.log('onLoad');
    },

    onError(e) {

      this.error = e;
      console.info('onError', e);
    },

    onStatus(message) {

      console.log(87, message)

      if (message.type === 'control') {

        if (message.data === 'FULLSCREEN_ENTER') {

          this.isAppFullscreen = true;
          this.toggleOverflowOnBody('hidden');

          message.acn();
        }

        if (message.data === 'FULLSCREEN_EXIT') {

          this.isAppFullscreen = false;
          this.toggleOverflowOnBody('visible');

          message.acn();
        }

      }

      if (message.type === 'event') {

        if (message.data === 'REQUEST_GUID') {

          const eventGuid = this.$route.query.id;

          message.acn(eventGuid);
        }
      }

      // else {
      //   console.log('onStatus', data);
      // }
    },

    onClose(){
      console.log('onClose');
    },

    reset(){

      this.error = false;
    },

    openSiteBuilder() {

      if (this.siteBuilderSettings && this.siteBuilderSettings.url) {

        this.isFrameOpened = true;
        this.srcUrl = this.siteBuilderSettings.url;

      } else {

        this.isFrameOpened = false;
        this.srcUrl = '';
      }
    },

    ...mapMutations({

    }),
    ...mapActions({

    })
  },
  computed: {
    ...mapGetters({
    })
  },

  watch: {

    show(val) {

      if (!val) {

        this.reset();

      }
    },

    siteBuilderSettings() {

      this.openSiteBuilder();
    }
  },
}
</script>

<style lang="scss">


</style>

<style lang="scss">




.event-appearance-component {

  height: 100%;

  .fullscreen {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: white;
  }

  .frame-app {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .frame-app {

  }

  .frame-app > div {
    width: 100%;
    margin: 0 auto;
  }

  .frame-menu > div{
    padding: 20px;
  }

  .frame-menu > div > div{
    display: inline-block;
    width: 50%;
  }
  .frame-menu > div > div input{
    width: 140px;
  }
  .frame-menu > div > div button{
    width: 100px;
  }
  .frame-menu > div > div:nth-child(2),
  .frame-menu > div > div:nth-child(5){
    text-align: right;
  }

  .frame-menu {
    height: 300px;
    margin-bottom: 20px !important;
  }

  .frame-app .frame-wrapper {
    height: 100%;
    z-index: 5;
  }

  .frame-menu, .frame-wrapper {
    overflow: hidden;
  }
}
</style>
