<template>
<section class="zen-master-settings-component pt2">
  <vue-form
    :state="zenMasterSettingsFormState"
    @submit.prevent
    v-if="!isConfigLoading"
    class="zen-config-form flex justify-between"
  >

    <section class="flex pb2">

      <validate class="ratio2-3" tag="label">
        <span class="">API Key</span>

        <input type="text"
          id="name"
          placeholder="API Key"
          v-model="zenMasterConfig.apiKey"
          name="zenMasterConfigKey"
        />
        <field-messages
          name="zenMasterConfigKey"
          show="$submitted || $dirty && $touched"
        >
        </field-messages>
      </validate>

      <check-component
        type="checkbox"
        label="Enabled"
        class="ml2 ratio1-3"
        :val="zenMasterConfig.enabled"
        :on-change="() => zenMasterConfig.enabled = !zenMasterConfig.enabled"/>

    </section>
    <footer class="flex mt1 pt2">
      <button-component variant="success" type="button" class="mr2" @click="saveZenMasterConfig"
        >Done</button-component
      >
      <button-component
        variant=""
        @click.stop.prevent="cancelSaveZenMasterConfigEdit"
        >Cancel</button-component
      >
    </footer>
  </vue-form>

  <logo-loader-component height="40px" class="mt2" v-else />

</section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({

    isConfigLoading: false,

    zenMasterSettingsFormState: {},

    zenMasterConfig: {

      enabled: false,
      apiKey: ""
    },

    cachedPristineConfig: {}

  }),

  created() {

    this.isConfigLoading = true;

    this.getZenMasterSettings().then(config => {

      this.zenMasterConfig = {
        ...config
      };

      this.cachedPristineConfig = {

        ...this.zenMasterConfig
      };

    }).finally(() => {

      this.isConfigLoading = false;
    });
  },

  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      setZenMasterSettings: "live/setZenMasterSettings",
      getZenMasterSettings: "live/getZenMasterSettings",
    }),

    saveZenMasterConfig() {

      this.zenMasterSettingsFormState._submit();

      if (this.zenMasterSettingsFormState.$invalid) {

        return;
      }

      this.isConfigLoading = true;

      this.setZenMasterSettings(this.zenMasterConfig).then(() => {

        this.$toasted.success('Settings were saved successfully.');

        this.cachedPristineConfig = {

          ...this.zenMasterConfig
        };

      }).finally(() => {

        this.isConfigLoading = false;
      })
    },

    cancelSaveZenMasterConfigEdit() {

      this.zenMasterConfig = {

        ...this.cachedPristineConfig
      };

      this.zenMasterSettingsFormState._reset();
    }
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  }
};
</script>

<style lang="scss">


.zen-master-settings-component .zen-config-form .check-component {

  margin-top: 2px;

  span {
    top: 28px;
    left: 40px;
  }
}
</style>
