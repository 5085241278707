import request from './request';
import { AUTH } from '@configs/apiUrls.config.js';

const authUrl = AUTH.url;


const _serialize = (obj) => {

  return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[ k ])}`).join('&');
};




/**
 * Organizations && Deployments methods
 */

export const getCognitoSettings = (params) => {

  const url = `${authUrl}/cognito/settings${params ? `?slug=${params.slug}` : ''}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options });
};

export const getAwsAccounts = ({ token }) => {

  const url = `${authUrl}/aws-accounts`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateAwsAccount = ({ token, payload }) => {

  const accountGuid = payload.guid;

  const url = `${authUrl}/aws-accounts/${accountGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteAwsAccount = ({ token, payload }) => {

  const accountGuid = payload.guid;

  const url = `${authUrl}/aws-accounts/${accountGuid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const addAwsAccount = ({ token, payload }) => {

  const url = `${authUrl}/aws-accounts`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getOrganizations = ({ token }) => {

  const url = `${authUrl}/organizations`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getOrganizationDetails = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};


export const getOrganizationBasicDetails = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.orgGuid}/details`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getOrganizationSocketNotifications = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.orgGuid}/details`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const editOrganizationSocketNotifications = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/details`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getOrganizationNotificationSubscriptions = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.orgGuid}/notification-subscriptions`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const addOrganizationNotification = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/notification-subscriptions`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};


export const deleteOrganizationNotification = ({ token, payload }) => {

  const { orgGuid, subscriptionGuid } = payload;

  const url = `${authUrl}/organizations/${orgGuid}/notification-subscriptions/${subscriptionGuid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const updateOrganization = ({ token, payload }) => {

  const orgGuid = payload.guid;

  const url = `${authUrl}/organizations/${orgGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const addOrganization = ({ token, payload }) => {

  const url = `${authUrl}/organizations`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteOrganization = ({ token, payload }) => {

  const guid = payload;

  const url = `${authUrl}/organizations/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};


export const getDeployments = ({ token, payload }) => {

  const orgGuid = payload;

  const url = `${authUrl}/organizations/${orgGuid}/deployments`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateDeployment = ({ token, payload }) => {

  const orgGuid = payload.orgGuid;
  const deploymentGuid = payload.deploymentGuid;

  const url = `${authUrl}/organizations/${orgGuid}/deployments/${deploymentGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const createDeployment = ({ token, payload }) => {

  const orgGuid = payload.guid;

  const url = `${authUrl}/organizations/${orgGuid}/deployments`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteDeployment = ({ token, payload }) => {

  const { orgGuid, deploymentGuid } = payload;

  const url = `${authUrl}/organizations/${orgGuid}/deployments/${deploymentGuid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getDeploymentDetails = ({ token, payload }) => {

  const orgGuid = payload.orgGuid;
  const deploymentGuid = payload.deploymentGuid;

  const url = `${authUrl}/organizations/${orgGuid}/deployments/${deploymentGuid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getDeploymentSettings = ({ token, payload }) => {

  const orgGuid = payload.orgGuid;
  const deploymentGuid = payload.deploymentGuid;

  const url = `${authUrl}/organizations/${orgGuid}/deployments/${deploymentGuid}/settings`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getPossibleDeploymentPermissions = ({ token, payload }) => {

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${authUrl}/organizations/${payload.orgGuid}/deployments/${payload.deploymentGuid}/permissions${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const syncRolePermissionsForDeployment = ({ token, payload }) => {

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${authUrl}/organizations/${payload.orgGuid}/deployments/${payload.deploymentGuid}/roles/permissions${query}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getPossibleRoles = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.guid}/roles`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createRole = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.guid}/roles`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const updateRole = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.guid}/roles/${payload.roleGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteRole = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/roles/${payload.guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const storeNewUser = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/users`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.userData)
  };

  return request({ url, options, token });
};

export const getUsersList = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.guid}/users`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getUsersWhoUploadedVod = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const getVodCategories = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const getVodTags = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const getVodGroups = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const getVodGenres = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const updateUser = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/users/${payload.userGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getSingleUserData = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/users/${payload.userGuid}`;

  const options = {

    method: 'GET',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteUser = ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/users/${payload.userGuid}`;

  const options = {

    method: 'DELETE',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getUserData = ({ token }) => {

  const url = `${authUrl}/users`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const sendPassword= ({ token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/users/${payload.userGuid}/resend`;

  const options = {

    method: 'POST'

  };

  return request({ url, options, token });
};

export const getGitlabBranches = (data) => {

  const { token, baseUrl, payload } = data;

  const url = `${baseUrl}/projects/${payload.projectId}/repository/branches?page=1&per_page=100`;
  //NOTE: If a Gitlab request is expected to return more than 100 items, multiple request should be made if all resp. item should be listed at once! https://docs.gitlab.com/ee/api/#pagination
  const options = {

    method: 'GET'
  };

  const tokenData = {

    gitlab: token
  }

  return request({ url, options, token: tokenData });
};

export const getGitlabTags = (data) => {

  const { token, baseUrl, payload } = data;

  const url = `${baseUrl}/projects/${payload.projectId}/repository/tags?page=1&per_page=100`;
  // NOTE: If a Gitlab request is expected to return more than 100 items, multiple request should be made if all resp. item should be listed at once! https://docs.gitlab.com/ee/api/#pagination
  const options = {

    method: 'GET'
  };

  const tokenData = {

    gitlab: token
  }

  return request({ url, options, token: tokenData });
};

export const getGitlabTriggers = (data) => {

  const { token, baseUrl, payload } = data;

  const url = `${baseUrl}/projects/${payload.projectId}/triggers?page=1&per_page=100`;
  // NOTE: If a Gitlab request is expected to return more than 100 items, multiple request should be made if all resp. item should be listed at once! https://docs.gitlab.com/ee/api/#pagination
  const options = {

    method: 'GET'
  };

  const tokenData = {

    gitlab: token
  }

  return request({ url, options, token: tokenData });
};

export const getGitlabJobs = (data) => {

  const { token, baseUrl, payload } = data;

  const url = `${baseUrl}/projects/${payload.projectId}/pipelines/${payload.pipelineId}/jobs?page=1&per_page=100`;
  // NOTE: If a Gitlab request is expected to return more than 100 items, multiple request should be made if all resp. item should be listed at once! https://docs.gitlab.com/ee/api/#pagination
  const options = {

    method: 'GET'
  };

  const tokenData = {

    gitlab: token
  }

  return request({ url, options, token: tokenData });
};

export const getDeploymentPipelines = ({ token, params }) => {

  const { orgGuid, deploymentGuid } = params;

  const url = `${authUrl}/organizations/${orgGuid}/deployments/${deploymentGuid}/pipelines`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getOrgDeploymentPipelines = ({ token, params }) => {

  const { orgGuid } = params;

  const url = `${authUrl}/organizations/${orgGuid}/pipelines`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const executeDeployScript = (data) => {

  const { token, baseUrl, payload } = data;

  const url = `${baseUrl}/projects/${payload.projectId}/trigger/pipeline`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.deployOpts)
  };

  const tokenData = {

    gitlab: token
  }

  return request({ url, options, token: tokenData });
};



/**
 * SiteBuilder config methods
 */
export const getSiteBuilderApps = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.guid}/site-builder`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, noDefaultError: true });
};


export const getSiteBuilders = ({ token }) => {

  const url = `${authUrl}/site-builders`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeSiteBuilder = ({ token, payload }) => {

  const url = `${authUrl}/site-builders`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateSiteBuilder = ({ token, payload }) => {

  const { data, guid } = payload;

  const url = `${authUrl}/site-builders/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteSiteBuilder = ({ token, params }) => {

  const url = `${authUrl}/site-builders/${params.guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};




/**
 * Auth methods
 */
export const getTokenPermissions = ({ token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${authUrl}/token-permissions${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const login = ({ email, password }) => {

  const payload = {

    email,
    password
  };

  const url = `${authUrl}/auth/authorize`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options });
};

export const getSocketToken = ({ token, params }) => {

  /**
   * @Note : url base is the authUrl, but the request requires token
   * @params should only be provided for master admin users
   *
   * Example:
   * {
   *  "organization": "6b571052-4092-41e6-8a9a-4a64083da033"
   * }
   */
  const url = `${authUrl}/socket-token`;

  const options = {

    method: 'POST',
    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const getMetaFieldGroupAttachments = ({ baseUrl, token, params }) => {

  const url = `${authUrl}/organizations/${params.guid}/meta-group-attachments`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getMetaFieldsGroups = ({ baseUrl, token, params }) => {

  const { resource, deploymentType } = params;

  let query = '';

  if (resource && deploymentType) {

    query = `?resource=${params.resource}&deploymentType=${params.deploymentType}`;
  }

  const url = `${authUrl}/organizations/${params.guid}/meta-groups${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getMetaFieldsByGroup = ({ baseUrl, token, params }) => {

  const url = `${authUrl}/organizations/${params.orgGuid}/meta-groups/${params.metaGroupGuid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeMetaFieldGroup = ({ baseUrl, token, payload }) => {

  const url = `${authUrl}/organizations/${payload.guid}/meta-groups`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const updateMetaFieldGroup = ({ baseUrl, token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/meta-groups/${payload.metaGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteMetaFieldGroup = ({ baseUrl, token, payload }) => {

  const url = `${authUrl}/organizations/${payload.orgGuid}/meta-groups/${payload.metaGuid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getActivityStream = ({ token, params }) => {

  const url = `${authUrl}/organizations/${params.orgGuid}/activities/search`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.query)
  };


  return request({ url, options, token });
};







/**
 * Channel Manager requests
 */
export const getMultipartUploadConfigs = ({ baseUrl, token }) => {

  const url = `${baseUrl}/upload-multipart`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getChannelsList = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/channels${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const reorderChannelsList = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels/reorder`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const createNewChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getChannelById = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const editChannel = ({ baseUrl, token, payload }) => {

  const { params, guid } = payload;
  const url = `${baseUrl}/channels/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const deleteChannel = ({ baseUrl, token, payload }) => {

  const { guid } = payload;

  const url = `${baseUrl}/channels/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const checkIsChannelLocked = ({ baseUrl, token, params }) => {

  const { guid } = params;
  const url = `${baseUrl}/channels/${guid}/edit`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getPlaylists = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/playlists${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const savePlaylist = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/playlists`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const updatePlaylist = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/playlists/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deletePlaylist = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/playlists/${payload.guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getVodThumbs = ({ baseUrl, token, params }) => {

  // const query = params ? `?${_serialize({
  //   ...params
  // })}` : '';

  const url = `${baseUrl}/vod/list/${params}/thumbs`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getVodSignedUrl = ({ baseUrl, token, payload }) => {

  const guid = payload;

  const url = `${baseUrl}/vod/download/${guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getVodList = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/vod/list${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getSignedHlsUrl = ({ baseUrl, token, params }) => {

  const query = params.query ? `?${_serialize({
    ...params.query
  })}` : '';

  const url = `${baseUrl}/vod/hls-signed-url/${params.guid}${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getVodElastic = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};

export const getVodDetails = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/vod/list/${params.guid}/info`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getVodCompressedFormats = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/vod/list/${params.guid}/mp4`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getAllEncodedVodFormats = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/vod/list/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const editVodMetadata = ({ baseUrl, token, payload }) => {

  const { params, vodGuid } = payload;

  const url = `${baseUrl}/vod/list/${vodGuid}`;


  const options = {

    method: 'PUT',
    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const editVodSlug = ({ baseUrl, token, payload }) => {

  const { params, vodGuid } = payload;

  const url = `${baseUrl}/vod/list/${vodGuid}/slug`;


  const options = {

    method: 'PUT',
    body: JSON.stringify(params)
  };

  return request({ url, options, token, noDefaultError: true });
};

export const archiveVod = ({ baseUrl, token, payload }) => {

  const { vodGuid } = payload;

  const url = `${baseUrl}/vod/archive/${vodGuid}`;


  const options = {

    method: 'POST'
  };

  return request({ url, options, token, returnFullResponse: true, noDefaultError: true });
};

export const unarchiveVod = ({ baseUrl, token, payload }) => {

  const { vodGuid } = payload;

  const url = `${baseUrl}/vod/unarchive/${vodGuid}`;


  const options = {

    method: 'POST'
  };

  return request({ url, options, token, returnFullResponse: true});
};

export const deleteVod = ({ baseUrl, token, payload }) => {

  const { vodGuid } = payload;

  const url = `${baseUrl}/vod/delete/${vodGuid}`;


  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token, returnFullResponse: true, noDefaultError: true });
};

export const clipVod = ({ baseUrl, token, payload }) => {

  const { vodGuid, data } = payload;

  const url = `${baseUrl}/vod/clip/${vodGuid}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(data)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getMediatailors = ({ baseUrl, token }) => {

  const url = `${baseUrl}/mediatailors`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const saveNewMediatailor = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/mediatailors`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const editMediatailor = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/mediatailors/${payload.guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteMediatailor = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/mediatailors/${payload.guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token, returnFullResponse: true, noDefaultError: true });
};

export const getListingsForChannel = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/listings${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const saveNewListingForChannel = ({ baseUrl, token, payload }) => {


  const url = `${baseUrl}/listings`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteListingForChannel = ({ baseUrl, token, payload }) => {

  const { channelGuid, listingStartTime } = payload;

  const url = `${baseUrl}/listings/${channelGuid}/${listingStartTime}`;

  const options = {

    method: 'DELETE',
  };

  return request({ url, options, token });
};

export const saveUpdateNewListingsForChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/listings/multiple`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateExistingListingForChannel = ({ baseUrl, token, payload }) => {

  const { channelId, listingData, oldItemStart } = payload;

  const url = `${baseUrl}/listings/${channelId}/${oldItemStart}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify({

      color: listingData.color,
      imageUrl: listingData.imageUrl,
      end: listingData.end,
      start: listingData.start,
      description: listingData.description,
      title: listingData.title
    })
  };

  return request({ url, options, token });
};

export const getSchedulesForChannel = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/schedules${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const saveUpdateNewSchedulesForChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/schedules`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const uploadFile = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/upload`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const uploadFileMeta = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/meta`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const uploadCaptionsMeta = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/captions/meta`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getCaptionsVttFile = ({ baseUrl, token, payload }) => {

  const url = `${payload.url}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnFullResponse: true });
};


export const encodeFile = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/encode/${payload.guid}`;

  const options = {

    method: 'POST'
  };

  return request({ url, options, token });
};

export const encodeWithAutoSubs = ({ baseUrl, token, payload, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/vod/encode/audio/${payload.guid}${query}`;

  const options = {

    method: 'POST'
  };

  return request({ url, options, token });
};

export const getFutureStoredCaptionsGuid = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/captions`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getFileCaptionsById = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/captions/${payload.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateFileCaptions = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/captions/${payload.guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const translateCaptions = ({ baseUrl, token, payload }) => {

  const { guid, fileGuid, data } = payload;

  const url = `${baseUrl}/vod/captions/${guid}/translation/${fileGuid}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const getBrokerSettings = ({ baseUrl, token, params }) => {

  // `params` can be a string like 'cdn-url'
  const url = `${baseUrl}/settings/${params}`;

  const options = {

    method: 'GET'
  };

  if ( params === 'bucket-acceleration' || params === 'signed-urls') {

    return request({ url, options, token , noDefaultError: true });
  } else {

    return request({ url, options, token});
  };
};

export const updateBrokerSettings = ({ baseUrl, token, payload }) => {

  // `params` can be a string like 'cdn-url'
  const url = `${baseUrl}/settings/${payload.params}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getCDNs = ({ baseUrl, token }) => {

  const url = `${baseUrl}/cdns`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createCDN = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/cdns`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const deleteCDN = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/cdns/${params}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getBrokerApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getBrokerApiKey = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/api-keys/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewBrokerApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateBrokerApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteBrokerApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getBrokerVocabularies = ({ baseUrl, token }) => {

  const url = `${baseUrl}/vocabularies`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createBrokerVocabulary = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vocabularies`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateBrokerVocabulary = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/vocabularies/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const getSingleVocabulary = ({ baseUrl, token, payload }) => {

  const  guid  = payload;

  const url = `${baseUrl}/vocabularies/${guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const deleteSingleVocabulary = ({ baseUrl, token, payload }) => {

  const  guid  = payload;

  const url = `${baseUrl}/vocabularies/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getBrokerVocabularyFilters = ({ baseUrl, token }) => {

  const url = `${baseUrl}/vocabulary-filters`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createBrokerVocabularyFilter = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vocabulary-filters`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateBrokerVocabularyFilter = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/vocabulary-filters/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const getSingleVocabularyFilter= ({ baseUrl, token, payload }) => {

  const  guid  = payload;

  const url = `${baseUrl}/vocabulary-filters/${guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const deleteSingleVocabularyFilter = ({ baseUrl, token, payload }) => {

  const  guid  = payload;

  const url = `${baseUrl}/vocabulary-filters/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getBrokerPresets = ({ baseUrl, token }) => {

  const url = `${baseUrl}/presets`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateExistingPreset = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/preset/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const getSourcePullerJobs = ({ baseUrl, token }) => {

  const url = `${baseUrl}/source-pullers`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createSourcePullerJob = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/source-pullers`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const generateEpgAndDownload = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize(params)}` : '';

  const url = `${baseUrl}/epg${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnResponseHeaders: true, noDefaultError: true });
};

/**
 * END Channel manager requests
 */





/**
 * Live manager requests
 */

export const getRegions = ({ baseUrl, token }) => {

  const url = `${baseUrl}/regions`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getBlueprints = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';


  const url = `${baseUrl}/blueprints${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getBlueprintModel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/blueprints/${payload.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getWorkflows = ({ baseUrl, token, params }) => {

  // Custom logic is required for parsedStatusesQuery,
  // since the BE expects duplicate status keys
  let parsedStatusesQuery = ''

  if (params && params.status) {

    const filteredByStatus = params.status;

    delete params.status;


    filteredByStatus.forEach(s => {

      parsedStatusesQuery += `&status=${s}`;
    });
  }

  const query = params ? `?${_serialize(params)}` : '';

  const url = `${baseUrl}/workflows${query}${parsedStatusesQuery}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

// NOTE: Deptrecate when new NFL BE is merged
export const getWorkflowsStatsOld = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/workflows`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getWorkflowsStats = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows/filters/info`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getMultipleWorkflowsByIds = ({ baseUrl, token, params }) => {


  // Custom logic is required for parsedWorkflowsQuery,
  // since the BE expects duplicate workflow keys
  let parsedWorkflowsQuery = '?';

  params.workflows.forEach(guid => {

    parsedWorkflowsQuery += `&workflow=${guid}`;
  });

  const url = `${baseUrl}/workflows/batch${parsedWorkflowsQuery}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getWorkflowDetails = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/workflows/${params.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getWorkflowRuns = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.id}/runs`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const saveBlueprintAsWorkflow = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteWorkflow = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows/${payload.id}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const duplicateWorkflow = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows/${payload.id}/duplicate`;

  const options = {

    method: 'POST'
  };

  payload.data && (options.body = JSON.stringify(payload.data));

  return request({ url, options, token });
};

export const updateWorkflowSchedule = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows/${payload.workflowId}/schedule`;

  const options = {

    method: 'PUT'
  };

  payload.data && (options.body = JSON.stringify(payload.data));

  return request({ url, options, token });
};


export const getWorkflowFilters = ({ baseUrl, token }) => {

  const url = `${baseUrl}/workflows/filters`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const createWorkflowFilter = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/workflows/filters`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteWorkflowFilter = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/workflows/filters/${params.id}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getChannelForWorkflow = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getThumbnailForChannel = ({ baseUrl, token, params }) => {

  const timestamp = new Date().getTime();

  const url = `${baseUrl}/channels/${params.id}/thumbnail?timestamp=${timestamp}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getChannelForWorkflowAlerts = ({ baseUrl, token, params }) => {

  const isActiveParam = {

    status: params.status
  };

  const query = params.status ? `?${_serialize({

    ...isActiveParam
  })}` : '';

  const url = `${baseUrl}/channels/${params.id}/alerts${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getChannelsAlerts = ({ baseUrl, token, params }) => {

  // Custom logic is required for parsedWorkflowsQuery,
  // since the BE expects duplicate workflow keys
  let parsedWorkflowsQuery = '?status=SET';


  params.workflows.forEach(w => {

    parsedWorkflowsQuery += `&workflow=${w.id}`;
  });

  const url = `${baseUrl}/channels/alerts${parsedWorkflowsQuery}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getElementalChannelsAlerts = ({ baseUrl, token, params }) => {

  let parsedWorkflowsQuery = '';

  for (let i = 0; i < params.workflows.length; i++) {

    const w = params.workflows[i];

    const divideSymbol = i === 0 ? '?' : '&';

    parsedWorkflowsQuery += `${divideSymbol}workflow=${w.id}`;
  }

  const url = `${baseUrl}/channels/alerts${parsedWorkflowsQuery}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getChannelForWorkflowHealth = ({ baseUrl, token, params }) => {

  const query = params.service ? `?${_serialize({

    service: params.service
  })}` : '';

  const url = `${baseUrl}/channels/${params.id}/health${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getDefaultInput = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.id}/inputs`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const switchInputForWorkflow = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.id}/input-switch`;

  const options = {

    method: 'POST',
    body: JSON.stringify(params.body)
  };

  return request({ url, options, token });
};

export const createChannelFromWorkflow = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels`;

  const workflows = [payload.id] // TODO: should support many ids as for v4.

  const options = {

    method: 'POST',
    body: JSON.stringify({ workflows })
  };

  return request({ url, options, token });
};

export const getChannelWorkflowLogs = ({ baseUrl, token, params }) => {

  const query = params.query ? `?${_serialize({
    ...params.query
  })}` : '';

  const url = `${baseUrl}/channels/${params.id}/log${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const deleteLiveChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels`;

  const workflows = [payload.id] // TODO: should support many ids as for v4.

  const options = {

    method: 'DELETE',
    body: JSON.stringify({ workflows })
  };

  return request({ url, options, token });
};

export const pauseLiveChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels/${payload.id}/pause`;

  const options = {

    method: 'POST'
  };

  if (payload.pauseRequestTime) {

    options.body = JSON.stringify({

      time: payload.pauseRequestTime
    });
  }

  return request({ url, options, token });
};

export const unpauseLiveChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels/${payload.id}/pause`;

  const options = {

    method: 'DELETE'
  };

  if (payload.pauseRequestTime) {

    options.body = JSON.stringify({

      time: payload.pauseRequestTime
    });
  }

  return request({ url, options, token });
};

export const deletePauseForChannel = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels/${payload.workflowId}/pause/${payload.pauseId}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getScheduledAds = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.workflowId}/dynamic-ads`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const scheduleAdForWorkflow = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/channels/${payload.workflowId}/dynamic-ads`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const removeAdScheduleFromWorkflow = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/channels/${params.workflowId}/ssai/${params.adId}/delete`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getConnectedDevices = ({ baseUrl, token }) => {

  const url = `${baseUrl}/connected-devices`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getConnectedDeviceById = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/connected-devices/${params.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getConnectedDeviceThumbnail = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/connected-devices/${params.id}/thumbnail`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const addConnectedDevice = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/connected-devices`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteConnectedDevice = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/connected-devices/${params.id}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getZenMasterSettings = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/integrations/zen-master`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const setZenMasterSettings = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/integrations/zen-master`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getLiveRegions = ({ baseUrl, token }) => {

  const url = `${baseUrl}/regions`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getManagedInputs = ({ baseUrl, token }) => {

  const url = `${baseUrl}/managed-inputs`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getManagedInputById = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/managed-inputs/${params.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeManagedInput = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/managed-inputs`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const editManagedInput = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/managed-inputs/${payload.id}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteManagedInput = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/managed-inputs/${payload.id}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getBlueprintForm = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/blueprints/${params.blueprintId}/form`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateFeaturedBlueprints = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/blueprints/featured`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getLiveApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewLiveApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateLiveApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteLiveApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};






/**
 * Elemental Live
 * NOTE: some methods are shared with the original live above and are just
 * called with the BASE_ELEMENTAL_LIVE_URL insteal. If the start to differ
 * in the future, add them as new seperate ones here as well
 */


export const getElementalDevices = ({ baseUrl, token }) => {

  const url = `${baseUrl}/devices`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getElementalDeviceById = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/devices/${params.id}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getElementalDeviceThumbnail = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/devices/${params.id}/thumbnail`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getElementalDevicesHealth = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/devices/health`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const addElementalDevice = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/devices`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const deleteElementalDevice = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/devices/${params.id}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};







/**
 * Images methods bellow
 */

export const getImages = ({ baseUrl, token }) => {

  const url = `${baseUrl}/`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getDownloadImageUrl = ({ baseUrl, token, params }) => {

  const guid = params;

  const url = `${baseUrl}/image/${guid}`;

  return url;
};

export const getImageDetails = ({ baseUrl, token, params }) => {

  const guid = params;

  const url = `${baseUrl}/${guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getImageUploadConfig = ({ baseUrl, token }) => {

  const url = `${baseUrl}/upload-multipart`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const uploadImageMeta = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/meta`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getImageApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getImageApiKey = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/api-keys/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewImageApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateImageApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteImageApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};


export const updateImagePermissions = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/images/permissions`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getImageSettings = ({ baseUrl, token }) => {

  const url = `${baseUrl}/images/settings`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateImageSettings = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/images/settings`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const getImagesElastic = ({ baseUrl, token, params }) => {

  const query = params.type ? `?${_serialize({
    ...params.type
  })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params.options)
  };

  return request({ url, options, token });
};
/**
 * END Images methods
 */









/**
 * Syndication requests
 */
export const getDistributions = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/distributors`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const updateDistribution = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/distributors/${payload.guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const getSyndicationLists = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/syndication-lists`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeSyndicationList = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/syndication-lists`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const updateSyndicationList = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/syndication-lists/${payload.guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const deleteSyndicationList = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/syndication-lists/${payload.guid}`;

  const options = {

    method: 'DELETE',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};

export const executeSyndicationOnList = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/syndication-lists/${payload.guid}/syndicate`;

  const options = {

    method: 'POST'
  };

  return request({ url, options, token });
};

export const getSyndicationAssetsForVod = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/assets/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const sendAssetToDistribution = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/vod/sources/${payload.srcGuid}/distributor/${payload.distGuid}/syndicate`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token });
};


export const getSyndicationApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getSyndicationApiKey = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/api-keys/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewSyndicationApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateSyndicationApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteSyndicationApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};



/**
 * END Syndication methods
 */






/**
 * Events methods
 */

 export const getEventsApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getEventsApiKey = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/api-keys/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewEventsApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateEventsApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteEventsApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};

export const getEvents = ({ baseUrl, token }) => {

  const url = `${baseUrl}/events`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getEventsElastic = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/search`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const getEventById = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/events/${guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewEvent = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/events`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const updateEvent = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/events/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteEvent = ({ baseUrl, token, params }) => {

  const { guid, children } = params;

  const query = children ? `?${_serialize({
    children: true
  })}` : '';

  const url = `${baseUrl}/events/${guid}${query}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};




export const getAttendees = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/events/${params.eventGuid}/attendees`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const addAttendee = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/events/${payload.eventGuid}/attendees`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const updateAttendee = ({ baseUrl, token, payload }) => {

  const { data, eventGuid, attendeeGuid } = payload;

  const url = `${baseUrl}/events/${eventGuid}/attendees/${attendeeGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteAttendee = ({ baseUrl, token, params }) => {

  const { eventGuid, attendeeGuid } = params;

  const url = `${baseUrl}/events/${eventGuid}/attendees/${attendeeGuid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};



export const exportAttendeesCsv = ({ baseUrl, token, payload }) => {

  const query = payload.params ? `?${_serialize({
    ...payload.params
  })}` : '';

  const url = `${baseUrl}/events/${payload.eventGuid}/attendees/export${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const importAttendeesCsv = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/events/${payload.eventGuid}/attendees/import`;

  const formData = new FormData();

  formData.append('file', payload.data);

  // NOTE: As per the following article, to upload files using
  // fetch and FormData, Content-Type header should NOT be set:
  // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
  const options = {

    method: 'POST',
    body: formData,
    headers: {
      formDataDeleteContentTypeHeader: true
    }
  };

  return request({ url, options, token, returnFullResponse: true });
};


export const duplicateAttendees = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/events/${payload.oldEventGuid}/attendees/duplicate`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload.data)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getMixerSettings = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/mixers/${params.eventGuid}/settings`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};


export const updateMixerSettings = ({ baseUrl, token, payload }) => {

  const { data, eventGuid } = payload;

  const url = `${baseUrl}/mixers/${eventGuid}/settings`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

/**
 * END Events methods
 */


/**
 * Statistics methods
 */
 export const getDashboardCombinedStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/cm/dashboard${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }

  };

  return request({ url, options, token });
};

export const getChannelsStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/cm/channels${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }

  };

  return request({ url, options, token });
};

export const getChannelByIdStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/cm/channel${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getVodsStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/cm/vods${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getVodByIdStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/vod${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getVodForChannelByIdStats = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
  })}` : '';

  const url = `${baseUrl}/cm/vod${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getEventViewsStats = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/event?app_id=${params.appId}&page_path=/event/${params.eventGuid}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getDashboardInsightsStats = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/hbi/${params.type}/${params.org_id}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

export const getEventReportStats = ({ baseUrl, token, params }) => {

  const query = params.query ? `?${_serialize({
    ...params.query
  })}` : '';

  const url = `${baseUrl}/er/${params.type}/${params.eventGuid}${query}`;

  const options = {

    method: 'GET',

    headers: {
      env: true
    }
  };

  return request({ url, options, token });
};

/**
 * END Statistics methods
 */


/**
 * START Ingest methods
 */

 export const getIngestDistributors = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/distributors`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};


export const updateIngestDistributors = ({ baseUrl, token, payload }) => {

  const { data, distributorGuid } = payload;

  const url = `${baseUrl}/distributors/${distributorGuid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const seedIngestDistributor = ({ baseUrl, token, payload }) => {

  const { data, distributorGuid } = payload;

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${baseUrl}/distributors/${distributorGuid}/seed${query}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const checkIngestDistributorStatus = ({ baseUrl, token, payload}) => {

  const { distributorGuid } = payload;

  const url = `${baseUrl}/distributors/${distributorGuid}/status-check`;

  const options = {

    method: 'GET',
  };

  return request({ url, options, token,  returnFullResponse: true, noDefaultError: true});
};

export const listIngestDistributorSources = ({ baseUrl, token, payload }) => {

  const  distributorGuid  = payload.distributorGuid;

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${baseUrl}/distributors/${distributorGuid}/sources${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token});
};

export const getIngestElastic = ({ baseUrl, token, params }) => {

  const query = params.query ? `?${_serialize({
    ...params.query
      })}` : '';

  const url = `${baseUrl}/search${query}`;

  const options = {

    method: 'POST',

    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const ingestDistributorsSources = ({ baseUrl, token, payload }) => {

  const { data, distributorGuid } = payload;

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${baseUrl}/distributors/${distributorGuid}/sources/ingest${query}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const getGoogleDriveIoAuth = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/distributors/google-drive/exchange-code`;

  const options = {

    method: 'POST',
    body: JSON.stringify(params)
  };

  return request({ url, options, token });
};

export const googleDriveRedirectGuid = ({ token, params }) => {

  const url = `${authUrl}/integrations/redirects`;

  const options = {

    method: 'POST',
    body: JSON.stringify(params)
  };

  return request({ url, options, token, returnFullResponse: true });
};

export const getGoogleEmail = ({ baseUrl, token, params }) => {

  const query = params ? `?${_serialize({
    ...params
      })}` : '';

  const url = `${baseUrl}${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getGoogleDriveFolders = ({ baseUrl, token, payload }) => {

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${baseUrl}/distributors/google-drive/watch-settings${query}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token});
};

export const saveGoogleDriveFolders = ({ baseUrl, token, payload }) => {

  const data = payload.data;

  const query = payload.query ? `?${_serialize({
    ...payload.query
  })}` : '';

  const url = `${baseUrl}/distributors/google-drive/watch-settings${query}`;

  const options = {

    method: 'POST',
    body: JSON.stringify(data)
  };

  return request({ url, options, token});
};

export const getIngestApiKeys = ({ baseUrl, token }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const getIngestApiKey = ({ baseUrl, token, params }) => {

  const url = `${baseUrl}/api-keys/${params.guid}`;

  const options = {

    method: 'GET'
  };

  return request({ url, options, token });
};

export const storeNewIngestApiKey = ({ baseUrl, token, payload }) => {

  const url = `${baseUrl}/api-keys`;

  const options = {

    method: 'POST',
    body: JSON.stringify(payload)
  };

  return request({ url, options, token });
};

export const updateIngestApiKey = ({ baseUrl, token, payload }) => {

  const { data, guid } = payload;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'PUT',
    body: JSON.stringify(data)
  };

  return request({ url, options, token });
};

export const deleteIngestApiKey = ({ baseUrl, token, params }) => {

  const { guid } = params;

  const url = `${baseUrl}/api-keys/${guid}`;

  const options = {

    method: 'DELETE'
  };

  return request({ url, options, token });
};
/**
 * END Ingest methods
 */
export default {

  /**
   * Organizations && Deployments methods
   */
  login,
  getTokenPermissions,
  getCognitoSettings,
  getAwsAccounts,
  updateAwsAccount,
  deleteAwsAccount,
  addAwsAccount,
  getOrganizations,
  getOrganizationDetails,
  getOrganizationBasicDetails,
  getOrganizationSocketNotifications,
  editOrganizationSocketNotifications,
  getOrganizationNotificationSubscriptions,
  addOrganizationNotification,
  deleteOrganizationNotification,
  updateOrganization,
  addOrganization,
  deleteOrganization,
  getDeployments,
  updateDeployment,
  createDeployment,
  deleteDeployment,
  getDeploymentDetails,
  getDeploymentSettings,
  getPossibleDeploymentPermissions,
  syncRolePermissionsForDeployment,
  getPossibleRoles,
  createRole,
  updateRole,
  deleteRole,
  storeNewUser,
  getUsersList,
  getUsersWhoUploadedVod,
  getVodCategories,
  getVodTags,
  getVodGroups,
  getVodGenres,
  updateUser,
  deleteUser,
  getUserData,
  sendPassword,
  getSingleUserData,


  getGitlabBranches,
  getGitlabTags,
  getGitlabTriggers,
  getGitlabJobs,
  getDeploymentPipelines,
  getOrgDeploymentPipelines,
  executeDeployScript,



  getSocketToken,
  getMultipartUploadConfigs,

  getMetaFieldGroupAttachments,
  getMetaFieldsGroups,
  getMetaFieldsByGroup,
  storeMetaFieldGroup,
  updateMetaFieldGroup,
  deleteMetaFieldGroup,
  getActivityStream,

  getSiteBuilderApps,
  getSiteBuilders,
  storeSiteBuilder,
  updateSiteBuilder,
  deleteSiteBuilder,


  /**
   * Channel Manager requests
   */
  getChannelsList,
  reorderChannelsList,
  createNewChannel,
  getChannelById,
  editChannel,
  deleteChannel,
  checkIsChannelLocked,
  getPlaylists,
  savePlaylist,
  updatePlaylist,
  deletePlaylist,
  getVodThumbs,
  getVodSignedUrl,
  getVodList,
  getSignedHlsUrl,
  getVodElastic,
  getVodDetails,
  getVodCompressedFormats,
  getAllEncodedVodFormats,
  editVodMetadata,
  editVodSlug,
  archiveVod,
  unarchiveVod,
  deleteVod,
  clipVod,
  getMediatailors,
  saveNewMediatailor,
  editMediatailor,
  deleteMediatailor,
  getListingsForChannel,
  saveNewListingForChannel,
  deleteListingForChannel,
  saveUpdateNewListingsForChannel,
  updateExistingListingForChannel,

  getSchedulesForChannel,
  saveUpdateNewSchedulesForChannel,

  uploadFile,
  uploadFileMeta,
  uploadCaptionsMeta,
  getCaptionsVttFile,
  encodeFile,
  encodeWithAutoSubs,


  getFutureStoredCaptionsGuid,
  getFileCaptionsById,
  updateFileCaptions,
  translateCaptions,

  getBrokerSettings,
  updateBrokerSettings,
  getCDNs,
  createCDN,
  deleteCDN,
  getSourcePullerJobs,
  createSourcePullerJob,
  getBrokerApiKeys,
  getBrokerApiKey,
  storeNewBrokerApiKey,
  updateBrokerApiKey,
  deleteBrokerApiKey,
  generateEpgAndDownload,
  getBrokerVocabularies,
  createBrokerVocabulary,
  updateBrokerVocabulary,
  getSingleVocabulary,
  deleteSingleVocabulary,
  getBrokerVocabularyFilters,
  createBrokerVocabularyFilter,
  updateBrokerVocabularyFilter,
  getSingleVocabularyFilter,
  deleteSingleVocabularyFilter,
  getBrokerPresets,
  updateExistingPreset,

  /**
   * Live manager requests
   */
  getRegions,
  getBlueprints,
  getBlueprintModel,
  getWorkflows,
  getWorkflowsStatsOld,
  getWorkflowsStats,
  getMultipleWorkflowsByIds,
  getWorkflowDetails,
  getWorkflowRuns,
  saveBlueprintAsWorkflow,
  deleteWorkflow,
  duplicateWorkflow,
  updateWorkflowSchedule,
  getWorkflowFilters,
  createWorkflowFilter,
  deleteWorkflowFilter,
  getChannelForWorkflow,
  getThumbnailForChannel,
  getChannelForWorkflowAlerts,
  getChannelsAlerts,
  getElementalChannelsAlerts,
  getChannelForWorkflowHealth,
  getDefaultInput,
  switchInputForWorkflow,
  getChannelWorkflowLogs,
  createChannelFromWorkflow,
  deleteLiveChannel,
  pauseLiveChannel,
  unpauseLiveChannel,
  deletePauseForChannel,
  getScheduledAds,
  scheduleAdForWorkflow,
  removeAdScheduleFromWorkflow,

  getConnectedDevices,
  getConnectedDeviceById,
  getConnectedDeviceThumbnail,
  addConnectedDevice,
  deleteConnectedDevice,
  getZenMasterSettings,
  setZenMasterSettings,

  getLiveRegions,
  getManagedInputs,
  getManagedInputById,
  storeManagedInput,
  editManagedInput,
  deleteManagedInput,

  getBlueprintForm,
  updateFeaturedBlueprints,
  getLiveApiKeys,
  storeNewLiveApiKey,
  updateLiveApiKey,
  deleteLiveApiKey,

  /**
   * Elemental Live specific requests
   */
  getElementalDevices,
  getElementalDeviceById,
  getElementalDeviceThumbnail,
  getElementalDevicesHealth,
  addElementalDevice,
  deleteElementalDevice,

  /**
   * Images requests
   */
  getImages,
  getDownloadImageUrl,
  getImageDetails,
  getImageUploadConfig,
  uploadImageMeta,


  getImageApiKeys,
  getImageApiKey,
  storeNewImageApiKey,
  updateImageApiKey,
  deleteImageApiKey,
  updateImagePermissions,
  getImageSettings,
  updateImageSettings,
  getImagesElastic,


  /**
   * Syndication requests
   */
  getDistributions,
  updateDistribution,
  getSyndicationLists,
  storeSyndicationList,
  updateSyndicationList,
  deleteSyndicationList,
  executeSyndicationOnList,
  getSyndicationAssetsForVod,
  sendAssetToDistribution,
  getSyndicationApiKeys,
  getSyndicationApiKey,
  storeNewSyndicationApiKey,
  updateSyndicationApiKey,
  deleteSyndicationApiKey,


  /**
   * Events requests
   */
  getEventsApiKeys,
  getEventsApiKey,
  storeNewEventsApiKey,
  updateEventsApiKey,
  deleteEventsApiKey,

  getEvents,
  getEventsElastic,
  getEventById,
  storeNewEvent,
  updateEvent,
  deleteEvent,

  getAttendees,
  addAttendee,
  updateAttendee,
  deleteAttendee,
  importAttendeesCsv,
  exportAttendeesCsv,
  duplicateAttendees,
  getMixerSettings,
  updateMixerSettings,


  /**
   * Statistics methods
   */
  getDashboardCombinedStats,
  getChannelsStats,
  getChannelByIdStats,
  getVodsStats,
  getVodByIdStats,
  getVodForChannelByIdStats,
  getEventViewsStats,
  getDashboardInsightsStats,
  getEventReportStats,


  /**
   * Ingest methods
   */
  getIngestDistributors,
  updateIngestDistributors,
  seedIngestDistributor,
  listIngestDistributorSources,
  checkIngestDistributorStatus,
  getIngestElastic,
  ingestDistributorsSources,
  getGoogleDriveIoAuth,
  getGoogleEmail,
  googleDriveRedirectGuid,
  getGoogleDriveFolders,
  saveGoogleDriveFolders,
  getIngestApiKeys,
  getIngestApiKey,
  storeNewIngestApiKey,
  updateIngestApiKey,
  deleteIngestApiKey,
}
