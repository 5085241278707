/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-lines': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#444"><path pid="0" data-color="color-2" d="M23 13H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1z"/><path pid="1" d="M23 6H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1zM23 20H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1z"/></g>'
  }
})
