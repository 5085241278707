<template>

  <main-nav-component
    :logoRedirectPath="DEFAULT_ADMIN_PATH"
    useAdminNavItems
    :selectedItem="selectedItem"
    :selectedSecondaryItem="selectedSecondaryItem" />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import {
  DEFAULT_ADMIN_PATH
} from "@basePath/configs/routesAuth.config";

export default {
  data: () => ({
  }),
  props: {
    selectedItem: String,
    selectedSecondaryItem: String,
  },

  created() {

    this.DEFAULT_ADMIN_PATH = DEFAULT_ADMIN_PATH;
  },

  methods: {
    ...mapMutations({

    }),
    ...mapActions({

    }),

  },
  computed: {
    ...mapGetters({

    })
  }
};
</script>

<style lang="scss" scoped>

/* header styles */
.admin-header-component {
  height: 3rem;
  background: var(--windowBgColor);
  color: var(--windowColor);
  border-bottom: 1px solid var(--windowBrColor);
  z-index: 9997;
}
</style>
