/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-cloud': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 5H12.5l-3-4H1a1 1 0 00-1 1v18a3 3 0 003 3h18a3 3 0 003-3V6a1 1 0 00-1-1zm-9.2 12.7H9a3 3 0 010-6c.36 0 .6.12.96.12A4.251 4.251 0 0113.8 9.3a4.2 4.2 0 010 8.4z" _fill="#5c7499"/>'
  }
})
