"use strict"



function importVTT ( vttFiles ) {
    let 
          filenames = Object.keys ( vttFiles )
        , result = {}
        ;

    filenames.forEach ( filename => {
                        let records = vttFiles[filename].split ( '\n\n' );
                        let vttObject = records.reduce ( (res,item,num) => { 
                                                                if ( item.startsWith('WEBVTT'))   return res
                                                                const rec = defineVTTRecord(item,num);
                                                                res.push ( rec )
                                                                return res  
                                                            },[])
                        result[filename] = vttObject
                })
    return result
} // importVTT func.





function defineVTTRecord ( rawData, num ) {
    let
          timestampRow = new RegExp (/^([\d]{1,2}:)?[\d]{1,2}:[\d]{1,2}\.[\d]{1,3} --> [\d]{1,2}.*/) 
        , recRows      = rawData.split ( '\n' )
        , label = num
        , begin
        , end
        , ttl
        , txt = ''
        , cueStyles = ''
        , id  = `${num}Cue`
        ;
    
    recRows.forEach ( (rec,i) => {
                            let 
                                  withTimestamp = timestampRow.test ( rec )
                                , times
                                ;

                            if ( i == 0 && !withTimestamp )   label = rec
                            if ( withTimestamp ) { 
                                        times     = findStartandEnd ( rec )
                                        begin     = times.begin
                                        end       = times.end
                                        ttl       = times.ttl
                                        cueStyles = times.cueStyles || ''
                                    }
                            if ( i > 0 && !withTimestamp )   txt += `${rec}\n`
                    })

    txt = txt.trimEnd ( '\n' )
    return {
                  begin
                , end
                , ttl
                , txt
                , cueStyles
                , label
                , id
    }
} // defineVTTRecord func.




function findStartandEnd ( str ) {
    const 
          timeline = new RegExp (/^(([0-9]{1,2}):)?([0-9]{1,2}):([0-9]{1,2})\.([0-9]{1,3}).?-->.?(([0-9]{1,2}):)?([0-9]{1,2}):([0-9]{1,2})\.([0-9]{1,3}) ?(.*)?/ )
        , res = str.match ( timeline )
        ;
    let 
          begin
        , end 
        , ttl   // time to live
        ;
        
    if ( !res )   return false
    
    let   [ ,, beginH, beginM, beginSec, beginMs
            ,, endH, endM, endSec, endMs
            , cueStyles ] = res;
    
    if ( !beginH )   beginH = '0'
    if ( !endH   )     endH = '0'

    // Calculate starting time and duration (ttl)
    begin = parseInt(beginSec);
    if ( beginM > 0 )   begin += parseInt(beginM)  * 60      // Convert to seconds
    if ( beginH > 0 )   begin += parseInt(beginH)  * 3600    // Convert to seconds. (60*60) 
                        begin += parseInt(beginMs) * 0.001

    end = parseInt(endSec)
    if ( endM > 0 )   end += parseInt(endM)  * 60
    if ( endH > 0 )   end += parseInt(endH)  * 3600
                      end += parseInt(endMs) * 0.001

    ttl = Math.round (end*1000 - begin*1000)/1000

    return {
              begin
            , end  
            , ttl
            , cueStyles
        }
} // findStartandEnd func.



module.exports = importVTT


