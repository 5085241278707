
const isDownloadModalOpened = (state) => state.isDownloadModalOpened;
const forModalActionVod = (state) => state.forModalActionVod;
const editedVod = (state) => state.editedVod;
const editedVodGuid = (state) => state.editedVod.guid;
const clickedVodId = (state) => state.clickedVodId;
const isMediaInfoModalOpened = (state) => state.isMediaInfoModalOpened;
const editedVodCaptions = (state) => state.editedVodCaptions;
const shouldEncodeCaptions = (state) => state.shouldEncodeCaptions

export {

  isDownloadModalOpened,
  forModalActionVod,
  editedVod,
  editedVodGuid,
  clickedVodId,
  isMediaInfoModalOpened,
  editedVodCaptions,
  shouldEncodeCaptions
}