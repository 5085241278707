<template>
<div
class="settings-panel-component pane border-left flex flex-column flex-none"
:class="activeToolbarPanel !== 'settings' ? 'hide' : 'width+3'"
>
  <div class="pane-header m0 p2 flex items-center flex-none">

    <h4 class="caps m0 size-2">
      Settings
    </h4>
    <button-component class="ml-auto flex-none disabled" variant="subtle" shape="circle">
      <ioio-icon icon="fas-ellipsis" class="h-4"/>
    </button-component>
  </div>

  <div class="pane-content flex-auto" style="overflow: auto">
    <div class="p2">

      <vue-form :state="formState" @submit.prevent="getFormValues" class="relative">

        <validate class="" tag="label">
          <span class="">Name <span>*</span></span>
          <input
            class="input"
            type="text"
            id="name"
            v-model="channelName"
            name="channelName"
            placeholder="Name"
            maxlen="100"
            required/>
          <field-messages name="channelName" show="$submitted || $dirty && $touched">
            <div slot="required">Name is a required field</div>
            <div slot="maxlen">Name length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <validate class="" tag="label">
          <span class="">Description <span>*</span></span>
          <input
            class="input"
            type="text"
            id="channelDescription"
            v-model="channelDescription"
            maxlen="500"
            name="channelDescription"
            placeholder="Description"
            required/>
          <field-messages name="channelDescription" show="$submitted || $dirty && $touched">
            <div slot="required">Description is a required field</div>
            <div slot="maxlen">Description length should not be more than 500 characters</div>

          </field-messages>
        </validate>

        <div>
          <label for="type">
            Type
            <!--<span>*</span>-->
          </label>

          <select
            class="select"
            id="type"
            v-model="channelTypeSelected"
            disabled
            name="channelType">
            <option
              :key="item.value"
              :value="item.value"
              v-for="item in channelTypes"
              >{{ item.label }}
            </option>
          </select>
        </div>

        <div>
          <label for="type">
            CDN
            <!--<span>*</span>-->
          </label>

          <select
            class="select"
            id="cdnGuid"
            v-model="cdnGuid"
            name="cdnGuid">

            <option :value="undefined"
            >Default CDN</option>

            <option
              :key="item.guid"
              :value="item.guid"
              v-for="item in availableCdns"
              >{{ item.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="isActive">
            Status
            <span>*</span>
          </label>

          <div>
            <label>
              <input type="radio" name="status" v-model="isActive" id="isActive" value="1">
              Active
            </label>
          </div>

          <div>
            <label>
              <input type="radio" name="status" v-model="isActive" id="isInactive" value="0">
              Inactive
            </label>
          </div>
        </div>

        <div>
          <label for="image-url">Image url</label>

          <input class="input" type="text" id="image-url" v-model="imageUrl" name="imageUrl" placeholder="Image url">
        </div>

        <div v-if="isExternalHLSChannelTypeSelected">

          <validate class="" tag="label">
            <span class="">External URL <span class="text-danger">*</span></span>
            <input
              type="url"
              id="externalUrl"
              placeholder="External URL"
              required
              v-model="externalUrl"
              name="externalUrl"/>
            <field-messages name="externalUrl" show="$submitted || $dirty && $touched">
              <div slot="required">External URL is a required field</div>                <div slot="url">External URL should be a valid URL</div>
            </field-messages>
          </validate>
        </div>

        <div v-if="isVirtualChannelTypeSelected">
          <label for="loopInterval">Loop interval (hours)</label>

          <select class="select" name="loopInterval" id="loopInterval" v-model="loopOptionSelected">
            <option v-for="option in loopOptions" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <div>
          <label for="hasExternalAds">
            Has external ads?
            <span>*</span>
          </label>

          <div>
            <label>
              <input
              type="radio"
              name="hasExternalAds"
              id="hasExternalAds"
              v-model="hasExternalAds"
              value="1"
              checked="checked"
              >
              Yes
            </label>
          </div>

          <div>
            <label>
              <input
              type="radio"
              name="hasExternalAds"
              id="hasNotExternalAds"
              v-model="hasExternalAds"
              value="0"
              >
              No
            </label>
          </div>
        </div>

        <div v-if="isHasExternalAdsSelected">


          <label for="mediatailors">Mediatailor</label>

          <validate class="" tag="label">
            <div class="flex items-center">
            <select
              class="select size-s m0 mr2"
              name="mediatailors"
              id="mediatailors"
              v-model="mediatailorSelected"
              required>
              <option v-for="mtailor in mediatailors" :value="mtailor.guid">{{ mtailor.name }}</option>
            </select>
          </div>
            <field-messages name="mediatailors" show="$submitted || $dirty && $touched">
              <div slot="required">Mediatailor is a required field</div>
            </field-messages>
          </validate>

        </div>

        <custom-meta-fields-legacy-component
          class="pt2 mt3 border-top"
          :editedAsset="channelMeta"
          metaResourceType="channel" />


        <channel-subs-lang-selector-component
          class="pt2 mt3 border-top"
          :subtitlesData="channelSubsLangData"
          small
          ref="subsLangSelectorEdit" />


        <ioio-dropdown
          :items="possibleTimezones"
          v-model="channelTimezone"
          valueProp="value"
          titleProp="label"
          required
          fullWidth
          label="Timezone"
          placeholder="Timezone"
          size="medium"
          class="w-full mb-8"
        />

        <footer class="flex justify-end mt2" v-if="!isEditInProgress && !isChannelDeleteInProgress">

          <button-component type="submit" variant="primary" size="size-s" class="mr2"
            :class="isEditInProgress || (formState.$submitted && formState.$invalid) ? 'disabled' : ''">Save</button-component>

          <delete-confirm-modal-component

            v-if="!isChannelDeleteInProgress"
            dialogWidth="500"
            dialogHeight="225"
            dialogOpenBtnLabel="Delete"
            dialogOpenBtnSize="size-s"
            :isDialogOpenBtnVisible="true"
            delConfirmBtnLabel="Delete"
            :delConfirmLabel="`Are you sure you want to delete channel ${channelsMeta.selected.name} ?`"
            :isDelConfirmBtnVisible="!isChannelDeleteInProgress"
            :onDeleteConfirm="() => triggerChannelDelete()"
          />

        </footer>
        <article v-else class="overflow-hidden">
          <logo-loader-component width="40px" height="22px" />
        </article>
      </vue-form>

    </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getEventsPossibleTimezones } from "@utils/helpers";

export default {
  data() {
    const vm = this;

    return {

      formState: {},

      channelName: '',
      channelDescription: '',
      channelTypeSelected: '',
      isActive: '',
      imageUrl: '',
      externalUrl: '',
      loopOptionSelected: '',
      hasExternalAds: '',
      mediatailorSelected: '',
      channelMeta: {},
      channelSubsLangData: {},
      cdnGuid: '',
      channelTimezone: '',

      channelTypes: [
        {
          label: "Media Live",

          value: "mediaLive"
        },

        {
          label: "External HLS",

          value: "externalHLS"
        },

        {
          label: "Virtual",

          value: "virtual"
        }
      ],

      loopOptions: [
        {
          label: "Without loop",

          value: 0
        },

        {
          label: "1",

          value: 1
        },

        {
          label: "3",

          value: 3
        },

        {
          label: "6",

          value: 6
        },

        {
          label: "12",

          value: 12
        },

        {
          label: "24",

          value: 24
        }
      ],

      prompts: [],

      isEditInProgress: false,

      isChannelDeleteInProgress: false,

      availableCdns: [],

      possibleTimezones: []
    };
  },
  mounted() {

    this.loadInitialEditValues();

    this.possibleTimezones = getEventsPossibleTimezones();

  },
  props: {},
  methods: {
    ...mapMutations({}),
    ...mapActions({

      makeEditChannelRequest: "channelManager/makeEditChannelRequest",
      makeDeleteChannelRequest: "channelManager/makeDeleteChannelRequest",
      makeGetMediatailorsRequest: "channelManager/makeGetMediatailorsRequest",
      getCDNs: "channelManager/getCDNs",
    }),

    triggerChannelDelete() {

      this.isChannelDeleteInProgress = true;

      const cachedForDelChannelName = this.channelsMeta.selected.name;

      this.makeDeleteChannelRequest({ guid: this.channelsMeta.selected.guid })
        .then(() => {

          this.$toasted.success(`Channel ${this.$options.filters.truncate(cachedForDelChannelName, 64, "...")} is successfully deleted.`);

          /**
           * Update the query param with the selected channel guid,
           * since it will be used and loaded if a page reload occurs
           */
          this.$router.replace({

            path: this.$route.fullPath,
            query: {
              'channel-guid': this.channelsMeta.selected.guid
            }
          });
        })
        .finally(() => {

          this.isChannelDeleteInProgress = false;
        });
    },

    getFormValues(submitEvent) {

      if(this.formState.$invalid) {

        return;
      }

      const inputs = submitEvent.target.elements;

      const params = {
        hasAds: +inputs.hasExternalAds.value,

        imageUrl: inputs.imageUrl.value,

        name: inputs.channelName.value,

        description: inputs.channelDescription.value,

        active: +this.isActive,

        cdnGuid: this.cdnGuid || '',

        timezone: this.channelTimezone
      };

      if (this.isExternalHLSChannelTypeSelected) {
        params.externalUrl = inputs.externalUrl.value;
      }

      if (this.isVirtualChannelTypeSelected) {

        params.loopInterval = +inputs.loopInterval.value;
      }

      if (this.isHasExternalAdsSelected) {
        params.mediatailor = this.mediatailorSelected;
      }

      // Check if custom meta was added to the channel
      Object.keys(this.channelMeta).length && (params.meta = this.channelMeta);

      // Check if subtitles data was added to the channel
      const subtitles  = this.$refs.subsLangSelectorEdit.gatherSubsData();

      params.subtitles = subtitles

      this.isEditInProgress = true;

      this.makeEditChannelRequest({
        guid: this.channelsMeta.selected.guid,
        params
      })
        .then(() => {

          this.$toasted.success('Your changes were saved successfully');
        })
        .finally(() => {

          this.isEditInProgress = false;
        });
    },

    loadInitialEditValues() {

      const channelData = this.channelsMeta.selected;

      this.channelName = channelData.name;
      this.channelDescription = channelData.description;
      this.channelTypeSelected = channelData.type;
      this.isActive = channelData.active;
      this.imageUrl = channelData.imageUrl;
      this.externalUrl = channelData.externalUrl || '';
      this.loopOptionSelected = channelData.loopInterval || 0;
      this.hasExternalAds = `${channelData.hasAds}`;
      this.mediatailorSelected = channelData.mediatailor || '';
      this.channelMeta = channelData.meta || {};
      this.channelSubsLangData = channelData.subtitles || {};
      channelData.timezone ? this.channelTimezone = channelData.timezone : this.channelTimezone = '';
      this.cdnGuid = channelData.cdnGuid;
    }
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      activeToolbarPanel: "channelManager/activeToolbarPanel",
      mediatailors: "channelManager/mediatailors",
    }),

    isExternalHLSChannelTypeSelected() {
      return this.channelTypeSelected === "externalHLS";
    },

    isHasExternalAdsSelected() {
      // The value is a string per BE

      return this.hasExternalAds === "1";
    },

    isVirtualChannelTypeSelected() {
      return this.channelTypeSelected === "virtual";
    },
  },

  watch: {
    channelsMeta() {

      console.log(this.channelsMeta.selected);

      this.loadInitialEditValues();
    },

    /**
     * Load the mediatailors, when the settings bar appears
     */
    activeToolbarPanel() {

      if (this.activeToolbarPanel === 'settings') {

        this.makeGetMediatailorsRequest();

        this.getCDNs()
          .then(cdns => {

            this.availableCdns = cdns;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>


.settings-panel-component {
  background: var(--panelBgColor);
  color: var(--panelColor);
}

</style>
