// theme backgrounds should be listed first
const themeBackgroundUrls = [
  { cdnPath: 'backgrounds/160.png' }, // classic
  { cdnPath: 'backgrounds/137.png' }, // showtime
  { cdnPath: 'backgrounds/143.png' }, // accent
  { cdnPath: 'backgrounds/146.png' }, // moonlight
];

// additional backgrounds should be listed later in the list
const additionalBackgroundUrls = [
  // { cdnPath: 'backgrounds/137.png' }, // showtime
  { cdnPath: 'backgrounds/140.png' },
  { cdnPath: 'backgrounds/142.png' },
  // { cdnPath: 'backgrounds/143.png' }, // accent
  // { cdnPath: 'backgrounds/146.png' }, // moonlight
  { cdnPath: 'backgrounds/147.png' },
  { cdnPath: 'backgrounds/148.png' },
  { cdnPath: 'backgrounds/149.png' },
  { cdnPath: 'backgrounds/150.png' },
  { cdnPath: 'backgrounds/151.png' },
  { cdnPath: 'backgrounds/152.png' },
  { cdnPath: 'backgrounds/153.png' },
  { cdnPath: 'backgrounds/154.png' },
  { cdnPath: 'backgrounds/155.png' },
  { cdnPath: 'backgrounds/156.png' },
  { cdnPath: 'backgrounds/157.png' },
  { cdnPath: 'backgrounds/158.png' },
  { cdnPath: 'backgrounds/159.png' },
  // { cdnPath: 'backgrounds/160.png' }, // classic
  { cdnPath: 'backgrounds/161.png' },
];

export default {
  themeBackgroundUrls,
  additionalBackgroundUrls
}