<template>
  <section class="elemental-workflow-details-container flex flex-column flex-auto pt-6 pl-6 pb-6">

    <div class="workflow-details-section flex-auto flex-column">

      <!-- TODO: refactor to ioio components
       use action-confirm-modal-component as reference
      -->
      <extend-duration-workflow-modal-component
        :duration="this.selectedWorkflowSchedule.duration"
        :isRequestPending="this.isRequestPending"
        :onEdit="this.extendScheduleForWorkflow"
        :onCancel="() => closeActionModal('extend-duration-workflow')"/>

      <edit-workflow-modal-component
        :workflow="this.selectedWorkflow"
        :onEdit="() => closeActionModal('edit-workflow')"
        :onCancel="editWorkflow"/>

      <!-- TODO: refactor to ioio components
       use action-confirm-modal-component as reference -->
      <duplicate-workflow-modal-component
        :onConfirm="openWorkflowDetails"
        :onCancel="() => {}"
        liveType="elemental"
      />

      <section class="h-full">

        <div class="flex flex-column relative h-full">

          <div
            class="h-full flex flex-column"
            :class="{ 'is-preview-collapsed': isPreviewCollapsed }"
          >

            <!-- Stream Preview -->
            <div class="">

              <section class="p-0 flex items-center justify-between">

                <section class="flex items-center flex-auto mr-16">

                  <ioio-tooltip
                    class="tooltip-z cursor-pointer"
                    :text="selectedWorkflow.name"
                    initialPostion="bottom-center"
                    style="max-width: 80%"
                  >

                    <h4 class="text-2xl text-bold truncate">{{ selectedWorkflow.name }}

                    </h4>
                  </ioio-tooltip>
                  <div class="flex items-center ml-4">

                    <ioio-label
                      :text="getReadableChannelStatus(selectedWorkflow.status).label"
                      :type="
                        getReadableChannelStatus(selectedWorkflow.status).labelType
                      "
                      :icon="getReadableChannelStatus(selectedWorkflow.status).icon"
                      size="size-s"
                    />

                    <ioio-label
                      v-if="elapsedWorkflowsRunningTime"
                      :text="elapsedWorkflowsRunningTime"
                      type="success"
                      size="size-s"
                      class="ml-2"
                    />
                  </div>
                </section>

                <section class="flex items-center">

                  <ioio-button
                    v-if="selectedWorkflow && selectedWorkflow.id"
                    class="close-preview-btn z-1 ml-8"
                    variant="minimal"
                    shape="circle"
                    size="xs"
                    @click.stop.prevent="onPreviewClose"
                  >
                    <ioio-icon icon="fa-xmark" class="w-5 h-5 ml-2"/>
                  </ioio-button>
                </section>
              </section>

              <p class="text-xs truncate mb-4">{{ selectedWorkflow.description }}</p>

              <section class="flex mb-4">

                <ioio-button
                  v-if="selectedWorkflow.status === 'READY'"
                  :disabled="!areActionsEnabled"
                  :class="{ disabled: !areActionsEnabled }"
                  class="flex mr-6"
                  variant="outline" size="small"
                  @click="
                    () =>
                      makeChannelRequest(
                        'makeCreateChannelFromWorkflowRequest',
                        this.selectedWorkflow
                      )
                  ">
                  <loader-component
                    size="size-xs"
                    variant="clean"
                    v-if="!areActionsEnabled">wait
                  </loader-component>

                  <span v-else class="flex flex-none items-center">
                    <ioio-icon
                      class="mr-2 h-2 w-2"
                      icon="fas-play"
                    />
                    Start live
                  </span>
                </ioio-button>

                <action-confirm-modal-component
                  v-else-if="isWorkflowPreviewShown && areActionsEnabled"
                  dialogWidth="500"
                  dialogHeight="225"
                  dialogOpenBtnLabel="Stop"
                  dialogOpenBtnSize="small"
                  :isDialogOpenBtnVisible="true"
                  actionConfirmBtnLabel="Stop Workflow"
                  :actionConfirmLabel="`Stopping this workflow will terminate all resources associated with it and any unmanaged inputs will be destroyed. Are you sure you want to stop workflow ${selectedWorkflow.name} ?`"
                  :isActionConfirmBtnVisible="areActionsEnabled"
                  :onActionConfirm="stopChannel"
                  dialogOpenBtnVariant="danger"
                  actionConfirmBtnVariant="danger"
                  class="mr-6"
                />

                <!-- hide edit -->
                <!-- <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  @click="() => {}"
                  class="mr-2"
                >
                  <ioio-icon
                    icon="fa-light fa-pen"
                    class="w-3 h-3"
                  />
                  Edit
                </ioio-button> -->

                <!-- <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  @click="() => copyToClipboard('PlaybackUrl')"
                  class="mr-2"
                  v-if="selectedWorkflow.status === 'RUNNING'"
                >
                  <ioio-icon
                    icon="fa-light fa-clone"
                    class="w-3 h-3"
                  />
                  Copy Playback URL
                  <input
                    class="copy-to-clipboard-input"
                    type="text"
                    id="PlaybackUrl"
                    :value="cdnPlaybackUrl"
                  />
                </ioio-button> -->

                <!-- @Stef where is SSAI -->
                <!-- Only shown for WFs that have ads - value TBD -->
                <!-- It is in a Jira story for FE work -->
                <!-- <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  @click="() => copyToClipboard('SSAI')"
                  class="mr-2"
                >
                  <ioio-icon
                    icon="fa-light fa-clone"
                    class="w-3 h-3"
                  />
                  Copy SSAI URL
                  <input
                    class="copy-to-clipboard-input"
                    type="text"
                    id="SSAI"
                    :value="selectedWorkflow.name"
                  />
                </ioio-button> -->


                <div class="details-action-open-btn mr-4">

                  <ioio-dropdown-base
                    alignX="right"
                    :fullWidth="false"
                    class="text-base sorting-picker">
                    <template slot="title">
                      <!-- <div
                        class="inline-flex items-center justify-center cursor-pointer"
                      >

                        <div class=" multiselect__tags is-small is-borderless pr-0 mr-3">Manage workflow</div>
                        <ioio-icon icon="fal-chevron-down"></ioio-icon>
                      </div> -->

                      <ioio-button
                        type="secondary"
                        variant="outline"
                        size="small"
                        class="mr-2"
                      >
                        Manage workflow
                        <ioio-icon
                          icon="fal-chevron-down"
                          class="w-3 h-3"
                        />
                      </ioio-button>
                    </template>


                    <delete-confirm-modal-component

                      dialogId="delete-workflow"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnSize="size-s"
                      delConfirmBtnLabel="Delete"
                      :delConfirmLabel="`Are you sure you want to delete workflow ${selectedWorkflow.name} ?`"
                      delConfirmSecondaryLabel="If the workflow has an archive you will not be able to access it."
                      :onDeleteConfirm="deleteWorkflow"
                      dialogNewButton
                      :isLoadingIndicatorVisible="!areActionsEnabled"
                    >

                      <ioio-button
                        class="dropdown__menu-item"
                        type="minimal"
                      >
                        <span class="red">Delete</span>

                      </ioio-button>

                    </delete-confirm-modal-component>

                    <!-- @Stef duplicate not supported on the BE -->
                    <!-- <ioio-button
                      v-for="(action, i) in workflowActions" :class="{ disabled: !action.enabledForStatus[selectedWorkflow.status]}"
                      :key="i"
                      @click="triggerWorkflowAction(
                        selectedWorkflow,
                        action.type,
                        i
                      )"
                      class="dropdown__menu-item"
                      type="minimal"
                    >
                      <span :class="action.className">{{ action.label }}</span>

                    </ioio-button> -->
                  </ioio-dropdown-base>
                </div>
              </section>

              <section
                v-if="isWorkflowPreviewShown"
                class="video-previews-box flex justify-between"
                :class="{ 'secondary-stream-active': shouldSecondaryStreamPlay }"
              >

                <div class="flex-auto">
                  <div class="live-workflows-preview-vid ratio-16-9 relative">


                    <div
                      v-show="shouldStreamPlay && thumbnailsMap[selectedWorkflow.id]?.url"
                      :style="{background: `url('${
                        thumbnailsMap[selectedWorkflow.id]?.url
                      }') no-repeat center center / cover`
                    }">
                    </div>

                    <div class="h-full w-full flex flex-column justify-center items-center">

                      <div
                        v-show="!shouldStreamPlay && !thumbnailsMap[selectedWorkflow.id]?.url"
                        class="h-full w-full flex flex-column justify-center items-center"
                      >

                        <ioio-icon class="mb-2 text-black-500 w-8 h-8" icon="fa fa-video-camera"/>
                        <h4
                          class="text-xs text-black-400">No preview available
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-auto ml-4 bg-black-200" v-if="shouldSecondaryStreamPlay">

                  <div class="live-workflows-preview-vid ratio-16-9 relative">

                    <video
                      class="absolute"
                      id="oven-video-container"
                      width="100%"
                      height="100%"
                      @click="preventVideoElPauseOnClick"
                      controls
                      poster="~@assets/SVGs/refresh.svg">
                    </video>

                    <ioio-label
                      :text="selectedOvenplayerSourceName"
                      type="normal"
                      class="m-2 absolute t-0 l-0 r-auto"
                      size="size-s"
                      style="bottom: auto; right: auto"
                    />

                    <div
                      class="h-full w-full flex flex-column justify-center items-center"
                      v-show="!shouldSecondaryStreamPlay"
                    >

                      <ioio-icon class="mb-2 text-black-500 w-8 h-8" icon="fa fa-video-camera"/>
                      <h4
                        class="text-xs text-black-400">No preview available
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
              <section
                v-else-if="selectedWorkflowFailReasonArr.length"
                class="text-xs text-red-500"
              >
                <p
                  v-for="(r, i) in selectedWorkflowFailReasonArr"
                  :key="i"
                  class="mb-1"
                >
                  {{ r }}
                </p>
              </section>
            </div>

            <ioio-tabs
              class="flex flex-auto flex-column mt-6"
              @input="setSelectedTabName"
              v-if="selectedTabName"
            >

              <ioio-button

                type="primary"
                variant="outline"
                size="small"
                class="expand-details-toggle"
                iconRight="fal-chevron-down"
                v-if="isWorkflowPreviewShown && isPreviewCollapsed"
                @click="togglePreviewCollapsed">Collapse details
              </ioio-button>
              <ioio-button

                type="primary"
                variant="outline"
                size="small"
                class="expand-details-toggle"
                iconRight="fal-chevron-up"
                v-if="isWorkflowPreviewShown && !isPreviewCollapsed"
                @click="togglePreviewCollapsed">Expand details
              </ioio-button>


              <ioio-tab
                name="Details"
                :selected="selectedTabName === 'Details'"
                icon="fa-regular fa-circle-info"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >



                <section class="flex">

                  <section class="w-1/2 pr-3">

                    <h3 class="text-lg mt-2 mb-4">Details</h3>



                    <ioio-collapsibles
                      autoclose
                      size="small"
                      v-if="selectedWorkflowSchedule.startDateTime && !areChannelDetailsLoading"
                    >
                      <ioio-collapsible>
                        <template slot="title">
                          <ioio-icon class="mr-2 mb-0.5 text-blue-500 w-5 h-5" icon="fat-calendar-check"/> Schedule
                        </template>
                        <template slot="content">
                          <section class="ds-collapsible__els">
                            <div
                              class="ds-collapsible__el"
                            >
                              <section class="flex justify-between mb-4">
                                <section class="text-xs mr-4">


                                  <div class="mb-2" v-if="selectedWorkflowSchedule.startDateTime">
                                    <ioio-icon class="mr-1 mb-0.5 text-blue-500 w-3 h-3" icon="fat-calendar-check"/>
                                    Starts:
                                    <readable-time-component
                                      :timestamp="selectedWorkflowSchedule.startDateTime"
                                    />
                                  </div>

                                  <div class="text-xs" v-if="selectedWorkflowScheduleRecurrence && selectedWorkflowScheduleRecurrence.endDate">
                                    <ioio-icon class="mr-1 mb-0.5 text-red-700 h-3 w-3" icon="fat-calendar-check"/>
                                    Schedule End Date (inclusive):
                                    <readable-time-component
                                      :timestamp="selectedWorkflowScheduleRecurrence.endDate"
                                    />
                                  </div>
                                </section>
                                <section>
                                  <section v-if="selectedWorkflowScheduleRecurrence" class="mb-2 text-xs">
                                    Recurrence: {{ selectedWorkflowParsedRecurrenceInfo }}
                                  </section>
                                  <section v-if="selectedWorkflowSchedule.duration" class="text-xs">
                                    Duration: {{ parseDuration(selectedWorkflowSchedule.duration) }}
                                  </section>
                                </section>
                              </section>

                              <aside>
                                <div v-if=!isRequestPending>
                                  <ioio-button

                                    type="danger"
                                    variant="outline"
                                    size="small"
                                    class="mr-4"
                                    @click="clearScheduleForWorkflow">Clear Schedule
                                  </ioio-button>

                                  <ioio-button
                                    type="primary"
                                    variant="outline"
                                    size="small"
                                    @click="promptExtendScheduleForWorkflow">Extend Duration
                                  </ioio-button>
                                </div>

                                <div class="mt1" v-else><loader-component height="28px"/></div>
                              </aside>
                            </div>
                          </section>
                        </template>
                      </ioio-collapsible>
                    </ioio-collapsibles>

                    <section class="flex justify-between items-start mb-5">
                      <div class="text-black-600 whitespace-nowrap mr-2">Workflow ID</div>
                      <div class="flex flex-auto">
                        <span
                          @click="copyToClipboard('selectedWorkflowId')"
                          class="url flex items-center truncate justify-end"
                        >
                          <span>{{ selectedWorkflowId }}</span>
                          <ioio-icon
                            icon="fas-clone"
                            class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                          />
                        </span>
                        <input
                          class="copy-to-clipboard-input"
                          type="text"
                          id="selectedWorkflowId"
                          :value="selectedWorkflowId"
                        >
                      </div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Blueprint ID</div>
                      <div>{{ selectedWorkflowBlueprint && this.selectedWorkflowBlueprint.id }}</div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Event ID</div>
                      <div>{{ selectedWorkflow.eventId }}</div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Description</div>
                      <div>{{ selectedWorkflow.description || 'No description' }}</div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Device</div>
                      <div>{{ devicesConfig[selectedWorkflow.deviceId]?.name }}</div>
                    </section>

                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Created</div>

                      <readable-time-component
                        :timestamp="selectedWorkflow.created"
                      />

                    </section>

                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Start requested at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.startRequestedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Started running at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.startedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Stop requested at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.stopRequestedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Stopped at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.stoppedDateTime"
                      />
                    </section>
                  </section>

<!-- @Stef no info here - hidden
                  <aside class="w-1/2 pl-3">
                    <h3 class="text-lg mt-2 mb-4">Inputs</h3>
                    <div
                      class=""
                      v-if="hasSelectedWFChannelDataInputs"
                    >

                      <section
                        v-for="(input, i) in selectedWorkflowChannelData.endpoints.input"
                        :key="i"
                        class="pb-2"
                      >

                        <section v-for="(endpoint, j) in input.endpoints" class="" :key="j">

                          <h4 class="text-base mb-2 text-black-600">{{ input.inputName }}</h4>
                          <p class="bg-black-100 mb-2 p-2 rounded">

                            <span
                              @click="copyToClipboard('copy-input-' + i + j)"
                              class="url flex items-center"><span>{{endpoint}}</span>
                              <ioio-icon
                                icon="fas-clone"
                                class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                              />
                            </span>
                            <input
                              class="copy-to-clipboard-input"
                              type="text"
                              :id="'copy-input-' + i + j"
                              :value="endpoint"
                            >
                          </p>
                        </section>

                        <section class="ml-4" v-if="input.outputs && input.outputs.length">

                          <h5 class="text-sm text-black-600 mb-2 mt-3">Output</h5>

                          <section v-for="(output, k) in input.outputs" class="flex flex-column" :key="output.url + k">

                            <p class="bg-black-100 mb-2 p-2 rounded">

                              <span
                                @click="copyToClipboard('copy-input-' + output.url + 'k')"
                                class="flex items-center">Url: <span class="url mr-3">{{ output.url}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.url + 'k'"
                                :value="output.url"
                              >
                            </p>

                            <p
                              class="bg-black-100 mb-2 p-2 rounded"
                              v-if="output.streamId"
                            >
                              <span
                                @click="copyToClipboard('copy-input-' + output.streamId + 's')"
                                class="flex items-center">Stream ID:<span class="url mr-3">{{ output.streamId}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.streamId + 's'"
                                :value="output.streamId"
                              >
                            </p>

                            <p
                              class="bg-black-100 mb-2 p-2 rounded"
                              v-if="output.remoteId"
                            >
                              <span
                                @click="copyToClipboard('copy-input-' + output.remoteId + 'r')"
                                class="flex items-center">Remote ID:<span class="url mr-3">{{ output.remoteId}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.remoteId + 'r'"
                                :value="output.remoteId">
                            </p>
                          </section>
                        </section>
                      </section>

                    </div>
                    <div class="p-4 bg-black-100 rounded mb-8" v-else>No data available at this time.</div>

                    <h3 class="text-lg mt-4 mb-4">Output Urls</h3>

                    <div class="" v-if="selectedWorkflowChannelData.endpoints &&
                      selectedWorkflowChannelData.endpoints.output &&
                      selectedWorkflowChannelData.endpoints.output.length"
                    >

                      <section v-for="(output, i) in selectedWorkflowChannelData.endpoints.output" :key="output.id">
                        <p class="bg-black-100 mb-4 p-2 rounded">
                          <span
                            @click="copyToClipboard('copy-output-' + i)"
                            class="url flex items-center"><span>{{output.url}}</span>
                            <ioio-icon
                              icon="fas-clone"
                              class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                            />
                          </span>
                          <input
                            class="copy-to-clipboard-input"
                            type="text"
                            :id="'copy-output-' + i"
                            :value="output.url">
                        </p>
                      </section>

                    </div>
                    <div class="p-4 bg-black-100 rounded" v-else>No data available at this time.</div>

                    <h3 class="text-lg mt-8 mb-4">Archive Urls</h3>

                    <div
                      class=""
                      v-if="selectedWorkflowChannelData.endpoints &&
                        selectedWorkflowChannelData.endpoints.archives && selectedWorkflowChannelData.endpoints.archives.length
                      "
                    >
                      <section
                        v-for="(archive, i) in selectedWorkflowChannelData.endpoints.archives"
                        :key="i"
                      >
                        <p class="bg-black-100 mb-4 p-2 rounded">
                          <span
                            @click="copyToClipboard('copy-archive-' + i)"
                            class="url flex items-center"
                          >
                            <span>{{archive.url}}</span>
                            <ioio-icon
                              icon="fas-clone"
                              class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                            />
                          </span>
                          <input
                            class="copy-to-clipboard-input"
                            type="text"
                            :id="'copy-archive-' + i"
                            :value="archive.url"
                          >
                        </p>
                      </section>

                    </div>

                    <div class="p-4 bg-black-100 rounded" v-else>No data available at this time.</div>
                  </aside> -->
                </section>
              </ioio-tab>

              <ioio-tab
                name="Input"
                :selected="selectedTabName === 'Input'"
                icon="fas fa-arrow-square-right"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 relative"
              >
                <h3 class="text-lg mt-2 mb-2">Input switch</h3>
                <p class="text-xs text-black-500 mb-6">Switch the input that the running channel is ingesting</p>

                <div
                  class=""
                  v-if="hasSelectedWFChannelDataInputs && areActionsEnabled"
                >

                  <ioio-radio
                    v-if="inputOptions.length"
                    class="w-full mb-4"
                    :options="inputOptions"
                    size="small"
                    v-model="mainInput"
                    @input="switchInputs"
                  />

                </div>
                <div class="p-4 bg-black-100 rounded mb-8" v-else>No data available at this time.
                </div>

                <div class="absolute z-1 w-full h-full" v-if="!areActionsEnabled">
                  <ioio-loader isShown />
                </div>
              </ioio-tab>


              <!-- @Stef More explanation for ads - will have a new story in Jira -->
              <!-- <ioio-tab
                name="Ads"
                :selected="selectedTabName === 'Ads'"
                icon="fa-regular fa-tag"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >
                <h3 class="text-lg mt-2 mb-4">Insert ad</h3>

                <p class="text-xs text-black-500 mb-2">Switch the input that the running channel is ingesting</p>

                <section class="flex">
                  <ioio-button
                    type="secondary"
                    variant="outline"
                    size="small"
                    @click="() => {}"
                    class="mr-3"
                  >15s
                  </ioio-button>
                  <ioio-button
                    type="secondary"
                    variant="outline"
                    size="small"
                    @click="() => {}"
                    class="mr-3"
                  >30s
                  </ioio-button>
                  <ioio-button
                    type="secondary"
                    variant="outline"
                    size="small"
                    @click="() => {}"
                    class="mr-3"
                  >60s
                  </ioio-button>
                  <ioio-button
                    type="secondary"
                    variant="outline"
                    size="small"
                    @click="() => {}"
                    class="mr-3"
                  >120s
                  </ioio-button>

                </section>

                some more inputs...

                <h3 class="text-lg mt-2 mb-4">Scheduled ads</h3>

              </ioio-tab> -->

              <!-- @Stef - overlay will have a new story in Jira -->
              <!-- <ioio-tab
                name="Overlays"
                :selected="selectedTabName === 'Overlays'"
                icon="fa-regular fa-image"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >
                <h3 class="text-lg mt-2 mb-4">Add overlay</h3>
                <p class="text-xs text-black-500 mb-2">Place logo, banners or any kind of branding to your workflow</p>
              </ioio-tab> -->

              <!-- <ioio-tab
                name="Statistics"
                :selected="selectedTabName === 'Statistics'"
                icon="far fa-chart-bar"
                class="text-xs text-black-600 flex flex-auto flex-column pr-2"
              >
                <h3 class="text-lg mt-2 mb-6 flex items-center justify-between"

                  >Statistics

                @Stef not supported on BE
                  <ioio-button
                    type="primary"
                    variant="outline"
                    size="small"
                    @click="makeHealthTrackingRequest"
                    v-if="selectedWorkflow.status !== 'RUNNING'"
                  >
                    <ioio-icon icon="far-rotate-right" class="h-4 mr-2" />
                    Refresh
                  </ioio-button>
                </h3>


                <div v-if="!isHealthRequestPending" class="flex flex-wrap flex-auto overflow-auto">
                  <img
                    v-for="(item, i) in selectedWorkflowChannelHealth"
                    :key="i"
                    class="ratio1-3 mb-4 px-2 cursor-pointer"
                    v-bind:src="'data:image/jpeg;base64,' + item.image"
                    @click="openStatisticsModal(i)"
                  />
                  <div
                    class="p-2 bg-black-100 rounded w-full h-full flex flex-auto items-center justify-center" v-if="!selectedWorkflowChannelHealth || !selectedWorkflowChannelHealth.length"
                  >No data available at this time.</div>
                </div>

                <div class="py-4 overflow-hidden" v-else>
                  <logo-loader-component width="50px" height="50px"/>
                </div>

                <ioio-modal
                  :show.sync="isHealthGraphModalVisible"
                  size="medium"
                  :noScroll="false"
                  headerClasses=""
                  footerClasses=""
                >
                  <img :src="selectedHealthGraph.src" />
                </ioio-modal>
              </ioio-tab> -->


              <ioio-tab
                name="Alerts"
                :selected="selectedTabName === 'Alerts'"
                icon="fa-regular fa-triangle-exclamation"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 relative"
                :badge="activeAlerts && activeAlerts.length || 0"
              >

                <div class="flex items-center justify-between mb-6 mt-2">

                  <aside class="flex">

                    <ioio-button
                      :type="selectedAlertsType === 'actual' ? 'primary': 'secondary'"
                      variant="outline"
                      size="small"
                      @click="() => changeSelectedAlertsType('actual')"
                      class="mr-2"
                    >Actual
                    </ioio-button>
                    <ioio-button
                      :type="selectedAlertsType === 'history' ? 'primary': 'secondary'"
                      variant="outline"
                      size="small"
                      @click="() => changeSelectedAlertsType('history')"
                      class="mr-3"
                    >History
                    </ioio-button>
                  </aside>
                  <ioio-button
                    type="primary"
                    variant="outline"
                    size="small"
                    @click="refreshAlertsLists">
                    <ioio-icon icon="far-rotate-right" class="h-4 mr-2" />
                    Refresh
                  </ioio-button>
                </div>

                <ioio-table
                  class="flex-auto alerts-table"
                  responsive
                  v-if="selectedWorkflowChannelVisibleAlerts && selectedWorkflowChannelVisibleAlerts.length"
                >
                  <template slot="thead">
                    <tr class="w-full flex">
                      <!-- TODO: resize cols to be as the width of the text in them and not wider. Use ref how to do it the table in the LOGS tab -->
                      <th
                        class="truncate pr-8 pointer w-40"
                        style="min-width: 160px"
                        @click="sortAlerts('time')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.time }"
                      >
                        Time
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.time" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-28"
                        style="min-width: 112px"
                        @click="sortAlerts('status')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.status }"
                      >
                        Status
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.status" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <!-- <th
                        class="truncate pr-8 pointer w-40"
                        style="min-width: 160px"
                        @click="sortAlerts('clearedAt')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.clearedAt }"
                      >
                        Cleared At
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.clearedAt" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th> -->
                      <th
                        class="truncate pr-8 pointer w-48"
                        style="min-width: 192px"
                        @click="sortAlerts('name')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.name }"
                      >
                        Name
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.name" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-28"
                        style="min-width: 112px"
                        @click="sortAlerts('code')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.code }"
                      >
                        Code
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.code" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer flex-auto"
                        style=""
                        @click="sortAlerts('message')"
                        :class="{ 'is-column-sorted-by': alertsSortedBy.message }"
                      >
                        Message
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="alertsSortedBy.message" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                    </tr>
                  </template>

                  <template
                    slot="tbody"
                  >
                    <tr
                      v-for="alert in selectedWorkflowChannelVisibleAlerts"
                      :key="alert.id"
                      class="w-full flex"
                    >
                      <td class="w-40">
                        <div class="cell-content">
                          <readable-time-component
                            :timestamp="alert.time"
                          />
                        </div>
                      </td>

                      <td class=" alerts-container w-28">
                        <span class="status" style="word-break: normal;" :class="{'active' : alert.status === 'SET'}">{{ alert.status }}</span>
                      </td>

                      <!-- <td v-if="alert.clearedAt" class="w-40">

                        <div class="cell-content">

                          <readable-time-component
                            :timestamp="alert.clearedAt"
                          />
                        </div>
                      </td>
                      <td v-else class="w-40">--</td>
                      -->
                      <td class="w-48">
                        <div class="cell-content"
                        >{{ alert.name }}
                        </div>
                      </td>
                      <td class="w-28">

                        <div class="cell-content"
                        >{{ alert.code }}
                        </div></td>
                      <td class="flex-auto w-min">

                        <div class="whitespace-normal"
                        >{{ alert.message }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </ioio-table>

                <div class="" v-else-if="!selectedWorkflowChannelVisibleAlerts || !selectedWorkflowChannelVisibleAlerts.length && !areAlertsCurrentlyLoading">No data available at this time.</div>

                <div class="absolute z-1 w-full h-full"
                  v-if="areAlertsCurrentlyLoading && !(areGeneralDetailsLoading || areChannelDetailsLoading)"
                >
                  <ioio-loader isShown />
                </div>
              </ioio-tab>


              <!-- <ioio-tab
                name="Logs"
                :selected="selectedTabName === 'Logs'"
                icon="fa fa-history"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >

                <div v-if="!areChannelLogsLoading" class="flex items-center flex-none w-full justify-between logs-label mb-6">
                  <div class="flex justify-between items-end flex-auto mt-2">
                    <div class="flex">

                      <div class="relative datepicker-overwrite-logs mr-2">
                        <ioio-field
                          class="mr-2"
                          v-model="workflowLogsParams.startDateTime"
                          type="datetime"
                          size="medium"
                        ></ioio-field>
                        <span
                          class="placeholder absolute text-sm" v-if="!workflowLogsParams.startDateTime"
                        >Start date time
                        </span>
                      </div>


                      <div class="relative datepicker-overwrite-logs mr-2">
                        <ioio-field
                          class="mr-2"
                          v-model="workflowLogsParams.endDateTime"
                          type="datetime"
                          size="medium"
                        ></ioio-field>
                        <span
                          class="placeholder absolute text-sm" v-if="!workflowLogsParams.endDateTime"
                        >End date time
                        </span>
                      </div>

                      <ioio-dropdown
                        fullWidth
                        class="w-64"
                        size="small"
                        :items="logsPerPageItems"
                        titleProp="name"
                        valueProp="id"
                        v-model="workflowLogsParams.pageSize"
                        placeholder="Logs per page"
                      />
                      <ioio-dropdown
                        fullWidth
                        class="ml-2 w-64"
                        size="small"
                        v-if="openPipelineItems"
                        :items="logsPipelineItems"
                        titleProp="name"
                        valueProp="id"
                        v-model="workflowLogsParams.pipeline"
                        placeholder="Select Pipeline"
                      />
                    </div>

                    <ioio-button
                      type="primary"
                      variant="outline"
                      size="small"
                      class="ml-8 flex-none"
                      iconLeft="far-rotate-right"
                      @click="() => getWorkflowsLogs(null)"
                    >
                      Refresh
                    </ioio-button>
                  </div>
                </div>

                <section v-if="!areChannelLogsLoading">
                  <div v-if="selectedWorkflowChannelLogs && selectedWorkflowChannelLogs.items && selectedWorkflowChannelLogs.items.length">
                    <ioio-table responsive class="logs-table" >
                      <template slot="thead">
                        <tr class="w-full flex">
                          <th class="w-40 truncate" style="min-width: 160px">Created At</th>
                          <th class="w-40 truncate" style="min-width: 160px">Type</th>
                          <th class="flex-auto truncate">Message</th>
                        </tr>
                      </template>
                      <template
                        slot="tbody"
                      >
                        <tr v-for="(log, id) in selectedWorkflowChannelLogs.items" :key="id" class="w-full flex">
                          <td class="w-40 truncate" style="min-width: 160px">
                            <readable-time-component
                              :timestamp="log.timestamp"
                              class="cell-content"
                            />
                          </td>
                          <td class="w-40 truncate" style="min-width: 160px">
                            <ioio-label
                              :text="logsSeverity[log.severity].label"
                              :type="logsSeverity[log.severity].type"
                              size="small"
                              :icon="null"
                              :isMetric="false"
                              :rounded="false"
                            />
                          </td>
                          <td class="flex-auto truncate">

                            <div class="log-msg-col z-1">{{ log.message }}</div>
                            <!-- NOTE: remove the tooltip for now - the props for line-clamp and ones for the tooltip (requires overflow-visible) can not work together -->
                            <!-- <ioio-tooltip
                              class="tooltip-z cursor-pointer"
                              :text="log.message"
                              :multiline="true"
                              initialPostion="left"
                            >
                            </ioio-tooltip> -

                          </td>
                        </tr>
                      </template>
                    </ioio-table>
                  </div>
                  <div class="p2 bg-gray+5 m2 rounded-2" v-if="!selectedWorkflowChannelLogs || !selectedWorkflowChannelLogs.items || !selectedWorkflowChannelLogs.items.length">No data available at this time.</div>
                  <div class="flex p-6">

                    <ioio-button
                      :disabled="logsRequestNumbers === 0"
                      class="mr-6"
                      iconLeft="far-angle-left"
                      type="primary"
                      size="small"
                      variant="outline"
                      @click="getWorkflowsLogs('backwardToken'), logsRequestNumbers -= 1"
                    >Prev</ioio-button>
                    <ioio-button
                      :disabled="selectedWorkflowChannelLogs.items && workflowLogsParams.pageSize != selectedWorkflowChannelLogs.items.length || !selectedWorkflowChannelLogs.items"
                      type="primary"
                      variant="outline"
                      size="small"
                      iconRight="far-angle-right"
                      @click="getWorkflowsLogs('forwardToken'), logsRequestNumbers += 1"
                    >Next</ioio-button>
                  </div>
                </section>
                <div class="relative min-h-36 mt-5" v-else>
                  <ioio-loader isShown />
                </div>

              </ioio-tab> -->
            </ioio-tabs>

          </div>

          <div
            class="min-h-36 h-full w-full flex flex-column items-center"
            v-if="areChannelDetailsLoading || areGeneralDetailsLoading"
            @click.stop.prevent=""
          >
            <ioio-loader isShown />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment-timezone";
import Hls from "hls.js";
import OvenPlayer from 'ovenplayer';

import askForPromise from "ask-for-promise";

import {
  getReadableChannelStatus,
  getLocalTimezone,
  copyElementInnerText,
  getHHMMSSFromMS
} from "@utils/helpers";


export default {
  data: () => ({

    areActionsEnabled: true,

    // TODO: continue here - remove the concept of workflowActions and use plain functions. Refactor the DupliocateWorkflow modal to use DS
    workflowActions: [{
      label: 'Duplicate',
      className: '',
      type: 'promptDuplicateWorkflow',
      enabledForStatus: {
        DONE: true,
        READY: true,
      }
    },
    // TODO: Add back once functionality is available
    // {
    //   label: 'Edit',
    //   type: 'promptEditWorkflow',
    //   enabledForStatus: { }
    // }
  ],
    alertsSortedBy: {
      time: true,
      status: true,
      clearedAt: true,
      name: true,
      type: true,
      message: true
    },

    selectedWorkflowId: '',
    selectedAlertsType: 'actual',
    healthTrackingIntervalId: null,

    workflowTabs: {

      general: {
        label: 'General'
      },
      alerts: {
        label: 'Alerts'
      },
      statistics: {
        label: 'Statistics'
      },
      blueprint: {
        label: 'Blueprint'
      },
      logs: {
        label: 'Logs'
      }
    },

    logsSeverity: {
      D: {
        label: 'Debug',
        type: 'normal'
      },
      I: {
        label: 'Information',
        type: 'normal'
      },
      W: {
        label: 'Warning',
        type: 'warning'
      },
      E: {
        label: 'Error',
        type: 'failed'
      }
    },

    workflowLogsParams: {
      startDateTime: '',
      endDateTime: '',
      pipeline: 0,
      pageSize: 200
    },

    openPipelineItems: false,
    logsRequestNumbers: 0,
    logsPipelineItems: [
      {"id":0,"name":"Pipeline 0"},
      {"id":1,"name":"Pipeline 1"}
    ],
    logsPerPageItems: [
      {"id":200,"name":"Logs per page 200"},
      {"id":300,"name":"Logs per page 300"},
      {"id":500,"name":"Logs per page 500"}
    ],

    blueprintEditor: {},

    isBlueprintForPreviewLoading: false,

    previewPromiseTask: null,

    isBlueprintSectionOpened: false,

    cdnPlaybackUrl: '',
    hls: null,
    hlsjsConfig: {

      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },
    isHlsStreamRecoveringFromError: false,

    ovenPlayerInstance: null,
    ovenPlayerConfig: {
      mute: true,
      autoStart: true,
      autoFallback: true
    },

    selectedOvenplayerSourceName: '',

    isWorkflowsListOpened: false,
    localTimezone: '',

    runningWorkflows: [],

    playbackRetryId: null,

    isRequestPending: false,

    isHealthRequestPending: false,

    selectedHealthGraph: {},
    isHealthGraphModalVisible: false,

    oldChannelAlerts: [],
    mainInput:'',
    inputOptions: [],

    selectedTabName: '',

    elapsedWorkflowsRunningTime: '',
    runTimeRefreshItervalId: null,

    isPreviewCollapsed: false,

    selectedWorkflowChannelInputs: [],

    channelDataRefreshIntervalId: null,

    thumbnailsMap: {},
  }),

  props: {
    workflowId: String,
    onPreviewClose: Function,
    stageSelectedWorkflowForPreview: Function,
    activeAlerts: Array,
    devicesConfig: Object
  },

  created() {

    // NOTE: ovenplayer seeks a global instance of the Hls library
    window.Hls = Hls;

    this.moment = moment;
    this.getReadableChannelStatus = getReadableChannelStatus;
    this.copyElementInnerText = copyElementInnerText;

    this.localTimezone = getLocalTimezone();
  },

  mounted() {

    window.w = this;

    const preselectedOpenedTab = this.$route.query.workflowSection;

    this.setSelectedTabName(preselectedOpenedTab || 'Details');

    this.setupWorkflowStats();
  },

  beforeDestroy() {


    this.setupChannelDataRefreshInterval('clear');



    this.unloadHlsPlayer();
    this.unloadOvenPlayer();

    clearInterval(this.healthTrackingIntervalId);
    this.healthTrackingIntervalId = null;

    this.setSelectedWorkflow({});
    this.setSelectedWorkflowChannelData({});
    this.setSelectedWorkflowChannelHealth({items:null});

    this.setupRunTimeRefreshIterval('reset');
  },

  methods: {

    togglePreviewCollapsed() {

      this.isPreviewCollapsed = !this.isPreviewCollapsed;

      // The minified version has no room for the controls to be usable
      this.isPreviewCollapsed ?
        this.ovenPlayerInstance?.showControls(false) : this.ovenPlayerInstance?.showControls(true);
    },

    stopChannel() {

      this.setupRunTimeRefreshIterval('reset');

      this.makeChannelRequest(
        'makeDeleteLiveChannelRequest',
        this.selectedWorkflow
      );
    },

    setupRunTimeRefreshIterval(shouldClear) {

      if (shouldClear) {

        clearInterval(this.runTimeRefreshItervalId);

        this.runTimeRefreshItervalId = null;
        this.elapsedWorkflowsRunningTime = '';
        return;
      }

      // Prevent possible leaks
      if (this.runTimeRefreshItervalId) {

        this.setupRunTimeRefreshIterval('reset');
      }

      this.runTimeRefreshItervalId = setInterval(() => {

        this.calcWorkflowRunTime();

      }, 1000);
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;

      this.$pushRoute(`/live/elemental-workflows?id=${this.workflowId}&workflowSection=${name}`);

      this.makeHealthTrackingRequest(true);
    },

    setupWorkflowStats() {

      const workflowId = this.workflowId;

      if (!workflowId) {

        // redirect to live events manager
        this.$pushRoute("/live/elemental-workflows");
      }

      this.loadWorkflowDetails(workflowId);

      this.oldChannelAlerts = this.selectedWorkflowChannelVisibleAlerts.slice();
    },

    openStatisticsModal (index) {

      let selectedGraph = this.selectedWorkflowChannelHealth[index];

      selectedGraph.src = 'data:image/jpeg;base64,' + selectedGraph.image;

      this.selectedHealthGraph = selectedGraph;

      this.isHealthGraphModalVisible = true;
    },

    clearScheduleForWorkflow() {

      this.isRequestPending = true;

      // send without data key (empty body) to clear the schedule
      this.updateWorkflowSchedule({
        workflowId: this.selectedWorkflowId,
        originalName: this.selectedWorkflow.name
      }).then(() => {

        this.loadWorkflowDetails(this.selectedWorkflowId);

      }).finally(() => {

        this.isRequestPending = false;
      });
    },

    promptExtendScheduleForWorkflow () {

      this.$modal.show("extend-duration-workflow");

    },

    extendScheduleForWorkflow (duration) {

      const newDuration = this.selectedWorkflowSchedule.duration + Number(duration);

      this.isRequestPending = true;

      this.updateWorkflowSchedule({

        workflowId: this.selectedWorkflowId,
        originalName: this.selectedWorkflow.name,

        data: {

          ...this.selectedWorkflowSchedule,
          startDateTime: this.selectedWorkflowSchedule.startDateTime,
          duration: newDuration
        }

      }).then(() => {

        this.$toasted.success('Your changes were saved successfully');

        this.loadWorkflowDetails(this.selectedWorkflowId);

        this.closeActionModal('extend-duration-workflow');

      }).finally(() => {

        this.isRequestPending = false;
      });
    },

    ...mapMutations({

      setSelectedWorkflow: "elementalLive/SET_SELECTED_WORKFLOW",
      setSelectedWorkflowChannelData: "elementalLive/SET_SELECTED_WORKFLOW_CHANNEL_DATA",
      setSelectedWorkflowChannelHealth: "elementalLive/SET_SELECTED_WORKFLOW_CHANNEL_HEALTH_DATA",
      setSelectedWorkflowChannelAlertsData: "elementalLive/SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_DATA",
      setSelectedWorkflowChannelAlertsHistoryData: "elementalLive/SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_HISTORY_DATA",
    }),
    ...mapActions({

      makeGetWorkflowDetailsRequest: "elementalLive/makeGetWorkflowDetailsRequest",
      makeGetChannelForWorkflowRequest: "elementalLive/makeGetChannelForWorkflowRequest",
      getThumnailPreviewsForChannels: "elementalLive/getThumnailPreviewsForChannels",

      makeGetChannelForWorkflowHealthRequest: "elementalLive/makeGetChannelForWorkflowHealthRequest",

      makeDeleteWorkflowRequest: "elementalLive/makeDeleteWorkflowRequest",
      makeCreateChannelFromWorkflowRequest: "elementalLive/makeCreateChannelFromWorkflowRequest",
      makeDeleteLiveChannelRequest: "elementalLive/makeDeleteLiveChannelRequest",

      makeGetBlueprintModelRequest: "elementalLive/makeGetBlueprintModelRequest",
      getWorkflows: "elementalLive/makeGetWorkflowsRequest",
      getBlueprintForm: "elementalLive/getBlueprintForm",
      updateWorkflowSchedule: 'elementalLive/updateWorkflowSchedule',
      getChannelWorkflowLogs: 'elementalLive/getChannelWorkflowLogs',
      switchInputForWorkflow: 'elementalLive/switchInputForWorkflow',
      getDefaultInput: 'elementalLive/getDefaultInput',
      makeGetChannelForWorkflowAlertsRequest: "elementalLive/makeGetChannelForWorkflowAlertsRequest",
      makeGetChannelForWorkflowAlertsHistoryRequest: "elementalLive/makeGetChannelForWorkflowAlertsHistoryRequest",
    }),

    parseDuration(s) {

      const calcedDurationInMS = s * 1000;

      return getHHMMSSFromMS(calcedDurationInMS);
    },

    unloadOvenPlayer() {

      this.ovenPlayerInstance?.remove();
      this.ovenPlayerInstance = null;
    },

    unloadHlsPlayer() {

      if (this.hls) {

        this.hls.off(Hls.Events.ERROR, this.onHlsError);

        this.hls.stopLoad();
        this.hls.destroy();
        this.hls = null;
      }

      this.clearPlaybackRetry();
    },

    preventVideoElPauseOnClick(e) {

      e.preventDefault();
    },

    loadWorkflowDetails(workflowId) {

      this.cacheSelectedWorkflowId(workflowId);

      this.setupRunTimeRefreshIterval('reset');

      this.makeGetWorkflowDetailsRequest(this.selectedWorkflowId)
        .then(() => {

          this.getWorkflowsLogs();

          // if ( this.selectedWorkflow.blueprint.settings.channel.channelClass === "STANDARD") {

          //   this.openPipelineItems = true;

          // }
        });

      this.refreshChannelData(this.selectedWorkflowId);
    },

    refreshChannelData(workflowId) {

      this.setSelectedWorkflowChannelAlertsData([]);
      this.setSelectedWorkflowChannelAlertsHistoryData([]);

      this.selectedWorkflowChannelInputs = [];

      return this.makeGetChannelForWorkflowRequest(workflowId)
        .then(() => {

          if (this.selectedWorkflowChannelData.status === "RUNNING") {

            this.areActionsEnabled = false;

            this.setupRunTimeRefreshIterval();

            this.getDefaultInput({ id : this.workflowId })
              .then((resp) => {

                this.mainInput = resp.activeInputName;

                this.selectedWorkflowChannelInputs = [...resp.inputs];

                this.areActionsEnabled = true;
              });
          };

          if (this.selectedWorkflowChannelData.startedDateTime) {

            this.initChannelHealthTracking();

            this.workflowLogsParams.startDateTime = this.selectedWorkflowChannelData.startedDateTime;

            this.workflowLogsParams.endDateTime = this.selectedWorkflowChannelData.stoppedDateTime;
          };
        });
    },

    calcWorkflowRunTime() {

      const elapsedTime = moment().valueOf() - moment.tz(this.selectedWorkflowChannelData.startedDateTime, this.localTimezone).valueOf();

      this.elapsedWorkflowsRunningTime = getHHMMSSFromMS(elapsedTime, 'returnFullHour');
    },

    getWorkflowsLogs(token = null) {
// @Stef not working
return
      const params = {

        id: this.selectedWorkflowId,
      };

      let query = {...this.workflowLogsParams};

      query.startDateTime =  this.workflowLogsParams.startDateTime.slice(0,-1);

      this.workflowLogsParams.endDateTime ? query.endDateTime =  this.workflowLogsParams.endDateTime.slice(0,-1) :  query.endDateTime = '';

      params.query = {...query};

      token ? params.query[token] = this.selectedWorkflowChannelLogs[token] : this.logsRequestNumbers = 0;

      this.getChannelWorkflowLogs(params);
    },
    toggleWorkflowsListOpened(newVal) {

      this.isWorkflowsListOpened = newVal;

      if (newVal) {

        this.getWorkflows()
          .then(() => {

            this.runningWorkflows =
              this.workflowsList.filter(w => w.status === 'RUNNING');
          });

      } else {

        this.runningWorkflows = [];
      }
    },

    openWorkflowDetails(id) {

      this.setSelectedWorkflow({});
      this.setSelectedWorkflowChannelData({});
      this.setSelectedWorkflowChannelHealth({items:null});

      this.stageSelectedWorkflowForPreview({ id });

      this.loadWorkflowDetails(id);
    },

    copyToClipboard(elementId) {

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    closeBlueprintPreview() {

      this.isBlueprintSectionOpened = false;
    },

    openBlueprintPreview() {

      this.isBlueprintSectionOpened = true;

      const blueprintId = this.selectedWorkflowBlueprint.id;

      this.isBlueprintForPreviewLoading = true;

      this.makeGetBlueprintModelRequest(blueprintId).then(() => {

        this.isBlueprintForPreviewLoading = false;

        this.previewPromiseTask = askForPromise();

        const params = {

          orgGuid: this.organizationSelected.guid,
          blueprintId: this.selectedBlueprintModel.id
        };

        this.getBlueprintForm(params).then((form) => {

          this.blueprintEditor({
            task: this.previewPromiseTask,
            containerID: "blueprint-preview",
            data: this.selectedBlueprintModel,
            mode: "svg",
            form
          });
        });
      });
    },

    triggerWorkflowAction(workflow, actionType, actionIndex) {

      if (!this.workflowActions[actionIndex].enabledForStatus[workflow.status]) {

        return;
      }

      this[actionType](workflow);
    },

    closeActionModal(type) {

      this.$modal.hide(type);
    },

    deleteWorkflow() {

      this.areActionsEnabled = false;

      this.makeDeleteWorkflowRequest(this.selectedWorkflow).then(() => {

        // redirect
        this.$pushRoute('/live/elemental-workflows');

        // close the preview
        this.onPreviewClose();
      })
      .finally(() => {

        this.areActionsEnabled = true;
      });
    },

    editWorkflow() {
      console.log('Edit:', this.selectedWorkflow);

      this.closeActionModal('edit-workflow');
    },

    promptEditWorkflow(workflow) {
      console.log('Edit:', workflow.name);

      return; // TODO: Delete when EDIT is supported

      this.$modal.show("edit-workflow", workflow);
    },

    promptDuplicateWorkflow(workflow) {

      this.$modal.show("duplicate-workflow", workflow);
    },

    makeChannelRequest(requestType, workflow) {

      this.areActionsEnabled = false;

      return this[requestType](workflow)
        .then(() => {

          this.makeGetWorkflowDetailsRequest(this.selectedWorkflowId).then(() => {

            this.areActionsEnabled = true;
          });

          this.refreshChannelData(this.selectedWorkflowId);
        });
    },

    cacheSelectedWorkflowId(id) {

      this.selectedWorkflowId = id;
    },

    changeSelectedAlertsType(type) {

      this.selectedAlertsType = type;

      type === 'actual' ? this.setSelectedWorkflowChannelAlertsHistoryData(this.oldChannelAlerts) : this.setSelectedWorkflowChannelAlertsData(this.oldChannelAlerts);

      this.alertsSortedBy = {
        time: true,
        status: true,
        clearedAt: true,
        name: true,
        type: true,
        message: true
      }

      this.oldChannelAlerts = this.selectedWorkflowChannelVisibleAlerts.slice();

    },

    refreshAlertsLists() {

      this.makeGetChannelForWorkflowAlertsRequest(this.selectedWorkflowId);

      // this.makeGetChannelForWorkflowAlertsHistoryRequest(this.selectedWorkflowId);
    },

    makeHealthTrackingRequest(initially = false) {

      if (this.selectedTabName !== 'Statistics' || !this.selectedWorkflowId) {

        return;
      }

      if (initially) {

        this.isHealthRequestPending = true;
      }

      this.makeGetChannelForWorkflowHealthRequest(this.selectedWorkflowId).then(() => {

        this.isHealthRequestPending = false;
      });
    },

    initChannelHealthTracking() {
      // @Stef no health for now
return;
      this.makeHealthTrackingRequest(true);

      clearInterval(this.healthTrackingIntervalId);
      this.healthTrackingIntervalId = null;

      this.healthTrackingIntervalId = setInterval(() => this.makeHealthTrackingRequest(), 10000);
    },

    clearPlaybackRetry() {

      clearInterval(this.playbackRetryId);
      this.playbackRetryId = null;
    },

    onHlsError(event, data) {

      if (data.type === 'networkError' && data.response) {

        if (data.response.code === 504) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.clearPlaybackRetry();
        }

        if (data.response.code >= 400 && data.response.code <= 499) {

          this.setupRetryPlaybackIfApplicable();

          this.isHlsStreamRecoveringFromError = true;
        }

      } else {

        // Log the error for debugging
        console.error('onHlsError', event, data);
      }
    },

    setupRetryPlaybackIfApplicable() {

      if (this.playbackRetryId) {

        return;
      }

      this.playbackRetryId = setInterval(() => {

        this.loadHlsVidPlayback();
      }, 5000);
    },

    loadHlsVidPlayback() {

      // NOTE: this fn is not used for elemental

      if (Hls.isSupported()) {

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }

        if (!this.cdnPlaybackUrl) {

          // this.hls.off(Hls.Events.ERROR, this.onHlsError);

          return;
        }


        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });

        const video = this.$refs.previewVid;

        this.hls.attachMedia(video);

        if (this.isHlsStreamRecoveringFromError) {

          // set this flag in the error handler when 400-499 enters
          console.log('RECOVERS~')

          this.hls.startLoad();
          this.isHlsStreamRecoveringFromError = false;
        }

        console.log("try to loadHlsVidPlayback", this.shouldStreamPlay,video);

        this.hls.on(Hls.Events.MEDIA_ATTACHED, (e, d) => {

          console.log("video and hls.js are now bound together !", e, d);

          this.hls.loadSource(this.cdnPlaybackUrl);
        });

        this.hls.once(Hls.Events.LEVEL_LOADED, (event, data) => {

          console.log("LEVEL_LOADED ", event, data);
          console.log(111, data.details.live)

          // Handle the edge case where a stale stream will be played as a VOD.
          // The player should retry until a valid LIVE stream appears or
          // another error is found.
          if (data.details.live === false) {

            // Leave for debuggind -> if this log is present, but shouldn't be check the
            // this.hls.once(Hls.Events.LEVEL_LOADED handler. For the stale VOD logic to work,
            // the `data.details.live` prop should only be false for STALE VODS and not anything else.
            console.log('STALE stream -> return');

            this.setupRetryPlaybackIfApplicable();

            this.hls.detachMedia();

            return;
          }

          this.hls.media.muted = true;
          this.hls.media.play();

          if (this.playbackRetryId) {

            this.clearPlaybackRetry();
          }
        });


        // NOTE: this is the recommended hook when one should play the stream. Since
        // we have additional logic for stale streams, we are using LEVEL_LOADED.
        // Change it back if it's not working as expected.
        this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {

          console.log("MANIFEST_PARSED " + data.levels && data.levels.length + " quality level", event, data);
        });


        this.hls.on(Hls.Events.ERROR, this.onHlsError);

        // Some browsers pause the video if PiP is closed. Prevent it
        video && video.addEventListener('leavepictureinpicture', (e) => {

          setTimeout(() => {

            video.play();
          });
        });
      }
    },

    loadOvenPlayer() {

      if (!this.isWorkflowPreviewShown || !this.shouldSecondaryStreamPlay) {

        return;
      }

      this.unloadOvenPlayer();

      // NOTE: timeout is needed for the #oven-video-container UI element
      // to appear in the DOM when conditions to play the stream change to true
      setTimeout(() => {

        this.ovenPlayerInstance = OvenPlayer.create('oven-video-container', {

          ...this.ovenPlayerConfig,

          hlsConfig: this.hlsConfig,

          sources: this.ovenPlayerSources
        });

        // this.ovenPlayerInstance.on('ready', () => {

        // });

        this.ovenPlayerInstance.on('sourceChanged', (info) => {

          this.setSelectedOvenplayerSourceName(info.currentSource);
        });

        this.ovenPlayerInstance.on('error', (err) => {
          console.error('Oven player err: ', err)
        });
      });
    },

    setSelectedOvenplayerSourceName(index) {

      this.selectedOvenplayerSourceName = this.ovenPlayerSources.length ?
        this.ovenPlayerSources[index]?.label : '';
    },

    sortAlerts(type) {

      this.alertsSortedBy[type] = !this.alertsSortedBy[type];

      const sortedChannelAlerts = [...this.selectedWorkflowChannelVisibleAlerts];

      if (type === 'status' || type === 'code' || type === 'message'){

        this.alertsSortedBy[type] ? sortedChannelAlerts.alertsSortedBy((a,b) => b[type].localeCompare(a[type])) : sortedChannelAlerts.alertsSortedBy((a,b) => a[type].localeCompare(b[type]));

      } else if (type === 'time'){

        this.alertsSortedBy[type] ? sortedChannelAlerts.alertsSortedBy((a,b) => moment(b[type]).valueOf() - moment(a[type]).valueOf()) : sortedChannelAlerts.alertsSortedBy((a,b) => moment(a[type]).valueOf() - moment(b[type]).valueOf());

      } else {

        this.alertsSortedBy[type] ? sortedChannelAlerts.alertsSortedBy((a,b) => b[type] - a[type]) : sortedChannelAlerts.alertsSortedBy((a,b) => a[type] - b[type]);

      }

      this.selectedAlertsType === 'actual' ? this.setSelectedWorkflowChannelAlertsData(sortedChannelAlerts) : this.setSelectedWorkflowChannelAlertsHistoryData(sortedChannelAlerts);

    },

    switchInputs(inputName) {

      console.log(this.mainInput, inputName)

      let params = {};

      params.id = this.selectedWorkflowId;

      params.body = {

        "inputName": inputName
      };

      this.areActionsEnabled = false;

      this.switchInputForWorkflow(params)
        .then(() => {

          this.$toasted.success(`Switching to ${inputName} has been successful. Please note your preview may not be real time and it may take some time for the new input to appear.`);

          // The BE has a limitiation and needs 5s
          setTimeout(() => {

            this.areActionsEnabled = true;
          }, 3000);
        })
        .catch(() => {

        });
    },

    setupChannelDataRefreshInterval(shouldClear) {

      if (shouldClear || this.channelDataRefreshIntervalId) {

        clearInterval(this.channelDataRefreshIntervalId);
        this.channelDataRefreshIntervalId = null;

        return;
      }

      this.channelDataRefreshIntervalId = setInterval(() => {

        this.getThumnailPreviewsForChannels([this.selectedWorkflowId])
          .then((resp) => {

            this.thumbnailsMap = {
              ...resp
            };
          });
      }, 2000);
    },
  },
  computed: {

    ...mapGetters({

      selectedWorkflow: "elementalLive/selectedWorkflowForPreview",
      selectedWorkflowChannelData: "elementalLive/selectedWorkflowChannelData",
      selectedWorkflowChannelAlerts: "elementalLive/selectedWorkflowChannelAlerts",
      selectedWorkflowChannelLogs: "elementalLive/selectedWorkflowChannelLogs",
      selectedWorkflowChannelAlertsHistory: "elementalLive/selectedWorkflowChannelAlertsHistory",
      selectedWorkflowChannelHealth: "elementalLive/selectedWorkflowChannelHealth",
      areGeneralDetailsLoading: 'elementalLive/areGeneralDetailsLoading',
      areChannelDetailsLoading: 'elementalLive/areChannelDetailsLoading',
      areChannelLogsLoading: 'elementalLive/areChannelLogsLoading',
      areAlertsCurrentlyLoading: 'elementalLive/areAlertsCurrentlyLoading',
      selectedBlueprintModel: "elementalLive/selectedBlueprintModel",
      workflowsList: "elementalLive/workflowsList",
      isWorkflowsListLoading: "elementalLive/isWorkflowsListLoading",
      organizationSelected: "app/organizationSelected",
      msgBusMsgList: 'app/msgBusMsgList',
    }),

    selectedWorkflowFailReasonArr() {

      return this.selectedWorkflowChannelData && this.selectedWorkflowChannelData.failReason ?
        this.selectedWorkflowChannelData.failReason.split(';') : [];
    },

    hasSelectedWFChannelDataInputs() {

      return this.selectedWorkflowChannelInputs.length;
    },

    shouldStreamPlay() {

      return this.selectedWorkflowChannelData?.thumbnail && this.selectedWorkflow.status === 'RUNNING';
    },

    shouldSecondaryStreamPlay() {

      return this.ovenPlayerSources.length;
    },

    ovenPlayerSources() {

      const sources = [];

      const endpointOutputs = this.selectedWorkflowChannelData?.endpoints?.output || [];

      endpointOutputs.forEach(o => {

        if (o.type === 'webrtc') {

          sources.push({
            label: 'WEBRTC',
            type: 'webrtc',
            file: o.url
          });
        }

        if (o.type === 'llhls') {

          sources.push({
            label: 'LLHLS',
            type: 'll-hls',
            file: o.url
          });
        }
      });

      return sources;
    },

    selectedWorkflowChannelStatus() {

      return this.getReadableChannelStatus(this.selectedWorkflowChannelData.status);
    },

    selectedWorkflowBlueprint() {
      return this.selectedWorkflow ? this.selectedWorkflow.blueprint : {};
    },
    selectedWorkflowSchedule() {
      return this.selectedWorkflow && this.selectedWorkflow.schedule
        ? this.selectedWorkflow.schedule
        : {};
    },

    selectedWorkflowScheduleRecurrence() {

      return this.selectedWorkflowSchedule.recurrence;
    },

    selectedWorkflowParsedRecurrenceInfo() {

      if (this.selectedWorkflowScheduleRecurrence && this.selectedWorkflowScheduleRecurrence.days) {

        return this.selectedWorkflowScheduleRecurrence.days.join(',');

      } else if (this.selectedWorkflowScheduleRecurrence && (
        this.selectedWorkflowScheduleRecurrence.unit === null ||
        this.selectedWorkflowScheduleRecurrence.unit === 'DAY')) {

        return 'Every Day';
      }
    },

    selectedWorkflowChannelVisibleAlerts() {

      return this.selectedAlertsType === 'actual' ?
        this.selectedWorkflowChannelAlerts : this.selectedWorkflowChannelAlertsHistory;
    },

    hasWorkflowEverRun() {

      return this.selectedWorkflow.status !== 'READY';
    },

    isWorkflowPreviewShown() {
      return this.selectedWorkflow.status === 'RUNNING' || this.selectedWorkflow.status === 'PAUSED';
    }
  },

  watch: {

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];
      const affectedWorkflowId = newestMsg.data.resourceId;

      // The data needs updating
      if (
        (newestMsg.type === 'channel_status_changed' ||
        newestMsg.type === 'CHANNEL_STATUS_CHANGED') &&
        affectedWorkflowId === this.selectedWorkflowId
      ) {

        this.refreshChannelData(this.selectedWorkflowId);
      }
    },

    activeAlerts() {

      if (this.activeAlerts?.length) {

        // The workflowId used in refreshAlertsLists needs time to change
        setTimeout(() => {

          this.refreshAlertsLists();

        }, 300);
      }
    },

    hasSelectedWFChannelDataInputs() {

      if (!this.hasSelectedWFChannelDataInputs) {

        return;
      }

      const inputOptions = [];

      for (let i = 0; i < this.selectedWorkflowChannelInputs.length; i++) {

        const input = this.selectedWorkflowChannelInputs[i];

        const currentFormat = {

          value: input,
          label: input || 'Unnamed input',
          disclaimer: input || ''
        };

        inputOptions.push(currentFormat);
      }

      this.inputOptions = [ ...inputOptions ];
    },

    workflowId() {

      this.setupWorkflowStats();
      //NOTE: close the bp here if it was opened, so that it can be reloaded with the one for the new WF
      this.closeBlueprintPreview();

      this.setSelectedWorkflowChannelHealth({ items: null });
    },

    // selectedWorkflowChannelData(newVal) {

      // if (Object.hasOwn(newVal, 'items')) {

      //   this.refreshChannelData(this.workflowId);
      //   return;
      // }

      // let newCdnUrl = '';

      // if (newVal && newVal.endpoints && newVal.endpoints.output && newVal.endpoints.output[0]) {

      //   const cdnUrl = newVal.endpoints.output[0].url;

      //   if (cdnUrl.includes('http')) {

      //     newCdnUrl = cdnUrl;
      //   }
      // }

      // this.cdnPlaybackUrl = newCdnUrl;

      // this.loadHlsVidPlayback();

      // this.loadOvenPlayer();
    // },

    shouldStreamPlay(shouldPlay) {

      if (shouldPlay) {

        // On initial change of a channel to RUNNING status,
        // the interval should be triggered manually from here

        this.setupChannelDataRefreshInterval();

      } else {

        this.setupChannelDataRefreshInterval('clear');
      }
    },

    shouldSecondaryStreamPlay(shouldPlay) {

      if (shouldPlay) {

        this.loadOvenPlayer();

      } else {

        this.unloadOvenPlayer();
      }
    }
  }

};
</script>

<style lang="scss" >
  /**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";

.elemental-workflow-details-container {

  .datepicker-overwrite-logs {

    width: 160px;
    position: relative;

    .ds-fields__input.is-medium {

      font-size: 12px !important;
      color: black;
      font-weight: 500;
    }

    .form-control.datepicker.input.ds-fields__input.is-medium {

      height: 32px;
      min-height: auto;
      padding-left: 8px;
    }

    .form-control.datepicker.input.ds-fields__input {

      box-shadow: none;
      outline: none;
    }

    .placeholder {

      top: 6px;
      left: 8px;
      width: 100%;
      pointer-events: none;
    }

    .flatpickr-wrapper~svg {

      z-index: 1;
      background: white;
      padding: 8px;
      right: 2px;
    }
  }

  .ds-collapsible {

    .ds-collapsible__header, .ds-collapsible__body {
      padding-left: 0;
    }


    .ds-collapsible__icon {

      font-size: 20px;
    }
  }

  .alerts-table, .logs-table {

    @apply
    border
    border-black-200
    px-0
    rounded;

    thead th {

      @apply
      px-4
      py-1.5;

      min-width: auto;
    }

    td {

      @apply
      text-sm
      pr-8;

      min-width: auto;

      .cell-content {

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;


        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .ds-tabs {

    position: relative;
  }

  .ds-tabs__nav {

    min-height: 41px;
  }

  th, label {
    @apply
    font-medium;
  }

  .ratio-16-9 {
    width: 100%;
    padding-bottom: 56.25%;
  }
  // @include overwrite-styleberry-styles-htags-font-size;
  // @include overwrite-styleberry-styles-htags-margin;

//  .overwrite-styleberry-styles-htags-margin {
//     margin-top: 1em;
//     margin-bottom: 0.5em;
//   }

  .size0 {
    font-size: 16px;
    font-size: 1rem;
  }

  .size-1 {
    font-size: calc(1rem - .1618vmin);
  }

  .size\+1 {
    font-size: calc(1rem + .4854vmin);
  }

  .m-0 {
    margin: 0px;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  /********************************END**********/



  // background: var(--sceneBgColor);
  height: calc(100vh - 64px);
  overflow: auto;

  .disabled {

    cursor: not-allowed;
  }

  .mr-0125 { margin-right: .125rem;}
  .mb-0125 { margin-bottom: .125rem;}

  .pane-header .btn.tab {

    min-width: 20%;

    &:not(.active) {

      box-shadow: none;
    }
  }

  // .details-action-open-btn {

  //   position: absolute;
  //   top: 5px;
  //   right: 0;
  // }

  .workflows-list .dropdown-content {

    width: 600px;
    max-height: 600px;
    overflow: auto;
  }

  .text-plain {
    font-weight: 400;
  }

  .alerts-container {

    .status {

      color: green;

      &.active {

        color: red;
      }
    }
  }

  .stream-name-col {

    word-break: break-all;
  }

  .url {

    color: var(--color-blue-600);
    margin-left: 0.25rem;

    span {

      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 12px;
    }
  }

  .copy-to-clipboard-input {
    /**
    * Hide the visibility via z-index, since hiding it in
    * any other way will make the copy to clipboard not work.
    */
    position: fixed;
    z-index: -1;
  }

  .blueprint-preview > div {

    width: 100%;
    height: 100%;
  }

  .blueprint-render-container {

    width: 100%;
    height: 450px;
  }

  .blueprint-hide {

    overflow: hidden;
    opacity: 0;
  }

  .blueprint-loader {

    position: absolute;
  }

  .live-workflows-preview-vid {

    @apply
    bg-red-50
    rounded;

    .tags-section {

      position: absolute;
      top: 0;
      left: 0;
    }

    video {
      height: 100%;
    }

    .op-wrapper.ovenplayer {

      position: absolute;
    }

    video::-webkit-media-controls-timeline {
      display: none;
    }

    video::-webkit-media-controls-time-remaining-display {
      display: none;
    }

    // video::-webkit-media-controls-current-time-display {
    //   display: none;
    // }
    video::-webkit-media-controls-play-button {
      display: none;
    }

    video::-webkit-media-controls-overlay-play-button {
      display: none;
    }
  }

  .logs-label {
    label {
      @apply
      m-0
      pb-2;
    }
  }

  .width-40 {
    width: 40rem;
  }

  .tooltip-z {
    z-index: 1;
  }

  .table {

    tbody {
      display: block;
      max-height: 250px;
      overflow: auto;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }
  }

  .input-margin-right {
    margin-right: 0.5rem;
  }

  .expand-details-toggle {

    position: absolute;
    right: 16px;
    top: 0;
  }

  .video-previews-box {

    width: 50%;

    &.secondary-stream-active {
      width: 100%;
    }
  }

  .is-preview-collapsed {

    position: relative;

    .video-previews-box {

      position: absolute;
      top: 0;
      right: 56px;
      width: 178px;

      &.secondary-stream-active {
        width: 356px;
      }
    }
  }

  .log-msg-col {

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: break-spaces;
  }
}
</style>
