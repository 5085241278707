/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-gear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.872 13.453A9.033 9.033 0 0021 12a9.033 9.033 0 00-.128-1.453l2.1-2.029a1 1 0 00.171-1.218l-1.5-2.6a1.009 1.009 0 00-1.143-.461l-2.8.8a9.017 9.017 0 00-2.527-1.451L14.47.758A1 1 0 0013.5 0h-3a1 1 0 00-.97.758l-.707 2.83A9.017 9.017 0 006.3 5.039l-2.8-.8a1.01 1.01 0 00-1.143.461l-1.5 2.6a1 1 0 00.171 1.219l2.1 2.029A9.033 9.033 0 003 12a9.033 9.033 0 00.128 1.453l-2.1 2.029A1 1 0 00.857 16.7l1.5 2.6a1 1 0 00.867.5 1.048 1.048 0 00.275-.038l2.8-.8a9.017 9.017 0 002.527 1.451l.707 2.83A1 1 0 0010.5 24h3a1 1 0 00.97-.758l.707-2.83a9.017 9.017 0 002.523-1.451l2.8.8a1.048 1.048 0 00.275.038 1 1 0 00.867-.5l1.5-2.6a1 1 0 00-.171-1.219zM12 16a4 4 0 114-4 4 4 0 01-4 4z" _fill="#5c7499"/>'
  }
})
