/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'data-cloud': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3a1 1 0 00-1 1v22a1 1 0 001 1h18a1 1 0 001-1V1a1 1 0 00-1-1zm-7.5 15h-4a2.5 2.5 0 11.776-4.865A3.5 3.5 0 1113.5 15z" _fill="#2d3857"/>'
  }
})
