<template>

<ioio-modal

  :show.sync="isModalOpened"
  size="large"
  :noScroll="false"
  headerClasses=""
  footerClasses=""
  class="assign-videos"
>
  <template slot="header">
    <div class="assign-videos__title">Add Videos</div>

    <div class="mt-5">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
        class=""
      />
    </div>
  </template>

  <section class="assign-videos__content">

    <ul
      class="assign-videos__list"
      v-if="!isRequestPending && filteredVodList.length"
      ref="libraryScrollArea"
    >
      <li
        class="assign-videos__list-el"
        :key="vod.guid"
        v-for="vod in filteredVodList"
      >
        <div class="flex items-center">
          <img
            :src="vod.meta.imageUrl"
            class="assign-videos__list-el-image"
          />
          <div class="assign-videos__list-el-text">
            {{ vod.meta.title }}
            <span>{{ moment(vod.createdAt).format('MMM Do YYYY h:mma z Z') }}</span>
            <span>{{ parseDuration(vod.srcDuration) }}</span>
          </div>
        </div>

        <ioio-button
          type="secondary"
          variant="outline"
          v-if="!addedGuidsMap[vod.guid]"
          @click.stop.prevent="toggleAddVodToList(vod, true)"
        >
          Select
        </ioio-button>
        <ioio-button
          type="primary"
          variant="outline"
          v-else
          @click.stop.prevent="toggleAddVodToList(vod, false)"
        >
          Deselect
        </ioio-button>

      </li>
    </ul>

    <p
      class="assign-videos__nodata"
      v-else-if="!isRequestPending && !filteredVodList.length"
    >
      No videos found
    </p>

    <div v-else style="overflow:hidden" class="flex items-center flex-auto">
      <ioio-loader isShown></ioio-loader>
    </div>

    <footer class="flex items-center justify-center mt-4">

      <pagination-component
        class="flex-none"
        :page-selected="vodSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="vodSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </footer>
  </section>

  <template slot="footer">
    <footer
      class="flex justify-between mt-4"
      v-if="!isRequestPending && filteredVodList.length"
    >
      <div class="flex">
        <div class="mr-4 w-60 text-black-500">
          To add new media, you need to upload it first in Library.
        </div>

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="navigate('/images-library')"
        >
          Go to Library
        </ioio-button>
      </div>
      <div>
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelAssignVods"
        >
          Cancel
        </ioio-button>

        <ioio-button
          @click.stop.prevent="getCollectedVods"
        >
          Add Videos
        </ioio-button>
      </div>
    </footer>
    <div v-else class="mt-2 overflow-hidden">
      <ioio-loader isShown></ioio-loader>
    </div>
  </template>

</ioio-modal>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    isRequestPending: false,
    addedGuidsMap: {},
    addedGuidsVodMap: {},
    collectedVodsCount: 0,
  }),
  props: {
    eventGuid: String,
    getAssignedVods: Function,
    initialVods: Object,
    singleChoise: Boolean
  },

  created() {

    this.moment = moment;
  },

  mounted() {
    window.as = this;
  },

  beforeDestroy() {

    this.unstageFromFiltering('vodLibrary');
  },

  methods: {

    navigate(link) {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.cancelAssignVods();

            // Navigate to the requested state
            this.$pushRoute(link);
          }
        });

        this.raiseRedirectFlag(true);

      } else {

        this.cancelAssignVods();

        // Navigate to the requested state
        this.$pushRoute(link);
      }
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms);
    },

    cancelAssignVods() {

      this.toggleAssignVideosModalFlag(false);
    },

    getCollectedVods() {

      const videos = Object.keys(this.addedGuidsMap);

      const vodsFullData = this.addedGuidsVodMap;

      this.getAssignedVods(videos, this.addedGuidsVodMap);
    },

    toggleAddVodToList(vod, newVal) {

      if (newVal) {

        if (this.singleChoise) {

          this.addedGuidsMap = {};
          this.addedGuidsVodMap = {};
        }

        this.addedGuidsVodMap[vod.guid] = {

          ...vod
        };

        this.addedGuidsMap = {

          ...this.addedGuidsMap,
          [vod.guid]: true
        };

        this.collectedVodsCount++;

      } else {

        delete this.addedGuidsVodMap[vod.guid];


        const updatedGuids = {
          ...this.addedGuidsMap
        };

        delete updatedGuids[vod.guid];

        this.addedGuidsMap = {

          ...updatedGuids
        };

        this.collectedVodsCount--;
      }
    },

    beforeOpen() {

      this.addedGuidsMap = {};
      this.addedGuidsVodMap = {};

      console.log(123, this.initialVods)

      // check possible prev selected videos
      for (let vodGuid in this.initialVods) {

        this.toggleAddVodToList(this.initialVods[vodGuid], true);
      }

      this.triggerGetVods(this.eventGuid);
    },

    triggerGetVods() {

      this.unstageFromFiltering('vodLibrary');

      this.isRequestPending = true;

      return this.getVodsList().then(resp => {

        this.videos = resp;

        this.stageForFiltering({
          items: this.videos,
          division: 'vodLibrary'
        });

        this.applyFiltering({

          filterType: 'vodLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    onPaginationClicked(pageNum) {

      this.$refs.libraryScrollArea.scrollTop = 0;

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'vodLibrary'
      });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'vodLibrary'
      });
    },

    ...mapMutations({
      toggleAssignVideosModalFlag: 'events/TOGGLE_ASSIGN_VIDEOS_MODAL_VISIBLE',
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      getVodsList: "channelManager/makeGetVodListRequest",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({
      filteredVodList: "filterAndPaginate/filteredVodList",
      filteredItemsCount: "filterAndPaginate/filteredVodListItemsCount",
      vodSearchOptions: "filterAndPaginate/vodSearchOptions",
      isAssignVideosModalOpened: 'events/isAssignVideosModalOpened',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    isModalOpened: {

      get() {

        return this.isAssignVideosModalOpened;
      },

      set(newVal) {

        this.toggleAssignVideosModalFlag(newVal);
      }
    }
  },
  watch: {

    isModalOpened() {

      if (this.isModalOpened) {

        this.beforeOpen();
      }
    }
  }
}
</script>

<style lang="scss">



.assign-videos {
  &__content {
    @apply
    flex
    flex-auto
    flex-col
    py-4;
  }
  &__list {
    @apply flex flex-col;
  }
  &__list-el {
    @apply
    flex
    items-center
    justify-between
    mt-5;
  }
  &__list-el-image {
    @apply
    w-36
    h-20
    mr-5
    rounded
    overflow-hidden
    object-cover
    border
    border-dark-blue-100;
  }
  &__list-el-text {
    @apply flex flex-col;

    span {
      @apply text-blue-grey-500;
    }
  }
}
</style>
