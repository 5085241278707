/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elemental-link': {
    width: 41,
    height: 15,
    viewBox: '0 0 41 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.335 14.57h38.248a.91.91 0 00.91-.91V.91a.91.91 0 00-.91-.91H1.335a.91.91 0 00-.91.91v12.75c0 .503.407.91.91.91zm37.337-1.82H2.245V1.82h36.427V12.75zm-2.686-3.826h-11.11V7.103h11.11v1.821zm-31.054 0h4.143V7.103H4.932v1.821z" _fill="#F2994A"/>'
  }
})
