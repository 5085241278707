<template>

<div class="vod-meta-form-component flex flex-column">

  <vue-form
    :state="metaFormState"
    class="width+10"
  >

    <div class="flex flex-column">

      <section class="flex items-center">
        <validate class="mr2 width+3">
          <label for="title" class="size-2">Title
            <span class="gray+1">(required)</span>
          </label>
          <input
            type="text"
            class="input size-s"
            id="title"
            v-model="meta.title"
            placeholder="Title"
            required
            name="title" />
          <field-messages name="title" show="$submitted || $dirty && $touched">
            <div class="red size-3" slot="required">Title is a required field</div>
          </field-messages>
        </validate>

        <validate class="mr2 width+3">
          <label for="type" class="size-2" >Type</label>
          <select class="select size-s width+10"
            id="type" v-model="meta.type"
            name="type" required>
            <option value="source">Video</option>
            <option value="ad">Ad</option>
          </select>
          <!-- <field-messages name="type" show="$submitted || $dirty && $touched">
            <div class="red size-3" slot="required">Type is a required field</div>
          </field-messages> -->
        </validate>

        <validate class="width+4">

          <label
            for="description"
            class="size-2"
            >Description</label>
          <textarea
          class="input area size-s"
          id="description"
          v-model="meta.description"
          placeholder="Description"
          name="description" rows="3"/></textarea>
          <!-- <field-messages name="description" show="$submitted || $dirty && $touched">
            <div class="red size-3" slot="required">Description is a required field</div>
          </field-messages> -->
        </validate>
      </section>
      <section class="flex items-center">

        <div class="form-group mr2 width+3">
          <label
            for="group"
            class="size-2"
          >Group</label>
          <input
            type="text"
            class="input size-s"
            id="group"
            v-model="meta.group"
            placeholder="Optional"
          >
        </div>

        <div class="form-group mr2 width+3">
          <label
            for="genre"
            class="size-2"
          >Genre</label>
          <input
            type="text"
            class="input size-s"
            id="genre"
            v-model="meta.genre"
            placeholder="Optional"
          >
        </div>

        <div class="flex width+4 mt2">

          <check-component
            type="checkbox"
            class="size-2 mr3"
            label="Encode"
            :val="meta.encode"
            :on-change="toggleMetaEncodeStatus"/>

          <check-component
            type="checkbox"
            class="size-2"
            label="Auto Subtitles"
            :val="meta.autoSubs"
            :on-change="toggleMetaAutoSubsStatus"/>
        </div>


      </section>

      <p class="disclaimer size-2 my-4">Please note, that auto subtitles for videos longer than 4 hours will not be processed.</p>

    </div>
  </vue-form>

    <!-- isMetaInEditMode="true" -->
  <augmented-vod-meta-component
    ref="augMetaComponent"
    class="augmented-vod-meta" />
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({


    metaFormState: {},
    meta: {
      title: '',
      type: 'source',
      description: '',
      group: '',
      genre: '',
      encode: false,
      autoSubs: false
    }
  }),
  props: {},
  methods: {

    toggleMetaEncodeStatus() {


      const newEncodeVal = !this.meta.encode;

      this.meta = {

        ...this.meta,
        encode: newEncodeVal
      };
    },

    toggleMetaAutoSubsStatus() {

      const newSubsVal = !this.meta.autoSubs;

      this.meta = {

        ...this.meta,
        autoSubs: newSubsVal
      };
    },

    onSubmit() {

      this.metaFormState._submit();
      this.metaFormState._validate();

      if (this.metaFormState.$invalid) {

        return {};
      }

      const meta = this.meta;

      // request to get metaGuid and return the metaGuid and NOT the meta itself
      // return this.meta;

      let metaPayload = {

        title: meta.title,
        type: meta.type
      };

      if (meta.description) {

        metaPayload.description = meta.description;
      }

      if (meta.group) {
        metaPayload.group = meta.group;
      }

      if (meta.genre) {
        metaPayload.genre = meta.genre;
      }

      if (!meta.encode) {

        metaPayload.encodeLater = '1';
      }

      if (meta.autoSubs) {

        metaPayload.autoSubs = '1';
      }

      // new meta fields should be added here

      metaPayload = {
        ...metaPayload,
        ...this.$refs.augMetaComponent.gatherMeta()
      };

      console.log(metaPayload);

      return { promise: () => this.makeUploadFileMetaRequest({ meta: metaPayload }) };
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      makeUploadFileMetaRequest: "channelManager/makeUploadFileMetaRequest",
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>



</style>
