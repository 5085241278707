/** GRP 0 (default getters, not for any specific filter type) */
const urlSearchQuery = (state) => state.urlSearchQuery;
const filteredItems = (state) => state.filteredItems;
const filteredItemsNotPaginated = (state) => state.filteredItemsNotPaginated;



/** When adding new set of filters follow GRP by number */

/** GRP 1 */
const filteredVodList = (state) => state.filteredItems.vodLibrary;
const filteredPlaylists = (state) => state.filteredItems.playlistLibrary;
const filteredImages = (state) => state.filteredItems.imagesLibrary;
const filteredEvents = (state) => state.filteredItems.eventsLibrary;
const filteredEventAttendees = (state) => state.filteredItems.eventAttendeesLibrary;
const filteredEventSpeakers = (state) => state.filteredItems.eventSpeakersLibrary;
const filteredEventSessions = (state) => state.filteredItems.eventSessionsLibrary;



/** GRP 2 */
const filteredVodListItemsCount = (state) =>
  state.filteredItemsNotPaginated.vodLibrary.length;

const filteredPlaylistsItemsCount = (state) =>
  state.filteredItemsNotPaginated.playlistLibrary.length;

const filteredImagesItemsCount = (state) =>
  state.filteredItemsNotPaginated.imagesLibrary.length;

const filteredEventsItemsCount = (state) =>
  state.filteredItemsNotPaginated.eventsLibrary.length;

const filteredEventAttendeesItemsCount = (state) =>
  state.filteredItemsNotPaginated.eventAttendeesLibrary.length;

const filteredEventSpeakersItemsCount = (state) =>
  state.filteredItemsNotPaginated.eventSpeakersLibrary.length;

const filteredEventSessionsItemsCount = (state) =>
  state.filteredItemsNotPaginated.eventSessionsLibrary.length;




/** GRP 3 */
const filteredVodListNotPaginated = (state) => state.filteredItemsNotPaginated.vodLibrary;
const filteredPlaylistsNotPaginated = (state) => state.filteredItemsNotPaginated.playlistLibrary;
const filteredImagesNotPaginated = (state) => state.filteredItemsNotPaginated.imagesLibrary;
const filteredEventsNotPaginated = (state) => state.filteredItemsNotPaginated.eventsLibrary;
const filteredEventAttendeesNotPaginated = (state) => state.filteredItemsNotPaginated.eventAttendeesLibrary;
const filteredEventSpeakersNotPaginated = (state) => state.filteredItemsNotPaginated.eventSpeakersLibrary;
const filteredEventSessionsNotPaginated = (state) => state.filteredItemsNotPaginated.eventSessionsLibrary;


/** GRP 4 */
const vodSearchOptions = (state) => state.vodSearchOptions;
const playlistsSearchOptions = (state) => state.playlistsSearchOptions;
const imagesSearchOptions = (state) => state.imagesSearchOptions;
const eventsSearchOptions = (state) => state.eventsSearchOptions;
const eventAttendeesSearchOptions = (state) => state.eventAttendeesSearchOptions;
const eventSpeakersSearchOptions = (state) => state.eventSpeakersSearchOptions;
const eventSessionsSearchOptions = (state) => state.eventSessionsSearchOptions;



export {
  urlSearchQuery,
  filteredItems,


  filteredVodList,
  filteredPlaylists,
  filteredImages,
  filteredEvents,
  filteredEventAttendees,
  filteredEventSpeakers,
  filteredEventSessions,


  filteredVodListItemsCount,
  filteredPlaylistsItemsCount,
  filteredImagesItemsCount,
  filteredEventsItemsCount,
  filteredEventAttendeesItemsCount,
  filteredEventSpeakersItemsCount,
  filteredEventSessionsItemsCount,


  filteredItemsNotPaginated,



  filteredVodListNotPaginated,
  filteredPlaylistsNotPaginated,
  filteredImagesNotPaginated,
  filteredEventsNotPaginated,
  filteredEventAttendeesNotPaginated,
  filteredEventSpeakersNotPaginated,
  filteredEventSessionsNotPaginated,


  vodSearchOptions,
  playlistsSearchOptions,
  imagesSearchOptions,
  eventsSearchOptions,
  eventAttendeesSearchOptions,
  eventSpeakersSearchOptions,
  eventSessionsSearchOptions,
}