<template>
<div class="breadcrumbs-component text-base text-black-500 flex items-center">
  <span v-for="c in crumbs" :key="c.label" class="breadcrumb">

    <!-- NOTE: Don't reformat the HTML bellow, since it leaves undesired white space when rendered -->
    <span class="mr-4 cursor-pointer" :class="{ 'text-blue-600': c.label === activeCrumb }" @click="navigate(c.route)"
    >{{ c.label }}</span><ioio-icon class="arrow mr-4" icon="fa-angle-right"  />
</span>
</div>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {
    activeCrumb: String,
    crumbs: Array
  },
  methods: {

    navigate(navItemLink) {

      if (!navItemLink) {

        return;
      }

      // Navigate to the requested state
      return this.$pushRoute(navItemLink);
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">
.breadcrumb {

  &:last-of-type {

    .arrow {

      display: none;
    }

    span {

      cursor: default;
    }

    display: inline-block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

</style>
