/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scissors': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.5 15a4.44 4.44 0 00-2.016.499l-2.208-2.575 5.625-5.625A5.974 5.974 0 0020.8 0L12 10.267 3.2 0a5.972 5.972 0 00.899 7.298l5.625 5.625-2.208 2.575A4.452 4.452 0 005.5 15C3.019 15 1 17.019 1 19.5S3.019 24 5.5 24s4.5-2.019 4.5-4.5c0-.672-.158-1.304-.423-1.877L12 15.2l2.423 2.423A4.454 4.454 0 0014 19.5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5zm-13 7C4.122 22 3 20.879 3 19.5S4.122 17 5.5 17 8 18.121 8 19.5 6.878 22 5.5 22zM16 19.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.122 2.5-2.5 2.5-2.5-1.121-2.5-2.5z" _fill="#002c65"/>'
  }
})
