<template>
<div class="dashboard-recently-added-component">
  <div class="box">
    <h2 class="box__title">Recently added</h2>
    <div v-if="vodList.length > 0 && !isRequestPending">
      <div class="rtable">
        <table>
          <thead>
            <tr>
              <th>Video</th>
              <th>Tags</th>
              <th>Status</th>
              <th>Date of Upload</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="vod in vodList"
              :key="vod.guid"
              @click="handleOpenVod(vod)"
            >
              <td class="rtable__video">
                <div class="rtable__vthumb">
                  <img v-if="vod.meta && vod.meta.imageUrl" :src="vod.meta.imageUrl"/>
                  <img else src="https://qa2.stg.ioio.tv/image/049c2a3e-2d50-432a-b9af-d4a467fb34d0.png"/>
                  <div class="rtable__vduration">
                    {{ parseDuration(vod.srcDuration) }}
                  </div >
                </div>
                <h4 v-if="vod.meta.title.length < 26" class="rtable__vname">{{vod.meta.title}}</h4>
                <ioio-tooltip v-else :text="vod.meta.title" initialPostion="top-right" class="tooltip-z">
                  <h4 class="rtable__vname">{{vod.meta.title}}</h4>
                </ioio-tooltip>
              </td>
              <td>
                <ioio-label
                  v-for="(tag,i) in vod.tags"
                  :key="i"
                  :text="tag"
                  class="mx-1"
                  size="small"
                />
                <ioio-tooltip
                  class="z-10"
                  v-if="vod.extraTags"
                  :text="vod.extraTags"
                >
                  <ioio-label
                    :text="vod.tooltipTag"
                    class="mx-1"
                    size="small"
                  />
                </ioio-tooltip>
              </td>
              <td>
                <ioio-label
                  :type="vod.typeLabel"
                  :text="vod.status"
                  size="small"
                />
              </td>
              <td class="rtable__vupload-date">{{moment(vod.createdAt).format('MM-DD-YYYY, h:mm a')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ioio-button
        type="minimal"
        class="rtable__see-all"
        @click="openLibrary"
      >
        See all
      </ioio-button>
    </div>
    <div class="nodata"  v-else-if="vodList.length == 0 && !isRequestPending">
      <div class="nodata-thumb">
        <ioio-icon icon="far-cloud-slash"/>
      </div>
      <div class="nodata-text">No uploads yet</div>
    </div>
    <div class="nodata-loader" v-else>
      <ioio-loader isShown />
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";
import moment from "moment-timezone";

export default {
  data: () => ({
    vodList: [],
    elasticOptions: {
      currentPage: 1,
      perPageItems: 4,
      sortBy: [{"createdAt": "desc"}]
    },
    isRequestPending: false,
    moment: {}
  }),
  props: {},
  created() {
    this.loadRecentlyAddedVod();
    this.moment = moment;
  },
  methods: {
    loadRecentlyAddedVod() {
      this.isRequestPending = true;

      const from = 0;
      const size = this.elasticOptions.perPageItems;
      const sort = this.elasticOptions.sortBy;
      const search = {};
      search.options = {
        from,
        size,
        sort,
        "query": {
          "bool": {
            "filter": [
              {
                "term": {
                  "resourceType": "source"
                }
              },
              {
                "bool": {
                  "should": [
                    {
                      "bool": {
                        "must": [
                          {
                            "exists": {
                              "field": "meta.privacy"
                            }
                          },
                          {
                            "term": {
                              "meta.privacy": false
                            }
                          }
                        ]
                      }
                    },
                    {
                      "bool": {
                        "must_not": [
                          {
                            "exists": {
                              "field": "meta.privacy"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "terms": {
                  "meta.type.keyword": [
                    "source",
                    "ad"
                  ]
                }
              },
              {
                "terms": {
                  "status": [
                    "complete",
                    "encoding",
                    "error",
                    "encode-later"
                  ]
                }
              }
            ]
          }
        },
        "_source": [
          "guid",
          "status",
          "srcDuration",
          "createdAt",
          "meta.imageUrl",
          "meta.title",
          "meta.description",
          "meta.categories",
          "meta.tags",
          "meta.user.email",
          "mp4Outputs"
        ]
      };

      this.getVodElastic(search)
        .then((response) => {

          const vods  = response.hits.hits.map(i => {

            // parse VOD`s tags

            let tags = [];

            i._source.meta.genre ? tags = i._source.meta.genre.split(',') : null;

            if (tags.length > 0) {

              let tagsLength = 0;

              let index = 0;

              while (tagsLength < 13 && tags[index]) {

                tagsLength  = tagsLength + tags[index].length + 3;

                index = index + 1;
              }

              tagsLength > 15 ? index = index -1 : null;

              let extraTags = tags.splice(index, tags.length-index)

              i._source.extraTags = extraTags.join(', ');

              i._source.tooltipTag = `+${extraTags.length}`;

              i._source.tags = tags;
            }

            // parse VOD`s label status

            switch (i._source.status) {

              case 'complete':
                i._source.status = 'Uploaded';
                i._source.typeLabel = 'success';
                break;

              case 'encoding':
                i._source.status = 'Encoding';
                i._source.typeLabel = 'normal';
                break;

              case 'error':
                i._source.status = 'Failed';
                i._source.typeLabel = 'failed';
                break;

              case 'encode-later':
                i._source.status = 'Encode later';
                i._source.typeLabel = 'normal';
                break;
            }

            return i._source;
          });

          this.vodList = vods;
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    handleOpenVod(vod) {

      this.$pushRoute(`/vod/library-new?id=${vod.guid}`);
    },

    openLibrary() {

      this.$pushRoute("/vod/library-new")
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms);
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getVodElastic: "channelManager/getVodElastic",
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>

.box {
  @apply
  mt-5
  p-6
  shadow-card
  border
  border-black-100
  rounded-lg
  bg-white
  overflow-hidden;

  &__title {
    @apply text-lg pb-3;

    &.e-border {
      @apply border-b border-black-100;
    }
  }
}

.nodata {
  @apply
  flex
  justify-center
  items-center
  flex-col
  min-h-44;

  &-thumb {
    @apply
    flex
    justify-center
    items-center
    w-14
    h-14
    mb-4
    rounded-full
    bg-black-50
    text-lg
    text-black-500;
  }

  &-text {
    @apply text-black-500 text-sm;
  }

  &-loader {
    @apply
    relative
    h-10
    mt-5;
  }
}


// ***********************************************
// Responsive table
// ***********************************************
.rtable {
  @apply w-full overflow-x-auto;

  &::-webkit-scrollbar {
    @apply w-2 h-2;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded bg-black-500;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    @apply bg-black-300;
  }

  table {
    @apply table w-full;
  }

  tr {
    @apply border-b border-black-100;
  }

  th,
  td {
    @apply px-3 min-w-40 text-left;
  }

  th {
    @apply py-3 text-sm text-black-500 font-medium;

  }

  td {
    @apply py-5 align-middle;
  }

  &__video {
    @apply
    flex
    flex-row
    items-center
    justify-center
    cursor-pointer;
  }

  &__vthumb {
    @apply
    relative
    mr-4
    flex-shrink-0
    overflow-hidden
    rounded;
    width: 90px;
    height: 56px;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__vduration {
    @apply
    absolute
    left-0
    bottom-0
    py-1
    px-2
    bg-blue-grey-900
    bg-opacity-60
    text-xs
    text-white;
  }

  &__vname {
    @apply
    inline-block
    w-52
    text-sm
    pr-2
    z-0
    truncate;
  }

  &__vupload-date {
    @apply
    text-sm
    text-black-500
    whitespace-nowrap;
  }

  &__see-all.ds-btn.is-minimal {
    @apply
    w-full
    mt-3
    justify-center
    text-blue-600;
  }

  .tooltip-z {
    z-index: 1;
  }
}



</style>
