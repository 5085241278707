const _logWorkedOnWorkflow = (commit, workflow) => {

  commit('app/LOG_WORKED_ON_ITEM', {
    title: workflow.name,
    type: 'Workflow',
    loggedAt: new Date().getTime(),
    url: `/live/workflows/details?id=${workflow.id}`
  }, {
    root: true
  });
};

const makeGetRegionsRequest = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getRegions',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    })
    .then(response => {

      commit('SET_POSSIBLE_REGIONS', response.items);
    });
};

const makeGetBlueprintsRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getBlueprints',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    })
    .then(response => {

      console.log('blueprintsList', response);

      commit('SET_BLUEPRINTS_LIST', response.items);
    });
};

const makeGetBlueprintModelRequest = ({ state, commit, dispatch }, id) => {
console.log(id)
  return dispatch('app/makeRequest', {
      type: 'getBlueprintModel',
      baseUrlType: 'BASE_LIVE_URL',
      payload: {
        id
      }
    }, {
      root: true
    })
    .then(model => {

      console.log('model', model);

      commit('SET_SELECTED_BLUEPRINT_MODEL', model);
    });
};

const makeGetWorkflowsRequest = ({ state, commit, dispatch }, params) => {

  state.isWorkflowsListLoading = true;

  return dispatch('app/makeRequest', {
      type: 'getWorkflows',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    })
    .then(response => {

      commit('SET_WORKFLOWS_LIST', response.items);
      commit('SET_WORKFLOWS_PAGINATION_INFO', response);
    })
    .finally(() => {

      state.isWorkflowsListLoading = false;
    });
};

// NOTE: Deptrecate when new NFL BE is merged
const getWorkflowsStatsOld = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getWorkflowsStatsOld',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    });
};

const getWorkflowsStats = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'getWorkflowsStats',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const getMultipleWorkflowsByIds = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getMultipleWorkflowsByIds',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const makeGetWorkflowDetailsRequest = ({ state, commit, dispatch }, id) => {

  state.areGeneralDetailsLoading = true;

  return dispatch('app/makeRequest', {
      type: 'getWorkflowDetails',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    })
    .then(response => {

      console.log('getWorkflowDetails', response);

      commit('SET_SELECTED_WORKFLOW_FOR_PREVIEW', response);
    })
    .finally(() => {

      state.areGeneralDetailsLoading = false;
    });
};

const makeGetChannelForWorkflowRequest = ({ state, commit, dispatch }, id) => {

  state.areChannelDetailsLoading = true;

  commit('SET_SELECTED_WORKFLOW_CHANNEL_DATA', {});

  return dispatch('app/makeRequest', {
      type: 'getChannelForWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    })
    .then(response => {

      console.log('getChannelForWorkflow', response);

      commit('SET_SELECTED_WORKFLOW_CHANNEL_DATA', response);

      return response;
    })
    .finally(() => {

      state.areChannelDetailsLoading = false;
    });
};

const makeGetChannelForWorkflowAlertsRequest = ({ state, commit, dispatch }, id) => {

  state.areAlertsCurrentlyLoading = true;

  return dispatch('app/makeRequest', {
      type: 'getChannelForWorkflowAlerts',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id,
        status: 'SET'
      }
    }, {
      root: true
    })
    .then(response => {

      commit('SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_DATA', response.items);
    })
    .finally(() => {

      state.areAlertsCurrentlyLoading = false;
    });
};

const makeGetChannelForWorkflowAlertsHistoryRequest = ({ state, commit, dispatch }, id) => {

  state.areAlertsCurrentlyLoading = true;

  return dispatch('app/makeRequest', {
      type: 'getChannelForWorkflowAlerts',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    })
    .then(response => {

      commit('SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_HISTORY_DATA', response.items);
    })
    .finally(() => {

      state.areAlertsCurrentlyLoading = false;
    });
};

const getChannelWorkflowLogs = ({ state, commit, dispatch }, params) => {

  state.areChannelLogsLoading = true;

  return dispatch('app/makeRequest', {
      type: 'getChannelWorkflowLogs',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    })
    .then(response => {

      commit('SET_SELECTED_WORKFLOW_CHANNEL_LOGS_DATA', response);
    })
    .finally(() => {

      state.areChannelLogsLoading = false;
    });
};


const getChannelsAlerts = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getChannelsAlerts',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const makeGetChannelForWorkflowHealthRequest = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getChannelForWorkflowHealth',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    })
    .then(response => {

      commit('SET_SELECTED_WORKFLOW_CHANNEL_HEALTH_DATA', response);
    });
};



const makeSaveBlueprintAsWorkflowRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'saveBlueprintAsWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    })
    .then(response => {

      console.log('SAVED WORKFLOW!', response);

      _logWorkedOnWorkflow(commit, {

        name: payload.name,
        id: response.id
      });

      return response;
    });
};

const switchInputForWorkflow = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'switchInputForWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const getDefaultInput = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getDefaultInput',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const makeCreateChannelFromWorkflowRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createChannelFromWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const makeDeleteLiveChannelRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteLiveChannel',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const pauseLiveChannel = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'pauseLiveChannel',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const unpauseLiveChannel = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'unpauseLiveChannel',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const deletePauseForChannel = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deletePauseForChannel',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const getScheduledAds = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getScheduledAds',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const scheduleAdForWorkflow = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'scheduleAdForWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const removeAdScheduleFromWorkflow = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'removeAdScheduleFromWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const makeDeleteWorkflowRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const duplicateWorkflow = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'duplicateWorkflow',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    })
    .then(response => {

      _logWorkedOnWorkflow(commit, {

        name: payload.originalName,
        id: response.id
      });

      return response;
    });
};

const updateWorkflowSchedule = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateWorkflowSchedule',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    })
    .then(response => {

      _logWorkedOnWorkflow(commit, {

        name: payload.originalName,
        id: payload.workflowId
      });

      return response;
    });
};

const getWorkflowFilters = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getWorkflowFilters',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    });
};

const createWorkflowFilter = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'createWorkflowFilter',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const deleteWorkflowFilter = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'deleteWorkflowFilter',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};


const getConnectedDevices = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getConnectedDevices',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    })
    .then(response => {

      return response.items;
    });
};

const getConnectedDeviceById = ({ state, commit, dispatch }, id) => {

  return dispatch('app/makeRequest', {
      type: 'getConnectedDeviceById',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    });
};

const getConnectedDeviceThumbnail = ({ state, commit, dispatch }, id) => {

  return dispatch('app/makeRequest', {
      type: 'getConnectedDeviceThumbnail',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    });
};

const addConnectedDevice = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'addConnectedDevice',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const deleteConnectedDevice = ({ state, commit, dispatch }, id) => {

  return dispatch('app/makeRequest', {
      type: 'deleteConnectedDevice',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    });
};

const getZenMasterSettings = ({ state, commit, dispatch }, id) => {

  return dispatch('app/makeRequest', {
      type: 'getZenMasterSettings',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    });
};

const setZenMasterSettings = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'setZenMasterSettings',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};



const getLiveRegions = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getLiveRegions',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    });
};

const getManagedInputs = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getManagedInputs',
      baseUrlType: 'BASE_LIVE_URL'
    }, {
      root: true
    });
};

const getManagedInputById = ({ state, commit, dispatch }, id) => {

  return dispatch('app/makeRequest', {
      type: 'getManagedInputById',
      baseUrlType: 'BASE_LIVE_URL',
      params: {
        id
      }
    }, {
      root: true
    });
};

const storeManagedInput = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'storeManagedInput',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const editManagedInput = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'editManagedInput',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const deleteManagedInput = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'deleteManagedInput',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};

const getBlueprintForm = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getBlueprintForm',
      baseUrlType: 'BASE_LIVE_URL',
      params
    }, {
      root: true
    });
};

const updateFeaturedBlueprints = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'updateFeaturedBlueprints',
      baseUrlType: 'BASE_LIVE_URL',
      payload
    }, {
      root: true
    });
};



const getLiveApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getLiveApiKeys',
    baseUrlType: 'BASE_LIVE_URL',
  }, {
    root: true
  });
}

const storeNewLiveApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewLiveApiKey',
    baseUrlType: 'BASE_LIVE_URL',
    payload
  }, {
    root: true
  });
}

const updateLiveApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateLiveApiKey',
    baseUrlType: 'BASE_LIVE_URL',
    payload
  }, {
    root: true
  });
}

const deleteLiveApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteLiveApiKey',
    baseUrlType: 'BASE_LIVE_URL',
    params
  }, {
    root: true
  });
}


export {
  makeGetRegionsRequest,
  makeGetBlueprintsRequest,
  makeGetBlueprintModelRequest,
  makeGetWorkflowsRequest,
  getWorkflowsStatsOld,
  getWorkflowsStats,
  getMultipleWorkflowsByIds,
  makeGetWorkflowDetailsRequest,
  makeGetChannelForWorkflowRequest,
  makeGetChannelForWorkflowAlertsRequest,
  makeGetChannelForWorkflowAlertsHistoryRequest,
  getChannelsAlerts,
  getChannelWorkflowLogs,
  makeGetChannelForWorkflowHealthRequest,
  switchInputForWorkflow,
  getDefaultInput,
  makeSaveBlueprintAsWorkflowRequest,
  makeCreateChannelFromWorkflowRequest,
  makeDeleteLiveChannelRequest,
  pauseLiveChannel,
  unpauseLiveChannel,
  deletePauseForChannel,
  getScheduledAds,
  scheduleAdForWorkflow,
  removeAdScheduleFromWorkflow,
  makeDeleteWorkflowRequest,
  duplicateWorkflow,
  updateWorkflowSchedule,
  getWorkflowFilters,
  createWorkflowFilter,
  deleteWorkflowFilter,
  getConnectedDevices,
  getConnectedDeviceById,
  getConnectedDeviceThumbnail,
  addConnectedDevice,
  deleteConnectedDevice,
  getZenMasterSettings,
  setZenMasterSettings,
  getLiveRegions,
  getManagedInputs,
  getManagedInputById,
  storeManagedInput,
  editManagedInput,
  deleteManagedInput,
  getBlueprintForm,
  updateFeaturedBlueprints,

  getLiveApiKeys,
  storeNewLiveApiKey,
  updateLiveApiKey,
  deleteLiveApiKey,
}