<template>
<div class="event-recordings-component flex flex-auto flex-column ratio7-10 py2">

recordings
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
   data(){
    return {

    }
  },

  props: {},


  mounted(){

  },

  methods: {

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">



.event-recordings-component {

  height: 100%;

}
</style>
