<template>
<div class="tags-and-cc-component width+10">


  <modal
    name="translation-create"
    width="500px"
    height="auto"
    :scrollable="true"
    classes="v--modal translation-create-modal p3 flex flex-column"
    :clickToClose="false"
    @before-open="beforeTranslationModalOpen"
  >

    <section class="mb2">

      <h1 class="m0 mb2 size+2">Select languages to translate to</h1>

      <ioio-dropdown
        v-model="translationLangs"
        :items="languagesTranslateConfig"
        :multiple="true"
        placeholder="Languages"
        label="Languages"
        titleProp="label"
        valueProp="abbr"
        class="w-72"
      />

    </section>

    <div class="mt2 flex items-center justify-end">
      <section v-if="!isTranslationCreateInProgress" class="flex items-center justify-end">
        <button-component
          variant="primary"
          class="mr2"
          @click="submitSubsTranslation"
        >Save</button-component>
        <button-component
          variant="default"
          @click="$modal.hide('translation-create')"
        >Cancel</button-component>

      </section>
      <div v-else class="overflow-hidden flex justify-center" style="width: 70px; height: 30px">
        <logo-loader-component />
      </div>
    </div>


  </modal>

  <section>

    <section class="flex">

      <aside class="width+2 flex flex-column mr3 pl3 pt3 pb3 mt2">

        <div class="btn-group border-gray+4 flex-column">

          <button-component variant="primary"
            @click="() => selectSubsUploadOption('upload')"
            size="size-m"
            :class="{ active: selectedUploadOption === 'upload' }"
            class=""
            >
            <ioio-icon icon="fas-cloud-arrow-up" class="w-6 h-6 mr-2"/>
            Upload
          </button-component>

          <button-component variant="primary"
            @click="() => selectSubsUploadOption('rev')"
            size="size-m"
            :class="{ active: selectedUploadOption === 'rev' }"
            class="mt1"
            >
            <svg-icon name="REV" class="mr1" width="28px" height="28px"></svg-icon> Send to Rev
          </button-component>

        </div>

        <div class="btn-group border-gray+4 flex-column mt1">
          <button-component variant="primary"
            v-if="!editedVod.meta.autoTranslate"
            @click="autoGenerateSubs(null)"
            size="size-m"
            :class="{ disabled: isAutoSubsDisabled }"
            class="active"
            style="height: 4rem"
            >
            <svg-icon name="ioio_play" class="" width="30px" height="30px">
            </svg-icon> Auto Generate Subs
          </button-component>

          <button-component variant="default"
            @click="autoGenerateSubs('autoTranslate')"
            size="size-m"
            :class="{ disabled: isAutoSubsDisabled }"
            style="height: 5rem"
            >
            <svg-icon name="ioio_play" class="" width="30px" height="30px">
            </svg-icon> Auto Generate & Translate Subs
          </button-component>
        </div>
      </aside>

      <article class="width+8">

        <section class="cc-section width+10" v-show="selectedUploadOption === 'upload'">
          <h6 class="my3" style="lineHeight: 1.2rem"
            > Upload a new Subtitles or Closed Captions file or edit an existing one. Click on <svg-icon name="subtitles"
            width="1.2rem"
            height="1.2rem"
            style="marginTop: -2px"
            class="mx1 light pointer silver"></svg-icon> to mark as default Subtitles and               <svg-icon name="closed_caption"
              width="1.2rem"
              height="1.2rem"
              style="marginTop: -2px"
              class="mx1 light pointer silver"></svg-icon> to mark as Closed Captions. For your files to become active you must Encode
            <ioio-icon
              icon="far-rotate-right"
              class="h-4 mx-2 mt-0.5 cursor-pointer font-light text-black-400"
            /> the video when you are satisfied with your changes. <b>* Multiple Subtitle files and only one default and/or CC file allowed per video.</b>
          </h6>
          <h5 class="subtitle size-2 m0">
            Supported file types: .dfxp, .ttml, .sami, .smi, .srt, .scc, .vtt
          </h5>

          <vue-form :state="newUploadsFormState" @submit.prevent class="new-uploads-section">

            <section class="flex mt2 stage-new-section">

              <div class="flex mr1">

                <svg-icon name="closed_caption"
                  width="1.2rem"
                  height="1.4rem"
                  :class="{ 'silver': !isCC }"
                  class="mr1 mt1 light pointer"
                  @click="isCC = !isCC"></svg-icon>

                <svg-icon name="subtitles"
                  width="1.2rem"
                  height="1.4rem"
                  :class="{ 'silver': !isDefault }"
                  class="mr1 mt1 light pointer"
                  @click="isDefault = !isDefault"></svg-icon>

                <check-component
                  type="checkbox"
                  label="Encode"
                  class="mr1 size-3"
                  style="margin-top: 11px"
                  :val="shouldEncode"
                  :on-change="() => shouldEncode = !shouldEncode"/>

                <check-component
                  type="checkbox"
                  label="AutoTranslate"
                  class="size-3"
                  style="margin-top: 11px"
                  :val="autoTranslate"
                  :on-change="() => autoTranslate = !autoTranslate"/>
              </div>
              <article class="choose-file-group border flex">
                <select
                  class="select pr2 mr2"
                  style="padding-right: 28px"
                  id="selectedCCLanguage"
                  v-model="selectedCCLanguage"
                  required
                  name="selectedCCLanguage"
                >
                  <option
                    v-for="(label, abbr) in allowedLanguages"
                    :value="abbr"
                    >{{ label }}</option
                  >
                </select>

                <label class="custom-file-upload truncate" v-if="!preStagedFileFormAdvancedEditor">
                  <input
                    class="input"
                    type="file"
                    ref="ccInput"
                    @change="validateFileInput"
                  />
                  {{ fileUploadLabel }}
                </label>


                <label class="custom-file-upload truncate" v-if="preStagedFileFormAdvancedEditor">
                  {{ preStagedFileFormAdvancedEditor.name }}
                </label>

                <validate class="border-right">
                  <input
                    class="input"
                    style="width: 100px;"
                    type="text"
                    placeholder="Screen Name"
                    pattern="^[A-Za-z0-9_ ]*$"
                    name="newCCScreenName"
                    v-model="newCCScreenName"
                  />
                  <field-messages
                    name="newCCScreenName"
                    show="$submitted || $dirty && $touched"
                    class="description-err-msgs"
                  >
                    <div slot="pattern">Screen Name should contain only letters and numbers</div>
                  </field-messages>
                </validate>

                <validate class="">
                  <input
                    class="input"
                    style="width: 100px;"
                    type="text"
                    placeholder="Description"
                    pattern="^[A-Za-z0-9_ ]*$"
                    name="newCCDescription"
                    v-model="newCCDescription"
                  />
                  <field-messages
                    name="newCCDescription"
                    show="$submitted || $dirty && $touched"
                    class="description-err-msgs"
                  >
                    <div slot="pattern">Description should contain only letters and numbers</div>
                  </field-messages>
                </validate>

                <button-component variant="primary" @click="stageCC" :class="{ disabled: (isStageFileDisabled || newUploadsFormState.$invalid) }">Stage File</button-component>


              </article>
              <button-component v-if="!preStagedFileFormAdvancedEditor" variant="primary" size="size-m" class="ml1" style="margin-top: 1px" @click="() => loadSubsEditor()"
                >Generate New File
              </button-component>

              <button-component v-if="preStagedFileFormAdvancedEditor" variant="primary" size="size-m" class="ml1" style="margin-top: 1px" @click="cancelAdvancedEditPreStage"
                >Cancel New File
              </button-component>


            </section>

            <ul class="list-reset m0 p1" v-if="stagedCCs.length">
              <h5>Staged</h5>
              <li class="hover-bg-blue+5 size-1 rounded p1" v-for="(cc, index) in stagedCCs">

                <h3 class="size-2">{{ cc.screenName }}</h3>
                <section class="flex">

                  <div class="flex mr3">

                    <svg-icon name="closed_caption"
                      width="1.2rem"
                      height="1.4rem"
                      :class="{ 'silver': !cc.cc }"
                      class="mr2 mt1 light pointer"
                      @click="cc.cc = !cc.cc"></svg-icon>

                    <svg-icon name="subtitles"
                      width="1.2rem"
                      height="1.4rem"
                      :class="{ 'silver': !cc.default }"
                      class="mr2 mt1 light pointer"
                      @click="cc.default = !cc.default"></svg-icon>

                    <check-component
                      type="checkbox"
                      label="Encode"
                      class="ml2 size-3"
                      style="margin-top: 11px"
                      :val="cc.shouldEncode"
                      :on-change="() => cc.shouldEncode = !cc.shouldEncode"/>

                     <check-component
                      type="checkbox"
                      label="AutoTranslate"
                      class="ml2 size-3"
                      style="margin-top: 11px"
                      :val="cc.autoTranslate"
                      :on-change="() => cc.autoTranslate = !cc.autoTranslate"/>

                  </div>

                  <article class="flex mr2">

                    <h6 class="" style="width: 100px;">{{ allowedLanguages[cc.lang] }}</h6>
                    <h6 class="overflow-hidden" style="width: 100px; textOverflow: ellipsis"
                      >{{ cc.description }}
                    </h6>
                  </article>
                  <ioio-icon
                    icon="fa-xmark"
                    class="w-5 h-5 text-red-600 my-2 mr-4"
                    @click="removeStagedCC(index)"
                    :class="{ 'gray disabled': isUploadNewCaptionsLoading, pointer: !isUploadNewCaptionsLoading }"
                  />
                  <progress-component class="small" style="width: 7.5rem" :progress="cc.progressPercentage"  v-if="isUploadNewCaptionsLoading" />

                </section>
              </li>
            </ul>

            <footer v-if="stagedCCs.length" class="py1">
              <button-component variant="primary" class="upload-btn" size="size-m" @click="uploadStagedCC" :class="{ disabled: !stagedCCs.length }">Upload</button-component>
            </footer>
          </vue-form>

        </section>

        <section v-show="selectedUploadOption === 'rev'" class="width+10 mt3">

          <h5>Comming Soon...</h5>
        </section>

      </article>
    </section>






      <section class="subs-editor-holder" :class="{ active: isAdvacedEditorActive }">

        <section id="subs-editor-container"></section>
      </section>





    <hr>
    <section class="overflow-hidden" v-if="isStoredCaptionsDataLoading">
      <logo-loader-component width="50px" height="50px" />
    </section>
    <section v-if="storedCaptionsData.guid && !isStoredCaptionsDataLoading">
      <ul class="list-reset m0 p1">
        <header class="flex justify-between">
          <h5>Active</h5>

        </header>

        <h6 v-if="isStoredCaptionsDataModified" class="mb1">Some of the Active Captions Data has been modified. Save changes in order to keep them.</h6>
        <h6 v-if="!storedCaptionsData.files || !storedCaptionsData.files.length" class="mb1">No Active Captions Data has been saved for this video.</h6>
        <li class="hover-bg-blue+5 size-1 rounded p1"
          v-for="(storedCC, index) in storedCaptionsData.files" :key="storedCC.guid">

          <h3 class="size-2">{{ storedCC.meta.screenName || storedCC.guid }}</h3>

          <section class="flex">

            <div class="flex mr3 pointer">

              <svg-icon name="closed_caption"
                width="1.2rem"
                height="1.4rem"
                :class="{ 'silver': storedCaptionsData.cc !== storedCC.guid }"
                class="mr2 mt1 light pointer"
                @click="changeActiveDefaultCC(storedCC.guid, 'cc', storedCaptionsData.cc !== storedCC.guid)"></svg-icon>

              <svg-icon name="subtitles"
                width="1.2rem"
                height="1.4rem"
                :class="{ 'silver': storedCaptionsData.default !== storedCC.guid }"
                class="mr2 mt1 light pointer"
                @click="changeActiveDefaultCC(storedCC.guid, 'default', storedCaptionsData.default !== storedCC.guid)"></svg-icon>

              <check-component
                type="checkbox"
                label="Encode"
                class="ml2 size-3"
                style="margin-top: 11px"
                :val="storedCC.meta.shouldEncode"
                :on-change="(e) => changeActiveCCShouldEncode(e, index)"/>

               <check-component
                type="checkbox"
                label="AutoTranslate"
                class="ml2 size-3"
                style="margin-top: 11px"
                :val="storedCC.meta.autoTranslate=='1'"
                :on-change="(e) => changeActiveCCAutoTranslate(e, index)"/>
            </div>
            <article class="choose-file-group border flex mr2">

              <select
                class="select pointer"
                style="padding-right: 28px"
                v-model="storedCC.meta.lang"
                @change="changeActiveCCLang($event, index)"
                required
              >
                <option
                  v-for="(label, abbr) in allowedLanguages"
                  :key="label"
                  :value="abbr"
                  >{{ label }}</option
                >
              </select>

              <input type="text" class="ml2" placeholder="Description" v-model="storedCC.meta.description"
              @change="changeActiveCCDescription($event, index)">

            </article>
            <ioio-icon
              icon="far-xmark"
              class="w-5 h-5 text-red-600 my-2 cursor-pointer"
              @click="stageActiveCCForRemove(storedCC.guid, index)"
            />

            <button-component variant="primary" size="size-m" class="ml2" @click="() => loadSubsEditor(storedCC)"
              >Edit As New
            </button-component>

            <button-component variant="default" size="size-m" class="ml2" @click="$modal.show('translation-create', storedCC.guid)"
              >Translate
            </button-component>
          </section>

          <section
            v-if="pendingTranslations[storedCC.guid] && pendingTranslations[storedCC.guid].length">

            <h3 class="size-2">Pending translations</h3>

            <div v-for="penTrans in pendingTranslations[storedCC.guid]" :key="penTrans.guid" class="flex" style="font-size: 12px">
              <div class="mr3 truncate" style="width: 100px">{{ languagesTranslateMap[penTrans.lang] }}</div>
              <div class="mr3 truncate" style="width: 100px">{{ translationStatusesConfig[penTrans.status] }}</div>
              <div><span class="">Started:</span> {{
                moment
                    .tz(penTrans.startTime, localTimezone)
                    .format("MMM Do h:mma z Z")
                }}
              </div>
            </div>
          </section>
        </li>
      </ul>
      <footer class="flex justify-between py1">
        <aside>
          <button-component variant="primary" size="size-m" @click="updateActiveCCs" v-if="isStoredCaptionsDataModified">Save Changes</button-component>
          <button-component variant="subtle" size="size-m" @click="cancelUpdateActiveCCs"  class="ml2" v-if="isStoredCaptionsDataModified">Discard</button-component>
        </aside>




        <div v-if="!isEditedVodEncoding" class="mb2">
          <button-component class="re-encode-btn" size="size-m"
            @click.stop.prevent="onReEncode(editedVod)"
            >Encode <ioio-icon icon="far-rotate-right" class="ml-2 h-4"/>
          </button-component>
        </div>

        <div v-else class="mb2">
          <h4>This video is encoding.</h4>
        </div>



      </footer>
    </section>


  </section>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import md5 from "md5";
import { CognitoIdentity } from "aws-sdk/clients/all";
import moment from "moment-timezone";

import askForPromise from "ask-for-promise";

import {

  languagesTranslateConfig,
  languagesThreeLetterConfig,
  translationStatusesConfig
} from "@utils/constants";

import { getLocalTimezone } from "@utils/helpers";

export default {
  data: () => ({

    moment: {},
    localTimezone: '',


    // CC specific data
    newUploadsFormState: {},
    isDefault: false,
    isCC: false,
    selectedCCLanguage: 'ENG',
    newCCScreenName: '',
    newCCDescription: '',
    shouldEncode: true,
    autoTranslate: false,

    preStagedFileFormAdvancedEditor: null,

    allowedCaptionsExtensions: {
      'dfxp': true,
      'ttml': true,
      'sami': true,
      'smi': true,
      'srt': true,
      'scc': true,
      'vtt': true,
    },
    allowedLanguages: languagesThreeLetterConfig,

    languagesTranslateConfig: Object.keys(languagesTranslateConfig)
      .map((key) => { return { abbr: key, label: languagesTranslateConfig[key] }}),

    languagesTranslateMap: languagesTranslateConfig,

    translationStatusesConfig: translationStatusesConfig,

    isStageFileDisabled: true,
    isAutoSubsDisabled: false,
    stagedCCs: [],
    isUploadNewCaptionsLoading: false,
    uploadedCCsCount: 0,

    storedCaptionsData: {},
    isStoredCaptionsDataLoading: false,
    isStoredCaptionsDataModified: false,
    forRemoveCCGuids: [],

    pendingTranslations: {},

    fileUploadLabel: 'Select a File',

    selectedUploadOption: 'upload',

    subtitlesEditor: null,
    editPromiseTask: null,

    isAdvacedEditorActive: false,

    translationLangs: [],
    forTranslationFileGuid: null,
    isTranslationCreateInProgress: false,

  }),

  watch: {

    editedVodGuid() {

      this.setInitialComponentState();
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'captions_status_change') {

        const captionsGuidInEditedVod =
          this.editedVod &&
          this.editedVod.meta &&
          this.editedVod.meta.captionsGuid;

        if (captionsGuidInEditedVod && captionsGuidInEditedVod === newestMsg.data.guid) {

          this.getFileCaptions(captionsGuidInEditedVod);

        } else if (this.editedVod.guid === newestMsg.data.source_guid) {

          const augmentedVod = {

            ...this.editedVod,
            meta: {

              ...this.editedVod.meta,
              captionsGuid: newestMsg.data.guid
            }
          };

          this.updateVodListItem(augmentedVod);
          this.setSingleSourcePlayed(augmentedVod);

          this.updateEditedVod(augmentedVod);

          this.getFileCaptions(newestMsg.data.guid);

        } else if (newestMsg.data.error) {

          this.$toasted.error(`There was an error processing your file: ${newestMsg.data.error}`);
        }
      }

      if (newestMsg.type === 'translation_job_status_change') {

        const isRelevantTranslationJobUpdated =
          newestMsg.data.translationJobs
            .find(j => j.captionGuid === this.editedVod.meta.captionsGuid);

        if (isRelevantTranslationJobUpdated) {

          //updateCaptions data
          this.getFileCaptions(this.editedVod.meta.captionsGuid);
        }
      }
    },

    stagedCCs() {

      if (this.stagedCCs.length) {

        this.setRedirectGuard({
          redirectMsg: 'Your subtitles may not be uploaded if you proceed.',
          redirectSecondaryMsg: 'Are you sure?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    }
  },

  created() {

    this.moment = moment;
    this.localTimezone = getLocalTimezone();

  },
  mounted() {

    this.setInitialComponentState();
    window.c = this;

  },
  props: {

    editedVod: Object,
    editedVodGuid: String,
    onReEncode: Function
  },
  methods: {
    ...mapMutations({

      updateVodListItem: 'channelManager/UPDATE_VOD_LIST_ITEM',
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
      updateEditedVod: "channelManager/UPDATE_EDITED_VOD",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
    }),
    ...mapActions({

      getFutureStoredCaptionsGuid: "channelManager/getFutureStoredCaptionsGuid",

      makeUploadCaptionsMetaRequest: "channelManager/makeUploadCaptionsMetaRequest",

      getFileCaptionsById: 'channelManager/getFileCaptionsById',

      updateFileCaptions: 'channelManager/updateFileCaptions',

      translateCaptions: 'channelManager/translateCaptions',

      encodeWithAutoSubs: 'channelManager/encodeWithAutoSubs',

      getCaptionsVttFile: 'channelManager/getCaptionsVttFile'
    }),

    beforeTranslationModalOpen (event) {

    	this.forTranslationFileGuid = event.params;
    },

    submitSubsTranslation() {

      if (!this.translationLangs.length) {

        return;
      }

      const parsedLangs = [];

      this.translationLangs.forEach(l => {

        parsedLangs.push(l.abbr);
      });


      const captionsGuidInEditedVod =
        this.editedVod &&
        this.editedVod.meta &&
        this.editedVod.meta.captionsGuid;

      const payload = {

        guid: captionsGuidInEditedVod,
        fileGuid: this.forTranslationFileGuid,
        data: { languages: parsedLangs }
      };

      this.isTranslationCreateInProgress = true;

      this.translateCaptions(payload)
        .then(() => {

          this.$toasted.success(`Captions translation started successfully`);
        })
        .finally(() => {

          this.$modal.hide('translation-create');

          this.isTranslationCreateInProgress = false;

          this.translationLangs = [];
        });

    },

    cancelAdvancedEditPreStage() {

      this.preStagedFileFormAdvancedEditor = null;

      this.isStageFileDisabled = true;
    },

    setupSubsEditorTask(nameKey, vttInput) {

      console.log(nameKey, vttInput);
      const videoUrl = this.editedVod.sourceProxy && this.editedVod.sourceProxy.proxyUrl;

      if (!videoUrl) {

        this.$toasted.error('Subtitles editor is only available for videos with proxyUrl.');
        return;
      }

      this.isAdvacedEditorActive = true;

      this.editPromiseTask = askForPromise();

      this.editPromiseTask.onComplete (response => {

        let responseVttText = '';

        if (response) {

          // Get the first response file, since currently only one is supported
          responseVttText = response[Object.keys(response)[0]];

          this.preStagedFileFormAdvancedEditor = new File([responseVttText], `${nameKey}.vtt`, {
            type: ""
          })

        } else {

          this.resetUploadInputState();
        }

        this.isStageFileDisabled = false;

        // close the editor
        this.isAdvacedEditorActive = false;
      });

      let components = {};

      this.subtitlesEditor ( {

        task: this.editPromiseTask,
        containerID: 'subs-editor-container',
        vtt: vttInput,
        videoURL: videoUrl,
        components
      });
    },

    loadSubsEditor(file) {
      /**
       * The subtitles editor library has NON SSR-friendly dependencies, thus
       * it can only be required in the mounted() hook and can not be imported!
       */
      // this.subtitlesEditor =
      //   require("../../../../subs-editor/src/main.js").subtitlesEditor;
      this.subtitlesEditor =
        require("../../external-libraries/subs-editor/main.js").subtitlesEditor;

      const vttInput = { };


      let nameKey = 'newCueFile';

      if (file) {

        const vttDownloadUrl = `${this.storedCaptionsData.cdnPath}${file.vtt}`;

        //TODO: @BE @Simeon sometimes the CDN returns CORS error from the browser for no reason (http || https) ???

        this.download(vttDownloadUrl).then((fileContent) => {

          nameKey = file.meta.screenName || file.guid; // use guid for backwards compatibility

          vttInput[nameKey] = fileContent;

          this.setupSubsEditorTask(nameKey, vttInput);
        });

      } else {

        this.setupSubsEditorTask(nameKey, vttInput);
      }
    },

    selectSubsUploadOption(option) {

      this.selectedUploadOption = option;
    },

    setInitialComponentState() {

      this.clearUploadState();

      if (this.editedVodGuid && this.editedVod.meta && this.editedVod.meta.captionsGuid) {

        this.getFileCaptions(this.editedVod.meta.captionsGuid);
      }
    },

    removeStagedCC(index) {

      if (this.isUploadNewCaptionsLoading) {

        return;
      }

      this.stagedCCs.splice(index, 1);
    },

    // division { string | cc / default }
    changeActiveDefaultCC(guid, division, newVal) {

      this.isStoredCaptionsDataModified = true;

      if (division === 'cc') {

        if (this.storedCaptionsData.cc === guid && !newVal) {

          const updatedStoredCaptionsData = {

            ...this.storedCaptionsData
          };

          delete updatedStoredCaptionsData.cc;

          this.storedCaptionsData = {

            ...updatedStoredCaptionsData
          };

        } else if (newVal) {

          this.storedCaptionsData = {

            ...this.storedCaptionsData,
            cc: guid
          };
        }

      }

      if (division === 'default') {

        if (this.storedCaptionsData.default === guid && !newVal) {

          const updatedStoredCaptionsData = {

            ...this.storedCaptionsData
          };

          delete updatedStoredCaptionsData.default;

          this.storedCaptionsData = {

            ...updatedStoredCaptionsData
          };

        } else if (newVal) {

          this.storedCaptionsData = {

            ...this.storedCaptionsData,
            default: guid
          };
        }
      }
    },

    changeActiveCCLang(event, ccIndex) {

      // NOTE: The actual change is handled from the select's model.
      // Add additional logic here to do it manually, if problems arrise.

      this.isStoredCaptionsDataModified = true;
    },

    changeActiveCCDescription(event, ccIndex) {

      // NOTE: The actual change is handled from the input's model.
      // Add additional logic here to do it manually, if problems arrise.

      this.isStoredCaptionsDataModified = true;
    },

    changeActiveCCShouldEncode(event, ccIndex) {

      this.storedCaptionsData.files[ccIndex] = {

        ...this.storedCaptionsData.files[ccIndex],
        meta: {
          ...this.storedCaptionsData.files[ccIndex].meta,
          shouldEncode:  !this.storedCaptionsData.files[ccIndex].meta.shouldEncode
        }
      }


      this.isStoredCaptionsDataModified = true;
    },

    changeActiveCCAutoTranslate(event, ccIndex) {

      this.storedCaptionsData.files[ccIndex] = {

        ...this.storedCaptionsData.files[ccIndex],
        meta: {
          ...this.storedCaptionsData.files[ccIndex].meta,
          autoTranslate:  !this.storedCaptionsData.files[ccIndex].meta.autoTranslate
        }
      }


      this.isStoredCaptionsDataModified = true;
    },



    stageActiveCCForRemove(guid, fileIndex) {

      if (this.storedCaptionsData.files.length === 1) {

        this.$toasted.error('At least one file should remain.');

        return;
      }

      this.isStoredCaptionsDataModified = true;

      this.forRemoveCCGuids.push(guid);

      if (guid === this.storedCaptionsData.cc) {

        this.changeActiveDefaultCC(guid, 'cc', false);
      }

      if (guid === this.storedCaptionsData.default) {

        this.changeActiveDefaultCC(guid, 'default', false);
      }

      this.storedCaptionsData.files.splice(fileIndex, 1);
    },

    updateActiveCCs() {

      this.isStoredCaptionsDataLoading = true;

      const filesAsObject = {};

      this.storedCaptionsData.files.forEach(f => {

        filesAsObject[f.guid] = {

          lang: f.meta.lang
        };

        f.meta.description && (filesAsObject[f.guid].description = f.meta.description);
        f.meta.screenName && (filesAsObject[f.guid].screenName = f.meta.screenName);
        f.meta.shouldEncode !== undefined && (filesAsObject[f.guid].shouldEncode = f.meta.shouldEncode);
        f.meta.autoTranslate !== undefined && (filesAsObject[f.guid].autoTranslate = f.meta.autoTranslate);
      });

      const DTO = {

        files: filesAsObject
      };

      this.storedCaptionsData.cc && (DTO.cc = this.storedCaptionsData.cc);

      this.storedCaptionsData.default && (DTO.default = this.storedCaptionsData.default);

      this.forRemoveCCGuids.length && (DTO.remove = this.forRemoveCCGuids);

      this.updateFileCaptions({

          guid: this.storedCaptionsData.guid,
          data: DTO

        }).then(resp => {

          this.isStoredCaptionsDataModified = false;
          this.forRemoveCCGuids = [];

        }).finally(() => {

          this.isStoredCaptionsDataLoading = false;
        });
    },

    cancelUpdateActiveCCs() {

      this.getFileCaptions(this.editedVod.meta.captionsGuid);
    },

    getFileCaptions(guid) {

      this.storedCaptionsData = {};
      this.isStoredCaptionsDataModified = false;

      this.isStoredCaptionsDataLoading = true;

      return this.getFileCaptionsById({ guid })
        .then(captionsData => {

          const captionsFiles = captionsData.files;

          captionsFiles.forEach(f => {

            const shouldEncode = f.meta.shouldEncode === false ? false : true;
            f.meta.shouldEncode = shouldEncode;
          });

          this.storedCaptionsData = captionsData;

          const pendingTranslations = { };

          this.storedCaptionsData.translationJobs &&
            this.storedCaptionsData.translationJobs.forEach((j) => {

              if (!pendingTranslations[j.translatedFrom]) {

                pendingTranslations[j.translatedFrom] = [];
              }

              if (j.status !== 'COMPLETED') {

                pendingTranslations[j.translatedFrom].push(j);
              }
            });

          this.pendingTranslations = { ...pendingTranslations };

        })
        .finally(() => {

          this.isStoredCaptionsDataLoading = false;
        });
    },

    clearUploadState() {

      this.resetUploadInputState();

      this.isDefault = false;
      this.isCC = false;
      this.selectedCCLanguage = 'ENG';
      this.newCCScreenName = '';
      this.newCCDescription = '';
      this.shouldEncode = true;
      this.autoTranslate = false;
      this.stagedCCs = [];
      this.storedCaptionsData = {};
      this.isStoredCaptionsDataModified = false;
      this.forRemoveCCGuids = [];
    },

    stageCC() {

      if (this.newUploadsFormState.$invalid) {

        return;
      }

      const stagedFiles = this.$refs.ccInput ?
        this.$refs.ccInput.files : [this.preStagedFileFormAdvancedEditor];

      const isDefault = this.isDefault;
      const isCC = this.isCC;

      let updatedStagedCCs = [];

      for (let i = 0; i < this.stagedCCs.length; i++) {

        const stagedCC = this.stagedCCs[i];

        updatedStagedCCs[i] = {

          ...stagedCC
        };

        // Since only one file can be CC or Default, if the new file is such
        // flags of any old files should be turned to false
        if (isDefault) {

          updatedStagedCCs[i].default = false;
        }

        if (isCC) {

          updatedStagedCCs[i].cc = false;
        }
      }

      updatedStagedCCs[updatedStagedCCs.length] = {

        file: stagedFiles[0],
        default: isDefault,
        cc: isCC,
        lang: this.selectedCCLanguage,
        screenName: this.newCCScreenName || stagedFiles[0].name,
        description: this.newCCDescription,
        autoTranslate: this.autoTranslate,
        shouldEncode: this.shouldEncode
      };

      this.stagedCCs = [

        ...updatedStagedCCs
      ];

      this.resetUploadInputState();

      // reset the file meta fields
      this.isDefault = false;
      this.isCC = false;
      this.selectedCCLanguage = 'ENG';
      this.newCCScreenName = '';
      this.newCCDescription = '';
      this.shouldEncode = true;
      this.autoTranslate = false;
      this.isStageFileDisabled = true;
    },

    autoGenerateSubs(type) {

      this.isAutoSubsDisabled = true;

      let params = {

        vod: this.editedVod

      }

      if (type !== null && !this.editedVod.meta.autoTranslate) {

        params['query'] = {'autoTranslate':'1'};
      } else {

        params['query'] = {'autoTranslate':'0'};
      }


      this.encodeWithAutoSubs(params)
        .then(() => {

        })
        .finally(() => {

          this.isAutoSubsDisabled = false;
        });
    },

    sendUploadRequest(stagedCC, captionsGuid, token) {

      const file = stagedCC.file;
      const meta = {

        lang: stagedCC.lang
      };

      stagedCC.screenName && (meta.screenName = stagedCC.screenName);

      stagedCC.description && (meta.description = stagedCC.description);

      stagedCC.shouldEncode !== undefined && (meta.shouldEncode = stagedCC.shouldEncode);

      stagedCC.autoTranslate !== undefined && (meta.autoTranslate = stagedCC.autoTranslate);

      this.makeUploadCaptionsMetaRequest({ meta }).then((data) => {

        const {
          bucket,
          bucketRegion,
          cognitoRegion,
          identityPoolId,
          logins
        } = this.uploadConfig;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
          {
            IdentityPoolId: identityPoolId,
            Logins: {
              [logins.cognito]: token
            }
          },
          {
            region: cognitoRegion
          }
        );

        const augmentedMeta = {

          metaGuid: data.guid,
          captionsGuid,
          sourceGuid: this.editedVod.guid
        };

        // Add `default` && `cc` only if they are selected
        stagedCC.default && (augmentedMeta.default = 'default');
        stagedCC.cc && (augmentedMeta.cc = 'cc');

        file.meta = augmentedMeta;


        /**
        * Get the file extension, generate an md5 hash of the file name.
        * Add a timestamp to insure an unique key will be generated.
        */
        var extension = file.name.substr(file.name.lastIndexOf(".") + 1);

        const key = `${md5(file.name + new Date().getTime())}.${extension}`;

        let params = {
          Bucket: bucket,
          Key: key,
          Body: file,
          Metadata: augmentedMeta
        };

        file.s3upload = new S3.ManagedUpload({
          params: params
        });

        file.s3upload.on("httpUploadProgress", progress => {
          if (progress.total) {

            var percent = (progress.loaded * 100) / progress.total;

            const updatedFileWithProgress = {

              ...stagedCC,
              progressPercentage: percent
            };

            this.stagedCCs.splice(stagedCC.index, 1, updatedFileWithProgress);
          }
        });

        file.s3upload.send((err, data) => {

          if (err) {

            console.error("s3upload err", err);


          } else {

            let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

            let params = {
              Bucket: bucket,
              ACL: "bucket-owner-full-control",
              Key: key
            };

            s3.putObjectAcl(params, (err, data) => {

              if (err) {

                console.error("putObjectAcl err", err);

              } else {

                this.onUploadFinished();
              }
            });
          }
        });
      });
    },

    onUploadFinished() {

      this.uploadedCCsCount++;

      this.$toasted.success(`Your file was uploaded successfully.`);

      if (this.uploadedCCsCount === this.stagedCCs.length) {

        this.uploadedCCsCount = 0;
        this.stagedCCs = [];
        this.isUploadNewCaptionsLoading = false;
      }
    },

    download(downloadLink) {

      return this.getCaptionsVttFile({ url: downloadLink });
    },

    sendUploadRequestForStagedCCs(captionsGuid) {

      this.$Amplify.Auth.currentSession().then(session => {

        const token = session.idToken.jwtToken;


        for (let i = 0; i < this.stagedCCs.length; i++) {

          const stagedCC = this.stagedCCs[i];

          const augmentedCC = {
            ...stagedCC,
            index: i
          };

          this.sendUploadRequest(augmentedCC, captionsGuid, token)
        }
      });
    },

    uploadStagedCC() {

      // Nothing to upload
      if (!this.stagedCCs.length) {

        return;
      }

      if (this.isUploadNewCaptionsLoading) {

        return;
      }

      this.isUploadNewCaptionsLoading = true;

      // The captionsGuid is already generated
      if (this.editedVod.meta && this.editedVod.meta.captionsGuid) {

        this.sendUploadRequestForStagedCCs(this.editedVod.meta.captionsGuid);
        return;
      }

      // The captionsGuid is NOT generated. Generate one, and upload stagedCCs afterwards
      this.getFutureStoredCaptionsGuid({ sourceGuid: this.editedVodGuid })
        .then((response) => {

          const augmentedVod = {

            ...this.editedVod,
            meta: {

              ...this.editedVod.meta,
              captionsGuid: response.guid
            }
          };

          this.updateVodListItem(augmentedVod);
          this.setSingleSourcePlayed(augmentedVod);

          this.sendUploadRequestForStagedCCs(response.guid);
        });
    },

    validateFileInput() {

      if (this.$refs.ccInput.files.length === 0) {

        this.isStageFileDisabled = true;

      } else {

        const file = this.$refs.ccInput.files[0];
        const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);

        if (!this.allowedCaptionsExtensions[fileExtension]) {

          this.$toasted.error('The selected file type is not supported.');

          this.resetUploadInputState();
          return;
        }

        this.isStageFileDisabled = false;
      }

      this.setFileUploadLabel();
    },

    resetUploadInputState() {

      // reset the preStagedFileFormAdvancedEditor
      this.preStagedFileFormAdvancedEditor = null;

      // reset the file field
      if (!this.$refs.ccInput) {

        return;
      }

      this.$refs.ccInput.type = 'text';
      this.$refs.ccInput.type = 'file';

      this.setFileUploadLabel();
    },

    setFileUploadLabel() {

      this.fileUploadLabel = (this.$refs.ccInput &&
        this.$refs.ccInput.files &&
        this.$refs.ccInput.files[0] ?
        this.$refs.ccInput.files[0].name : 'Select a File');
    }
  },
  computed: {
    ...mapGetters({
      uploadConfig: "app/uploadConfig",
      msgBusMsgList: 'app/msgBusMsgList',
      singleSourcePlayed: 'channelManager/singleSourcePlayed',
    }),

    isEditedVodEncoding() {

      return this.singleSourcePlayed.guid === this.editedVodGuid && (
        this.singleSourcePlayed.status === 'ingest' ||
        this.singleSourcePlayed.status === 'encoding'
      );
    }
  },
}
</script>

<style lang="scss">



.translation-create-modal.v--modal-box {

  overflow: visible !important;
  padding: 24px;
}

.tags-and-cc-component {


  .silver {

    @apply text-black-400;
  }

  .btn.tab {

    border-right: none;

    &:not(.active) {

      box-shadow: none;
    }
  }

  .select {

    padding: 0.125rem 0.25rem;
    border-width: 1px;
    font-size: .875rem;
    box-shadow: inset 0 2px 0 #f7f7f7;

    &:hover {

      border-color: var(--color-gray-300);
    }
  }

  .choose-file-group {

    border: 1px solid var(--color-gray-300) !important;

    border-radius: 4px;
    overflow: hidden;

    input, .select {

      min-width: auto;
      max-width: auto;
      padding: 0.5rem;
      margin: 0;
      border: none;
      line-height: 1.6;
      width: 100px;
    }

    .select {

    }

    input {

      font-size: 12px;
    }

    .btn {

      height: auto !important;
    }
  }

  .custom-file-upload {

    display: inline-block;
    line-height: 1.7;
    width: 170px;
    margin: 0;
    padding: 6px 12px;
    font-weight: 400;
    color: var(--selectedBgColor);
    text-decoration: underline;
    border-left: 1px solid var(--color-gray-300);
    border-right: 1px solid var(--color-gray-300);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;


    input[type="file"] {
      display: none;
    }
  }

  .upload-btn.size-m {

    margin-top: 1px;
  }

  .re-encode-btn.btn {

    background: #f2994a;
    color: white;
  }

  .stage-new-section {

    position: relative;
  }

  .vf-form-invalid .stage-new-section {

    margin-bottom: 48px;
  }

  .description-err-msgs {

    position: absolute;
    bottom: -42px;
    font-size: 12px;
    width: 160px;
    left: 400px;
  }

  .subs-editor-holder {
    textarea{
      @apply text-black-700;
    }
  }

  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/

  .multiselect {

    /* min-width: 100%;
    max-width: 100%; */
  }

  .multiselect__option .multiselect__option--highlight .multiselect__option--selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .multiselect__option.multiselect__option--highlight, .multiselect__option.multiselect__option--highlight:after {
    @apply text-blue-grey-800;
  }

  .multiselect__single {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .multiselect__tag {

    @apply text-blue-grey-800;
 }

  .multiselect__tag-icon:after {
    content: none;
  }
}
</style>
