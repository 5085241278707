/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'set-start': {
    width: 17,
    height: 11,
    viewBox: '0 0 17 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.787 3.878L6.644 6.94a.424.424 0 01-.586-.157.445.445 0 01-.058-.22V.437C6 .196 6.192 0 6.429 0c.075 0 .15.02.215.06l5.143 3.062a.443.443 0 010 .756z" _fill="#4F4F4F"/><path pid="1" _stroke="#4F4F4F" d="M.5 7v3M17 10.5H0"/>'
  }
})
