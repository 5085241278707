const eventDetails = (state) => state.eventDetails;
const mixerSettings = (state) => state.mixerSettings;
const isAssignSpeakersToEventModalVisible = (state) => state.isAssignSpeakersToEventModalVisible;
const isAssignImagesOpened = (state) => state.isAssignImagesOpened;
const isOverlayBgImagesOpened = (state) => state.isOverlayBgImagesOpened;
const isOverlayImagesOpened = (state) => state.isOverlayImagesOpened;
const isAssignVideosModalOpened = (state) => state.isAssignVideosModalOpened;

const isEventStreamOptionModalOpened = (state) => state.isEventStreamOptionModalOpened;
const isEventStreamAssetsModalOpened = (state) => state.isEventStreamAssetsModalOpened;
const isEventStreamOverlaysModalOpened = (state) => state.isEventStreamOverlaysModalOpened;
const isEventStreamChannelModalOpened = (state) => state.isEventStreamChannelModalOpened;
const isEventStreamUrlModalOpened = (state) => state.isEventStreamUrlModalOpened;
const isEventCoverImageModalOpened = (state) => state.isEventCoverImageModalOpened;


export {
  eventDetails,
  mixerSettings,
  isAssignSpeakersToEventModalVisible,
  isAssignImagesOpened,
  isOverlayBgImagesOpened,
  isOverlayImagesOpened,
  isAssignVideosModalOpened,
  isEventStreamOptionModalOpened,
  isEventStreamAssetsModalOpened,
  isEventStreamOverlaysModalOpened,
  isEventStreamChannelModalOpened,
  isEventStreamUrlModalOpened,
  isEventCoverImageModalOpened
}