<template>
  <section class="workflow-details-container flex flex-column flex-auto pt-6 pl-6 pb-6">

    <div class="workflow-details-section flex-auto flex-column">

      <!-- TODO: refactor to ioio components
       use action-confirm-modal-component as reference
      -->
      <extend-duration-workflow-modal-component
        :duration="this.selectedWorkflowSchedule.duration"
        :isRequestPending="this.isRequestPending"
        :onEdit="this.extendScheduleForWorkflow"
        :onCancel="() => closeActionModal('extend-duration-workflow')"/>

      <edit-workflow-modal-component
        :workflow="this.selectedWorkflow"
        :onEdit="() => closeActionModal('edit-workflow')"
        :onCancel="editWorkflow"/>


      <!-- TODO: refactor to ioio components
       use action-confirm-modal-component as reference -->
      <duplicate-workflow-modal-component
        :onConfirm="onDuplicateWorkflowSuccess"
        :onCancel="() => {}"
      />

      <section class="h-full">

        <div class="flex flex-column relative h-full">

          <div
            class="h-full flex flex-column"
            :class="{ 'is-preview-collapsed': isPreviewCollapsed }"
          >

            <!-- Stream Preview -->
            <div class="">

              <section class="p-0 flex items-center justify-between">

                <section class="flex items-center flex-auto mr-16">

                  <ioio-tooltip
                    class="tooltip-z cursor-pointer"
                    :text="selectedWorkflow.name"
                    initialPostion="bottom-center"
                    style="max-width: 80%"
                  >

                    <h4 class="text-2xl text-bold truncate">{{ selectedWorkflow.name }}

                    </h4>
                  </ioio-tooltip>
                  <div class="flex items-center ml-4">

                    <ioio-label
                      :text="getReadableChannelStatus(selectedWorkflow.status).label"
                      :type="
                        getReadableChannelStatus(selectedWorkflow.status).labelType
                      "
                      :icon="getReadableChannelStatus(selectedWorkflow.status).icon"
                      size="size-s"
                    />

                    <ioio-label
                      v-if="elapsedWorkflowsRunningTime && selectedWorkflow.status === 'RUNNING'"
                      :text="elapsedWorkflowsRunningTime"
                      type="success"
                      size="size-s"
                      class="ml-2"
                    />
                  </div>
                </section>

                <section class="flex items-center">

                  <ioio-button
                    v-if="selectedWorkflow && selectedWorkflow.id"
                    class="close-preview-btn z-1 ml-8"
                    variant="minimal"
                    shape="circle"
                    size="xs"
                    @click.stop.prevent="onPreviewClose"
                  >
                    <ioio-icon icon="fa-xmark" class="w-5 h-5 ml-2"/>
                  </ioio-button>
                </section>
              </section>

              <p class="text-xs truncate mb-4">{{ selectedWorkflow.description }}</p>

              <section class="flex mb-4">

                <ioio-button
                  v-if="selectedWorkflow.status === 'READY'"
                  :disabled="!actionsEnabled"
                  :class="{ disabled: !actionsEnabled }"
                  class="flex mr-6"
                  variant="outline" size="small"
                  @click="
                    () =>
                      makeChannelRequest(
                        'makeCreateChannelFromWorkflowRequest',
                        this.selectedWorkflow
                      )
                  ">
                  <loader-component
                    size="size-xs"
                    variant="clean"
                    v-if="!actionsEnabled">wait
                  </loader-component>

                  <span v-else class="flex flex-none items-center">
                    <ioio-icon
                      class="mr-2 h-2 w-2"
                      icon="fas-play"
                    />
                    Start live
                  </span>
                </ioio-button>

                <action-confirm-modal-component
                  v-else-if="isWorkflowPreviewShown && actionsEnabled"
                  dialogWidth="500"
                  dialogHeight="225"
                  dialogOpenBtnLabel="Stop"
                  dialogOpenBtnSize="small"
                  :isDialogOpenBtnVisible="true"
                  actionConfirmBtnLabel="Stop Workflow"
                  :actionConfirmLabel="`Stopping this workflow will terminate all resources associated with it and any unmanaged inputs will be destroyed. Are you sure you want to stop workflow ${selectedWorkflow.name} ?`"
                  :isActionConfirmBtnVisible="actionsEnabled"
                  :onActionConfirm="stopChannel"
                  dialogOpenBtnVariant="danger"
                  actionConfirmBtnVariant="danger"
                  class="mr-6"
                />

                <div
                  class="details-action-open-btn mr-2"
                  v-if="selectedWorkflow.status === 'READY' || selectedWorkflow.status === 'RUNNING' || selectedWorkflow.status === 'PAUSED'"
                >

                  <ioio-dropdown-base
                    alignX="left"
                    :fullWidth="false"
                    class="text-base sorting-picker"
                    closeOnSelect
                  >
                    <template slot="title">

                      <ioio-button
                        type="secondary"
                        variant="outline"
                        size="small"
                        class=""
                      >
                        Pause options
                        <ioio-icon
                          icon="fal-chevron-down"
                          class="w-3 h-3"
                        />
                      </ioio-button>
                    </template>


                    <schedule-channel-pause-modal-component
                      dialogId="schedulePause"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnLabel="Schedule pause"
                      dialogHeaderLabel="Schedule pause"
                      dialogOpenBtnSize="medium"
                      :isDialogOpenBtnVisible="true"
                      actionConfirmBtnLabel="Schedule"
                      :actionConfirmLabel="`Are you sure you want to schedule a pause for workflow ${selectedWorkflow.name} ?`"
                      actionConfirmSecondaryLabel="Scheduling a pause will allow you to pause the channel in a predefined time."
                      :onActionConfirm="saveScheduledPauseLiveChannel"
                      dialogOpenBtnVariant=""
                      actionConfirmBtnVariant="danger"
                      :selectedWorkflow="selectedWorkflow"
                    />

                    <schedule-channel-pause-modal-component
                      dialogId="scheduleResume"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnLabel="Schedule resume"
                      dialogHeaderLabel="Schedule resume"
                      dialogOpenBtnSize="medium"
                      :isDialogOpenBtnVisible="true"
                      actionConfirmBtnLabel="Schedule"
                      :actionConfirmLabel="`Are you sure you want to schedule a resume for workflow ${selectedWorkflow.name} ?`"
                      actionConfirmSecondaryLabel="Scheduling a resume will allow you to resume the channel in a predefined time."
                      :onActionConfirm="saveScheduledResumeLiveChannel"
                      dialogOpenBtnVariant=""
                      actionConfirmBtnVariant="primary"
                      :selectedWorkflow="selectedWorkflow"
                    />

                    <unschedule-channel-pause-modal-component
                      dialogId="unschedulePause"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnLabel="Manage schedules"
                      dialogOpenBtnSize="medium"
                      :isDialogOpenBtnVisible="true"
                      actionConfirmBtnLabel="Manage schedules"
                      :actionConfirmLabel="`Manage schedules @Ico. Are you sure you want to pause workflow ${selectedWorkflow.name} ?`"
                      dialogOpenBtnVariant=""
                      actionConfirmBtnVariant="danger"
                      :selectedWorkflow="selectedWorkflow"
                      :selectedWorkflowChannelData="selectedWorkflowChannelData"
                    />


                    <action-confirm-modal-component
                      v-if="isSelectedWorkflowPaused"
                      dialogId="resumeConfirm"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnLabel="Resume immediately"
                      dialogOpenBtnSize="medium"
                      :isDialogOpenBtnVisible="true"
                      actionConfirmBtnLabel="Resume"
                      :actionConfirmLabel="`Are you sure you want to immediately resume workflow ${selectedWorkflow.name} ?`"
                      :onActionConfirm="triggerUnpauseLiveChannel"
                      dialogOpenBtnVariant=""
                      actionConfirmBtnVariant="primary"
                    />

                    <action-confirm-modal-component
                      v-if="selectedWorkflow.status === 'RUNNING'"
                      dialogId="pauseConfirm"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnLabel="Pause immediately"
                      dialogOpenBtnSize="medium"
                      :isDialogOpenBtnVisible="true"
                      actionConfirmBtnLabel="Pause"
                      :actionConfirmLabel="`Are you sure you want to immediately pause workflow ${selectedWorkflow.name} ?`"
                      :onActionConfirm="triggerPauseLiveChannel"
                      dialogOpenBtnVariant=""
                      actionConfirmBtnVariant="danger"
                    />


                  </ioio-dropdown-base>
                </div>






                <!-- hide edit -->
                <!-- <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  @click="() => {}"
                  class="mr-2"
                >
                  <ioio-icon
                    icon="fa-light fa-pen"
                    class="w-3 h-3"
                  />
                  Edit
                </ioio-button> -->

                <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  @click="() => copyToClipboard('PlaybackUrl')"
                  class="mr-2"
                  v-if="selectedWorkflow.status === 'RUNNING'"
                >
                  <ioio-icon
                    icon="fa-light fa-clone"
                    class="w-3 h-3"
                  />
                  Copy Playback URL
                  <input
                    class="copy-to-clipboard-input"
                    type="text"
                    id="PlaybackUrl"
                    :value="cdnPlaybackUrl"
                  />
                </ioio-button>

                <div class="details-action-open-btn mr-4">

                  <ioio-dropdown-base
                    alignX="right"
                    :fullWidth="false"
                    class="text-base sorting-picker">
                    <template slot="title">
                      <!-- <div
                        class="inline-flex items-center justify-center cursor-pointer"
                      >

                        <div class=" multiselect__tags is-small is-borderless pr-0 mr-3">Manage workflow</div>
                        <ioio-icon icon="fal-chevron-down"></ioio-icon>
                      </div> -->

                      <ioio-button
                        type="secondary"
                        variant="outline"
                        size="small"
                        class="mr-2"
                      >
                        Manage workflow
                        <ioio-icon
                          icon="fal-chevron-down"
                          class="w-3 h-3"
                        />
                      </ioio-button>
                    </template>


                    <delete-confirm-modal-component

                      dialogId="delete-workflow"
                      dialogWidth="500"
                      dialogHeight="225"
                      dialogOpenBtnSize="size-s"
                      delConfirmBtnLabel="Delete"
                      :delConfirmLabel="`Are you sure you want to delete workflow ${selectedWorkflow.name} ?`"
                      delConfirmSecondaryLabel="If the workflow has an archive you will not be able to access it."
                      :onDeleteConfirm="deleteWorkflow"
                      dialogNewButton
                      :isLoadingIndicatorVisible="!actionsEnabled"
                    >

                      <ioio-button
                        class="dropdown__menu-item"
                        type="minimal"
                      >
                        <span class="red">Delete</span>

                      </ioio-button>

                    </delete-confirm-modal-component>

                    <ioio-button
                      v-for="(action, i) in workflowActions" :class="{ disabled: !action.enabledForStatus[selectedWorkflow.status]}"
                      :key="i"
                      @click="triggerWorkflowAction(
                        selectedWorkflow,
                        action.type,
                        i
                      )"
                      class="dropdown__menu-item"
                      type="minimal"
                    >
                      <span :class="action.className">{{ action.label }}</span>

                    </ioio-button>
                  </ioio-dropdown-base>
                </div>
              </section>

              <section
                v-if="isWorkflowPreviewShown"
                class="video-previews-box flex justify-between"
                :class="{ 'secondary-stream-active': shouldSecondaryStreamPlay }"
              >

                <div class="flex-auto bg-black-200" v-if="shouldSecondaryStreamPlay">

                  <div class="live-workflows-preview-vid ratio-16-9 relative">

                    <video
                      class="absolute"
                      id="oven-video-container"
                      width="100%"
                      height="100%"
                      @click="preventVideoElPauseOnClick"
                      controls
                      poster="~@assets/SVGs/refresh.svg">
                    </video>

                    <ioio-label
                      :text="selectedOvenplayerSourceName"
                      type="normal"
                      class="m-2 absolute t-0 l-0 r-auto"
                      size="size-s"
                      style="bottom: auto; right: auto"
                    />

                    <div
                      class="h-full w-full flex flex-column justify-center items-center"
                      v-show="!shouldSecondaryStreamPlay"
                    >

                      <ioio-icon class="mb-2 text-black-500 w-8 h-8" icon="fa fa-video-camera"/>
                      <h4
                        class="text-xs text-black-400">No preview available
                      </h4>
                    </div>
                  </div>
                </div>

                <div
                  class="flex-auto"
                  :class="shouldSecondaryStreamPlay ? 'ml-4' : 'mr-4'"
                >
                  <div class="live-workflows-preview-vid ratio-16-9 relative">

                    <video
                      v-show="shouldStreamPlay"
                      class="absolute z-1"
                      ref="previewVid"
                      id="video-container"
                      width="100%"
                      height="100%"
                      @click="preventVideoElPauseOnClick"
                      controls
                      poster="~@assets/SVGs/refresh.svg">
                    </video>
                    <div class="h-full w-full flex flex-column justify-center items-center">

                      <div
                        v-show="!shouldStreamPlay"
                        class="h-full w-full flex flex-column justify-center items-center"
                      >

                        <ioio-icon class="mb-2 text-black-500 w-8 h-8" icon="fa fa-video-camera"/>
                        <h4
                          class="text-xs text-black-400">No preview available
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
              <section
                v-else-if="selectedWorkflowFailReasonArr.length"
                class="text-xs text-red-500"
              >
                <p
                  v-for="(r, i) in selectedWorkflowFailReasonArr"
                  :key="i"
                  class="mb-1"
                >
                  {{ r }}
                </p>
              </section>
            </div>

            <ioio-tabs
              class="flex flex-auto flex-column mt-6"
              @input="setSelectedTabName"
              v-if="selectedTabName"
            >

              <ioio-button

                type="primary"
                variant="outline"
                size="small"
                class="expand-details-toggle"
                iconRight="fal-chevron-down"
                v-if="isWorkflowPreviewShown && isPreviewCollapsed"
                @click="togglePreviewCollapsed">Collapse details
              </ioio-button>
              <ioio-button

                type="primary"
                variant="outline"
                size="small"
                class="expand-details-toggle"
                iconRight="fal-chevron-up"
                v-if="isWorkflowPreviewShown && !isPreviewCollapsed"
                @click="togglePreviewCollapsed">Expand details
              </ioio-button>


              <ioio-tab
                name="Details"
                :selected="selectedTabName === 'Details'"
                icon="fa-regular fa-circle-info"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >



                <section class="flex">

                  <section class="w-1/2 pr-3">

                    <h3 class="text-lg mt-2 mb-4">Details</h3>



                    <ioio-collapsibles
                      autoclose
                      size="small"
                      v-if="selectedWorkflowSchedule.startDateTime && !areChannelDetailsLoading"
                    >
                      <ioio-collapsible>
                        <template slot="title">
                          <ioio-icon class="mr-2 mb-0.5 text-blue-500 w-5 h-5" icon="fat-calendar-check"/> Schedule
                        </template>
                        <template slot="content">
                          <section class="ds-collapsible__els">
                            <div
                              class="ds-collapsible__el"
                            >
                              <section class="flex justify-between mb-4">
                                <section class="text-xs mr-4">


                                  <div class="mb-2" v-if="selectedWorkflowSchedule.startDateTime">
                                    <ioio-icon class="mr-1 mb-0.5 text-blue-500 w-3 h-3" icon="fat-calendar-check"/>
                                    Starts:
                                    <readable-time-component
                                      :timestamp="selectedWorkflowSchedule.startDateTime"
                                    />
                                  </div>

                                  <div class="text-xs" v-if="selectedWorkflowScheduleRecurrence && selectedWorkflowScheduleRecurrence.endDate">
                                    <ioio-icon class="mr-1 mb-0.5 text-red-700 h-3 w-3" icon="fat-calendar-check"/>
                                    Schedule End Date (inclusive):
                                    <readable-time-component
                                      :timestamp="selectedWorkflowScheduleRecurrence.endDate"
                                    />
                                  </div>
                                </section>
                                <section>
                                  <section v-if="selectedWorkflowScheduleRecurrence" class="mb-2 text-xs">
                                    Recurrence: {{ selectedWorkflowParsedRecurrenceInfo }}
                                  </section>
                                  <section v-if="selectedWorkflowSchedule.duration" class="text-xs">
                                    Duration: {{ parseDuration(selectedWorkflowSchedule.duration) }}
                                  </section>
                                </section>
                              </section>

                              <aside>
                                <div v-if=!isRequestPending>
                                  <ioio-button

                                    type="danger"
                                    variant="outline"
                                    size="small"
                                    class="mr-4"
                                    @click="clearScheduleForWorkflow">Clear Schedule
                                  </ioio-button>

                                  <ioio-button
                                    type="primary"
                                    variant="outline"
                                    size="small"
                                    @click="promptExtendScheduleForWorkflow">Extend Duration
                                  </ioio-button>
                                </div>

                                <div class="mt1" v-else><loader-component height="28px"/></div>
                              </aside>
                            </div>
                          </section>
                        </template>
                      </ioio-collapsible>
                    </ioio-collapsibles>

                    <section class="flex justify-between items-start mb-5">
                      <div class="text-black-600 whitespace-nowrap mr-2">Workflow ID</div>
                      <div class="flex flex-auto">
                        <span
                          @click="copyToClipboard('selectedWorkflowId')"
                          class="url flex items-center truncate justify-end"
                        >
                          <span>{{ selectedWorkflowId }}</span>
                          <ioio-icon
                            icon="fas-clone"
                            class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                          />
                        </span>
                        <input
                          class="copy-to-clipboard-input"
                          type="text"
                          id="selectedWorkflowId"
                          :value="selectedWorkflowId"
                        >
                      </div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Blueprint ID</div>
                      <div>{{ this.selectedWorkflowBlueprint && this.selectedWorkflowBlueprint.id }}</div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Description</div>
                      <div>{{ selectedWorkflow.description }}</div>
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Region</div>
                      <div>{{ selectedWorkflow.region }}</div>
                    </section>

                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Created</div>

                      <readable-time-component
                        :timestamp="selectedWorkflow.created"
                      />

                    </section>

                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Start requested at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.startRequestedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Started running at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.startedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Stop requested at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.stopRequestedDateTime"
                      />
                    </section>
                    <section class="flex justify-between mb-5">
                      <div class="text-black-600">Stopped at</div>

                      <readable-time-component
                        :timestamp="selectedWorkflowChannelData.stoppedDateTime"
                      />
                    </section>
                  </section>

                  <aside class="w-1/2 pl-3">

                    <h3 class="text-lg mt-2 mb-4">Inputs</h3>
                    <div
                      class=""
                      v-if="hasSelectedWFChannelDataInputs"
                    >

                      <section
                        v-for="(input, i) in selectedWorkflowChannelData.endpoints.input"
                        :key="i"
                        class="pb-2"
                      >

                        <section v-for="(endpoint, j) in input.endpoints" class="" :key="j">

                          <h4 class="text-base mb-2 text-black-600">{{ input.inputName }}</h4>
                          <p class="bg-black-100 mb-2 p-2 rounded">

                            <span
                              @click="copyToClipboard('copy-input-' + i + j)"
                              class="url flex items-center"><span>{{endpoint}}</span>
                              <ioio-icon
                                icon="fas-clone"
                                class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                              />
                            </span>
                            <input
                              class="copy-to-clipboard-input"
                              type="text"
                              :id="'copy-input-' + i + j"
                              :value="endpoint"
                            >
                          </p>
                        </section>

                        <section class="ml-4" v-if="input.outputs && input.outputs.length">

                          <h5 class="text-sm text-black-600 mb-2 mt-3">Output</h5>

                          <section v-for="(output, k) in input.outputs" class="flex flex-column" :key="output.url + k">

                            <p class="bg-black-100 mb-2 p-2 rounded">

                              <span
                                @click="copyToClipboard('copy-input-' + output.url + 'k')"
                                class="flex items-center">Url: <span class="url mr-3">{{ output.url}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.url + 'k'"
                                :value="output.url"
                              >
                            </p>

                            <p
                              class="bg-black-100 mb-2 p-2 rounded"
                              v-if="output.streamId"
                            >
                              <span
                                @click="copyToClipboard('copy-input-' + output.streamId + 's')"
                                class="flex items-center">Stream ID:<span class="url mr-3">{{ output.streamId}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.streamId + 's'"
                                :value="output.streamId"
                              >
                            </p>

                            <p
                              class="bg-black-100 mb-2 p-2 rounded"
                              v-if="output.remoteId"
                            >
                              <span
                                @click="copyToClipboard('copy-input-' + output.remoteId + 'r')"
                                class="flex items-center">Remote ID:<span class="url mr-3">{{ output.remoteId}}</span>
                                <ioio-icon
                                  icon="fas-clone"
                                  class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                                />
                              </span>
                              <input
                                class="copy-to-clipboard-input"
                                type="text"
                                :id="'copy-input-' + output.remoteId + 'r'"
                                :value="output.remoteId">
                            </p>
                          </section>
                        </section>
                      </section>

                    </div>
                    <div class="p-4 bg-black-100 rounded mb-8" v-else>No data available at this time.</div>

                    <h3 class="text-lg mt-4 mb-4">Output Urls</h3>

                    <div class="" v-if="selectedWorkflowChannelData.endpoints &&
                      selectedWorkflowChannelData.endpoints.output &&
                      selectedWorkflowChannelData.endpoints.output.length"
                    >

                      <section v-for="(output, i) in selectedWorkflowChannelData.endpoints.output" :key="output.id">
                        <p class="bg-black-100 mb-4 p-2 rounded">
                          <span
                            @click="copyToClipboard('copy-output-' + i)"
                            class="url flex items-center"><span>{{output.url}}</span>
                            <ioio-icon
                              icon="fas-clone"
                              class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                            />
                          </span>
                          <input
                            class="copy-to-clipboard-input"
                            type="text"
                            :id="'copy-output-' + i"
                            :value="output.url">
                        </p>
                      </section>

                    </div>
                    <div class="p-4 bg-black-100 rounded" v-else>No data available at this time.</div>

                    <h3 class="text-lg mt-8 mb-4">Archive Urls</h3>

                    <div
                      class=""
                      v-if="selectedWorkflowChannelData.endpoints &&
                        selectedWorkflowChannelData.endpoints.archives && selectedWorkflowChannelData.endpoints.archives.length
                      "
                    >
                      <section
                        v-for="(archive, i) in selectedWorkflowChannelData.endpoints.archives"
                        :key="i"
                      >
                        <p class="bg-black-100 mb-4 p-2 rounded">
                          <span
                            @click="copyToClipboard('copy-archive-' + i)"
                            class="url flex items-center"
                          >
                            <span>{{archive.url}}</span>
                            <ioio-icon
                              icon="fas-clone"
                              class="w-3 h-3 ml-1 cursor-pointer text-blue-200"
                            />
                          </span>
                          <input
                            class="copy-to-clipboard-input"
                            type="text"
                            :id="'copy-archive-' + i"
                            :value="archive.url"
                          >
                        </p>
                      </section>

                    </div>

                    <div class="p-4 bg-black-100 rounded" v-else>No data available at this time.</div>
                  </aside>
                </section>
              </ioio-tab>

              <ioio-tab
                name="Input"
                :selected="selectedTabName === 'Input'"
                icon="fas fa-arrow-square-right"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 input-switch-tab"
              >
                <h3 class="text-lg mt-2 mb-2">Input switch</h3>
                <p class="text-xs text-black-500 mb-6">Switch the input that the running channel is ingesting</p>

                <div
                  class=""
                  v-if="hasSelectedWFChannelDataInputs && !isInputSwitchInProgress && (selectedWorkflow.status === 'RUNNING' || selectedWorkflow.status === 'PAUSED')"
                >

                  <div
                    v-if="inputOptions.length"
                    class="flex"
                  >
                    <section class="flex-auto mr-16">
                      <div
                        v-for="input, index in inputOptions"
                        :key="index"
                        class="relative custom-input-radio mb-6 flex items-center border-bottom border-black-200 pb-4"
                      >
                        <div class="mr-6">
                          <ioio-label
                            v-if="input.value === mainInput"
                            text="Live"
                            class=" justify-center min-w-20"
                            size="small"
                            type="danger"
                          />
                          <ioio-label
                            v-else
                            text="Available"
                            class=" min-w-20"
                            size="small"
                            type="success"
                          />
                        </div>

                        <div class="text-xl mr-6 w-24 truncate">{{ input.label }}</div>

                        <div class="flex items-center mr-2 text-sm">

                          <div class="truncate" :class="{ 'w-48': isInputSwitchSelected }">{{ input.url }}</div>

                          <div
                            @click="() => copyToClipboard(`inputUrl-${input.value}`)"
                            class="ml-4 pointer"
                          >
                            <ioio-icon
                              icon="fa-light fa-clone"
                              class="w-4 h-4"
                            />
                            <input
                              class="copy-to-clipboard-input"
                              type="text"
                              :id="`inputUrl-${input.value}`"
                              :value="input.url"
                            />
                          </div>
                        </div>
                        <!-- NOTE: the radios are in a v-for on purpose, in order for the custom labels to be displayed  -->
                        <ioio-radio
                          class="flex flex-auto justify-end"
                          :options="[inputOptions[index]]"
                          size=""
                          v-model="selectedInput"
                          @input="() => setIsInputSwitchSelected(true)"
                        />
                      </div>
                    </section>
                    <aside class="w-1/2 form-section" v-if="isInputSwitchSelected">

                      <section class="flex">
                        <ioio-radio
                          class="input-switch-time-radio mr-6"
                          :options="inputSwitchStartOptions"
                          name="access"
                          size="large"
                          v-model="isInputSwitchImmediate"
                          :checkedVal="isInputSwitchImmediate === 'true'"
                          label="Switch type"
                        />

                        <div class="relative datepicker-overwrite-input-switch mt-5" v-if="isInputSwitchImmediate === 'false'">
                          <ioio-field
                            required
                            topic="inputSwitchForm"
                            v-model="inputSwitchTime"
                            type="datetime"
                            size="medium"
                          ></ioio-field>
                          <span
                            class="placeholder absolute text-sm mt-1" v-if="!inputSwitchTime"
                          >Select date time
                          </span>
                        </div>
                      </section>

                      <footer class="flex mt-2">

                        <!-- NOTE: remove the topic if the datefield should not be validated, since otherwise, event if it's hidden, DS still tries to validate it as required -->

                        <action-confirm-modal-component
                          v-if="!isInputSwitchInProgress"
                          :btnTopic="isInputSwitchImmediate === 'false' ? 'inputSwitchForm' : ''"
                          dialogId="switchConfirm"
                          dialogWidth="500"
                          dialogHeight="225"
                          dialogOpenBtnLabel="Switch"
                          dialogOpenBtnSize="small"
                          dialogOpenBtnClass="mr-4"
                          :isDialogOpenBtnVisible="true"
                          actionConfirmBtnLabel="Yes"
                          :actionConfirmLabel="`You are about to change the input of this channel from ${mainInput} to ${selectedInput}. Do you wish to proceed?`"
                          :onActionConfirm="switchInputs"
                          dialogOpenBtnVariant="primary"
                          actionConfirmBtnVariant="primary"
                        />

                        <ioio-button
                          type="secondary"
                          size="small"
                          variant="outline"
                          @click.stop.prevent="setIsInputSwitchSelected(false)"
                        >
                          Cancel
                        </ioio-button>
                      </footer>

                    </aside>
                  </div>

                </div>
                <div class="p-4 bg-black-100 rounded mb-8" v-else-if="!hasSelectedWFChannelDataInputs && !isInputSwitchInProgress">No data available at this time.
                </div>
                <div class="relative min-h-36" v-else-if="isInputSwitchInProgress">
                  <ioio-loader isShown />
                </div>

              </ioio-tab>

              <ioio-tab
                name="Ad Insertion"
                :selected="selectedTabName === 'Ad Insertion'"
                icon="fa-regular fa-tag"
                class="text-xs text-black-600 flex flex-auto overflow-y pr-2"
              >

                <section v-if="shouldDisplayAdLayout" class="flex flex-auto">

                  <section class="flex flex-column w-1/3 mr-8">
                    <h3 class="text-lg mt-2 mb-4">Insert ad break</h3>

                    <p class="text-sm text-black-500 mb-2">Select duration or manually enter in seconds</p>

                    <section class="flex">
                      <ioio-button
                        :type="selectedAdDuration === 15 ? 'primary' : 'secondary'"
                        variant="outline"
                        size="small"
                        @click="() => setAdDuration(15)"
                        class="mr-3"
                      >15s
                      </ioio-button>
                      <ioio-button
                        :type="selectedAdDuration === 30 ? 'primary' : 'secondary'"
                        variant="outline"
                        size="small"
                        @click="() => setAdDuration(30)"
                        class="mr-3"
                      >30s
                      </ioio-button>
                      <ioio-button
                        :type="selectedAdDuration === 60 ? 'primary' : 'secondary'"
                        variant="outline"
                        size="small"
                        @click="() => setAdDuration(60)"
                        class="mr-3"
                      >60s
                      </ioio-button>
                      <ioio-button
                        :type="selectedAdDuration === 120 ? 'primary' : 'secondary'"
                        variant="outline"
                        size="small"
                        @click="() => setAdDuration(120)"
                        class="mr-3"
                      >120s
                      </ioio-button>

                      <ioio-field
                        type="number"
                        topic="adInsertForm"
                        size="small"
                        placeholder="Custom"
                        v-model="customAdDuration"
                        @input="(newVal) => setAdDuration(newVal, 'custom')"
                        class="custom-ad-duration-input"
                      />
                    </section>

                    <ioio-radio
                      class="w-full ad-start-radio mt-6"
                      :options="adInsertStartOptions"
                      name="access"
                      size="large"
                      v-model="isAdInsertImmediate"
                      :checkedVal="isAdInsertImmediate === 'true'"
                      label="Start type"
                    />

                    <div class="relative datepicker-overwrite-ads mb-4" v-if="isAdInsertImmediate === 'false'">
                      <ioio-field
                        required
                        topic="adInsertForm"
                        v-model="adInsertTime"
                        type="datetime"
                        size="medium"
                      ></ioio-field>
                      <span
                        class="placeholder absolute text-sm mt-1" v-if="!adInsertTime"
                      >Select date time
                      </span>
                    </div>

                    <footer class="flex mt-2">

                      <!-- NOTE: remove the topic if the datefield should not be validated, since otherwise, event if it's hidden, DS still tries to validate it as required -->
                      <ioio-button
                        :topic="isAdInsertImmediate === 'false' ? 'adInsertForm' : ''"
                        type="primary"
                        :disabled="isAdsListLoading"
                        @click="() => scheduleAdForWorkflow()"
                        class=""
                      >
                        Insert ad break
                      </ioio-button>

                    </footer>

                  </section>

                  <aside class="flex flex-column w-2/3">
                    <header class="flex justify-between">


                      <h3 class="text-lg mt-2 mb-4">Scheduled ad breaks</h3>


                      <ioio-dropdown-base
                        alignX="left"
                        :fullWidth="false"
                        class="text-base sorting-picker"
                        closeOnSelect
                      >
                        <template slot="title">

                          <ioio-button
                            type="secondary"
                            variant="outline"
                            size="small"
                            class=""
                          >
                            <ioio-icon
                              icon="fa-light fa-clone"
                              class="w-3 h-3"
                            />
                            Copy SSAI URL
                            <ioio-icon
                              icon="fal-chevron-down"
                              class="w-3 h-3"
                            />
                          </ioio-button>
                        </template>

                        <ioio-button

                          v-for="mtailor in mediatailorsForLive"
                          :key="mtailor.guid"
                          @click="() => copyToClipboard(`SSAI-${mtailor.guid}`)"

                          class="dropdown__menu-item"
                          type="minimal"
                        >
                          <ioio-icon
                            icon="fa-light fa-clone"
                            class="w-3 h-3 mr-2"
                          />
                          {{ mtailor.name }}
                          <input
                            class="copy-to-clipboard-input"
                            type="text"
                            :id="`SSAI-${mtailor.guid}`"
                            :value="mtailor.adServer"
                          />
                        </ioio-button>

                      </ioio-dropdown-base>

                    </header>

                    <ioio-table responsive class="logs-table" v-if="scheduledAds.length && !isAdsListLoading">
                      <template slot="thead">
                        <tr class="w-full grid grid-cols-10">
                          <th class="col-span-2 truncate" style="padding: 0.375rem 1rem">Type</th>
                          <th class="col-span-4 truncate" style="padding: 0.375rem 1rem">Start</th>
                          <th class="col-span-2 truncate" style="padding: 0.375rem 1rem">Duration</th>
                          <th class="col-span-2 truncate" style="padding: 0.375rem 1rem">ID</th>
                        </tr>
                      </template>
                      <template
                        slot="tbody"
                      >

                        <tr v-for="(ad, id) in scheduledAds" :key="id" class="w-full grid grid-cols-10">
                          <td class="col-span-2 truncate" style="padding: 1rem">

                            <ioio-label
                              :text="ad.scheduleType"
                              :type="ad.scheduleType === 'FIXED' ? 'normal' : 'accent'"
                              size="size-s"
                            />
                          </td>
                          <td class="col-span-4 truncate" style="padding: 1rem">

                            <readable-time-component

                              :timestamp="ad.time"
                              class="cell-content"
                            />
                          </td>
                          <td class="col-span-2 truncate" style="padding: 1rem">
                            {{ ad.duration }}s
                          </td>
                          <td class="col-span-2 truncate" style="padding: 1rem">

                            <div class="flex justify-between">
                              {{ ad.spliceEventId }}

                              <delete-confirm-modal-component
                                dialogWidth="500"
                                dialogHeight="225"
                                dialogOpenBtnLabel="Delete"
                                dialogOpenBtnSize="size-xs"
                                :isDialogOpenBtnVisible="true"
                                delConfirmBtnLabel="Delete"
                                :delConfirmLabel="`Are you sure you want to delete ad ${ad.spliceEventId}?`"
                                :delConfirmSecondaryLabel="`Ad duration: ${ad.duration}s`"
                                :onDeleteConfirm="() => confirmRemoveAdScheduleFromWorkflow(ad.spliceEventId)"
                                :isDelConfirmBtnVisible="!isAdsListLoading"
                              />
                            </div>
                          </td>
                        </tr>
                      </template>
                    </ioio-table>

                    <p v-else-if="!scheduledAds.length && !isAdsListLoading" class="mt-4 text-sm">There are no scheduled ad breaks found.</p>

                    <div class="py-4 overflow-hidden" v-else>
                      <logo-loader-component width="50px" height="50px"/>
                    </div>
                  </aside>

                </section>

                <section v-else class="">

                  <p class="m-8 text-m">
                    The workflow doesn't have the necessary settings and needs to be
                    <ioio-label
                      :text="getReadableChannelStatus('RUNNING').label"
                      :type="
                        getReadableChannelStatus('RUNNING').labelType
                      "
                      :icon="getReadableChannelStatus('RUNNING').icon"
                      size="size-s"
                      class="mx-2 inline"
                    />
                    in order to use this functionality.
                  </p>
                </section>
              </ioio-tab>

              <!-- @Stef - overlay will have a new story in Jira -->
              <!-- <ioio-tab
                name="Overlays"
                :selected="selectedTabName === 'Overlays'"
                icon="fa-regular fa-image"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >
                <h3 class="text-lg mt-2 mb-4">Add overlay</h3>
                <p class="text-xs text-black-500 mb-2">Place logo, banners or any kind of branding to your workflow</p>
              </ioio-tab> -->

              <ioio-tab
                name="Statistics"
                :selected="selectedTabName === 'Statistics'"
                icon="far fa-chart-bar"
                class="text-xs text-black-600 flex flex-auto flex-column pr-2"
              >
                <h3 class="text-lg mt-2 mb-6 flex items-center justify-between"

                  >Statistics

                  <aside class="flex">

                    <ioio-dropdown
                      fullWidth
                      class="mr-4 w-40"
                      size="small"
                      :items="possibleHealthTrackingServiceOptions"
                      titleProp="label"
                      valueProp="service"
                      v-model="selectedServiceForHealthTracking"
                    />

                    <ioio-button
                      type="primary"
                      variant="outline"
                      size="small"
                      @click="makeHealthTrackingRequest"
                    >
                      <ioio-icon icon="far-rotate-right" class="h-4 mr-2" />
                      Refresh
                    </ioio-button>
                  </aside>
                </h3>


                <div v-if="!isHealthRequestPending" class="flex flex-wrap flex-auto overflow-auto">
                  <img
                    v-for="(item, i) in selectedWorkflowChannelHealth"
                    :key="i"
                    class="ratio1-3 mb-4 px-2 cursor-pointer"
                    v-bind:src="'data:image/jpeg;base64,' + item.image"
                    @click="openStatisticsModal(i)"
                  />
                  <div
                    class="p-2 bg-black-100 rounded w-full h-full flex flex-auto items-center justify-center" v-if="!selectedWorkflowChannelHealth || !selectedWorkflowChannelHealth.length"
                  >No data available at this time.</div>
                </div>

                <div class="py-4 overflow-hidden" v-else>
                  <logo-loader-component width="50px" height="50px"/>
                </div>

                <ioio-modal
                  :show.sync="isHealthGraphModalVisible"
                  size="medium"
                  :noScroll="false"
                  headerClasses=""
                  footerClasses=""
                >
                  <img :src="selectedHealthGraph.src" />
                </ioio-modal>
              </ioio-tab>


              <ioio-tab
                name="Alerts"
                :selected="selectedTabName === 'Alerts'"
                icon="fa-regular fa-triangle-exclamation"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
                :badge="activeAlerts && activeAlerts.length || 0"
              >

                <div class="flex items-center justify-between mb-6 mt-2 ">

                  <aside class="flex">

                    <ioio-button
                      :type="selectedAlertsType === 'actual' ? 'primary': 'secondary'"
                      variant="outline"
                      size="small"
                      @click="changeSelectedAlertsType('actual')"
                      class="mr-2"
                    >Actual
                    </ioio-button>
                    <ioio-button
                      :type="selectedAlertsType === 'history' ? 'primary': 'secondary'"
                      variant="outline"
                      size="small"
                      @click="changeSelectedAlertsType('history')"
                      class="mr-3"
                    >History
                    </ioio-button>
                  </aside>
                  <ioio-button
                    type="primary"
                    variant="outline"
                    size="small"
                    @click="refreshAlertsLists">
                    <ioio-icon icon="far-rotate-right" class="h-4 mr-2" />
                    Refresh
                  </ioio-button>
                </div>


                <ioio-table
                  class="flex-auto alerts-table"
                  responsive
                  v-if="selectedWorkflowChannelVisibleAlerts && selectedWorkflowChannelVisibleAlerts.length"
                >
                  <template slot="thead">
                    <tr class="w-full flex">

                      <th
                        class="truncate pr-8 pointer w-40"
                        style="min-width: 160px"
                        @click="sortAlerts('triggeredAt')"
                        :class="{ 'is-column-sorted-by': sort.triggeredAt }"
                      >
                        Triggered At
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.triggeredAt" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-28"
                        style="min-width: 112px"
                        @click="sortAlerts('state')"
                        :class="{ 'is-column-sorted-by': sort.state }"
                      >
                        State
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.state" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-40"
                        style="min-width: 160px"
                        @click="sortAlerts('clearedAt')"
                        :class="{ 'is-column-sorted-by': sort.clearedAt }"
                      >
                        Cleared At
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.clearedAt" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-28"
                        style="min-width: 112px"
                        @click="sortAlerts('pipeline')"
                        :class="{ 'is-column-sorted-by': sort.pipeline }"
                      >
                        Pipeline
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.pipeline" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer w-48"
                        style="min-width: 192px"
                        @click="sortAlerts('type')"
                        :class="{ 'is-column-sorted-by': sort.type }"
                      >
                        Type
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.type" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                      <th
                        class="truncate pr-8 pointer flex-auto"
                        style=""
                        @click="sortAlerts('message')"
                        :class="{ 'is-column-sorted-by': sort.message }"
                      >
                        Message
                        <ioio-icon icon="fa-solid fa-angle-down" class="ml-1" v-if="sort.message" />
                        <ioio-icon icon="fa-solid fa-angle-up" class="ml-1" v-else />
                      </th>
                    </tr>
                  </template>

                  <template
                    slot="tbody"
                  >
                    <tr
                      v-for="alert in selectedWorkflowChannelVisibleAlerts"
                      :key="alert.id"
                      class="w-full flex"
                    >
                      <td class="w-40">
                        <div class="cell-content">
                          <readable-time-component
                            :timestamp="alert.triggeredAt"
                          />
                        </div>
                      </td>

                      <td class=" alerts-container w-28">
                        <span class="state" style="word-break: normal;" :class="{'active' : alert.state === 'SET'}">{{ alert.state }}</span>
                      </td>

                      <td v-if="alert.clearedAt" class="w-40">

                        <div class="cell-content">

                          <readable-time-component
                            :timestamp="alert.clearedAt"
                          />
                        </div>
                      </td>
                      <td v-else class="w-40">--</td>

                      <td class="w-28">
                        <div class="cell-content"
                        >{{ alert.pipeline }}
                        </div>
                      </td>
                      <td class="w-48">

                        <div class="cell-content"
                        >{{ alert.type }}
                        </div></td>
                      <td class="flex-auto w-min">

                        <div class="cell-content"
                        >{{ alert.message }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </ioio-table>

                <div class="" v-if="!selectedWorkflowChannelVisibleAlerts || !selectedWorkflowChannelVisibleAlerts.length">No data available at this time.</div>

              </ioio-tab>


              <ioio-tab
                name="Logs"
                :selected="selectedTabName === 'Logs'"
                icon="fa fa-history"
                class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
              >

                <div v-if="!areChannelLogsLoading" class="flex items-center flex-none w-full justify-between logs-label mb-6">
                  <div class="flex justify-between items-end flex-auto mt-2">
                    <div class="flex">

                      <div class="relative datepicker-overwrite-logs mr-2">
                        <ioio-field
                          class="mr-2"
                          v-model="workflowLogsParams.startDateTime"
                          type="datetime"
                          v-if="selectedTabName === 'Logs'"
                          size="medium"
                        ></ioio-field>
                        <span
                          class="placeholder absolute text-sm" v-if="!workflowLogsParams.startDateTime"
                        >Start date time
                        </span>
                      </div>


                      <div class="relative datepicker-overwrite-logs mr-2">
                        <ioio-field
                          class="mr-2"
                          v-model="workflowLogsParams.endDateTime"
                          type="datetime"
                          v-if="selectedTabName === 'Logs'"
                          size="medium"
                        ></ioio-field>
                        <span
                          class="placeholder absolute text-sm" v-if="!workflowLogsParams.endDateTime"
                        >End date time
                        </span>
                      </div>

                      <ioio-dropdown
                        fullWidth
                        class="w-64"
                        size="small"
                        :items="logsPerPageItems"
                        titleProp="name"
                        valueProp="id"
                        v-model="workflowLogsParams.pageSize"
                        placeholder="Logs per page"
                      />
                      <ioio-dropdown
                        fullWidth
                        class="ml-2 w-64"
                        size="small"
                        v-if="openPipelineItems"
                        :items="logsPipelineItems"
                        titleProp="name"
                        valueProp="id"
                        v-model="workflowLogsParams.pipeline"
                        placeholder="Select Pipeline"
                      />
                    </div>

                    <ioio-button
                      type="primary"
                      variant="outline"
                      size="small"
                      class="ml-8 flex-none"
                      iconLeft="far-rotate-right"
                      @click="() => getWorkflowsLogs(null)"
                    >
                      Refresh
                    </ioio-button>
                  </div>
                </div>

                <section v-if="!areChannelLogsLoading">
                  <div v-if="selectedWorkflowChannelLogs && selectedWorkflowChannelLogs.items && selectedWorkflowChannelLogs.items.length">
                    <ioio-table responsive class="logs-table" >
                      <template slot="thead">
                        <tr class="w-full flex">
                          <th class="w-40 truncate" style="min-width: 160px">Created At</th>
                          <th class="w-40 truncate" style="min-width: 160px">Type</th>
                          <th class="flex-auto truncate">Message</th>
                        </tr>
                      </template>
                      <template
                        slot="tbody"
                      >
                        <tr v-for="(log, id) in selectedWorkflowChannelLogs.items" :key="id" class="w-full flex">
                          <td class="w-40 truncate" style="min-width: 160px">
                            <readable-time-component
                              :timestamp="log.timestamp"
                              class="cell-content"
                            />
                          </td>
                          <td class="w-40 truncate" style="min-width: 160px">
                            <ioio-label
                              :text="logsSeverity[log.severity].label"
                              :type="logsSeverity[log.severity].type"
                              size="small"
                              :icon="null"
                              :isMetric="false"
                              :rounded="false"
                            />
                          </td>
                          <td class="flex-auto truncate">

                            <div class="log-msg-col z-1">{{ log.message }}</div>
                            <!-- NOTE: remove the tooltip for now - the props for line-clamp and ones for the tooltip (requires overflow-visible) can not work together -->
                            <!-- <ioio-tooltip
                              class="tooltip-z cursor-pointer"
                              :text="log.message"
                              :multiline="true"
                              initialPostion="left"
                            >
                            </ioio-tooltip> -->

                          </td>
                        </tr>
                      </template>
                    </ioio-table>
                  </div>
                  <div class="p2 bg-gray+5 m2 rounded-2" v-if="!selectedWorkflowChannelLogs || !selectedWorkflowChannelLogs.items || !selectedWorkflowChannelLogs.items.length">No data available at this time.</div>
                  <div class="flex p-6">

                    <ioio-button
                      :disabled="logsRequestNumbers === 0"
                      class="mr-6"
                      iconLeft="far-angle-left"
                      type="primary"
                      size="small"
                      variant="outline"
                      @click="getWorkflowsLogs('backwardToken'), logsRequestNumbers -= 1"
                    >Prev</ioio-button>
                    <ioio-button
                      :disabled="selectedWorkflowChannelLogs.items && workflowLogsParams.pageSize != selectedWorkflowChannelLogs.items.length || !selectedWorkflowChannelLogs.items"
                      type="primary"
                      variant="outline"
                      size="small"
                      iconRight="far-angle-right"
                      @click="getWorkflowsLogs('forwardToken'), logsRequestNumbers += 1"
                    >Next</ioio-button>
                  </div>
                </section>
                <div class="relative min-h-36 mt-5" v-else>
                  <ioio-loader isShown />
                </div>

              </ioio-tab>
            </ioio-tabs>

          </div>

          <div
            class="fixed left-0 top-0 min-h-36 h-full w-full flex flex-column items-center"
            v-if="areChannelDetailsLoading || areGeneralDetailsLoading"
            @click.stop.prevent=""
          >
            <ioio-loader isShown />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment-timezone";
import Hls from "hls.js";
import OvenPlayer from 'ovenplayer';
import dash from 'dashjs'

import askForPromise from "ask-for-promise";

import {
  getReadableChannelStatus,
  getLocalTimezone,
  copyElementInnerText,
  getHHMMSSFromMS
} from "@utils/helpers";


export default {
  data: () => ({

    actionsEnabled: true,

    isInputSwitchSelected: false,

    inputSwitchStartOptions: [
      {
        value: 'true',
        label: 'Immediate'
      },
      {
        value: 'false',
        label: 'Fixed'
      }
    ],
    // NOTE: io-radio can not accept boolean
    isInputSwitchImmediate: 'true',
    inputSwitchTime: '',
    isInputSwitchInProgress: false,

    // TODO: continue here - remove the concept of workflowActions and use plain functions. Refactor the DupliocateWorkflow modal to use DS
    workflowActions: [
      {
        label: 'Duplicate',
        className: '',
        type: 'promptDuplicateWorkflow',
        enabledForStatus: {
          DONE: true,
          READY: true,
        }
      },
      // TODO: Add back once functionality is available
      // {
      //   label: 'Edit',
      //   type: 'promptEditWorkflow',
      //   enabledForStatus: { }
      // }
    ],
    sort: {
      triggeredAt: true,
      state: true,
      clearedAt: true,
      pipeline: true,
      type: true,
      message: true
    },

    selectedWorkflowId: '',
    selectedAlertsType: 'actual',
    healthTrackingIntervalId: null,

    workflowTabs: {

      general: {
        label: 'General'
      },
      alerts: {
        label: 'Alerts'
      },
      statistics: {
        label: 'Statistics'
      },
      blueprint: {
        label: 'Blueprint'
      },
      logs: {
        label: 'Logs'
      }
    },

    logsSeverity: {
      D: {
        label: 'Debug',
        type: 'normal'
      },
      I: {
        label: 'Information',
        type: 'normal'
      },
      W: {
        label: 'Warning',
        type: 'warning'
      },
      E: {
        label: 'Error',
        type: 'failed'
      }
    },

    workflowLogsParams: {
      startDateTime: '',
      endDateTime: '',
      pipeline: 0,
      pageSize: 200
    },

    openPipelineItems: false,
    logsRequestNumbers: 0,
    logsPipelineItems: [
      {"id":0,"name":"Pipeline 0"},
      {"id":1,"name":"Pipeline 1"}
    ],
    logsPerPageItems: [
      {"id":200,"name":"Logs per page 200"},
      {"id":300,"name":"Logs per page 300"},
      {"id":500,"name":"Logs per page 500"}
    ],

    blueprintEditor: {},

    isBlueprintForPreviewLoading: false,

    previewPromiseTask: null,

    isBlueprintSectionOpened: false,

    cdnPlaybackUrl: '',
    hls: null,
    hlsjsConfig: {

      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },
    isHlsStreamRecoveringFromError: false,

    ovenPlayerInstance: null,
    ovenPlayerConfig: {
      mute: true,
      autoStart: true,
      autoFallback: true
    },

    selectedOvenplayerSourceName: '',

    isWorkflowsListOpened: false,
    localTimezone: '',

    runningWorkflows: [],

    playbackRetryId: null,

    isRequestPending: false,

    isHealthRequestPending: false,

    selectedHealthGraph: {},
    isHealthGraphModalVisible: false,

    oldChannelAlerts: [],
    mainInput:'',
    selectedInput: '',
    inputOptions: [],

    selectedTabName: '',

    elapsedWorkflowsRunningTime: '',
    runTimeRefreshItervalId: null,

    isPreviewCollapsed: false,

    adInsertStartOptions: [
      {
        value: 'true',
        label: 'Immediate'
      },
      {
        value: 'false',
        label: 'Fixed'
      }
    ],
    // NOTE: io-radio can not accept boolean
    isAdInsertImmediate: 'true',
    adInsertTime: '',

    isAdsListLoading: false,
    scheduledAds: [],
    selectedAdDuration: 15,
    selectedAdType: 'CREATE', // 'END' type will be added in the future
    customAdDuration: null,

    selectedServiceForHealthTracking: '',
    possibleHealthTrackingServiceOptions: [

      { service: '', label: "All services" },
      { service: 'MEDIALIVE', label: "MEDIALIVE" },
      { service: 'MEDIACONNECT', label: "MEDIACONNECT" },
      { service: 'MEDIAPACKAGE', label: "MEDIAPACKAGE" }
    ]
  }),

  props: {
    workflowId: String,
    onPreviewClose: Function,
    stageSelectedWorkflowForPreview: Function,
    activeAlerts: Array,
    triggerGetWorkflows: Function
  },

  created() {

    // NOTE: ovenplayer seeks a global instance of the Hls && dash library
    window.Hls = Hls;
    window.dash = dash;

    this.moment = moment;
    this.getReadableChannelStatus = getReadableChannelStatus;
    this.copyElementInnerText = copyElementInnerText;

    this.localTimezone = getLocalTimezone();

    this.makeGetMediatailorsRequest();
  },

  mounted() {

    window.w = this;

    if (this.$route.query.autoStart) {

      this.makeChannelRequest('makeCreateChannelFromWorkflowRequest', this.selectedWorkflow);
    }

    const preselectedOpenedTab = this.$route.query.workflowSection;

    this.setSelectedTabName(preselectedOpenedTab || 'Details');

    this.setupWorkflowStats();



    /**
     * The blueprint editor library has NON SSR-friendly dependencies, thus
     * it can only be required in the mounted() hook and can not be imported!
     */
    this.blueprintEditor =
      require("ioio-blueprint-editor/src/main.js").blueprintEditor;

  },

  beforeDestroy() {

    this.unloadHlsPlayer();
    this.unloadOvenPlayer();

    this.initChannelHealthTracking('clear');

    this.setSelectedWorkflow({});
    this.setSelectedWorkflowChannelData({});
    this.setSelectedWorkflowChannelHealth({items:null});

    this.setupRunTimeRefreshIterval('reset');
  },

  methods: {

    setAdDuration(newVal, isCustom) {

      this.selectedAdDuration = newVal;
      isCustom && (this.customAdDuration = newVal);
    },

    scheduleAdForWorkflow() {

      this.isAdsListLoading = true;

      const payload = {

        duration: this.selectedAdDuration,
        type: this.selectedAdType
      };

      this.isAdInsertImmediate === 'false' && (payload.time = this.adInsertTime);

      this.makeScheduleAdForWorkflow({

        workflowId: this.workflowId,
        data: payload
      })
      .then(() => {

        return this.refreshScheduledAdsList();
      })
      .catch(() => {

        this.isAdsListLoading = false;
      });
    },

    confirmRemoveAdScheduleFromWorkflow(adId) {

      this.isAdsListLoading = true;

      this.removeAdScheduleFromWorkflow({

        workflowId: this.selectedWorkflowId,
        adId
      })
      .then(() => {

        return this.refreshScheduledAdsList();
      })
      .finally(() => {

        this.isAdsListLoading = false;
      });
    },

    togglePreviewCollapsed() {

      this.isPreviewCollapsed = !this.isPreviewCollapsed;

      // The minified version has no room for the controls to be usable
      this.isPreviewCollapsed ?
        this.ovenPlayerInstance?.showControls(false) : this.ovenPlayerInstance?.showControls(true);
    },

    stopChannel() {

      this.setupRunTimeRefreshIterval('reset');

      this.makeChannelRequest(
        'makeDeleteLiveChannelRequest',
        this.selectedWorkflow
      );
    },

    setupRunTimeRefreshIterval(shouldClear) {

      if (shouldClear) {

        clearInterval(this.runTimeRefreshItervalId);

        this.runTimeRefreshItervalId = null;
        this.elapsedWorkflowsRunningTime = '';
        return;
      }

      // Prevent possible leaks
      if (this.runTimeRefreshItervalId) {

        this.setupRunTimeRefreshIterval('reset');
      }

      this.runTimeRefreshItervalId = setInterval(() => {

        this.calcWorkflowRunTime();

      }, 1000);
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;

      this.$pushRoute(`/live/workflows?id=${this.workflowId}&workflowSection=${name}`);

      this.makeHealthTrackingRequest(true);

      this.refreshScheduledAdsList();
    },

    refreshScheduledAdsList() {

      if (this.shouldDisplayAdLayout) {

        this.isAdsListLoading = true;

        return this.getScheduledAds({ workflowId: this.workflowId })
          .then((resp) => {

            this.scheduledAds = resp.items;
          })
          .finally(() => {

            this.isAdsListLoading = false;
          });
      }
    },

    setupWorkflowStats() {

      const workflowId = this.workflowId;

      if (!workflowId) {

        // redirect to live events manager
        this.$pushRoute("/live/workflows");
      }

      this.loadWorkflowDetails(workflowId);

      this.oldChannelAlerts = this.selectedWorkflowChannelVisibleAlerts.slice();
    },

    openStatisticsModal (index) {

      let selectedGraph = this.selectedWorkflowChannelHealth[index];

      selectedGraph.src = 'data:image/jpeg;base64,' + selectedGraph.image;

      this.selectedHealthGraph = selectedGraph;

      this.isHealthGraphModalVisible = true;
    },

    clearScheduleForWorkflow() {

      this.isRequestPending = true;

      // send without data key (empty body) to clear the schedule
      this.updateWorkflowSchedule({
        workflowId: this.selectedWorkflowId,
        originalName: this.selectedWorkflow.name
      }).then(() => {

        this.loadWorkflowDetails(this.selectedWorkflowId);

      }).finally(() => {

        this.isRequestPending = false;
      });
    },

    promptExtendScheduleForWorkflow () {

      this.$modal.show("extend-duration-workflow");

    },

    extendScheduleForWorkflow (duration) {

      const newDuration = this.selectedWorkflowSchedule.duration + Number(duration);

      this.isRequestPending = true;

      this.updateWorkflowSchedule({

        workflowId: this.selectedWorkflowId,
        originalName: this.selectedWorkflow.name,

        data: {

          ...this.selectedWorkflowSchedule,
          startDateTime: this.selectedWorkflowSchedule.startDateTime,
          duration: newDuration
        }

      }).then(() => {

        this.$toasted.success('Your changes were saved successfully');

        this.loadWorkflowDetails(this.selectedWorkflowId);

        this.closeActionModal('extend-duration-workflow');

      }).finally(() => {

        this.isRequestPending = false;
      });
    },

    triggerPauseLiveChannel() {

      this.pauseLiveChannel(this.selectedWorkflow)
        .then(() => {

        });
    },

    saveScheduledPauseLiveChannel(payload) {

      this.pauseLiveChannel(payload)
        .then(() => {

          this.refreshChannelData(this.selectedWorkflowId);
        });
    },

    saveScheduledResumeLiveChannel(payload) {

      this.unpauseLiveChannel(payload)
        .then(() => {

          this.refreshChannelData(this.selectedWorkflowId);
        });
    },

    triggerUnpauseLiveChannel() {

      this.unpauseLiveChannel(this.selectedWorkflow)
        .then(() => {

        });
    },

    ...mapMutations({

      setSelectedWorkflow: "live/SET_SELECTED_WORKFLOW",
      setSelectedWorkflowChannelData: "live/SET_SELECTED_WORKFLOW_CHANNEL_DATA",
      setSelectedWorkflowChannelHealth: "live/SET_SELECTED_WORKFLOW_CHANNEL_HEALTH_DATA",
      setSelectedWorkflowChannelAlertsData: "live/SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_DATA",
      setSelectedWorkflowChannelAlertsHistoryData: "live/SET_SELECTED_WORKFLOW_CHANNEL_ALERTS_HISTORY_DATA",
    }),
    ...mapActions({

      makeGetWorkflowDetailsRequest: "live/makeGetWorkflowDetailsRequest",
      makeGetChannelForWorkflowRequest: "live/makeGetChannelForWorkflowRequest",
      makeGetChannelForWorkflowAlertsRequest: "live/makeGetChannelForWorkflowAlertsRequest",
      makeGetChannelForWorkflowAlertsHistoryRequest: "live/makeGetChannelForWorkflowAlertsHistoryRequest",
      makeGetChannelForWorkflowHealthRequest: "live/makeGetChannelForWorkflowHealthRequest",

      makeDeleteWorkflowRequest: "live/makeDeleteWorkflowRequest",
      makeCreateChannelFromWorkflowRequest: "live/makeCreateChannelFromWorkflowRequest",
      makeDeleteLiveChannelRequest: "live/makeDeleteLiveChannelRequest",

      makeGetBlueprintModelRequest: "live/makeGetBlueprintModelRequest",
      getWorkflows: "live/makeGetWorkflowsRequest",
      getBlueprintForm: "live/getBlueprintForm",
      updateWorkflowSchedule: 'live/updateWorkflowSchedule',
      getChannelWorkflowLogs: 'live/getChannelWorkflowLogs',
      switchInputForWorkflow: 'live/switchInputForWorkflow',
      getDefaultInput: 'live/getDefaultInput',

      pauseLiveChannel: 'live/pauseLiveChannel',
      unpauseLiveChannel: 'live/unpauseLiveChannel',

      getScheduledAds: 'live/getScheduledAds',
      makeScheduleAdForWorkflow: 'live/scheduleAdForWorkflow',
      removeAdScheduleFromWorkflow: 'live/removeAdScheduleFromWorkflow',
      makeGetMediatailorsRequest: "channelManager/makeGetMediatailorsRequest",
    }),

    parseDuration(s) {

      const calcedDurationInMS = s * 1000;

      return getHHMMSSFromMS(calcedDurationInMS);
    },

    unloadOvenPlayer() {

      this.ovenPlayerInstance?.remove();
      this.ovenPlayerInstance = null;
    },

    unloadHlsPlayer() {

      if (this.hls) {

        this.hls.off(Hls.Events.ERROR, this.onHlsError);

        this.hls.stopLoad();
        this.hls.destroy();
        this.hls = null;
      }

      this.clearPlaybackRetry();
    },

    preventVideoElPauseOnClick(e) {

      e.preventDefault();
    },

    loadWorkflowDetails(workflowId) {

      this.cacheSelectedWorkflowId(workflowId);

      this.setupRunTimeRefreshIterval('reset');

      this.makeGetWorkflowDetailsRequest(this.selectedWorkflowId)
        .then(() => {

          this.getWorkflowsLogs();

          if (this.selectedWorkflow.status === "RUNNING") {

            this.setupRunTimeRefreshIterval();
          };

          if ( this.selectedWorkflow.blueprint.settings.channel.channelClass === "STANDARD") {

            this.openPipelineItems = true;
          }

          this.refreshScheduledAdsList();
        });

      this.refreshChannelData(this.selectedWorkflowId);
    },

    refreshActiveInput() {

      return this.getDefaultInput({ id : this.workflowId })
        .then((resp) => {

          this.mainInput = resp.activeInputName;
          this.selectedInput = resp.activeInputName;
        });
    },

    refreshChannelData(workflowId) {

      return this.makeGetChannelForWorkflowRequest(workflowId)
        .then(() => {

          if (this.selectedWorkflowChannelData.status === "RUNNING") {

            if (this.hasSelectedWFChannelDataInputs) {

              this.refreshActiveInput();
            };

          } else {

            if (this.selectedWorkflowChannelData.startedDateTime) {

              this.initChannelHealthTracking();

            } else {

              this.initChannelHealthTracking('clear');
            }
          }

          if (this.selectedWorkflowChannelData.startedDateTime) {

            this.refreshAlertsLists();

            this.workflowLogsParams.startDateTime = this.selectedWorkflowChannelData.startedDateTime;

            this.workflowLogsParams.endDateTime = this.selectedWorkflowChannelData.stoppedDateTime;
          };
        });
    },

    calcWorkflowRunTime() {

      const elapsedTime = moment().valueOf() - moment.tz(this.selectedWorkflowChannelData.startedDateTime, this.localTimezone).valueOf();

      this.elapsedWorkflowsRunningTime = getHHMMSSFromMS(elapsedTime, 'returnFullHour');
    },

    getWorkflowsLogs(token = null) {

      const params = {

        id: this.selectedWorkflowId,
      };

      let query = {...this.workflowLogsParams};

      query.startDateTime =  this.workflowLogsParams.startDateTime.slice(0,-1);

      this.workflowLogsParams.endDateTime ? query.endDateTime =  this.workflowLogsParams.endDateTime.slice(0,-1) :  query.endDateTime = '';

      params.query = {...query};

      token ? params.query[token] = this.selectedWorkflowChannelLogs[token] : this.logsRequestNumbers = 0;

      this.getChannelWorkflowLogs(params);
    },
    toggleWorkflowsListOpened(newVal) {

      this.isWorkflowsListOpened = newVal;

      if (newVal) {

        this.getWorkflows()
          .then(() => {

            this.runningWorkflows =
              this.workflowsList.filter(w => w.status === 'RUNNING');
          });

      } else {

        this.runningWorkflows = [];
      }
    },

    openWorkflowDetails(id) {

      this.setSelectedWorkflow({});
      this.setSelectedWorkflowChannelData({});
      this.setSelectedWorkflowChannelHealth({items:null});

      this.stageSelectedWorkflowForPreview({ id });

      this.loadWorkflowDetails(id);
    },

    onDuplicateWorkflowSuccess(newWorkflowId) {

      this.openWorkflowDetails(newWorkflowId);
      this.triggerGetWorkflows();
    },

    copyToClipboard(elementId) {

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    closeBlueprintPreview() {

      this.isBlueprintSectionOpened = false;
    },

    openBlueprintPreview() {

      this.isBlueprintSectionOpened = true;

      const blueprintId = this.selectedWorkflowBlueprint.id;

      this.isBlueprintForPreviewLoading = true;

      this.makeGetBlueprintModelRequest(blueprintId).then(() => {

        this.isBlueprintForPreviewLoading = false;

        this.previewPromiseTask = askForPromise();

        const params = {

          orgGuid: this.organizationSelected.guid,
          blueprintId: this.selectedBlueprintModel.id
        };

        this.getBlueprintForm(params).then((form) => {

          this.blueprintEditor({
            task: this.previewPromiseTask,
            containerID: "blueprint-preview",
            data: this.selectedBlueprintModel,
            mode: "svg",
            form
          });
        });
      });
    },

    triggerWorkflowAction(workflow, actionType, actionIndex) {

      if (!this.workflowActions[actionIndex].enabledForStatus[workflow.status]) {

        return;
      }

      this[actionType](workflow);
    },

    closeActionModal(type) {

      this.$modal.hide(type);
    },

    deleteWorkflow() {

      this.actionsEnabled = false;

      this.makeDeleteWorkflowRequest(this.selectedWorkflow)
        .then(() => {

          // redirect
          this.$pushRoute('/live/workflows');

          // close the preview
          this.onPreviewClose();

          this.actionsEnabled = true;
        })
        .catch(() => {

          this.actionsEnabled = true;
        });
    },



    editWorkflow() {
      console.log('Edit:', this.selectedWorkflow);

      this.closeActionModal('edit-workflow');
    },

    promptEditWorkflow(workflow) {
      console.log('Edit:', workflow.name);

      return; // TODO: Delete when EDIT is supported

      this.$modal.show("edit-workflow", workflow);
    },

    promptDuplicateWorkflow(workflow) {

      this.$modal.show("duplicate-workflow", workflow);
    },

    makeChannelRequest(requestType, workflow) {

      this.actionsEnabled = false;

      this[requestType](workflow)
        .then(() => {

          this.makeGetWorkflowDetailsRequest(this.selectedWorkflowId).then(() => {

            this.actionsEnabled = true;
          });

          this.refreshChannelData(this.selectedWorkflowId);
        });
    },

    cacheSelectedWorkflowId(id) {

      this.selectedWorkflowId = id;
    },

    changeSelectedAlertsType(type) {

      this.selectedAlertsType = type;

      type === 'actual' ? this.setSelectedWorkflowChannelAlertsHistoryData(this.oldChannelAlerts) : this.setSelectedWorkflowChannelAlertsData(this.oldChannelAlerts);

      this.sort = {
        triggeredAt: true,
        state: true,
        clearedAt: true,
        pipeline: true,
        type: true,
        message: true
      }

      this.oldChannelAlerts = this.selectedWorkflowChannelVisibleAlerts.slice();

    },

    refreshAlertsLists() {

      this.makeGetChannelForWorkflowAlertsRequest(this.selectedWorkflowId);

      this.makeGetChannelForWorkflowAlertsHistoryRequest(this.selectedWorkflowId);
    },

    makeHealthTrackingRequest(initially = false) {

      if (this.selectedTabName !== 'Statistics' || !this.selectedWorkflowId) {

        return;
      }

      if (initially) {

        this.isHealthRequestPending = true;
      }

      this.makeGetChannelForWorkflowHealthRequest({
        id: this.selectedWorkflowId,
        service: this.selectedServiceForHealthTracking
      }).then(() => {

        this.isHealthRequestPending = false;
      });
    },

    initChannelHealthTracking(shouldClear) {

      clearInterval(this.healthTrackingIntervalId);
      this.healthTrackingIntervalId = null;

      if (shouldClear) {

        return;
      }

      this.makeHealthTrackingRequest(true);

      this.healthTrackingIntervalId = setInterval(() => this.makeHealthTrackingRequest(), 10000);
    },

    clearPlaybackRetry() {

      clearInterval(this.playbackRetryId);
      this.playbackRetryId = null;
    },

    onHlsError(event, data) {

      if (data.type === 'networkError' && data.response) {

        if (data.response.code === 504) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.clearPlaybackRetry();
        }

        if (data.response.code >= 400 && data.response.code <= 499) {

          this.setupRetryPlaybackIfApplicable();

          this.isHlsStreamRecoveringFromError = true;
        }

      } else {

        // Log the error for debugging
        console.error('onHlsError', event, data);
      }
    },

    setupRetryPlaybackIfApplicable() {

      if (this.playbackRetryId) {

        return;
      }

      this.playbackRetryId = setInterval(() => {

        this.loadHlsVidPlayback();
      }, 5000);
    },

    loadHlsVidPlayback() {

      if (Hls.isSupported()) {

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }

        if (!this.cdnPlaybackUrl) {

          // this.hls.off(Hls.Events.ERROR, this.onHlsError);

          return;
        }


        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });

        const video = this.$refs.previewVid;

        this.hls.attachMedia(video);

        if (this.isHlsStreamRecoveringFromError) {

          // set this flag in the error handler when 400-499 enters
          console.log('RECOVERS~')

          this.hls.startLoad();
          this.isHlsStreamRecoveringFromError = false;
        }

        console.log("try to loadHlsVidPlayback", this.shouldStreamPlay,video);

        this.hls.on(Hls.Events.MEDIA_ATTACHED, (e, d) => {

          console.log("video and hls.js are now bound together !", e, d);

          this.hls.loadSource(this.cdnPlaybackUrl);
        });

        this.hls.once(Hls.Events.LEVEL_LOADED, (event, data) => {

          console.log("LEVEL_LOADED ", event, data);
          console.log(111, data.details.live)

          // Handle the edge case where a stale stream will be played as a VOD.
          // The player should retry until a valid LIVE stream appears or
          // another error is found.
          if (data.details.live === false) {

            // Leave for debuggind -> if this log is present, but shouldn't be check the
            // this.hls.once(Hls.Events.LEVEL_LOADED handler. For the stale VOD logic to work,
            // the `data.details.live` prop should only be false for STALE VODS and not anything else.
            console.log('STALE stream -> return');

            this.setupRetryPlaybackIfApplicable();

            this.hls.detachMedia();

            return;
          }

          this.hls.media.muted = true;
          this.hls.media.play();

          if (this.playbackRetryId) {

            this.clearPlaybackRetry();
          }
        });


        // NOTE: this is the recommended hook when one should play the stream. Since
        // we have additional logic for stale streams, we are using LEVEL_LOADED.
        // Change it back if it's not working as expected.
        this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {

          console.log("MANIFEST_PARSED " + data.levels && data.levels.length + " quality level", event, data);
        });


        this.hls.on(Hls.Events.ERROR, this.onHlsError);

        // Some browsers pause the video if PiP is closed. Prevent it
        video && video.addEventListener('leavepictureinpicture', (e) => {

          setTimeout(() => {

            video.play();
          });
        });
      }
    },

    loadOvenPlayer() {

      if (!this.isWorkflowPreviewShown || !this.shouldSecondaryStreamPlay) {

        return;
      }

      this.unloadOvenPlayer();

      // NOTE: timeout is needed for the #oven-video-container UI element
      // to appear in the DOM when conditions to play the stream change to true
      setTimeout(() => {

        this.ovenPlayerInstance = OvenPlayer.create('oven-video-container', {

          ...this.ovenPlayerConfig,

          hlsConfig: this.hlsConfig,

          sources: this.ovenPlayerSources
        });

        // this.ovenPlayerInstance.on('ready', () => {

        // });

        this.ovenPlayerInstance.on('sourceChanged', (info) => {

          this.setSelectedOvenplayerSourceName(info.currentSource);
        });

        this.ovenPlayerInstance.on('error', (err) => {
          console.error('Oven player err: ', err)
        });
      });
    },

    setSelectedOvenplayerSourceName(index) {

      this.selectedOvenplayerSourceName = this.ovenPlayerSources.length ?
        this.ovenPlayerSources[index]?.label : '';
    },

    sortAlerts(type) {

      this.sort[type] = !this.sort[type];

      let sortChannelAlerst = [...this.selectedWorkflowChannelVisibleAlerts];

      if (type === 'state' || type === 'type' || type === 'message'){

        this.sort[type] ? sortChannelAlerst.sort((a,b) => b[type].localeCompare(a[type])) : sortChannelAlerst.sort((a,b) => a[type].localeCompare(b[type]));

      } else if (type === 'triggeredAt' || type === 'clearedAt'){

        this.sort[type] ? sortChannelAlerst.sort((a,b) => moment(b[type]).valueOf() - moment(a[type]).valueOf()) : sortChannelAlerst.sort((a,b) => moment(a[type]).valueOf() - moment(b[type]).valueOf());

      } else {

        this.sort[type] ? sortChannelAlerst.sort((a,b) => b[type] - a[type]) : sortChannelAlerst.sort((a,b) => a[type] - b[type]);

      }

      this.selectedAlertsType === 'actual' ? this.setSelectedWorkflowChannelAlertsData(sortChannelAlerst) : this.setSelectedWorkflowChannelAlertsHistoryData(sortChannelAlerst);

    },

    setIsInputSwitchSelected(newVal) {

      this.isInputSwitchSelected = newVal;

      if (!newVal) {

        this.selectedInput = this.mainInput;
      }
    },

    switchInputs() {

      const params = {};

      params.id = this.selectedWorkflowId;

      params.body = {

        "inputName": this.selectedInput
      };

      this.isInputSwitchImmediate === 'false' &&
        (params.body.time = this.inputSwitchTime);

      this.isInputSwitchInProgress = true;

      this.switchInputForWorkflow(params)
        .then(() => {

          this.$toasted.success(`Switching to ${this.selectedInput} has been successful. Please note your preview may not be real time and it may take some time for the new input to appear.`);

          // The BE has a limitiation and needs 5s
          setTimeout(() => {

            this.refreshActiveInput()
              .then(() => {

                this.isInputSwitchInProgress = false;
                this.setIsInputSwitchSelected(false);
              });
          }, 5000);

        })
        .catch(() => {

          this.isInputSwitchInProgress = false;
        });
    },

    getCdnUrl(channelData) {

      let newCdnUrl = '';

      const outputsList = channelData?.endpoints?.output;

      if (outputsList) {

        let cdnOutput = null;

        for (let output of outputsList) {

          if (!output.requireAuthorization) {

            cdnOutput = output;
            break;
          }
        }

        // Map for old live versions
        if (!cdnOutput && outputsList[0]) {

          cdnOutput = outputsList[0];
        }

        const cdnUrl = cdnOutput?.url;

        if (cdnUrl?.includes('http')) {

          newCdnUrl = cdnUrl;
        }
      }

      return newCdnUrl;
    },
  },
  computed: {

    ...mapGetters({

      selectedWorkflow: "live/selectedWorkflowForPreview",
      selectedWorkflowChannelData: "live/selectedWorkflowChannelData",
      selectedWorkflowChannelAlerts: "live/selectedWorkflowChannelAlerts",
      selectedWorkflowChannelLogs: "live/selectedWorkflowChannelLogs",
      selectedWorkflowChannelAlertsHistory: "live/selectedWorkflowChannelAlertsHistory",
      selectedWorkflowChannelHealth: "live/selectedWorkflowChannelHealth",
      areGeneralDetailsLoading: 'live/areGeneralDetailsLoading',
      areChannelDetailsLoading: 'live/areChannelDetailsLoading',
      areChannelLogsLoading: 'live/areChannelLogsLoading',
      // areAlertsCurrentlyLoading: 'live/areAlertsCurrentlyLoading',
      selectedBlueprintModel: "live/selectedBlueprintModel",
      workflowsList: "live/workflowsList",
      isWorkflowsListLoading: "live/isWorkflowsListLoading",
      organizationSelected: "app/organizationSelected",
      msgBusMsgList: 'app/msgBusMsgList',
      mediatailors: "channelManager/mediatailors",
    }),

    mediatailorsForLive() {

      return this.mediatailors.filter(m => m.type === 'live');
    },

    isSelectedWorkflowPaused() {

      return this.selectedWorkflow?.status === 'PAUSED';
    },

    selectedWorkflowFailReasonArr() {

      return this.selectedWorkflowChannelData && this.selectedWorkflowChannelData.failReason ?
        this.selectedWorkflowChannelData.failReason.split(';') : [];
    },

    hasSelectedWFChannelDataInputs() {

      return this.selectedWorkflowChannelData.endpoints &&
        this.selectedWorkflowChannelData.endpoints.input &&
        this.selectedWorkflowChannelData.endpoints.input.length;
    },

    shouldStreamPlay() {

      return this.cdnPlaybackUrl && this.selectedWorkflow.status === 'RUNNING';
    },

    shouldSecondaryStreamPlay() {

      return this.ovenPlayerSources.length;
    },

    ovenPlayerSources() {

      const sources = [];

      const endpointOutputs = this.selectedWorkflowChannelData?.endpoints?.output || [];

      endpointOutputs.forEach(o => {

        if (o.type === 'webrtc') {

          sources.push({
            label: 'WEBRTC',
            type: 'webrtc',
            file: o.url
          });
        }

        if (o.type === 'llhls') {

          sources.push({
            label: 'LLHLS',
            type: 'll-hls',
            file: o.url
          });
        }

        if (o.type === 'dash') {

          sources.push({
            label: 'DASH',
            type: 'dash',
            file: o.url
          });
        }
      });

      return sources;
    },

    selectedWorkflowChannelStatus() {

      return this.getReadableChannelStatus(this.selectedWorkflowChannelData.status);
    },

    selectedWorkflowBlueprint() {
      return this.selectedWorkflow ? this.selectedWorkflow.blueprint : {};
    },
    selectedWorkflowSchedule() {
      return this.selectedWorkflow && this.selectedWorkflow.schedule
        ? this.selectedWorkflow.schedule
        : {};
    },

    selectedWorkflowScheduleRecurrence() {

      return this.selectedWorkflowSchedule.recurrence;
    },

    selectedWorkflowParsedRecurrenceInfo() {

      if (this.selectedWorkflowScheduleRecurrence && this.selectedWorkflowScheduleRecurrence.days) {

        return this.selectedWorkflowScheduleRecurrence.days.join(',');

      } else if (this.selectedWorkflowScheduleRecurrence && (
        this.selectedWorkflowScheduleRecurrence.unit === null ||
        this.selectedWorkflowScheduleRecurrence.unit === 'DAY')) {

        return 'Every Day';
      }
    },

    selectedWorkflowChannelVisibleAlerts() {

      return this.selectedAlertsType === 'actual' ?
        this.selectedWorkflowChannelAlerts : this.selectedWorkflowChannelAlertsHistory;
    },

    hasWorkflowEverRun() {

      return this.selectedWorkflow.status !== 'READY';
    },

    isWorkflowPreviewShown() {

      return this.selectedWorkflow.status === 'RUNNING' || this.selectedWorkflow.status === 'PAUSED';
    },

    shouldDisplayAdLayout() {

      return this.selectedTabName === 'Ad Insertion' &&
        this.selectedWorkflow.status === 'RUNNING' &&
        this.selectedWorkflow?.blueprint?.settings?.adServers?.length;
    }
  },

  watch: {

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];
      const affectedWorkflowId = newestMsg.data.resourceId;

      // The previewUrls need to be updated
      if (newestMsg.type === 'OME_FARGATE_TASK_RESTARTED' && affectedWorkflowId === this.selectedWorkflowId) {

        this.$toasted.info('The preview urls were updated and it takes about a minute for the new urls to become active and working!');

        this.refreshChannelData(this.selectedWorkflowId);
      }
    },

    hasSelectedWFChannelDataInputs() {

      if (!this.hasSelectedWFChannelDataInputs) {

        return;
      }

      const inputOptions = [];

      for (let i = 0; i < this.selectedWorkflowChannelData.endpoints.input.length; i++) {

        const input = this.selectedWorkflowChannelData.endpoints.input[i];

        const currentFormat = {

          value: input.inputName,
          label: input.inputName || 'Unnamed input',
          url: input.endpoints && input.endpoints[0] || ''
        };

        inputOptions.push(currentFormat);
      }

      this.inputOptions = [ ...inputOptions ];
    },

    workflowId() {

      this.setupWorkflowStats();
      //NOTE: close the bp here if it was opened, so that it can be reloaded with the one for the new WF
      this.closeBlueprintPreview();

      this.setSelectedWorkflowChannelHealth({ items: null });
    },

    selectedWorkflowChannelData(newVal) {

      if (Object.hasOwn(newVal, 'items')) {

        this.refreshChannelData(this.workflowId);
        return;
      }

      this.cdnPlaybackUrl = this.getCdnUrl(newVal);

      this.loadHlsVidPlayback();

      this.loadOvenPlayer();
    },

    shouldStreamPlay(shouldPlay) {

      if (shouldPlay) {

        this.loadHlsVidPlayback();

        // On initial change of a channel to RUNNING status,
        // the interval should be triggered manually from here
        this.setupRunTimeRefreshIterval();

      } else {

        this.unloadHlsPlayer();
      }
    },

    shouldSecondaryStreamPlay(shouldPlay) {

      if (shouldPlay) {

        this.loadOvenPlayer();

      } else {

        this.unloadOvenPlayer();
      }
    }
  }

};
</script>

<style lang="scss" >
  /**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";

.workflow-details-container {

  .custom-ad-duration-input {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    input {

      width: 80px;
      min-height: 32px;
      padding-right: 8px;
    }

    svg {

      display: none;
    }
  }

  .datepicker-overwrite-logs {

    width: 160px;
    position: relative;

    .ds-fields__input.is-medium {

      font-size: 12px !important;
      color: black;
      font-weight: 500;
    }

    .form-control.datepicker.input.ds-fields__input.is-medium {

      height: 32px;
      min-height: auto;
      padding-left: 8px;
    }

    .form-control.datepicker.input.ds-fields__input {

      box-shadow: none;
      outline: none;
    }

    .placeholder {

      top: 6px;
      left: 8px;
      width: 100%;
      pointer-events: none;
    }

    .flatpickr-wrapper~svg {

      z-index: 1;
      background: white;
      padding: 8px;
      right: 2px;
    }
  }

  .datepicker-overwrite-ads, .datepicker-overwrite-input-switch {
    .placeholder {

      top: 6px;
      left: 8px;
      width: 100%;
      pointer-events: none;
    }
  }

  .ds-collapsible {

    .ds-collapsible__header, .ds-collapsible__body {
      padding-left: 0;
    }


    .ds-collapsible__icon {

      font-size: 20px;
    }
  }

  .alerts-table, .logs-table {

    @apply
    border
    border-black-200
    px-0
    rounded;

    thead th {

      @apply
      px-4
      py-1.5;

      min-width: auto;
    }

    td {

      @apply
      text-sm
      pr-8;

      min-width: auto;

      .cell-content {

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;


        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .ds-tabs {

    position: relative;
  }

  .ds-tabs__nav {

    min-height: 41px;
  }

  th, label {
    @apply
    font-medium;
  }

  .ratio-16-9 {
    width: 100%;
    padding-bottom: 56.25%;
  }
  // @include overwrite-styleberry-styles-htags-font-size;
  // @include overwrite-styleberry-styles-htags-margin;

//  .overwrite-styleberry-styles-htags-margin {
//     margin-top: 1em;
//     margin-bottom: 0.5em;
//   }

  .size0 {
    font-size: 16px;
    font-size: 1rem;
  }

  .size-1 {
    font-size: calc(1rem - .1618vmin);
  }

  .size\+1 {
    font-size: calc(1rem + .4854vmin);
  }

  .m-0 {
    margin: 0px;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  /********************************END**********/



  // background: var(--sceneBgColor);
  height: calc(100vh - 64px);
  overflow: auto;

  .disabled {

    cursor: not-allowed;
  }

  .mr-0125 { margin-right: .125rem;}
  .mb-0125 { margin-bottom: .125rem;}

  .pane-header .btn.tab {

    min-width: 20%;

    &:not(.active) {

      box-shadow: none;
    }
  }

  // .details-action-open-btn {

  //   position: absolute;
  //   top: 5px;
  //   right: 0;
  // }

  .workflows-list .dropdown-content {

    width: 600px;
    max-height: 600px;
    overflow: auto;
  }

  .text-plain {
    font-weight: 400;
  }

  .alerts-container {

    .state {

      color: green;

      &.active {

        color: red;
      }
    }
  }

  .stream-name-col {

    word-break: break-all;
  }

  .url {

    color: var(--color-blue-600);
    margin-left: 0.25rem;

    span {

      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 12px;
    }
  }

  .copy-to-clipboard-input {
    /**
    * Hide the visibility via z-index, since hiding it in
    * any other way will make the copy to clipboard not work.
    */
    position: fixed;
    z-index: -1;
    right: 10000px;
  }

  .blueprint-preview > div {

    width: 100%;
    height: 100%;
  }

  .blueprint-render-container {

    width: 100%;
    height: 450px;
  }

  .blueprint-hide {

    overflow: hidden;
    opacity: 0;
  }

  .blueprint-loader {

    position: absolute;
  }

  .live-workflows-preview-vid {

    @apply
    bg-red-50
    rounded;

    .tags-section {

      position: absolute;
      top: 0;
      left: 0;
    }

    video {
      height: 100%;
    }

    .op-wrapper.ovenplayer {

      position: absolute;
    }

    video::-webkit-media-controls-timeline {
      display: none;
    }

    video::-webkit-media-controls-time-remaining-display {
      display: none;
    }

    // video::-webkit-media-controls-current-time-display {
    //   display: none;
    // }
    video::-webkit-media-controls-play-button {
      display: none;
    }

    video::-webkit-media-controls-overlay-play-button {
      display: none;
    }
  }

  .logs-label {
    label {
      @apply
      m-0
      pb-2;
    }
  }

  .width-40 {
    width: 40rem;
  }

  .tooltip-z {
    z-index: 1;
  }

  .table {

    tbody {
      display: block;
      max-height: 250px;
      overflow: auto;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }
  }

  .input-margin-right {
    margin-right: 0.5rem;
  }

  .expand-details-toggle {

    position: absolute;
    right: 16px;
    top: 0;
  }

  .video-previews-box {

    width: 50%;

    &.secondary-stream-active {
      width: 100%;
    }
  }

  .is-preview-collapsed {

    position: relative;

    .video-previews-box {

      position: absolute;
      top: 0;
      right: 56px;
      width: 178px;

      &.secondary-stream-active {
        width: 356px;
      }
    }
  }

  .log-msg-col {

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: break-spaces;
  }

  .ad-start-radio, .input-switch-time-radio {

    .ds-radio__label {

      @apply
      pb-2;
    }

    .ds-radio {
      @apply
      flex;
    }

    .ds-radio__element {

      @apply
      mr-4;
    }
  }

  .input-switch-tab {

    .form-section {

      margin-top: -33px;
    }
  }

  .custom-input-radio {

    .ds-label {

      // height: 18px;
    }

    .ds-radio {

      label {
        height: 24px;
        width: 24px;
        overflow: hidden;
      }
    }
  }

  .datepicker-overwrite-input-switch {

    .flatpickr-calendar {
      right: 0;
    }
  }
}
</style>
