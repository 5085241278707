<template>
  <div class="library-component">

    <div
      class="pane-toolbar p1 border-bottom flex items-center justify-between flex-none"
    >


      <div class="flex items-center mr1 filter-search-combo">


        <vod-list-filters-component :applyFiltering="applyFiltering"/>
        <input-search-component :onSearchInput="handleInputSearch" size="small" isBorderless/>
      </div>

      <button-component variant="default" style="height: 34px" size="size-m" class="items-center ml-auto flex-none" @click="show()" v-if="shouldDisplayAddSourceCtrl">
        <ioio-icon icon="fas-circle-plus" class="h-3.5 text-green-600 mr-2"/>
        <span>Add Video</span>
      </button-component>
    </div>

    <div class="pane-content flex-auto" style="overflow: auto" ref="libraryScrollArea">
      <ul class="list-reset mt1 px1" :class="listType" v-if="!isVodListLoading">
        <DraggableHolderComponent
          group-name="library-playlist"
          :get-child-payload="getChildPayload"
          :should-accept-drop="shouldAcceptDrop"
          :get-ghost-parent="getGhostParent"
          drag-class="ghost-element"
        >
          <DraggableComponent v-for="vod in filteredVodList" :key="vod.guid" style="overflow: visible">
            <li class="fileitem flex mb1">

              <div v-if="vod.status !== 'encode-later'">

                <thumbnail-component :type="vod.meta && vod.meta.type" :duration="parseDuration(vod.srcDuration)" :image="vod.meta && vod.meta.imageUrl"/>
              </div>
              <button-component
                v-else
                @click="makeEncodeFileRequest(vod)">Encode
              </button-component>

              <div class="fileitem-info ml2 flex flex-column flex-auto">
                <h4 class="fileitem-title size-2 truncate">{{ vod.meta && vod.meta.title | truncate(64, '...') }}</h4>
                <div class="flex flex-wrap items-center">
                  <tag-component v-if="vod.meta && vod.meta.group" class="fileitem-group mr-025 mb-025">{{ vod.meta && vod.meta.group || "No group" }}</tag-component>
                  <tag-component v-if="vod.meta && vod.meta.genre" class="fileitem-genre mb-025">{{ vod.meta && vod.meta.genre }}</tag-component>
                </div>
                <div class="fileitem-actions">
                  <vod-lib-actions-component
                    :vod="vod"
                    :initLibraryItemPlayback="initLibraryItemPlayback"
                    :showEdit="showEdit"
                    :onNotEncodedVodPreview="onNotEncodedVodPreview"
                    :areEditAndPreviewSeperate="true"
                    editLabel="Edit"
                    previewLabel="Preview"
                    proxyPreviewLabel="Preview"
                    v-if="shouldDisplayVodActions"/>
                </div>
                <div v-if="vod.status !== 'complete' && vod.status !== 'encode-later'">

                  Status: {{vod.status}}
                </div>
              </div>
            </li>
          </DraggableComponent>
        </DraggableHolderComponent>
      </ul>

      <div v-else class="h100 width+10 p2"><loader-component height="100%" spinner="logo" size="size-m"  /></div>

    </div>
    <div class="pane-footer mx2 py1 border-top flex items-center justify-between">

      <pagination-component
        :page-selected="vodSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="vodSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

      <div class="flex justify-end width+2">

        <div>
          <div class="btn-group minimal justify-end">

            <button-component
              variant="minimal"
              size="size-xs"
              @click="toggleList()"
              :class="listType == 'list' ? 'active' : ''"
            >
              <ioio-icon icon="far-bars" class="w-3.5 h-3.5"/>
            </button-component>
            <button-component
              variant="minimal"
              size="size-xs"
              @click="toggleBlock()"
              :class="listType == 'blocklist' ? 'active' : ''"
            >
              <ioio-icon icon="far-table-list" class="w-5 h-5"/>
            </button-component>
          </div>
        </div>
      </div>
    </div>

    <modal
      name="edit-vod"
      width="600"
      height="auto"
      :scrollable="true"
      classes="v--modal p2 mt4 mb2"
      @before-open="beforeEditVodModalOpen"
    >
      <vue-form :state="formState" @submit.prevent>

        <validate class="" tag="label">
          <label class="">Title <span class="text-danger">*</span></label>
          <input
            class="input"
            type="text"
            placeholder="Title"
            required
            name="title"
            maxlen="100"
            v-model="editedVodMeta.title"/>
          <field-messages name="title" show="$submitted || $dirty && $touched">
            <div slot="required">Title is a required field</div>
            <div slot="maxlen">Title length should not be more than 100 characters</div>
          </field-messages>
        </validate>

        <validate class="" tag="label">
          <label class="">Description</label>
          <input
            class="input"
            type="text"
            placeholder="Description"
            name="description"
            maxlen="500"
            v-model="editedVodMeta.description"
          />
          <field-messages name="description" show="$submitted || $dirty && $touched">
            <div slot="maxlen">Description length should not be more than 500 characters</div>
          </field-messages>
        </validate>

        <validate class="" tag="label">
          <label class="">Type <span class="text-danger">*</span></label>

          <select
            class=""
            id="meta['type']"
            v-model="editedVodMeta.type"
            required
            name="type"
            >
              <option
                v-for="option,i in possibleVodTypes"
                :value="option.value"
                :key="i"
              >{{ option.label }}</option>
          </select>

          <field-messages name="type" show="$submitted || $dirty && $touched">
            <div slot="required">Type is a required field</div>
          </field-messages>
        </validate>

        <validate>
          <label for="meta['group']">Group</label>
          <input
            type="text"
            id="meta['group']"
            placeholder="Group"
            v-model="editedVodMeta.group"
            name="group"
            maxlen="64"
          />
          <field-messages name="group" show="$submitted || $dirty && $touched">
            <div class="size-3" slot="maxlen">Group length should not be more than 64 characters</div>
          </field-messages>
        </validate>
        <validate>
          <label for="meta['genre']">Genre</label>
          <input
            type="text"
            id="meta['genre']"
            placeholder="Genre"
            v-model="editedVodMeta.genre"
            name="genre"
            maxlen="64"
          >
          <field-messages name="genre" show="$submitted || $dirty && $touched">
            <div class="size-3" slot="maxlen">Genre length should not be more than 64 characters</div>
          </field-messages>
        </validate>
        <div>
          <label for="meta['imageUrl']">Image url</label>
          <input type="text" id="meta['imageUrl']" placeholder="Image url" v-model="editedVodMeta.imageUrl">
        </div>
        <div>
          <label class="mb1" for="start-date-time">Expires At
          </label>

          <section class="flex">
            <datetime
              class="flex-auto"
              v-model="expiresAt"
              input-id="expiresDateTime"
              type="datetime"
              use12-hour
              placeholder="Expires At"
              format="DDD t ZZZZ"
              name="expiresDateTime"
            ></datetime>
            <button-component
              class="ml2"
              variant="minimal"
              shape="circle"
              @click.stop.prevent="clearExpiresStamp">
                <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
            </button-component>
          </section>
        </div>


        <custom-meta-fields-legacy-component
          class="pt2 mt3 border-top"
          :editedAsset="editedVodMeta"
          metaResourceType="video" />

        <button-component
          type="submit"
          variant="primary"
          size="size-m"
          class="mt2"
          @click="saveEditedVod"
          :class="isEditInProgress ? 'disabled' : ''"
        >Save Changes</button-component>
        <button-component
          variant="subtle"
          size="size-m"
          class="mt2"
          @click.stop.prevent="closeEditVodModal"
          :class="isEditInProgress ? 'disabled' : ''"
        >Discard</button-component>

      </vue-form>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({
    listType: "blocklist",

    dragOrigin: "library",

    formState: {},
    editedVodMeta: { },
    editedVodGuid: null,

    isExpiresAtChanged: false,
    expiresAt: '',

    isEditInProgress: false,

    isVodListLoading: false
  }),
  created() {},

  beforeDestroy() {

    this.unstageFromFiltering('vodLibrary');
  },

  props: {

    shouldDisplaySourceCtrls: {

      default: true,
      type: Boolean
    },
    shouldDisplayAddSourceCtrl: {

      default: true,
      type: Boolean
    },

    shouldDisplayVodActions: {

      default: true,
      type: Boolean
    },

    shouldHideInactiveVods: {

      default: false,
      type: Boolean
    },
  },

  mounted() {
    this.getVodList();

    window.li = this;
  },

  methods: {

    clearExpiresStamp() {

      this.expiresAt = '';
    },

    toggleList: function() {
      this.listType = "list";
    },
    toggleBlock: function() {
      this.listType = "blocklist";
    },
    show() {
      this.$modal.show("add-source");
    },
    showEdit(vod) {
      this.$modal.show("edit-vod", vod);
    },
    ...mapMutations({
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
      resetDashboardPanelsState: "channelManager/RESET_DASHBOARD_PANELS_STATE",
    }),
    ...mapActions({
      makeGetVodListRequest: "channelManager/makeGetVodListRequest",
      makeEditVodMetadataRequest: 'channelManager/makeEditVodMetadataRequest',
      makeEncodeFileRequest: 'channelManager/makeEncodeFileRequest',

      // new filtering actions
      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
    }),

    triggerEncodeFileRequest(vod) {

      this.makeEncodeFileRequest(vod);
    },

    getVodList() {

      this.isVodListLoading = true;

      this.makeGetVodListRequest()
        .then(list => {

          this.stageForFiltering({
            items: list,
            division: 'vodLibrary'
          });

          this.applyFiltering({
            searchOpts: {

              vodStatus: "content",
              pageNum: 1
            },
            filterType: 'vodLibrary'
          });
        })
        .finally(() => {

          this.isVodListLoading = false;
        });
    },

    getChildPayload(removedIndex) {

      return {
        item: this.filteredVodList[removedIndex],
        origin: this.dragOrigin,
        removedIndex
      };
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {
      return false;
    },

    getGhostParent() {
      return document.body;
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'vodLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'vodLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    parseDuration(ms) {
      return getHHMMSSFromMS(ms, 'returnFullHour');
    },

    initLibraryItemPlayback(vodItem) {

      this.resetDashboardPanelsState();

      this.setSingleSourcePlayed(vodItem);
    },

    onNotEncodedVodPreview(proxyUrl, vod) {

      this.initLibraryItemPlayback({
        ...vod,
        isMp4: true,
        hlsUrl: proxyUrl
      });
    },

    beforeEditVodModalOpen(vod) {

      this.editedVodMeta = {
        ...vod.params.meta
      };

      this.editedVodMeta.expires && (
        this.expiresAt = new Date(this.editedVodMeta.expires).toISOString());

      this.editedVodGuid = vod.params.guid;
    },

    saveEditedVod() {

      if(this.formState.$invalid) {

        return;
      }

      const payload = {
        params: this.editedVodMeta,
        vodGuid: this.editedVodGuid
      };

      if (this.expiresAt) {

        payload.params.expires = new Date(this.expiresAt).getTime();

      } else {

        delete payload.params.expires;
      }

      this.isEditInProgress = true;

      this.makeEditVodMetadataRequest(payload)
        .then(() => {

          this.stageForFiltering({
            items: this.vodList,
            division: 'vodLibrary'
          });

          this.applyFiltering({

            filterType: 'vodLibrary'
          });

          this.closeEditVodModal();

          this.$toasted.success('Your changes were saved successfully');
        })
        .finally(() => {

          this.isEditInProgress = false;
        });

    },

    closeEditVodModal() {

      this.$modal.hide("edit-vod");
      this.editedVodMeta = {};
      this.expiresAt = '';

      this.editedVodGuid = null;
    },
  },
  computed: {
    ...mapGetters({
      vodList: "channelManager/vodList",
      filteredVodList: "filterAndPaginate/filteredVodList",
      filteredItemsCount: "filterAndPaginate/filteredVodListItemsCount",
      vodSearchOptions: "filterAndPaginate/vodSearchOptions",
      possibleVodTypes: "channelManager/possibleVodTypes",
    })
  },

  watch: {

    vodList() {

      this.stageForFiltering({
        items: this.vodList,
        division: 'vodLibrary'
      });

      this.applyFiltering({

        searchOpts: {

          vodStatus: "content",
          pageNum: 1
        },

        filterType: 'vodLibrary'
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.library-component {
  background: var(--windowBgColor);
  color: var(--windowColor);
}

.pane-header {
  background: var(--sceneBgColor);
}

.fileitem {
  display: flex;
  margin-bottom: 2px;
  border-radius: 4px;
  padding: 0.5rem;
  position: relative;

  .fileitem-actions {
    margin: 0.25rem;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
  }
  &:hover {
    background: var(--highlightBgColor);
  }
  .fileitem-title {
    word-wrap: break-word;
    margin: 0;
    margin-bottom: 0.25rem;
    max-width: 85%;
  }
  .fileitem-type {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
  }
  .fileitem-duration {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
  }
}

.list {
  .fileitem {
    border-bottom: 1px solid #eee;
    border-radius: 0;
    margin: 0;
    padding: 0.5rem 0;
    position: relative;

    .thumbnail-component {

      display: none;
    }

    .fileitem-image,
    .tag {
      display: none;
    }
    .fileitem-info {
      width: 100%;
      display: flex;
    }
    .fileitem-duration {
      order: 1;
      margin-right: 0.5rem;
    }
    .fileitem-title {

      margin-right: auto;
    }
    .fileitem-type {
      display: inline-flex;
      order: 3;
    }
  }
}

// the element that is being dragged
.ghost-element {
  width: 20rem;
  border-radius: 4px;
  overflow: visible;
  background: white;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--highlightColor) !important;

  // transform: translate(-50%, 0px);
}

.smooth-dnd-draggable-wrapper {
  //TODO: investigage if translate(50%, 0px);
  // on .smooth-dnd-draggable-wrapper or
  // on .ghost-element or combination of both
  // will improve the on drag behavior,
  // where the item has to be dragged to 50%
  // of it's width so that it overtakes another item

  // transform: translate(50%, 0px);
}

.filter-search-combo {
  border: 1px solid var(--color-gray-400);
  border-radius: 4px;
  overflow: hidden;
  height: 35px;
  min-height: 35px;
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

.green-1 {
    color: #57ad00;
}
</style>
