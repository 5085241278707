import { getRandomColor } from "@utils/helpers";

/**
 * @BtF namespace stands for `Backend to Frontend`.
 * @FtB namespace stands for `Frontend to Backend`.
 *
 * Their intend is to differentiate the methods, that are
 * present in the DTOMiddleware. Each method, that is added
 * here should have one of the namespaces applied.
 */

export const BtF_VODSourceToPlaylistForAds = (vod) => {
console.log(vod)
  const selectedVod = {

    name: vod.meta.title,
    type: vod.meta.type, // should be 'source'
    duration: vod.srcDuration,
    absoluteStart: 0, // Unafected from the ads (only applicable to type `vod`)
    startOffset: 0, // start of every chunk, with ads in mind
    end: vod.srcDuration, // end of every chunk, with ads in mind. The start end is the total duration, since the chunk is only one.
    originalObject: vod
  };

  return selectedVod;
}

export const BtF_VODAdToPlaylistForAds = (vod) => {
  console.log(vod)
  const selectedVod = {

    name: vod.meta.title,
    type: vod.meta.type, // should be 'ad'
    duration: vod.srcDuration,

    originalObject: vod
  };

  return selectedVod;
}

export const FtB_VODChunksToPlaylistWithAds = (vodChunks, newProps) => {


  console.log('vodChunks', vodChunks)

  const ads = [];
  let originalVodData = null;

  vodChunks.forEach(chunk => {
    if (chunk.type === 'ad') {
      console.log('ad chunk', chunk)

      ads.push({
        start: chunk.atBreakpoint,
        guid: chunk.originalObject.guid,
        substitute: 0
      });

    } else if (!originalVodData && chunk.type === 'source') {

      originalVodData = chunk.originalObject;
    }
  });

  const sourceWithAds = {
    guid: originalVodData.guid,
    type: "source",
    substitute: 0,
    ads
  };

  const exitDto = {

    // Required on the BE
    name: newProps.name,
    sources: [ sourceWithAds ],
  };

  // Non required props; add them only if they have set values in the newProps obj
  newProps.color && (exitDto.color = newProps.color);
  newProps.imageUrl && (exitDto.imageUrl = newProps.imageUrl);
  newProps.description && (exitDto.description = newProps.description);
  newProps.title && (exitDto.title = newProps.title);

  return exitDto;
}



export const BtF_VODPlaylistItemForMultiplePlaylists = (playlist) => {

  const totalDuration = playlist.srcDuration;

  const isPlaylistFromSourceWithAds =
    playlist.meta.sources.length === 1 &&
    playlist.meta.sources[0].ads &&
    playlist.meta.sources[0].ads.length;

  const sources = playlist.meta.sources.map(source => {

    const mappedSource = {

      guid: source.guid,
      name: source.meta.title,
      duration: source.totalDuration || source.duration,

      color: playlist.meta.color, // the source has no color, use the one of the original playlist
      originalObject: playlist,
      origin: isPlaylistFromSourceWithAds ? 'playlist-with-ads' : 'playlist',
      type: source.meta.type
    };

    // If the source has ads, add a quick reference to them
    isPlaylistFromSourceWithAds && (mappedSource.adsRef = source.ads);

    return mappedSource;
  });

  return {
    sources,
    totalDuration
  };
}

export const BtF_VODSourceItemForMultiplePlaylists = (source) => {

  const totalDuration = source.srcDuration;

  const mappedSource = {

    guid: source.guid,
    name: source.meta.name,
    title: source.meta.title,
    duration: source.srcDuration,

    color: getRandomColor(), // the source has no color
    originalObject: source,
    origin: 'single-source',
    type: source.meta.type
  };

  return {
    sources: [mappedSource],
    totalDuration
  };
}

export const FtB_VODPlaylistChunksAndVodsToPlaylist = (vodChunks, newProps) => {

  const sources = [];

  vodChunks.forEach(chunk => {

    if (chunk.origin === 'single-source') {

      sources.push({
        guid: chunk.guid,
        type: chunk.type,
        substitute: 0
      });

    } else {

      /**
       * The chunk origin is playlist-with-ads || playlist
       */
      const mappedSource = {

        guid: chunk.guid,
        type: chunk.type,
        substitute: 0,
      };

      /**
       * The playlist's source has ads in it. Add them to the DTO
       * as they have come from the BE.
       *
       * NOTE: If needed some props may be omited, so that the ads
       * part of the DTO comply with what the BE expects
       */
      if (chunk.adsRef && chunk.adsRef.length) {

        mappedSource.ads = chunk.adsRef;
      }

      sources.push(mappedSource);
    }
  });



  const exitDto = {

    // Required on the BE
    name: newProps.name,
    sources,
  };

  // Non required props; add them only if they have set values in the newProps obj
  newProps.color && (exitDto.color = newProps.color);
  newProps.imageUrl && (exitDto.imageUrl = newProps.imageUrl);
  newProps.description && (exitDto.description = newProps.description);
  newProps.title && (exitDto.title = newProps.title);
  newProps.meta && (exitDto.meta = newProps.meta);

  return exitDto;
}