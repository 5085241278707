"use strict"
import Vue from 'vue'


import store from "../../store";

const eBus = new Vue ({store});

export { eBus }


