<template>
<div class="delete-vod-component">
  <div
    class="open-delete-modal"
    @click.stop="toggleDeleteModalOpened"
  >

    <!-- The slot will allow for a custom open btn to be added to the component
     (just as markup, the actual opening is triggered from above - toggleDeleteModalOpened)
    -->
    <slot>
      <div
        class="p-2 hover-red hover-bg-red+5 flex items-center size-2 font-medium cursor-pointer"
      >
        <ioio-icon width="14px" height="14px" icon="fa-trash-can" class="mr-2 svg-icon" />
        Delete
      </div>
    </slot>
  </div>
  <ioio-modal
    class=""
    :show.sync="isDeleteVodModalOpened"
    v-if="isDeleteVodModalOpened"
    size="medium"
    headerClasses="border-bottom"
  >

    <template slot="header" >
      <div class="pb-3">
        Delete Video Asset
      </div>
    </template>

    <h3 v-if="!playlistsToBeDeleted.length" class="pt-6 overflow-y-hidden">
      "{{ vod.meta && vod.meta.title || 'Video' }}" will be deleted permanently.
      <br>Are you sure you want to do this?
    </h3>

    <section v-else>

      <h3>This video asset is part of the following playlists:</h3>

      <h6>(click to navigate)</h6>


      <section class="vods-for-deletion-list">
        <a
          class="cursor-pointer flex size-1 font-bold mb-4"
          v-for="playlist,i in this.playlistsToBeDeleted"
          :key="i"
          @click.stop="navigateToPlaylistDetails(playlist.guid)"
        >{{ playlist.name }}
        </a>

      </section>

      <h4 class="mb-4">Please remove it from the playlists above in order to delete.</h4>
    </section>

    <template slot="footer">
      <div class="flex justify-end" v-if="!isDeleteInProgress">
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="closeModal"
        >
          Cancel
        </ioio-button>
        <ioio-button
          v-if="!playlistsToBeDeleted.length"
          type="danger"
          variant="tonal"
          @click.stop.prevent="onConfirmDelete"
        >
          Yes, delete it!
        </ioio-button>
      </div>
      <div class="relative min-h-36" v-else>
        <ioio-loader isShown />
      </div>
    </template>
  </ioio-modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    playlistsToBeDeleted: [],
    isDeleteVodModalOpened: false,
    isDeleteInProgress: false,
  }),
  props: {

    vod: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
       makeDeleteVodRequest: 'channelManager/makeDeleteVodRequest'
    }),

     toggleDeleteModalOpened() {

      this.isDeleteVodModalOpened = true;
    },

    onConfirmDelete() {

      this.isDeleteInProgress = true;

      this.makeDeleteVodRequest({ vodGuid: this.vod.guid })
        .then(() => {

          this.$toasted.success('Video asset has been deleted.');
          this.closeModal();
        })
        .catch(errPayload => {

          console.log(errPayload);
          this.playlistsToBeDeleted = errPayload.error.playlists;
        })
        .finally(() => {

          this.isDeleteInProgress = false;
        });
    },

    navigateToPlaylistDetails(guid) {

      this.$pushRoute(`/vod/library/playlists?id=${guid}`);
    },

    closeModal() {
      this.isDeleteVodModalOpened = false;
      this.playlistsToBeDeleted = [];
      this.$emit('close');
    }
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">
.vods-for-deletion-list {

  max-height: 450px;
  overflow: auto;
}

</style>
