<template>

<ioio-modal
  :externalClasses="modalClass"
  :show.sync="isModalOpened"
  size="large"
  :noClose="!!forUploadVods.length"
  :clickOutside="false"
>

  <section class="flex h-full main-modal-content relative overflow-x-hidden "
    :class="{ 'is-opened' : isEditPanelOpened }"
  >

    <section class="h-full table-cont flex flex-column pb-6" v-if="!isUploadInProgress">

      <header>
        <header class="flex items-center justify-between pb-4" v-if="!forUploadVods.length">
          <div class="">
              Upload videos
          </div>

        </header>
        <header class="flex items-center justify-between mb-3" v-else>

          <aside>
            <h3 class="text-lg text-black-800 mb-2">Upload videos</h3>
            <h5 class="text-xs text-black-500">Drag and drop your files</h5>
          </aside>

          <aside class="flex">

            <ioio-button
              v-if="!isEditPanelOpened"
              type="secondary"
              variant="outline"
              size="small"
              iconLeft="fa-pen"
              class="mr-3"
              :disabled="bulkSelectedCount === 0"
              @click="toggleEditPanelOpened(true)"
            >
              {{ openEditPanelBtnText }}
            </ioio-button>

            <ioio-button

              type="secondary"
              variant="outline"
              size="small"
              iconLeft="fad-cloud"
              @click="triggerDropzoneClick"
            >
              Browse
            </ioio-button>
          </aside>
        </header>
      </header>

      <div class="flex-auto relative" :class="{ 'is-dragged-over': isDraggedOver }">

        <div class="h-full pb-6 dropzone-container" :class="{ 'staged-files-present': forUploadVods.length, 'upload-in-progress': isUploadInProgress }">
          <vue-dropzone
            :options="dropzoneOptions"
            :include-styling="false"
            id="customdropzone"
            class="h-full"
            ref="dropzoneComponent"
            :useCustomSlot="true"
          >
            <div v-if="!forUploadVods.length && !isDraggedOver" class="dropzone-custom-content flex flex-column items-center">

              <div class="text-green-500 flex flex-column items-center mb-2" v-if="vodBatchAlreadyUploaded">

                  <ioio-icon icon="fa-solid fa-circle-check" class="mb-4 w-20 h-20" />
                  <h3 class="text-lg mb-8">Videos successfully uploaded</h3>

                  <h3 class="text-xs text-black-800">
                    Want to add more? Drag and drop your videos here
                  </h3>
              </div>

              <h3 class="text-base text-black-800 mb-2" v-else>
                Drag and drop your videos here
              </h3>
              <h5 class="text-xs text-black-500 mb-4">
                We support .mpeg, .mov, .avi, .ts, .m2v, .mkv, .mpg, .mp4,
                .m4v, .mxf, .webm
              </h5>
              <ioio-button
                type="primary"
                variant="solid"
                size="small"
                iconLeft="fad-cloud"
              >
                Browse
              </ioio-button>
            </div>
          </vue-dropzone>
        </div>
        <ioio-table
          responsive
          :responsiveFlip="false"
          v-if="forUploadVods.length"
          class="vod-table max-h-full relative">
          <template slot="thead">
            <tr class="">
              <th class="checkbox-t title-col">
                <div class="flex items-center">
                  <ioio-checkbox
                    size="small"
                    disclaimer=""
                    class="mt-2"
                    v-model="isSelectAllOptionApplied"
                    @input="selectAllVods"
                  />
                  <p>Deselect all</p>
                </div>
              </th>

              <th
                class="truncate pr-8 general-col"
              >
                General
              </th>
              <th class="truncate pr-8 details-col">
                Details
              </th>
              <th class="truncate pr-8 hubs-col">
                Hubs
              </th>
              <th class="truncate pr-8 transcription-col">
                Transcription
              </th>
            </tr>
          </template>
          <template slot="tbody">

            <tr class="items-center vod-item"
              v-for="(vod, index) in forUploadVods"
              :key="vod.id"
              :class="{ 'bg-blue-50': bulkListMap[vod.id] }">
              <!-- NOTE: @click.stop="" is needed since toggling the checkbox triggers the above <tr onclick>  -->
              <td class="checkbox-t title-col" @click.stop="">

                <div class="flex items-center">
                  <ioio-checkbox
                    size="small"
                    disclaimer=""
                    class="mt-2"
                    v-model="bulkListMap[vod.id]"
                    @input="(newVal) => toggleAddVodToBulkList(vod)"
                  />

                  <aside class="flex-auto">

                    <div class="truncate text-sm text-black-700 mb-1">{{ vod.data.title || '--' }}</div>

                    <div class="text-xs text-black-500">{{ formatBytes(vod.file.size, 1) }}</div>
                  </aside>
                </div>
              </td>

              <td class="pr-8 general-col">
                <div class="">

                  <div class="text-xs text-black-500 mb-2">
                    {{ vod.data.privacy === 'true' ? 'Private' : 'Public' }}
                  </div>
                  <div class="text-xs text-black-500 mb-2">
                    {{ vod.data.type === 'source' ? 'Video' : 'Ad' }}
                  </div>

                  <ioio-label
                    text="To encode"
                    size="small"
                    v-if="vod.data.encode"
                  />
                  <ioio-label
                    text="Don't encode"
                    size="small"
                    v-else
                  />
                </div>
              </td>

              <td class="pr-8 details-col">
                <div class="text-xs text-black-500">

                  <div v-if="vod.data.categories" class="mb-1 truncate">

                    <span
                      v-for="(cat, index) in vod.data.categories"
                      :key="index"
                      class="mr-2"
                    >{{ cat }}
                    </span>
                  </div>

                  <div v-if="vod.data.tags" class="mb-1 truncate">

                    <span
                      v-for="(tag, index) in vod.data.tags"
                      :key="index"
                      class="mr-2"
                    >{{ tag }}
                    </span>
                  </div>

                  <div v-if="vod.data.group" class="mb-1 truncate">
                    <span>{{ vod.data.group }}</span>
                  </div>

                  <div v-if="vod.data.genre" class="mb-1 truncate">

                    <span>{{ vod.data.genre }}</span>
                  </div>

                </div>
              </td>

              <td class="pr-8 hubs-col">
                <div class="">

                  <div v-if="vod.data.hubs && vod.data.hubs.length" class="flex flex-column">

                    <ioio-label
                      v-for="(h, index) in vod.data.hubs"
                      :key="index"
                      :text="h.name"
                      class="mb-1"
                    />
                  </div>
                  <div v-else>--</div>
                </div>
              </td>

              <td class="pr-8 transcription-col">
                <div class="flex items-center">
                  <div class="flex flex-column mr-2">
                    <ioio-label
                      text="Auto subtitles"
                      size="small"
                      v-if="vod.data.autoSubs"
                      class="mb-1"
                    />
                    <ioio-label
                      text="Auto translation"
                      size="small"
                      v-if="vod.data.autoTranslate"
                      class="mb-1"
                    />
                    <ioio-label
                      :text="vod.data.medicalTranscribeType"
                      size="small"
                      v-if="vod.data.medicalTranscribe"
                      class="mb-1"
                    />
                  </div>

                  <div class="vod-actions flex justify-end mr-2">

                    <ioio-button
                      type="secondary"
                      variant="outline"
                      size="small"
                      class="mr-2 w-8 flex justify-center cursor-pointer"
                      @click.stop.prevent="toggleEditPanelOpened(true, index)"
                    >
                      <ioio-icon icon="fa-light fa-pen" />
                    </ioio-button>
                    <ioio-button
                      type="secondary"
                      variant="outline"
                      size="small"
                      @click.stop.prevent="onVodRemove(index)"
                      class="mr-2 w-8 flex justify-center cursor-pointer"
                    >
                      <ioio-icon icon="fa-light fa-trash-can" />
                    </ioio-button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </ioio-table>
      </div>
    </section>

    <section class="h-full table-cont flex flex-column pb-6" v-else>

      <header class="mb-6">

        <!-- @Stefup in design the label is "Upload videos" and there is the X btn to close - I advise NOT to close, but show a widget as a sidebar in the app, while upload is in progress -->
        <h3 class="text-lg text-black-800">Uploading videos</h3>

      </header>

      <div class="flex-auto relative">
        <ioio-table
          responsive
          :responsiveFlip="false"
          v-if="forUploadVods.length"
          class="vod-table max-h-full relative">

          <template slot="tbody">

            <tr class="items-center vod-item"
              v-for="(vod, index) in forUploadVods"
              :key="vod.id">

              <td class="uploading-title-col">

                <div class="truncate text-sm text-black-700 mb-1">{{ vod.data.title || '--' }}</div>

                <div class="text-xs text-black-500">{{ formatBytes(vod.file.size, 1) }}</div>
              </td>

              <td class="pr-6 progress-col">

                <progress-component class="w-full" :progress="vod.progressPercentage" v-if="vod.status !== 'error' && vod.status !== 'uploaded'"/>

              </td>

              <td class="progress-description-col text-xs capitalize">
                <div class="text-black-500" v-if="vod.status !== 'uploaded'">
                  <ioio-icon icon="fad-spinner" class="text-blue-600 fa-spin mr-1" />
                  {{ vod.status }}
                  <span class="ml-1 text-black-700" v-if="vod.progressPercentage">{{ `${vod.progressPercentage}%` }}</span>
                </div>
                <div class="text-green-500" v-if="vod.status === 'uploaded'">

                  <ioio-icon icon="fa-solid fa-circle-check" class="mr-1" />
                  {{ vod.status }}
                </div>
              </td>
            </tr>
          </template>
        </ioio-table>
      </div>
    </section>

    <ioio-logical-edit
      @changed="onLogicalEditChange"
      :detectChanges="!isFormDirty"
      class="edit-meta-panel flex flex-column pr-8"
    >

      <header class="flex justify-between mb-6">
        <h3 class="mt-2 flex-auto truncate">{{ editPanelHeaderText }}</h3>

        <ioio-button
          type="minimal"
          size="small"
          @click="toggleEditPanelOpened(false)">
          <ioio-icon
            icon="fa-solid fa-xmark"
          />
        </ioio-button>
      </header>

      <ioio-tabs
        class="upload-details-tabs flex flex-auto flex-column"
        @input="setSelectedTabName"
        ref="tabForm">

        <ioio-tab
          name="General"
          :selected="true"
          class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
        >

          <ioio-field
            label="Title"
            type="text"
            required
            topic="uploadVod"
            size="medium"
            placeholder="Title of video"
            v-model="editDataFields.title"
            v-if="!areMultipleVodsEdited"
            class="mb-4"
          />

          <ioio-field
            label="Description"
            type="textarea"
            topic="uploadVod"
            size="medium"
            placeholder="Description"
            v-model="editDataFields.description"
            v-if="!areMultipleVodsEdited"
            class="mb-4"
          />


          <ioio-toggle
            size="medium"
            labelRight="Encode"
            topic="uploadVod"
            v-model="editDataFields.encode"
          />

          <div class="text-xs text-blue-grey-400 mt-2 mb-4">All videos will get encoded after uploading</div>


          <ioio-dropdown
            topic="uploadVod"
            label="Type"
            fullWidth
            :items="possibleVodTypes"
            titleProp="label"
            valueProp="value"
            v-model="editDataFields.type"
            size="medium"
            class="mb-5"
          />

          <ioio-radio
            topic="uploadVod"
            class="w-full mb-5"
            :options="accessPrivacyOptions"
            name="privacy"
            size="medium"
            v-model="editDataFields.privacy"
            :checkedVal="editDataFields.privacy"
            label="Privacy"
            disclaimer="Set who this video is available to"
          />

        </ioio-tab>

        <ioio-tab name="Details" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

          <ioio-tags

            topic="uploadVod"
            size="medium"
            label="Categories"
            disclaimer="Manage videos and improve visibility"
            class="mb-5"
            v-model="editDataFields.categories"
            :tags="editDataFields.categories"
          />

          <ioio-tags

            topic="uploadVod"
            size="medium"
            label="Tags"
            disclaimer="Identify different video assets"
            class="mb-5"
            v-model="editDataFields.tags"
            :tags="editDataFields.tags"
          />

          <ioio-field
            label="Group"
            type="text"
            topic="uploadVod"
            size="medium"
            placeholder="Group"
            v-model="editDataFields.group"
            class="mb-5"
          />

          <ioio-field
            label="Genre"
            type="text"
            topic="uploadVod"
            size="medium"
            placeholder="Genre"
            v-model="editDataFields.genre"
            class="mb-5"
          />


          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900 mb-1">Featured video</div>

              <div class="text-xs text-blue-grey-400">This video will appear as featured in hubs</div>
            </aside>

            <ioio-toggle
              size="medium"
              topic="uploadVod"
              v-model="editDataFields.featured"
              :labelLeft="editDataFields.featured ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900 mb-1">List in Video Portals</div>

              <div class="text-xs text-blue-grey-400">This video will appear in video portal results</div>
            </aside>

            <ioio-toggle
              size="medium"
              topic="uploadVod"
              v-model="editDataFields.listedInPortals"
              :labelLeft="editDataFields.listedInPortals ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

        </ioio-tab>

        <ioio-tab name="Hubs" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 pt-2">
          <library-vod-hubs-tab-edit-component
            :editedVodMeta="editDataFields"
            v-if="isEditPanelOpened"
          />
        </ioio-tab>

        <ioio-tab name="Subtitles" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Auto subtitles</div>

            <ioio-toggle
              size="small"
              topic="uploadVod"
              v-model="editDataFields.autoSubs"
              :labelLeft="editDataFields.autoSubs ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Auto translation</div>

            <ioio-toggle
              size="small"
              topic="uploadVod"
              :disabled="!editDataFields.autoSubs"
              v-model="editDataFields.autoTranslate"
              :labelLeft="editDataFields.autoTranslate ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div v-if="editDataFields.autoTranslate" class="mb-5 text-black-500">
            <h5
              v-if="defaultData['caption-auto-translate-languages'].length"
              class="mb-3">
              The subtitles of this video will be automatically translated into the following languages:
            </h5>
            <h5
              v-else
              class="text-red-500">
              You don’t have any auto translate languages defined in Settings!
            </h5>
            <div
              v-for="lang,i in defaultData['caption-auto-translate-languages']"
              :key="i">
              {{ languagesTranslateConfig[lang] }}
            </div>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Medical transcription</div>

            <ioio-toggle
              size="small"
              topic="uploadVod"
              :disabled="!editDataFields.autoSubs"
              v-model="editDataFields.medicalTranscribe"
              :labelLeft="editDataFields.medicalTranscribe ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <ioio-dropdown

            v-if="editDataFields.medicalTranscribe"
            topic="uploadVod"
            label="Select type"
            fullWidth
            :items="possibleMedicalTranscriptionTypes"
            titleProp="label"
            valueProp="value"
            v-model="editDataFields.medicalTranscribeType"
            size="small"
            class="mb-5"
          />
        </ioio-tab>
      </ioio-tabs>
    </ioio-logical-edit>

  </section>


  <template slot="footer">

    <footer class="flex justify-between items-center border-t border-black-200 pt-6">

      <aside>

        <p class="text-xs">{{ forUploadVodsCountLabel }}</p>
        <!-- @Stef this will not be possible until minimized modal view is implemented for uploadModal. We should tell the user NOT to close the modal until then -->
        <!-- <p class="text-xs text-black-500 mt-1" v-if="isUploadInProgress">You can close this modal and upload will continue</p> -->

      </aside>

      <aside v-if="forUploadVods.length && !isUploadInProgress">

        <div v-if="isEditPanelOpened">

          <ioio-button
            @click.stop="toggleEditPanelOpened(false)"
            class="mr-4"
            size="small"
            type="secondary"
            variant="outline"
          >Cancel</ioio-button>

          <ioio-button
            type="primary"
            size="small"
            @click.stop="applyEditToBulkSelected"

          >Apply changes to {{ applyChangesToCount }}</ioio-button>
        </div>

        <div v-else>

          <ioio-button
            @click.stop="onCloseModal"
            class="mr-4"
            size="small"
            type="secondary"
            variant="outline"
          >Cancel</ioio-button>

          <ioio-button
            type="primary"
            size="small"
            @click.stop="onSubmitAll"
          >Upload {{ forUploadVods.length }}</ioio-button>
        </div>
      </aside>

      <ioio-button
        v-else
        @click.stop="onCloseModal"
        size="small"
        type="secondary"
        variant="outline"
      >Close</ioio-button>

    </footer>

  </template>

</ioio-modal>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import md5 from "md5";
import { CognitoIdentity } from "aws-sdk/clients/all";
import {languagesTranslateConfig} from "@utils/constants";

import { formatBytes, validateTabsFormAndHighlight } from "@utils/helpers";

export default {

  data() {

    const _this = this;

    return {

      isFormDirty: false,

      isUploadModalMinimized: false,

      languagesTranslateConfig,

      formatBytes,

      forUploadVods: [],
      vodUniqueIDReached: 1,

      dropzoneComponent: {},

      dropzoneOptions: {
        // The URL will be changed for each new file being processing
        timeout: null,
        maxFilesize: null,
        url: '/',
        // Since we're going to do a `PUT` upload to S3 directly
        method: 'put',
        addRemoveLinks: true,

        // Content-Type should be included, otherwise you'll get a signature
        // mismatch error from S3. We're going to update this for each file.
        // headers: {},
        // Here we request a signed upload URL when a file being accepted
        accept(file, done) {

          _this.isDraggedOver = false;
          _this.dragOverEventsCount = 0;

          const acceptedFiles = '.mpeg, .mov, .avi, .ts, .m2v, .mkv, .mpg, .mp4, .m4v, .mxf, .webm';

          var fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);

          if (!acceptedFiles.includes(fileExtension.toLowerCase())) {

            const errMsg = `The file ${_this.$options.filters.truncate(file.name, 64, '...')} is not a supported type.`;

            _this.$toasted.error(errMsg)

            /**
             * Calling done(...) with a parameter (errMsg) to tell Dropzone,
             * that the file type check has failed.
             */
            done(errMsg);

            return;
          }

          file.vodIndex = _this.forUploadVods.length;

          const trimmedName = file.name.substr(0, file.name.lastIndexOf('.'));

          _this.forUploadVods.push({
            file,
            done,
            data: {
              title: trimmedName,
              description: '',
              type: 'source',
              encode: !_this.defaultData['encode-later'],
              autoSubs: _this.defaultData['auto-subs'],
              autoTranslate: _this.defaultData['auto-translate'],
              medicalTranscribeType: _this.defaultData['medical-transcribe-type'],
              medicalTranscribe: _this.defaultData['medical-transcribe']
            },
            isDirty: false,
            status: 'staged',
            formState: {},
            isFormVisible: false,
            id: _this.vodUniqueIDReached
          });

          _this.vodUniqueIDReached++;

          _this.toggleAddVodToBulkList(_this.forUploadVods[_this.forUploadVods.length - 1]);

          _this.setVideosNotUploadedGuard();
        },

        sending(file, xhr) {
          /**
           * Hijack the native send request, since it is not
           * used and only throws an error if present
           */
          xhr.send = () => null;
        },
      },

      isSelectAllOptionApplied: true,
      bulkList: {},
      bulkListMap: {},
      singleEditedVod: null,

      isUploadInProgress: false,

      isEditPanelOpened: false,

      possibleMedicalTranscriptionTypes: [
        {
          label: 'Dictation',
          value: 'dictation'
        }, {
           label: 'Conversation',
          value: 'conversation'
        }
      ],
      defaultData: {
        encode: false,
        autoSubs: false,
        autoTranslate: false,
        medicalTranscribeType: 'dictation',
        medicalTranscribe: false,
      },

      editDataFields: {},
      selectedTabName: '',

      vodBatchAlreadyUploaded: false,

      isDraggedOver: false,
      dragOverEventsCount: 0,
    };
  },

  created() {

    if (this.BASE_BROKER_URL) {

      this.fetchUploadSettings();
    }
  },

  mounted() {
    window.add = this;

    // NOTE: nextTick needed for dropzone to render correctly
    this.$nextTick(() => {

      this.setupDropzoneComponent();
    });

    document.addEventListener('dragenter', this.onDragEnter, true);
    document.addEventListener('dragleave', this.onDragLeave, true);
  },

  beforeDestroy() {

    document.removeEventListener('dragenter', this.onDragEnter, true);
    document.removeEventListener('dragleave', this.onDragLeave, true);
  },

  methods: {

    fetchUploadSettings() {

      this.getMultipartUploadConfigs();
      this.getOrganizationsSettings();
    },

    setVideosNotUploadedGuard() {

      this.setRedirectGuard({
        redirectMsg: 'Your videos are not uploaded.',
        redirectSecondaryMsg: 'Are you sure you want to leave?'
      });
    },

    onLogicalEditChange(a) {

      this.setRedirectGuard({
        redirectMsg: 'Edited changes are not applied.',
        redirectSecondaryMsg: 'Are you sure you want to cancel?'
      });

      this.isFormDirty = true;
    },

    onDragLeave(e) {

      this.dragOverEventsCount--;

      if (this.dragOverEventsCount === 0) {

        this.isDraggedOver = false;
      }
    },

    onDragEnter(e) {

      this.dragOverEventsCount++;

      this.isDraggedOver = true;
    },

    onVodRemove(index) {

      const affectedVodId = this.forUploadVods[index].id;

      this.forUploadVods.splice(index, 1);

      const updatedBulkList = {
        ...this.bulkList
      };

      const updatedBulkListMap = {

        ...this.bulkListMap
      };

      delete updatedBulkList[affectedVodId];
      delete updatedBulkListMap[affectedVodId];

      this.bulkList = { ...updatedBulkList };
      this.bulkListMap = { ...updatedBulkListMap };

      if (this.bulkSelectedCount === 0) {

        this.toggleEditPanelOpened(false);
      }
    },

    applyEditToBulkSelected() {

      if (validateTabsFormAndHighlight(this.$refs.tabForm, 'uploadVod')) {

        return;

      } else {

        this.setRedirectGuard(false);
      }

      const editDataFields = this.editDataFields;

      // General tab fields
      const meta = {

        ...editDataFields
      };

      // Title and description should not be applied to more than 1 vod in bulk
      if (this.areMultipleVodsEdited) {

        delete meta.title;
        delete meta.description;
      }

      const vodList = this.singleEditedVod ?
        {
          [this.singleEditedVod.id]: this.singleEditedVod
        } :
        this.bulkList;

      for (let vodId in vodList) {

        const currVod = vodList[vodId];

        currVod.data = {

          ...currVod.data,
          ...meta
        }
      }

      this.toggleEditPanelOpened(false);

      // Reestablish the original videos not uploaded guard,
      // since it was overwritten by the editForm guard
      this.setVideosNotUploadedGuard();
    },

    onSubmitAll() {

      this.isUploadInProgress = true;

      this.$Amplify.Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;

        this.forUploadVods.forEach(stagedVod => {

          this.sendUploadRequest(stagedVod, token);
        });
      });
    },

    sendUploadRequest(stagedVod, token) {

      stagedVod.status = 'submitted';

      const file = stagedVod.file;

      const currentVodData = stagedVod.data;

      // General tab fields
      let meta = {
        originalFileName: file.name,
        title: currentVodData.title,
        type: currentVodData.type,
        user: this.getUserData(),
        origin: this.getOriginData(),
      };

      currentVodData.description && (meta.description = currentVodData.description);

      currentVodData.encode ? (meta.encodeLater = '0') : (meta.encodeLater = '1');

      currentVodData.privacy === 'true' ? (meta.privacy = true) : (meta.privacy = false);


      // Details tab fields
      const areCategoriesSelected =
        currentVodData.categories && currentVodData.categories.length;

      areCategoriesSelected && (meta.categories = currentVodData.categories);


      const areTagsSelected =
        currentVodData.tags && currentVodData.tags.length;

      areTagsSelected && (meta.tags = currentVodData.tags);


      currentVodData.group && (meta.group = currentVodData.group);

      currentVodData.genre && (meta.genre = currentVodData.genre);

      currentVodData.featured && (meta.featured = true);

      currentVodData.listedInPortals && (meta.listedInPortals = true);



      // Hubs tab fields

      const areHubsSelected =
        currentVodData.hubs && currentVodData.hubs.length;

      areHubsSelected && (meta.hubs = currentVodData.hubs);



      // Subtitles tab fields
      currentVodData.autoSubs ? (meta.autoSubs = '1') : (meta.autoSubs = '0');

      currentVodData.autoTranslate ? (meta.autoTranslate = '1') : (meta.autoTranslate = '0');

      if (currentVodData.medicalTranscribe) {

        meta.medicalTranscribe = '1';
        meta.medicalTranscribeType = currentVodData.medicalTranscribeType;

      } else {

        meta.medicalTranscribe = '0';
      }

      /**
       * There are problems, uploading NON ASCII strings to S3 in the meta.
       * The makeUploadFileMetaRequest makes it possible to first upload
       * the meta, regardless of the strings in it, then the main upload
       * only requires metaGuid
       */
      this.makeUploadFileMetaRequest({ meta }).then((data) => {

        const augmentedMeta = {

          metaGuid: data.guid
        };

        stagedVod.file.meta = augmentedMeta;

        const {
          bucket,
          bucketRegion,
          cognitoRegion,
          identityPoolId,
          logins
        } = this.uploadConfig;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials(
          {
            IdentityPoolId: identityPoolId,
            Logins: {
              [logins.cognito]: token
            }
          },
          {
            region: cognitoRegion
          }
        );

        if (this.uploadConfig.hasOwnProperty('hasAcceleration')) {

          AWS.config.useAccelerateEndpoint = this.uploadConfig.hasAcceleration;
        };

        /**
         * Get the file extension, generate an md5 hash of the file name.
         * Add a timestamp to insure an unique key will be generated.
         */
        var extension = file.name.substr(file.name.lastIndexOf('.') + 1);

        const key = `${md5(file.name + new Date().getTime())}.${extension}`;

        this.setPendingUploadAsset({

          key,
          assetData: meta,
          isPending: true
        });

        let params = {
          Bucket: bucket,
          Key: key,
          Body: file,
          Metadata: augmentedMeta,
          StorageClass: 'STANDARD_IA'
        };

        const fileSize = file.size;
        const partSizeMininum = 5 * 1024 * 1024; // (5mb)
        const maxPartChunks = 10000;

        const fileSizeDivided = fileSize / maxPartChunks;

        const partSizeOptimized = fileSizeDivided < partSizeMininum ?
          partSizeMininum : fileSizeDivided;

        const maxQueueSize = 20;
        const filesCount = this.forUploadVods.length;

        const queueSizeDivided = Math.floor(maxQueueSize / filesCount);

        const queueSizeOptimized = queueSizeDivided > 1 ? queueSizeDivided : 1;

        file.s3upload = new S3.ManagedUpload({
          params: params,

          partSize: partSizeOptimized,
          queueSize: queueSizeOptimized,
        });

        file.s3upload.on('httpUploadProgress', progress => {

          if (progress.total) {

            const percent = Math.round((progress.loaded * 100) / progress.total);

            const currentPercentage = this.forUploadVods[file.vodIndex].progressPercentage;

            if (currentPercentage > percent) {

              return;
            }

            this.dropzoneComponent.emit(
              'uploadprogress',
              file,
              percent,
              progress.loaded
            );

            const updatedFileWithProgress = {

              ...this.forUploadVods[file.vodIndex],
              progressPercentage: percent
            };

            this.forUploadVods.splice(file.vodIndex, 1, updatedFileWithProgress);
          }
        });

        stagedVod.status = 'loading';

        file.s3upload.send((err, data) => {

          if (err) {

            const currentVodIndex = stagedVod.file.vodIndex;

            this.forUploadVods.splice(currentVodIndex, 1, {
              ...stagedVod,
              status: 'error'
            });

            this.onFileUploadFinished(stagedVod, err);

          } else {

            let s3 = new AWS.S3({ apiVersion: '2006-03-01' });

            let params = {
              Bucket: bucket,
              ACL: 'bucket-owner-full-control',
              Key: key
            };

            s3.putObjectAcl(params, (err, data) => {

              if (err) {

                console.error('putObjectAcl err', err);

              } else {

                const currentVodIndex = stagedVod.file.vodIndex;

                this.forUploadVods.splice(currentVodIndex, 1, {
                  ...stagedVod,
                  file: {

                    uploadURL: data,
                    // copy name && vodIndex manually, since they are read only
                    name: stagedVod.file.name,
                    vodIndex: currentVodIndex
                  },
                  status: 'uploaded'
                });


                // stagedVod.done(); // NOTE: investigate if needed - throws an error here, but NOT in old addSource

                this.onFileUploadFinished(stagedVod);
              }
            });
          }
        });
      });
    },

    onFileUploadFinished(stagedVod, err) {

      let areAllFilesDoneUploading = true;
      let isErrorFoundUploading = false;

      for (var i = 0; i < this.forUploadVods.length; i++) {
        /**
        * Prevent the Add Source Modal from closing, since there
        * are still videos, that haven't uploaded yet
        */
        if (this.forUploadVods[i].status !== 'uploaded' &&
          this.forUploadVods[i].status !== 'error') {

          areAllFilesDoneUploading = false;
        }

        if (this.forUploadVods[i].status === 'error') {

          isErrorFoundUploading = true;
        }

        if (!areAllFilesDoneUploading && isErrorFoundUploading) {

          break;
        }
      }

      if (err) {

        this.$toasted.error(err);

      } else {

        this.$toasted.success(`${this.$options.filters.truncate(stagedVod.data.title, 64, '...')} was uploaded successfully.`);
      }

      if (areAllFilesDoneUploading) {

        this.cleanForUploadVodsState(isErrorFoundUploading);

        this.vodBatchAlreadyUploaded = true;
        this.isUploadInProgress = false;
      }
    },

    cleanForUploadVodsState(errorsFound) {

      if (errorsFound) {

        const filteredPrompts = this.forUploadVods.filter(p => p.status === 'error');

        this.forUploadVods = filteredPrompts;
        this.vodUniqueIDReached = this.forUploadVods.length + 1;

        return;
      }

      this.forUploadVods = [];
      this.vodUniqueIDReached = 1;
      this.isSelectAllOptionApplied = true;
      this.bulkList = {};
      this.bulkListMap = {};
      this.singleEditedVod = null;
      this.editDataFields = {};

      this.setRedirectGuard(false);
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;
    },

    setupEditData(vods, vodIndex) {

      // default to empty fields
      let vodData = {

        type: 'source',
        encode: !this.defaultData['encode-later'],
        autoSubs: this.defaultData['auto-subs'],
        autoTranslate: this.defaultData['auto-translate'],
        medicalTranscribeType: this.defaultData['medical-transcribe-type'],
        medicalTranscribe: this.defaultData['medical-transcribe']
      };

      // only one vod is edited by a click on a table row
      if (vodIndex !== undefined) {

        vodData = vods[vodIndex].data;

      } else if (this.bulkSelectedCount === 1 && vodIndex === undefined) {

        // only one vod is selected from bulk checkboxes

        const singleSelectedVodGuid = this.bulkListGuidArray[0];

        vodData = this.bulkList[singleSelectedVodGuid].data;

      } else {

        // Bulk edit is triggered
        // NOTE: a logic to combine common data can be applied here if needed...
      }

      this.editDataFields = {
        ...vodData,
        privacy: vodData.privacy === 'true' ? 'true' : 'false',
        tags: vodData.tags ? [...vodData.tags] : [],
        categories: vodData.categories ? [...vodData.categories] : [],
        hubs: vodData.hubs ? [...vodData.hubs] : []
      };
    },

    toggleEditPanelOpened(newVal, vodIndex) {

      const newState = newVal !== undefined ? newVal : !this.isEditPanelOpened;

      // raise guard logic for edit changes not applied
      if (this.isEditPanelOpened && this.isActiveConfirmGuardSet && this.isFormDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.isEditPanelOpened = newState;

            // Reestablish the original videos not uploaded guard,
            // since it was overwritten by the editForm guard
            this.setVideosNotUploadedGuard();
          }
        });

        this.raiseRedirectFlag(true);

        return;
      }

      this.isEditPanelOpened = newState;

      // The check above has passed, so the form state should be nulled
      this.isFormDirty = false;

      if (newState) {

        this.isEditPanelOpened = newState;
        this.setupEditData(this.forUploadVods, vodIndex);

        if (vodIndex !== undefined) {

          this.singleEditedVod = this.forUploadVods[vodIndex];

        } else {

          this.singleEditedVod = null;
        }
      }
    },

    triggerDropzoneClick() {

      this.$refs.dropzoneComponent.$el.click();
    },

    selectAllVods() {

      const updatedBulkList = {};
      const updatedBulkListMap = {};

      this.forUploadVods.forEach(vod => {

        this.isSelectAllOptionApplied && (updatedBulkList[vod.id] = vod);

        updatedBulkListMap[vod.id] = this.isSelectAllOptionApplied;
      });

      this.bulkList = {

        ...updatedBulkList
      };

      this.bulkListMap = {

        ...updatedBulkListMap
      };

      if (!this.isSelectAllOptionApplied) {

        this.toggleEditPanelOpened(false);
      }
    },

    toggleAddVodToBulkList(vod) {

      const updatedBulkList = { ...this.bulkList };

      const updatedBulkListMap = { ...this.bulkListMap };

      if (!this.bulkList[vod.id]) {

        updatedBulkList[vod.id] = vod;

        updatedBulkListMap[vod.id] = true;

      } else {

        delete updatedBulkList[vod.id];

        updatedBulkListMap[vod.id] = false;
      }

      this.bulkList = {

        ...updatedBulkList
      };

      this.bulkListMap = {

        ...updatedBulkListMap
      };

      if (this.bulkSelectedCount === 0) {

        this.toggleEditPanelOpened(false);
      }

      this.evaluateIsSelectAllApplied();
    },

    evaluateIsSelectAllApplied() {

      if (this.forUploadVods.length === this.bulkSelectedCount) {

        this.isSelectAllOptionApplied = true;

      } else {

        this.isSelectAllOptionApplied = false;
      }
    },

    getOrganizationsSettings() {

      this.getBrokerSettings('').then((defaultData) => {

        if (!defaultData) {

          return;
        }

        this.defaultData = defaultData.reduce((acc, item) => {

          acc[item.guid] = item.value;
          return acc;
        },{} );
        if (!this.defaultData['auto-subs']) {
          this.defaultData['auto-translate'] = false;
        };
      });
    },

    setupDropzoneComponent() {

      this.dropzoneComponent =
        this.$refs.dropzoneComponent && this.$refs.dropzoneComponent.dropzone;
    },

    browse() {

      this.dropzoneComponent.hiddenFileInput.click();
    },

    onCloseModal() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {
            this.closeModal();
          }
        });

        this.raiseRedirectFlag(true);

        return;
      }

      this.isFormDirty = false;

      this.closeModal();
    },

    closeModal() {

      this.forUploadVods = [];
      this.toggleUploadModalOpened(false);

      this.cleanForUploadVodsState();
    },

    getUserData() {

      return this.userData;
    },

    getOriginData() {

      return {

        type: this.appName,
        appVersion: this.appVersion,
        userAgent: window.navigator.userAgent, // any versioning of the origination
        source: 'upload', //recording, import, upload
      };
    },

    ...mapMutations({
      setRedirectGuard: 'app/SET_REDIRECT_GUARD',
      setPendingUploadAsset: 'app/SET_PENDING_UP_ASSET_KEY',
      toggleUploadModalOpened: 'app/TOGGLE_UPLOAD_MODAL_OPENED',
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),

    ...mapActions({

      getMultipartUploadConfigs: 'app/getMultipartUploadConfigs',
      makeUploadFileMetaRequest: 'channelManager/makeUploadFileMetaRequest',
      getBrokerSettings: 'channelManager/getBrokerSettings',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({

      uploadConfig: 'app/uploadConfig',
      isUploadModalOpened: 'app/isUploadModalOpened',
      possibleVodTypes: 'channelManager/possibleVodTypesArr',
      accessPrivacyOptions: 'channelManager/accessPrivacyOptions',
      BASE_BROKER_URL: 'app/BASE_BROKER_URL',

      appName: 'app/appName',
      appVersion: 'app/appVersion',
      userData: 'app/userData',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet'
    }),

    isModalOpened: {

      get() {

        return this.isUploadModalOpened;
      },

      set(newVal) {

        if (newVal) {

          this.toggleUploadModalOpened(newVal);

        } else {

          this.onCloseModal();
        }
      }
    },

    beforeUploadVodsCountLabel() {

      const count = this.forUploadVods.length;

      return count ?
        (count > 1 ? `${count} videos` : `${count} video`) : '';
    },

    uploadingVodsCountLabel() {

      const count = this.forUploadVods.length;

      return `${count} uploading`;
    },

    forUploadVodsCountLabel() {

      return this.isUploadInProgress ?
        this.uploadingVodsCountLabel : this.beforeUploadVodsCountLabel;
    },

    bulkListGuidArray() {

      return Object.keys(this.bulkList);
    },

    bulkSelectedCount() {

      return this.bulkListGuidArray.length;
    },

    areMultipleVodsEdited() {

      return this.bulkSelectedCount > 1 && !this.singleEditedVod;
    },

    openEditPanelBtnText() {

      return this.bulkSelectedCount ?
        `Edit ${this.bulkSelectedCount} selected` :
        `${this.bulkSelectedCount} selected`;
    },

    editPanelHeaderText() {

      return this.areMultipleVodsEdited ?
        `Edit ${this.bulkSelectedCount} selected` :
        `Edit ${this.forUploadVods[0] && this.forUploadVods[0].data.title}`;
    },

    applyChangesToCount() {

      return this.areMultipleVodsEdited ? this.bulkSelectedCount : 1;
    },

    modalClass() {

      return `upload-vod-modal-component ${ this.isUploadModalMinimized ? 'minimized' : '' }`;
    },
  },

  watch: {
    'editDataFields.autoSubs'() {

      if (!this.editDataFields.autoSubs) {

        this.editDataFields.autoTranslate = false;
      }
    },

    BASE_BROKER_URL() {

      this.fetchUploadSettings();
    }
  }

}
</script>

<style lang="scss">

/* @Stefnew
Use `minimized` class for the  minimize-when-uploading feature */
.upload-vod-modal-component {

  .ds-modal__dialog {

    height: 90%;
    @apply
    py-6
    overflow-x-hidden
    relative;
  }

  /* @Stefup above comment */
  &.minimized {

    position: fixed;
    right: 20px;
    width: 100px;
    background: red;
  }

  #customdropzone {

    /* height: 570px; */
    border: 1.5px dashed;
    @apply
    rounded-xl
    border-black-200
    flex
    justify-center
    items-center;

    /* border-radius: 8px;
    border: 1px dashed var(--sceneBrColor);
    color: var(--sceneColor);
    transition: background-color 0.2s linear; */
    /* width: 100%; */
    /* padding: 1rem; */
  }
  #customdropzone:hover {
    background-color: var(--color-blue-50);
    border: 2px dashed var(--windowBrColor);
  }

  .staged-files-present {

    /* The dropzone will become visible when item is dragged over the screen ".is-dragged-over" */
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .is-dragged-over {

    .dropzone-container {

      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000ad;
      color: white;

      #customdropzone {

        border: none;
        background-color: initial;
      }
    }
  }

  #customdropzone .dz-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  #customdropzone {

    .dz-preview.dz-file-preview,
    .dz-preview.dz-image-preview,
    .dz-error.dz-preview {

      display: none;
    }
  }

  .ds-modal__footer {

    margin: 0 -36px;

    footer {

      @apply
      px-8;
    }
  }

  .vod-table {

    @apply
    border
    border-black-100
    px-0
    rounded;

    tbody tr:last-of-type {

      border-bottom: none;
    }

    thead {

      th:after {
        content: '';

        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        @apply
        border-b
        border-black-100;
      }
    }

    th {

      @apply
      py-2;
    }

    .vod-item {

      .vod-actions {

        position: absolute;
        right: 0;
        z-index: 1;
        opacity: 0;
      }

      &:hover {

        @apply
        bg-black-50;

        .vod-actions {

          opacity: 1;
        }
      }
    }

    td {

      @apply
      pt-4
      pb-4;
    }


    th {

      @apply
      text-xs
      text-black-400
      pt-2
      pb-2;
    }

    td {
      @apply
      text-black-700;
    }

    .vod-data:not(.vod-title) {
      @apply
      text-black-500
      text-xs;
    }

    td, .vod-data {

      @apply
      text-sm;

      .cell-content {

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;


        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }


    .title-col {
      width: 270px;
      max-width: 270px;
      min-width: 270px;
    }

    .general-col {
      width: 110px;
      min-width: 110px;
      max-width: 110px;
    }

    .details-col {

      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }

    .hubs-col {
      width: 150px;
      min-width: 150px;
      max-width: 150px;
    }

    .transcription-col {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }


    .uploading-title-col {
      width: 50%;
      min-width: 230px;
      max-width: 230px;
    }

    .progress-col {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }

    .progress-description-col {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
    }

  }

  .main-modal-content {

    &.is-opened {

      .table-cont {

        width: calc(100% - 420px);
      }

      .edit-meta-panel {

        transform: translate3d(0, 0, 0);
      }
    }

    .table-cont {

      width: 100%;
      transition: width 0.3s ease-out;
    }
  }

  .edit-meta-panel {

    position: absolute;
    top: 0;
    right: -24px;
    bottom: 0;
    z-index: 1;
    width: 420px;
    transform: translate3d(420px, 0, 0);
    transition: transform 0.3s ease-out;
    background-color: white;

    @apply
    border-l
    border-black-100
    pl-6;
  }

  .ds-modal__header {

    height: 0;
  }

  .ds-modal__footer {

    padding-top: 0;
  }

  .upload-details-tabs {

    .ds-tabs__nav {

      border-bottom-width: 0;
      padding-left: 0;

      @apply
      mb-5;
    }
  }
}
</style>
