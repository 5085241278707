<template>
<div class="assign-images-list-to-vod-component">
  <image-cropping-modal-component
    ref="imageCropModal"
    :isModalOpened="isModalOpened"
    :toggleCropModalOpened="toggleModalOpened"
    :getSearchOptions="getSearchOptions"
    :editedVod="editedVod"
    :stagedImages="stagedImages"
    :cancelAllChanges="cancelAllChanges"
    :removeStagedImg="removeStagedImg"
    :appyAllChanges="appyAllChanges"
    @changeStageImg="applyChangesToStagedImage"
  />

  <header class="flex justify-between items-center mb-4">
    <label class="ds-fields__label pb-0">Cropped Images</label>
    <ioio-button
      type="secondary"
      variant="outline"
      size="small"
      iconLeft="fa-solid fa-plus"
      @click="toggleModalOpened(true)"
    >Add images
    </ioio-button>
  </header>

  <assigned-images-to-vod-component
    :stagedImages="stagedImages"
    :openEditForStagedImg="openEditForStagedImg"
    :removeStagedImg="removeStagedImg"
  />
</div>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getSearchOptions } from './elasticSearchOptions';

export default {

  data: () => ({
    getSearchOptions: getSearchOptions,

    isModalOpened: false,

    stagedImages: [],
    cachedCroppedImagesList: [],

    hasImgDataBeenChanged: false,

  }),
  props: {
    editedVod: Object
  },

  mounted() {

    window.con = this;

    this.loadStagedImages();
  },

  methods: {

    toggleModalOpened(value) {

      this.isModalOpened = value;

      if (this.isModalOpened) {

        // Load again since they may have changed form prev iteraction with the modal
        this.loadStagedImages();

      }
    },


    applyChangesToStagedImage(imgDto, updateIndex) {

      if (updateIndex !== null) {

        this.stagedImages.splice(updateIndex, 1, imgDto);

      } else {

        this.stagedImages.push(imgDto);
      }

      this.hasImgDataBeenChanged = true;

    },

    openEditForStagedImg(index) {

      if (!this.isModalOpened) {

        this.toggleModalOpened(true);
      }

      const img = this.stagedImages[index];

      this.$refs.imageCropModal.openEditForStagedImg(index, img);

    },

    removeStagedImg(index) {

      this.hasImgDataBeenChanged = true;

      this.stagedImages.splice(index, 1);

      this.editedVod.meta.croppedImages = [...this.stagedImages];

      this.$emit('change');
    },

    loadStagedImages() {

      let newStagedImagesData = [];

      if (this.editedVod.meta && this.editedVod.meta.croppedImages) {

        newStagedImagesData = [...this.editedVod.meta.croppedImages];
      }

      this.stagedImages = newStagedImagesData;

      this.cachedCroppedImagesList = [...this.stagedImages];
    },

    cancelAllChanges() {

      this.stagedImages = [...this.cachedCroppedImagesList];

      this.toggleModalOpened(false);
    },

    appyAllChanges() {

      this.editedVod.meta.croppedImages = [...this.stagedImages];

      this.$emit('change');

      this.toggleModalOpened(false);
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // getImagesElastic: "images/getImagesElastic",
    })
  },

  computed: {

    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">

.assign-images-list-to-vod-component, .configure-cropped-images-for-vod-modal, .library-side-view-single-vod-component {

  .thumb-image {

    min-width: 150px;
    max-width: 150px;
    height: 85px;

    @apply
    mr-2
    border
    border-black-200
    rounded;
  }
}

.assign-images-list-to-vod-component {

  .assigned-images-to-vod-component {


  }
}

.configure-cropped-images-for-vod-modal {

  .ds-modal__dialog {

    height: 830px;
    max-width: 1216px !important;
  }

  .ds-modal__dialog, .ds-modal__body {

    padding: 0 !important;
  }

  .ds-modal__header, .ds-modal__footer {

    display: none;
  }

  .ds-modal__dialog {

    overflow: hidden;
  }

  .ds-modal__body {

    @apply
    row-start-1
    row-end-3;
  }

  .input-search {

    width: 512px;
  }

  .images-list-section {

    max-width: 794px;
    width: 794px;
  }

  .crop-details-section {

    min-width: 422px;
    width: 422px;
  }

  .img-item {

    max-width: 160px;
    height: 90px;

    .img-meta {

      display: none;
      background: #ffffffeb;
    }

    &:hover {

      .img-meta {

        display: block;
      }
    }
  }


  /* If the image is transparent, show bg as white */
  .vue-preview-result__image {

    background: white;
  }

  .vue-advanced-cropper__background {

    background: #00000082;
  }

  .cropper-holder {

    min-height: 184px;
    height: 184px;
    position: relative;
  }

  .cropper {

    height: 100%;
  }


  .vue-advanced-cropper {

    @apply
    rounded
    overflow-hidden;
  }

  .dimensions-box {

    position: absolute;
    top: 8px;
    left: 8px;
    background: #000000cc;
  }

  .ds-fields__textarea.is-small {

    min-height: auto;
    height: 50px;
  }

  .assigned-images-to-vod-component {

    .cropped-item {

      &:hover {

        .img-ctrls {

          display: flex;

          input {

            display: none;
          }
        }
      }
    }

    .img-ctrls {

      display: none;
      position: absolute;
      top: 16px;
      left: 8px;
    }
  }
}
</style>
