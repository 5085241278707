/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'preview': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.847 11.468C23.655 11.163 19.071 4 12 4S.345 11.163.153 11.468a1 1 0 000 1.064C.345 12.837 4.929 20 12 20s11.655-7.163 11.847-7.468a1 1 0 000-1.064zM12 16a4 4 0 114-4 4 4 0 01-4 4z" _fill="#111"/>'
  }
})
