/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.625 8.219l-10-8a1 1 0 00-1.25 0l-10 8A1 1 0 001 9v13a1 1 0 001 1h7v-8h6v8h7a1 1 0 001-1V9a1 1 0 00-.375-.781z" _fill="#5c7499"/>'
  }
})
