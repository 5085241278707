<template>

<div class="image-logo-settings-component p-4 pb-0">
  <image-cropping-modal-component
    ref="imageCropModal"
    :isModalOpened="isModalOpened"
    :toggleCropModalOpened="toggleModalOpened"
    :getSearchOptions="getSearchOptions"
    :cancelAllChanges="cancelAllChanges"
    @changeStageImg="applyChangesToStagedImage"
    :isLogo="true"
  />

  <header class="flex justify-between border-bottom pl-4 pb-4 mb-2">

    <h4 class="mt-2">Logo</h4>

    <ioio-button
      type="secondary"
      variant="outline"
      size="small"
      @click="() => toggleModalOpened(true)"
    >
      Add New Logo
    </ioio-button>

  </header>

  <section v-if="!isLogoDataUpdating" class="mt-4">

    <div
      v-if="organizationSelected && organizationSelected.config && organizationSelected.config.logo"

    >
      <div class="flex justify-between items-center">
        <div class="items-center image-item overflow-hidden mr-4 rounded">
          <img :src="organizationSelected.config.logo" width="80" height="45">
        </div>
        <h6></h6>
        <ioio-button
          size="small"
          @click="preview=!preview">
          Preview
        </ioio-button>
      </div>
      <div v-if="preview" class="flex items-center justify-center rounded mt-6">
        <img :src="organizationSelected.config.logo" width="400" height="225" class="rounded">
      </div>

    </div>

    <h3 v-else class="text-sm">No logo has been saved.</h3>

  </section>

  <div v-else class="relative min-h-36">
    <ioio-loader isShown />
  </div>

</div>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getSearchOptions } from './elasticSearchOptions';

export default {
  data: () => ({
    getSearchOptions: getSearchOptions,
    isLogoDataUpdating: false,
    isModalOpened: false,
    preview: false
  }),
  props: {},
  methods: {

    toggleModalOpened(value) {

      this.isModalOpened = value;
    },

    cancelAllChanges() {

      this.toggleModalOpened(false);
    },

    applyChangesToStagedImage(imgDto) {

      this.saveOrgDetails(imgDto.url);
    },

    saveOrgDetails(url) {

      this.isLogoDataUpdating = true;
      const data = {
        name: this.organizationSelected.name,
        searchDeploymentGuid: this.organizationSelected.searchDeploymentGuid,
        status: this.organizationSelected.status,

        ssoSettings: {

          idProvider: this.organizationSelected.ssoSettings.idProvider,
          defaultRoleGuid: this.organizationSelected.ssoSettings.defaultRoleGuid
        },

        siteBuilderGuid: this.organizationSelected.siteBuilderGuid,
        customDomain: this.organizationSelected.customDomain,

        regions: this.organizationSelected.regions,
        config: {
          logo: url
        }
      };
      this.toggleModalOpened(false);

      this.updateOrganization({
        guid: this.organizationSelected.guid,
        data
      }).then(() => {

        this.setOrganizationSelected({

          ...this.organizationSelected,
          config: {
            logo: url
          }
        });
        this.$toasted.success('Your changes were saved successfully');
      })
      .finally(() => {

        this.isLogoDataUpdating = false;
      });
    },

    ...mapMutations({
      setOrganizationSelected: "app/SET_ORGANIZATION_SELECTED",
    }),
    ...mapActions({
      updateOrganization: 'app/updateOrganization',
    })
  },
  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected",
    })
  },
}
</script>

<style lang="scss">
  .image-logo-settings-component {
    .image-item {
      min-width: 80px;
      height: 45px;
    }
  }

</style>
