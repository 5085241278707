<template>

<ioio-modal
  externalClasses="video-insights-modal-component"
  :show.sync="isInsightsModalOpened"
  size="large"
  noScroll
  noClose
>
  <section class="flex h-full">

    <section class="general-section flex flex-auto flex-column" v-if="!isChartLoading">
      <header class="flex justify-between items-center mb-6 mt-12 ml-12">
        <h1 class="text-2xl">Insights</h1>
        <header class="flex items-center mr-12">

          <ioio-dropdown-base
            class="mr-4"
            alignX="right"
            :overflow="true"
            size="small"
          >

            <template slot="title" classes="text-black-900" >

              <ioio-button
                type="secondary"
                variant="outline"
                class=""
                size="small"
              >
                {{ selectedPeriod }}
                <ioio-icon icon="fal-chevron-down" class="ml-2" />
              </ioio-button>
            </template>

            <div
              v-for="option in activityDropdownItems"
              :key="option.label"
              class="dropdown__menu-item">

              <ioio-button
                type="minimal"
                size="medium"
                @click="onActivityPeriodChange(option)">
                {{ option.label }}
              </ioio-button>
            </div>

            <div @click.stop="">
              <ioio-field
                :minimum="minDate"
                :maximum="maxDate"
                v-model="dateRange"
                placeholder='Choose dates'
                size="medium"
                type="daterange"
                :hasClearBtn="false"
                @input="onActivityPeriodChange">
              </ioio-field>
            </div>

          </ioio-dropdown-base>

          <ioio-dropdown
            :items="devicesDropdownItems"
            titleProp="label"
            valueProp="device"
            v-model="selectedDeviceOption"

            size="small"
            class="w-36"
            fullWidth
          />
          <ioio-button
            size="small"
            class="ml-7"
            @click="getVodStats">
              Apply
          </ioio-button>
        </header>
      </header>

      <ioio-tabs
        class="insights-section flex flex-auto flex-column pl-4 pr-12"
        size="medium"
        @input="onTabChange"
      >
        <ioio-tab
          name="Engagement"
          class="h-full ml-4 mt-3 pt-2"
          :selected="selectedTab === 'Engagement'"
        >
          <ioio-tabs
            class="secondary-section flex flex-auto flex-column pr-12"
            size="medium"
            @input="onEngagementTabChange"
          >
            <ioio-tab
              name="Views"
              class="h-full mt-3 pt-2"
              :selected="selectedEngagementTab === 'Views'"
            >
              <section class="chart-section mb-2">
                <header class="flex justify-between px-4">

                  <aside class="flex items-center" v-if="viewsChartSeries.length && !isChartLoading">

                    <h3 class="m0 mr-4 text-2xl text-black-900 font-normal"
                      >{{ viewsAdditionalData.value }}
                    </h3>

                    <h6 v-if="viewsAdditionalData.up" class="m0 text-green-500 text-xs font-normal">
                      <ioio-icon icon="fa-arrow-up" />
                      +<span>{{ viewsAdditionalData.percent }}%</span>
                    </h6>
                    <h6 v-else class="m0 text-red-500 text-xs font-normal">
                      <ioio-icon icon="fa-arrow-down" />
                      +<span>{{ viewsAdditionalData.percent }}%</span>
                    </h6>
                  </aside>
                </header>

                <apexchart
                  width="100%"
                  height="380"
                  :options="viewsChartOptions"
                  :series="viewsChartSeries"
                  v-if="viewsChartSeries.length && !isChartLoading"
                />

                <p
                  class="py-12 px-4"
                  v-else-if="!viewsChartSeries.length && !isChartLoading"
                >No information found.
                </p>

                <div
                  v-else
                  style="overflow:hidden"
                  class="flex items-center flex-auto"
                >
                  <logo-loader-component width="100px" height="100px" />
                </div>
              </section>
            </ioio-tab>
            <ioio-tab
              name="Video engagement"
              class="h-full  ml-4 mt-3 pt-2"
              :selected="selectedEngagementTab === 'Video engagement'"
            >
              <section class="chart-section">

                <apexchart width="100%" height="380" :options="videoEngagementChartOptions" :series="videoEngagementChartSeries" v-if="videoEngagementChartSeries.length && !isChartLoading"></apexchart>

                <p class="py-12 px-4" v-else-if="!videoEngagementChartSeries.length && !isChartLoading">No information found.</p>


                <div v-else style="overflow:hidden" class="flex items-center flex-auto">
                  <logo-loader-component width="100px" height="100px" />
                </div>
              </section>
            </ioio-tab>
          </ioio-tabs>
        </ioio-tab>

        <ioio-tab
          name="Location"
          class="h-full ml-4 mt-3 pt-2"
          :selected="selectedTab === 'Location'"
        >
          <ioio-tabs
            class="secondary-section flex flex-auto flex-column pr-12"
            size="medium"
            @input="onLocationTabChange"
          >
            <ioio-tab
              name="Table"
              class="h-full mt-3 pt-2"
              :selected="selectedLocationTab === 'Table'"
            >

              <location-stats-widget-component
                v-if="!isChartLoading"
                :locationData="locationData"
                shouldDisplayVerticle
                show="table"
              />
            </ioio-tab>
            <ioio-tab
              name="Chart"
              class="h-full mt-3 pt-2"
              :selected="selectedLocationTab === 'Chart'"
            >

              <location-stats-widget-component
                v-if="!isChartLoading"
                :locationData="locationData"
                shouldDisplayVerticle
                show="chart"
              />
            </ioio-tab>
          </ioio-tabs>
        </ioio-tab>
        <ioio-tab
          name="Devices"
          class="h-full"
          :selected="selectedTab === 'Devices'"
        >

          <devices-stats-widget-component
            :deviceTableStats="deviceTableStats"
            :deviceChartOptions="deviceChartOptions"
            :deviceChartSeries="deviceChartSeries"
          />
        </ioio-tab>
      </ioio-tabs>

    </section>

    <aside
      class="general-stats-section h-full"
      v-if="!isChartLoading"
    >
      <button
        class="ds-modal__close mt-4 right-6"
        type="button"
        @click="toggleInsightsModalOpened(false)"
        data-dismiss="modal"
      >
        <ioio-icon icon="fal-times" />
      </button>

      <h1 class="text-2xl mb-9">Total stats</h1>

      <insights-general-stats-component
        class="flex-column"
        :allTimeViews="allTimeViews"
        :avgWatchTime="avgWatchTime"
        :avgCompletion="avgCompletion"
        :totalWatchTime="totalWatchTime"
      />
    </aside>

    <ioio-loader :isShown="isChartLoading" />
  </section>
</ioio-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";


import moment from "moment-timezone";

import {
  viewsChartOptions,
  videoEngagementChartOptions,
  deviceChartOptions
} from './charts.config.js'

export default {
  data: () => ({

    activityDropdownItems: [
      { "t_r": '7d', "label": "Last 7 days" },
      { "t_r": '15d', "label": "Last 15 days" },
      { "t_r": '30d', "label": "Last 30 days" },
    ],
    activityPeriod: '7d',
    selectedPeriod: 'Last 7 days',
    devicesDropdownItems: [
      { "device": '', "label": "All devices" },
      { "device": 'PC', "label": "PC" },
      { "device": 'Mobile', "label": "Mobile" },
      { "device": 'Tablet', "label": "Tablet" },
    ],
    selectedDeviceOption: '',

    viewsChartOptions: viewsChartOptions,
    viewsChartSeries: [],

    viewsAdditionalData: {},

    videoEngagementChartOptions: videoEngagementChartOptions,

    videoEngagementChartSeries: [],

    videoEngagementAdditionalData: {},

    deviceTableStats: [],
    deviceChartOptions: deviceChartOptions,

    deviceChartSeries: [],

    locationData: [],

    isChartLoading: true,

    channelsStats: [],

    allTimeViews: 0,
    avgWatchTime: 0,
    avgCompletion: 0,
    totalWatchTime: 0,
    statsVodTitle: '',

    hls: null,
    moment: {},
    dateRange:[],

    selectedTab: 'Engagement',
    selectedEngagementTab: 'Views',
    selectedLocationTab: 'Table'
  }),

  created() {

    this.moment = moment;
  },

  mounted() {

    window.v = this;
  },

  props: {

    visibleVodDetails: {
      type: Object|Boolean
    },
    toggleInsightsModalOpened: Function,
    isModalOpened: Boolean
  },

  methods: {

    onTabChange(newTabName) {

      this.selectedTab = newTabName;
    },

    onEngagementTabChange(newTabName) {

      this.selectedEngagementTab = newTabName;
    },

    onLocationTabChange(newTabName) {

    this.selectedLocationTab = newTabName;
    },

    onActivityPeriodChange(value) {

      if (Array.isArray(value)) {

        if (value.length < 2) {

          return;
        } else {

          this.dateRange = value;

          this.activityPeriod =
            `${this.moment(value[0])
              .format('YYYY-MM-DD')}_${this.moment(value[1]).format('YYYY-MM-DD')}`;

          this.selectedPeriod =
            `${this.moment(value[0])
              .format('DD/MM/YYYY')} to ${this.moment(value[1]).format('DD/MM/YYYY')}`;

          this.$refs.dropdown.click();
        };

      } else {

        this.activityPeriod = value.t_r;
        this.selectedPeriod = value.label;
        this.dateRange = [];
      }


    },

    getVodStats() {

      if (!this.visibleVodDetails.guid) {

        return;
      }

      const statsParams = {

        org_id: this.organizationSelected.guid,
        v_guid: this.visibleVodDetails.guid,
        t_r: this.activityPeriod
      };

      this.selectedDeviceOption && (statsParams.device = this.selectedDeviceOption);

      this.isChartLoading = true;

      this.getVodByIdStats(statsParams)
        .then(stats => {

          // No records found -> null the chartsData
          if (stats.views_chart.data_available === false) {

            this.viewsChartSeries = [];

          } else {

            // Update viewsChart
            this.viewsChartOptions = {
              ...this.viewsChartOptions,
              xaxis: {
                ...this.viewsChartOptions.xaxis,
                categories: [
                  ...stats.views_chart.DTO.categories
                ]
              },
              yaxis: {
                ...this.viewsChartOptions.yaxis,
                ...stats.views_chart.DTO.yaxis
              }
            }

            this.viewsChartSeries = [
              ...stats.views_chart.DTO.series
            ];

            this.viewsAdditionalData = {

              ...stats.views_chart.total_views
            };
          }
          // END Update viewsChart

          // No records found -> null the chartsData
          if (stats.video_engagement.data_available === false) {

            this.videoEngagementChartSeries = [];

          } else {

            // Update videoEngagementChartOptions
            this.videoEngagementChartOptions = {
              ...this.videoEngagementChartOptions,
              xaxis: {
                ...this.videoEngagementChartOptions.xaxis,
                categories: [
                  ...stats.video_engagement.DTO.categories
                ]
              },
              yaxis: {
                ...this.videoEngagementChartOptions.yaxis,
                ...stats.video_engagement.DTO.yaxis
              }
            }

            // Calculate the max integer of the y-axis in Video engagement chart, based on the max from stats response

            let max = Math.max(...stats.video_engagement.DTO.series[0].data);

            let divisor = Number('1'.padEnd(max.toString().length,'0'));

            this.videoEngagementChartOptions.yaxis.max = Math.ceil(max/divisor)*divisor;

            if (max < 100 ) {

              this.videoEngagementChartOptions.yaxis.tickAmount = 10;
            }
            if (max < 10) {

              this.videoEngagementChartOptions.yaxis.max = 10;
            }
            // End of calculating
            this.videoEngagementChartSeries = [
              ...stats.video_engagement.DTO.series
            ];
          }
          // END Update videoEngagementChartOptions

          // No records found -> null the chartsData
          if (stats.device_usage.data_available === false) {

            this.deviceChartSeries = [];

          } else {

            // Update device Stats
            this.deviceTableStats = [...stats.device_usage.data];

            this.deviceChartOptions = {
              ...this.deviceChartOptions,
              xaxis: {
                ...this.deviceChartOptions.xaxis,
                categories: [
                  ...stats.device_usage.DTO.categories
                ]
              },
              yaxis: {
                ...this.deviceChartOptions.yaxis,
                ...stats.device_usage.DTO.yaxis
              }
            }

            this.deviceChartSeries = [
              ...stats.device_usage.DTO.series
            ];
          }
          // END Update device Stats


          // Update location Stats
          this.locationData = [
            ...stats.locations
          ];
          // END Update location Stats


          this.allTimeViews = stats.all_time_data.total_views;
          this.avgWatchTime = stats.all_time_data.avg_watch_time;
          this.avgCompletion = stats.all_time_data.avg_comp;
          this.totalWatchTime = stats.all_time_data.total_watch_time;
          this.statsVodTitle = stats.vod_title || '';
        })
        .finally(() => {

          this.isChartLoading = false;
        });
    },

    ...mapActions({
      getVodByIdStats: "statistics/getVodByIdStats",
    })
  },
  computed: {
    ...mapGetters({

      organizationSelected: "app/organizationSelected"
    }),

    isInsightsModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(newVal) {

        this.toggleInsightsModalOpened(!!newVal);
      }
    },

    videoMeta() {

      return this.visibleVodDetails.meta || {};
    },

    maxDate() {

      return this.moment().subtract(1, 'days').format('YYYY-MM-DD');
    },

    minDate() {

      return this.moment(this.visibleVodDetails.createdAt).format('YYYY-MM-DD');
    }
  },

  watch: {

    visibleVodDetails() {

      if (!this.visibleVodDetails.guid) {

        return;
      }

      this.activityPeriod = '7d';
      this.selectedPeriod = 'Last 7 days';
      this.dateRange = [];

      this.getVodStats();
    },

    isInsightsModalOpened() {

      // Reset to open the first tab
      if (this.isInsightsModalOpened) {

        this.selectedTab = 'Engagement';
      }
    }
  }
}
</script>

<style lang="scss" >

.video-insights-modal-component {

  .ds-modal__dialog, .ds-modal__body {

    padding: 0 !important;
  }

  .ds-modal__header, .ds-modal__footer {

    display: none;
  }

  .ds-modal__dialog {

    overflow: hidden;
  }

  .ds-modal__body {

    @apply
    row-start-1
    row-end-3;
  }

  .general-section {
    height: 45rem;
    overflow-y: scroll;
  }

  .general-stats-section {

    min-width: 232px;
    width: 232px;

    @apply
    bg-black-50
    py-12
    pr-6
    pl-8;

    .insights-general-stats-component>div {

      padding: 0;
      margin: 0;
      border: none;

      @apply
      mb-9;
    }
  }

  .devices-stats-widget-component {

    @apply
    pt-8;

    .table-section {

      @apply
      flex-auto
      pr-12
      mr-6
      mt-2
      pt-0;
    }

    .chart-section {

      width: 290px;
      min-width: 290px;

      @apply
      flex-none
      pr-0
      pt-0;
    }
  }

  .insights-section {

    @apply
    mb-12;

    .ds-tabs__nav {

      height: 46px;
      padding-left: 0;
      overflow: unset;
      @apply
      ml-8;
    }

    .section-title {

      @apply
      text-base
      text-black-700
      font-medium;
    }
  }

  .secondary-section {
    .ds-tabs__nav {

      height: 46px;
      padding-left: 0;
      overflow: unset;
      @apply
      ml-4;
    }
  }
}
</style>
