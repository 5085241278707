<template>
<div class="modal-vod-action-btn-component">
  <div
    @click.stop="openModal"
    v-if="vod.status !== 'error'">

    <!-- The slot will allow for a custom open btn to be added to the component -->
    <slot />
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {

    vod: Object,
    openFlagName: String
  },
  created() {
    window.d = this;
  },
  methods: {

    openModal() {

      this.toggleVodActionModalOpened({
        flagName: this.openFlagName,
        newVal: true
      });

      this.setVodForModalAction(this.vod);
    },

    ...mapMutations({
      toggleVodActionModalOpened: "library/TOGGLE_ACTION_MODAL_OPENED",
      setVodForModalAction: "library/SET_VOD_FOR_MODAL_ACTION",
    }),
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss" scoped>



</style>
