<template>
<section class="videos-stats-component m-10 flex flex-col flex-auto relative">

  <section class="text-black-600 items-center">

    <aside class="report-actions">

      <!-- <ioio-button
        type="primary"
        size="medium"
        :fullWidth="false"
        iconLeft=""
        iconRight=""
        classes="mr-4"
        :disabled="false"
      >
        Share
      </ioio-button> -->
      <ioio-button
        type="primary"
        variant="outline"
        size="medium"
        :fullWidth="false"
        iconLeft=""
        iconRight=""
        classes=""
        :disabled="false"
        @click="exportToCsv"
      >
        Export to CSV
      </ioio-button>
    </aside>
  </section>

  <header class="flex items-center mb-12">
    <h3 class="m-0 mr-2 text-base text-black-400 font-normal">Show activity for</h3>
    <ioio-dropdown-base
      class="w-64 mr-4"
      alignX="right"
      :overflow="true">

      <template slot="title" classes="text-black-900" >
        <div class="ds-dropdown__title"  ref="dropdown">
          {{selectedPeriod}}
          <ioio-icon icon="fal-chevron-down" />
        </div>
      </template>

        <div
          v-for="option in activityDropdownItems"
          :key="option.label"
          class="dropdown__menu-item">

          <ioio-button
            type="minimal"
            size="medium"
            @click="onActivityPeriodChange(option)">
              {{ option.label }}
          </ioio-button>
        </div>

        <div @click.stop="">
          <ioio-field
            :maximum="moment().subtract(1, 'days').format('YYYY-MM-DD')"
            v-model="dateRange"
            placeholder='Choose dates'
            type="daterange"
            :hasClearBtn="false"
            @input="onActivityPeriodChange">
          </ioio-field>
        </div>

    </ioio-dropdown-base>

    <ioio-dropdown
      :items="devicesDropdownItems"
      titleProp="label"
      valueProp="device"
      v-model="selectedDeviceOption"
      @change="onSelectedDeviceOptionsChange"
      size="medium"
      class="mr-4 w-64"
      fullWidth
    />

    <input-search-component
      :onSearchInput="onSearchWordChange"
      placeholder="Search a video"
    />
  </header>

  <section class="flex flex-col flex-auto overflow-auto pr-4 mb-8">

    <ioio-table class="p-0 border-0 shadow-none mb-8">
      <template slot="thead">
        <tr class="w-full grid grid-cols-5">
          <th class="text-left text-base text-black-400 font-normal">Video</th>
          <th class="text-left text-base text-black-400 font-normal">
            Total time watched
          </th>
          <th class="text-left text-base text-black-400 font-normal">
            Total views
          </th>
          <th class="text-left text-base text-black-400 font-normal">
            Unique views
          </th>
          <th class="text-left text-base text-black-400 font-normal">
            Average time watched
          </th>
        </tr>
      </template>
      <template slot="tbody" v-if="!isRequestPending">
        <tr class="w-full grid grid-cols-5 items-center cursor-pointer" v-for="vid in videosStats" @click="navigateToVideoInsights(vid)">
          <td class="text-base text-black-900 truncate pr-2">{{ vid.name }}</td>

          <td class="text-base text-black-800 font-normal flex items-center">

            <span class="mr-4">{{ vid.total_time_watched.value }}</span>
            <span v-if="vid.total_time_watched.up" class="text-green-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-up"  />
              +<span>{{ vid.total_time_watched.percent }}%</span>
            </span>

            <span v-else class="text-red-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-down"  />
              -<span>{{ vid.total_time_watched.percent }}%</span>
            </span>

          </td>

          <td class="text-base text-black-800 font-normal flex items-center">

            <span class="mr-4">{{ vid.total_views.value }}</span>
            <span v-if="vid.total_views.up" class="text-green-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-up"  />
              +<span>{{ vid.total_views.percent }}%</span>
            </span>

            <span v-else class="text-red-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-down"  />
              -<span>{{ vid.total_views.percent }}%</span>
            </span>

          </td>
          <td class="text-base text-black-800 font-normal flex items-center">

            <span class="mr-4">{{ vid.unique_views.value }}</span>
            <span v-if="vid.unique_views.up" class="text-green-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-up"  />
              +<span>{{ vid.unique_views.percent }}%</span>
            </span>

            <span v-else class="text-red-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-down"  />
              -<span>{{ vid.unique_views.percent }}%</span>
            </span>

          </td>

          <td class="text-base text-black-800 font-normal flex items-center">

            <span class="mr-4">{{ vid.avg_time_watched.value }}</span>
            <span v-if="vid.avg_time_watched.up" class="text-green-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-up"  />
              +<span>{{ vid.avg_time_watched.percent }}%</span>
            </span>

            <span v-else class="text-red-500 text-xs font-normal">
              <ioio-icon icon="fa-arrow-down"  />
              -<span>{{ vid.avg_time_watched.percent }}%</span>
            </span>

          </td>

        </tr>

        <tr v-if="!videosStats.length">No information found.</tr>
      </template>
      <template slot="tbody" v-else>

        <div style="overflow:hidden; width: 100px" class="mt-10 flex items-center flex-auto">
          <logo-loader-component width="100px" height="120px" />
        </div>
      </template>
    </ioio-table>

  </section>

  <pagination-component
    class="flex-none"
    :page-selected="currentPage"
    :click-callback="onPaginationClicked"
    :per-page-items="perPageItems"
    :totalItemsLength="totalCount"
  />
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { downloadCSV } from '@utils/helpers';
import moment from "moment-timezone";

export default {
  data: () => ({
    activityDropdownItems: [
      { "t_r": '7d', "label": "Last 7 days" },
      { "t_r": '15d', "label": "Last 15 days" },
      { "t_r": '30d', "label": "Last 30 days" },
    ],
    activityPeriod: '7d',
    selectedPeriod: 'Last 7 days',
    devicesDropdownItems: [
      { "device": '', "label": "All devices" },
      { "device": 'PC', "label": "PC" },
      { "device": 'Mobile', "label": "Mobile" },
      { "device": 'Tablet', "label": "Tablet" },
    ],
    selectedDeviceOption: '',
    searchWord: '',
    currentPage: 1,
    perPageItems: 10,
    totalCount: 0,

    videosStats: [],

    isRequestPending: false,
    moment: {},
    dateRange:[]
  }),
  props: {
    navigateToVideoInsights: Function
  },
  created() {

    this.loadVideosStats();
    this.moment = moment;
  },
  methods: {
    exportToCsv() {

      const params = {

        org_id: this.organizationSelected.guid,
        t_r: this.activityPeriod,
        searchWord: this.searchWord,
        pageNum: this.currentPage,
        perPageItems: this.perPageItems,
        is_csv: true
      };

      this.selectedDeviceOption && (params.device = this.selectedDeviceOption);

      const channelGuid = this.$route.query.channelGuid;

      if (channelGuid) {

        params.c_guid = channelGuid;
      }

      this.getVodsStats(params)
        .then(stats => {

          downloadCSV(stats, 'stats.csv')
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },
    onSearchWordChange(newVal) {

      // FilterChange -> go to first page of the new selection
      this.currentPage = 1;

      this.searchWord = newVal;
      this.loadVideosStats();
    },

    onSelectedDeviceOptionsChange() {

      // FilterChange -> go to first page of the new selection
      this.currentPage = 1;

      this.loadVideosStats();
    },

    onActivityPeriodChange(value) {

      if (Array.isArray(value)) {

        if (value.length < 2) {

          return;
        } else {

          this.dateRange = value;
          this.activityPeriod = `${this.moment(value[0]).format('YYYY-MM-DD')}_${this.moment(value[1]).format('YYYY-MM-DD')}`;
          this.selectedPeriod = `${this.moment(value[0]).format('DD/MM/YYYY')} to ${this.moment(value[1]).format('DD/MM/YYYY')}`;
          this.$refs.dropdown.click();
        };

      } else {

        this.activityPeriod = value.t_r;
        this.selectedPeriod = value.label;
        this.dateRange = [];
      }

      // FilterChange -> go to first page of the new selection

      this.currentPage = 1;

      this.loadVideosStats();

    },

    onPaginationClicked(pageNum) {

      this.currentPage = pageNum;
      this.loadVideosStats();
    },

    loadVideosStats() {

      const params = {

        org_id: this.organizationSelected.guid,
        t_r: this.activityPeriod,
        pageNum: this.currentPage,
        perPageItems: this.perPageItems,
      };

      this.selectedDeviceOption && (params.device = this.selectedDeviceOption);
      this.searchWord && (params.searchWord = this.searchWord);

      const channelGuid = this.$route.query.channelGuid;

      if (channelGuid) {

        params.c_guid = channelGuid;
      }

      this.isRequestPending = true;

      this.getVodsStats({
        ...params
      })
        .then(stats => {

          this.videosStats = stats.videos ? [...stats.videos] : [];

          this.totalCount = stats.totalCount;
        })
        .finally(() => {

          this.isRequestPending = false;
        })
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getVodsStats: "statistics/getVodsStats",
    })
  },
  computed: {
    ...mapGetters({
      organizationSelected: "app/organizationSelected"
    })
  },
}
</script>

<style lang="scss">
.videos-stats-component {

  .input.ds-fields__input{

    @apply h-10;
  }

  .report-actions {

    position: absolute;
    right: 0;
    top: -78px;
  }
}
</style>
