<template>
<div class="library-tab-images-component flex h100">

  <aside class="width+3 flex flex-column h100 border-right flex-none pr2">

    <images-library-component :onSelectAsset="stageFileForCrop" :selectedImg="stagedImg" />
  </aside>

  <section class="selected-img-section p2 width+7 h100 flex flex-column flex-auto">

    <section v-if="cropperSrc" class="cropper-section flex flex-auto mb2">

      <article class="cropper-holder mr3">
        <cropper
          class="cropper"
          :src="cropperSrc"
          ref="cropper"
          :checkOrientation="false"
          @ready="() => setStencilCoordinatesPreset(selectedStencilPreset, preselectedSize)"
          @change="onCoordsChange"
          :stencil-props="selectedStencilPreset.props"
        />
        <div class="selected-size-preview p1 size-4 rounded-2 white">
          <div>Width: {{ selectedWidth }}px</div>
          <div>Height: {{ selectedHeight }}px</div>
        </div>
        <footer>

          <div class="btn-group minimal justify-end mt2">

            <button-component
              variant="minimal"
              size="size-s"
              @click="() => setStencilCoordinatesPreset(preset)"
              :class="{ active : preset.name === selectedStencilPreset.name }"

              v-for="preset in possibleCoordsPresets" :key="preset.name"
            >{{ preset.label }}

            </button-component>

          </div>

        </footer>

      </article>

      <aside class="flex flex-auto">
        <!--form-->

        <vue-form :state="newImgFormState" @submit.prevent class="overflow-y h100 ratio1-1">

          <validate class="" tag="label">
            <span class="text-danger">Title <span class="text-danger">*</span></span>
            <input
              type="text"
              placeholder="Title"
              v-model="stagedImg.meta.title"
              required
              name="stagedImgTitle"/>
            <field-messages name="stagedImgTitle" show="$submitted || $dirty && $touched">
              <div slot="required">Title is a required field</div>
            </field-messages>
          </validate>

          <validate class="" tag="label">
            <span class="text-danger">Description</span>
            <input
              type="text"
              placeholder="Description"
              v-model="stagedImg.meta.description"
              name="stagedImgDescription"/>
            <field-messages name="stagedImgDescription" show="$submitted || $dirty && $touched">
            </field-messages>
          </validate>


          <validate class="" tag="label">

            <span class="text-danger">Language <span class="text-danger">*</span></span>
            <select class="select ratio1-1" style="border: 1px solid var(--color-gray-300);box-shadow: none;"
              v-model="stagedImg.meta.lang"
              name="type" required>

              <option
                :key="abbr"
                :value="abbr"
                v-for="(label, abbr) in possibleLanguages"
                >{{ label }}</option>
            </select>
            <field-messages name="type" show="$submitted || $dirty && $touched">
              <div slot="required">Language is a required field</div>
            </field-messages>
          </validate>


          <footer v-if="!isRequestPending" class="">
            <section class="flex mb2">
              <button-component
                variant="subtle"
                @click.stop.prevent="cancelImageSelection"
                class="mr2 ratio1-2"
                size="size-m"
              >Cancel</button-component>
              <button-component
                variant="primary"
                @click="() => stageImage(null)"
                size="size-m ratio1-2"
                style="padding: 0;"
              >{{ preselectedSize ? 'Stage New' : 'Stage' }}</button-component>
            </section>
            <button-component
              v-if="preselectedSize"
              variant="primary"
              @click="() => stageImage(updateIndex)"
              size="size-m ratio1-1"
            >Stage Update</button-component>

          </footer>
        </vue-form>

      </aside>

    </section>


    <section v-else class="flex flex-auto justify-center items-center">
      <h3 class="mx3">Select an Image from the library on the left in order to add it to {{ this.editedVod.meta.title || 'this VOD' }}.
      </h3>
    </section>

    <section class="asigned-images-to-vod-section flex flex-auto flex-column">

      <header class="flex justify-between border-bottom">

        <h1 class="size-1 pb1">Images</h1>

        <button-component
          v-if="hasImgDataBeenChanged && !isRequestPending"
          variant="primary"
          intent="success"
          @click.stop.prevent="updateVodMetadata"
          class=""
          size="size-m"
        >Save Changes</button-component>
      </header>

      <section v-if="stagedImages.length" class="ratio1-1 px1 overflow-y">
        <ul class="list-reset staged-imgs-container">
          <li v-for="(img, index) in stagedImages" :key="index" class="flex justify-between py2 border-bottom">
            <section class="img-description-section flex">
              <div
                :style="{ background:`url('${img.url}&q=80&f=jpeg') no-repeat center center / contain` }"
                class="thumb-image mr2">
              </div>
              <h6 class="mr2 m0 mt1 size+2 truncate title">{{ img.title }}</h6>

              <p class="m0 mt2 size-3">{{ img.width }}x{{ img.height }}</p>
            </section>

            <aside class="img-ctrls flex items-center">

              <div class="mr-2">
                <ioio-button
                  type="secondary"
                  variant="outline"
                  size="small"
                  v-tooltip="'copy URL'"
                  @click.stop.prevent="copyToClipboard(index)">
                  <ioio-icon
                    icon="fas-clone"
                    class="w-5 h-5 text-black-200 cursor-pointer"
                  />
                </ioio-button>
                <input
                  class="copy-to-clipboard-input"
                  type="text"
                  :id="'image-url'+index"
                  :value="img.url">
              </div>

              <button-component
                class="mr1 flex-none"
                size="size-s"
                @click="editStagedImg(index)"
                v-tooltip="'edit file'"
                >
                <ioio-icon icon="fas-pen" class="w-4 h-4"/>

              </button-component>

              <button-component
                class="flex-none"
                size="size-s"
                @click="removeStagedImg(index)"
                v-tooltip="'remove file'"
                >
                <ioio-icon icon="far-xmark" class="w-4 h-4 text-red-600"/>

              </button-component>
            </aside>


          </li>
        </ul>
      </section>

      <h3 v-else>This Asset doesn't have any Images associated with it.</h3>
    </section>
  </section>



</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { languagesTwoLetterConfig } from "@utils/constants";
import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({

    isRequestPending: false,

    stagedImg: {},

    cropperSrc: '',

    // for size preview box
    selectedWidth: 0,
    selectedHeight: 0,

    selectedStencilPreset: {

      name:'wide',
      label: 'Wide',

      props: {
        aspectRatio: 16/9
      }
    },

    possibleCoordsPresets: [{
      name:'poster',
      label: 'Poster',

      props: {
        // Will only be used if frameWidth and
        // frameHeight are NOT present in the editedVod data
        aspectRatio: 1
      }
    },
    {
      name:'thumbnail',
      label: 'Thumbnail',

      props: {
        // Will only be used if frameWidth and
        // frameHeight are NOT present in the editedVod data
        aspectRatio: 1
      }
    },
    {
      name:'portrait',
      label: 'Portrait',

      props: {
        aspectRatio: 2/3
      }
    },
    {
      name:'square',
      label: 'Square',

      props: {
        aspectRatio: 1
      }
    },
    {
      name:'wide',
      label: 'Wide',

      props: {
        aspectRatio: 16/9
      }
    },
    {
      name:'ultrawide',
      label: 'Ultrawide',

      props: {
        aspectRatio: 21/9
      }
    },
    {
      name:'custom',
      label: 'Custom',

      props: {
        // Uncomment if ratio should be set for custom as well
        // aspectRatio: 6/2
      }
    }],

    preselectedSize: null,
    updateIndex: null,

    stagedImages: [],

    newImgFormState: {},

    hasImgDataBeenChanged: false,

    possibleLanguages: languagesTwoLetterConfig,
  }),
  props: {},

  created() {

    this.loadStagedImages();
  },

  mounted() {

    window.as = this;
    this.copyElementInnerText = copyElementInnerText;
  },

  methods: {

    setStencilCoordinatesPreset(preset, preselectedSize) {

      const newPreset = {
        ...preset
      };

      // The aspect ratio should be based on the video
      if (preset.name === 'poster' || preset.name === 'thumbnail') {

        if (this.editedVod.frameWidth && this.editedVod.frameHeight) {

          newPreset.props.aspectRatio =
            this.editedVod.frameWidth / this.editedVod.frameHeight;
        }
      }

      this.selectedStencilPreset = newPreset;

      // Center the viewfinder. setTimeout is needed, since the
      // new coordinates aren't available immediately, when stencil
      // preset is changed
      setTimeout(() => {

        // First enlarge the viewFinder as much as it will go,
        // based on the img size and the newly set aspect ratio
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => {

          console.log('coordinates, imageSize', coordinates, imageSize, preselectedSize);

          if (preselectedSize) {

            return ({
              width: preselectedSize.width,
              height: preselectedSize.height
            });
          }

          return ({
            width: imageSize.width,
            height: imageSize.height
          });
        });

        // Center the viewFinder
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => {

          console.log('coordinates, imageSize', coordinates, imageSize);

          if (preselectedSize) {

            return ({

              left: preselectedSize.left,
              top: preselectedSize.top,
            });
          }

          return ({
            left: imageSize.width/2 - coordinates.width/2,
            top: imageSize.height/2 - coordinates.height/2,
          })
        });
      },0);
    },

    stageFileForCrop(img, isEditing) {
      console.log(img)
      if (!isEditing) {

        this.preselectedSize = null;
      }

      this.stagedImg = {
        ...img,
        meta: {
          lang: 'EN', //default to EN if not selected
          ...img.meta
        }
      };

      this.cropperSrc = `${img.cdnPath}?f=jpeg`;
    },

    stageImage(updateIndex) {

      this.newImgFormState._submit();

      if (this.newImgFormState.$invalid) {

        return;
      }


      this.preselectedSize = null;

      const { coordinates } = this.$refs.cropper.getResult();

      console.log(coordinates)

      const { width, height, left, top } = coordinates;

      const imgDto = {

        width,
        height,
        left,
        top,
        title: this.stagedImg.meta.title,
        description: this.stagedImg.meta.description,
        lang: this.stagedImg.meta.lang,
        variant: this.selectedStencilPreset.name, // [enum - poster	thumbnail	portrait	square	wide	ultra-wide [custom - NOT for Brightcove]	]
        absoluteUrl: this.stagedImg.cdnPath,
        url: `${this.stagedImg.cdnPath}?e=${left},${top},${width},${height}`
      };

      if (updateIndex !== null) {

        this.stagedImages.splice(updateIndex, 1, imgDto);
        this.updateIndex = null;

      } else {

        this.stagedImages.push(imgDto);
      }


      this.hasImgDataBeenChanged = true;
    },

    copyToClipboard(index) {
      let elementId = 'image-url'+index;
      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    onCoordsChange({ coordinates, canvas }) {

      this.selectedWidth = coordinates.width;
      this.selectedHeight = coordinates.height;
		},

    cancelImageSelection() {

      this.cropperSrc = '';

      this.stagedImg = {};
    },

    editStagedImg(index) {

      this.updateIndex = index;

      console.log('img for edit - ', this.stagedImages[index]);

      const mappedFile = {
        meta: this.stagedImages[index],
        cdnPath: this.stagedImages[index].absoluteUrl
      };

      const prevSavedStencilPreset =
        this.possibleCoordsPresets.find(p => p.name === this.stagedImages[index].variant);

      const { width, height, top, left } = this.stagedImages[index];

      const preselectedSize = {
        width, height, top, left
      };

      this.preselectedSize = preselectedSize;


      this.setStencilCoordinatesPreset(prevSavedStencilPreset, preselectedSize);

      this.stageFileForCrop(mappedFile, 'isEditing');
    },

    removeStagedImg(index) {

      this.hasImgDataBeenChanged = true;

      this.stagedImages.splice(index, 1);
    },

    updateVodMetadata() {

      console.log('this.stagedImages', this.stagedImages)
      console.log('editedVod', this.editedVod)

      const params = {

        ...this.editedVod.meta,
        croppedImages: this.stagedImages
      };

      const payload = {

        params,
        vodGuid: this.editedVod.guid
      };

      this.isRequestPending = true;

      this.makeEditVodMetadataRequest(payload)
        .then(() => {

          this.$toasted.success("Your changes were saved successfully");

          this.hasImgDataBeenChanged = false;

          this.updateEditedVod({
            ...this.editedVod,
            meta: {

              ...params
            },
          });
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    loadStagedImages() {

      let newStagedImagesData = [];

      if (this.editedVod.meta.croppedImages) {

        newStagedImagesData = this.editedVod.meta.croppedImages;
      }

      this.stagedImages = newStagedImagesData;
    },


    ...mapMutations({
      updateEditedVod: "channelManager/UPDATE_EDITED_VOD",
    }),
    ...mapActions({

      makeEditVodMetadataRequest: "channelManager/makeEditVodMetadataRequest",
    })
  },
  computed: {
    ...mapGetters({
      editedVod: "channelManager/editedVod",
    })
  },

  watch: {

    editedVod() {

      this.loadStagedImages();

      this.hasImgDataBeenChanged = false;
    }

  }
}
</script>

<style lang="scss">



.library-tab-images-component {

  .cropper-section {

    height: 350px;
    min-height: 350px;
    max-height: 350px;

    // display: none;

    footer {

      height: 50px;
    }
  }

  .cropper-holder {

    position: relative;
  }

  .selected-size-preview {

    position: absolute;
    background-color: #0000009e;
    top: 0;
    right: 0;
  }

  .cropper {

    width: 500px;
    height: 300px;
  }

  .staged-imgs-container {

    .thumb-image {

      width: 60px;
      height: 45px;
    }

    .title {

      width: 300px;
    }
  }
}
</style>
