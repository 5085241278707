<template>
<div class="zoom-integration-settings-component flex flex-column flex-auto overflow-auto mt-4">

  <ioio-modal
    class=""
    :show.sync="isConfirmDisableModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        Are you sure you want to disable this integration?
      </div>
    </template>

    <h3 class="">
      All integrations with Zoom will be disabled.
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelDisable"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmDisable"
        >
          Disable
        </ioio-button>
      </div>
    </template>

  </ioio-modal>

  <div v-if="isRequestPending" style="overflow:hidden" class="flex items-center flex-auto">
    <logo-loader-component width="100px" height="100px" />
  </div>

  <section v-if="!isRequestPending && !wrongSettings && !wrongConnection && isOpen" class="flex flex-column flex-auto">
    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6" v-if="!wrongConnection && zoomConnect">
      <h5 class="my-2 text-sm">Zoom has been successfully integrated. Here you can manage additional settings, that will improve your ioPlatform experience.</h5>
    </section>
    <header class="flex justify-between items-center mb-4">
      <h1>Emails</h1>
      <ioio-button
        type="primary"
        size="small"
        @click.stop.prevent="addEmailField"
      >
        Add
      </ioio-button>
    </header>

    <section class="border-bottom border-black-200 mb-6 pb-4">

      <h3 v-if="!emailFields.length" class="text-sm text-black-400 mb-2">Here you setup a list of users, that would be excluded from the automated import of Zoom meetings.</h3>
      <h3 v-else class="text-sm text-black-400 mb-2">This is the list of Zoom users that are excluded from the automated import.</h3>
      <section
        class="flex justify-between items-center mb-4"
        v-for="(emailField, index) in emailFields"
        :key="index">

        <ioio-field
          type="email"
          required
          topic="integrationForm"
          size="small"
          placeholder="Email"
          :maximum=64
          v-model="emailFields[index]" class="w-full mr-6"></ioio-field>

          <ioio-button
            type="danger"
            variant="tonal"
            size="small"
            @click.stop.prevent="removeEmailField(index)"
          >
            Remove
          </ioio-button>

      </section>
    </section>

    <section class="tags-section border-bottom border-black-200 pb-4 mb-6">

      <ioio-tags
        topic="integrationForm"
        size="small"
        label="Tags"
        class="w-full"
        v-model="customMeta.tags"
        :tags="customMeta.tags"
      />

      <div class="ds-tags__error" v-if="!tagsLength">
        Tags length should not be more than 64 characters.
      </div>

      <ioio-tags
        topic="integrationForm"
        size="small"
        label="Categories"
        class="w-full mt-4"
        v-model="customMeta.categories"
        :tags="customMeta.categories"
      />

      <div class="ds-tags__error" v-if="!categoriesLength">
        Categories length should not be more than 64 characters.
      </div>

    </section>

    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6">

      <h1 class="text-md mb-4">Select hubs to integrate with Zoom</h1>
      <div v-if="combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded">

        <ioio-checkbox
          v-for="hub in combinedSiteBuilderHubs"
          :key="hub.id"
          size="small"
          disclaimer=""
          v-model="addedHubsGuidsMap[hub.id]"
          @input="(newVal) => toggleAddHubToList(hub, newVal)"
        >
          <span class="text-sm">{{ hub.name }}</span>
        </ioio-checkbox>
      </div>
      <h3 v-else-if="!combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded" class="text-sm text-black-400">No hubs configured</h3>

    </section>


    <ioio-checkbox
      size="small"
      disclaimer="The integration will be disabled"
      v-model="isIntegrationDisabled"
      @input="toggleDisableIntegrationnewVal"
    >
      <span class="text-sm">Disable integration</span>
    </ioio-checkbox>

    <footer class="flex justify-end my-8">

      <ioio-button
        type="primary"
        size="medium"
        @click.stop.prevent="saveZoomSettings('update')"
        topic="integrationForm"
      >Save
      </ioio-button>
    </footer>

  </section>

  <section  v-if="!isRequestPending && wrongSettings && !wrongConnection" class="flex flex-column flex-auto">
    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6" >
      <h1 class="text-md mb-4">Only Zoom admin users can add admin applications. In case you are not a Zoom admin please have him/her perform this action in ioPlatform.</h1>
    </section>
    <footer class="flex justify-end my-8">

      <ioio-button
        type="primary"
        size="medium"
        @click.stop.prevent="zoomIntergation"
        topic="integrationForm"
      >Authorize
      </ioio-button>
    </footer>

  </section>
  <section  v-if="wrongConnection" class="flex flex-column flex-auto">
    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6" >
      <h1 class="text-md mb-4">There is problem to connect with Zoom</h1>
    </section>

  </section>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isOpen: false,
    isConfirmDisableModalOpened: false,
    isRequestPending: false,
    wrongSettings: false,

    zoomSettings: {},
    installUrl:'',

    emailFields: [],

    customMeta: {

      hubs: [],
      tags: [],
      categories: []
    },

    addedHubsGuidsMap: {},
    addedHubsMap: {},

    isIntegrationDisabled: false,

    url: '',
    code: '',
    wrongConnection: false
  }),

  props: {

    zoomConnect: {
      type: Boolean,
      default: false
    }
  },

  created() {

    if(!this.zoomConnect) {

      this.setZoomSettings('update');

    } else {

      this.url = window.location.href.split('?')[0];
      this.code = this.$route.query.code;

     if (this.code !== '' && this.$route.query.hasOwnProperty('code')) {

       this.setZoomSettings("set");
     } else {
       this.wrongConnection = true;
     }
    }

  },
  mounted() {

    window.a = this;
  },
  methods: {

    setZoomSettings(type) {

      this.isRequestPending = true;

      this.getIngestDistributors()
        .then((distributors) => {
          const zoom = distributors.find(d => d.guid === 'zoom');

          if (!zoom) {

            this.$toasted.error('The Zoom integration is not available in your organisation')
            return;
          }

          this.isIntegrationDisabled = !zoom.enabled;

          this.zoomSettings = {

            ...zoom.zoomSettings
          };

          this.installUrl = this.zoomSettings.installUrl;

          delete this.zoomSettings.installUrl;

          if (this.zoomSettings.emails) {

            this.emailFields = [

              ...zoom.zoomSettings.emails
            ];
          }

          if (this.zoomSettings.customMeta) {

            const tags = this.zoomSettings.customMeta.tags || [];
            const categories = this.zoomSettings.customMeta.categories || [];

            this.customMeta = {

              ...this.zoomSettings.customMeta,
              tags: [...tags],
              categories: [...categories]
            };

            if (this.zoomSettings.customMeta.hasOwnProperty('hubs')) {

              this.zoomSettings.customMeta.hubs.forEach((hub) => this.toggleAddHubToList(hub, true));
            };
          };

          this.isOpen = true;

          if (type === 'update') {

            this.checkDistributorStatus();
          };
        })

        .finally(() => {

            if (type === "set") {

              this.saveZoomSettings('set');
            };

          });
    },

    zoomIntergation() {

      let path = window.location.href.split('?')[0];

      let url = this.installUrl + path;

      this.setZoomIntegrationRequest(true);

      window.open(url,"_self");
    },

    checkDistributorStatus() {

      this.isRequestPending = true;

      let payloadCheck = {

        distributorGuid: 'zoom'
      };

        this.checkIngestDistributorStatus(payloadCheck)
          .then(() => {

            this.wrongSettings = false;
            this.isOpen = true;

          })
          .catch(errPayload => {

            if (errPayload.code === 422 || errPayload.code === 400) {

              this.wrongSettings = true;
            }
          })

          .finally(() => {

            this.isRequestPending = false;
          });

    },

    cancelDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(false);
    },

    confirmDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(true, 'force');
    },

    addEmailField() {

      this.emailFields.push('');
    },

    removeEmailField(index) {

      this.emailFields.splice(index, 1);
    },

    toggleDisableIntegrationnewVal(newVal, force) {

      if (newVal && !force) {

        this.isConfirmDisableModalOpened = true;
        return;
      }

      this.isIntegrationDisabled = newVal;
    },

    toggleAddHubToList(hub, newVal) {

      if (newVal) {

        this.addedHubsGuidsMap[hub.id] = true;

        this.addedHubsMap[hub.id] = {

          id: hub.id,
          name: hub.name,
          permissions: []
        };

      } else {

        this.addedHubsGuidsMap[hub.id] = false;
        delete this.addedHubsMap[hub.id];
      }
    },

    saveZoomSettings(type) {

      if (type === 'update' && (!this.categoriesLength || !this.tagsLength)) {

        return;
      };

      const hubs = [];

      for (const hubId in this.addedHubsMap) {

        if (this.addedHubsMap[hubId]) {

          hubs.push(this.addedHubsMap[hubId]);
        }
      };

      const payload = {

        distributorGuid: 'zoom',

        data: {

          enabled: !this.isIntegrationDisabled,
          zoomSettings: {

            ...this.zoomSettings,

            emails: this.emailFields,

            customMeta: {
              ...this.customMeta,
              hubs
            },

            code: this.code,

            origin: this.url
          }
        }
      };

      if (type === 'set') {

        payload.data.enabled = true;

        this.isIntegrationDisabled = false;
      };

      this.isRequestPending = true;

      this.updateIngestDistributors(payload)
        .then(() => {

          if (type === 'set') {

            this.$toasted.success('Integrated with Zoom successfully');
          } else {



            if (this.isIntegrationDisabled) {

              this.wrongSettings = true;
            } else {

              this.$toasted.success('Configuration saved successfully');
            }
          }

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    ...mapMutations({
      setZoomIntegrationRequest: "ingest/SET_ZOOM_INTEGRATION"
    }),
    ...mapActions({
      getIngestDistributors: "ingest/getIngestDistributors",
      updateIngestDistributors: "ingest/updateIngestDistributors",
      checkIngestDistributorStatus: "ingest/checkIngestDistributorStatus"
    })
  },
  computed: {
    ...mapGetters({
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
      distributors: 'ingest/ingestDistributors'
    }),

    combinedSiteBuilderHubs() {

      const hubs = [];

      this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          a.hubs.forEach(hub =>{

             hubs.push(hub);
          });
        }
      });

      return hubs;
    },
    tagsLength() {

      let tags = this.customMeta.tags.reduce((a, b) => a + b,'');

      return tags.length < 64;
    },
    categoriesLength() {

      let categories = this.customMeta.categories.reduce((a, b) => a + b,'');

      return categories.length < 64;
    }
  }
}
</script>

<style lang="scss">


</style>
