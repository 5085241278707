/**
 * @requiresAuth - Boolean
 *
 * A value of `true` - accessible ONLY from Authenticated user
 * A value of `false` - accessible ONLY from NON-Authenticated user
 * A value of `null` - Should be set for routes, available for users in Auth && Un-Auth state
 */

 /**
 * @permissions - Object
 *
 * A key - value pair, where the key is the permission string
 * (inline with the ones comming from /token-permissions request)
 * and the value is `true`.
 * A Pair should be set, ONLY when permission should be checked.
 */

 /**
 * @isAdminRoute - Boolean
 *
 * A special key, which should be set ONLY on routes, that are
 * for Admin (internal use).
 * This key is intentionally different from `permissions`,
 * since the BE logic is different
 */

export const routesConfig = {
  /**
   * NON - auth routes
   */
  '/login': {
    view: 'Login',
    requiresAuth: false,
    meta: {
      pageTitle: 'Login'
    }
  },
  /* *** */


  /**
   * Routes accessible for every auth state
   */
  '/vod-preview': {
    view: 'VodPreview',
    requiresAuth: null,
    meta: {
      pageTitle: 'VodPreview'
    }
  },
  /* *** */


  /**
   * ADMIN specific routes
   */
  '/admin/manage-organizations': {
    view: 'ManageOrganizations',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },

  '/admin/manage-organizations/details': {
    view: 'OrganizationDetails',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },

  '/admin/manage-organizations/details/deployment': {
    view: 'OrganizationDeploymentProcess',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },

  '/admin/manage-organizations/deployment': {
    view: 'DeploymentProcess',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },

  '/admin/manage-aws-accounts': {
    view: 'ManageAwsAccounts',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },

  '/admin/sitebuilder-settings': {
    view: 'SitebuilderSettings',
    requiresAuth: true,
    isAdminRoute: true,
    meta: {
      pageTitle: 'Admin'
    }
  },
  /* *** */


  /**
   * Auth routes
   */
  '/': {
    view: 'Dashboard',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {},
    meta: {
      pageTitle: 'Dashboard'
    }
  },

  '/manage/settings': {
    view: 'Settings',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/notifications': {
    view: 'ManageNotifications',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/users': {
    view: 'ManageUsers',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/roles-and-permissions': {
    view: 'ManageRolesAndPermissions',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/api-keys': {
    view: 'ApiKeysSettings',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/meta-fields': {
    view: 'ManageMetaFields',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/managed-inputs': {
    view: 'ManagedInputs',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/integrations': {
    view: 'ManageApps',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/manage/syndication': {
    view: 'SyndicationSettings',
    requiresAuth: true,
    permissions: {
      syndication_lists_list: true
    },
    meta: {
      pageTitle: 'Settings'
    }
  },

  '/statistics/channels': {
    view: 'AllChannelsStats',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },

  '/statistics/channels/details': {
    view: 'ChannelStats',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },

  '/statistics/channels/videos': {
    view: 'ChannelVideosStats',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },

  '/statistics/channels/videos/details': {
    view: 'ChannelVideoStats',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },

  '/statistics/vod': {
    view: 'LibraryInsights',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },
  '/statistics/vod/details': {
    view: 'LibraryVideoStats',
    requiresAuth: true,
    permissions: {},
    meta: {
      pageTitle: 'Insights'
    }
  },
  /* *** */


  /**
   * Channel Manager specific routes
   */
  '/vod': {
    view: 'ChannelManagerOld',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      listings_list: true,
      schedules_list: true
    },
    meta: {
      pageTitle: 'Channel Manager'
    }
  },
  /**
  * NOTE: ChannelManagerOldContainer was created and it is a copy of
  * the working version of the ChannelManager. Development of the new
  * version of the ChannelManager will be done in ChannelManagerContainer.
  * The new container will be rendered on route /vod/channel-manager-new .
  * The old copy will be rendered on route /vod so that no breaking changes
  * are made to it while the new ChannelManager is implemented.
  */
  '/vod/channel-manager-new': {
    view: 'ChannelManager',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      listings_list: true,
      schedules_list: true
    },
    meta: {
      pageTitle: 'Channel Manager'
    }
  },

  '/vod/library': {
    view: 'Library',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      // NOTE: some permissions have to be added
    },
    meta: {
      pageTitle: 'Library'
    }
  },

  '/vod/library-new': {
    view: 'LibraryNew',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      // NOTE: some permissions have to be added
    },
    meta: {
      pageTitle: 'Library'
    }
  },

  '/vod/library/playlists': {
    view: 'LibraryPlaylists',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      // NOTE: some permissions have to be added
    },
    meta: {
      pageTitle: 'Playlists'
    }
  },

  '/vod/library/workflows': {
    view: 'LibraryWorkflows',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Library Workflows'
    }
  },

  '/vod/library/syndication': {
    view: 'Syndication',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      syndication_lists_list: true
    },
    meta: {
      pageTitle: 'Syndication'
    }
  },

  '/vod/library/ingest': {
    view: 'LibraryIngest',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      sources_list: true,
      sources_ingest: true
    },
    meta: {
      pageTitle: 'Ingest'
    }
  },
  /* *** */


  /**
   * Live Manager specific routes
   */
  '/live/workflows': {
    view: 'LiveWorkflows',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Live Workflows'
    }
  },

  '/live/workflows/create': {
    view: 'CreateWorkflow',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Live Workflows'
    }
  },

  '/live/workflows/details': {
    view: 'WorkflowDetails',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Live Workflows'
    }
  },

  '/live/connected-devices': {
    view: 'ConnectedDevicesPage',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Connected Devices'
    }
  },

  '/live/blueprints': {
    view: 'Blueprints',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Blueprints'
    }
  },

  /**
   * Elemental Live specific routes
   */
  '/live/elemental-workflows': {
    view: 'ElementalWorkflows',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Live Workflows'
    }
  },

  '/live/elemental-workflows/create': {
    view: 'CreateElementalWorkflow',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'Live Workflows'
    }
  },

  '/live/elemental-devices': {
    view: 'ElementalDevices',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {

    }
  },

  '/live/elemental-blueprints': {
    view: 'ElementalBlueprints',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      WORKFLOW: true
    },
    meta: {
      pageTitle: 'ElementalBlueprints'
    }
  },

  /* *** */

  /**
   * Images specific routes
   */
  '/images-library': {
    view: 'Images',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      images_list: true
    },
    meta: {
      pageTitle: 'Images'
    }
  },
  /* *** */


  /**
   * Events specific routes
   */
  '/events-list': {
    view: 'Events',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      events_list: true
    },
    meta: {
      pageTitle: 'Events'
    }
  },
  '/events-list/details': {
    view: 'EventDetails',
    requiresAuth: true,
    requiresOrganizationSelected: true,
    permissions: {
      events_list: true
    },
    meta: {
      pageTitle: 'Events'
    }
  }
  /* *** */
};

/**
 * Used in Auth checks for redirect
 */
export const DEFAULT_AUTH_PATH = '/';
export const DEFAULT_UNAUTH_PATH = '/login';
export const DEFAULT_ADMIN_PATH = '/admin/manage-organizations';
