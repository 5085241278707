<template>
<section
  class="vod-ad-playlist-component h100 flex">

  <section class="flex flex-column h100 ratio1-1">
    <h1 class="m0 size+1 pb1 mb1 border-bottom">Playlist with Ads</h1>
    <div class="flex">

      <div class="ratio1-2">
        <video
          ref="previewVid"
          src=""
          id="video-container"
          width="100%"
          height="250"
          controls
          :poster="selectedVodOriginal.meta && selectedVodOriginal.meta.imageUrl">
        </video>

        <ul class="horizontal-chunks-preview list-reset m0 p0 ratio1-1">
          <li v-for="chunk in playlistChunks" :class="chunk.type === 'ad' ? 'ad-chunk' : 'vod-chunk'" :style="{ width: `${100 / playlistChunks.length}%` }"></li>
        </ul>

      </div>


      <div class="flex ratio1-2 border-left pl2">
        <vue-form :state="formState" @submit.prevent class="width+10">

          <section class="flex">
            <validate tag="label" class="ratio1-2 mr1">
              <div class="text-danger mb1">Display Name <span class="text-danger">*</span></div>
              <input
              class="input width+10"
                type="text"
                placeholder="Display Name"
                v-model="newVodAdPlaylist.name"
                required
                name="newVodAdPlaylistName"/>
              <field-messages name="newVodAdPlaylistName" show="$submitted || $dirty && $touched">
                <div slot="required">Display Name is a required field</div>
              </field-messages>
            </validate>
            <validate tag="label" class="ratio1-2">
              <div class="text-danger mb1">Playlist Name</div>
              <input
              class="input width+10"
                type="text"
                placeholder="Display Name"
                v-model="newVodAdPlaylist.title"
                name="newVodAdPlaylistTitle"/>
              <field-messages name="newVodAdPlaylistTitle" show="$submitted || $dirty && $touched">
              </field-messages>
            </validate>
          </section>


          <div>
            <label>Description</label>
            <textarea
              class="textarea"
              placeholder="Description"
              v-model="newVodAdPlaylist.description"
            ></textarea>
          </div>

          <div class="flex">
            <div class="ratio3-4 mr3">
              <label>Image url</label>
              <input
              class="input"
                type="text"
                placeholder="Image url"
                v-model="newVodAdPlaylist.imageUrl"
              >
            </div>

            <div class="relative ratio1-4">
              <label class="">Color</label>
              <button-component @click.stop.prevent="toggleDropdownOpened" :style="{backgroundColor: newVodAdPlaylist.color || 'gray'}" style="width: 2.125rem; height: 2.125rem; border: 1px solid white; margin: 0;">

              </button-component>

              <dropdown-component v-show="isDropdownOpened" :onClose="toggleDropdownOpened" width="20rem">
                <swatches
                v-model="newVodAdPlaylist.color"
                :colors="possiblePlaylistColors"
                inline
                >
              </swatches>
              </dropdown-component>
            </div>
          </div>
        </vue-form>
      </div>

    </div>

    <div class="flex flex-auto">

      <!-- timeline -->

      <div id="container"
        class="timeline flex flex-auto flex-column relative z3 mr2 ratio2-5"
        ref="timelineOverflowContainer"
      >

        <h3 class="m0 mb2 p1 border-bottom size-2">Video Segments</h3>

        <DraggableHolderComponent
          orientation="vertical"
          class="cards flex flex-column h100 z2 relative ratio1-1 overflow-y"
          :get-ghost-parent="getGhostParent"
          drag-class="staged-ghost-element"
          :get-child-payload="getPlaylistChunkChildPayload"
          :should-accept-drop="shouldPlaylistAcceptDrop"
          @drop="handleOnPlaylistDrop">

          <DraggableComponent

            v-for="(chunk, index) in playlistChunks"
            :key="index"
            href="#"
            :style="{ height: cardHeight + 'px', overflow: 'visible' }"
            class="card relative flex-none chunk border-bottom border-white rounded-2"
            :class="chunk.type === 'ad' ? 'ad-chunk' : 'vod-chunk pointer'"
            @click.native.stop="setPlybackPosition(chunk)">

            <div class="">
              <span class="start-offset-display ml1">{{ parseDuration(chunk.startOffset) }}</span>

              <span class="ad-info mx1 flex items-center" v-show="chunk.type === 'ad'">
                <span>
                  AD: <span>{{ chunk.name | truncate(15, '...') }}</span>
                </span>
                <span class="chunk-duration ml1 mr1">{{ parseDuration(chunk.duration) }}</span>

                <button-component class="remove-ad-btn" variant="minimal" shape="circle" @click.stop.prevent="removeAdFromPlaylist(chunk, index)">
                  <ioio-icon icon="fa-xmark" class="w-3.5 h-3.5"/>
                </button-component>
              </span>
            </div>
          </DraggableComponent>
        </DraggableHolderComponent>


      </div>
      <!-- timeline end -->

      <!-- Ads list -->
      <aside class="flex flex-auto flex-column ratio3-5">
        <h3 class="m0 mb2 p1 border-bottom size-2">Ads Library</h3>

        <DraggableHolderComponent
          orientation="vertical"
          class="flex-auto flex flex-wrap relative ads-list-section flex-auto overflow-y"

          :get-ghost-parent="getGhostParent"
          :get-child-payload="getAdListChildPayload"
          :should-accept-drop="shouldAdListAcceptDrop"
          drag-class="ad-ghost-element"
          v-if="possibleAdsList.length && !isVodListLoading">

          <DraggableComponent

            v-for="(ad, index) in possibleAdsList"
            :key="index"
            href="#"
            class="relative overflow-hidden flex-none ad-draggable-container fileitem">
              <div class="flex ratio1-1">
                <div v-if="ad.status !== 'encode-later'" class="img-container">
                  <div
                    v-if="ad.meta && ad.meta.imageUrl"
                    class="fileitem-image flex-none relative bg-gray+4"
                    style="width: 96px; height: 54px"
                    :style="{
                      background: ` url('${
                        ad.meta && ad.meta.imageUrl
                      }') no-repeat center center / cover`
                    }"
                  >
                    <tag-component
                      v-if="ad.meta && ad.meta.type === 'ad'"
                      class="fileitem-type"
                      :variant="ad.meta && ad.meta.type"
                      size="size-s"
                      >{{ ad.meta && ad.meta.type }}</tag-component
                    >
                    <tag-component
                      class="fileitem-duration"
                      variant="duration"
                      size="size-s"
                      >{{ parseDuration(ad.srcDuration) }}</tag-component
                    >
                  </div>

                </div>

                <div class="fileitem-info flex flex-column flex-auto ml2">
                  <h4 class="fileitem-title size-2 truncate pr3">
                    {{ ad.meta && ad.meta.title | truncate(64, "...") }}
                  </h4>
                  <div class="flex items-center tags-container">
                    <tag-component
                      v-if="ad.meta && ad.meta.group"
                      class="fileitem-group mr1"
                      >{{ ad.meta.group || "No group" }}</tag-component
                    >
                    <tag-component v-if="ad.meta && ad.meta.genre" class="fileitem-genre">{{
                      ad.meta.genre
                    }}</tag-component>
                  </div>

                </div>
              </div>
          </DraggableComponent>
        </DraggableHolderComponent>

        <h3 v-else-if="!isVodListLoading">There are no saved ads.</h3>

        <div v-else class="h100 width+10 p2"><loader-component height="100%" spinner="logo" size="size-m" /></div>

      </aside>
    </div>

    <footer class="flex justify-end mt2">
      <p class="mr2 mo mt1 gray+1 size-1">Video is saved as a playlist with inserted ads.</p>
      <button-component
        variant="primary"
        size="size-m"

        @click="saveVodAsPlaylistWithAds"
      >Save Playlist</button-component>
    </footer>
  </section>

</section>
</template>

<script>

import Hls from "hls.js";

import { mapGetters, mapMutations, mapActions } from "vuex";

import { getMouseClickCoords, paletteColors, getHHMMSSFromMS } from "@utils/helpers";
import {
  BtF_VODSourceToPlaylistForAds,
  BtF_VODAdToPlaylistForAds,
  FtB_VODChunksToPlaylistWithAds
} from "@utils/DTOMiddleware";

export default {
  data: () => ({
    container: null,

    xPos: 5,

    isDropdownOpened: false,

    totalStagedDuration: 0,
    markerSize: 1000, // NOTE: probably will be adjusted and made bigger for larger VODs

    cardHeight: 26,

    selectedVod: {},
    selectedVodOriginal: {},

    possibleBreakPoints: [],

    possibleVodsList: [],
    possibleAdsList: [],

    playlistChunks: [],

    formState: {},

    newVodAdPlaylist: {
      color: '',
      imageUrl: '',
      name: '',
      title: '',
      description: '',
    },

    possiblePlaylistColors: [...paletteColors()],

    isVodListLoading: false,
  }),

  props: {

    isVisible: Boolean
  },

  mounted() {

    if (this.isVisible) {

      this.seperateFilteredAdsAndVods();
    }

    window.ad = this;
  },

  beforeDestroy() {

  },

  methods: {

    removeAdFromPlaylist(ad, index) {

      this.totalStagedDuration -= ad.duration;

      const updatedChunks = [...this.playlistChunks];

      updatedChunks.splice(index, 1);

      const adjustedChunks = this.getAdjustedChunksOffsets(updatedChunks);

      this.playlistChunks = adjustedChunks;
    },

    parseDuration(ms) {
      return getHHMMSSFromMS(ms, 'returnFullHour');
    },

    getGhostParent() {
      return document.body;
    },
    toggleDropdownOpened() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    getPlaylistChunkChildPayload(removedIndex) {
      return {
        item: this.playlistChunks[removedIndex],
        origin: 'playlistChunks',
        removedIndex
      };
    },

    shouldPlaylistAcceptDrop(sourceContainerOptions, payload) {

      if (payload &&
        (payload.origin === "adList" ||
        payload.origin === "playlistChunks")) {

        return true;
      }

      return false;
    },

    getAdListChildPayload(removedIndex) {

      const parsedAd =
        BtF_VODAdToPlaylistForAds(this.possibleAdsList[removedIndex]);

      return {
        item: parsedAd,
        origin: 'adList',
        removedIndex
      };
    },

    shouldAdListAcceptDrop() {

      return false;
    },

    seperateFilteredAdsAndVods() {
console.log('--seperateFilteredAdsAndVods',this.vodList.length)
      const filteredVods = [];
      const filteredAds = [];

      this.vodList.forEach(item => {

        /**
         * Prevent displaying VODs, that are in status `encode-later`
         * or `error` since they do not have valid adPoints
         */
        if (item.status !== 'complete') {

          return;
        }

        if (item.meta.type === 'ad') {

          filteredAds.push(item);

        } else {

          filteredVods.push(item);
        }

      });

      this.possibleVodsList = [...filteredVods];
      this.possibleAdsList = [...filteredAds];
    },

    stageVodForAdInsertion(guid) {

      return this.makeGetVodDetailsRequest({ guid })
        .then(vod => {

          if (!vod.adPoints) {

            this.$toasted.error('The selected VOD has no valid ad points.');
            return;
          }

          this.selectedVod = BtF_VODSourceToPlaylistForAds(vod);
          this.selectedVodOriginal = vod;

          this.newVodAdPlaylist = {

            ...this.newVodAdPlaylist,
            name: vod.meta.name,
            description: vod.meta.description,
            imageUrl: vod.meta.imageUrl,
          };

          this.totalStagedDuration = this.selectedVod.duration;

          this.possibleBreakPoints = vod.adPoints;

          this.spliceVodToChunksAtBreakpoints(this.selectedVod);
        });
    },

    // Deprecate
    // shouldDisplayChunkName(chunk, index) {
    //   /**
    //    * Will return true if the chunk is for an ad || the
    //    * chunk is source and is first || first after an ad
    //    */

    //   const prevChunk = this.playlistChunks[index - 1];

    //   if (prevChunk && prevChunk.type === 'source' && chunk.type === 'source') {

    //     return false
    //   }

    //   return true;
    // },

    // Second concept methods
    spliceVodToChunksAtBreakpoints(vod) {

      const playlistChunks = [];

      let accumulatedDuration = 0;

      /**
       * Intentionally ignore the first possible breakpoint, since it's always 0
       */
      for (let i = 1; i < this.possibleBreakPoints.length; i++) {

        const prevBreakPoint = this.possibleBreakPoints[i - 1] || 0;
        const currentBreakPoint = this.possibleBreakPoints[i];
        const nextBreakPoint = this.possibleBreakPoints[i + 1] || 0;

        const fromPrevToCurrentBreakPoint =
          Math.abs(currentBreakPoint - prevBreakPoint);

        const fromCurrentToNextBreakPoint =
          Math.abs(nextBreakPoint - currentBreakPoint);

        const newEnd = accumulatedDuration + fromPrevToCurrentBreakPoint;

        playlistChunks.push({

          ...vod,
          absoluteOrder: i,
          name: vod.name,
          duration: fromPrevToCurrentBreakPoint,
          end: newEnd,
          absoluteStart: accumulatedDuration,
          absoluteEnd: accumulatedDuration + fromPrevToCurrentBreakPoint,
          startOffset: accumulatedDuration,
        });

        accumulatedDuration += fromPrevToCurrentBreakPoint;
      }

      /**
       * The final chunk of the VOD is smaller than the average
       * chunk duration. Add it manually (it wasn't added above).
       */
      if (accumulatedDuration < vod.duration) {

        const finalChunkDuration = vod.duration - accumulatedDuration;

        playlistChunks.push({

          ...vod,
          absoluteOrder: playlistChunks.length + 1,
          name: vod.name,
          duration: finalChunkDuration,
          end: accumulatedDuration + finalChunkDuration,
          absoluteStart: accumulatedDuration,
          absoluteEnd: accumulatedDuration + finalChunkDuration,
          startOffset: accumulatedDuration,
        });
      }

      this.playlistChunks = [...playlistChunks];
    },


    willDropChangeAbsoluteOrder(dragData) {

      const vodChunk = dragData.payload.item;

      let willAbsOrderChange = false;

      /**
        * If the vodChunk type is `ad` it's move
        * won't change anything. If it's `vod`,
        * make additional check bellow.
        */
      if (vodChunk.type === 'source') {

        const startI = Math.min(dragData.removedIndex, dragData.addedIndex);
        const endI = Math.max(dragData.removedIndex, dragData.addedIndex);

        let affectedVodChunksCount = 0;

        for (let i = startI; i <= endI; i++) {

          if (this.playlistChunks[i].absoluteOrder) {

            affectedVodChunksCount++

            if (affectedVodChunksCount === 2) {

              willAbsOrderChange = true;
              break;
            }
          }
        }
      }

      return willAbsOrderChange;
    },

    willSwapPlaceAdInBeginningOrEnd(dragData) {

      const { addedIndex, removedIndex } = dragData;

      const lastIndex = this.playlistChunks.length - 1;

      const isAddedOrRemovedIndexInBeginningOrEnd =
        addedIndex === 0 || addedIndex === lastIndex ||
        removedIndex === 0 || removedIndex === lastIndex;

      const willAdBePlacedInBeginningOrEnd =
        isAddedOrRemovedIndexInBeginningOrEnd &&
        (this.playlistChunks[addedIndex].type === 'source' ||
        this.playlistChunks[removedIndex].type === 'source');

      return willAdBePlacedInBeginningOrEnd;
    },

    /**
     * Make checks to see if the drop was caused from adding
     * a new ad or from a swap in the timeline.
     * Enforce checks and prevent forbidden drops.
     */
    handleOnPlaylistDrop(dragData) {

      const { addedIndex, removedIndex } = dragData;

      if (addedIndex === null) {

        return;
      }

      /**
       * The event is comming from a swap. Make sure that
       * the absoluteOrder of VOD chunks won't change.
       */
      if (removedIndex !== null) {

        const willAbsOrderChange = this.willDropChangeAbsoluteOrder(dragData);

        if (willAbsOrderChange) {

          this.$toasted.error('You can not change the order of the original VOD\'s chunks.');
          return;
        }

        const willAdBePlacedInBeginningOrEnd =
          this.willSwapPlaceAdInBeginningOrEnd(dragData);

        if (willAdBePlacedInBeginningOrEnd) {

          this.$toasted.error('You can not place ads in the beginning or end of a VOD.');
          return;
        }

        /**
         * The drop was not successful or aborted by the user,
         * actual order in the UI hasn't changed. Do nothing.
         */
        if (removedIndex === addedIndex) {

          return;
        }

        this.triggerPlaylistSwap(dragData);

      } else {

        const isItemAddedToBegginingOrEnd =
          addedIndex === 0 || addedIndex === this.playlistChunks.length;

        if (isItemAddedToBegginingOrEnd) {

          this.$toasted.error('You can not add ads to the beginning or end of a VOD.');
          return;
        }

        this.addAdToPlaylist(dragData);
      }
    },

    triggerPlaylistSwap(dragData) {

      console.log('triggerPlaylistSwap', dragData)

      const newChunks = [...this.playlistChunks];

      const movedChunk = dragData.payload.item;


      if (movedChunk.type === 'ad') {

        let affectedIndex = dragData.addedIndex

        if (dragData.removedIndex < dragData.addedIndex) {

          affectedIndex += 1;
        }

        const nearestBreakPointToStart =
          this.getNearestBreakpointToStart(affectedIndex);

        movedChunk.atBreakpoint = nearestBreakPointToStart;
      }

      newChunks.splice(dragData.removedIndex, 1);
      newChunks.splice(dragData.addedIndex, 0, dragData.payload.item);


      const adjustedChunks = this.getAdjustedChunksOffsets(newChunks);

      this.playlistChunks = adjustedChunks;
    },

    addAdToPlaylist(dragData) {

      if (!this.playlistChunks[dragData.addedIndex]) {

        return;
      }

      const ad = dragData.payload.item;

      const atPlacedPositionChunkEnd = this.playlistChunks[dragData.addedIndex].end;

      const adEnd = atPlacedPositionChunkEnd + ad.duration;

      this.totalStagedDuration += ad.duration;

      const newChunks = [...this.playlistChunks];

      const nearestBreakPointToStart =
        this.getNearestBreakpointToStart(dragData.addedIndex);

      newChunks.splice(dragData.addedIndex, 0, {

        ...ad,
        startOffset: atPlacedPositionChunkEnd,
        atBreakpoint: nearestBreakPointToStart,
        end: adEnd
      });


      // NOTE: this check should be tested extensively before the real data is applied! The check should validate if, after the splice, all chunks have valid adjusted offset fields
      const adjustedChunks = this.getAdjustedChunksOffsets(newChunks);

      this.playlistChunks = adjustedChunks;
    },

    /**
     * After a splice to the original array is made, this method
     * should apply adjustments to the chunks, taking into account
     * their new places in the array and their durations.
     */
    getAdjustedChunksOffsets(initialChunks) {

      const adjustedChunks = [];

      let accumulatedOffset = 0;

      for (let i = 0; i < initialChunks.length; i++) {

        const chunk = initialChunks[i];
        const chunkDuration = chunk.duration;

        const adjustedStart = accumulatedOffset;

        accumulatedOffset += chunkDuration;

        adjustedChunks.push({
          ...chunk,
          startOffset: adjustedStart,
          end: accumulatedOffset
        });
      }

      return adjustedChunks;
    },

    setupSeekbar(shouldCancel) {

      const vid = this.$refs.previewVid;

      if (shouldCancel) {

        vid.ontimeupdate = null;
      }

      const updateInterval = 1;

      let prevVal = 0;

      if (vid.ontimeupdate) return;

      vid.ontimeupdate = () => {

        const percentage = ( vid.currentTime / vid.duration ) * 100;

        prevVal += updateInterval;

        this.xPos = percentage;

        this.currentCursorParsedTime =
          this.parseDuration(vid.currentTime * 1000);
      };
    },


    getNearestBreakpointToStart(newIndex) {

      const prevChunk = this.playlistChunks[newIndex - 1];
console.log('prev', prevChunk)
      let nearestBreakPointToStart = prevChunk.type === 'source' ?
        prevChunk.absoluteEnd : prevChunk.atBreakpoint;

      return nearestBreakPointToStart;
    },
    // END Second concept methods


    saveVodAsPlaylistWithAds() {

      if (this.formState.$invalid) {

        return;
      }

      const DTO = FtB_VODChunksToPlaylistWithAds(this.playlistChunks, this.newVodAdPlaylist);

      console.log('Sent to BE: ', DTO);
// return; // NOTE: Since logic to save to the BE is not tested, only display the collected data in the console for now.
      this.makeSavePlaylistRequest(DTO).then(() => {


        this.$toasted.success('VOD with ads saved successfully.');

        this.totalStagedDuration = 0;
        this.possibleBreakPoints = [];
      });

    },

    loadVidPlayback() {

      const video = this.$refs.previewVid;

      if (Hls.isSupported()) {

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }

        this.hls = new Hls({
          // ...this.hlsjsConfig
        });

        this.hls.loadSource(this.selectedVodOriginal.hlsUrl);

        this.hls.attachMedia(video);
      }
    },

    setPlybackPosition(chunk) {

      if (chunk.type === 'ad') {

        return;
      }

      this.$refs.previewVid.pause();

      this.$refs.previewVid.currentTime = chunk.absoluteStart / 1000;

      this.$refs.previewVid.play();

    },

    ...mapMutations({
      // setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED"
    }),
    ...mapActions({
      makeGetVodDetailsRequest: 'channelManager/makeGetVodDetailsRequest',
      makeSavePlaylistRequest: "channelManager/makeSavePlaylistRequest"
    })
  },
  computed: {
    ...mapGetters({

      vodList: "channelManager/vodList",
      stagedVodAdInsertionGuid: "channelManager/stagedVodAdInsertionGuid",
    }),

// deprecate
    markerCount() {
      const totalStagedDurationRounded = (this.totalStagedDuration -
        this.totalStagedDuration % this.markerSize);

      return totalStagedDurationRounded / this.markerSize || 0;
    },
// deprecate
    markerHeight() {

      const adPoints = this.selectedVod.originalObject.adPoints;

      const chunkSizeMs = adPoints.length > 1 ? adPoints[1] - adPoints[0] : adPoints[0];

      return this.cardHeight / (chunkSizeMs / this.markerSize);
    },

    isSaveBtnDisabled() {

      return !this.playlistChunks.length || this.formState.$invalid;
    }

  },

  watch: {

    stagedVodAdInsertionGuid() {
console.log(1, this.stagedVodAdInsertionGuid)
      if (!this.stagedVodAdInsertionGuid) {

        return;
      }

      this.stageVodForAdInsertion(this.stagedVodAdInsertionGuid)
        .then(() => {

          this.loadVidPlayback();
        });
    },

    isVisible() {

      if (this.isVisible) {

        this.seperateFilteredAdsAndVods();
      }
    }
  }
};
</script>

<style lang="scss">


$vodChunkBgColor: #647693;
$adChunkBgColor: #ffa23a;

.smooth-dnd-ghost.ad-draggable-container {

  height: 24px !important;
}

.ad-ghost-element {

  // width: 300px;
  height: 24px;
  background: $adChunkBgColor;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  .fileitem-title {

    margin: 3px auto;
  }

  .img-container, .tags-container { display: none; }
}

.staged-ghost-element {

  height: 24px;
  background: $vodChunkBgColor;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: white;
}

.ad-chunk .staged-ghost-element {

  background: $adChunkBgColor;
}

.vod-ad-playlist-component {

  width: 100%;
  background: var(--windowBgColor);
  color: var(--windowColor);
  overflow: hidden;
  z-index: 2;

  .remove-ad-btn {

    width: 20px !important;
    height: 20px !important;
    margin-top: 2px;
  }

  .dropdown-container {

    margin-left: -25px !important;
  }

  .horizontal-chunks-preview {

    height: 26px;

    li {
      display: inline-block;
      height: 12px;
      background: $vodChunkBgColor;
      border: 1px solid var(--windowBgColor);

      &.ad-chunk {
        background: $adChunkBgColor;
      }
    }
  }

  .fileitem {

    display: flex !important;
    width: 100%;
    height: 70px;
    margin-bottom: 2px;
    border-radius: 0px;
    padding: 0.5rem;
    position: relative;
  }

  // TODO: check for unused styles
  /* timeline related styles */
  .timeline {

    min-width: 250px;
    max-width: 500px;
  }

  .cards {

  }
  .card {
    color: white;

    &.smooth-dnd-draggable-wrapper {
      white-space: nowrap;
    }
  }

  .card:hover {
    z-index: 99;
    box-shadow: 0px 0px 0px 1px var(--color-white),
    0px 0px 0px 2px rgba(0, 0, 0, 0.125), 0px 16px 32px rgba(0, 0, 0, 0.35);
  }

  .cardbg {
    opacity: 0.75;
    background: red;
  }

  .card.active .cardbg,
  .card:hover .cardbg {
    opacity: 1;
  }

  .card.active {
    box-shadow: 0px 0px 0px 2px var(--color-white),
    0px 0px 0px 4px rgba(0, 0, 0, 0.125), 0px 8px 16px rgba(0, 0, 0, 0.25);
    z-index: 9;
  }

  .card.empty {

    background: rgba(255, 255, 255, 0.5);
    border: 1px dashed var(--sceneBrColor);
    border-width: 1px 0;
    border-radius: 0;
  }
  .card.empty:hover {
    background: rgba(255, 255, 255, 0.75);
    opacity: 1;
    box-shadow: none;
  }

  .seek-bar {
    position: absolute;
    left: 0;
    z-index: 9;

    width: 100%;
    height: 2px;
    pointer-events: none;
    transition: transform 0s linear; // the transition-duration is comming from this.playheadTransitionDuration

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -2px;
      width: 100%;
      height: 100%;
      background-color: var(--color-red-400);
    }
  }

  .tag {
    height: 0.25rem;
    margin-right: 1px;
    background: white;
    transition: all 0.125s ease-out;
    opacity: 0.7;
  }

  .ads {
    background: yellow;
    max-width: 1rem;
  }

  .card:hover .tag {
    height: 0.75rem;
  }

  .timeslot {
    --sections: 2;
    // --slot: calc(100% / var(--sections));

    position: relative;
    overflow: hidden;
    border-color: var(--sceneBrColor);
    background: linear-gradient(
    to right,
    transparent,
    transparent calc(var(--slot) - 1px),
    var(--sceneBrColor) var(--slot),
    transparent calc(var(--slot) + 1px),
    transparent calc(var(--slot) * 2 - 1px),
    var(--sceneBrColor) calc(var(--slot) * 2),
    transparent calc(var(--slot) * 2 + 1px),
    transparent calc(var(--slot) * 3 - 1px),
    var(--sceneBrColor) calc(var(--slot) * 3),
    transparent calc(var(--slot) * 3 + 1px),
    transparent calc(var(--slot) * 4 - 1px),
    var(--sceneBrColor) calc(var(--slot) * 4),
    transparent calc(var(--slot) * 4 + 1px),
    transparent calc(var(--slot) * 5 - 1px),
    var(--sceneBrColor) calc(var(--slot) * 5),
    transparent calc(var(--slot) * 5 + 1px),
    transparent 100%
    );
  }

  .timeslot span {
    padding: 0 0 4px;
    line-height: 1.5;
    text-align: right;
  }
  .timeslot:before {
    content: "";
    width: 6px;
    position: absolute;
    bottom: 0;
    right: -3px;
    height: 4px;
    background: red;//var(--sceneBrColor);
    z-index: 3;
  }

  // .timeslot:nth-child(3n) span {
  //   background: red;
  // }

  .timelinebg {
    background: var(--sceneBgColor);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05) inset;
  }





  /* New styles here */

  .chunk {

    &.ad-chunk {

      background-color: $adChunkBgColor;
    }

    &.vod-chunk {

      background-color: $vodChunkBgColor;
    }

    .start-offset-display {

    }

    .chunk-duration {


    }

    .ad-info {
      float: right;
    }
  }

}

</style>
