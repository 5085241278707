<template>
  <div class="thumbnail-component flex-none" style="width: 7.5rem">
    <div class="ratio-16-9">
      <div>
        <tag-component v-if="type === 'ad'" class="fileitem-type" :variant="type">{{ type }}</tag-component>
        <tag-component v-if="type === 'live'" class="fileitem-type" :variant="type">{{ type }}</tag-component>
        <tag-component class="fileitem-duration" variant="duration" size="size-s">{{ duration }}</tag-component>
        <div v-if="image" class="flex-none" style="width: 100%; height: 100%" :style="{ background:`url('${image}') no-repeat center center / cover` }"></div>
        <div v-else class="flex-none flex items-center justify-around bg-gray+4" style="width: 100%; height: 100%">
          <ioio-icon icon="far-image" class="w-6 h-6 text-black-400"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  props: {
    type: {
      type: String,
    },
    duration: {
      type: String,
      default: "N/A"
    },
    image: {
      type: String,
      default: ""
    }
  },
}
</script>

<style lang="scss" scoped>




.fileitem-image { position: relative;}

.fileitem-type {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  z-index: 2;
}
.fileitem-duration {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  z-index: 2;
}




</style>
