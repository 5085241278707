<template>
  <ioio-modal
    v-if="isCropModalOpened"
    externalClasses="configure-cropped-images-for-vod-modal"
    :show="isCropModalOpened"
    size="large"
    noScroll
    noClose
  >
    <section class="flex h-full">

      <section class="images-list-section h-full flex flex-auto flex-column border-right p-12">
        <header class="flex justify-between items-center mb-6">

          <h1 class="mr-12 text-2xl">Images</h1>
          <input-search-component
            :onSearchInput="handleInputSearch"
            :threshold="500"
            size="small"
            class="input-search"
            placeholder="Search in the image library"
          />

        </header>
        <section class="grid grid-cols-4 grid-rows-5 gap-4 pr-2 mb-8 img-grid h-full overflow-auto" v-if="!isRequestPending">

          <div class="items-center img-item overflow-hidden mb-2 rounded cursor-pointer"
            v-for="img in imagesList"
            @click="stageFileForCrop(img._source)"
            :key="img._source.guid"
            :class="{ 'selected': stagedImg && stagedImg.guid === img._source.guid }"
            :style="{ background:`url(${img._source.cdnPath}?d=160x90&q=80&f=jpeg) no-repeat center center / contain` }">

            <div class="img-meta w-full h-full text-xs p-4">
              <h6 class="img-title truncate mb-2">
                {{ img._source.meta && img._source.meta.title || img._source.originalFileName }}
              </h6>
              <p>
                {{ img._source.width }}x{{ img._source.height }}
              </p>
            </div>
          </div>
        </section>

        <div v-else class="relative h-full">
          <ioio-loader isShown />
        </div>

        <footer
          class="flex"
        >

          <ioio-dropdown
            class="per-page-items-select mr-4"
            :items="[{ value: 20, label: 20 }, {value: 50, label: 50 }, { value: 100, label: 100 }]"
            valueProp="value"
            titleProp="label"
            v-model="elasticOptions.perPageItems"
            size="small"
            menuTopPlacement
            @input="onPerPageItemsChange"
          />

          <pagination-component
            :page-selected="elasticOptions.currentPage"
            :click-callback="onPaginationClicked"
            :per-page-items="elasticOptions.perPageItems"
            :totalItemsLength="filteredItemsCount"
          />
        </footer>
      </section>

      <aside class="crop-details-section h-full p-12 flex flex-column flex-auto">

        <header class="flex items-center justify-between mb-4">

          <h3 class="truncate mr-4">{{ selectedImgHeaderText }}</h3>
          <button
            class="ds-modal__close static"
            type="button"
            @click="cropperSrc ? cancelImageSelection() : cancelAllChanges()"
          >
            <ioio-icon icon="fal-times" />
          </button>
        </header>
        <section class="form-section flex flex-column flex-auto">
          <section v-if="cropperSrc" class="cropper-section flex flex-auto flex-column">

            <section class="cropper-holder w-full mb-4">
              <cropper
                class="cropper"
                :src="cropperSrc"
                ref="cropper"
                :checkOrientation="false"
                @ready="() => setStencilCoordinatesPreset(selectedStencilPreset, preselectedSize)"
                @change="onCoordsChange"
                :stencil-props="selectedStencilPreset.props"
              />
              <div class="dimensions-box rounded-sm text-xs text-white p-1">
                {{ selectedWidth }} : {{ selectedHeight }}
              </div>

            </section>

            <section class="form-section flex flex-column flex-auto overflow-auto pr-2">

              <ioio-dropdown
                v-if="!isLogo"
                topic="cropImgForm"
                label="Type"
                fullWidth
                :items="possibleCoordsPresets"
                titleProp="label"
                valueProp="name"
                v-model="selectedPresetName"
                @input="onPresetSelect"
                size="small"
                class="mb-4"
              />

              <ioio-field
                topic="cropImgForm"
                label="Width"
                type="number"
                required
                size="small"
                placeholder="Width"
                v-model="selectedWidth"
                @input="onWidthHeightInputChange"
                class="mb-4"
              />

              <ioio-field
                topic="cropImgForm"
                label="Height"
                type="number"
                required
                size="small"
                placeholder="Height"
                v-model="selectedHeight"
                @input="onWidthHeightInputChange"
                class="mb-4"
              />

              <ioio-field
                v-if="!isLogo"
                topic="cropImgForm"
                label="Title"
                type="text"
                required
                size="small"
                placeholder="Title"
                v-model="stagedImg.meta.title"
                class="mb-4"
              />

              <ioio-field
                v-if="!isLogo"
                type="textarea"
                topic="editEventForm"
                size="small"
                class="mb-4"
                label="Description"
                placeholder="Description"
                :maximum="100"
                rows="2"
                v-model="stagedImg.meta.description"
              ></ioio-field>

              <ioio-dropdown
                v-if="!isLogo"
                topic="cropImgForm"
                label="Language"
                fullWidth
                :items="possibleLanguages"
                titleProp="label"
                valueProp="value"
                v-model="stagedImg.meta.lang"
                size="small"
                class=""
              />

              <h6 v-if="isLogo" class="text-xs text-black-400 mt-4">Recommended aspect ratio of the oraganization logo is 16/9.
                The preset values of width and height you see above follow this aspect ratio.</h6>

            </section>

            <footer class="flex justify-end mt-4">

              <ioio-button
                v-if="preselectedSize && !isLogo"
                type="primary"
                variant="solid"
                class="mr-4"
                size="small"
                @click="() => applyChangesToStagedImage(updateIndex)"
              >Edit
              </ioio-button>

              <ioio-button
                type="primary"
                variant="outline"
                size="small"
                class="mr-4"
                @click="() => applyChangesToStagedImage(null)"
              >{{ preselectedSize ? 'Add as new' : 'Add' }}
              </ioio-button>

              <ioio-button
                type="secondary"
                variant="outline"
                size="small"
                @click="cancelImageSelection"
              >Cancel
              </ioio-button>
            </footer>

          </section>

          <section
            v-else-if="!isLogo"
            class="flex flex-column flex-auto"
          >
            <assigned-images-to-vod-component
              class="flex flex-column flex-auto"
              :stagedImages="stagedImages"
              :openEditForStagedImg="openEditForStagedImg"
              :removeStagedImg="removeStagedImg"
            />

            <footer class="flex justify-end mt-4">

              <ioio-button
                type="primary"
                variant="solid"
                class="mr-4"
                size="small"
                @click="appyAllChanges"
              >Apply all
              </ioio-button>

              <ioio-button
                type="secondary"
                variant="outline"
                size="small"
                @click="cancelAllChanges"
              >Cancel
              </ioio-button>
            </footer>
          </section>

        </section>
      </aside>
    </section>
  </ioio-modal>

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  convertObjectKeyValuesToArr,
  validateIoFormAndGetErrors
} from "@utils/helpers";

import { languagesTwoLetterConfig } from "@utils/constants";

export default {
  data: () => ({
    searchWord: '',
    filteredItemsCount: 0,
    isRequestPending: false,
    imagesList: [],

    elasticOptions: {

      currentPage: 1,
      perPageItems: 20
    },
    selectedPresetName: 'wide',
    selectedWidth: 0,
    selectedHeight: 0,
    stagedImg: null,
    cropperSrc: '',
    preselectedSize: null,
    updateIndex: null,
    possibleCoordsPresetsMap: {},

    possibleCoordsPresets: [{
      name:'poster',
      label: 'Poster',

      props: {
        // Will only be used if frameWidth and
        // frameHeight are NOT present in the editedVod data
        aspectRatio: 1
      }
    },
    {
      name:'thumbnail',
      label: 'Thumbnail',

      props: {
        // Will only be used if frameWidth and
        // frameHeight are NOT present in the editedVod data
        aspectRatio: 1
      }
    },
    {
      name:'portrait',
      label: 'Portrait',

      props: {
        aspectRatio: 2/3
      }
    },
    {
      name:'square',
      label: 'Square',

      props: {
        aspectRatio: 1
      }
    },
    {
      name:'wide',
      label: 'Wide',

      props: {
        aspectRatio: 16/9
      }
    },
    {
      name:'ultrawide',
      label: 'Ultrawide',

      props: {
        aspectRatio: 21/9
      }
    },
    {
      name:'custom',
      label: 'Custom',

      props: {
        // Uncomment if ratio should be set for custom as well
        // aspectRatio: 6/2
      }
    }],
    selectedStencilPreset: {

      name:'wide',
      label: 'Wide',

      props: {
        aspectRatio: 16/9
      }
    },
    possibleLanguages: convertObjectKeyValuesToArr(languagesTwoLetterConfig),

  }),
  props: {
    toggleCropModalOpened: Function,
    isModalOpened: Boolean,
    getSearchOptions: Function,
    editedVod: {
      type: Object,
      default: () => ({})
    },
    cancelAllChanges: Function,
    stagedImages: {
      type: Array,
      default: () => []
    },
    removeStagedImg: Function,
    appyAllChanges: Function,
    isLogo: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {

    this.configurePresetMap();
    this.loadImagesElastic();
  },
  methods: {

    handleInputSearch(input) {

      this.searchWord = input;

      this.elasticOptions.currentPage = 1;

      this.loadImagesElastic();
    },

    onPerPageItemsChange() {

      this.elasticOptions.currentPage = 1;

      this.loadImagesElastic();
    },

    onPaginationClicked(pageNum) {

      this.elasticOptions.currentPage = pageNum;

      this.loadImagesElastic();
    },

    loadImagesElastic() {

      const searchOptions = this.getSearchOptions({

        searchWord: this.searchWord,
        elasticOptions: this.elasticOptions
      });

      this.isRequestPending = true;

      this.getImagesElastic(searchOptions)
        .then((response) => {

          this.filteredItemsCount = response.hits.total.value;

          this.imagesList = response.hits.hits;
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    configurePresetMap() {

      const map = {};

      for (let i = 0; i < this.possibleCoordsPresets.length; i++) {

        map[this.possibleCoordsPresets[i].name] = i;
      }

      this.possibleCoordsPresetsMap = { ...map };
    },
    openEditForStagedImg(index, stagedImage = null) {

      if (!stagedImage)  {
        stagedImage = this.stagedImages[index];
      }

      this.updateIndex = index;

      const mappedFile = {
        meta: stagedImage,
        cdnPath: stagedImage.absoluteUrl
      };

      const prevSavedStencilPreset =
        this.possibleCoordsPresets.find(p => p.name === stagedImage.variant);

      const { width, height, top, left } = stagedImage;

      const preselectedSize = {
        width, height, top, left
      };

      this.preselectedSize = preselectedSize;

      this.setStencilCoordinatesPreset(prevSavedStencilPreset, preselectedSize);

      this.stageFileForCrop(mappedFile, 'isEditing');
    },

    setStencilCoordinatesPreset(preset, preselectedSize) {

      const newPreset = {
        ...preset
      };

      // The aspect ratio should be based on the video
      if (preset && (preset.name === 'poster' || preset.name === 'thumbnail')) {

        if (this.editedVod.frameWidth && this.editedVod.frameHeight) {

          newPreset.props.aspectRatio =
            this.editedVod.frameWidth / this.editedVod.frameHeight;
        }
      }

      preset ? this.selectedStencilPreset = newPreset : null;

      // Center the viewfinder. setTimeout is needed, since the
      // new coordinates aren't available immediately, when stencil
      // preset is changed
      setTimeout(() => {

        // First enlarge the viewFinder as much as it will go,
        // based on the img size and the newly set aspect ratio
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => {

          if (preselectedSize) {

            return ({
              width: preselectedSize.width,
              height: preselectedSize.height
            });
          }

          return ({
            width: imageSize.width,
            height: imageSize.height
          });
        });

        // Center the viewFinder
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => {

          if (preselectedSize) {

            return ({

              left: preselectedSize.left,
              top: preselectedSize.top,
            });
          }

          return ({
            left: imageSize.width / 2 - coordinates.width / 2,
            top: imageSize.height / 2 - coordinates.height / 2,
          })
        });
      }, 0);
    },

    stageFileForCrop(img, isEditing) {

      if (img.format === 'svg') {

        this.$toasted.error('SVG format type is not supported for image cropping.');

        return;
      }

      if (!isEditing) {

        this.preselectedSize = null;
      }

      if (!img) {

        this.stagedImg = null;

        return;
      }

      this.stagedImg = {
        ...img,
        meta: {
          lang: 'EN', //default to EN if not selected
          ...img.meta
        }
      };

      this.cropperSrc = `${img.cdnPath}?f=jpeg`;
    },

    cancelImageSelection() {

      this.cropperSrc = '';

      this.stageFileForCrop(false);
    },


    onPresetSelect(newVal) {

      const presetIndex = this.possibleCoordsPresetsMap[newVal];

      const selectedPreset = this.possibleCoordsPresets[presetIndex];

      this.setStencilCoordinatesPreset(selectedPreset);
    },

    onWidthHeightInputChange(newVal) {

      const customPreset = this.possibleCoordsPresets[this.possibleCoordsPresets.length - 1];

      this.setStencilCoordinatesPreset(customPreset, {
        width: this.selectedWidth,
        height: this.selectedHeight,
        top: this.$refs.cropper.coordinates.top,
        left: this.$refs.cropper.coordinates.left
      });
    },
    onCoordsChange({ coordinates }) {

      this.selectedWidth = coordinates.width;
      this.selectedHeight = coordinates.height;
		},
    applyChangesToStagedImage(updateIndex) {

      if (validateIoFormAndGetErrors('cropImgForm').length) {

        return;
      }

      const { coordinates } = this.$refs.cropper.getResult();

      const { width, height, left, top } = coordinates;

      const imgDto = {

        width,
        height,
        left,
        top,
        title: this.stagedImg.meta.title,
        description: this.stagedImg.meta.description,
        lang: this.stagedImg.meta.lang,
        variant: this.selectedStencilPreset.name, // [enum - poster	thumbnail	portrait	square	wide	ultra-wide [custom - NOT for Brightcove]	]
        absoluteUrl: this.stagedImg.cdnPath,
        url: `${this.stagedImg.cdnPath}?e=${left},${top},${width},${height}`
      };

      this.$emit('changeStageImg',imgDto,updateIndex);

      this.updateIndex = null;

      this.cancelImageSelection();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getImagesElastic: "images/getImagesElastic",
    })
  },
  computed: {

    isCropModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(value) {

        if (!value) {

          this.stagedImg = null;
          this.toggleCropModalOpened(value);
        };

      }
    },
    selectedImgHeaderText() {

      const selectedImageTitle = this.stagedImg &&
        (this.stagedImg.meta.title || this.stagedImg.originalFileName);

      return selectedImageTitle || 'Select an image to crop';
    },
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
