/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-next': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M17.581 11.187l-14-10a1.005 1.005 0 00-1.039-.077A1.001 1.001 0 002 2v20a1.001 1.001 0 001 1 .988.988 0 00.581-.187l14-10a.997.997 0 000-1.626z"/><path pid="1" data-color="color-2" d="M21 0a1 1 0 00-1 1v22a1 1 0 102 0V1a1 1 0 00-1-1z"/></g>'
  }
})
