<template>
<div class="readable-time-component">
  {{ timestamp ? moment.tz(timestamp, localTimezone).format('MMM Do YYYY h:mma z') : '--' }}
</div>
</template>

<script>

import moment from "moment-timezone";

import {
  getLocalTimezone
} from "@utils/helpers";

export default {
  data: () => ({

    localTimezone: ''
  }),
  props: {
    timestamp: String
  },

  created() {

    this.moment = moment;

    this.localTimezone = getLocalTimezone();
  },

}
</script>

<style lang="scss">


</style>
