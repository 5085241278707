<template>
  <div v-if="isValidObject" class="json-preview-component">
    <div class="flex items-center my-4">
      <h3 class="">Settings</h3>
      <ioio-tooltip :text="expand ? 'Collapse settings' : 'Expand settings'">
        <ioio-icon
          class="text-black-600 cursor-pointer mx-2"
          :icon="expand ? 'fas-caret-down' : 'fas-caret-right'"
          @click="expandSettings"
        />
      </ioio-tooltip>
    </div>
    <div
      class="flex items-center justify-end w-max bg-black-100 sticky top-2 ml-auto -mt-11 rounded"
    >
      <ioio-field
        v-model="searchText"
        type="text"
        placeholder="Find"
        size="small"
        class="mr-2"
        isSearch
        :hasClearBtn="true"
        :threshold="500"
        @onChange="highlightText"
      />
      <span class="text-black-300 text-xs mx-1">{{
        highlightColection.length > 0
          ? highlightedElement + 1 + " of " + highlightColection.length
          : "No results"
      }}</span>
      <ioio-icon
        class="text-black-600 cursor-pointer mx-2"
        icon="arrow-up"
        @click="previousHighlightText"
      />
      <ioio-icon
        class="text-black-600 cursor-pointer mx-2"
        icon="arrow-down"
        @click="nextHighlightText"
      />
    </div>

    <div v-html="generateHTML(this.jsonData)" id="content" class="mt-4"></div>
  </div>
  <div v-else>
    <h3 class="mt-8 text-red-400">
      We can't show you the settings because they are not in a valid format!
    </h3>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    expand: false,
    searchText: "",
    highlightColection: [],
    highlightedElement: 0,
  }),
  props: {
    jsonData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    expandSettings() {
      this.expand = !this.expand;
      const content = document.getElementById("content");
      const elements = content.getElementsByTagName("details");
      Array.from(elements).map((el) => (el.open = this.expand));
    },
    highlightText(e) {
      // Remove previous highlights
      this.removeHighlights();
      this.highlightColection = [];
      this.expand = true;

      if (this.searchText !== "") {
        let text = document.getElementById("content");
        let re = new RegExp(this.searchText, "gi"); // search for all instances
        let tags = text.getElementsByClassName("generate");
        Array.from(tags).forEach((tag) => {
          let newText = tag.innerHTML.replace(re, function (str) {
            return `<mark>${str}</mark>`;
          });
          tag.innerHTML = newText;
        });

        this.highlightColection = document
          .getElementById("content")
          .getElementsByTagName("mark");
        const firstHighlight = document
          .getElementById("content")
          .getElementsByTagName("mark")
          .item(0);

        if (firstHighlight) {
          this.openDetails(firstHighlight);
          firstHighlight.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          this.highlightedElement = 0;
        } else {
          this.highlightColection = [];
          this.expandSettings();
        }
      } else {
        this.highlightColection = [];
        this.expandSettings();
      }
    },
    removeHighlights() {
      const content = document.getElementById("content");
      const highlightedElements = content.getElementsByTagName("mark");

      if (highlightedElements.length) {
        Array.from(highlightedElements).forEach((element) => {
          const parent = element.parentNode;
          parent.replaceChild(element.firstChild, element);
        });
      }
    },
    nextHighlightText() {
      if (this.highlightColection.length) {
        this.highlightColection.length - 1 > this.highlightedElement
          ? this.highlightedElement++
          : null;
        const firstHighlight = this.highlightColection.item(
          this.highlightedElement
        );
        this.openDetails(firstHighlight);
        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },

    previousHighlightText() {
      if (this.highlightColection.length) {
        this.highlightedElement > 0 ? this.highlightedElement-- : null;
        const firstHighlight = this.highlightColection.item(
          this.highlightedElement
        );
        this.openDetails(firstHighlight);
        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    openDetails(el) {
      const parent = el.parentElement;

      if (parent.id === "content") {
        return;
      } else if (parent.tagName.toLowerCase() === "details") {
        parent.open = true;
        this.openDetails(parent);
      } else {
        this.openDetails(parent);
      }
    },
    generateHTML(data) {
      let html = "";
      // Check if the data is Array
      if (Array.isArray(data)) {
        data.forEach((el) => {
          if (typeof el === "object") {
            html += this.generateHTML(el);
          } else {
            html += `<div class="text-red-400 text-sm my-2 pl-4 generate"> ${el}</div>`;
          }
        });
      } else {
        // Iterate over each property in the object
        for (let key in data) {
          if (typeof data[key] === "object") {
            // If the property is an object, generate a details

            html += '<details class="pl-4 pt-2 mb-2">';
            html += `<summary class="text-sm text-black-600 mb-2 generate">${key}</summary>`;
            html += `<div class="my-2">${this.generateHTML(data[key])}</div>`;
            html += `</details>`;
          } else {
            // If the property is text, display it as a span
            html += `<div class="flex mt-2 mb-2 pl-4"> <span class="text-sm text-black-600 mr-1 generate">${key}:</span> <span class="text-sm text-red-400 generate">${data[key]}</span></div>`;
          }
        }
      }

      return html;
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    }),
  },
  computed: {
    isValidObject() {
        
      return typeof this.jsonData === "object";
    },
    ...mapGetters({
     
    }),
  },
  watch: {
    jsonData() {
      this.searchText = '';
    } 
  }
};
</script>

<style lang="scss"></style>
