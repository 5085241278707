/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_list_outlined': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 10v3.333h30V10H5zm11.667 20h6.666v-3.333h-6.666V30zM30 21.667H10v-3.334h20v3.334z" _fill="#4F4F4F"/>'
  }
})
