<template>
  <div class="library-vod-meta-data-component h-full flex flex-column flex-auto">
    <ioio-logical-edit
      @changed="onLogicalEditChange"
      :detectChanges="!isFormDirty"
      class="edit-meta-panel flex flex-column flex-auto"
    >

      <header class="flex justify-between mb-6">
        <h3 class="mt-2 flex-auto truncate">Add Meta to Clipped VOD</h3>

        <ioio-button
          v-if="!isClippingInProgress"
          type="minimal"
          size="small"
          @click="toggleMetaDataOpened(false)">
          <ioio-icon
            icon="fa-solid fa-xmark"
          />
        </ioio-button>
      </header>

      <ioio-tabs
        class="upload-details-tabs flex flex-auto flex-column"
        @input="setSelectedTabName"
        ref="tabForm">

        <ioio-tab
          name="General"
          :selected="true"
          class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
        >

          <ioio-field
            label="Title"
            type="text"
            required
            topic="cropVod"
            size="medium"
            placeholder="Title of video"
            v-model="editDataFields.title"
            class="mb-4"
          />

          <ioio-field
            label="Description"
            type="textarea"
            topic="cropVod"
            size="medium"
            placeholder="Description"
            v-model="editDataFields.description"
            class="mb-4"
          />


          <ioio-toggle
            size="medium"
            labelRight="Encode"
            topic="cropVod"
            v-model="editDataFields.encode"
          />

          <div class="text-xs text-blue-grey-400 mt-2 mb-4">All videos will get encoded after uploading</div>


          <ioio-dropdown
            topic="cropVod"
            label="Type"
            fullWidth
            :items="possibleVodTypes"
            titleProp="label"
            valueProp="value"
            v-model="editDataFields.type"
            size="medium"
            class="mb-5"
          />

          <ioio-radio
            topic="cropVod"
            class="w-full mb-5"
            :options="accessPrivacyOptions"
            name="privacy"
            size="medium"
            v-model="editDataFields.privacy"
            label="Privacy"
            disclaimer="Set who this video is available to"
          />

        </ioio-tab>

        <ioio-tab name="Details" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

          <ioio-tags
            topic="cropVod"
            size="medium"
            label="Categories"
            disclaimer="Manage videos and improve visibility"
            class="mb-5"
            v-model="editDataFields.categories"
            :tags="editDataFields.categories"
          />

          <ioio-tags
            topic="cropVod"
            size="medium"
            label="Tags"
            disclaimer="Identify different video assets"
            class="mb-5"
            v-model="editDataFields.tags"
            :tags="editDataFields.tags"
          />

          <ioio-field
            label="Group"
            type="text"
            topic="cropVod"
            size="medium"
            placeholder="Group"
            v-model="editDataFields.group"
            class="mb-5"
          />

          <ioio-field
            label="Genre"
            type="text"
            topic="cropVod"
            size="medium"
            placeholder="Genre"
            v-model="editDataFields.genre"
            class="mb-5"
          />


          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900 mb-1">Featured video</div>

              <div class="text-xs text-blue-grey-400">This video will appear as featured in hubs</div>
            </aside>

            <ioio-toggle
              size="medium"
              topic="cropVod"
              v-model="editDataFields.featured"
              :labelLeft="editDataFields.featured ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">
            <aside>
              <div class="text-sm text-black-900 mb-1">List in Video Portals</div>

              <div class="text-xs text-blue-grey-400">This video will appear in video portal results</div>
            </aside>

            <ioio-toggle
              size="medium"
              topic="cropVod"
              v-model="editDataFields.listedInPortals"
              :labelLeft="editDataFields.listedInPortals ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

        </ioio-tab>

        <ioio-tab name="Hubs" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 pt-2">
          <library-vod-hubs-tab-edit-component
            :editedVodMeta="editDataFields"
          />
        </ioio-tab>

        <ioio-tab name="Subtitles" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2">

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Auto subtitles</div>

            <ioio-toggle
              size="small"
              topic="cropVod"
              v-model="editDataFields.autoSubs"
              :labelLeft="editDataFields.autoSubs ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Auto translation</div>

            <ioio-toggle
              size="small"
              topic="cropVod"
              :disabled="!editDataFields.autoSubs"
              v-model="editDataFields.autoTranslate"
              :labelLeft="editDataFields.autoTranslate ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <div v-if="editDataFields.autoTranslate" class="mb-5 text-black-500">
            <h5
              v-if="defaultData['caption-auto-translate-languages'].length"
              class="mb-3">
              The subtitles of this video will be automatically translated into the following languages:
            </h5>
            <h5
              v-else
              class="text-red-500">
              You don’t have any auto translate languages defined in Settings!
            </h5>
            <div
              v-for="lang,i in defaultData['caption-auto-translate-languages']"
              :key="i">
              {{ languagesTranslateConfig[lang] }}
            </div>
          </div>

          <div class="mb-5 text-black-500 flex justify-between">

            <div class="text-sm text-black-900">Medical transcription</div>

            <ioio-toggle
              size="small"
              topic="cropVod"
              :disabled="!editDataFields.autoSubs"
              v-model="editDataFields.medicalTranscribe"
              :labelLeft="editDataFields.medicalTranscribe ? 'On' : 'Off'">
            </ioio-toggle>
          </div>

          <ioio-dropdown

            v-if="editDataFields.medicalTranscribe"
            topic="cropVod"
            label="Select type"
            fullWidth
            :items="possibleMedicalTranscriptionTypes"
            titleProp="label"
            valueProp="value"
            v-model="editDataFields.medicalTranscribeType"
            size="small"
            class="mb-5"
          />
        </ioio-tab>
      </ioio-tabs>
    </ioio-logical-edit>
    <footer class="drawer-footer mt-8" v-if="!isClippingInProgress">
      <article class="flex" >
        <button-component
          variant="primary"
          class="mr2"
          @click="onMetaSaved"
        >Create Video
        </button-component>
        <button-component
          variant="default"
          @click="toggleMetaDataOpened(false)"
        >Cancel</button-component>
      </article>
    </footer>
    <div class="relative min-h-10" v-else>
      <ioio-loader isShown />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { validateTabsFormAndHighlight } from "@utils/helpers";
import {languagesTranslateConfig} from "@utils/constants";

export default {
  data: () => ({
    name: 'LibraryVodMetaData',
    possibleMedicalTranscriptionTypes: [
      {
        label: 'Dictation',
        value: 'dictation'
      }, {
        label: 'Conversation',
        value: 'conversation'
      }
      ],
    defaultData: {
      encode: false,
      autoSubs: false,
      autoTranslate: false,
      medicalTranscribeType: 'dictation',
      medicalTranscribe: false,
    },
    editDataFields: {},
    selectedTabName: '',
    isFormDirty: false,
    isClippingInProgress: false,
    languagesTranslateConfig,
    accessPrivacyOptions: [
      { "value":"public",
        "label":"Public",
        "disclaimer":"Available to everyone whitin your organization"
      },
      { "value":"private",
        "label":"Private",
        "disclaimer":"Available only to you"
      }
    ]
  }),
  props: {},
  created() {

    this.getOrganizationsSettings();
  },

  methods: {
    onMetaSaved() {

      if (validateTabsFormAndHighlight(this.$refs.tabForm, 'cropVod')) {

        return;

      } else {

        this.setRedirectGuard(false);
      }

      this.isClippingInProgress = true;

      const editDataFields = this.editDataFields;

      let meta = {

        title: editDataFields.title,
        type: editDataFields.type,
        user: this.getUserData,
        origin: this.getOriginData,
      };

      editDataFields.description && (meta.description = editDataFields.description);

      editDataFields.encode ? (meta.encodeLater = '0') : (meta.encodeLater = '1');

      editDataFields.privacy === 'private' ? (meta.privacy = true) : (meta.privacy = false);


      // Details tab fields
      const areCategoriesSelected =
        editDataFields.categories && editDataFields.categories.length;

      areCategoriesSelected && (meta.categories = editDataFields.categories);


      const areTagsSelected =
        editDataFields.tags && editDataFields.tags.length;

      areTagsSelected && (meta.tags = editDataFields.tags);


      editDataFields.group && (meta.group = editDataFields.group);

      editDataFields.genre && (meta.genre = editDataFields.genre);

      editDataFields.featured && (meta.featured = true);

      editDataFields.listedInPortals && (meta.listedInPortals = true);



      // Hubs tab fields

      const areHubsSelected =
        editDataFields.hubs && editDataFields.hubs.length;

      areHubsSelected && (meta.hubs = editDataFields.hubs);



      // Subtitles tab fields
      editDataFields.autoSubs ? (meta.autoSubs = '1') : (meta.autoSubs = '0');

      editDataFields.autoTranslate ? (meta.autoTranslate = '1') : (meta.autoTranslate = '0');

      if (editDataFields.medicalTranscribe) {

        meta.medicalTranscribe = '1';
        meta.medicalTranscribeType = editDataFields.medicalTranscribeType;

      } else {

        meta.medicalTranscribe = '0';
      }

      /**
       * There are problems, uploading NON ASCII strings to S3 in the meta.
       * The makeUploadFileMetaRequest makes it possible to first upload
       * the meta, regardless of the strings in it, then the main upload
       * only requires metaGuid
       */

      this.makeUploadFileMetaRequest({ meta })
      .then((data) => {

        this.$emit('meta',data);
        // Reestablish the original videos not crop guard,
        // since it was overwritten by the metaDataForm guard
        this.setVideosNotCropGuard();
      })
      .finally(() => {

        this.isClippingInProgress = false;
      });
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;
    },
    toggleMetaDataOpened(newVal) {


      // raise guard logic for edit changes not applied
      if (this.isActiveConfirmGuardSet && this.isFormDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            // Reestablish the original videos not crop guard,
            // since it was overwritten by the metaDataForm guard
            this.setVideosNotCropGuard();
            this.isFormDirty = false;
            this.$emit('close', newVal);
            this.editDataFields = {};
          }
        });

        this.raiseRedirectFlag(true);

        return;
      }

      // The check above has passed, so the form state should be nulled
      this.isFormDirty = false;
      this.$emit('close', newVal);
      this.editDataFields = {};
    },

    setupEditData() {
      // default to empty fields
      let vodData = {

        type: 'source',
        encode: !this.defaultData['encode-later'],
        autoSubs: this.defaultData['auto-subs'],
        autoTranslate: this.defaultData['auto-translate'],
        medicalTranscribeType: this.defaultData['medical-transcribe-type'],
        medicalTranscribe: this.defaultData['medical-transcribe']
      };

      this.editDataFields = {
        ...vodData,
        privacy: vodData.privacy === 'true' ? 'private' : 'public',
        tags: vodData.tags ? [...vodData.tags] : [],
        categories: vodData.categories ? [...vodData.categories] : [],
        hubs: vodData.hubs ? [...vodData.hubs] : []
      };
    },

    getOrganizationsSettings() {

      this.getBrokerSettings('').then((defaultData) => {

        this.defaultData = defaultData.reduce((acc, item) => {

          acc[item.guid] = item.value;
          return acc;
        },{} );
        if (!this.defaultData['auto-subs']) {
          this.defaultData['auto-translate'] = false;
        };
      })
      .then(() => {
        this.setupEditData()
      });
    },

    onLogicalEditChange(a) {

      this.setRedirectGuard({
        redirectMsg: 'The data you entered are not applied.',
        redirectSecondaryMsg: 'Are you sure you want to cancel?'
      });

      this.isFormDirty = true;
    },

    setVideosNotCropGuard() {

      this.setRedirectGuard({
        redirectMsg: 'Your video will not be clipped if you proceed.',
        redirectSecondaryMsg: 'Are you sure?'
      });

    },
    ...mapMutations({
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
      setRedirectGuard: 'app/SET_REDIRECT_GUARD',
    }),
    ...mapActions({
      getBrokerSettings: 'channelManager/getBrokerSettings',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
      makeUploadFileMetaRequest: "channelManager/makeUploadFileMetaRequest",
    })
  },
  computed: {
    ...mapGetters({
      possibleVodTypes: 'channelManager/possibleVodTypesArr',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',
      appName: 'app/appName',
      appVersion: 'app/appVersion',
      userData: 'app/userData',
    }),
    getUserData() {

      return this.userData;
    },
    getOriginData() {

      return {

        type: this.appName,
        appVersion: this.appVersion,
        userAgent: window.navigator.userAgent, // any versioning of the origination
        source: "upload", //recording, import, upload
      };
    },

  },

  watch: {
    'editDataFields.autoSubs'() {
      if (!this.editDataFields.autoSubs) {
        this.editDataFields.autoTranslate = false;
      }
    }
  }
}
</script>

<style lang="scss">
  .library-vod-meta-data-component{
    @apply
    p-4
    pr-1
    pb-2;

    .drawer-footer {
      @apply
      flex
      pb-3
      pr-3
      justify-end;
    }
  }

</style>
