<template>
<div
class="live-preview-panel-component pane border-left flex flex-column flex-none"
:class="activeToolbarPanel !== 'livePreview' ? 'hide' : 'width+3'"
>
  <section
    v-if="channelsMeta.selected || singleSourcePlayed"
    :class="{ 'live-channel-playing' : !singleSourcePlayed }">
    <div class="pane-header m0 p2 flex items-center flex-none">

      <div v-if="!singleSourcePlayed" class="flex flex-auto items-center justify-between">
        <h4 class="caps m0 size-2">Channel Preview</h4>
        <button-component
          @click="openInsightsOverview"
          variant="default"
          class=""
          size="size-xs"
          style="width: 100px"
        >Insights
        </button-component>
      </div>
      <h4 v-else class="caps m0 size-2">Video Preview</h4>

      <button-component v-if="false" class="ml-auto flex-none disabled" variant="subtle" shape="circle">
        <ioio-icon icon="fas-ellipsis" class="h-4"/>
      </button-component>
    </div>
    <video
      v-show="videoPlaybackUrl"
      ref="previewVid"
      id="video-container"
      width="100%"
      height="236"
      controls
      :poster="channelsMeta.selected.imageUrl">
    </video>


    <div class="pane-content flex-auto" style="overflow: auto">

      <div v-if="singleSourcePlayed" class="p2 flex flex-column h100">

        <div v-if="singleSourcePlayed.status === 'encode-later'" class="mb2">
          <h4>This video has not yet been encoded.</h4>
          <button-component
            @click="triggerEncodeFileRequest(singleSourcePlayed)">Encode Now
          </button-component>
        </div>
        <div v-if="singleSourcePlayed.status === 'ingest'" class="mb2">
          <h4>This video is encoding.</h4>
        </div>

        <div>
          <tag-component :variant="singleSourcePlayed.meta.type" v-if="singleSourcePlayed.meta.type">{{ possibleVodTypes[singleSourcePlayed.meta.type].label }}</tag-component>
        </div>

        <h3 class="m0 mb1 truncate">{{ singleSourcePlayed.meta.title }}</h3>
        <section v-if="singleSourcePlayed.meta.description">

          <h5 class="size-5 caps ls0 gray">description</h5>
          <p class="size-2 preview-description">{{ singleSourcePlayed.meta.description }}</p>
        </section>

        <h5 class="size-5 caps ls0 gray">group</h5>
        <tag-component v-if="singleSourcePlayed.meta.group">{{ singleSourcePlayed.meta.group }}</tag-component>
        <!-- NOTE: adding button component here in case we decide to allow user to modify data inline from preview -->
        <span class="size-2" v-else>This video does not belong to a group.<button-component class="hide"  variant="subtle">Add Group</button-component></span>
        <h5 class="size-5 caps ls0 gray">genre</h5>
        <tag-component v-if="singleSourcePlayed.meta.genre">{{ singleSourcePlayed.meta.genre }}</tag-component>
        <!-- NOTE: adding button component here in case we decide to allow user to modify data inline from preview -->
        <span class="size-2" v-else>No genre specified. <button-component class="hide" variant="subtle">Add Genre</button-component></span>

        <div class="flex items-center mt2">
            <button-component @click.stop.prevent="nullSingleSourcePlayed" class="mx-auto">Close Preview</button-component>
        </div>

        <div class="cue-points-wrapper hide">
          <h5>Cue points log:</h5>
          <p v-for="(cuePoint, index) in cuePointsLog.slice().reverse()" :key="index">
            <b>{{cuePoint.time}}</b>
            {{cuePoint.payload}}
          </p>
        </div>
      </div>

      <div v-if="!singleSourcePlayed && channelsMeta.selected.guid" class="meta p2">
        <h3 class="truncate">{{ channelsMeta.selected.name }}</h3>
        <h5 class="size-5 caps ls0 gray">Now Playing</h5>
        <div class="flex items-center">
          <div class="square1 circle mr1 preview-dot" :style="{backgroundColor: currentlyPlayingListing.color, color: 'white'}" ></div>
          <h4 class="m0 preview-listing-name pr3"> {{ currentlyPlayingListing.title }}</h4>
        </div>
        <div class="flex border-top border-bottom py1 my1">
          <div class="ratio1-4">
            <h5 class="size-5 caps ls0 gray">Status</h5>
            <tag-component :variant="channelsMeta.selected.active === 1 ?  'active' : 'inactive'">{{ channelsMeta.selected.active === 1 ?  "active" : "inactive" }}</tag-component>
          </div>
          <div class="ratio1-5">
            <h5 class="size-5 caps ls0 gray">Type</h5>
            <span class="size-1 medium" style="text-transform: capitalize;">{{ channelsMeta.selected.type }}</span>
          </div>
          <div class="ratio1-5">
            <h5 class="size-5 caps ls0 gray">Loop</h5>
            <span class="size-1 medium">{{ channelsMeta.selected.loopInterval ? (channelsMeta.selected.loopInterval > 1 ? channelsMeta.selected.loopInterval + " hours" : channelsMeta.selected.loopInterval + " hour") : "None" }}</span>
          </div>
          <div class="ratio1-5">
            <h5 class="size-5 caps ls0 gray">TimeZone</h5>
            <span class="size-1 medium">{{ channelsMeta.selected.timezone ? moment().tz(channelsMeta.selected.timezone).format('z') : "None" }}</span>
          </div>
          <div class="ratio1-5">
            <h5 class="size-5 caps ls0 gray">External Ads</h5>
            <span class="size-1 medium">{{ channelsMeta.selected.hasAds === 0 ? "None" : "Yes" }}</span>
          </div>
        </div>
        <div>
          <h5 class="size-5 caps ls0 gray">Channel URL</h5>

          <div class="flex">
            <pre class="size-2 bg-yellow+5 m0 medium" style="height: 54px;line-height: 28px; overflow-y: hidden">{{ channelsMeta.selected.playbackUrl }}</pre>
            <button-component variant="subtle" class="ml1 flex-none" @click.stop.prevent="copyToClipboard('channel-url')">Copy</button-component>
            <input class="copy-to-clipboard-input" type="text" id="channel-url" :value="channelsMeta.selected.playbackUrl">
          </div>

        </div>
      </div>
    </div>
  </section>



  <div v-if="!singleSourcePlayed && !(channelsMeta.selected && channelsMeta.selected.guid)" class="p2">
    There are no channels available.
    <button-component
      variant="default"
      class="ml1 items-center"
      @click="openDialog('create-channel')"
    >
     <ioio-icon
        icon="fas-circle-plus"
        class="h-3.5 text-green-600 mr-2"
      />
      <span>Create New</span>
    </button-component>
  </div>
</div>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";

import moment from "moment-timezone";
import Hls from "hls.js";

import { copyElementInnerText } from "@utils/helpers";

export default {
  data: () => ({
    name: "LivePreviewPanel",

    // watchListingsChangeInterval: null,

    cuePointsLog: [],
    hls: null,
    hlsjsConfig: {

      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },
    copyElementInnerText: null,

    moment: {}
  }),

  created() {

    this.copyElementInnerText = copyElementInnerText;

    this.moment = moment;
  },

  mounted() {
    window.l = this;

    if (!this.channelsMeta.selected.guid) {

      return;
    }

    this.loadVidPlayback();

    this.$refs.previewVid.textTracks.addEventListener(
      "addtrack",
      addTrackEvent => {
        let track = addTrackEvent.track;
        track.mode = "hidden";

        track.addEventListener("cuechange", event => {
          Array.prototype.slice.call(event.target.activeCues).forEach(cue => {
            // Check if the track type is metadata, there are different types such as subtitles tracks and others.
            if (cue.track.kind === "metadata") {
              let payload;
              if (
                cue.value.key === "TXXX" &&
                (payload = this.extractCuePayload(cue.value))
              ) {
                this.cuePointsLog.push({
                  time: this.moment().format("h:mm:ss a"),
                  payload: payload
                });
              }
            }
          });
        });
      }
    );
  },

  beforeDestroy() {
    if (this.hls) {
      this.hls.stopLoad();
      this.hls = null;
    }
  },
  props: {},
  methods: {
    ...mapMutations({
      setStreamToNowOffset: "playhead/SET_STREAM_TO_NOW_OFFSET",
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED"
    }),
    ...mapActions({

      makeEncodeFileRequest: 'channelManager/makeEncodeFileRequest'
    }),

    openInsightsOverview() {

      this.$pushRoute(`/statistics/channels/details?channelGuid=${this.channelsMeta.selected.guid}`);
    },

    copyToClipboard(elementId) {

      this.copyElementInnerText(elementId);
      this.$toasted.show('Copied!');
    },

    loadVidPlayback() {

      this.cuePointsLog = [];

      if (Hls.isSupported()) {

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }


        if (!this.videoPlaybackUrl) {

          return;
        }

        const video = this.$refs.previewVid;

        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        /**
         * Test if the videoPlaybackUrl is .mp4 instead of .m3u8
         */
        if (this.singleSourcePlayed && this.singleSourcePlayed.isMp4) {
console.log('IS MP4', this.videoPlaybackUrl)
          const source = document.createElement('source');

          source.src = this.videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);
          video.play();

          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });

        this.hls.loadSource(this.videoPlaybackUrl);


        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          const video = this.$refs.previewVid;
          this.hls.attachMedia(video);

          this.hls.media.muted = true;
          this.hls.media.play();
        });

        this.hls.on(Hls.Events.ERROR, (event, data) => {

          if (data.type === 'networkError' && data.response && data.response.code === 504) {

            this.hls.stopLoad();
            this.hls.destroy();

          } else {
            // Log the error for debugging
            console.error(data);
          }
        });

        /**
         * Calc the streamToNowOffset and set it to the playhead's state.
         * It's value is used to sync the currentlyPlaying[Listing/Schedule]
         */

        if (!this.singleSourcePlayed) {

          this.hls.once(Hls.Events.FRAG_LOADED, this.calcStreamToNowOffset);
        }
      }
    },

    calcStreamToNowOffset(event, data) {
      const now = new Date().getTime();

      const streamToNowOffset = data.frag.programDateTime - now;

      this.setStreamToNowOffset(streamToNowOffset);
    },

    extractCuePayload(value) {
      if (value.data) return value.data;

      if (value.info.startsWith("jsonObject")) {
        return JSON.parse(value.info.substring("jsonObject".length));
      }
    },

    nullSingleSourcePlayed() {
      this.setSingleSourcePlayed(null);
    },

    openDialog(type) {

      this.$modal.show(type);
    },

    triggerEncodeFileRequest(vod) {

      this.makeEncodeFileRequest(vod);
    }
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      channelsList: "channelManager/channelsList",
      activeToolbarPanel: "channelManager/activeToolbarPanel",
      allListings: "channelManager/selectedChannelListingsAbsolute",
      currentlyPlayingListing: "playhead/currentlyPlayingListing",
      currentlyPlayingSchedule: "playhead/currentlyPlayingSchedule",
      singleSourcePlayed: "channelManager/singleSourcePlayed",
      possibleVodTypes: "channelManager/possibleVodTypes",
    }),

    videoPlaybackUrl() {
      const playbackUrl = this.singleSourcePlayed
        ? this.singleSourcePlayed.hlsUrl
        : this.channelsMeta.selected.playbackUrl;

      return playbackUrl;
    }
  },

  watch: {
    videoPlaybackUrl() {
      this.loadVidPlayback();
    }
  }
};
</script>

<style lang="scss" scoped>


.live-channel-playing {

  video::-webkit-media-controls-timeline {
    display: none;
  }

  video::-webkit-media-controls-time-remaining-display {
    display: none;
  }

  video::-webkit-media-controls-current-time-display {
    display: none;
  }
}

video::-internal-media-controls-download-button {
  display:none;
}

.live-preview-panel-component {
  background: var(--windowBgColor);
  color: var(--windowColor);
}

.preview-description {

  width: 100%;
  max-height: 60px;
  word-break: break-word;
  overflow: auto;
}

.preview-dot {

  align-self: start;
  margin-top: 3px;
}

.preview-listing-name {

  width: 100%;
  max-height: 100px;
  overflow: auto;
  word-break: break-word;
}

.cue-points-wrapper {
  max-height: 300px;
  overflow-y: auto;
  background: ivory;
}

.copy-to-clipboard-input {
  /**
   * Hide the visibility via z-index, since hiding it in
   * any other way will make the copy to clipboard not work.
   */
  position: fixed;
  z-index: -1;
}
</style>
