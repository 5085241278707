/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Play': {
    width: 26,
    height: 29,
    viewBox: '0 0 26 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.596 15.789L2.739 28.257c-.869.496-1.984.209-2.491-.641A1.752 1.752 0 010 26.718V1.781C0 .798.815 0 1.821 0c.323 0 .64.084.918.243L24.596 12.71a1.76 1.76 0 01.656 2.436 1.802 1.802 0 01-.656.642z" _fill="#4F4F4F"/>'
  }
})
