const getDashboardCombinedStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getDashboardCombinedStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getChannelsStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getChannelsStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      if (params.is_csv) {

        return resp;
      }

      return resp.body && resp.body.data;
    });
};

const getChannelByIdStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getChannelByIdStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getVodsStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodsStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      if (params.is_csv) {

        return resp;
      }

      return resp.body && resp.body.data;
    });
};

const getVodByIdStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodByIdStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getVodForChannelByIdStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getVodForChannelByIdStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getEventViewsStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getEventViewsStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getDashboardInsightsStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getDashboardInsightsStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      return resp.body && resp.body.data;
    });
};

const getEventReportStats = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
      type: 'getEventReportStats',
      baseUrlType: 'BASE_STATISTICS_URL',
      params
    }, {
      root: true
    }).then(resp => {

      if (params.query && params.query.is_csv) {

        return resp;
      } else {

        return resp.body && resp.body.data;
      }

    });
};

export {
  getDashboardCombinedStats,
  getChannelsStats,
  getChannelByIdStats,
  getVodsStats,
  getVodByIdStats,
  getVodForChannelByIdStats,
  getEventViewsStats,
  getDashboardInsightsStats,
  getEventReportStats
}