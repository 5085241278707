"use strict"

import { start } from './start'
import { end   } from './end'
import { reset } from './reset'

import { newSubtitles } from './newSubtitles'
import { openFile     } from './openFile'

import { applyChanges      } from './applyChanges'
import { cancelChanges     } from './cancelChanges'




const lib = {
                  start
                , end
                , reset
                , newSubtitles
                , openFile

                , applyChanges
                , cancelChanges
            };



export default lib


