/**
 * Export point for all genericComponents
 */

import AvatarComponent from './Avatar'
import ButtonComponent from './Button'
import CheckComponent from './Check'
import DropdownComponent from './Dropdown'
import LoaderComponent from './Loader'
import LogoComponent from './Logo'
import LogoLoaderComponent from './LogoLoader'
import ProgressComponent from './Progress'
import TagComponent from './Tag'

export default {
  AvatarComponent,
  ButtonComponent,
  CheckComponent,
  DropdownComponent,
  LoaderComponent,
  LogoComponent,
  LogoLoaderComponent,
  ProgressComponent,
  TagComponent,
}