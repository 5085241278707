<template>
<modal
  name="create-channel"
  class="create-channel-component"
  width="620"
  height="700"
  :clickToClose="false"
  @before-open="beforeCreateChannelModalOpen"
>


  <button-component
    class="close-create-channel-btn"
    variant="minimal"
    shape="circle"
    @click="closeCreateNewChannelModal">
    <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
  </button-component>

  <div class="p2 h100 flex flex-column">

    <h2 class="size+2 pl2">Create New Channel</h2>
    <vue-form @submit.prevent="triggerCreateNewChannel" :state="createChannelFormState" class="overflow-y p2">


      <validate class="" tag="label">
        <span class="">Name <span class="text-danger">*</span></span>
        <input
          type="text"
          id="name"
          placeholder="Name"
          required
          v-model="newChannel.name"
          maxlen="100"
          name="channelName"/>
        <field-messages name="channelName" show="$submitted || $dirty && $touched">
          <div slot="required">Name is a required field</div>
          <div slot="maxlen">Name length should not be more than 100 characters</div>
        </field-messages>
      </validate>

      <validate class="" tag="label">
        <span class="">Description <span class="text-danger">*</span></span>
        <input
          type="text"
          id="description"
          placeholder="Description"
          required
          v-model="newChannel.description"
          maxlen="500"
          name="channelDescription"/>
        <field-messages name="channelDescription" show="$submitted || $dirty && $touched">
          <div slot="required">Description is a required field</div>
          <div slot="maxlen">Description length should not be more than 500 characters</div>
        </field-messages>
      </validate>

      <div>
        <label for="channelType">
          Type
          <span>*</span>
        </label>

        <select
          id="channelType"
          v-model="channelTypeSelected"
          name="channelType"
        >
          <option
            :key="item.value"
            :value="item.value"
            v-for="item in channelTypes"
          >{{ item.label }}</option>
        </select>
      </div>

      <div>
        <label for="type">
          CDN
          <!--<span>*</span>-->
        </label>

        <select
          class="select"
          id="cdnGuid"
          v-model="cdnGuid"
          name="cdnGuid">

          <option :value="''"
          >Default CDN</option>

          <option
            :key="item.guid"
            :value="item.guid"
            v-for="item in availableCdns"
            >{{ item.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="isActive">
          Status
          <span>*</span>
        </label>

        <div>
          <label>
            <input
              type="radio"
              name="status"
              id="isActive"
              value="1"
              checked="checked"
              v-model="newChannel.status"
            >
            Active
          </label>
        </div>

        <div>
          <label>
            <input
              type="radio"
              name="status"
              id="isInactive"
              value="0"
              v-model="newChannel.status"
            >
            Inactive
          </label>
        </div>
      </div>

      <div>
        <label for="image-url">Image url</label>

        <input
          type="text"
          id="image-url"
          name="imageUrl"
          placeholder="Image url"
          v-model="newChannel.imageUrl"
        >
      </div>

      <div v-if="isExternalHLSChannelTypeSelected">

        <validate class="" tag="label">
          <span class="">External URL <span class="text-danger">*</span></span>
          <input
            type="url"
            id="externalUrl"
            placeholder="External URL"
            required
            v-model="newChannel.externalUrl"
            name="externalUrl"/>
          <field-messages name="externalUrl" show="$submitted || $dirty && $touched">
            <div slot="required">External URL is a required field</div>                <div slot="url">External URL should be a valid URL</div>
          </field-messages>
        </validate>
      </div>

      <div v-if="isVirtualChannelTypeSelected">
        <label for="loopInterval">Loop interval (hours)</label>

        <select
          name="loopInterval"
          id="loopInterval"
          v-model="loopOptionSelected"
        >
          <option
            v-for="(option,i) in loopOptions"
            :key="i"
            :value="option.value"
          >{{ option.label }}</option>
        </select>
      </div>

      <div>
        <label for="hasExternalAds">
          Has external ads?
          <span>*</span>
        </label>

        <div>
          <label>
            <input
              type="radio"
              name="hasExternalAds"
              id="hasExternalAds"
              v-model="hasExternalAds"
              value="1"
              checked="checked"
            >
            Yes
          </label>
        </div>

        <div>
          <label>
            <input
              type="radio"
              name="hasExternalAds"
              id="hasNotExternalAds"
              v-model="hasExternalAds"
              value="0"
            >
            No
          </label>
        </div>
      </div>

      <div v-if="isHasExternalAdsSelected">

        <label for="mediatailors">Mediatailor</label>
        <validate class="" tag="label">
          <select
            class="select"
            name="mediatailors"
            id="mediatailors"
            v-model="mediatailorSelected"
            required>
            <option v-for="(mtailor,i) in mediatailors" :key="i" :value="mtailor.guid">{{ mtailor.name }}</option>
          </select>

          <field-messages name="mediatailors" show="$submitted || $dirty && $touched">
            <div slot="required">Mediatailor is a required field</div>
          </field-messages>
        </validate>
      </div>

      <custom-meta-fields-legacy-component
        containerClass="pt2 mt3 border-top"
        :editedAsset="newChannel.meta"
        metaResourceType="channel" />


      <channel-subs-lang-selector-component
        class="pt2 mt3 border-top"
        :subtitlesData="newChannel.subtitles"
        ref="subsLangSelector" />


      <ioio-dropdown
        :items="possibleTimezones"
        v-model="newChannel.timezone"
        valueProp="value"
        titleProp="label"
        required
        fullWidth
        topic="newChannelForm"
        label="Timezone"
        placeholder="Timezone"
        size="medium"
        class="w-full mb-8"
      />

      <button-component
        type="submit"
        variant="primary"
        size="size-m"
        class="mt2"
        :class="isCreateInProgress || (createChannelFormState.$submitted && createChannelFormState.$invalid) ? 'disabled' : ''"
      >Create Channel</button-component>
    </vue-form>
  </div>
</modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getEventsPossibleTimezones } from "@utils/helpers";
import moment from "moment-timezone";

export default {
  data: () => ({
    createChannelFormState: {},

    newChannel: {

      name: '',
      description: '',
      status: 1,
      imageUrl: '',
      externalUrl: '',
      meta: {},
      subtitles: {},
      timezone: ''
    },

    channelTypeSelected: "virtual",
    cdnGuid: '',

    channelTypes: [
      // NOTE: currently NOT supported, it's definition is left
      // in the settings panel, since there are old channels,
      // that were created with this type
      // {
      //   label: "Media Live",

      //   value: "mediaLive"
      // },
      {
        label: "Virtual",

        value: "virtual"
      },
      {
        label: "External HLS",

        value: "externalHLS"
      }
    ],

    loopOptions: [
      {
        label: "Without loop",

        value: 0
      },

      {
        label: "1",

        value: 1
      },

      {
        label: "3",

        value: 3
      },

      {
        label: "6",

        value: 6
      },

      {
        label: "12",

        value: 12
      },

      {
        label: "24",

        value: 24
      }
    ],

    loopOptionSelected: 0,

    hasExternalAds: "0",

    mediatailorSelected: '',

    isCreateInProgress: false,

    availableCdns: [],

    possibleTimezones: []
  }),
  props: {},

  created() {

    this.possibleTimezones = getEventsPossibleTimezones();

    this.newChannel.timezone = moment.tz.guess();
  },

  methods: {
    triggerCreateNewChannel() {

      if (this.createChannelFormState.$invalid) {

        return;
      }

      const params = {

        name: this.newChannel.name,

        description: this.newChannel.description,

        type: this.channelTypeSelected,

        active: +this.newChannel.status,

        imageUrl: this.newChannel.imageUrl,

        hasAds: +this.hasExternalAds,

        cdnGuid: this.cdnGuid,

        timezone: this.newChannel.timezone
      };

      if (this.isExternalHLSChannelTypeSelected) {

        params.externalUrl = this.newChannel.externalUrl;
      }

      if (this.isHasExternalAdsSelected) {

        params.mediatailor = this.mediatailorSelected;
      }

      if (this.isVirtualChannelTypeSelected) {

        params.loopInterval = +this.loopOptionSelected;
      }

      // Check if custom meta was added to the channel
      Object.keys(this.newChannel.meta).length &&
        (params.meta = this.newChannel.meta);

      // Check if subtitles data was added to the channel
      const subtitles  = this.$refs.subsLangSelector.gatherSubsData();

      params.subtitles = subtitles;

      this.isCreateInProgress = true;

      this.makeCreateNewChannelRequest(params).then((newlyCreatedChannel) => {
        // Close the modal
        this.closeCreateNewChannelModal();

        this.$toasted.success(`Channel ${ this.$options.filters.truncate(params.name, 64, "...")} was created successfully.`);

        /**
         * Update the query param with the selected channel guid,
         * since it will be used and loaded if a page reload occurs
         */
        this.$pushRoute({

          path: this.$route.fullPath,
          query: {
            'channel-guid': newlyCreatedChannel.guid
          }
        });

        // Null the form values
        this.newChannel = {

          name: '',
          description: '',
          status: 1,
          imageUrl: '',
          externalUrl: '',
          meta: {},
          subtitles: {},
          timezone: moment.tz.guess()
        };

        this.channelTypeSelected = "virtual";

        this.loopOptionSelected = 0;

        this.hasExternalAds = "0";

        this.mediatailorSelected = '';

        this.toggleSidebarOpened();
      })
      .finally(() => {

        this.isCreateInProgress = false;
      });
    },

    beforeCreateChannelModalOpen() {

      this.makeGetMediatailorsRequest();

      this.getCDNs()
        .then(cdns => {

          this.availableCdns = cdns;
        });
    },

    closeCreateNewChannelModal() {
      this.$modal.hide("create-channel");
    },
    ...mapMutations({
      toggleSidebarOpened: "app/TOGGLE_SIDEBAR_OPENED",
    }),
    ...mapActions({
      makeGetMediatailorsRequest: "channelManager/makeGetMediatailorsRequest",
      makeCreateNewChannelRequest: "channelManager/makeCreateNewChannelRequest",
      getCDNs: "channelManager/getCDNs",
    })
  },
  computed: {
    ...mapGetters({
      mediatailors: "channelManager/mediatailors",
    }),
    isExternalHLSChannelTypeSelected() {
      return this.channelTypeSelected === "externalHLS";
    },

    isVirtualChannelTypeSelected() {
      return this.channelTypeSelected === "virtual";
    },

    isHasExternalAdsSelected() {
      // The value is a string per BE

      return this.hasExternalAds === "1";
    }
  },
}
</script>

<style lang="scss" scoped>


/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";
@include overwrite-styleberry-styles-inputs;
@include overwrite-styleberry-styles-htags-margin;
@include overwrite-styleberry-styles-htags-font-size;

</style>
