<template>

  <div class="toolbar-component flex items-center flex-column flex-none">

    <button-component
      variant="subtle"
      class="mt1"
      shape="circle"
      size="size-m"
      @click="() => toggleChannelManagerPanelsOpened('livePreview')"
      :class="activeToolbarPanel !== 'livePreview' ? '' : 'active'"
      ><ioio-icon icon="fas-clapperboard-play"/></button-component>

    <button-component
      variant="subtle"
      class="mt1"
      shape="circle"
      size="size-m"
      @click="() => toggleChannelManagerPanelsOpened('settings')"
      :class="activeToolbarPanel !== 'settings' ? '' : 'active'"
      ><ioio-icon icon="fas-gear" class="w-5 h-5"/></button-component>

    <button-component
      variant="subtle"
      class="mt1"
      shape="circle"
      size="size-m"
      @click="() => toggleChannelManagerPanelsOpened('epg')"
      :class="activeToolbarPanel !== 'epg' ? '' : 'active'"
      v-if="$auth('epg_generate')"
      ><ioio-icon icon="fas-calendar-range"/></button-component>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    name: "Toolbar"
  }),
  props: {},
  methods: {
    ...mapMutations({
      // toggleLibraryPanelOpened: "channelManager/TOGGLE_LIBRARY_PANEL_OPENED",
      toggleChannelManagerPanelsOpened:"channelManager/TOGGLE_DASHBOARD_PANELS_OPENED",
      // toggleSettingsPanelOpened: "channelManager/TOGGLE_SETTINGS_PANEL_OPENED"
    }),
    ...mapActions({})
  },
  computed: {
    ...mapGetters({
      activeToolbarPanel: "channelManager/activeToolbarPanel",
    })
  }
};
</script>

<style lang="scss" scoped>

.toolbar-component {
  width: 3rem;
  background: var(--windowBgColor);
  color: var(--windowColor);
  border-left: 1px solid var(--windowBrColor);
}
</style>
