/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'right-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 23.414L5.586 22l10-10-10-10L7 .586l10.707 10.707a.999.999 0 010 1.414L7 23.414z" _fill="#111"/>'
  }
})
