<template>

<section class="static-timeline-component mb2 flex items-bottom">

  <aside class="flex-auto mr2">

    <header class="timeline-header flex justify-between mb1">
      <p class="m0 size-2">{{ new Date(loadedPeriodStart).toDateString() }} - {{ new Date(loadedPeriodEnd).toDateString() }}</p>


      <aside class="timeline-ctrls flex" v-if="!timelineExternalIsControlled">

        <button-component class="mr1" variant="minimal" shape="circle"
          @click.stop.prevent="loadPeriod('prev')">
          <ioio-icon icon="fas-backward-step" class="w-6 h-6"/>
        </button-component>

        <button-component class="mr1" variant="minimal" shape="circle"
          @click.stop.prevent="loadPeriod()">
          <ioio-icon icon="fas-location-dot" class="w-6 h-6"/>
        </button-component>
        <button-component class="" variant="minimal" shape="circle"
          @click.stop.prevent="loadPeriod('next')">
          <ioio-icon icon="fas-forward-step" class="w-6 h-6"/>
        </button-component>

      </aside>

      <aside class="" v-else>
        <h3 class="m0 mr2 size-3">This is how the program will look like.</h3>
        <p class="m0 size-4">Save or discard changes to exit the edit mode.</p>
      </aside>
    </header>

    <section class="timeline-wrapper px2 pb2 listing-content">
    <section class="flex flex-column justify-end rounded-2 relative">

      <ul class="listings-overlay flex list-reset m0 mt2">
        <li v-for="schedule in visibleSchedules"
          :class="[
            schedule.isServiceElement ? 'service' : 'card schedule-card rounded-2',
            (scheduleHoverData &&
            schedule.guid === scheduleHoverData.guid &&
            schedule.start === scheduleHoverData.start) ? 'is-in-hover-guid' : ''
          ]"
          :style="{
            width: `${getSchedulePercentageWidth(schedule)}%`
          }"
          :key="schedule.guid"
        >

          <div class="full-schedule-bg-preview rounded-2" :style="{
            background: ` url('${
                schedule.meta && schedule.meta.imageUrl
              }?d=80x45&q=80&f=jpeg') no-repeat center center / cover`
          }">
              <p class="m0 p1 title-display truncate" >{{ schedule.meta && schedule.meta.title }}

            </p>
            <p class="m0 p1 title-display"
              >{{ moment(schedule.start).format("hh:mm:ss") }} -
            {{ moment(schedule.start + schedule.totalDuration).format("hh:mm:ss") }}
            </p>
          </div>

          <div class="inner-listing overflow-hidden rounded-2" :style="{background: ` url('${
                schedule.meta && schedule.meta.imageUrl
              }?d=80x45&q=80&f=jpeg') no-repeat center center / cover`
          }">

            <p class="m0 p1 title-display truncate" >{{ schedule.meta && schedule.meta.title }}

              <span
                >({{ moment(schedule.start).format("hh:mm:ss") }} -
              {{ moment(schedule.start + schedule.totalDuration).format("hh:mm:ss") }})
              </span>
            </p>
          </div>
        </li>
      </ul>

      <ul class="listings-overlay flex list-reset m0 mt1">
        <li v-for="listing in visibleListings"
          class="overflow-hidden"
          :class="listing.isServiceElement ? 'service' : 'card rounded-2'"
          :style="{
            width: `${getListingPercentageWidth(listing)}%`,
            backgroundColor: listing.color
          }"
        >
          <p class="m0 p1 title-display truncate">{{ listing.title }}
            <span
              >({{ moment(listing.start).format("hh:mm:ss") }} -
             {{ moment(listing.end).format("hh:mm:ss") }})
            </span>
          </p>

        </li>
      </ul>

      <div class="hour-underlay ratio1-1" :style="{backgroundSize: hourUnderlayMinuteSize}"></div>
      <ul class="hours-display flex justify-between list-reset m0 size-3">
        <li v-for="h in totalMarkerCount" :style="{width: `calc(100% / ${totalMarkerCount})`}" class="pr1">
          <div class="marker"></div>
          <div class="truncate">{{ getTimespaceHourVisual(h - 1) }}</div>
        </li>
      </ul>

      <div class="playhead-wrapper overflow-hidden">
        <div class="playhead" :style="{ transform: this.playheadOffset }">
          <div class="dead-zone-display h100 bg-gray" :style="{width: playheadDeadZoneWidth}" ></div>
        </div>
      </div>


      <div class="timeline-loader-container overflow-hidden flex items-center flex-auto" v-if="isTimelineDataLoading">
        <logo-loader-component width="50px" height="50px" />
      </div>

      <div class="overflow-mask rounded-2"></div>


    </section>
    </section>
  </aside>

  <section class="flex flex-column items-center justify-center video-holder relative">

    <header class="vod-preview-header px1 items-center flex justify-between" v-if="singleSourcePlayed">
      <h3 class="m0 size-1 truncate mr2">Previewing {{ singleSourcePlayed.meta.title }}</h3>
      <button-component
        class="close-action-modal-btn"
        variant="minimal"
        shape="circle"
        @click.stop.prevent="closeVodPreview">
          <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
      </button-component>
    </header>

    <header class="vod-preview-header px1 items-center flex justify-between" v-if="noVideoStream">
      <h3 class="m0 size-1 truncate mr2">No video stream is found.</h3>
    </header>

    <video
      ref="previewVid"

      id="video-container"
      class="flex-auto"
      width="300px"
      controls
    ></video>
  </section>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import moment from "moment-timezone";

import Hls from "hls.js";

// NOTE: UI and calculations only make sense if timelineHoursLoaded is an even number!
const timelineHoursLoadedDefault = 12;

export default {
  data: () => ({

    timelineHoursLoaded: timelineHoursLoadedDefault,
    timelineHoursLoadedMS: timelineHoursLoadedDefault * 1000 * 60 * 60,
    startHour: new Date().getHours() - timelineHoursLoadedDefault / 2,
    startHourDeviation: 0, // adjust with every click of the timeline ctrl +-= timelineHoursLoaded
    loadedPeriodStart: 0,
    loadedPeriodEnd: 0,
    perHourMarkerCount: 2,
    visibleListings: [],
    visibleSchedules: [],
    isTimelineDataLoading: false,

    // video specific
    hls: null,
    hlsjsConfig: {
      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },
    noVideoStream: false,

    playheadOffset: 0,
    playheadUpdateIntervalId: null,

  }),
  props: {},

  created() {

    this.moment = moment;

    this.setPlayheadUpdateFn();
  },

  mounted() {
    window.t = this;

    this.loadVidPlayback();

    this.loadProgramForSelectedCalendarPeriod();
  },

  beforeDestroy() {

    this.clearPlayheadUpdateFn();
  },

  methods: {

    setPlayheadUpdateFn() {

      if (this.playheadUpdateIntervalId) {

        this.clearPlayheadUpdateFn();
      }

      this.calcPlayheadOffsetLeft();

      this.playheadUpdateIntervalId = setInterval(() => this.calcPlayheadOffsetLeft(), 15000);
    },

    clearPlayheadUpdateFn() {

      clearInterval(this.playheadUpdateIntervalId);
      this.playheadUpdateIntervalId = null;
    },

    loadVidPlayback() {

      if (Hls.isSupported()) {

        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }


        if (!this.videoPlaybackUrl) {

          return;
        }

        const video = this.$refs.previewVid;

        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        /**
         * Test if the videoPlaybackUrl is .mp4 instead of .m3u8
         */
        if (this.singleSourcePlayed && this.singleSourcePlayed.isMp4) {

          const source = document.createElement('source');

          source.src = this.videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);
          video.play();

          this.noVideoStream = false;
          return;
        }

        /**
         * The source is HLS
         */
        this.hls = new Hls({
          ...this.hlsjsConfig
        });

        this.hls.loadSource(this.videoPlaybackUrl);


        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

          const video = this.$refs.previewVid;
          this.hls.attachMedia(video);

          this.hls.media.muted = true;
          this.hls.media.play();
          this.noVideoStream = false;
        });

        this.hls.on(Hls.Events.ERROR, (event, data) => {


          if (data.type === 'networkError' && data.response) {

            if (data.response.code === 504) {

              this.hls.stopLoad();
              this.hls.destroy();
            }

            if (data.response.code === 404) {

              this.noVideoStream = true;
            }

          } else {
            // Log the error for debugging
            console.error(data);
          }
        });

        /**
         * Calc the streamToNowOffset and set it to the playhead's state.
         * It's value is used to sync the currentlyPlaying[Listing/Schedule]
         */

        if (!this.singleSourcePlayed) {

          this.hls.once(Hls.Events.FRAG_LOADED, this.calcStreamToNowOffset);
        }
      }
    },

    calcStreamToNowOffset(event, data) {
      const now = new Date().getTime();

      const streamToNowOffset = data.frag.programDateTime - now;

      this.setStreamToNowOffset(streamToNowOffset);
    },

    calcPlayheadOffsetLeft() {

      const startDate = this.timelineExternalStart ? new Date(this.timelineExternalStart) : new Date();

      const startHourStamp = startDate.setHours(this.startHour + this.startHourDeviation, 0, 0, 0);

      const endHourStamp = startHourStamp + this.timelineHoursLoaded * 60 * 60 * 1000;

      const visiblePeriodInMs = endHourStamp - startHourStamp;

      const now = new Date().getTime();

      const nowAdjusted = now + this.streamToNowOffset;

      const playheadToStartHourOffset = nowAdjusted - startHourStamp;

      const playheadOffset =
        100 *
        (playheadToStartHourOffset /
          (visiblePeriodInMs));

      this.playheadOffset = `translate3d(${playheadOffset - 100}%, 0, 0)`;
    },

    loadPeriod(direction) {

      if (direction === 'prev') {

        this.startHourDeviation -= this.timelineHoursLoaded;

      } else if (direction === 'next') {

        this.startHourDeviation += this.timelineHoursLoaded;

      } else {

        this.startHourDeviation = 0;
      }

      this.loadProgramForSelectedCalendarPeriod();
    },

    calcPerHourMarkerCount() {

      const repetitionIndex = 4 / (this.timelineHoursLoaded / 12 * 2);

      const roundedRepetitionIndex = (Math.round(repetitionIndex * 4) / 4);

      this.perHourMarkerCount = roundedRepetitionIndex;
    },

    enterManagedView() {

      // timelineHoursLoaded should be even number ceil to
      // appropriate one, based on external start/end
      const oneHour = 3600000;

      this.timelineHoursLoaded =
        2 * Math.ceil((this.timelineExternalEnd - this.timelineExternalStart) / oneHour / 2);

      this.timelineHoursLoadedMS = this.timelineHoursLoaded * 1000 * 60 * 60;

      this.calcPerHourMarkerCount();

      const startH = new Date(this.timelineExternalStart).getHours();

      this.startHour = startH;

      this.loadProgramForSelectedCalendarPeriod(this.timelineExternalStart);

      // null the content while loading the new one
      this.stageListings([]);
      this.stageSchedules([]);
    },

    exitManagedView() {

      this.timelineHoursLoaded = timelineHoursLoadedDefault;
      this.timelineHoursLoadedMS = this.timelineHoursLoaded * 1000 * 60 * 60;

      this.startHour = new Date().getHours() - this.timelineHoursLoaded / 2;

      this.calcPerHourMarkerCount();

      this.loadProgramForSelectedCalendarPeriod();

      // null the content while loading the new one
      this.stageListings([]);
      this.stageSchedules([]);
    },

    loadProgramForSelectedCalendarPeriod(managedStartStamp) {

      if (!this.selectedChannel.guid) {

        return;
      }

      const startDate = managedStartStamp ?
        new Date(managedStartStamp) : new Date();

      const startHourDeviation = managedStartStamp ? 0 : this.startHourDeviation;

      this.loadedPeriodStart = startDate.setHours(this.startHour + startHourDeviation, 0, 0, 0);
      this.loadedPeriodEnd =
        new Date(this.loadedPeriodStart + this.timelineHoursLoadedMS).getTime();

      this.calcPlayheadOffsetLeft();

      this.isTimelineDataLoading = true;

      const params = {
        start: this.loadedPeriodStart,

        end: this.loadedPeriodEnd,

        channel: this.selectedChannel.guid,

        current: "1"
      };

      const requests = [

        this.getSchedulesForChannelRequest(params),
        this.getListings(params)
      ];

      Promise.all(requests).then(responses => {

        const schedules = responses[0];
        const listings = responses[1];

        this.stageListings(listings);
        this.stageSchedules(schedules);

      }).finally(() => {

        this.isTimelineDataLoading = false;
      });
    },

    stageSchedules(schedules) {

      // If the schedules come from external source (SchedulesScheduler component),
      // their start and totalDuration can be adjusted via startAdjustment ||
      // endAdjustment props. This adjustment should be corrected here,
      // since the timeline can not work with it.
      if (schedules[0] && schedules[0].startAdjustment) {

        const startAdjustment = schedules[0].startAdjustment;

        const adjustedStart = schedules[0].start - startAdjustment;
        const adjustedDuration = schedules[0].totalDuration + startAdjustment;

        schedules[0] = {

          ...schedules[0],
          start: adjustedStart,
          totalDuration: adjustedDuration
        };
      }

      if (schedules[schedules.length - 1] && schedules[schedules.length - 1].endAdjustment) {

        const endAdjustment = schedules[schedules.length - 1].endAdjustment;

        const adjustedDuration = schedules[schedules.length - 1].totalDuration + endAdjustment;

        schedules[schedules.length - 1] = {

          ...schedules[schedules.length - 1],
          totalDuration: adjustedDuration
        };
      }

      let gap = 0;

      for (let i = 0; i < schedules.length; i++) {

        const currentSchedule = schedules[i];

        const currentScheduleEnd = currentSchedule.start + currentSchedule.totalDuration;

        const nextSchedule = schedules[i + 1] || {};

        gap = nextSchedule.start - currentScheduleEnd;

        if (gap > 0) {

          schedules.splice(i + 1, 0, {
            isServiceElement: true,
            start: currentScheduleEnd,
            end: nextSchedule.start,
            totalDuration: gap
          });

        }
      }

      // add service element at the back
      let lastSchedule = schedules[schedules.length - 1];

      if (!lastSchedule) {

        lastSchedule = {
          isServiceElement: true,
          start: this.loadedPeriodStart,
          end: this.loadedPeriodEnd,
          totalDuration: this.loadedPeriodEnd - this.loadedPeriodStart
        };

        schedules[0] = lastSchedule;
      }

      const lastScheduleEnd = lastSchedule.start + lastSchedule.totalDuration;

      // Insert element at the back
      if (lastScheduleEnd < this.loadedPeriodEnd) {

        schedules.push({
          isServiceElement: true,
          start: lastScheduleEnd,
          end: this.loadedPeriodEnd,
          totalDuration: this.loadedPeriodEnd - lastScheduleEnd
        });
      }

      if (schedules[0]) {

        const firstScheduleStart = schedules[0].start;

        if (firstScheduleStart < this.loadedPeriodStart) {

          //adjust start
          const forAdjustDuration = this.loadedPeriodStart - firstScheduleStart;

          schedules[0].start = this.loadedPeriodStart;
          schedules[0].totalDuration -= forAdjustDuration;

        } else {

          const totalDuration = schedules[0].start - this.loadedPeriodStart;

          //insert service element in front
          schedules.unshift({
            isServiceElement: true,
            start: this.loadedPeriodStart,
            end: schedules[0].start,
            totalDuration
          });
        }

        const lastSchedule = schedules[schedules.length - 1];
        const lastScheduleEnd = lastSchedule.start + lastSchedule.totalDuration;

        if (lastScheduleEnd > this.loadedPeriodEnd) {

          //adjust end
          const forAdjustDuration = lastScheduleEnd - this.loadedPeriodEnd;

          schedules[schedules.length - 1].end = this.loadedPeriodEnd;
          schedules[schedules.length - 1].totalDuration -= forAdjustDuration;
        }
      }

      this.visibleSchedules = schedules;
    },

    stageListings(listings) {

      let gap = 0;

      for (let i = 0; i < listings.length; i++) {

        const currentListing = listings[i];
        const nextListing = listings[i + 1] || {};

        gap = nextListing.start - currentListing.end;

        if (gap > 0) {

          listings.splice(i + 1, 0, {
            isServiceElement: true,
            start: currentListing.end,
            end: nextListing.start
          });

        }
      }


      // add service element at the back
      let lastListing = listings[listings.length - 1];

      if (!lastListing) {

        lastListing = {
          isServiceElement: true,
          start: this.loadedPeriodStart,
          end: this.loadedPeriodEnd
        };

        listings[0] = lastListing;
      }

      const lastListingEnd = lastListing.end;

      // Insert element at the back
      if (lastListingEnd < this.loadedPeriodEnd) {

        listings.push({
          isServiceElement: true,
          start: lastListingEnd,
          end: this.loadedPeriodEnd
        });
      }

      if (listings[0]) {

        const firstListingStart = listings[0].start;

        if (firstListingStart < this.loadedPeriodStart) {

          //adjust start
          listings[0].start = this.loadedPeriodStart;
          listings[0].startAdjustment = this.loadedPeriodStart - firstListingStart;

        } else {

          //insert service element in front
          listings.unshift({
            isServiceElement: true,
            start: this.loadedPeriodStart,
            end: listings[0].start
          });
        }

        const lastListingEnd = listings[listings.length - 1].end;

        if (lastListingEnd > this.loadedPeriodEnd) {

          //adjust end
          listings[listings.length - 1].end = this.loadedPeriodEnd;
          listings[listings.length - 1].endAdjustment = this.loadedPeriodEnd - lastListingEnd;
        }
      }

      this.visibleListings = listings;
    },

    getTimespaceHourVisual(markerIndex) {

      let markerDistanceFromHourInMins = 0;
      let markerDistanceFromHourRest = markerIndex % this.perHourMarkerCount;

      if (markerDistanceFromHourRest !== 0) {

        markerDistanceFromHourInMins = 60 * markerDistanceFromHourRest / this.perHourMarkerCount;
      }

      const hourIndex = Math.floor(markerIndex / this.perHourMarkerCount);

      let hourDeviation = 0;

      if (this.startHourDeviation <= 0) {

        hourDeviation = Math.abs(24 + this.startHour - Math.abs(this.startHourDeviation % 24) + hourIndex);

      } else {

        hourDeviation = Math.abs(this.startHour + this.startHourDeviation % 24 + hourIndex);
      }

      let parsedHour = hourDeviation < 24
        ? hourDeviation
        : Math.abs(24 - hourDeviation);

      parsedHour > 24 ? parsedHour = parsedHour % 24 : parsedHour;

      let parsedHourUS =
        parsedHour > 12 ? parsedHour - 12 :  parsedHour;

      if (parsedHourUS === 0) {

        parsedHourUS = 12;
      }

      const hourSigniture = parsedHour > 11 ? 'PM' : 'AM';

      if (markerDistanceFromHourInMins) {

        return `${parsedHourUS}:${markerDistanceFromHourInMins}${hourSigniture}`;
      }

      return `${parsedHourUS}${hourSigniture}`;
    },

    getListingPercentageWidth(listing) {
      const listingDuration = listing.end - listing.start;
      const a = 100 / (this.timelineHoursLoadedMS / listingDuration);

      return a;
    },

    getSchedulePercentageWidth(schedule) {
      const scheduleEnd = schedule.start + schedule.totalDuration;

      const scheduleDuration = scheduleEnd - schedule.start;
      const a = 100 / (this.timelineHoursLoadedMS / schedule.totalDuration);

      return a;
    },

    closeVodPreview() {

      this.setSingleSourcePlayed(null);
    },

    ...mapMutations({

      setStreamToNowOffset: "playhead/SET_STREAM_TO_NOW_OFFSET",
      setSingleSourcePlayed: "channelManager/SET_SINGLE_SOURCE_PLAYED",
    }),
    ...mapActions({
      getListings: "channelManager/makeGetListingsForChannelRequest",
      getSchedulesForChannelRequest: "channelManager/makeGetSchedulesForChannelRequest",

    })
  },
  computed: {
    ...mapGetters({
      channelsMeta: "channelManager/channelsMeta",
      singleSourcePlayed: "channelManager/singleSourcePlayed",
      streamToNowOffset: "playhead/streamToNowOffset",

      timelineExternalStart: "channelManager/timelineExternalStart",
      timelineExternalEnd: "channelManager/timelineExternalEnd",
      timelineExternalIsControlled: "channelManager/timelineExternalIsControlled",
      timelineTempSchedulesSelection: "channelManager/timelineTempSchedulesSelection",
      timelineTempListingsSelection: "channelManager/timelineTempListingsSelection",
      deadzoneBufferMs: 'channelManager/deadzoneBufferMs',
      msgBusMsgList: 'app/msgBusMsgList',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',
      scheduleHoverData: 'channelManager/scheduleHoverData',
    }),

    selectedChannel() {

      return this.channelsMeta.selected;
    },

    hourUnderlayMinuteSize() {
      // 4 comes from the specified gradient has 4 sides, 60 comes from minutes in a hour
      return `calc(100% / ${this.timelineHoursLoaded * 60 / 2})`;
    },

    totalMarkerCount() {

      return Math.round(this.timelineHoursLoaded * this.perHourMarkerCount);
    },

    videoPlaybackUrl() {
      const playbackUrl = this.singleSourcePlayed
        ? this.singleSourcePlayed.hlsUrl
        : this.selectedChannel.playbackUrl;

      return playbackUrl;
    },

    playheadDeadZoneWidth() {

      const newWidth = 100 / (this.timelineHoursLoadedMS / this.deadzoneBufferMs);
      const newWidthRounded = newWidth.toFixed(2);

      return `${newWidthRounded}%`;
    },
  },

  watch: {

    selectedChannel(newVal, oldVal) {

      if (newVal.guid === oldVal.guid) {

        return;
      }

      if (this.isControlled || this.isActiveConfirmGuardSet) {
        console.log('NOT UPDATING, since timeline is controlled externally');
        return;
      }

      this.loadProgramForSelectedCalendarPeriod();
    },

    videoPlaybackUrl() {
      this.loadVidPlayback();
    },

    timelineExternalIsControlled() {

      if (this.timelineExternalIsControlled) {

        this.enterManagedView();

      } else {

        this.exitManagedView();
      }
    },

    timelineTempSchedulesSelection() {

      this.stageSchedules([...this.timelineTempSchedulesSelection]);
    },

    timelineTempListingsSelection() {

      this.stageListings([...this.timelineTempListingsSelection]);
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1] || {};

      if (newestMsg.type === 'UPDATE_STATIC_TIMELINE_DATA') {

        this.loadProgramForSelectedCalendarPeriod();

        // null the content while loading the new one
        this.stageListings([]);
        this.stageSchedules([]);
      }
    }
  }
}
</script>

<style lang="scss">



.static-timeline-component {

  // background-color: #FFF;

  $markerColor: #D9DBE7;

  .timeline-header {

    height: 30px;
  }

  .overflow-mask {

    background: #fcfcff;
    width: 16px;
    height: 140px;
    position: absolute;
    top: 0;
    left: 100%;

    &:after {
      content: '';
      position: relative;
      left: 100%;
      background: #e5e5e5;
      top: 0;
      width: 16px;
      height: 140px;
      display: block;
    }
  }

  .listing-content {

    height: 140px;
    background-color: #FCFCFF;
  }

  .hour-underlay {

    height: 15px;

    background-image: linear-gradient(90deg, $markerColor 25%, #fff 25%, #fff 50%, $markerColor 50%, $markerColor 75%, #fff 75%, #fff 100%);

  }

  .hours-display {

    height: 20px;

    .marker {
      height: 40px;
      border-left: 1px solid $markerColor;
      margin-top: -40px;
      opacity: 0.7;
    }
  }

  .listings-overlay {

    height: 35px;
    background-color: #FCFCFF;

    .inner-listing {

      width: 100%;
      height: 100%;
      background: orange;
    }

    li {

      background-color: #3788d8;

      &.service {

        background-color: #e5e5e5;
      }
    }

    .title-display {

      font-size: 14px;
      color: white;
      // mix-blend-mode: difference;
    }
  }

  .schedule-card:hover {
    z-index: 99;
    box-shadow: 0px 0px 0px 1px var(--color-white),
      0px 0px 0px 2px rgba(0, 0, 0, 0.125), 0px 16px 32px rgba(0, 0, 0, 0.35);

    .full-schedule-bg-preview {

      display: block;
    }
  }

  .schedule-card.is-in-hover-guid {

    .full-schedule-bg-preview {

      display: block;
    }
  }

  .full-schedule-bg-preview {

    display: none;
    position: absolute;
    width: 320px;
    height: 200px;
    top: 0;
    left: 0;

    z-index: 99;
    box-shadow: 0px 0px 0px 1px var(--color-white),
      0px 0px 0px 2px rgba(0, 0, 0, 0.125), 0px 16px 32px rgba(0, 0, 0, 0.35);
  }

  .schedule-card:not(.empty) {
    position: relative;
  }

  .video-holder {

    width: 300px;
    min-width: 300px;
  }

  .vod-preview-header {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 40px;
    background-color: white;
    opacity: 0.4;

    &:hover {

      opacity: 1;
    }
  }

  .playhead-wrapper {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .playhead {

    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: transform 1s ease-in-out;
    transform: translate3d(0%, 0px, 0px);

    &:after {


      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      background: #0077FF;
      right: -6px;
      border-radius: 50%;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 100%;
      background: #151E33;
      top: 0;
      right: -2px;
    }
  }

  .dead-zone-display {

    position: absolute;
    left: 100%;
    opacity: 0.2;
  }
}

.timeline-loader-container {

  position: absolute;
  top: 30%;
  right: calc(50% - 150px);
  z-index: 5;
}


</style>
