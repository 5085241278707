<template>
<section class="insights-general-stats-component flex">

  <div class="pr-14 mr-14 border-right">
    <h1 class="text-xl">{{ allTimeViews }}</h1>
    <h3 class="text-base text-blue-grey-600">All time views</h3>
  </div>
  <div class="pr-14 mr-14 border-right">
    <h1 class="text-xl">{{ avgWatchTime }}</h1>
    <h3 class="text-base text-blue-grey-600">Avg. watch time</h3>
  </div>
  <div class="pr-14 mr-14 border-right">
    <h1 class="text-xl">{{ avgCompletion }}</h1>
    <h3 class="text-base text-blue-grey-600">Avg. completion</h3>
  </div>
  <div>
    <h1 class="text-xl">{{ totalWatchTime }}</h1>
    <h3 class="text-base text-blue-grey-600">Total watch time</h3>
  </div>

</section>
</template>

<script>

export default {

  props: {

    allTimeViews: String|Number,
    avgWatchTime: String|Number,
    avgCompletion: String|Number,
    totalWatchTime: String|Number,
  }
}
</script>

<style lang="scss">


</style>
