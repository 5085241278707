import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const state = {

  forModalActionVod: {},
  isDownloadModalOpened: false,
  isMediaInfoModalOpened: false,
  editedVod: {
    meta: {}
  },
  clickedVodId: '',
  editedVodCaptions: {},
  shouldEncodeCaptions: false
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}