/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'event-confirm': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M17 23.4L13.6 20l1.4-1.4 2 2 5-5 1.4 1.4-6.4 6.4z"/><path pid="1" d="M23 3h-5V1a1 1 0 00-2 0v2H8V1a.945.945 0 00-.887-1A1.065 1.065 0 007 0a.945.945 0 00-1 .887A1.065 1.065 0 006 1v2H1a.945.945 0 00-1 .887A1.065 1.065 0 000 4v18a.945.945 0 00.887 1A1.065 1.065 0 001 23h10v-2H2V7h20v5h2V4a.945.945 0 00-.887-1A1.065 1.065 0 0023 3z"/></g>'
  }
})
