function start ( task, dependencies, stateData, data ) {
    let
          { importVTT } = dependencies
        , {
              task:editTask
            , containerID
            , components
            , vtt
            , videoURL
            , audioDuration
           } = data
        , requestList = []     // Place for promises
        , standardLoader = []  // Component names for loading
        ;
    let vttFiles;
    if ( vtt )   vttFiles = importVTT ( vtt )
    else         vttFiles = {}

    if ( !components.MainPage ) {
            requestList.push ( import ( '../components/MainPage' ))
            standardLoader.push ( 'MainPage' )
        }

    if ( !components.CueEditor ) {
            requestList.push ( import ( '../components/CueEditor' ))
            standardLoader.push ( 'CueEditor' )
        }

    if ( !components.SubtitlePreview ) {
            requestList.push ( import ( '../components/SubtitlePreview' ))
            standardLoader.push ( 'SubtitlePreview' )
        }
        stateData.vttFiles = vttFiles
        stateData.editTask = editTask
        stateData.containerID = containerID
        stateData.audioDuration = audioDuration
        console.log(77,audioDuration, stateData.audioDuration)
    if ( videoURL )   stateData.videoURL = videoURL
    Promise.all ( requestList )
           .then ( comps => {
                    standardLoader.forEach ( (name,i) => components[name] = comps[i].default )
                    task.done ({
                                      success: true
                                    , response : { components, vttFiles }
                                    , stateData
                            })
                })
} // start func.



 export { start }


