/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-power': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M17.497 3.47a1 1 0 10-1 1.732A9.037 9.037 0 0121 13c0 4.963-4.037 9-9 9s-9-4.037-9-9a9.038 9.038 0 014.501-7.797.998.998 0 00.364-1.366.995.995 0 00-1.366-.364A11.04 11.04 0 001 13c0 6.065 4.935 11 11 11s11-4.935 11-11a11.04 11.04 0 00-5.503-9.53z"/><path pid="1" data-color="color-2" d="M12 9a1 1 0 001-1V1a1 1 0 10-2 0v7a1 1 0 001 1z"/></g>'
  }
})
