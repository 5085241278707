/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'meta-fields': {
    width: 24,
    height: 19,
    viewBox: '0 0 24 19',
    data: '<path pid="0" d="M13.09 8.727h7.637v1.637h-7.636V8.727zm0-2.727h7.637v1.636h-7.636V6zm0 5.454h7.637v1.637h-7.636v-1.636zM21.819 0H2.182A2.182 2.182 0 000 2.182v14.182a2.182 2.182 0 002.182 2.181h19.636A2.182 2.182 0 0024 16.364V2.182A2.182 2.182 0 0021.818 0zm0 16.364H12V2.182h9.818" _fill="#5c7499"/>'
  }
})
