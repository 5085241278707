<template>
<section class="library-multiple-vods-details-component flex flex-auto flex-column">

  <ioio-tabs
    v-if="!isVodPanelInEditMode"
    class="flex flex-auto flex-column"
    @input="setSelectedTabName"
  >

    <ioio-tab
      name="Details"
      :selected="true"
      class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
    >

      <section class="flex justify-between mb-4 mt-6">

        <p>Video type</p>
        <p>{{ parseVodType(commonVodData.meta.type) }}</p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Status</p>
        <p>{{ commonVodData.status }}</p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Categories</p>
        <p class="flex flex-auto flex-wrap justify-end">
          <span
            v-if="commonVodData.meta.categories && commonVodData.meta.categories.length && commonVodData.meta.categories !== 'Mixed'">

            <ioio-label
              :text="label"
              size="small"
              v-for="(label, index) in commonVodData.meta.categories"
              :key="index"
              class="ml-2 mb-2 float-right"
            >
            </ioio-label>
          </span>
          <span v-else>{{ commonVodData.meta.categories === 'Mixed' ? 'Mixed' : '--' }}</span>
        </p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Group</p>

        <p class="flex flex-auto flex-wrap justify-end">
          <ioio-label
            v-if="commonVodData.meta.group"
            :text="commonVodData.meta.group"
            size="small"
            class="ml-2"
          >
          </ioio-label>
          <span v-else>--</span>
        </p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Tags</p>
        <p class="flex flex-auto flex-wrap justify-end">
          <span v-if="commonVodData.meta.tags && commonVodData.meta.tags.length && commonVodData.meta.tags !== 'Mixed'">
            <ioio-label
              :text="`#${label}`"
              size="small"
              v-for="(label, index) in commonVodData.meta.tags"
              :key="index"
              class="ml-2"
            >
            </ioio-label>
          </span>
          <span v-else>{{ commonVodData.meta.tags === 'Mixed' ? 'Mixed' : '--' }}</span>
        </p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Genre</p>

        <p class="flex flex-auto flex-wrap justify-end">
          <ioio-label
            v-if="commonVodData.meta.genre"
            :text="`${commonVodData.meta.genre !== 'Mixed' ? '#' + commonVodData.meta.genre : commonVodData.meta.genre}`"
            size="small"
            class="ml-2"
          >
          </ioio-label>
          <span v-else>--</span>
        </p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Privacy</p>
        <p>{{ getVodPrivacy(commonVodData.meta.privacy) }}</p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Featured</p>
        <p>{{ getVodFeatured(commonVodData.meta.featured) }}</p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Listed in video portals</p>
        <p>{{ getVodListedInPortals(commonVodData.meta.listedInPortals) }}</p>
      </section>
      <section class="flex justify-between mb-6">
        <p>Expires at</p>
        <p v-if="commonVodData.meta.expires === 'Mixed'">{{ commonVodData.meta.expires }}</p>
        <p v-else>{{ (commonVodData.meta.expires && new Date(commonVodData.meta.expires).toLocaleString()) || '--' }}</p>
      </section>
    </ioio-tab>


    <ioio-tab name="Metadata" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 pt-2 mr-2">

      <section v-if="isCustomMetaMixed">

        <h3 class="mb-2 text-sm">Mixed content</h3>
        <p class="text-sm">You can align all videos by adding one meta group for all. However, keep in mind that all previous information will be overwritten
        </p>
      </section>

      <section v-else>

        <custom-meta-fields-component
          displayMode="showcase"
          :editedAsset="commonVodData.meta"
          metaResourceType="video"
          secondaryNoMetaDisclaimer="You can add one metadata group for all in edit mode"
        />
      </section>

    </ioio-tab>


    <ioio-tab name="Hubs" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 pt-2  mr-2">

      <section v-if="areHubsMixed">

        <h3 class="mb-2 text-sm">Mixed content</h3>
        <p class="text-sm">You can align all videos by adding one hub for all. However, keep in mind that all previous information will be overwritten
        </p>
      </section>

      <section v-else>

        <p v-for="hub in commonVodData.meta.hubs" :key="hub.name" class="mb-4 text-sm">
          {{ hub.name }}
        </p>

        <p v-if="!commonVodData.meta.hubs || !commonVodData.meta.hubs.length" class="text-sm">No hubs added yet.</p>
      </section>

    </ioio-tab>

  </ioio-tabs>
  <ioio-logical-edit
    v-else
    @changed="onLogicalEditChange"
    :detectChanges="!isFormDirty"
    class="flex flex-auto flex-column"
  >
    <ioio-tabs
      class="flex flex-auto flex-column"
      @input="setSelectedTabName"
      ref="tabForm"
    >

      <ioio-tab
        name="Details"
        :selected="true"
        class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2"
      >

        <ioio-dropdown
          topic="editMultipleVods"
          label="Type"
          fullWidth
          :items="possibleVodTypes"
          titleProp="label"
          valueProp="value"
          v-model="commonVodDataEdit.meta.type"
          size="medium"
          class="mb-5"
        />

        <section class="relative-toggle-section">

          <label class="text-black-700 text-sm mb-1">Categories</label>
          <p class="text-xs text-black-400 mb-2 font-normal mr-16">Adding new categories will append them to previously existing ones. To overwrite content, use the toggle</p>

          <ioio-tags

            topic="editMultipleVods"
            size="medium"
            class="mb-5"
            v-model="commonVodDataEdit.meta.categories"
            :tags="commonVodDataEdit.meta.categories"
          />

          <ioio-toggle
            size="small"
            labelLeft="Overwrite"
            topic="editMultipleVods"
            v-model="shouldOverwriteCategories"
          />
        </section>

        <section class="relative-toggle-section">

          <label class="text-black-700 text-sm mb-1">Tags</label>
          <p class="text-xs text-black-400 mb-2 font-normal mr-16">Adding new tags will append them to previously existing ones. To overwrite content, use the toggle</p>

          <ioio-tags

            topic="editMultipleVods"
            size="medium"
            class="mb-5"
            v-model="commonVodDataEdit.meta.tags"
            :tags="commonVodDataEdit.meta.tags"
          />

          <ioio-toggle
            size="small"
            labelLeft="Overwrite"
            topic="editMultipleVods"
            v-model="shouldOverwriteTags"
          />
        </section>

        <ioio-field
          label="Group"
          type="text"
          topic="editMultipleVods"
          size="medium"
          placeholder="Group"
          v-model="commonVodDataEdit.meta.group"
          class="mb-5"
        />

        <ioio-field
          label="Genre"
          type="text"
          topic="editMultipleVods"
          size="medium"
          placeholder="Genre"
          v-model="commonVodDataEdit.meta.genre"
          class="mb-5"
        />

        <ioio-radio
          topic="editMultipleVods"
          class="w-full mb-5"
          :options="accessPrivacyOptions"
          name="privacy"
          size="medium"
          v-model="privacy"
          :checkedVal="privacy"
          @input="(e) => onPrivacyInput(e)"
          label="Privacy"
          disclaimer="Set who this video is available to"
        />

        <div class="mb-5 text-black-500 flex justify-between">
          <aside>

            <div class="text-sm text-black-900">Featured video</div>
            <div class="text-xs text-blue-grey-400">This video will appear as featured in hubs</div>
          </aside>

          <ioio-toggle
            size="medium"
            topic="editMultipleVods"
            v-model="commonVodDataEdit.meta.featured"
            :labelLeft="commonVodDataEdit.meta.featured ? 'On' : 'Off'">
          </ioio-toggle>
        </div>

        <div class="mb-5 text-black-500 flex justify-between">
          <aside>

            <div class="text-sm text-black-900">List in Video Portals</div>
            <div class="text-xs text-blue-grey-400">This video will appear in video portal results</div>
          </aside>

          <ioio-toggle
            size="medium"
            topic="editMultipleVods"
            v-model="commonVodDataEdit.meta.listedInPortals"
            :labelLeft="commonVodDataEdit.meta.listedInPortals ? 'On' : 'Off'">
          </ioio-toggle>
        </div>

        <div class="text-sm text-black-900">Expires at</div>
        <div class="text-xs text-blue-grey-400 mb-3">This video will expire after the selected date and hour</div>

        <section
          class="flex"
          v-if="selectedTabName === 'Details'">

          <ioio-field
            topic="editMultipleVods"
            type="date"
            size="medium"
            placeholder="Choose date"
            v-model="commonVodDataEdit.meta.expires"
            class="w-full mr-4"
          />

          <ioio-field
            topic="editMultipleVods"
            type="time"
            size="medium"
            placeholder="Choose end time"
            v-model="commonVodDataEdit.meta.expires"
            class="w-full"
          />
        </section>

      </ioio-tab>


      <ioio-tab name="Metadata" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2  mr-2">

        <custom-meta-fields-component
          :editedAsset="commonVodDataEdit.meta"
          metaResourceType="video"
          formTopic="editMultipleVods"
          label="Overwrite metagroup"
          disclaimer="All previous content will be replaced in all videos. The changes will be applied after you save them."
        />
      </ioio-tab>


      <ioio-tab name="Hubs" class="text-xs text-black-600 flex flex-auto flex-column overflow-y pr-2 mr-2">

        <section class="flex justify-between mb-2">
          <h3 class="text-sm text-black-600">Add selection to hubs</h3>
          <ioio-toggle
            size="small"
            labelLeft="Overwrite all"
            topic="editMultipleVods"
            v-model="shouldOverwriteHubs"
          />
        </section>
        <p class="mb-4 text-black-400">By selecting a hub from the list, you will cast your videos inside. By default, any hub selected will be appended to existing list of hubs. To overwrite them, you can use the toggle.
        </p>
        <library-vod-hubs-tab-edit-component
          :editedVodMeta="commonVodDataEdit.meta"
          :onHubsChanged="onLogicalEditChange"
        />
      </ioio-tab>

    </ioio-tabs>
  </ioio-logical-edit>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  parseVodType,
  getVodPrivacy,
  getVodFeatured,
  getVodListedInPortals,
  comparePrimitiveArrays,
  compareObjectArraysByProp,
  deepClone
} from "@utils/helpers";

export default {
  data: () => ({

    selectedTabName: 'Details',

    parseVodType,
    getVodPrivacy,
    getVodFeatured,
    getVodListedInPortals,

    shouldOverwriteHubs: false,
    shouldOverwriteCategories: false,
    shouldOverwriteTags: false,

    baseProps: ['status'],

    metaProps: [
      'type',
      'categories',
      'group',
      'tags',
      'genre',
      'privacy',
      'featured',
      'listedInPortals',
      'expires'
    ],

    hubsProps: ['hubs'],

    customMetaProps: ['selectedMetaGroupGuid'],

    isCustomMetaMixed: false,
    areHubsMixed: false,

    commonVodData: {
      meta: { }
    },

    // NOTE: seperate object is required, since in the details section the UI
    // represents mixed data fields as 'Mixed', but the form (edit) section
    // doesn't allow such values and defaults for each field should be used
    commonVodDataEdit: {
      meta: { }
    },

    fieldsDefaultValuesMap: {

      categories: [],
      expires: undefined,
      featured: false,
      listedInPortals: false,
      genre: '',
      group: '',
      privacy: false,
      tags: [],
      type: 'source'
    },

    fetchedFieldsForGroupMap: {},

    privacy: 'false',

    isFormDirty: false,
  }),

  props: {

    selectedVods: Object,
    selectedVodsCount: Number,
    isVodPanelInEditMode: Boolean
  },

  mounted() {
    window.m = this;
  },

  methods: {

    onLogicalEditChange(a) {

      this.isFormDirty = true;
    },

    cleanFormState() {

      if (this.isFormDirty) {

        // The fields were edited and this may result in a mismatch between what is presented to the user and the actual data. Reevaluate to fix it.
        this.evaluateCommonProps();
      }

      this.isFormDirty = false;
    },

    updateInitialFormOptions() {

      this.privacy = `${this.commonVodDataEdit.meta.privacy}`;

      if (!this.commonVodDataEdit.meta.categories) {

        this.commonVodDataEdit.meta.categories = [];
      }

      if (!this.commonVodDataEdit.meta.tags) {

        this.commonVodDataEdit.meta.tags = [];
      }

      if (this.commonVodDataEdit.meta.expires) {

        this.commonVodDataEdit.meta.expires = new Date(this.commonVodDataEdit.meta.expires).toISOString();
      }

      this.shouldOverwriteHubs = false;
      this.shouldOverwriteCategories = false;
      this.shouldOverwriteTags = false;
    },

    setSelectedTabName(name) {

      this.selectedTabName = name;
    },

    onPrivacyInput(input) {

      const privacy = input === 'true' ? true : false;

      this.commonVodDataEdit = {
        ...this.commonVodDataEdit,
        meta: {
          ...this.commonVodDataEdit.meta,
          privacy
        }
      };
    },

    gatherFormData() {

      return {
        ...this.commonVodDataEdit,
        shouldOverwriteHubs: this.shouldOverwriteHubs,
        shouldOverwriteCategories: this.shouldOverwriteCategories,
        shouldOverwriteTags: this.shouldOverwriteTags
      };
    },

    getMetaFieldsNamesForVod(metaGroupGuid) {

      // Prevent a request for this grp, if it's already fetched
      if (this.fetchedFieldsForGroupMap[metaGroupGuid]) {

        return this.fetchedFieldsForGroupMap[metaGroupGuid];
      }

      return this.getMetaFieldsByGroup({
        orgGuid: this.organizationSelected.guid,
        metaGroupGuid
      })
      .then(resp => {

        const fields = resp.fields.map(f => f.name);

        this.fetchedFieldsForGroupMap[metaGroupGuid] = fields;

        return fields;
      });
    },

    async extractCommonVodData(comparedPropGroup, checkInnerPropName, customCheck) {

      const mergedVodData = {

        ...this.commonVodData
      };

      const mergedVodDataEdit = {

        ...this.commonVodDataEdit
      };

      const combinedPropsForChecking = [...comparedPropGroup];

      for (var i = 0; i < combinedPropsForChecking.length; i++) {

        const comparedProp = combinedPropsForChecking[i];

        const vodGuids = Object.keys(this.selectedVods);

        if (!vodGuids.length) {

          return;
        }

        const firstComparedObject = checkInnerPropName ?
          this.selectedVods[vodGuids[0]][checkInnerPropName] :
          this.selectedVods[vodGuids[0]];

        const comparedValue = firstComparedObject[comparedProp];

        if (comparedProp === 'selectedMetaGroupGuid' && comparedValue) {

          //add the relevant meta group fields for checking
          const additionalFields = await this.getMetaFieldsNamesForVod(comparedValue)

          combinedPropsForChecking.push(...additionalFields);
        }

        let isMismatchFound = false;

        let isCustomMetaMixed = false;
        let areHubsMixed = false;


        for (var j = 1; j < vodGuids.length; j++) {

          const currentVod = checkInnerPropName ?
            this.selectedVods[vodGuids[j]][checkInnerPropName] :
            this.selectedVods[vodGuids[j]];

          const currentVodValue = currentVod[comparedProp];

          // Flag this prop as `Mixed`, a difference is found
          if (currentVodValue !== comparedValue) {

            // If the prop is Array, make additional check before flaging
            if (currentVodValue && currentVodValue.constructor === Array) {

              // Only comparing hubs by name
              if (comparedProp === 'hubs' &&
                compareObjectArraysByProp(currentVodValue, comparedValue, 'name')) {

                continue;
              }

              if (comparePrimitiveArrays(currentVodValue, comparedValue)) {

                continue;
              }
            }

            isMismatchFound = true;

            if (checkInnerPropName) {

              mergedVodData[checkInnerPropName][comparedProp] = 'Mixed';
              mergedVodDataEdit[checkInnerPropName][comparedProp] =
                this.fieldsDefaultValuesMap[comparedProp];

            } else {

              mergedVodData[comparedProp] = 'Mixed';
              mergedVodDataEdit[comparedProp] = this.fieldsDefaultValuesMap[comparedProp];
            }

            if (customCheck === 'checkCustomMeta') {

              isCustomMetaMixed = true;
              mergedVodData.meta.selectedMetaGroupGuid = '';
              mergedVodDataEdit.meta.selectedMetaGroupGuid = '';

              // Break the props check, since it is not needed - the custom meta is mixed
              i = combinedPropsForChecking.length;
            }

            if (customCheck === 'checkHubs') {

              areHubsMixed = true;
              mergedVodData.meta.hubs = [];
              mergedVodDataEdit.meta.hubs = [];
            }

            break;
          }
        }

        this.isCustomMetaMixed = isCustomMetaMixed;
        this.areHubsMixed = areHubsMixed;

        // The prop is identical accross all vods
        if (!isMismatchFound) {

          if (checkInnerPropName) {

            mergedVodData[checkInnerPropName][comparedProp] = comparedValue;
            mergedVodDataEdit[checkInnerPropName][comparedProp] = comparedValue;

          } else {

            mergedVodData[comparedProp] = comparedValue;
            mergedVodDataEdit[comparedProp] = comparedValue;
          }
        }
      }

      this.commonVodData = deepClone(mergedVodData);

      this.commonVodDataEdit = deepClone(mergedVodDataEdit);
    },

    evaluateCommonProps() {

      this.extractCommonVodData(this.baseProps);
      this.extractCommonVodData(this.metaProps, 'meta');
      this.extractCommonVodData(this.hubsProps, 'meta', 'checkHubs');
      this.extractCommonVodData(this.customMetaProps, 'meta', 'checkCustomMeta');

      this.updateInitialFormOptions();
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getMetaFieldsByGroup: 'app/getMetaFieldsByGroup',
    })
  },
  computed: {
    ...mapGetters({

      organizationSelected: 'app/organizationSelected',
      possibleVodTypes: "channelManager/possibleVodTypesArr",
      accessPrivacyOptions: 'channelManager/accessPrivacyOptions'
    })
  },

  watch: {

    selectedVodsCount(newVal, oldVal) {

      /**
       * Removing vods from the selection doesn't trigger the reevaluation
       * of common props and needs a seperate watcher. selectedVodsCount
       * can no be used for adding new vods, since their info comes async,
       * but the counter is changes synchronously
       */
      if (newVal < oldVal) {

        this.evaluateCommonProps();
      }
    },

    selectedVods() {

      this.evaluateCommonProps();
    }
  }
}
</script>

<style lang="scss">

.library-multiple-vods-details-component {

.relative-toggle-section {

  @apply
  relative;

  .ds-toggle {

    @apply
    absolute;

    top: 2px;
    right: 0;
  }
}
}
</style>
