<template>
<tr v-if="!isMedicalTranscribeDataUpdating" class="medical-transcription-component">
  <td>
    <span class="size-3 truncate">
      Auto Generate Medical Transcription
    </span>
  </td>
  <td>
    <check-component
      type="checkbox"
      class="size-0 "
      :val="defaultData.value"
      :on-change="() => defaultData.value = !defaultData.value"/>
  </td>
  <td>
    <modal
    name="medical-transcription-type"
    width="600px"
    height="auto"
    :scrollable="true"
    classes="v--modal"
    :clickToClose="false"
    >
      <article class="bg-gray+5 m2 mt3 rounded-3">
        <section class="p2">
          <header class="flex justify-between mb3">

            <h3 class="m0">Edit Medical Transcription Type</h3>

            <button-component
              class="close-delete-modal-btn"
              variant="minimal"
              shape="circle"
              @click="toggleMedicalTranscriptionTypeModalOpened(false)">
                <ioio-icon icon="fa-xmark" class="w-5 h-5"/>
            </button-component>
          </header>
          <div class="box bg-white rounded-3 pl-2">
            <div class="pt-2">
              <label>
                <input type="radio" name="status" v-model="defaultType"  value="conversation">
                Conversation
              </label>
            </div>

            <div class="pb-2">
              <label>
                <input type="radio" name="status" v-model="defaultType"  value="dictation">
                Dictation
              </label>
            </div>
          </div>

          <footer class="mt3">
            <section class="flex justify-end" v-if="!isDefaultTypeUpdating">
              <button-component
                variant="primary"
                size="size-m"
                class="ml2"
                @click="updateMedicalTranscriptionType()">Update
              </button-component>
            </section>
            <div v-else class="overflow-hidden">
              <logo-loader-component width="40px" height="40px" />
            </div>
          </footer>
        </section>
      </article>
    </modal>
  </td>
  <td>
    <button-component
      v-if="defaultData.value"
      class="w-20"
      variant="primary"
      size="size-s"
      @click="toggleMedicalTranscriptionTypeModalOpened(true)">Type
    </button-component>
    <div class="w-20" v-else>
    </div>
  </td>
  <td>
    <ioio-button
      type="primary"
      variant="solid"
      size="xs"
      class="btn-green"
      @click="updateDefaultMedicalTranscribeSettings">Update
    </ioio-button>
  </td>
</tr>
<tr v-else>
  <td colspan="5">
    <logo-loader-component width="50px" height="50px" class="mt3"/>
  </td>
</tr>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isDefaultTypeUpdating: false,
    isLoading: true,
    isMedicalTranscribeDataUpdating: false,
    defaultData: {},
    defaultType: ''
  }),
  props: {},

  created() {

    this.$emit('loading', true);

    this.getMedicalTranscribeSettings();

    this.getMedicalTranscribeType();

  },

  methods: {

    async updateMedicalTranscriptionType() {

      this.isDefaultTypeUpdating = true;

      const updateVal = this.defaultType;

      await this.updateBrokerSettings({
        params: 'medical-transcribe-type',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getMedicalTranscribeType();
    },

    toggleMedicalTranscriptionTypeModalOpened(show) {

      this.$modal[show ? 'show': 'hide']('medical-transcription-type');
    },

    getMedicalTranscribeSettings() {

      this.getBrokerSettings('medical-transcribe').then((medicalTranscribeData) => {

        this.defaultData = medicalTranscribeData;
      })
      .finally(() => {

        this.isLoading == false ? (this.$emit('loading', false), this.isMedicalTranscribeDataUpdating = false) : this.isLoading = false;

      });

    },

    getMedicalTranscribeType() {

      this.getBrokerSettings('medical-transcribe-type').then((medicalTranscribeType) => {

        this.defaultType = medicalTranscribeType.value;
      })
      .finally(() => {

        this.isDefaultTypeUpdating = false;

        this.isLoading == false ? (this.$emit('loading', false), this.isMedicalTranscribeDataUpdating = false) : this.isLoading = false; ;

      });

    },


    async updateDefaultMedicalTranscribeSettings(){

      this.isMedicalTranscribeDataUpdating = true;

      const updateVal = this.defaultData.value;

      await this.updateBrokerSettings({
        params: 'medical-transcribe',

        data: {
          value: updateVal
        }
      });

      this.$toasted.success('Your changes were saved successfully');

      this.getMedicalTranscribeSettings();
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings",
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
