<template>
  <div class="images-library-component flex-column flex flex-auto">

    <div
      class="pane-toolbar pb1 flex items-center justify-between flex-none"
    >
      <div class="flex items-center filter-search-combo border-bottom pb-2 ratio1-1">
        <input-search-component :onSearchInput="handleInputSearch" size="small" class="border w-full"/>
      </div>
    </div>

    <div class="pane-content flex-auto" style="overflow: auto" ref="libraryScrollArea">
      <ul class="list-reset mt1 flex-auto" v-if="!isRequestPending">
        <li
          class="fileitem flex mb1 pointer"
          v-for="img in filteredImages"
          :key="img.guid"
          @click="() => onImgSelected(img)"
          :class="{ 'bg-blue+4': selectedImg.guid === img.guid }"
        >

          <div
            class="fileitem-image flex-none relative bg-gray+4"
            style="width: 80px; height: 50px"
            :style="{
              background: ` url('${
                img.cdnPath
              }?d=80x45&q=80&f=jpeg') no-repeat center center / contain`
            }"
          >
          </div>

          <div class="fileitem-info flex flex-column flex-auto ml2">
            <h4 class="fileitem-title size-2 truncate pr3">
              {{ img.meta && img.meta.title | truncate(64, "...") }}
            </h4>
            <div class="flex items-center">
              <tag-component
                v-if="img.meta && img.meta.group"
                class="fileitem-group mr1"
                >{{ img.meta.group || "No group" }}</tag-component
              >
              <tag-component v-if="img.meta && img.meta.genre" class="fileitem-genre">{{
                img.meta.genre
              }}</tag-component>
            </div>
          </div>
        </li>
      </ul>

      <div v-else class="h100 width+10 p2"><loader-component height="100%" spinner="logo" size="size-m"  /></div>

    </div>

    <div class="pane-footer py1 mt-2 border-top flex items-center justify-between">

      <pagination-component
        :page-selected="imagesSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="imagesSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    isRequestPending: false,

    imagesList: [],
  }),

  created() {

    this.updateImagesList();
  },

  beforeDestroy() {

    this.unstageFromFiltering('imagesLibrary');
  },

  props: {

    onSelectAsset: {

      type: Function
    },

    selectedImg: {

      type: Object
    },

    onLibraryChanged: {

      type: Function
    }
  },

  mounted() {


    window.li = this;
  },

  methods: {

    ...mapMutations({

      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({

      // new filtering actions
      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
      triggerGetImages: "images/makeGetImagesRequest",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    }),

    updateImagesList() {

      this.isRequestPending = true;

      this.triggerGetImages()
        .then(images => {

          this.imagesList = images;

          this.stageForFiltering({
            items: this.imagesList,
            division: 'imagesLibrary'
          });

          this.applyFiltering({

            filterType: 'imagesLibrary'
          });

          this.onLibraryChanged(this.imagesList);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'imagesLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'imagesLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    onImgSelected(img) {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.onSelectAsset(img)
        });

        this.raiseRedirectFlag(true);

      } else {

        this.onSelectAsset(img);
      }
    },


  },
  computed: {
    ...mapGetters({

      filteredImages: "filterAndPaginate/filteredImages",
      filteredItemsCount: "filterAndPaginate/filteredImagesItemsCount",
      imagesSearchOptions: "filterAndPaginate/imagesSearchOptions",
      msgBusMsgList: 'app/msgBusMsgList',
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',

    })
  },

  watch: {


    // NOTIFICATION socket msg type, data  new_image

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'new_image') {

        const updatedList = [...this.imagesList, newestMsg.data];

        this.imagesList = updatedList;

        this.stageForFiltering({
          items: this.imagesList,
          division: 'imagesLibrary'
        });

        this.applyFiltering({

          filterType: 'imagesLibrary'
        });

        this.$toasted.success(`New image ${this.$options.filters.truncate(newestMsg.data.meta.title, 64, "...")} uploaded successfully`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.images-library-component {
  background: var(--windowBgColor);
  color: var(--windowColor);
}


.fileitem {
  display: flex;
  margin-bottom: 2px;
  border-radius: 4px;
  padding: 0.5rem;
  position: relative;

  .fileitem-actions {
    margin: 0.25rem;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
  }
  &:hover {
    background: var(--highlightBgColor);
  }
  .fileitem-title {
    word-wrap: break-word;
    margin: 0;
    margin-bottom: 0.25rem;
    max-width: 85%;
  }
}

// .filter-search-combo {
//   border: 1px solid var(--color-gray-400);
//   border-radius: 4px;
//   overflow: hidden;
//   height: 2.5rem;
//   min-height: 2.5rem;
//   &:hover {
//     --tw-border-opacity: 1;
//     border-color: rgba(0, 119, 255, var(--tw-border-opacity));
//   }
// }
</style>
