<template>
<div class="dashboard-last-worked-on-component box">
  <h2 class="box__title e-border">Last worked on</h2>
  <div class="lwo">
    <div class="lwo__el" v-for="item in lastWorkedOnItems" :key="item.type">
      <div class="lwo__el-cntnt" @click="navigate(item.url)">
        <div class="lwo__el-thumb" :class="iconsConfig[item.type].class">
          <ioio-icon :icon="iconsConfig[item.type].icon"/>
        </div>
        <div class="lwo__el-text" v-if="item.title">
          <div v-if="item.title.length < textWidth*3/8" class="lwo__el-title">{{ item.title }}</div>
          <ioio-tooltip v-else :text="item.title" initialPostion="top-right" :multiline="true">
            <div class="lwo__el-title truncate">{{ item.title }}</div>
          </ioio-tooltip>
          <p class="lwo__el-type">{{ item.type }}</p>
        </div>
      </div>
      <div v-if="calcHumanReadableTimeDifference(item.loggedAt).length < textWidth/6 " class="lwo__el-date-mod ml-auto">{{ calcHumanReadableTimeDifference(item.loggedAt) }}</div>
      <ioio-tooltip v-else class="w-1/4" :text="calcHumanReadableTimeDifference(item.loggedAt)" initialPostion="top-left" :multiline="true">
        <div class="lwo__el-date-mod truncate ml-auto">{{ calcHumanReadableTimeDifference(item.loggedAt) }}</div>
      </ioio-tooltip>
    </div>
    <div
      v-if="!lastWorkedOnItems.length"
      class="nodata"
    >
      <div class="nodata-thumb">
        <ioio-icon icon="far-hourglass-empty"/>
      </div>
      <div class="nodata-text">No recorded activity</div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { calcHumanReadableTimeDifference } from "@utils/helpers";

export default {
  data: () => ({

    calcHumanReadableTimeDifference,

    iconsConfig: {
      Event: {
        icon: 'fa-calendar',
        class: 'is-event'
      },
      Video: {
        icon: 'fa-play-circle',
        class: 'is-video'
      },
      Channel: {
        icon: 'fa-desktop',
        class: 'is-channel'
      },
      Playlist: {
        icon: 'fa-video',
        class: 'is-playlist'
      },
      Image: {
        icon: 'fa-camera',
        class: 'is-image'
      },
      Workflow: {
        icon: 'fa-play-circle',
        class: 'is-workflow'
      },
    },
  }),
  props: {},

  created() {

  },
  methods: {

    navigate(url) {

      this.$pushRoute(url);
    },

    ...mapMutations({

    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({

      lastWorkedOnItems: "app/lastWorkedOnItems"
    }),
    textWidth () {
      return window.innerWidth / 8;
    },
  },
}
</script>

<style lang="scss" scoped>


// ***********************************************
// Last worked on section
// ***********************************************
.lwo {
  @apply flex flex-col;

  &__el {
    @apply
    w-full
    flex
    justify-between
    cursor-pointer
    mt-6;

    &-cntnt {
      @apply
      flex
      flex-grow
      w-9/12;
    }

    &-thumb {
      @apply
      flex
      justify-center
      items-center
      flex-shrink-0
      w-10
      h-10
      mr-4
      text-white
      rounded-lg;

      &.is-video {
        @apply bg-blue-600;
      }
      &.is-image {
        @apply bg-green-500;
      }
      &.is-event {
        @apply bg-dark-blue-700;
      }
      &.is-channel {
        @apply bg-purple-600;
      }
      &.is-playlist {
        @apply bg-red-600;
      }
      &.is-workflow {
        @apply bg-yellow-300;
      }
    }

    &-text {
      @apply
      flex
      justify-between
      flex-col
      w-9/12;
    }

    &-type {
      @apply text-sm text-black-500;
    }

    &-date-mod {
      @apply
      whitespace-nowrap
      items-center
      text-sm
      text-black-500;
    }
  }
}

.box {
  @apply
  overflow-visible
  p-6;
}

.box:after {
  @apply
  content-none;
}

.nodata {
  @apply
  flex
  justify-center
  items-center
  flex-col
  min-h-44;

  &-thumb {
    @apply
    flex
    justify-center
    items-center
    w-14
    h-14
    mb-4
    rounded-full
    bg-black-50
    text-lg
    text-black-500;
  }

  &-text {
    @apply text-black-500 text-sm;
  }
}
</style>
