/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'crop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" data-color="color-2" d="M16 21h4v3h-4z"/><path pid="1" d="M4 0h4v5H4zM8 15v-4H4v7a1 1 0 001 1h19v-4H8z"/><path pid="2" data-color="color-2" d="M19 5H0v4h16v6h4V6a1 1 0 00-1-1z"/></g>'
  }
})
