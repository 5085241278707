/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0a12 12 0 1012 12A12.035 12.035 0 0012 0zm-2 17.414L4.586 12 6 10.586l4 4 8-8L19.414 8z" _fill="#5c7499"/>'
  }
})
