/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#111"><path pid="0" data-color="color-2" d="M22.707 5.293l-4-4a1 1 0 00-1.414 0L14.5 4.086 19.914 9.5l2.793-2.793a1 1 0 000-1.414z"/><path pid="1" d="M13.086 5.5l-9.793 9.793a1 1 0 00-.241.391l-2 6A1 1 0 002 23a.987.987 0 00.316-.052l6-2a1 1 0 00.391-.241l9.793-9.793z"/></g>'
  }
})
