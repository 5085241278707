/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'small-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 17.414l-4.707-4.707a.999.999 0 010-1.414L14 6.586 15.414 8l-4 4 4 4L14 17.414z" _fill="#111"/>'
  }
})
