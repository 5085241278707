/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'panorama_outlined': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M35 6.667H5A3.343 3.343 0 001.667 10v20c0 1.833 1.5 3.333 3.333 3.333h30c1.833 0 3.333-1.5 3.333-3.333V10c0-1.833-1.5-3.333-3.333-3.333zM35 30H5V10h30v20zm-16.667-4.15l5.834-7.517 7.5 10H8.333l5.834-7.5 4.166 5.017z" _fill="#4F4F4F"/>'
  }
})
