/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.746 1.464l3.11 6.3 6.954 1.012a.831.831 0 01.461 1.418l-5.033 4.9 1.188 6.926a.832.832 0 01-1.207.877L12 19.632 5.78 22.9a.833.833 0 01-1.207-.878L5.761 15.1.728 10.2a.831.831 0 01.461-1.418l6.954-1.017 3.11-6.3a.833.833 0 011.493-.001z" _fill="#5c7499"/>'
  }
})
