import sdk from "@sdk";

const makeGetImagesRequest = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
      type: 'getImages',
      baseUrlType: 'BASE_IMAGE_URL'
    }, {
      root: true
    })
    .then((images) => {

      console.log('IMG', images)

      return images;
    });
};

// NOTE: should not be used through the SDK, since just the url string is needed.
const makeGetDownloadImageUrlRequest = ({ rootState, commit, dispatch }, guid) => {

  const downloadUrl = `${rootState.app.BASE_IMAGE_URL}/image/${guid}`;

  return downloadUrl;
};

const makeGetImageDetailsRequest = ({ state, commit, dispatch }, guid) => {

  return dispatch('app/makeRequest', {
      type: 'getImageDetails',
      baseUrlType: 'BASE_IMAGE_URL',
      params: guid
    }, {
      root: true
    })
    .then((details) => {

      console.log('details', details)

      return details;
    });
};

const makeGetImageUploadConfig = ({ state, commit, dispatch }, guid) => {

  return dispatch('app/makeRequest', {
      type: 'getImageUploadConfig',
      baseUrlType: 'BASE_IMAGE_URL',
      params: guid
    }, {
      root: true
    })
    .then((config) => {

      console.log('config', config)

      return config;
    });
};

const makeUploadImageMetaRequest = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
      type: 'uploadImageMeta',
      baseUrlType: 'BASE_IMAGE_URL',
      payload
    }, {
      root: true
    });
};

const getImageApiKeys = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getImageApiKeys',
    baseUrlType: 'BASE_IMAGE_URL',
  }, {
    root: true
  });
}

const getImageApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getImageApiKey',
    baseUrlType: 'BASE_IMAGE_URL',
    params
  }, {
    root: true
  });
}

const storeNewImageApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'storeNewImageApiKey',
    baseUrlType: 'BASE_IMAGE_URL',
    payload
  }, {
    root: true
  });
}

const updateImageApiKey = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateImageApiKey',
    baseUrlType: 'BASE_IMAGE_URL',
    payload
  }, {
    root: true
  });
}

const deleteImageApiKey = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'deleteImageApiKey',
    baseUrlType: 'BASE_IMAGE_URL',
    params
  }, {
    root: true
  });
}

const updateImagePermissions = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateImagePermissions',
    baseUrlType: 'BASE_IMAGE_URL',
    payload
  }, {
    root: true
  });
}

const getImageSettings = ({ state, commit, dispatch }) => {

  return dispatch('app/makeRequest', {
    type: 'getImageSettings',
    baseUrlType: 'BASE_IMAGE_URL',
  }, {
    root: true
  });
}

const updateImageSettings = ({ state, commit, dispatch }, payload) => {

  return dispatch('app/makeRequest', {
    type: 'updateImageSettings',
    baseUrlType: 'BASE_IMAGE_URL',
    payload
  }, {
    root: true
  });
}

const getImagesElastic = ({ state, commit, dispatch }, params) => {

  return dispatch('app/makeRequest', {
    type: 'getImagesElastic',
    baseUrlType: 'BASE_IMAGE_URL',
    params
  }, {
    root: true
  });
};

export {
  makeGetImagesRequest,
  makeGetDownloadImageUrlRequest,
  makeGetImageDetailsRequest,
  makeGetImageUploadConfig,
  makeUploadImageMetaRequest,


  getImageApiKeys,
  getImageApiKey,
  storeNewImageApiKey,
  updateImageApiKey,
  deleteImageApiKey,
  updateImagePermissions,
  getImageSettings,
  updateImageSettings,

  getImagesElastic,
}