<template>
<section>

  <archive-vod-confirm-modal-component
    :vod="vod"
    :toggleArchiveModalOpened="toggleArchiveModalOpened"
    :isModalOpened="isArchiveVodModalOpened"
    v-if="isArchiveVodModalOpened"
  />

  <unarchive-vod-confirm-modal-component
    :vod="vod"
    :toggleUnarchiveModalOpened="toggleUnarchiveModalOpened"
    :isModalOpened="isUnarchiveVodModalOpened"
    v-if="isUnarchiveVodModalOpened"
  />


  <div
    v-if="vod.status !== 'archive'"
    @click.stop="toggleArchiveModalOpened(true)"
  >
    <!-- The slot will allow for a custom open btn to be added to the component
     (just as markup, the actual opening is triggered from above - toggleArchiveModalOpened)
    -->
    <slot>
      <li class="p-2 hover-red hover-bg-red+5 flex items-center size-2 font-medium cursor-pointer">
        <ioio-icon icon="fas-trash-xmark" class="w-3.5 h-3.5 mr-2"/>Archive
      </li>
    </slot>
  </div>

  <div v-else
    @click.stop="toggleUnarchiveModalOpened(true)"
  >

    <!-- The slot will allow for a custom open btn to be added to the component
     (just as markup, the actual opening is triggered from above - toggleUnarchiveModalOpened)
    -->
    <slot>
      <li class="p-2 hover-red hover-bg-red+5 flex items-center size-2 font-medium cursor-pointer">
        <ioio-icon icon="fa-solid fa-box-open" class="w-3.5 h-3.5 mr-2" />Unarchive
      </li>
    </slot>

  </div>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {

  data: () => ({

    isUnarchiveVodModalOpened: false,
    isArchiveVodModalOpened: false
  }),

  props: {

    vod: Object
  },

  methods: {

    toggleArchiveModalOpened(newVal) {

      this.isArchiveVodModalOpened = newVal;
    },

    toggleUnarchiveModalOpened(newVal) {

      this.isUnarchiveVodModalOpened = newVal;
    }
  },
}
</script>

<style lang="scss" scoped>



</style>
