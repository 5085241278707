<template>
<div class="import-wizard-component">
  <ioio-loader v-if="isGoogleRequestPending" isShown />
  <ioio-drawer :show.sync="drawer" :size="500">
    <div class="bg-white p-8 h-full overflow-scroll">
      <div class="drawer-flex">
        <h3>
          Google Drive Folders
        </h3>
        <ioio-icon class="mt-2 cursor-pointer" icon="fa-xmark" @click="drawer=false"></ioio-icon>
      </div>
      <h5 class="mb-8">
        Here you can select the folders we can automatically ingest video assets from
      </h5>
      <hr class="my-3" />

      <div class="flex">
        <h6>
          First accessed:
        </h6>
        <h6 class="text-black-400 ml-2">
          {{moment(googleFolders.createdAt).format('DD-MM-YYYY')}} at {{moment(googleFolders.createdAt).format('HH:mm')}}
        </h6>
      </div>
      <div class="flex">
        <h6>
          Last updated:
        </h6>
        <h6 class="text-black-400 ml-2">
          {{moment(googleFolders.updatedAt || googleFolders.createdAt).format('DD-MM-YYYY')}} at {{moment(googleFolders.updatedAt || googleFolders.createdAt).format('HH:mm')}}
        </h6>
      </div>

      <div class="flex mt-4 text-xs">
        Enable
        <span class="text-black-400">*</span>
        <ioio-checkbox
          v-model="googleFolders.enabled"
          size="small"
          class="ml-2 mt-2">
        </ioio-checkbox>
      </div>
      <div class="text-black-400 text-xs mb-4 mt-1">*This setting enables/disables the automatic ingestion</div>
      <hr class="my-3" />

      <div class="drawer-flex">
        <h4>
          Selected Folders
        </h4>
        <ioio-button
          size="small"
          @click="googleFolders.enabled ? saveWatchFolders() : isAutoIngestModalOpened = true">
            Save
        </ioio-button>
      </div>
      <ioio-table
        v-if="googleFolders.selectedFolders && googleFolders.selectedFolders.length > 0"
        class="h-auto p-0"
        responsive
        :responsiveFlip="false"
        :group="false"
      >
        <template slot="thead">
          <tr class="border-none">
            <th class="wizard-table-head-text folder">
              Folders
            </th>
            <th class="wizard-table-head-checkbox flex-google-checkbox">
              <span v-if="deselectAllFolders"> Deselect all </span>
              <ioio-checkbox
                size="small"
                v-model="deselectAllFolders"
                @input="toggleAllFolders"
                class="pt-1 ml-2">
              </ioio-checkbox>
            </th>
          </tr>
        </template>
        <template slot="tbody">
          <tr
            v-for="folder in googleFolders.selectedFolders"
            :key="folder.id"
            :style="folder.selected ? {background: '#F0F7FF'} : null"
          >
            <td class="wizard-table-body-text folder">
              {{folder.name}}
            </td>
            <td class="flex-google-checkbox w-20">
              <ioio-checkbox
                size="small"
                class="pt-4"
                v-model="folder.selected"
                @check="toggleSelectedFolder(folder)">
              </ioio-checkbox>
            </td>
          </tr>
        </template>
      </ioio-table>
      <h5 v-else>
        You have no selected folders
      </h5>
      <hr class="my-3" />
      <h4>
        Available Folders
      </h4>
      <ioio-table
        v-if="googleFolders.availableFolders && googleFolders.availableFolders.length > 0"
        class="h-auto p-0"
        responsive
        :responsiveFlip="false"
        :group="false"
      >
        <template slot="thead">
          <tr class="border-none">
            <th class="wizard-table-head-text folder">
              Folders
            </th>
            <th class="wizard-table-head-checkbox flex-google-checkbox">
              <span v-if="!selectAllFolders"> Select all </span>
              <ioio-checkbox
                class="pt-1 ml-2"
                size="small"
                v-model="selectAllFolders"
                @input="toggleAllFolders">
              </ioio-checkbox>
            </th>
          </tr>
        </template>
        <template slot="tbody">
          <tr
            v-for="folder in googleFolders.availableFolders"
            :key="folder.id"
            :style="folder.selected ? {background: '#F0F7FF'} : null"
          >

            <td class="wizard-table-body-text folder">
              {{folder.name}}
            </td>
            <td class="flex-google-checkbox w-20">
              <ioio-checkbox
                size="small"
                class="pt-4"
                v-model="folder.selected"
                @check="toggleSelectedFolder(folder)">
              </ioio-checkbox>
            </td>
          </tr>
        </template>
      </ioio-table>
      <h5 v-else>
        You have no available folders for select in your Google Drive
      </h5>
    </div>
  </ioio-drawer>

  <ioio-modal
    class=""
    :show.sync="isAutoIngestModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        You haven`t enabled automatic ingestion!
      </div>
    </template>

    <h3 class="">
      Are you sure you want to continue?
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isAutoIngestModalOpened=false"
        >
          No
        </ioio-button>
        <ioio-button
          @click.stop.prevent="saveWatchFolders"
        >
          Yes, continue
        </ioio-button>
      </div>
    </template>

  </ioio-modal>
  <ioio-modal
    class=""
    :show.sync="isConfirmDisableModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        Are you sure you want to disconnect this integration?
      </div>
    </template>

    <h3 class="">
      All integrations with Google Drive will be disconnect.
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isConfirmDisableModalOpened=false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmDisable"
        >
          Disconnect
        </ioio-button>
      </div>
    </template>

  </ioio-modal>
  <div class="wizard-head">
    <div class="wizard-head-start">
      <div class="wizard-head-start-text">
        <span v-if="!metaPage">Select videos to import from {{choosenDistributor.title}}</span>
        <span v-else>Customise videos before import </span>
      </div>
      <div class="wizard-head-start-refresh" v-if="!isRequestPending && !isSeeding && !metaPage && !isGoogleRequestPending">
        <ioio-button
          v-if="!justnow && !choosenDistributor.isCurrentlySeeding"
          type="minimal"
          iconLeft="fas-rotate-right"
          class="wizard-head-start-refresh-button"
          @click="seedDistributor(false)"
        >
          <p class="wizard-head-start-refresh-button-text"> Refresh list </p>
        </ioio-button>
        <div class="wizard-head-start-refresh-text flex pt-2" v-if="choosenDistributor.isCurrentlySeeding && !isSeeding">
          The list is being refreshed
        </div>
        <div class="wizard-head-start-refresh-text flex pt-2" v-if="justnow">
          Last refreshed:
          <span class="text-green-500 ml-1"> just now </span>
        </div>
        <div class="wizard-head-start-refresh-text" v-if="!justnow && !choosenDistributor.isCurrentlySeeding && choosenDistributor.lastSeededAt">
          Last refreshed: {{lastRefreshed.date}} at {{lastRefreshed.time}}
        </div>
      </div>
    </div>
    <div class="wizard-head-end">
      <ioio-dropdown-base
        v-if="choosenDistributor.guid === 'google-drive' && !isRequestPending && !isGoogleRequestPending && !metaPage"
        class="mb-4 mr-6"
        alignX="left">

        <template slot="title">
          <ioio-button
            type="primary"
            fullWidth=""
            iconRight="fal-chevron-down"
            size="small"
          >
            Google Settings
          </ioio-button>
        </template>
        <div class="m-2 dropdown__menu-item">
          <ioio-button
            v-if="choosenDistributor.guid === 'google-drive' && !isRequestPending"
            iconLeft="fal-folder-arrow-down"
            type="secondary"
            variant="text-boxed"
            size="small"
            @click.stop.prevent="openDrawer">
              Watch Folders
          </ioio-button>
        </div>
        <div class="m-2 dropdown__menu-item">
          <ioio-button
            v-if="choosenDistributor.guid === 'google-drive' && !userGoogleDriveTempData && !isRequestPending"
            type="danger"
            variant="tonal"
            iconLeft="fal-cloud-slash"
            size="small"
            @click.stop.prevent="isConfirmDisableModalOpened=true">
              Disconnect
          </ioio-button>
        </div>
      </ioio-dropdown-base>
      <ioio-button
        type="secondary"
        variant="outline"
        class="mr-6"
        size="small"
        @click.stop.prevent="stopImport"
      >
        <span class="text-black-900 text-sm font-medium">
          Cancel
        </span>
      </ioio-button>
      <ioio-button
        v-if="!isRequestPending&&videoList.length>0 && !metaPage && !isGoogleRequestPending"
        class="mr-6"
        type="primary"
        variant="outline"
        size="small"
        @click.stop.prevent="openMetaPage('All')"
      >
        Import all {{totalItems}}
      </ioio-button>
      <ioio-button
        v-if="!isRequestPending&&videoList.length>0 && metaPage"
        class="mr-6"
        type="primary"
        variant="outline"
        size="small"
        iconLeft="fas-arrow-left"
        @click.stop.prevent="backToSelected"
      >
        Back to selected
      </ioio-button>
      <ioio-button
        class="w-36 justify-center"
        v-if="!isRequestPending && !metaPage && !isGoogleRequestPending"
        type="primary"
        size="small"
        @click.stop.prevent="openMetaPage('Selected')"
        :disabled="selected.length === 0"
      >
        {{selectedText}}
      </ioio-button>
      <ioio-button
        v-if="!isRequestPending&&videoList.length>0 && metaPage"
        class="w-36 justify-center"
        type="primary"
        size="small"
        @click.stop.prevent="ingest()"
        :disabled="(selected.length === 0 && !importAll)"
      >
        Save and Import
      </ioio-button>
    </div>
  </div>
  <div class="wizard-loader" v-if="isRequestPending || (isSeeding && pause)">
    <ioio-icon icon="fad-spinner" class="text-blue-600 fa-spin"/>
    <p class="wizard-loader-text">
      Please, stand by - we are getting all of your videos from {{choosenDistributor.title}}
    </p>

  </div>
  <div class="wizard-loading" v-if="isRequestPending || (isSeeding && pause)">
    <div class="wizard-loading-row" v-for="row in 7" :key="row">
      <div class="wizard-loading-row-image">
      </div>
      <div class="wizard-loading-row-label">
      </div>
      <div class="wizard-loading-row-label">
      </div>
    </div>
  </div>
  <div class="wizard-table" v-if="!metaPage && !isRequestPending  && videoList.length > 0 || !metaPage && !isRequestPending && searchWord !== ''">
    <div class="wizard-table-tabs">
      <ioio-tabs size="medium" :notification="false" class="my-10 -ml-6" @input="changeTab">
        <ioio-tab name="All videos" :selected="allVideos === 'All videos'">

        </ioio-tab>
        <ioio-tab name="Selected" :selected="allVideos === 'Selected'">

        </ioio-tab>
      </ioio-tabs>

      <div class="relative w-80" v-if="allVideos === 'All videos'">
        <ioio-field
          type="text"
          isSearch
          size="medium"
          placeholder="Search by title, description, email"
          :isBorderless="false"
          v-model="searchWord"
          @onChange="onSearch"
        ></ioio-field>
      </div>
    </div>
    <ioio-table
      v-if="list.length > 0"
      class="h-auto p-0"
      responsive
      :responsiveFlip="false"
      :group="false"
    >
      <template slot="thead">
        <tr class="border-none">
          <th class="wizard-table-head-checkbox ">
            <ioio-checkbox
              size="small"
              v-model="selectAll"
              @input="toggleAll">
              <span v-if="!selectAll && allVideos === 'All videos'"> Select all </span>
              <span v-else> Deselect all </span>
            </ioio-checkbox>
          </th>
          <th class="wizard-table-head-text" @click="sortVods('title')">
            NAME
            <ioio-icon v-if="sort.title" icon="fas-sort-down" class="ml-1 pb-0.5"/>
            <ioio-icon v-else icon="fas-sort-up" class="ml-1"/>
          </th>
          <th class="wizard-table-head-text">
           {{choosenDistributor.guid === 'google-drive' ? 'SHARED WITH' : 'DESCRIPTION'}}
          </th>
          <th class="wizard-table-head-text" @click="sortVods('createdBy')">
            {{choosenDistributor.guid === 'google-drive' ? 'OWNER' : 'RECORDED BY'}}
            <ioio-icon v-if="sort.createdBy" icon="fas-sort-down" class="ml-1 pb-0.5"/>
            <ioio-icon v-else icon="fas-sort-up" class="ml-1"/>
          </th>
          <th class="wizard-table-head-text-end">
          </th>
          <th class="wizard-table-head-text-end">
            {{choosenDistributor.guid === 'google-drive' ? 'FOLDER' : 'SUBTITLES'}}
          </th>
          <th class="wizard-table-head-text-end" @click="sortVods('duration')">
            DURATION
            <ioio-icon v-if="sort.duration" icon="fas-sort-down" class="ml-1 pb-0.5"/>
            <ioio-icon v-else icon="fas-sort-up" class="ml-1"/>
          </th>
          <th class="wizard-table-head-text-end" @click="sortVods('createdAt')">
            {{choosenDistributor.guid === 'google-drive' ? 'CREATED' : 'RECORDED AT'}}
            <ioio-icon v-if="sort.createdAt" icon="fas-sort-down" class="ml-1 pb-0.5"/>
            <ioio-icon v-else icon="fas-sort-up" class="ml-1"/>
          </th>
        </tr>
      </template>
      <template slot="tbody">
        <tr v-for="vod,i in list" :key="vod.externalId" class="wizard-table-body" :style="vod.selected ? {background: '#F0F7FF'} : null">
          <td class="flex w-40">
            <ioio-checkbox
              size="small"
              class="pt-4"
              v-model="vod.selected"
              @check="toggleSelected(vod)">
            </ioio-checkbox>
            <div class="wizard-table-body-vthumb" v-if="vod.meta && vod.meta.image">
              <img  :src="vod.meta.image"/>
            </div>
            <div class="wizard-table-body-zoom" v-else>
              <img
                v-if="vod.distributorGuid === 'zoom'"
                src="~@assets/SVGs/zoom_logo_blue.svg"
                alt="zoom Logo"
                class="object-cover"/>

              <span v-else-if="vod.distributorGuid === 'brightcove'" class="text-blue-600"> BRIGHTCOVE </span>
              <span v-else-if="vod.distributorGuid === 'google-drive'" class="text-blue-600 whitespace-pre-wrap text-center"> GOOGLE DRIVE </span>
            </div>
          </td>
          <td class="wizard-table-body-text">
            {{vod.meta.title}}
          </td>
          <td class="wizard-table-body-text" v-if="vod.distributorGuid != 'google-drive'">
            {{ vod.meta.description }}
          </td>
          <td v-else-if=" vod.meta.sharedWith.join(', ').length < 40" class="wizard-table-body-text">
              {{vod.meta.sharedWith.join(', ')}}
          </td>
          <td v-else class="wizard-table-body-tooltip">
            <ioio-tooltip  :text="vod.meta.sharedWith.join(', ')" class="tooltip-z w-full">
              <div class="truncate pt-1" > {{vod.meta.sharedWith.join(', ')}} </div>
            </ioio-tooltip>
          </td>
           <td class="wizard-table-body-text">
            {{vod.meta.createdBy}}
          </td>
          <td>
          </td>
          <td v-if="vod.distributorGuid != 'google-drive'" class="wizard-table-body-text-end">
            <ioio-icon icon="fa-light fa-closed-captioning" v-if="vod.meta.hasCaptions"/>
          </td>
          <td v-else class="wizard-table-body-text-end">
            {{vod.meta.folder}}
          </td>
          <td class="wizard-table-body-text-end" >
             {{ parseDuration(vod.meta.duration) }}
          </td>
          <td class="wizard-table-body-text-end" >
             {{moment(vod.meta.createdAt).format('DD-MM-YYYY')}} at {{moment(vod.meta.createdAt).format('HH:mm')}}
          </td>
        </tr>
      </template>
    </ioio-table>
    <div class="wizard-pagination" v-if="allVideos === 'All videos' && totalItems > 0">
      <ioio-dropdown-base
        class="relative"
        alignX="left"
        :fullWidth="false"
        @change="changeDropdown"
        :overflow="true"
      >
        <template slot="title">
          <ioio-button
            type="secondary"
            variant="outline"
            iconRight="fal-chevron-down"
            size="small"
          >
            {{elasticOptions.perPageItems}}
          </ioio-button>
        </template>
          <slide-y-down-transition>
            <div
              class="wizard-dropdown"
              ref="menu"
              v-show="isOpenVodNumber"
            >
              <div
                v-for="option,i in vodNumberOptions"
                :key="i"
                class="wizard-dropdown-option"
                @click="changeVodNumberOptions(option)"
              >
                {{option}}
              </div>
            </div>
          </slide-y-down-transition>
      </ioio-dropdown-base>
      <pagination-component
        class="flex-none"
        :page-selected="elasticOptions.currentPage"
        :click-callback="onPaginationClicked"
        :per-page-items="elasticOptions.perPageItems"
        :totalItemsLength="totalItems"
      />
    </div>
  </div>
  <div class="wizard-nodata" v-if="!metaPage && !isRequestPending && totalItems === 0 && list.length === 0 && !pause">
    <div class="wizard-nodata-container">
      <div class="wizard-nodata-img">
        <img src="~@assets/SVGs/video_gallery.svg" />
        <img src="~@assets/SVGs/video_gallery2.svg" class="wizard-nodata-video"/>
      </div>
      <div class="wizard-nodata-text" v-if="lastWord !== ''">
        You have no videos in {{choosenDistributor.title}} to import for the specified search
      </div>
      <div class="wizard-nodata-secondtext" v-if="lastWord !== ''">
        Please adjust your search
      </div>
      <div class="wizard-nodata-text" v-if="lastWord === ''" >
        You have no videos in {{choosenDistributor.title}} to import
      </div>
      <div class="wizard-nodata-secondtext"  v-if="lastWord === ''">
        Go to {{choosenDistributor.title}}
      </div>
      <ioio-button
        v-if="lastWord === ''"
        class="wizard-nodata-button"
        type="secondary"
        variant="outline"
        size="small"
        iconLeft="fas-arrow-left"
        @click="stopImport"
      >
        Go back
      </ioio-button>
    </div>
  </div>
  <div class="wizard-nodata" v-if="!metaPage && !isRequestPending && allVideos === 'Selected' && list.length === 0">
    <div class="wizard-nodata-container">
      <div class="wizard-nodata-img">
        <img src="~@assets/SVGs/video_gallery.svg" />
        <img src="~@assets/SVGs/video_gallery2.svg" class="wizard-nodata-video"/>
      </div>
      <div class="wizard-nodata-text" >
        No selections
      </div>
    </div>
  </div>
  <div class="wizard-meta" v-if="metaPage">
    <div class="wizard-meta-text">
      <span> Tags and categories help you organise your videos and how they will appear in your hubs.</span>
      <span> All custom data will be applied to each video when being imported.</span>
      <span > If there is initially metadata shown below, this is your organization's default data.</span>
    </div>
    <section class=" pb-4 mb-6 mt-8 w-1/3">
      <vue-form :state="metaFormState">

        <validate :custom="{customValidator: categoriesLength}" tag="">
          <h4 class="wizard-meta-label">Category</h4>
          <ioio-tags
            name="categories"
            size="small"

            class="w-fll mb-8 wizard-meta-tags"
            v-model="customMeta.categories"
            :tags="customMeta.categories"
          />

          <field-messages name="categories" show="$submitted || $dirty || $touched">
            <div class="size-3" slot="customValidator">Categories length should not be more than 64 characters</div>
          </field-messages>

        </validate>

        <validate :custom="{customValidator: tagsLength}" tag="">
          <h4 class="wizard-meta-label">Tags</h4>
          <ioio-tags
            name="tags"
            size="small"

            class="w-fll wizard-meta-tags"
            v-model="customMeta.tags"
            :tags="customMeta.tags"
          />

          <field-messages name="tags" show="$submitted || $dirty || $touched">
            <div class="size-3" slot="customValidator">Tags length should not be more than 64 characters</div>
          </field-messages>

        </validate>



      </vue-form>
    </section>
    <section class="hubs-select-section pb-4 mb-6 w-1/3">

      <h4 class="wizard-meta-label">Add to hubs</h4>
      <div v-if="combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded">

        <ioio-checkbox
          v-for="hub in combinedSiteBuilderHubs"
          :key="hub.id"
          size="small"
          disclaimer=""
          v-model="addedHubsGuidsMap[hub.id]"
          @input="(newVal) => toggleAddHubToList(hub, newVal)"
        >
          <span class="text-sm">{{ hub.name }}</span>
        </ioio-checkbox>
      </div>
      <h3 v-else-if="!combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded" class="text-sm text-black-400">No hubs configured</h3>

    </section>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
import { getHHMMSSFromMS } from "@utils/helpers";
import moment from "moment-timezone";

export default {
  data: () => ({
    isRequestPending: false,
    videoList: [],
    selected:[],
    allVideos: 'All videos',
    selectAll: false,
    importAll: false,
    sort: {
      createdAt: true,
      createdBy: true,
      duration: true,
      title: true
    },
    vodPerPage: 20,
    vodNumberOptions: [ 20, 50, 100],
    isOpenVodNumber: false,
    pagination: {
      currentPage: 1
    },
    elasticOptions: {
      currentPage: 1,
      perPageItems: 20,
      sortBy: [{"meta.createdAt": "desc"}]
    },
    searchWord: '',
    lastWord: '',
    totalItems: 0,
    justnow: false,
    pause: false,
    choosenDistributor:{},

    metaPage: false,
    customMeta: {

      hubs: [],
      tags: [],
      categories: []
    },

    zoomMeta:
      {"tags":["technical"],"categories":["zoom"]}
    ,
    metaFormState: {},
    addedHubsGuidsMap: {},
    addedHubsMap: {},
    readyItems: [],
    isConfirmDisableModalOpened: false,
    drawer: false,
    googleFolders: {},
    selectAllFolders: false,
    deselectAllFolders: false,
    isAutoIngestModalOpened: false,
    isGoogleRequestPending: false
  }),
  props: {
    seedData: {
      type: Object,
      default: {
        data: {}
      }
    },
    seed: {
      type: Boolean,
      default: false
    },
    seededDistributor: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapMutations({
      setImportDistributorsList: "ingest/SET_IMPORT_DISTRIBUTORS_LIST",
      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      setChoosenDistributor: "ingest/SET_CHOOSEN_DISTRIBUTOR",
      setUserGoogleDriveData: "ingest/STORE_USER_GOOGLE_DRIVE_DATA"
    }),
    ...mapActions({
      listIngestDistributorSources: "ingest/listIngestDistributorSources",
      getIngestElastic: 'ingest/getIngestElastic',
      seedIngestDistributor: "ingest/seedIngestDistributor",
      ingestDistributorsSources: "ingest/ingestDistributorsSources",
      updateIngestDistributors: "ingest/updateIngestDistributors",
      getIngestDistributors: "ingest/getIngestDistributors",
      getGoogleDriveFolders: "ingest/getGoogleDriveFolders",
      saveGoogleDriveFolders: "ingest/saveGoogleDriveFolders"
    }),

    openDrawer() {

      this.isGoogleRequestPending = true;

      const payload = {
        query: {
          refreshToken: this.userGoogleDriveData && this.userGoogleDriveData.token ? this.userGoogleDriveData.token : this.userGoogleDriveTempData.token
        }
      };
      this.getGoogleDriveFolders(payload)
        .then((data) => {
          let selected = [];
          let available = [];
          data.availableFolders.sort((a, b) => a.name.localeCompare(b.name)).forEach(folder =>{
            if (data.selectedFolders.includes(folder.id)) {
              folder.selected = true;
              selected.push(folder);
            } else {
              folder.selected = false;
              available.push(folder);
            }
          });

          this.googleFolders = {...data};
          this.googleFolders.selectedFolders = selected;
          this.googleFolders.availableFolders = available;

          selected.length > 0 ? this.deselectAllFolders = true : null;
        })
        .finally(() => {

          this.isGoogleRequestPending = false;
          this.drawer = true;
        });

    },

    toggleAddHubToList(hub, newVal) {

      if (newVal) {

        this.addedHubsGuidsMap[hub.id] = true;

        this.addedHubsMap[hub.id] = {

          id: hub.id,
          name: hub.name,
          permissions: []
        };

      } else {

        this.addedHubsGuidsMap[hub.id] = false;
        delete this.addedHubsMap[hub.id];
      }
    },

    seedDistributor (type) {

      this.isRequestPending = true;

      this.videoList = [];

      this.selected = [];

      const payloadSeed = {

        distributorGuid: this.$route.query.distributor,

        data: {

          shouldIngestAll: type
        }
      };

      if (this.$route.query.distributor === 'google-drive') {

        payloadSeed.query = {

          refreshToken: this.userGoogleDriveData && this.userGoogleDriveData.token ? this.userGoogleDriveData.token : this.userGoogleDriveTempData.token
        }
      };

      this.seedIngestDistributor(payloadSeed)
        .then(() => {

          this.pause = true;
          if (type) {
            this.customMeta =  {
            hubs: [],
            tags: [],
            categories: []
          },
          this.$emit('ingest');
          }
        })
        .finally(() => {

        });

    },
    onSearch(newVal) {

      let searchTimer;

      this.elasticOptions.currentPage = 1;

      clearTimeout(searchTimer);

      searchTimer = setTimeout(() => {

        if (this.lastWord !== this.searchWord)  {

          this.listDdistributorsSources()
        }
      }, 1500);

    },
    onPaginationClicked(page) {

      this.elasticOptions.currentPage = page;

      this.listDdistributorsSources();
    },

    changeDropdown(state) {

      this.isOpenVodNumber = state;
    },
    stopImport() {

      const routeParams = {

          path: "/vod/library/ingest"
        };

      return this.$pushRoute(routeParams);
    },

    listInitialSources() {

      this.isRequestPending = true;

      let payload = {

        distributorGuid: this.choosenDistributor.guid,

        query: {

          limit: this.vodPerPage,

          status: 'non-ingested',

          page: this.pagination.currentPage

        }
      };

      if (this.choosenDistributor.guid === 'google-drive') {

        payload.query.refreshToken = this.userGoogleDriveData && this.userGoogleDriveData.token ? this.userGoogleDriveData.token : this.userGoogleDriveTempData.token;
      };

      this.listIngestDistributorSources(payload)
        .then((data) => {

          this.videoList = [...data.videos];

          this.totalItems = data.pagination.totalRecords;

        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },

    listDdistributorsSources() {

      this.lastWord = this.searchWord;

      this.isRequestPending = true;

      const from = (this.elasticOptions.currentPage - 1) * this.elasticOptions.perPageItems;

      const size = this.elasticOptions.perPageItems;

      const sort = this.elasticOptions.sortBy;

      const searchOptions = {
        from,
        size,
        sort
      };
      searchOptions.query =   {

        "bool": {

          "must": [

            {
              "match": {

                "distributorGuid": this.$route.query.distributor
              }
            }
          ],

          "must_not": {

            "exists": {

              "field": "ingestJob"
            }
          }
        }
      }

      if (this.searchWord !== "") {

        searchOptions.query.bool.must.push({

          "query_string": {

            "query": '*' + this.searchWord + '*',

            "fields" : ["meta.title", "meta.createdBy", "meta.description"]
          }
        });
      }

      if (this.$route.query.distributor === 'google-drive') {

        searchOptions.query.bool.must.push({

          "bool": {

            "should": [{

              "term": {

                "meta.createdBy.keyword": this.userGoogleDriveData && this.userGoogleDriveData.email
                  ? this.userGoogleDriveData.email : this.userGoogleDriveTempData ? this.userGoogleDriveTempData.email : '',
              }
            },
            {
              "term": {

                "meta.sharedWith.keyword": this.userGoogleDriveData && this.userGoogleDriveData.email
                  ? this.userGoogleDriveData.email : this.userGoogleDriveTempData ? this.userGoogleDriveTempData.email : '',
              }
            }],
          }
        });
      };

      this.getIngestElastic(searchOptions)
        .then((response) => {

          this.videoList  = response.hits.hits.map(i => {

            return i._source;
          });

          this.totalItems = response.hits.total.value;

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    ingest() {

      this.metaPage = false;

      if (this.importAll) {

        this.saveDistributorSettings();

      } else {

        return new Promise((resolve) => {

          resolve( this.addMetaData() );
        })
        .then( this.ingestVideos() );
      }

    },
    saveDistributorSettings() {

      this.isRequestPending = true;

      this.selected = [];

      let settingsName = '';

      if (this.choosenDistributor.guid === 'google-drive') {

        settingsName = 'googleDriveSettings'
      } else {

        settingsName = this.choosenDistributor.guid + 'Settings';
      };

      const hubs = [];

      for (const hubId in this.addedHubsMap) {

        if (this.addedHubsMap[hubId]) {

          hubs.push(this.addedHubsMap[hubId]);
        }
      }

      const payload = {

        distributorGuid: this.choosenDistributor.guid,

        data: {

          enabled: true
        }
      };

      payload.data[settingsName] = {...this.choosenDistributor[settingsName]};

      payload.data[settingsName].customMeta = {
        ...this.customMeta,
        hubs
      };

      if (payload.data[settingsName].hasOwnProperty('installUrl')) {

        delete payload.data[settingsName].installUrl;
      }

      this.updateIngestDistributors(payload)
        .then(() => {

          this.seedDistributor(true);

         })

        .finally(() => {

          this.isRequestPending = false;
        });
    },

    ingestVideos() {

      this.isRequestPending = true;

      this.metaPage = false;

      const payload = {

        distributorGuid: this.$route.query.distributor,

        data: {

          sources: [...this.readyItems]
        }
      };

      if (this.choosenDistributor.guid === 'google-drive') {

        payload.query = {

          refreshToken: this.userGoogleDriveData && this.userGoogleDriveData.token ? this.userGoogleDriveData.token : this.userGoogleDriveTempData.token
        };
      };

      this.ingestDistributorsSources(payload)
        .then(() => {
          this.customMeta =  {
            hubs: [],
            tags: [],
            categories: []
          },
          this.$emit('ingest');
        })
        .finally(() => {
           this.isRequestPending = false;
        });

    },
    addMetaData () {

      this.isRequestPending = true;

      const hubs = [];

      for (const hubId in this.addedHubsMap) {

        if (this.addedHubsMap[hubId]) {

          hubs.push(this.addedHubsMap[hubId]);
        }
      }

      const customMeta = {
        ...this.customMeta,
        hubs
      };

      this.selected.forEach(element =>{

        this.readyItems.push({

          externalId: element.externalId,
          customMeta: customMeta
        })
      });

      this.selected = [];

    },
    openMetaPage (type) {

      this.metaPage = true;

      if (type === 'All') {

        this.importAll = true;
      }
    },

    backToSelected() {

      this.metaPage = false;
      this.importAll = false;
      this.allVideos = 'Selected';
    },

    changeTab(tab) {

      this.allVideos = tab;
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms);
    },

    toggleSelected(vod) {

      if (vod.selected) {

        this.selected.push(vod);
      } else {

        let index = this.selected.findIndex(select => select.externalId === vod.externalId);
        this.selected.splice(index,1);
        this.selectAll = false;
      }
    },

    toggleAll(value) {

      if (value) {

        this.videoList.forEach(vod => {

          vod.selected = true;
          let index = this.selected.findIndex(select => select.externalId === vod.externalId);

          if (index === -1) {

            this.selected.push(vod);
          }

        });

      } else {

        this.videoList.forEach(vod => {
          vod.selected = false;

          if (this.allVideos === "All videos") {
            let index = this.selected.findIndex(select => select.externalId === vod.externalId);
            this.selected.splice(index,1);
          } else {
            this.selected = [];
          }

        });

      }

    },

    toggleSelectedFolder(folder) {

      if (folder.selected) {

        let index = this.googleFolders.availableFolders.findIndex(select => select.id === folder.id);
        this.googleFolders.availableFolders.splice(index,1);
        this.googleFolders.selectedFolders.unshift(folder);
        this.deselectAllFolders = true;
      } else {

        let index = this.googleFolders.selectedFolders.findIndex(select => select.id === folder.id);
        this.googleFolders.selectedFolders.splice(index,1);
        this.googleFolders.availableFolders.unshift(folder);
        this.googleFolders.availableFolders.sort((a, b) => a.name.localeCompare(b.name));
        this.selectAllFolders = false;
      };
    },

    toggleAllFolders(value) {

      if (value) {

        this.googleFolders.availableFolders.map(folder => folder.selected = true);
        this.googleFolders.selectedFolders.unshift(...this.googleFolders.availableFolders);
        this.googleFolders.availableFolders = [];
        this.deselectAllFolders = true;
      } else {

        this.googleFolders.selectedFolders.map(folder => folder.selected = false);
        this.googleFolders.availableFolders.unshift(...this.googleFolders.selectedFolders);
        this.googleFolders.availableFolders.sort((a, b) => a.name.localeCompare(b.name));
        this.googleFolders.selectedFolders = [];
        this.selectAllFolders = false;
      };
    },

    saveWatchFolders() {

      this.isAutoIngestModalOpened = false;
      this.drawer = false;
      this.isGoogleRequestPending = true;

      let data = {
        selectedFolders: [],
        enabled: this.googleFolders.enabled
      };

      this.googleFolders.selectedFolders.forEach(folder =>{
        data.selectedFolders.push(folder.id)
      });

      const payload = {
        query: {
          refreshToken: this.userGoogleDriveData && this.userGoogleDriveData.token ? this.userGoogleDriveData.token : this.userGoogleDriveTempData.token
        },
        data
      };

      this.saveGoogleDriveFolders(payload)
        .then(() => {

          this.$toasted.success('The change in watch folders has been saved successfully.');
        })
        .finally(() => {

          this.isGoogleRequestPending = false;
        });
    },

    sortVods(type) {

      this.sort[type] = !this.sort[type];

      if (this.allVideos === "All videos") {

        let typeSort = "meta." + type;

        if  (type === 'title' || type === 'createdBy' ) {

          typeSort = typeSort + ".keyword";
        }

        let sortby = {};

        this.sort[type] ? sortby[typeSort] = "desc" : sortby[typeSort] = "asc";

        this.elasticOptions.sortBy = [sortby];

        this.elasticOptions.currentPage = 1;

        this.listDdistributorsSources();

      } else {

        if (type === 'title' || type === 'createdBy' ){

          this.sort[type] ? this.selected.sort((a,b) => b.meta[type].localeCompare(a.meta[type])) : this.selected.sort((a,b) => a.meta[type].localeCompare(b.meta[type]));

        }  else {

          this.sort[type] ? this.selected.sort((a,b) => b.meta[type] - a.meta[type]) : this.selected.sort((a,b) => a.meta[type] - b.meta[type]);

        }
      }
    },

    changeVodNumberOptions(option) {

      this.elasticOptions.perPageItems = option;

      this.elasticOptions.currentPage = 1;

      this.listDdistributorsSources();
    },
    confirmDisable() {

      this.isConfirmDisableModalOpened = false;

      this.setUserGoogleDriveData(null);

      this.stopImport();
    }

  },

  computed: {
    ...mapGetters({
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
      distributors: 'ingest/ingestDistributors',
      userGoogleDriveData: 'ingest/userGoogleDriveData',
      userGoogleDriveTempData: 'ingest/userGoogleDriveTempData'

    }),

    selectedText() {

      const length = this.selected.length;

      return length > 0 ? "Import " + length + " videos" : "Import selected";
    },

    list() {

      let list = [];

      if (this.allVideos === "All videos") {

        let selectedNumber = 0;
        this.selected.forEach(selected =>{

          let index = this.videoList.findIndex(vod => vod.externalId === selected.externalId);
          if (index>-1) {
            this.videoList[index].selected = true;
            selectedNumber++
          }
        });

        if (selectedNumber === this.videoList.length) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }

        list = this.videoList;
      } else {

        list = this.selected;
        this.selectAll = true;
      }

      return  list;
    },

    lastRefreshed() {

      let lastRefreshed = {};

      if (this.isSeedingFinished ) {

        this.justnow = true;

        this.choosenDistributor.isCurrentlySeeding = false;

        setTimeout(() => {

          this.justnow = false;
          this.choosenDistributor.isCurrentlySeeding = false;
        }, 15000);

        lastRefreshed.date = moment(this.seedData.data.lastSeededAt).format('DD-MM-YYYY');

        lastRefreshed.time = moment(this.seedData.data.lastSeededAt).format('HH:mm');

      } else {

        if ( this.choosenDistributor.guid != 'google-drive') {

          lastRefreshed.date = moment(this.choosenDistributor.lastSeededAt).format('DD-MM-YYYY');

          lastRefreshed.time = moment(this.choosenDistributor.lastSeededAt).format('HH:mm');
        } else {

          let email = this.userGoogleDriveData && this.userGoogleDriveData.email ? this.userGoogleDriveData.email : this.userGoogleDriveTempData.email;

          lastRefreshed.date = moment(this.choosenDistributor.seedings[email].lastSeededAt).format('DD-MM-YYYY');

          lastRefreshed.time = moment(this.choosenDistributor.seedings[email].lastSeededAt).format('HH:mm');

        };

      }

      return lastRefreshed;
    },

    isSeeding() {

      return (this.seed && this.seededDistributor === this.$route.query.distributor)
    },
    tagsLength() {

      let tags = this.customMeta.tags.reduce((a, b) => a + b,'');

      return tags.length < 64;
    },
    categoriesLength() {

      let categories = this.customMeta.categories.reduce((a, b) => a + b,'');

      return categories.length < 64;
    },
    combinedSiteBuilderHubs() {

      const hubs = [];

      this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          a.hubs.forEach(hub =>{

             hubs.push(hub);
          });
        }
      });

      return hubs;
    },

    isSeedingFinished() {

      if (this.seedData.type === "ingest_distributor_seed_finished" && this.seededDistributor === this.$route.query.distributor) {

        return true;
      } else {

        return false;
      }

    }
  },
  mounted() {

    if (this.isSeeding) {

      this.pause = true;
    } else {

      this.listDdistributorsSources();
    }

  },
  created () {

    this.moment = moment;

    this.getIngestDistributors()
      .then((distributors) => {

        this.setChoosenDistributor(this.$route.query.distributor);

        this.choosenDistributor = distributors.find(d => d.guid === this.$route.query.distributor);

        let settingsName = '';

        if (this.choosenDistributor.guid === 'google-drive') {

          settingsName = 'googleDriveSettings'
        } else {

          settingsName = this.$route.query.distributor + 'Settings';
        };

        let customMeta = {};

        if (this.choosenDistributor.hasOwnProperty(settingsName) && this.choosenDistributor[settingsName].hasOwnProperty('customMeta')) {

          customMeta = {...this.choosenDistributor[settingsName].customMeta};

        }

        this.customMeta = {

          ...this.customMeta,
          ...customMeta
        }
        if (customMeta.hasOwnProperty('hubs'))customMeta.hubs.forEach((hub) => this.toggleAddHubToList(hub, true));
      });
  },

  watch: {
    '$route.query.distributor'() {

      if (this.$route.query.distributor) {

        this.choosenDistributor = this.distributors.find(d => d.guid === this.$route.query.distributor);

        this.listDdistributorsSources();
      }
    },

    selected() {

      if (this.selected.length > 0) {

        this.setRedirectGuard({
          redirectMsg: 'Leave this page without importing selected videos?',
          redirectSecondaryMsg: 'All your changes will be discarded if you leave this page'
        });

      } else {

        this.setRedirectGuard(false);
      }
    },
     tagsLength() {
      if(!this.tagsLength){

        this.metaFormState._submit();
      }
    },
    categoriesLength() {
      if(!this.categoriesLength){

        this.metaFormState._submit();
      }
    },
    isSeedingFinished() {

      if (this.isSeedingFinished ) {

        this.listInitialSources();

        this.pause = false;

        this.justnow = true;

        this.choosenDistributor.isCurrentlySeeding = false;

        this.getIngestDistributors();

        setTimeout(() => {

          this.justnow = false;
          this.choosenDistributor.isCurrentlySeeding = false;

        }, 15000);
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  .import-wizard-component {

  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/
  @import "@indexScss";

  @include overwrite-styleberry-styles-htags-margin;
  @include overwrite-styleberry-styles-htags-font-size;

    @apply
    flex
    flex-col
    pb-6
    px-10
    w-full
    h-full
    pt-24
    absolute
    bg-white;

    .wizard-head {
      @apply
      flex
      items-center
      h-8;
      &-start{
        @apply
        flex;

        &-text{
          @apply
          text-black-900
          font-medium
          text-2xl;
        }

        &-refresh{
          @apply
          flex
          flex-row
          items-center
          ml-4;

          &-button{
            @apply
            text-blue-600;

            &-text{
              @apply
              text-blue-600
              font-medium
              text-sm;
            }
          }
          &-text{
            @apply
            text-xs
            font-normal
            text-black-400;
          }
        }
      }
      &-end{
        @apply
        flex
        justify-end
        items-start
        ml-auto;
      }
    }
    .wizard-loader {
      @apply
      flex
      flex-row
      items-start
      my-6
      py-2
      px-4
      bg-blue-50;

      &-text{
        @apply
        text-xs
        font-normal
        ml-2
        text-black-700;
      }
    }
    .wizard-loading {
      @aplly
      flex
      flex-col
      items-start;

      &-row {
        @apply
        flex
        flex-row
        py-4
        px-2
        items-center
        border-b
        border-solid
        border-black-100;

        &-image {
          width: 132px;
          height: 56.25px;
          border-radius: 2px;
          @extend .animated-background;
        }
        &-label {
          width: 200px;
          height: 20px;
          margin-left: 1rem;
          border-radius: 2px;
          @extend .animated-background;
        }

      }

    }

    .wizard-table {
      @apply
      pb-6;
      &-tabs{
        @apply
        flex
        items-center
        justify-between;
      }
      &-head{
        &-checkbox {
          @apply
          text-left
          text-sm
          text-black-900
          pb-3
          font-normal;
        }
        &-text {
          @apply
          cursor-pointer
          text-left
          text-xs
          text-black-400
          font-medium;

          &-end {
            @apply
            cursor-pointer
            text-left
            text-xs
            text-black-400
            font-medium
            min-w-20;
          }
          &.folder {
            @apply
            pb-3;
          }
        }
      }
      &-body{


          &:hover {
            @apply bg-blue-grey-50;
          }
          &:last-child {
            @apply border-none;
          }
        &-tooltip {
          @apply
          max-w-80
          text-left
          text-xs
          text-black-400
          font-medium
        }

        &-text {
          @apply
          max-w-80
          text-left
          text-xs
          text-black-400
          font-medium
          truncate;

          &-end {
            @apply
            max-w-80
            text-left
            text-xs
            text-black-400
            font-medium
            truncate
            min-w-20;
          }
          &.folder {
            @apply
            max-w-60
            py-3;
          }
        }

        &-vthumb {
          @apply
          relative
          mr-4
          flex-shrink-0
          overflow-hidden
          rounded;
          width: 100px;
          height: 56px;

          img {
            @apply w-full h-full object-cover;
          }
        }
        &-zoom {
          @apply
          flex
          items-center
          justify-center
          border
          border-solid
          border-black-100
          rounded-sm;
          width: 100px;
          height: 56px;
        }


      }
    }

    .wizard-pagination {
      @apply
      flex
      items-start
      mt-10
      mb-4;

      .wizard-dropdown{
        @apply
        overflow-hidden
        left-0
        absolute
        z-10
        inline-block
        max-h-56
        min-w-full
        flex-col
        whitespace-nowrap
        rounded
        shadow-modal
        bg-white;
        bottom: calc(100% + 50px);

        &-option{
          @apply
          cursor-pointer
          p-4
          w-32
          text-black-400;
            &:hover {
              @apply bg-blue-grey-50;
            }
        }
      }
    }
    .wizard-nodata {
      @apply
      w-full
      h-full
      flex
      flex-col
      items-center
      py-28
      px-96
      bg-black-50
      mt-12
      mb-12;

      &-container {
        @apply
        flex
        flex-col
        justify-center
        items-center;
        width: 700px;
        height: 300px;
        background: #FFFFFF;

      }
      &-text {
        @apply
        flex
        items-center
        text-black-700
        mt-5
        mb-1
        text-center
        text-base
        font-normal;
      }
       &-secondtext {
        @apply
        flex
        items-center
        text-black-500
        mb-5
        text-center
        text-xs
        font-normal;
      }
      &-img {
        @apply
        w-8 h-8 relative;
      }
      &-video {
        position: absolute;
        top: 4px;
        right: 8px;
      }
      &-button {
        @apply
        font-medium text-black-900;
      }
    }
    .wizard-meta {


      &-text{
        @apply
        flex
        flex-col
        text-sm
        text-black-400
        font-normal
        mt-2;

      }

      &-label{
        @apply
        text-sm
        text-black-700
        font-normal
        mb-4
        mt-8;
      }
      &-tags .ds-tags{

          @apply
          p-0;

      }

    }

    .ds-tabs__nav {
      @apply
      p-0;
    }

  @keyframes placeHolderShimmer{
    0%{
      background-position: -468px 0
    }
    100%{
      background-position: 468px 0
    }
  }

  .animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F7F7F8;
    background: linear-gradient(to right, #F7F7F8 8%, #F0F0F0 18%, #F7F7F8 33%);
    background-size: 800px 104px;

    position: relative;
  }

  .drawer-flex {
    @apply
    flex
    items-center
    justify-between;
  }

  .flex-google-checkbox {
    @apply
    flex
    items-center
    justify-end
    ml-auto
    min-w-20
    py-3;
  }

  .dropdown__menu-item:not(:last-of-type) {
    @apply
    border-b
    border-black-50
    border-solid;
  }
}
</style>
