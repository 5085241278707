<template>
<div class="library-advanced-filters-component">

  <aside
    class="advanced-filter-box shadow-md flex flex-column flex-auto"
    :class="{ 'is-visible': isFilterBoxOpened }">

    <header class="flex justify-between items-center mb-6 mt-8 px-8">

      <h3 class="text-sm text-black-500"> More filters</h3>

      <ioio-button
        type="minimal"
        size="small"
        @click="toggleFilterBoxOpened">
        <ioio-icon
          icon="fa-solid fa-xmark"
        />
      </ioio-button>

    </header>

    <section class="flex flex-column flex-auto overflow-auto px-8 mb-8">
      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Video type</h2>
      <div class="mb-6 text-black-500 grid grid-cols-2 gap-2">

        <ioio-checkbox
          size="small"
          disclaimer=""
          v-for="(value, vodType) in vodTypes"
          :key="vodType"
          v-model="vodTypes[vodType].value"
          @input="(newVal) => selectVodType(newVal, vodType)"
        >{{ vodTypes[vodType].label }}
        </ioio-checkbox>

      </div>

      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Duration</h2>
      <div class="mb-6 text-black-500 flex flex-wrap">

        <ioio-button
          class="mr-2 mb-2 label-btn"
          :class="{ 'btn-selected': duration.isSelected }"
          size="small"
          type="secondary"
          variant="outline"
          v-for="(duration, key) in durationTypes"
          :key="key"
          @click="changeSelectedDuration(key)"
        >{{ duration.label }}
        </ioio-button>
        <ioio-field
          class="w-40"
          :class="{ 'btn-selected': selectedDurationCustom.length }"
          v-model="selectedDurationCustom"
          placeholder="Choose duration"
          type="timespan"
          @input="onCustomDurationChange"
          size="small"
          align="right"/>

      </div>

      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Created at</h2>
      <div class="mb-6 text-black-500 flex flex-wrap">

        <ioio-button
          class="mr-2 mb-2 label-btn"
          :class="{ 'btn-selected': uploadedAt.isSelected }"
          size="small"
          type="secondary"
          variant="outline"
          v-for="(uploadedAt, key) in uploadAtTypes"
          :key="key"
          @click="changeSelectedUploadAt(key)"
        >{{ uploadedAt.label }}
        </ioio-button>

        <ioio-field
          class="dates-picker"
          :class="{ 'btn-selected': selectedUploadedAtCustom.length }"
          v-model="selectedUploadedAtCustom"
          placeholder='Choose dates'
          type="daterange"
          size="small"
          hasClearBtn
          @input="onCustomUploadAtTimeChange">
        </ioio-field>

      </div>

      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Subtitles</h2>
      <ioio-dropdown
        class="mb-6"
        :items="withSubsOptions"
        valueProp="value"
        titleProp="label"
        v-model="withSubtitles"
        size="small"
        fullWidth
      />

      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Groups</h2>
      <div class="mb-6 text-black-500">
        <ioio-dropdown
          class="multiselect__alldown"
          :class="{'multiselect__alldown__select': groupsFilterApplied.length }"
          fullWidth
          v-model="groupsFilterApplied"
          :items="aggs.groups"
          multiple
          alignY="top"
          :closeOnSelect="false"
          placeholder="Select groups"
          size="small"
          titleProp="label"
          valueProp="value"
          :limit="2"
          :allInline="false"
          iconRight="fal-chevron-down"
        />
      </div>

      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Genres</h2>
      <div class="mb-6 text-black-500">
        <ioio-dropdown
          class="multiselect__alldown"
          :class="{'multiselect__alldown__select': genresFilterApplied.length }"
          fullWidth
          v-model="genresFilterApplied"
          :items="aggs.genres"
          multiple
          alignY="top"
          :closeOnSelect="false"
          placeholder="Select genres"
          size="small"
          titleProp="label"
          valueProp="value"
          :limit="2"
          :allInline="false"
          iconRight="fal-chevron-down"
        />
      </div>


      <h2 class="filter-title border-bottom text-sm pb-1 mb-4">Guid</h2>
      <div class="text-black-500">
        <ioio-field
          fullWidth
          v-model="fullGuid"
          placeholder="Enter full guid"
          size="small"
        />
      </div>

    </section>

    <footer class="flex justify-end px-8 mb-8">
      <ioio-button
        type="primary"
        variant="outline"
        size="small"
        class="mr-2"
        @click="toggleFilterBoxOpened"
      >Cancel
      </ioio-button>
      <ioio-button
        type="primary"
        size="small"
        @click="applyFilters"
      >Save
      </ioio-button>
    </footer>

  </aside>

</div>
</template>

<script>

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { deepClone } from '@utils/helpers';


const vodTypesDefault = {

  source: {
    label: 'Videos',
    value: true
  },
  ad: {
    label: 'Ads',
    value: true
  }
};

const durationTypesDefault = {
  1: {
    label: 'Less than 5 min',
    value: {

      lte: 300000
    },
    isSelected: false
  },
  2: {
    label: 'More than 20 min',
    value: {

      gte: 1200000
    },
    isSelected: false
  },
  3: {
    label: 'Between 5 - 20 min',
    value: {

      gte: 300000,
      lte: 1200000
    },
    isSelected: false
  }
};

const now = new Date();
const nowTime = now.getTime();

const uploadAtTypesDefault = {
  1: {
    label: 'Last week',
    value: {
      gte: new Date(nowTime).setDate(now.getDate() - 7),
      lte: nowTime
    },
    isSelected: false
  },
  2: {
    label: 'Last month',
    value: {
      gte: new Date(nowTime).setMonth(now.getMonth() - 1),
      lte: nowTime
    },
    isSelected: false
  },
  3: {
    label: 'Last 3 months',
    value: {
      gte: new Date(nowTime).setMonth(now.getMonth() - 3),
      lte: nowTime
    },
    isSelected: false
  },
  4: {
    label: 'Last year',
    value: {
      gte: new Date(nowTime).setMonth(now.getMonth() - 12),
      lte: nowTime
    },
    isSelected: false
  }
};

const withSubsOptionsDefault = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'true',
    label: 'With subtitles'
  },
  {
    value: 'false',
    label: 'Without subtitles'
  }
];

export default {
  data: () => ({

    isFilterBoxOpened: false,

    appliedAdvancedFiltersCount: 0,

    vodTypes: deepClone(vodTypesDefault),

    durationTypes: deepClone(durationTypesDefault),

    selectedDuration: null,
    selectedDurationCustom: [],

    uploadAtTypes: deepClone(uploadAtTypesDefault),

    selectedUploadedAt: null,
    selectedUploadedAtCustom: [],

    withSubsOptions: withSubsOptionsDefault,

    withSubtitles: '',

    groupsFilterApplied: [],
    genresFilterApplied: [],

    fullGuid: ''
  }),
  props: {

    loadVodsElastic: Function,
    aggs: {
      type: Object,
      default: {}
    }
  },
  created() {
    window.f = this;
  },
  methods: {

    resetFilters() {

      this.vodTypes = deepClone(vodTypesDefault);

      this.durationTypes = deepClone(durationTypesDefault);

      this.selectedDuration = null;
      this.selectedDurationCustom = [];

      this.uploadAtTypes = deepClone(uploadAtTypesDefault);

      this.selectedUploadedAt = null;
      this.selectedUploadedAtCustom = [];

      this.withSubsOptions = [ ...withSubsOptionsDefault ];

      this.withSubtitles = '';

      this.groupsFilterApplied = [];
      this.genresFilterApplied = [];
    },

    applyFilters() {

      this.loadVodsElastic() && this.toggleFilterBoxOpened();
      this.$emit('change');
    },

    applyChangedLabelCount(type) {
      const filter = type + 'FilterApplied';
      this[filter].forEach(app=>{
        let select = this.aggs[type].find(el => el.value === app.value)
        if (select) {
          app.label = select.label
        } else {
          app.label = app.value
        }
      })
    },

    onCustomDurationChange(range) {

      if (range.length) {

        // Null possible prev selected time
        this.changeSelectedDuration('custom');

        this.selectedDuration = {

          gte: range[0],
          lte: range[1]
        };
      }
    },

    onCustomUploadAtTimeChange(range) {

      if (range.length) {

        // Null possible prev selected time
        this.changeSelectedUploadAt('custom');

        this.selectedUploadedAt = {

          gte: new Date(range[0]).getTime(),
          lte: new Date(range[1]).getTime()
        };
      }
    },

    selectVodType(newVal, vodType) {

      this.vodTypes[vodType].value = newVal;
    },

    changeSelectedDuration(durationKey) {

      for (let key in this.durationTypes) {

        if (key !== durationKey) {

          this.durationTypes[key].isSelected = false;
        }
      }

      this.selectedDuration = null;

      // One of the predefined options is clicked - null possible value in timespan field
      if (durationKey !== 'custom') {

        this.selectedDurationCustom = [];
      }

      if (this.durationTypes[durationKey]) {

        this.durationTypes[durationKey].isSelected = !this.durationTypes[durationKey].isSelected;

        if (this.durationTypes[durationKey].isSelected) {

          this.selectedDuration = this.durationTypes[durationKey].value;
        }
      }
    },

    changeSelectedUploadAt(uploadAtKey) {

      for (let key in this.uploadAtTypes) {

        if (key !== uploadAtKey) {

          this.uploadAtTypes[key].isSelected = false;
        }
      }

      this.selectedUploadedAt = null;

      // One of the predefined options is clicked - null possible value in datetime field
      if (uploadAtKey !== 'custom') {

        this.selectedUploadedAtCustom = [];
      }

      if (this.uploadAtTypes[uploadAtKey]) {

        this.uploadAtTypes[uploadAtKey].isSelected = !this.uploadAtTypes[uploadAtKey].isSelected;

        if (this.uploadAtTypes[uploadAtKey].isSelected) {

          this.selectedUploadedAt = this.uploadAtTypes[uploadAtKey].value;
        }
      }
    },

    evaluateFiltersAppliedCount() {

      let count = 0;

      // The default filter state is for them both to be applied
      (!this.vodTypes.ad.value || !this.vodTypes.source.value) && count++;

      this.selectedDuration && count++;

      this.selectedUploadedAt && count++;

      this.withSubtitles && count++;

      this.groupsFilterApplied.length && count++;

      this.genresFilterApplied.length && count++;

      this.appliedAdvancedFiltersCount = count;
    },

    gatherFilterData() {

      if (this.fullGuid) {

        const fullGuid = this.fullGuid;

        // Since fullGuid overwrites all other filters, the field
        // has to be nulled in order to avoid dirty checking other inputs, if value in fullGuid exists
        this.fullGuid = '';

        this.$toasted.info(`Filtering by guid "${fullGuid}". All other filters are not applied.`);

        this.appliedAdvancedFiltersCount = 1;

        this.resetFilters();

        return {

          fullGuid,

          // vodTypes is always needed for the search
          vodTypes: this.vodTypes,
        };
      }

      this.evaluateFiltersAppliedCount();

      let selectedUploadedAt = this.selectedUploadedAt;

      if (this.selectedUploadedAtCustom.length) {

        selectedUploadedAt = {

          gte: new Date(this.selectedUploadedAtCustom[0]).getTime(),
          lte: new Date(this.selectedUploadedAtCustom[1]).getTime()
        };
      }


      return {
        vodTypes: this.vodTypes,
        selectedDuration: this.selectedDuration,
        selectedUploadedAt,
        withSubtitles: this.withSubtitles,
        groupsFilterApplied: this.groupsFilterApplied,
        genresFilterApplied: this.genresFilterApplied
      }
    },

    toggleFilterBoxOpened() {

      this.isFilterBoxOpened = !this.isFilterBoxOpened;
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">

.advanced-filter-box {

  $filterWidth: 376px;

  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: white;
  width: $filterWidth;
  transform: translate3d($filterWidth, 0, 0);
  transition: transform .3s ease-out;

  &.is-visible {

    transform: translate3d(0, 0, 0);
  }

  .label-btn.ds-btn {

    @apply
    text-xs
    px-2;

    &.btn-selected {

      @apply
      border-blue-600
      bg-blue-50
      text-black-900;
    }
  }

  .dates-picker {

    .flatpickr-calendar {

      left: -80px;
    }

    .toggle-button.is-small {

      top: 50%;
    }

    input {

      @apply
      text-xs;
    }


    &.btn-selected {
      input {

        @apply
        border-blue-600
        bg-blue-50
        text-black-900;
      }
    }
  }
  .ds-fields {
    &.btn-selected {
      input {

        @apply
        border-blue-600
        bg-blue-50
        text-black-900;
      }
    }
  }

}
</style>
