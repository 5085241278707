const TOGGLE_ACTION_MODAL_OPENED = (state, { flagName, newVal }) => {

  state[flagName] = newVal;
}

const SET_VOD_FOR_MODAL_ACTION = (state, vod = {}) => {

  state.forModalActionVod = {

    ...vod
  };
}

const EDIT_VOD_DATA = (state, newData = {}) => {

  // NOTE: the caller of the function should provide a mutated object
  state.editedVod = newData;
}

const SET_CLICKED_VOD_ID = (state, newData) => {

  state.clickedVodId = newData;
}

const SET_VOD_CAPTIONS = (state, newData = {}) => {
   // NOTE: the caller of the function should provide a mutated object
  state.editedVodCaptions = newData;
  let encode = false;
  if (newData) {
    for (const caption in newData) {
      for (const file in newData[caption]) {
        if (newData[caption][file].meta.shouldEncode && !newData[caption][file].isVodEncoded) {
          encode = true;
          break;
        }
      }
    }
  }
  state.shouldEncodeCaptions = encode;
}


export {
  TOGGLE_ACTION_MODAL_OPENED,
  SET_VOD_FOR_MODAL_ACTION,
  EDIT_VOD_DATA,
  SET_CLICKED_VOD_ID,
  SET_VOD_CAPTIONS
};