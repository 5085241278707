/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'key': {
    width: 49.859,
    height: 49.859,
    viewBox: '0 0 49.859 49.859',
    data: '<path pid="0" d="M40.413 11.474C40.413 5.137 33.481 0 24.929 0 16.379 0 9.447 5.137 9.447 11.474c0 5.182 4.636 9.556 11 10.98v2.476h1.396v22.486l2.025 1.327V24.93h.698v24.232l.804.697 3.736-3.7v-1.468l-1.118-.418 1.118-.838v-.77l-2.444-1.955 2.096-2.234-.769-.976.769-.629v-1.048l-1.467-1.605.698-1.536-1.326-1.188v-1.536l2.095-1.955V24.93h1.537v-2.704c5.903-1.619 10.118-5.815 10.118-10.752zM24.93 2.663c2.078 0 3.763 1.59 3.763 3.552s-1.686 3.552-3.763 3.552c-2.078 0-3.763-1.59-3.763-3.552s1.685-3.552 3.763-3.552z"/>'
  }
})
