<template>
  <label class="check-component" :class="type">
    <input
      :type="type"
      :name="name || 'checkbox'"
      :disabled="disabled"
      :checked="val"
      @change="changeHandler"/>
    <span></span>
    {{ label && label }}
  </label>
</template>

<script>
export default {
  data: () => ({}),
  methods: {

    changeHandler() {

      this.onChange();
    }
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "checkbox",
      validator: function (value) {
        return ['checkbox', 'radio'].indexOf(value) !== -1
      }
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false
    },
    model: {
      type: Boolean,
      required: false,
    },
    onChange: {
      type: Function,
      required: false
    },
    val: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>



/* Custom Radio Buttons */

.check-component {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.25rem;
  line-height: 1rem;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  height: 1rem;
}

/* Hide the browser's default radio button */
.check-component input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: initial;
	margin: 0;
  z-index: 0;
}

/* Create a custom radio button */
.check-component span {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
border-radius: 4px;
font-size: .75rem;

  z-index: 1;
}

.check-component.hidden span {
  display: none;
}

.check-component.radio span {
  border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.check-component:hover input ~ span {
  background-color: var(--highlightBgColor);
}

/* When the radio button is checked, add a blue background */
.check-component input:checked ~ span {
  background-color: var(--highlightColor);
}

/* When the input is disabled */
.check-component input:disabled ~ span {
    cursor: default;
    color: var(--sceneBrColor);
    background-color: var(--sceneBgColor);    
}
.check-component input:disabled {
    cursor: default;      
}


/* Create the indicator (the dot/circle - hidden when not checked) */
span:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.check-component input:checked ~ span:after {
  display: block;
}

/* Style the indicator (thick for checkbox) */
.check-component span:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid #242121;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-component input:checked ~ span:after {
  border-color: var(--highlightBgColor);
}


/* Style the indicator (dot/circle for radio) */
.check-component.radio span:after {
  top: 4px;
  left: 4px;
  width: 0.325rem;
  height: 0.325rem;
  border-radius: 50%;
  background: white;
}

</style>
