<template>
<section class="event-attendees">


  <ioio-modal
    class="create-attendee"
    :show.sync="isCreateAttendeeModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >

    <template slot="header">

      <h3 class="flex mb-8">New Attendee</h3>

    </template>

    <ioio-field
      type="text"
      required
      topic="newAttendeeForm"
      size="medium"
      label="Name"
      placeholder="Name"
      :maximum=100
      v-model="newAttendeeData.name" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="email"
      required
      topic="newAttendeeForm"
      size="medium"
      label="Email"
      placeholder="Email"
      :maximum=64
      v-model="newAttendeeData.email" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="text"
      topic="newAttendeeForm"
      size="medium"
      label="Phone"
      placeholder="Phone"
      :maximum=64
      v-model="newAttendeeData.phone" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="text"
      topic="newAttendeeForm"
      size="medium"
      label="Company Role"
      placeholder="Company Role"
      :maximum=64
      v-model="newAttendeeData.meta.companyRole" class="w-full mb-6"></ioio-field>

    <template slot="footer">
      <footer class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelCreateNewAttendee">Cancel</ioio-button>

        <ioio-button
          topic="newAttendeeForm"
          @click.stop.prevent="createAttendee">Add Attendee</ioio-button>

      </footer>
      <div v-else class="overflow-hidden mt3">
        <logo-loader-component width="30px" height="30px" class=""/>
      </div>
    </template>
  </ioio-modal>

  <ioio-modal
    class="edit-attendee-modal"
    :show.sync="isEditAttendeeModalVisible"
    size="medium"
    :noScroll="false"
    headerClasses=""
    footerClasses=""
  >

    <template slot="header">
      <h3 class="mb-6">Edit Attendee</h3>

    </template>

    <ioio-field
      type="text"
      required
      topic="editAttendeeForm"
      size="medium"
      label="Name"
      placeholder="Name"
      :maximum=100
      v-model="editedAttendeeData.name" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="email"
      required
      topic="editAttendeeForm"
      size="medium"
      label="Email"
      placeholder="Email"
      :maximum=64
      v-model="editedAttendeeData.email" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="text"
      topic="editAttendeeForm"
      size="medium"
      label="Phone"
      placeholder="Phone"
      :maximum=64
      v-model="editedAttendeeData.phone" class="w-full mb-6"></ioio-field>

    <ioio-field
      type="text"
      topic="editAttendeeForm"
      size="medium"
      label="Company Role"
      placeholder="Company Role"
      :maximum=64
      v-model="editedAttendeeData.meta.companyRole" class="w-full mb-6"></ioio-field>


    <template slot="footer">
      <footer class="flex justify-between mt2 ratio1-1" v-if="!isRequestPending">

        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="triggerDeleteAttendee"
        >
            Delete
        </ioio-button>

        <div>
          <ioio-button
            type="secondary"
            variant="outline"
            class="mr-4"
            @click.stop.prevent="cancelEditAttendee">Cancel</ioio-button>

          <ioio-button
            topic="editAttendeeForm"
            @click.stop.prevent="editAttendee">Edit</ioio-button>

        </div>


      </footer>
      <div v-else class="overflow-hidden">
        <logo-loader-component width="30px" height="30px" class=""/>
      </div>
  </template>
  </ioio-modal>


  <header class="event-attendees__header">
    <h1 class="event-attendees__header-title">Аttendees</h1>

    <aside class="event-attendees__header-tools">
      <input-search-component
        :onSearchInput="handleInputSearch"
        placeholder="Search..."
        class=""
      />

      <input
        ref="csvImportInput"
        type="file"
        class="hidden-csv-input"
        @change="triggerImportAttendeesCsv"
      />

      <ioio-button
        @click="triggerExportAttendeesCsv()"
        type="secondary"
        variant="outline"
        class="ml-2"
      >
        Export
      </ioio-button>

      <ioio-button
        @click="openImportAttendeesCsv()"
        type="secondary"
        variant="outline"
        class="ml-2"
      >
        Import CSV
      </ioio-button>

      <ioio-button
        type="primary"
        class="ml-2"
        iconLeft="plus"
        @click="openCreateModal()"
      >
        Add
      </ioio-button>

      <!-- <ioio-dropdown-base alignX="right">
        <template slot="title">
          <ioio-button type="minimal" size="small" icon="ellipsis-v" />
        </template>
        <ioio-button class="dropdown__menu-item" type="minimal">
          Edit page
        </ioio-button>
        <ioio-button class="dropdown__menu-item" type="minimal">
          Duplicate
        </ioio-button>
        <ioio-button class="dropdown__menu-item" type="minimal">
          Page settings
        </ioio-button>
        <ioio-button class="dropdown__menu-item" type="minimal">
          Delete
        </ioio-button>
      </ioio-dropdown-base> -->

      <!-- <button-component variant="default" size="size-m" class="items-center flex-none ml-auto mr1" >
        <span>Import CSV</span>
      </button-component> -->

      <!-- <button-component variant="default" size="size-m" class="" @click="openCreateModal()">
        <svg-icon name="circle-add" width="14" class="green-1 mr1"></svg-icon>
        <span>Add</span>
      </button-component> -->
    </aside>
  </header>

  <ioio-table class="event-attendees__table">
    <template slot="thead">
      <tr class="event-attendees__table-header">
        <th>
          Name
        </th>
        <th>
          E-mail
        </th>
        <th>
          Company Role
        </th>
        <th>
          Phone
        </th>
        <th>
        </th>
      </tr>
    </template>
    <template
      slot="tbody"
      v-if="!isRequestPending && filteredEventAttendees.length"
    >
      <tr
        class="event-attendees__table-body"
        v-for="(attendee, index) in filteredEventAttendees"
        :key="index"
      >
        <td class="text-sm text-blue-grey-900">
          <div v-if="attendee.name.length < textWidth/10" class="w-10/12">{{ attendee.name }}</div>
          <ioio-tooltip v-else :text="attendee.name"  initialPostion="top-right" class="w-full">
            <div class="truncate w-10/12">{{ attendee.name }}</div>
          </ioio-tooltip>
        </td>
        <td class="text-sm font-normal text-blue-grey-400">
          <div v-if="attendee.email.length < textWidth/10" class="w-10/12">{{ attendee.email }}</div>
          <ioio-tooltip v-else :text="attendee.email"  class="w-full">
            <div class="truncate w-10/12">{{ attendee.email }}</div>
          </ioio-tooltip>
        </td>
        <td class="text-sm font-normal text-blue-grey-400">
          <div v-if="!attendee.meta.companyRole || attendee.meta.companyRole && attendee.meta.companyRole.length < textWidth/10" class="w-10/12">{{ attendee.meta.companyRole || '--' }}</div>
          <ioio-tooltip v-else :text="attendee.meta.companyRole"  class="w-full">
            <div class="truncate w-10/12">{{ attendee.meta.companyRole || '--' }}</div>
          </ioio-tooltip>
        </td>
        <td class="text-sm font-normal text-blue-grey-400">
          <div v-if="attendee.phone && attendee.phone.length < textWidth/10" class="w-10/12">{{ attendee.phone }}</div>
          <ioio-tooltip v-else :text="attendee.phone"  class="w-full">
            <div class="truncate w-10/12">{{ attendee.phone || '--' }}</div>
          </ioio-tooltip>
        </td>
        <td class="flex justify-end">
          <ioio-button
            type="secondary"
            variant="outline"
            class="ml-2"
            @click="openEditAttendeeModal(attendee)"
          >
            Edit
          </ioio-button>
        </td>
      </tr>
    </template>
        <template
      slot="tbody"
      v-else-if="!isRequestPending && !filteredEventAttendees.length">
      <tr class="is-one-column">
        <td colspan="4" class="flex flex-col items-center">
          <img
            class="mb-5"
            src="~@assets/eventStyling/pages/events_creation/import.svg"
            alt=""
          />
          <p>You haven't added any attendees yet.</p>
          <ioio-button
            type="primary"
            variant="outline"
            class="mt-5"
            @click="openCreateModal()"
          >
            Add Attendees
          </ioio-button>
        </td>
      </tr>

    </template>
    <template
      slot="tbody"
      v-else>
      <tr>
        <td><ioio-loader isShown /></td>
      </tr>

    </template>
  </ioio-table>


  <footer class="flex justify-center py1">
    <div v-if="!isRequestPending && filteredEventAttendees.length">

      <pagination-component
        :page-selected="eventAttendeesSearchOptions.pageNum"
        :click-callback="onPaginationClicked"
        :per-page-items="eventAttendeesSearchOptions.perPageItems"
        :totalItemsLength="filteredItemsCount"
      />

    </div>
  </footer>

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { downloadCSV } from '@utils/helpers';

export default {
  data: () => ({

    isRequestPending: false,
    attendees: [],

    newAttendeeData: {
      name: '',
      email: '',
      role: 'attendee',
      phone: '',
      meta: {
        companyRole: ''
      },
    },
    isCreateAttendeeModalVisible: false,

    editedAttendeeData: {
      name: '',
      email: '',
      role: 'attendee',
      phone: '',
      meta: {
        companyRole: ''
      },
    },

    isEditAttendeeModalVisible: false,
  }),
  props: {},

  mounted() {

    window.a = this
  },

  created() {

    const eventGuid = this.$route.query.id;

    this.triggerGetAttendees(eventGuid);
  },

  beforeDestroy() {

    this.unstageFromFiltering('eventAttendeesLibrary');
  },

  methods: {

    triggerExportAttendeesCsv() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const payload = {

        eventGuid,
        params: {
          roles: 'attendee'
        }
      };

      this.exportAttendeesCsv(payload)
        .then((resp) => {

          console.log(resp);
          downloadCSV(resp, `${this.eventDetails.name}_attendees.csv`);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    openImportAttendeesCsv() {

      this.$refs.csvImportInput.click();
    },

    triggerImportAttendeesCsv(event) {

      const file = event.target.files[0];

      if (!file) {

        return;
      }

      const acceptedFiles = '.csv';

      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);

      if (!acceptedFiles.includes(fileExtension.toLowerCase())) {

        const errMsg = `The file ${this.$options.filters.truncate(file.name, 64, "...")} is not a supported type.`;

        this.$toasted.error(errMsg)

        this.resetUploadInputState();

        return;
      }

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const payload = {

        eventGuid,
        data: file
      };

      this.resetUploadInputState();

      this.importAttendeesCsv(payload)
        .then(() => {

          this.triggerGetAttendees(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });
    },

    resetUploadInputState() {

      // reset the file field
      if (!this.$refs.csvImportInput) {

        return;
      }

      this.$refs.csvImportInput.type = 'text';
      this.$refs.csvImportInput.type = 'file';
    },

    triggerDeleteAttendee() {

      // NOTE: possibly add confirm

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const params = {

        eventGuid,
        attendeeGuid: this.editedAttendeeData.guid
      };

      this.deleteAttendee(params)
        .then(() => {

          this.$toasted.success(`Attendee ${this.$options.filters.truncate(this.editedAttendeeData.name, 64, "...")} was deleted successfully!`);

          this.cancelEditAttendee();

          this.triggerGetAttendees(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });
    },

    openEditAttendeeModal(attendee) {

      this.editedAttendeeData = {

        ...attendee,
        meta: {
          ...attendee.meta
        }
      };

      this.isEditAttendeeModalVisible = true;
    },

    cancelEditAttendee() {

      this.isEditAttendeeModalVisible = false;

      this.editedAttendeeData = {

        name: '',
        email: '',
        role: 'attendee',
        phone: '',
        meta: {
          companyRole: ''
        },

      };
    },

    editAttendee() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const {
        name,
        email,
        role,
        phone,
        meta,
        guid
      } = this.editedAttendeeData;

      const payload = {

        eventGuid,
        attendeeGuid: guid,
        data: {
          name,
          email,
          role,
          phone,
          meta
        },
      };


      this.updateAttendee(payload)
        .then((resp) => {

          this.$toasted.success(`Attendee ${this.$options.filters.truncate(payload.data.name, 64, "...")} was successfully updated!`);

          this.cancelEditAttendee();

          this.triggerGetAttendees(eventGuid);
        })
        .catch(() => {

          this.isRequestPending = false;
        });

    },

    openCreateModal() {

      this.isCreateAttendeeModalVisible = true;
    },

    cancelCreateNewAttendee() {

      this.isCreateAttendeeModalVisible = false;

      this.newAttendeeData = {

        name: '',
        email: '',
        role: 'attendee',
        phone: '',
        meta: {
          companyRole: ''
        },

      };
    },

    createAttendee() {

      this.isRequestPending = true;

      const eventGuid = this.$route.query.id;

      const payload = {

        data: {
          ...this.newAttendeeData
        },
        eventGuid
      };


      this.addAttendee(payload)
        .then((resp) => {

          this.$toasted.success(`Attendee ${this.$options.filters.truncate(payload.data.name, 64, "...")} was successfully created!`);

          this.cancelCreateNewAttendee();

          this.triggerGetAttendees(eventGuid);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    triggerGetAttendees(eventGuid) {

      this.isRequestPending = true;

      this.getAttendees({ eventGuid }).then(resp => {

        this.attendees = resp;

        this.stageForFiltering({
          items: this.attendees,
          division: 'eventAttendeesLibrary'
        });

        this.applyFiltering({

          filterType: 'eventAttendeesLibrary'
        });
      })
      .finally(() => {

        this.isRequestPending = false;
      });
    },

    handleInputSearch(input) {

      this.applyFiltering({

        searchOpts: {

          urlSearchQuery: input,
          pageNum: 1
        },
        filterType: 'eventAttendeesLibrary'
      });
    },

    onPaginationClicked(pageNum) {

      this.applyFiltering({

        searchOpts: {

          pageNum
        },
        filterType: 'eventAttendeesLibrary'
      });

      this.$refs.libraryScrollArea.scrollTop = 0;
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getAttendees: "events/getAttendees",
      addAttendee: "events/addAttendee",
      updateAttendee: "events/updateAttendee",
      deleteAttendee: "events/deleteAttendee",
      importAttendeesCsv: "events/importAttendeesCsv",
      exportAttendeesCsv: "events/exportAttendeesCsv",

      stageForFiltering: 'filterAndPaginate/stageForFiltering',
      unstageFromFiltering: 'filterAndPaginate/unstageFromFiltering',
      applyFiltering: 'filterAndPaginate/applyFiltering',
    })
  },
  computed: {
    ...mapGetters({

      eventDetails: "events/eventDetails",
      filteredEventAttendees: "filterAndPaginate/filteredEventAttendees",
      filteredItemsCount: "filterAndPaginate/filteredEventAttendeesItemsCount",
      eventAttendeesSearchOptions: "filterAndPaginate/eventAttendeesSearchOptions",
    }),

    textWidth () {
      return window.innerWidth / 8;
    },
  },
}
</script>

<style lang="scss">



.event-attendees {


  // .attendee-tile {
  //   &:not(:last-of-type) {

  //     border-bottom: 1px solid var(--color-gray-300);
  //   }
  // }

  .hidden-csv-input {
    @apply
    invisible
    absolute
    w-0
    h-0;
  }
}

.event-attendees {
  @apply
  mx-auto
  max-w-7xl
  p-12;

  &__header {
    @apply
    flex
    items-center
    justify-between
    my-4;
  }

  &__header-title {
    @apply
    text-2xl;
  }

  &__header-tools {
    @apply flex;
  }

  &__table {
    @apply
    w-full
    overflow-x-auto;

    tr {
      @apply
      w-full
      grid
      grid-cols-5;

      &.is-one-column {
        @apply grid-cols-1;
      }
    }

    th, td {
      @apply
      text-base
      font-medium
      text-left
      text-blue-grey-400;
    }

    th {
      @apply text-blue-grey-900;
    }
  }



  // &__table-header {
  //   th {
  //     @apply min-w-32 max-w-sm text-sm font-medium text-left text-blue-grey-400;
  //   }
  // }

}
</style>
