<template>

<ioio-modal
  class=""
  :show.sync="isArchiveModalOpened"
  size="medium"
>
  <div v-if="!isLoadingData">
    <div v-if="!playlistsToBeDeleted.length">

      <h3>You are about to archive this video asset.</h3>

      <h4 class="whitespace-normal max-h-48 h-12 overflow-y-auto overflow-x-hidden">"{{vod.meta.title}}" will be permanently deleted in {{retentionPeriod}}. Are you sure you want to archive it?
      </h4>
    </div>
    <section v-else>

      <h3>This video asset is part of the following playlists:</h3>

      <h6>(click to navigate)</h6>

      <section class="vods-for-deletion-list">
        <a
          class="pointer flex text-sm font-bold mb-4"
          v-for="playlist,i in this.playlistsToBeDeleted"
          :key="i"
          @click.stop="navigateToPlaylistDetails(playlist.guid)"
        >{{ playlist.name }}
        </a>

      </section>

      <h4 class="mb-4">Please remove it from the playlists above in order to archive.</h4>
    </section>

    <footer v-if="!isArchiveInProgress" class="mt-6 flex justify-end">

      <ioio-button
        @click.stop="closeModal"
        class="mr-4"
        type="secondary"
        variant="outline"
      >Cancel</ioio-button>

      <ioio-button
        type="primary"
        @click.stop="onConfirmArchive"
        v-if="!playlistsToBeDeleted.length"
      >Yes, Archive</ioio-button>

    </footer>

    <div class="relative min-h-36" v-else>
      <ioio-loader isShown />
    </div>
  </div>
  <div class="relative min-h-36" v-else>
    <ioio-loader isShown />
  </div>
</ioio-modal>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    playlistsToBeDeleted: [],
    isArchiveInProgress: false,
    isLoadingData: true,
    period: ''
  }),

  props: {
    vod: Object,
    toggleArchiveModalOpened: Function,
    isModalOpened: Boolean
  },

  mounted() {

    this.onModalOpen();
  },

  methods: {

    ...mapActions({

      getBrokerSettings: "channelManager/getBrokerSettings",
      makeArchiveVodRequest: 'channelManager/makeArchiveVodRequest'
    }),

    onModalOpen() {

      this.getBrokerSettings('archive-retention-period').then((defaultData) => {

        this.period = defaultData.value;
      })
      .finally(() => {

        this.isLoadingData = false;
      });
    },

    closeModal() {

      this.toggleArchiveModalOpened();

      this.playlistsToBeDeleted = [];

      this.isArchiveInProgress = false;
    },

    onConfirmArchive() {

      this.isArchiveInProgress = true;

      this.makeArchiveVodRequest({ vodGuid: this.vod.guid })
        .then(() => {

          this.$toasted.success('Video asset has been archived.');
          this.closeModal();
        })
        .catch(errPayload => {

          console.log(errPayload);
          this.playlistsToBeDeleted = errPayload.error.playlists || [];
        })
        .finally(() => {

          this.isArchiveInProgress = false;
        });
    },

    navigateToPlaylistDetails(guid) {

      this.$pushRoute(`/vod/library/playlists?id=${guid}`);
    }
  },
  computed: {

    retentionPeriod() {

      const periodLabel = this.period === '1' ? '1 day' : `${this.period} days`;

      return periodLabel;
    },

    isArchiveModalOpened: {

      get() {

        return this.isModalOpened;
      },

      set(newVal) {

        this.toggleArchiveModalOpened(false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/

@import "@indexScss";

@include overwrite-styleberry-styles-tags;
@include overwrite-styleberry-styles-htags-margin;
@include overwrite-styleberry-styles-htags-font-size;

.vods-for-deletion-list {

  max-height: 450px;
  overflow: auto;
}

</style>
