<template>
<header class="main-header flex flex-row items-center justify-between pr-6 bg-white">
  <section class="flex flex-row flex-auto items-center">

    <div class="cursor-pointer mr-6" @click="() => navigate({ route: logoRedirectPath || DEFAULT_AUTH_PATH })">
      <h1
        class="ml-8 text-xl"
        v-if="organizationSelected && (!organizationSelected.config || !organizationSelected.config.logo)"
      >
        {{ organizationSelected.name }}
      </h1>
      <div
        v-if="organizationSelected && organizationSelected.config && organizationSelected.config.logo"
        class="flex items-center img-item overflow-hidden ml-8 rounded cursor-pointer"
      >
        <img :src="organizationSelected.config.logo" width="80" height="45" class="img-logo">
        <div class="img-meta w-full h-full text-center">
          <h1 class="text-xl">
            {{ organizationSelected.name }}
          </h1>
        </div>
      </div>
      <logo-component v-if="!organizationSelected" width="130" height="28"/>
    </div>

    <ul class="flex flex-row m0 text-base text-black-600 mr-5 font-normal nav-item-container">

      <li
        class="mr-2 cursor-pointer"
        v-for="navLink in activeNavItems"
        :key="navLink.name">

        <ioio-button
          v-if="!navLink.secondaryItems"
          @click="() => navigate(navLink)"
          :class="{ 'selected': selectedItem === navLink.name }"
          class="nav-item-btn"
          type="minimal"
          size="small"
        >{{ navLink.label }}
        </ioio-button>

        <ioio-dropdown-base
          v-else
          @change="changeDropdown"
          alignX="left"
          :fullWidth="false"
          class="cursor-default">

          <template slot="title">
            <ioio-button
              :class="{ 'selected': selectedItem === navLink.name }"
              class="nav-item-btn"
              type="minimal"
              size="small"
            >
              <span class="mr-2">{{ navLink.label }}</span>
              <ioio-icon icon="fal-chevron-down" class="fa-xs" />
            </ioio-button>
          </template>

          <section>
            <div
              v-for="secondaryNavItem in navLink.secondaryItems"
              :key="secondaryNavItem.name"
              class="dropdown__menu-item cursor-pointer"
            >

              <ioio-button
                @click="() => navigate(secondaryNavItem)"
                class="text-base"
                :class="{ 'text-blue-600 font-medium': selectedSecondaryItem === secondaryNavItem.name }"
                type="minimal"
                size="medium"
              >
                <span class="mr-2">{{ secondaryNavItem.label }}</span>
                <ioio-icon
                  v-if="secondaryNavItem.icon"
                  :icon="secondaryNavItem.icon"
                  class="fa-xs"
                />
              </ioio-button>
            </div>
          </section>

        </ioio-dropdown-base>
      </li>
    </ul>

  </section>

  <aside class="relative right-section py-4 flex justify-end">

    <ioio-dropdown-base
      @change="changeDropdownNew"
      class="quick-actions border-right"
      alignX="right"
      v-if="organizationSelected"
      :overflow="true">
      <template slot="title">
        <ioio-button
          type="primary"
          size="small"
          iconRight="fal-chevron-down"
          class="open-btn fa-xs">
          New
        </ioio-button>
      </template>

        <div
          v-for="navLink in activeQuickActionItems"
          :key="navLink.name"
          class="dropdown__menu-item"
        >

          <ioio-button
            v-if="navLink.name !== 'quickImport'"
            type="minimal"
            size="medium"
            :iconLeft="navLink.icon"
            @click="() => navigate(navLink)">
              {{ navLink.label }}
          </ioio-button>

          <div v-else class="relative" id="import">
            <ioio-button
              type="minimal"
              size="medium"
              :iconLeft="navLink.icon"
              :iconRight="isOpenNewImport ? 'fal-chevron-left' : 'fal-chevron-right'"
              @click.stop="toggleNewImport"
            >
              <span class="mr-16 ml-2">{{ navLink.label }}</span>
            </ioio-button>

            <div
              class="dropdown__import-item"
              :class="isOpenNewImport ? classPosition : null"
              ref="menu"
              id="menu"
            >
              <div
                v-for="distributor in availableDistributors"
                :key="distributor.guid"
                class="dropdown__menu-item"
              >
                <div class="flex justify-between dropdown-distributor">
                  <ioio-button
                    fullWidth
                    type="minimal"
                    :class="(distributor.guid === 'google-drive' && isUserGoogleDriveConnect) || (distributor.enabled && distributor.guid != 'google-drive') ? 'distributor-enabled' : 'distributor-connect'"
                    :disabled="(distributor.guid === 'google-drive' && !isUserGoogleDriveConnect) || (!distributor.enabled && distributor.guid != 'google-drive') "
                    @click="navigateToIngest(navLink,distributor)"
                  >
                    {{ distributor.title }}
                  </ioio-button>
                  <ioio-button
                    type="minimal"
                    v-if="distributor.guid === 'google-drive' && !isUserGoogleDriveConnect || !distributor.enabled"
                    class="ds-btn connect"
                    @click="navigateToIngest(navLink,distributor)"
                  >
                    Connect
                  </ioio-button>
                </div>
              </div>
            </div>
          </div>
        </div>

    </ioio-dropdown-base>


    <ioio-dropdown-base class="user-nav" alignX="left" :fullWidth="false">
      <template slot="title">
        <div class="inline-flex items-center cursor-pointer pr-2">

          <avatar-component :username="userData && userData.name"/>
          <div class="px-3">
            <h5 class="truncate main-username">{{ userData && userData.name }}</h5>
          </div>

          <ioio-icon icon="fal-chevron-down" class="fa-xs"/>
        </div>
      </template>

      <div class="flex mb-4">

        <avatar-component :username="userData && userData.name" class="is-big" />

        <div class="pl-3 w-3/4" >
          <h5 class="user-fullname">{{ userData && userData.name }}</h5>
          <ioio-tooltip v-if="userData && userData.email.length > 35" :text="userData && userData.email" initialPostion="bottom-left" class="w-full">
            <h6 class="user-email truncate">{{userData && userData.email}}</h6>
          </ioio-tooltip>
          <h6 class="user-email" v-else>{{userData && userData.email}}</h6>
        </div>
      </div>

      <ioio-button
        class="dropdown__menu-item"
        type="minimal"
        size="medium"
        v-for="navLink in activeUserNavItems"
        :key="navLink.name"
        @click="() => navigate(navLink)">
          {{ navLink.label }}
      </ioio-button>

      <p class="border-top border-black-50 pt-2">

        <ioio-button
          class="dropdown__menu-item mb-0"
          @click="handleLogout"
          type="minimal"
          size="medium"
        >Log out
        </ioio-button>
      </p>
    </ioio-dropdown-base>

  </aside>
</header>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  DEFAULT_UNAUTH_PATH,
  DEFAULT_AUTH_PATH
} from "@basePath/configs/routesAuth.config";

export default {
  data: () => ({
    isOpenImport: false,
    isOpenNewImport: false,
    position:''
  }),
  props: {
    selectedItem: String,
    selectedSecondaryItem: String,
    logoRedirectPath: String,
    useAdminNavItems: Boolean
  },
  created() {

    this.DEFAULT_AUTH_PATH = DEFAULT_AUTH_PATH;

    window.asd = this;
  },
  methods: {

    isOutOfViewport() {

      const parent = document.getElementById('import');

      const parentBounding = parent.getBoundingClientRect();

      const elem = document.getElementById('menu');

      const outOnRight = parentBounding.right + elem.offsetWidth > (window.innerWidth || document.documentElement.clientWidth);

      outOnRight ? this.position = 'right' : this.position = 'left';
    },

    changeDropdownNew(state) {

      if (!state) {

        this.isOpenNewImport = false;

      }  else {

       setTimeout(() => {
          this.isOutOfViewport()
       }, 10);

      }
    },

    changeDropdown(state) {

      if (!state) {

        this.isOpenImport = false;
      }
    },

    toggleNewImport() {

      this.isOpenNewImport = !this.isOpenNewImport;
    },

    toggleImport() {

      this.isOpenImport = !this.isOpenImport;
    },

    navigateToIngest(navitem, distributor) {

      let routeDetails = {};

      routeDetails.route = navitem.route;

      routeDetails.queries = {};

      if ((distributor.guid === 'google-drive' && this.isUserGoogleDriveConnect) || (distributor.guid != 'google-drive' && distributor.enabled)) {

        routeDetails.queries.type = 'import';
        routeDetails.queries.distributor = distributor.guid;
      } else if (!distributor.enabled || (distributor.guid === 'google-drive' && !this.isUserGoogleDriveConnect)){

        routeDetails.queries.type = 'connect';
        routeDetails.queries.distributor = distributor.guid;
      }

      this.navigate(routeDetails);

    },

    navigate(navItem) {

      const { route, isExternalRedirect, queries } = navItem;

      if (!route) {

        return;
      }


      if (!isExternalRedirect) {

        const routeParams = {

          path: route
        };

        queries && (routeParams.query = queries);

        return this.$pushRoute(routeParams);
        // Navigate to the requested state
        return this.$pushRoute(routeParams);
      }

      // Open external links into a new window
      window.open(route);
    },

    navigateToSecondaryRoute(newVal, secondaryItems) {

      const desiredRoute = secondaryItems.find(i => i.name === newVal)

      this.navigate({ route: desiredRoute });
    },

    handleLogout() {

      if (this.isActiveConfirmGuardSet) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.logout()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.logout();
      }
    },

    logout() {

      // Cache the val, since it will be nulled from commitLogout;
      const isLoginSSO = this.isLoginSSO;

      this.$Amplify.Auth.signOut().then(() => {

        /**
         * Redirect logic should be after the Amplify signOut is successful,
         * in order for the Amplify login-component to load properly afterwards.
         * If the login is SSO, the redirect will be async and automatic, from the hosted UI.
         */
        if (!isLoginSSO) {

          this.navigate({ route: DEFAULT_UNAUTH_PATH });
        }
      });

      this.commitLogout();

      this.clearSocketAndPings();
    },

    getHubsSecondaryItems() {

      if (!this.siteBuilderApps.length) {

        return;
      }

      const hubs = [];

      this.siteBuilderApps.forEach((app, index) => {

        app.hubs && app.hubs.forEach(h => {

          hubs.push({
            label: h.name,
            name: h.name,
            route: `https://${app.hostnames[0]}/vh`,
            permission: '',
            icon: 'far-external-link',
            isExternalRedirect: true
          });
        });
      });

      if (!hubs.length) {

        hubs.push({

          label: 'No hubs configured',
          name: 'No hubs configured',
          disabled: true
        });
      }

      return hubs;
    },

    ...mapMutations({

      commitLogout: "app/LOGOUT",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      clearSocketAndPings: "app/clearSocketAndPings",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    }),
  },
  computed: {
    ...mapGetters({

      distributors: 'ingest/ingestDistributors',

      availableDeployments: "app/availableDeployments",

      organizationSelected: "app/organizationSelected",

      navItems: "app/navItems",

      adminNavItems: "app/adminNavItems",

      quickActionItems: 'app/quickActionItems',

      userNavItems: "app/userNavItems",
      userData: 'app/userData',

      isLoginSSO: 'app/isLoginSSO',

      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',

      siteBuilderApps: 'app/siteBuilderApps',

      userGoogleDriveData: 'ingest/userGoogleDriveData',
      userGoogleDriveTempData: 'ingest/userGoogleDriveTempData'
    }),

    availableDistributors() {

      return this.distributors.filter(el => el.guid != 'zoom-ia');
    },

    isUserGoogleDriveConnect() {

      const googleDistributor = this.distributors.find(el => el.guid === "google-drive");

      if ( !googleDistributor
        || !this.userGoogleDriveData && !this.userGoogleDriveTempData
        || this.userGoogleDriveData && this.userGoogleDriveData.userGuid != this.userData.guid
        || !googleDistributor.hasOwnProperty('seedings')
        || this.userGoogleDriveData && !googleDistributor.seedings.hasOwnProperty(this.userGoogleDriveData.email)
        || this.userGoogleDriveTempData && !googleDistributor.seedings.hasOwnProperty(this.userGoogleDriveTempData.email) ) {

        return false
      };

      return true;
    },

    classPosition() {

      return this.position +'-position';

    },
    mergedQuickActionItems() {

      if (this.siteBuilderApps.length) {

        const mergedItems = [...this.quickActionItems];

        mergedItems.push({
          label: "Recording",
          name: "recording",
          permission: '',
          route: `https://${this.siteBuilderApps[0].hostnames[0]}/vh/recordings?new`,
          isExternalRedirect: true,
          icon: 'fal fa-video'
        });

        return mergedItems
      }

      return this.quickActionItems;
    },

    activeNavItems() {

      if (this.useAdminNavItems) {

        return this.adminNavItems;
      }

      const applicableNavItems = [];

      // Check user and return only items, allowed for them
      this.navItems.forEach((navLink, index) => {

        if (!this.$auth(navLink.permission) || navLink.hidden) {

          return;
        }

        // Hubs should be build separately
        if (navLink.name === 'hubs') {

          if (!this.availableDeployments.some(deployment => deployment.type === 'hubs')) {

            return;
          } else {

            navLink.secondaryItems = this.getHubsSecondaryItems();
          };

        }

        if (!navLink.secondaryItems) {

          applicableNavItems.push(navLink);
          return;
        }

        const allowedSecondaryItems = navLink.secondaryItems.filter(secondaryNavLink => {


        if (secondaryNavLink.name === 'ingest' && !this.availableDeployments.some(deployment => deployment.type === 'ingest')) {

          return
        }

          return this.$auth(secondaryNavLink.permission) && !secondaryNavLink.hidden;
        });

        applicableNavItems.push({

          ...navLink,
          secondaryItems: allowedSecondaryItems
        });
      });

      return applicableNavItems;
    },

    activeUserNavItems() {

      if (!this.organizationSelected) {

        return [];
      }

      return this.userNavItems.filter(navLink => {

        return (this.$auth(navLink.permission) && !navLink.hidden);
      });
    },

    activeQuickActionItems() {

      return this.mergedQuickActionItems.filter(navLink => {

        if (navLink.name === 'quickImport' && !this.availableDeployments.some(deployment => deployment.type === 'ingest')) {

          return;
        }

        return (this.$auth(navLink.permission) && !navLink.hidden);
      });
    }
  },
}
</script>

<style lang="scss">

.main-header {

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  z-index: 6;

  @apply
  bg-white;

  // NOTE: The styles for the navBar scroll are working, but then
  // the overflow of the secondaryNavItems can not be displayed properly.
  // Possibly another approach can be investigated for mobile
  .nav-item-container {
    margin: 0;

    /* overflow-x: auto; */

    /* Hide scrollbar for Chrome, Safari and Opera */
    /* &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    } */

    /* Track */
   /*  &::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: #edf2f7;
    } */

    /* Handle */
    /* &::-webkit-scrollbar-thumb {
      background: #cbd5e0;
      border-radius: 100vh;
      border: 1px solid #edf2f7;
    } */

    /* Handle on hover */
    /* &::-webkit-scrollbar-thumb:hover {
      background: #a0aec0;
    } */

    /* Hide scrollbar for IE, Edge and Firefox */
    /* &.scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox *
    }
    */
  }

  .nav-item-container {

    .ds-btn {

      height: 36px;

      @apply
      text-base;
    }
  }

  .nav-item-btn {

    @apply
    font-medium
    text-black-600;

    &.selected {

      @apply
      bg-dark-blue-50
      text-black-900;
    }
  }

  .right-section {

    @apply
    flex
    flex-auto
    bg-white
    pl-5;
  }

  .quick-actions {

    @apply
    mr-4
    pr-5;

    .ds-dropdown__menu {

      min-width: 200px;

      svg {

        @apply
        text-black-500;
      }
    }
  }

  .main-username {

    max-width: 150px;
    margin: 0;

    @apply
    text-black-900;
  }

  .ds-dropdown__menu {

    max-height: 25rem;

    @apply
    px-3
    py-3;

    .ds-btn {

      @apply
      px-2;

    }
    .dropdown-distributor {
      .ds-btn {

        @apply
        w-auto
        px-2;

        &.connect {
          @apply
          text-sm
          font-medium
          text-blue-600;
        }
      }
      .distributor-connect {
        @apply
        text-sm
        font-medium
        text-black-400;
      }
      .distributor-enabled {
        @apply
        w-full
        text-sm
        font-medium
        text-black-700;
      }
    }

  }


  .quick-actions, .nav-item-container {
    .dropdown__menu-item:not(:last-of-type) {

      @apply
      border-b
      border-black-50
      pb-1
      mb-1;
    }
  }

  .user-nav {

    .ds-dropdown__menu {

      left: -100px;
      min-width: 310px;

      @apply
      overflow-y-visible
      px-5
      pt-6
      pb-1;

      .user-fullname {

        @apply
        mt-1
        mb-0
        text-lg
        text-black-900;
      }

      .user-email {

        @apply
        m-0
        text-xs
        text-black-500;
      }

      .ds-tooltip__el{
        z-index:25;
      }
    }

    .dropdown__menu-item {
      @apply
      text-base
      text-black-600
      mb-2;

      &.border-bottom:not(:last-of-type) {
        @apply
        border-b
        border-black-50
        border-solid;
      }

    }

  }
  .dropdown__import-item {
    @apply
    p-2
    overflow-hidden
    absolute
    z-10
    inline-block
    max-h-56
    flex-col
    whitespace-nowrap
    rounded
    shadow-modal
    bg-white;
    top: -30rem;
  }

  .right-position {
    right: calc(100% + 15px);
    top: -0.375rem;
  }

  .left-position {
    left: calc(100% + 15px);
    top: -0.375rem;
  }

  .img-item {

    min-width: 80px;
    height: 45px;

    .img-meta {

      display: none;
      background: white;
    }

    &:hover {

      .img-meta {

        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .img-logo {
        display: none;
      }
    }
  }


  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/
  &.bg-white {
    background: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }
}

</style>
