<template>
  <transition name="loader-animation">
    <div
      class="loader-container"
      :class="[variant, size, height]"
      :style="{ height: height, width: width }"
      :spinner="spinner"
    >

      <svg v-if="spinner === 'circle'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        enableBackground="new 0 0 40 40"
        xmlSpace="preserve"
      >
        <path
          opacity="0.2"
          fill="currentColor"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
        s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
        c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path
          fill="currentColor"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
        C22.32,8.481,24.301,9.057,26.013,10.047z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>

      <logo-loader-component v-else />
      <slot/>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  props: {
    variant: {
      type: String,
      required: false,
      default: "default"
    },
    size: {
      type: String,
      required: false,
      default: "size-s"
    },
    spinner: {
      type: String,
      required: false,
      default: "circle"
    },
    height: {
      type: Number | String,
      required: false,
      default: "auto"
    },
    width: {
      type: Number | String,
      required: false,
      default: "100%"
    }
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({})
  },
  computed: {
    ...mapGetters({})
  }
};
</script>

<style lang="scss" scoped>


.loader-animation-enter,
.loader-animation-leave-to {
  opacity: 0;
  transform: scale(0.85);
}

.loader-animation-enter-to,
.loader-animation-leave {
  opacity: 1;
  transform: scale(1);
}

.loader-animation-enter-active,
.loader-animation-leave-active {
  transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1),
    transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--sceneBgColor);
  border-radius: 0.5rem;
}
.loader-container.size-xs svg {
  width: 1rem;
  height: 1rem;
}
.loader-container.size-s svg {
  width: 2rem;
  height: 2rem;
}
.loader-container.size-m svg {
  width: 2.5rem;
  height: 2.5rem;
}
.loader-container.size-l svg {
  width: 3rem;
  height: 3rem;
}
.loader-container.clean {
 background: none;
}
</style>
