import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import moment from "moment-timezone";

const state = {

  // START refactoring new Props

  activeSection: 'program',
  // activeSection: 'videos',

  // END refactoring new props



  isSyncPeriodInProgress: false,

  channelsMeta: {
    selected: {},
  },

  activePeriods: {
    selected: {
      className: "period-current",
      label: "current",
      date: moment().format("ddd, MMM Do YYYY"),
      listingType: "selectedChannelListingsCurrent",
      periodKey: "currentPeriod",
    },

    current: {
      className: "period-current",
      label: "current",
      date: moment().format("ddd, MMM Do YYYY"),
      listingType: "selectedChannelListingsCurrent",
      periodKey: "currentPeriod",
    },
    next: {
      className: "period-next",
      label: "next",
      date: moment()
        .add(1, "days")
        .format("ddd, MMM Do YYYY"),
      listingType: "selectedChannelListingsNext",
      periodKey: "nextPeriod",
    },
    prev: {
      className: "period-prev",
      label: "prev",
      date: moment()
        .subtract(1, "days")
        .format("ddd, MMM Do YYYY"),
      listingType: "selectedChannelListingsPrev",
      periodKey: "prevPeriod",
    },
  },

  // new timeline props
  timelineExternalStart: 0,
  timelineExternalEnd: 0,
  timelineExternalIsControlled: false,
  timelineTempSchedulesSelection: [],
  timelineTempListingsSelection: [],
  deadzoneBufferMs: 600000,
  scheduleHoverData: null,
  // END new timeline props

  // Symbolizes, that the timeline container should overflow the viewport by 6 times 600vw
  timelineOverflowIndex: 6,

  timelineWriteBufferPeriod: 1000 * 60 * 10, // 10 mins

  currentHour: new Date().getHours(),

  timelineSlots: [],

  selectedChannelListings: {
    absolute: [],
    entirePeriod: [],
    nextPeriod: [],
    prevPeriod: [],
    currentPeriod: [],
    pristine: [],
  },

  selectedPeriodTotalListingsDuration: 0,

  selectedListing: {},

  firstAffectedTimestamp: 0,
  lastAffectedTimestamp: 0,

  /**
   * Since in the current version of the code, the timeline is divided
   * into 24 hours (sections). These two timespaces variables can
   * be configured in a config, which will allow them to change,
   * based on the selected period.
   */
  listingsTimespacesSelected: 24, // TODO: this can be based on the selected period (10min, 24hours, 1week, etc.)
  perHourMarkerCount: 2,
  listingsTimespacesSelectedMSRepresentation: 1000 * 60 * 60, // 1 hour

  loadedListingsAndSchedulesBounds: {
    start: 0,
    end: 0,
  },

  vodList: [],
  fromSelectedVodClips: [],

  playlists: [],

  mediatailors: [],

  channelsList: [],
  channelsListPristine: [],
  lockedChannelsGuids: {},

  generatedPlaylistData: [],

  activeToolbarPanel: 'livePreview',

  singleSourcePlayed: null,

  possibleVodTypes: {
    ad: {
      value: "ad",
      label: "Ad",
    },
    source: {
      value: "source",
      label: "Video",
    },
  },

  possibleVodTypesArr: [
    {
      value: "ad",
      label: "Ad",
    },
    {
      value: "source",
      label: "Video",
    },
  ],

  accessPrivacyOptions: [
    {
      value: 'false',
      label: 'Public',
      disclaimer: 'Available to everyone whitin your organization'
    },
    {
      value: 'true',
      label: 'Private',
      disclaimer: 'Available only to you'
    }
  ],

  stagedVodAdInsertionGuid: "",

  editedVod: { meta: {} }, // meta prop should always be present for UI purposes

  vodSettings : {}
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
