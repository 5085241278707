export const viewsChartOptions = {
  chart: {
    id: 'viewsChart',
    height: 350,
    type: 'line',
    toolbar: {
      show: false,
    }
  },
  // These are the labels
  xaxis: {
    categories: [],
    position: 'bottom',

    axisBorder: {
      show: false
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,
    }
  },

  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },

  colors: ["#0077FF", '#7B61FF'],
  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {

      formatter: (val) => {
        // Needed in order to bypass the default behavior is to show decimal point form small numbers
        return val.toFixed(0);
      }
    }
  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    // formatter: (val) => {
    //   return val + "%";
    // },
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758", 'red']
    }
  },
}

export const videoEngagementChartOptions = {
  chart: {
    id: 'videoEngagementChart',
    height: 350,
    type: 'area',
    toolbar: {
      show: false,
    }
  },

  colors: ['#7B61FF'],

  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.1,
      opacityTo: 0.6,
      stops: [0, 100]
    }
  },

  // These are the labels
  xaxis: {
    categories: [],
    position: 'bottom',

    axisBorder: {
      show: false
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,
    }
  },

  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {
      // show: false,
      // formatter: (val) => {
      //   return val + "%";
      // }
    }
  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    // formatter: (val) => {
    //   return val + "%";
    // },
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
}


export const deviceChartOptions = {
  chart: {
    id: 'deviceChart',
    height: 350,
    type: 'bar',
    toolbar: {
      show: false,
    }
  },

  // These are the labels
  xaxis: {
    categories: [],
    position: 'bottom',

    axisBorder: {
      show: false
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,
    }
  },

  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {
      // show: false,
      formatter: (val) => {
        return val + "%";
      }
    }
  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: true,
    formatter: (val) => {
      return val + "%";
    },
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
}


