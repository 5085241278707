<template>
<div class="library-vod-distributions-component">

  <h1 class="border-bottom pb-4 mt-2 mb-4 text-xl">Send for Distribution</h1>

  <div v-if="!isRequestPending" class="p-4 flex items-end justify-center select-dist-form bg-blue-100 rounded-md">
    <ioio-dropdown
      class="w-1/4 mr-6"
      :items=possibleDistributors
      titleProp="title"
      valueProp="guid"
      v-model="selectedDistributorGuid"
      placeholder="Distributors"
      topic="distributeVod"
      :required="true"
      :multiple="false"
    />

    <ioio-checkbox
      class="mx-6"
      topic="distributeVod"
      v-model=isNewVodDistributionForceUploadChecked
      size="small"
      label="Publish"
    />

    <ioio-checkbox
      class="mx-6"
      topic="distributeVod"
      v-if="isNewSyndicationDistributorSling"
      v-model=isNewVodDistributionForceUploadChecked
      size="small"
      label="Force Upload"
    />

    <ioio-dropdown
      v-if="isNewSyndicationDistributorSinclair && possibleSyndicationChannels.length"
      class="w-1/4 mx-6"
      :items=possibleSyndicationChannels
      titleProp="name"
      valueProp="name"
      v-model="newVodDistributionChannelSelected"
      placeholder="Sinclair Channels"
      topic="distributeVod"
      :required="true"
      :multiple="false"
    />

    <ioio-button
      topic="distributeVod"
      type="primary"
      variant="solid"
      class="btn-green ml-auto"
      @click="sendToDistribution">Submit
    </ioio-button>

  </div>

  <h1 class="border-bottom text-sm mt-6 pb-4">Distributions</h1>

  <ioio-table
    responsive
    :responsiveFlip="false"
    v-if="!isRequestPending && parsedSubmissionsList.length"
    class="vod-table rounded-md mt-4"
  >
    <template slot="thead">
      <tr class="">
        <th class="w-1/4">Distributor</th>
        <th class="w-1/3">Last Submission</th>
        <th class="w-1/4">Status</th>
      </tr>
    </template>

    <template slot="tbody">
      <tr v-for="(submission) in parsedSubmissionsList" :key="submission.guid">
        <td>
          <span class="text-sm">
            <img
              v-if="distributorMap[submission.distributorGuid].icon"
              :src="require(`@/assets/SVGs/${distributorMap[submission.distributorGuid].icon}.svg`)"
              height="30"
              width="90"
              class="mr-2"/>
            <h3 v-else>{{ distributorMap[submission.distributorGuid].name }}</h3>
          </span>
        </td>
        <td>
          <span class="text-sm">{{
            moment.tz(submission.createdAt, localTimezone).format("MMM Do YYYY h:mma z Z") }}
          </span>
        </td>
        <td>
          <ioio-tooltip
            v-if="submission.error"
            :text="submission.error || ''">
            <span class="text-sm cursor-help">
              {{ submission.status }}
            </span>
          </ioio-tooltip>
          <span v-else  class="text-sm">
            {{ submission.status }}
          </span>
        </td>
      </tr>
    </template>

  </ioio-table>

  <h4 v-if="!isRequestPending && !parsedSubmissionsList.length" class="text-center mt-8 mb-4">
    No Distributions Set
  </h4>

  <div v-if="isRequestPending" class="overflow-hidden">
    <logo-loader-component width="40px" height="40px" class="mt3"/>
  </div>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";


import moment from "moment-timezone";

import { getLocalTimezone } from "@utils/helpers";

import { distributorMap } from "@utils/constants";

export default {
  data: () => ({

    selectDistributorFormState: {},
    possibleDistributors: [],

    possibleSyndicationChannels: [], // Only for Sinclair

    selectedDistributorGuid: '',
    isNewVodDistributionPublished: false,
    newVodDistributionChannelSelected: '',
    isNewVodDistributionForceUploadChecked: false,
    parsedSubmissionsList: [],

    isRequestPending: false,
    distributorMap
  }),
  created() {

    this.moment = moment;
    this.localTimezone = getLocalTimezone();

    this.updateDistributionListForAsset();

    this.getDistributions().then(list => {

      this.possibleDistributors = list.filter(d => d.enabled && d.guid != "xumo" );

      this.setPossibleSyndicationChannels(this.possibleDistributors);
    });
  },
  mounted() {

    window.a = this;
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

      getSyndicationAssetsForVod: "syndication/getSyndicationAssetsForVod",

      getDistributions: "syndication/getDistributions",
      sendAssetToDistribution: "syndication/sendAssetToDistribution",
    }),

    setPossibleSyndicationChannels(distributorsList) {

      // Only for Sinclair
      const sinclairDistributor =
        distributorsList.find(d => d.guid === 'sinclair');

      this.possibleSyndicationChannels = sinclairDistributor.sinclairSettings.channels;
    },

    toggleIsVodDistributionPublished() {

      this.isNewVodDistributionPublished =
        !this.isNewVodDistributionPublished;
    },

    toggleIsVodDistributionForceUploadChecked() {

      this.isNewVodDistributionForceUploadChecked =
        !this.isNewVodDistributionForceUploadChecked;
    },

    updateDistributionListForAsset() {

      this.isRequestPending = true;

      let parsedSubmissionsList = [];

      this.getSyndicationAssetsForVod({ guid: this.editedVod.guid })
        .then((distributorsSubmissionData) => {

          distributorsSubmissionData.forEach(distData => {

            const distSubmissions = [...distData.submissions];

            distSubmissions.forEach(s => {

              s.distributorGuid = distData.distributorGuid;
            })

            const joinedSubmissions = parsedSubmissionsList.concat(distSubmissions);

            parsedSubmissionsList = joinedSubmissions;
          });

          this.parsedSubmissionsList =
            parsedSubmissionsList.sort((a, b) => b.createdAt - a.createdAt);
        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },

    sendToDistribution() {

      this.isRequestPending = true;

      const payload = {

        distGuid: this.selectedDistributorGuid,
        srcGuid: this.editedVod.guid,

        data: {
          published: this.isNewVodDistributionPublished,
        }
      };

      this.isNewSyndicationDistributorSinclair && (
        payload.data.channel = this.newVodDistributionChannelSelected
      );

      this.isNewSyndicationDistributorSling && (
        payload.data.forceUpload = this.isNewVodDistributionForceUploadChecked
      );

      this.sendAssetToDistribution(payload).then(() => {

        this.$toasted.info('Video asset sent to distribution.');
        this.updateDistributionListForAsset();
        // Null the create form
        this.selectedDistributorGuid = '';
        this.isNewVodDistributionPublished = false;
        this.newVodDistributionChannelSelected = '';
        this.isNewVodDistributionForceUploadChecked = false;

      }).finally(() => {

        this.isRequestPending = false;
      });
    },
  },
  computed: {
    ...mapGetters({

      editedVod: 'library/editedVod',
      msgBusMsgList: 'app/msgBusMsgList',
    }),

    isNewSyndicationDistributorSinclair() {

      return this.selectedDistributorGuid === 'sinclair';
    },

    isNewSyndicationDistributorSling() {

      return this.selectedDistributorGuid === 'sling';
    }
  },

  watch: {

    editedVod() {

      this.updateDistributionListForAsset();
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'source_syndication_job_finished') {

        if (newestMsg.data.errorMessage) {

          this.$toasted.error(newestMsg.data.errorMessage);

        } else {

          this.$toasted.success('Video asset syndication job finished successfully.');
        }

        this.updateDistributionListForAsset();
      }
    }
  }
}
</script>

<style lang="scss">

.library-vod-distributions-component {

  label, th {
    @apply font-medium;
  }

  .select-dist-form {

    border: 2px dashed var(--sceneBrColor);
  }

  .ds-dropdown__base {
    background-color: white;
  }

  .btn-green{
    &.ds-btn.is-primary.is-solid{
      @apply
      bg-green-500;
    }
  }

  .ds-checkbox [type="checkbox"]:not(:checked) + label.is-small, .ds-checkbox [type="checkbox"]:checked + label.is-small {

    font-size: 0.875rem;
  }

  .published-check.check-component, .force-upload-check.check-component {

    display: block;
    height: 65px;

    span {

      top: 38px;
      left: 36px;
    }
  }
  .vod-table {
    @apply
    p-0
    border
    border-black-100;
  }
  .ds-tooltip__el{
    z-index:10;
  }
}

</style>
