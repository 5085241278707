<template>
<section class="live-tab-component flex flex-auto flex-column">

  <div class="pane-content flex-auto p1 flex flex-auto flex-column">
    <ul class="list-reset m0" @mousedown="validateForm">
      <DraggableHolderComponent
        group-name="library-playlist"
        :get-child-payload="getChildPayload"
        :should-accept-drop="shouldAcceptDrop"
        :get-ghost-parent="getGhostParent"
        drag-class="ghost-element"
        :non-drag-area-selector="'#live-chunk-element'"
      >
        <DraggableComponent :id="preventDragId">
          <li
            class="fileitem flex mb1 box border rounded-4">
            <div class="fileitem-info ml1 flex flex-column flex-auto">
              <div class="flex items-center mb-025">
                <tag-component size="size-s" class="">{{ parseDuration(liveChunk.totalDuration) }}</tag-component>
              </div>

              <div class="flex items-center">

                <h4
                  class="fileitem-title size-1 m0"
                  >{{ (liveChunk.meta.title) | truncate(64, '...') }}
                </h4>
              </div>
              <h4
                class="fileitem-title size-3 gray+1 italic">{{ liveChunk.meta.description | truncate(64, '...') }}
              </h4>
            </div>
          </li>
        </DraggableComponent>
      </DraggableHolderComponent>
    </ul>

    <vue-form :state="formState" @submit.prevent class="flex flex-auto flex-column overflow-auto">

      <h4>Enter the details of the Live Schedule</h4>

      <div>
        <label>Duration (ms)</label>
        <input
          type="number"
          min="60000"
          max="720000"
          placeholder="Duration"
          v-model="liveChunk.totalDuration"
        >
      </div>

      <validate class="" tag="label">
        <span class="text-danger">Title <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="Title"
          v-model="liveChunk.meta.title"
          required
          name="liveChunkTitle"/>
        <field-messages name="liveChunkTitle" show="$submitted || $dirty && $touched">
          <div slot="required">Title is a required field</div>
        </field-messages>
      </validate>

      <validate class="" tag="label">
        <span class="text-danger">HLS URL <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="HLS URL"
          v-model="liveChunk.url"
          required
          name="liveChunkUrl"/>
        <field-messages name="liveChunkUrl" show="$submitted || $dirty && $touched">
          <div slot="required">HLS URL is a required field</div>
        </field-messages>
      </validate>

      <validate class="" tag="label">
        <span class="text-danger">Slate Source GUID <span class="text-danger">*</span></span>
        <input
          type="text"
          placeholder="Name"
          v-model="liveChunk.guid"
          required
          name="liveChunkSlateGuid"/>
        <field-messages name="liveChunkSlateGuid" show="$submitted || $dirty && $touched">
          <div slot="required">Slate Source GUID is a required field</div>
        </field-messages>
      </validate>

      <div>
        <label>Description</label>
        <input
          type="text"
          placeholder="Description"
          v-model="liveChunk.meta.description"
        >
      </div>

    </vue-form>

    <footer class="mt1">
      <button-component
        variant="primary"
        intent="danger"
        @click="clearChunkData"
      >Clear Data</button-component>
    </footer>
  </div>
</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    dragOrigin: 'live-tab',
    liveChunk: {

      totalDuration: 600000,

      meta: {
        title: 'Live Program',
        description: '',
      },

      type: 'live',

      // a guid should be added : `When schedule is of type ‘source’ or ‘ad’ it represents the source GUID which will be used in the HSL stream. When schedule is of type ‘live’ it represent slate source GUID`
      guid: '',

      // Master HLS URL for the ‘live’ schedules
      url: ''
    },

    preventDragId: '',
    formState: {},
  }),
  props: {},
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    }),


    // should be called onMouseDown so that it validates the
    // form before the live chunk dragging begins and raises
    // preventDragId as non-drag-area-selector if needed
    validateForm() {

      if (this.isFormInvalid) {

        this.preventDragId = 'live-chunk-element';

        this.formState._submit();
        this.$toasted.error('Enter all required fields in order to drag to the rundown.');

      } else {

        this.preventDragId = '';
      }
    },

    clearChunkData() {

      this.liveChunk = {
        totalDuration: 60000,
        meta: {
          title: 'Live Program',
          description: '',
        },
        type: 'live',
        guid: '',
        url: ''
      };
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(+ms, 'returnFullHour');
    },

    getChildPayload(removedIndex) {

      return {

        item: {
          ...this.liveChunk,
          totalDuration: +this.liveChunk.totalDuration
        },
        origin: this.dragOrigin,
      };
    },

    shouldAcceptDrop(sourceContainerOptions, payload) {
      return false;
    },

    getGhostParent() {
      return document.body;
    },
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    }),

    isFormInvalid() {

      return this.formState.$invalid;
    }
  }
}
</script>

<style lang="scss" scoped>



.fileitem {

  display: flex;
  margin-bottom: 2px;
  border-radius: 0;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;
  &:hover {
    background: var(--highlightBgColor);
  }
}
</style>
