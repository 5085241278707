<template>
<div
class="epg-bar-component pane border-left flex flex-column flex-none"
:class="activeToolbarPanel !== 'epg' ? 'hide' : 'width+3'"
>
  <div class="pane-header m0 p2 flex items-center flex-none">

    <h4 class="caps m0 size-2">
      Generate EPG
    </h4>
  </div>

  <div class="pane-content flex-auto" style="overflow: auto">
    <div class="p2">

      <vue-form :state="formState" class="relative" @submit.prevent>

        <section class="flex">
          <div class="mr3 ratio1-2">
            <label class="mb1" for="start-date-time">Start Date & Time <span class="text-danger">*</span></label>

            <datetime
              v-model="start"
              input-id="startDateTime"
              type="datetime"
              use12-hour
              placeholder="Start Date Time"
              format="DDD t ZZZZ"
            ></datetime>

          </div>

          <div class="ratio1-2">
            <label class="mb1" for="end-date-time">End Date & Time</label>

            <datetime
              v-model="end"
              input-id="endDateTime"
              type="datetime"
              use12-hour
              :min-datetime="start"
              placeholder="End Date Time"
              format="DDD t ZZZZ"
            ></datetime>
          </div>
        </section>


        <section class="flex mt2">

          <validate class="ratio1-2 mr3" tag="label">

            <div class="mb1">Format <span>*</span></div>

            <select
              class="select ratio1-1"
              id="format"
              v-model="format"
              required
              name="format">
              <option
                :key="format"
                :value="format"
                v-for="format in possibleFormats"
                >{{ format }}
              </option>
            </select>

            <field-messages name="format" show="$submitted || $dirty && $touched">
              <div slot="required">Format is a required field</div>
            </field-messages>
          </validate>


          <!--
          NOTE: currently the EpgBar is located in a place, that is in relation to a currently opened channel - > use it's guid hardcoded
          validate class="ratio1-2" tag="label">
            <div class="mb1">Channel <span v-if="format === 'brightcove'">*</span></div>
            <input
              class="input"
              type="text"
              id="name"
              v-model="channel"
              name="channelName"
              placeholder="Name"
              :required="format === 'brightcove'"/>
            <field-messages name="channelName" show="$submitted || $dirty && $touched">
              <div slot="required">Channel is a required field</div>
            </field-messages>
          </validat e-->
        </section>

        <footer class="flex justify-end mt2" v-if="!isRequestPending">

          <button-component @click.native.stop="generateEpg" variant="primary" size="size-m" class=""
            :class="isRequestPending || (formState.$submitted && formState.$invalid) ? 'disabled' : ''">Generate</button-component>

        </footer>

        <article v-else class="overflow-hidden">
          <logo-loader-component width="40px" height="22px" />
        </article>
      </vue-form>

    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    formState: {},
    isRequestPending: false,


    possibleFormats: ['brightcove', 'xmltv'],

    start: '', // Filter results by start timestamp
    // onlyActive: true, // Hardcoded for now Generate EPG only on the active channels
    format: '', // In what format to return the data

    channel: '', // Filter result by channel guid. Required for brightcove format.

    current: 1, // Hardcoded for now Flag whether to include current playing listing when start param is provided
    end: ''

  }),
  props: {},

  created() {

    this.start = new Date().toISOString();
  },

  methods: {

    generateEpg() {

      this.formState._submit();
      this.formState._validate();

      if (this.formState.$invalid) {

        return;
      }

      const params = {

        start: new Date(this.start).getTime(),
        format: this.format,

        channel: this.channelsMeta.selected.guid
      };

      // this.channel && (params.channel = this.channel);

      // this.onlyActive && (params.onlyActive = this.onlyActive);

      this.current && (params.current = this.current);
      this.end && (params.end = new Date(this.end).getTime());

      this.isRequestPending = true;

      this.generateEpgAndDownload(params)
        .then((resp) => {

          const { headers, data } = resp;

          const dispositionHeader =  headers.get("content-disposition");

          const titleMatch = dispositionHeader.match(/filename="([^']+)"/);

          const title = titleMatch[1] || 'EPG.xml';

          const element = document.createElement('a');

          element.setAttribute('href', 'data:attachment/xml;charset=utf-8,' + encodeURI(data));
          element.setAttribute('download', title);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        })
        .catch(resp => {

          const err = resp.error;

          /* Custom err for xmltv */
          if (this.format === 'xmltv') {

            if (typeof err === 'string') {

              this.$toasted.error(err);
              return;
            }

            for (let key in err) {

              const e = err[key];

              if (e.error) {

                this.$toasted.error(`${e.error}. In listing ${this.$options.filters.truncate(e.title, 64, "...")}, channel ${this.$options.filters.truncate(e.channelName, 64, "...")}`);
              } else {

                this.$toasted.error(e);
              }
            }

          } else {

            this.$toasted.error(err);
          }
        })
        .finally(() => {

          this.isRequestPending = false;
        })
    },


    ...mapMutations({

    }),
    ...mapActions({
      generateEpgAndDownload: "channelManager/generateEpgAndDownload"
    })
  },
  computed: {
    ...mapGetters({
      activeToolbarPanel: "channelManager/activeToolbarPanel",
      channelsMeta: "channelManager/channelsMeta",
    })
  },
}
</script>

<style lang="scss" scoped>


/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
@import "@indexScss";
@include overwrite-styleberry-styles-inputs;
@include overwrite-styleberry-styles-htags-margin;
@include overwrite-styleberry-styles-htags-font-size;

</style>
