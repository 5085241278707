<template>
<div class="workflow-schedule-select-component">
  <div>
    <div class="flex">

      <label for="start-date-time-section" class="pt3 mr3" style="width: 100px">Date & Time</label>

      <div class="mr3">
        <label for="start-date-time">Date & Time <span class="text-danger">*</span></label>

        <datetime
          v-model="startDateTime"
          id="startDateTime"
          type="datetime"
          use12-hour
          :min-datetime="timeOfLoad"
          placeholder="Start Date Time"
          format="DDD t ZZZZ"
        ></datetime>

      </div>
      <!-- Temporary hide the dropdown, until timezone specifications are ready -->
      <div class="hide">
        <label>Timezone <span class="text-danger">*</span></label>
        <select id="timezoneSelected" v-model="timezoneSelected" style="height: 40px">
          <option
            v-for="zone in possibleTimezones"
            :value="zone"
            >{{ zone }}</option
          >
        </select>
      </div>
    </div>
    <div class="flex">

      <label for="start-date-time-section" class="pt3 mr3" style="width: 100px">Duration</label>

      <div class="mr3">
        <label>Hours</label>
        <select id="timezoneHoursDuration" v-model="timezoneHoursDuration" style="height: 40px">
          <option :value="0">0</option>
          <option
            v-for="hour in 11"
            :value="hour"
            >{{ hour }}</option
          >
        </select>
      </div>

      <div class="mr3">
        <label>Minutes</label>
        <select id="timezoneMinutesDuration" v-model="timezoneMinutesDuration" style="height: 40px">
          <option :value="0">0</option>
          <option
            v-for="minute in 59"
            :value="minute"
            >{{ minute }}</option
          >
        </select>
      </div>


      <label for="" class="pt3 mr3">Recurring</label>

      <check-component
        type="checkbox"
        label=""
        class=""
        style="margin-top: 34px"
        :val="isRecurringOptionApplied"
        :on-change="() => toggleRecurringOptionApplied()"/>
    </div>

    <section class="recurring-options-section" v-if="isRecurringOptionApplied">
      <h3>Recurrence</h3>

      <div class="flex mt2">

        <label for="start-date-time-section" class="pt1 mr3" style="width: 100px">Repeat Every</label>

        <div class="mr3">
          <select id="repeatEvery" v-model="repeatEvery" style="height: 40px">
            <option
              v-for="option in possibleRepeatEveryVals"
              :value="option"
              :key="option"
              >{{ option }}</option
            >
          </select>
        </div>

        <div class="flex" v-if="repeatEvery === 'WEEK'">
          <label for="start-date-time-section" class="pt1 mr3">On</label>
          <multiple-check-component style="margin-top: 8px;" :options="recurrenceWeekDaysSelected" :onUpdate="updateRecurrenceWeekDaysSelected"/>
        </div>
      </div>

      <div class="flex mt2">

        <label for="start-date-time-section" class="pt1 mr3 mb2" style="width: 100px; height: 40px">Ends</label>

        <label class="mr-4 mt-2" @click="() => enableStopDateVisible(false)">
          <input type="radio" :checked="!isStopDateVisible" name="isStopDateVisible" />
          <span class="checkmark radio"></span>
          Never
        </label>

        <label class="mr-16 mt-2" @click="() => enableStopDateVisible(true)">
          <input type="radio" :checked="isStopDateVisible" name="isStopDateVisible" />
          <span class="checkmark radio"></span>
          On
        </label>

        <datetime
          v-show="isStopDateVisible"
          v-model="endDate"
          id="endDate"
          type="date"
          :min-datetime="timeOfLoad"
          placeholder="Schedule End Date"
          format="D"
        ></datetime>
      </div>

    </section>





</div>


</div>
</template>

<script>

import { getPossibleTimezones } from "@utils/helpers";

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    startDateTime: null,
    timeOfLoad: '',


    endDate: null,
    timezoneSelected: '',
    possibleTimezones: [],

    // computed sum in calcedDuration prop
    timezoneHoursDuration: 1,
    timezoneMinutesDuration: 0,


    isRecurringOptionApplied: false,
    possibleRepeatEveryVals: ['WEEK', 'DAY'],
    repeatEvery: 'DAY',
    recurrenceWeekDaysSelected: [{
        label: 'S',
        abbr: 'SUN',
        value: false
      },{
        label: 'M',
        abbr: 'MON',
        value: false
      },{
        label: 'T',
        abbr: 'TUE',
        value: false
      },{
        label: 'W',
        abbr: 'WED',
        value: false
      },{
        label: 'T',
        abbr: 'THU',
        value: false
      },{
        label: 'F',
        abbr: 'FRI',
        value: false
      },{
        label: 'S',
        abbr: 'SAT',
        value: false
      }
    ],
    isStopDateVisible: false,

  }),

  props: {
    initialValues: Object
  },

  created() {

    this.timeOfLoad = new Date().toISOString();

    this.possibleTimezones = getPossibleTimezones();

    // NOTE: hardcoded for now
    this.timezoneSelected = this.possibleTimezones[0];

    if (this.initialValues) {

      const {

        startDateTime,
        duration,
        recurrence

      } = this.initialValues;


      startDateTime && (this.startDateTime = startDateTime);

      if (duration) {

        const timezoneMinutesDuration = duration % 60;
        this.timezoneMinutesDuration = timezoneMinutesDuration;

        this.timezoneHoursDuration = (duration - timezoneMinutesDuration) / 60;
      }

      const {

        unit,
        days,
        endDate

      } = recurrence || {};

      if (unit) {

        this.isRecurringOptionApplied = true;
        this.repeatEvery = unit;
      }

      if (endDate) {

        this.isStopDateVisible = true;
        this.endDate = endDate;
      }

      if (days) {

        const daysMap = days.reduce((o, key) => ({ ...o, [key]: true }), {});


        this.recurrenceWeekDaysSelected.forEach((d) => {

          if (daysMap[d.abbr]) {

            d.value = true;
          }
        });
      }

    }
  },

  methods: {


    enableStopDateVisible(newVal) {

      this.isStopDateVisible = newVal;
    },

    updateRecurrenceWeekDaysSelected(newVal) {

      this.recurrenceWeekDaysSelected = [...newVal];
    },

    toggleRecurringOptionApplied() {

      this.isRecurringOptionApplied = !this.isRecurringOptionApplied;
    },

    getValues() {

      return {

        startDateTime: this.startDateTime,
        duration: this.calcedDuration,
        recurrence: this.isRecurringOptionApplied ? {
          unit: this.repeatEvery,
          days: this.recurrenceWeekDaysSelected, // will need to be parsed
          endDate: (this.isStopDateVisible && this.endDate) || null
        }: null,
        timezoneSelected: this.timezoneSelected
      }
    },

    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    }),

    calcedDuration() {
      const calcedDuration =
        this.timezoneHoursDuration * 60 +
        this.timezoneMinutesDuration;

      return calcedDuration;
    },
  },
}
</script>

<style lang="scss" scoped>



</style>
