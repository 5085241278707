<template>
<video
  ref="videoPlayer"
  id="video-container"
  width="100%"
  controls
  :poster="thumbnail"
  class="video-player-component mb-2 rounded"
></video>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Hls from "hls.js";

export default {
  data: () => ({

    hls: null,
    hlsjsConfig: {
      maxBufferSize: 0,
      maxBufferLength: 30,
      liveSyncDuration: 30,
      liveMaxLatencyDuration: Infinity
    },
  }),
  props: {
    previewVod: Object,
    shouldLoadSynchronously: Boolean,
    onVodLoadCompleteStatus: Function,
    thumbnail: String,
    startPlaybackOnLoad: Boolean,
    vodSettings: Object
  },
  mounted() {

    if (this.shouldLoadSynchronously) {

      this.loadVidPlayback();
    }
  },
  methods: {

    loadVidPlayback() {

      if (this.previewVod.status === 'complete') {

        this.onVodLoadCompleteStatus && this.onVodLoadCompleteStatus();
      }

      if (Hls.isSupported()) {

        // hlsUrl is only present for encoded vods.
        // proxyUrl for mp4 playback may also not be present if the
        // vod is still processing.
        const mp4Url =
          this.previewVod.sourceProxy && this.previewVod.sourceProxy.proxyUrl;

        const shouldPlayMp4 = !this.previewVod.hlsUrl && mp4Url;

        const videoPlaybackUrl = shouldPlayMp4 ?
          mp4Url :
          (this.vodSettings && this.vodSettings['signed-urls']) ?
            this.previewVod.hlsUrlAdmin : this.previewVod.hlsUrl;

        // Null the prev instance
        if (this.hls) {

          this.hls.stopLoad();
          this.hls.destroy();

          this.hls = null;
        }

        const video = this.$refs.videoPlayer;

        // If the previously loaded source was mp4 manual
        // deletion of the <source> element is required
        const prevLoadedMp4Source = video && video.firstElementChild;

        if (prevLoadedMp4Source) {

          video.pause();
          video.removeChild(prevLoadedMp4Source); // empty source

          video.load(); // refresh the video, needed for Firefox
        }

        // The source will not be able to play neither through hls nor mp4
        if (!videoPlaybackUrl) {

          return;
        }

        /**
         * The videoPlaybackUrl is mp4 instead of hls
         */
        if (shouldPlayMp4) {

          const source = document.createElement('source');

          source.src = videoPlaybackUrl;
          source.type = 'video/mp4';

          video.appendChild(source);

          this.startPlaybackOnLoad && video.play();

        } else {

          /**
           * The source is HLS
           */
          this.hls = new Hls({

            ...this.hlsjsConfig
          });

          this.hls.loadSource(videoPlaybackUrl);

          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {

            this.hls.attachMedia(video);

            this.hls.media.muted = true;

            this.startPlaybackOnLoad && this.hls.media.play();
          });
        }
      }
    }
  },

  watch: {

    previewVod(newVal, oldVal) {

      if (newVal.guid !== oldVal.guid && !newVal.isLoadingFullData ||
        newVal.guid === oldVal.guid && !newVal.isLoadingFullData ||
        newVal.guid === oldVal.guid && newVal.status !== oldVal.status) {

        this.loadVidPlayback();
      }
    },
  }
}
</script>

<style lang="scss">


</style>
