<template>
<modal
  name="edit-workflow"
  width="600"
  height="auto"
  :scrollable="true"
  classes="edit-workflow-modal-component v--modal p2"
>
  <h3>Edit workflow {{ workflow.name }}?</h3>
  <button-component
    variant="primary"
    @click="onCancel"
  >Cancel</button-component>
  <button-component
    variant="primary"
    @click="onEdit"
  >Save</button-component>

</modal>
</template>

<script>
export default {
  data: () => ({
    name: 'EditWorkflowModal'
  }),
  props: {
    workflow: Object,
    onEdit: Function,
    onCancel: Function
  }
}
</script>

<style lang="scss" scoped>



</style>
