/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'multiple-19': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" data-color="color-2" d="M17 2c-.905 0-1.73.312-2.4.818A6.952 6.952 0 0116 7v1c0 .953-.193 1.862-.54 2.691A4 4 0 0021 7V6a4 4 0 00-4-4z"/><path pid="1" d="M9 13a5 5 0 01-5-5V7a5 5 0 0110 0v1a5 5 0 01-5 5z"/><path pid="2" data-color="color-2" d="M22.839 14.405C21.555 13.815 19.354 13 17 13c-1.195 0-2.35.211-3.367.495.376.078.753.157 1.13.253 2.911.742 4.97 3.275 5.192 6.252H23a1 1 0 001-1v-2.779c0-.782-.45-1.49-1.161-1.816z"/><path pid="3" d="M17 22H1a1 1 0 01-1-1v-.475c0-2.275 1.527-4.277 3.731-4.839C5.205 15.31 7.021 15 9 15s3.795.31 5.269.686C16.473 16.248 18 18.25 18 20.525V21a1 1 0 01-1 1z"/></g>'
  }
})
