<template>

<section>

  <redirect-prevent-confirm-modal-component />
  <upload-vod-modal-component v-if="isInitialAppStateReady" />

  <section id="app-container" v-if="isInitialAppStateReady" :class="$route.path === '/vod/library-new' ? 'mozila-container' : 'app-container'">

    <router-view :class="[{ 'is-active': isOpen || getMain }]" class="transition-blur"></router-view>

    <div id="modal-portal">
      <portal-target name="modals" multiple />

    </div>
    <!--Notifications class="transition-blur" :class="[{ 'is-active': getMain }]" /-->

    <ioio-loader main class="transition-blur" :class="[{ 'is-active': !getMain }]" />

  </section>
</section>
</template>

<script>

// The Design system tries to access process. Shim for it on the FE
if (typeof process === 'undefined') {

  window.process = {}
}

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return { };
  },

  created() {

    /**
     * Subscribe for mutations and watch for possible FORCE_LOGOUT
     * This is triggered if a token expires, for example. Redirect.
     */
    const unsubscribe = this.$store.subscribe((mutation, state) => {

      if (mutation.type === "app/FORCE_LOGOUT") {

        unsubscribe();

        if (this.$route.path !== 'login') {

          this.$pushRoute("/login");
        }
      }
    });


    /**
     * Make the toast library instance available to the store
     */
    this.setToastCtrlInStore(this.$toasted);
  },

  mounted() {

    this.loaderToggleMain(false);
  },

  computed: {
    ...mapGetters({
      isInitialAppStateReady: "app/isInitialAppStateReady",
      getMain: 'loader/getMain',
      isOpen: 'modals/isOpen'
    })
  },
  methods: {
    ...mapMutations({
      setToastCtrlInStore: "app/SET_TOAST_CTRL_IN_STORE",
    }),
    ...mapActions({

      loaderToggleMain: 'loader/toggleMain'
    })
  }
};
</script>


<style lang="scss">
@import "@indexScss";


html {
  overflow: auto !important;
}

*::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(115, 120, 140, var(--tw-bg-opacity));
}


body {
  /* @Stefup minimize-when-upload should be handled here as well (remove blur etc) .transition-blur.is-active */
  &.ds-modal-open {

    overflow: hidden;
  }
}
.application {
  .container {
    padding: 0;
  }
}

/* recommended css code for vue-svgicon */
.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.svg-fill {

  fill: currentColor;

  &:not(.enable-stroke) {

    stroke: none;
  }
}

.svg-up {
  /* default */
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}
// end (vue-svgicon styles)


/* VueForms styles */

/**
 * Map the error styles to the same rules as the forms
 * validator ($submitted || $dirty && $touched)
 */
.vf-form-submitted .vf-field-invalid,
*:not(.vf-field-pristine).vf-field-invalid {

  color: red !important;
}

.timeline-component {

  .vue-slider-dot-tooltip-top {

    top: 20px;
    right: calc(100% - 0px);
    left: initial;
    transform: translate(-50%, -100%);
  }
  .vue-slider-dot-tooltip-inner-top::after {

    top: 30%;
    left: 100%;
    transform: rotate(270deg);
  }
}

.break-word {

  word-wrap: break-word;
  word-break: break-word;
}

 /* For Firefox */
@-moz-document url-prefix() {
  .mozila-container {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}


/**
 * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
 * Tailwind classes are present in the HTML, so deleting the following lines,
 * when styleberry is deprecated should still work.
**/
.flatpickr-calendar {

  input {

    min-height: auto !important;
  }

  select {

    display: initial !important;
    min-height: auto !important;
  }
}
</style>
