const ingestDistributors = (state) => state.distributors;
const importDistributorsList = (state) => state.importDistributorsList;
const choosenDistributor = (state) => state.choosenDistributor;
const isZoomIntegrationRequestPending = () => JSON.parse(localStorage.getItem('ZoomIntegrationRequestPending'));
const userGoogleDriveData = (state) => state.googleDriveData;
const userGoogleDriveTempData = (state) => state.googleDriveTempData;

export {

  ingestDistributors,
  importDistributorsList,
  choosenDistributor,
  isZoomIntegrationRequestPending,
  userGoogleDriveData,
  userGoogleDriveTempData
};