/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'playlist': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#5c7499"><path pid="0" d="M23 10H1a1 1 0 01-1-1V3a1 1 0 011-1h22a1 1 0 011 1v6a1 1 0 01-1 1z"/><path pid="1" data-color="color-2" d="M23 16H1a1 1 0 010-2h22a1 1 0 010 2zM23 22H1a1 1 0 010-2h22a1 1 0 010 2z"/></g>'
  }
})
