/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button-previous': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g class="nc-icon-wrapper" _fill="#002c65"><path pid="0" d="M21.458 1.11a.999.999 0 00-1.039.076l-14 10a1 1 0 000 1.627l14 10A.988.988 0 0021 23a1.001 1.001 0 001-1V2c0-.375-.209-.718-.542-.89z"/><path pid="1" data-color="color-2" d="M3 0a1 1 0 00-1 1v22a1 1 0 102 0V1a1 1 0 00-1-1z"/></g>'
  }
})
