<template>
<div class="box e-px0">
  <h2 class="box__main-title">Insights</h2>
  <ioio-tabs class="insights" size="medium"  @input="onChangeAssetTab">
    <ioio-tab
      name="Library"
      class="insights__cntnt"
      :selected="true"
    >
      <div class="insights__rows-data"  v-if="!isRequestPending && videoStats">
        <span class="insights__interval">Last 30 days</span>
        <!-- Row with stats -->

        <!-- !! UNCOMMENT WHEN BE IS READY WITH REAL DATA !!!!!!!!!!!!!!!! -->
        <!-- <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-cloud-arrow-up"/>
          </div>

          <p class="insights__name">Total uploads</p>

          <ioio-tooltip text="All uploaded videos">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="videoStats.total_uploads.percent+'%'"
            :type="videoStats.total_uploads.type"
            size="large"
            :isMetric="videoStats.total_uploads.percent !== 0"
          />

          <div class="insights__value">{{videoStats.total_uploads.value}}</div>
        </div> -->
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-chart-simple"/>
          </div>

          <p class="insights__name">Total views</p>

          <ioio-tooltip text="All video plays">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="videoStats.total_views.percent+'%'"
            :type="videoStats.total_views.type"
            size="large"
            :isMetric="videoStats.total_views.percent != 0"
          />

          <div class="insights__value">{{videoStats.total_views.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-eye"/>
          </div>

          <p class="insights__name">Unique views</p>

          <ioio-tooltip text="All unique user video plays">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="videoStats.unique_views.percent+'%'"
            :type="videoStats.unique_views.type"
            size="large"
            :isMetric="videoStats.unique_views.percent != 0"
          />

          <div class="insights__value">{{videoStats.unique_views.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-clock"/>
          </div>

          <p class="insights__name">Avg watch time</p>

          <ioio-tooltip text="Average watch time per video">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="videoStats.avg_watch_time.percent+'%'"
            :type="videoStats.avg_watch_time.type"
            size="large"
            :isMetric="videoStats.avg_watch_time.percent != 0"
          />

          <div class="insights__value">{{videoStats.avg_watch_time.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-video"/>
          </div>

          <p class="insights__name">Avg completion</p>

          <ioio-tooltip text="Average completion rate of all videos">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="videoStats.avg_completion.percent+'%'"
            :type="videoStats.avg_completion.type"
            size="large"
            :isMetric="videoStats.avg_completion.percent != 0"
          />

          <div class="insights__value">{{videoStats.avg_completion.value}}</div>
        </div>
        <!-- End of row with stats -->
      </div>
      <div class="insights__chart"  v-if="!isRequestPending && videoStats">
        <h3 class="insights__chart-title">Video consumption</h3>
        <apexchart width="100%" height="250" type='area' :options="videoConsumptionOptions" :series="videoConsumptionSeries" ></apexchart>
      </div>

      <div class="nodata" v-else-if="!isRequestPending">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div class="nodata-text">There is no activity</div>
        <ioio-button
          v-if="activeQuickActionItems.quickUpload"
          class="mt-4"
          size="small"
          type="primary"
          variant="outline"
          @click="navigate(activeQuickActionItems.quickUpload)"
        >Upload video
        </ioio-button>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab>

    <!-- !! UNCOMMENT WHEN BE IS READY WITH REAL DATA !!!!!!!!!!!!!!!! -->

    <!-- <ioio-tab
      name="Events"
      class="insights__cntnt"
      v-if="eventEnabled"
    >
      <div class="insights__rows-data" v-if="!isRequestPending && eventStats && eventEnabled">
        <span class="insights__interval">Last 30 days</span>

        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-chart-simple"/>
          </div>

          <p class="insights__name">Total page visits</p>

          <ioio-tooltip text="Total page visits for all events">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="eventStats.total_visits.percent+'%'"
            :type="eventStats.total_visits.type"
            size="large"
            :isMetric="eventStats.total_visits.percent != 0 "
          />

          <div class="insights__value">{{eventStats.total_visits.value}}</div>
        </div>

        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-user-group"/>
          </div>

          <p class="insights__name">Unique page visits</p>

          <ioio-tooltip text="Unique page visits for all events">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="eventStats.unique_visits.percent+'%'"
            :type="eventStats.unique_visits.type"
            size="large"
            :isMetric="eventStats.unique_visits.percent != 0"
          />

          <div class="insights__value">{{eventStats.unique_visits.value}}</div>
        </div>

        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-calendar"/>
          </div>

          <p class="insights__name">Registrations</p>

          <ioio-tooltip text="Total registrations for all events">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="eventStats.registration.percent+'%'"
            :type="eventStats.registration.type"
            size="large"
            :isMetric="eventStats.registration.percent != 0"
          />

          <div class="insights__value">{{eventStats.registration.value}}</div>
        </div>

        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-eye"/>
          </div>

          <p class="insights__name">Live views</p>

          <ioio-tooltip text="Total live attendee views">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="eventStats.live_views.percent+'%'"
            :type="eventStats.live_views.type"
            size="large"
            :isMetric="eventStats.live_views.percent != 0"
          />

          <div class="insights__value">{{eventStats.live_views.value}}</div>
        </div>

        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-video"/>
          </div>

          <p class="insights__name">Event recording views</p>

          <ioio-tooltip text="otal views of event recordings">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="eventStats.event_recording_views.percent+'%'"
            :type="eventStats.event_recording_views.type"
            size="large"
            :isMetric="eventStats.event_recording_views.percent != 0"
          />

          <div class="insights__value">{{eventStats.event_recording_views.value}}</div>
        </div>

      </div>
      <div class="insights__chart" v-if="!isRequestPending && eventStats && eventEnabled">
        <h3 class="insights__chart-title">Event registrations</h3>
        <apexchart width="100%" height="250" type='area' :options="eventRegistrationsOptions" :series="eventRegistrationsSeries" ></apexchart>
      </div>
      <div class="nodata" v-else-if="!isRequestPending">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div v-if="eventEnabled" class="nodata-text">There is no activity</div>
        <div v-else class="nodata-text">The service is not enabled</div>
        <ioio-button
          v-if="activeQuickActionItems.quickEvent"
          class="mt-4"
          size="small"
          type="primary"
          variant="outline"
          @click="navigate(activeQuickActionItems.quickEvent)"
        >Create event
        </ioio-button>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab> -->
    <ioio-tab
      name="Channels"
      class="insights__cntnt"
    >
      <div class="insights__rows-data" v-if="!isRequestPending && channelsStats">
        <span class="insights__interval">Last 30 days</span>
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-chart-simple"/>
          </div>

          <p class="insights__name">Total viewers</p>

          <ioio-tooltip text="All channels play">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="channelsStats.total_views.percent+'%'"
            :type="channelsStats.total_views.type"
            size="large"
            :isMetric="channelsStats.total_views.percent != 0"
          />

          <div class="insights__value">{{channelsStats.total_views.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-user-group"/>
          </div>

          <p class="insights__name">Unique viewers</p>

          <ioio-tooltip text="Unique channels viewers">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="channelsStats.unique_views.percent+'%'"
            :type="channelsStats.unique_views.type"
            size="large"
            :isMetric="channelsStats.unique_views.percent != 0"
          />

          <div class="insights__value">{{channelsStats.unique_views.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-plus"/>
          </div>

          <p class="insights__name">New viewers</p>

          <ioio-tooltip text="New unique channels viewers">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="channelsStats.new_views.percent+'%'"
            :type="channelsStats.new_views.type"
            size="large"
            :isMetric="channelsStats.new_views.percent != 0"
          />

          <div class="insights__value">{{channelsStats.new_views.value}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-eye"/>
          </div>

          <p class="insights__name">Avg. watch duration</p>

          <ioio-tooltip text="Average watch duration per session">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <ioio-label
            class="ml-3"
            :text="channelsStats.avg_watch_time.percent+'%'"
            :type="channelsStats.avg_watch_time.type"
            size="large"
            :isMetric="channelsStats.avg_watch_time.percent != 0"
          />

          <div class="insights__value">{{channelsStats.avg_watch_time.value}}</div>
        </div>
        <!-- End of row with stats -->
      </div>
      <div class="insights__chart" v-if="!isRequestPending && channelsStats">
        <h3 class="insights__chart-title">Channels views</h3>
        <apexchart width="100%" height="250" type='area' :options="channelsViewsOptions" :series="channelsViewsSeries" ></apexchart>
      </div>
      <div class="nodata" v-else-if="!isRequestPending">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div class="nodata-text">There is no activity</div>
        <ioio-button
          v-if="activeQuickActionItems.quickChannel"
          class="mt-4"
          size="small"
          type="primary"
          variant="outline"
          @click="navigate(activeQuickActionItems.quickChannel)"
        >Create channel
        </ioio-button>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab>

    <!-- !!!!!!!!!!!!!!! UNCOMMENT HUBS WHEN GORO NIKOLOV IS READY !!!!!!!!!!!!!!!!!!!!! -->

    <!-- <ioio-tab name="Hubs">
      <div class="rtable" v-if="!isRequestPending && hubs.length > 0">
        <table>
          <thead>
            <tr>
              <th class="w-2/5">Name</th>
              <th class="w-1/5">Members</th>
              <th class="w-1/5">Total video plays</th>
              <th class="w-1/5">Visits</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hub,i) in hubs" :key="i">
              <td class="rtable__el">
                <span class="rtable__el-thumb" :style="{backgroundColor:hub.color}">{{hub.initials}}</span>
                <span class="rtable__el-name">{{hub.name}}</span>
              </td>
              <td>{{hub.members}}</td>
              <td>{{hub.total_vod_plays}}</td>
              <td>{{hub.avg_montly_visits}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="nodata" v-else-if="!isRequestPending && hubs.length === 0">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div class="nodata-text">There is no activity</div>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab> -->
    <ioio-tab name="Popular">
      <div class="rtable" v-if="!isRequestPending && !isRequestElasticPending && vodList.length > 0">
        <table>
          <thead>
            <tr>
              <th class="w-2/5">Name</th>
              <th>
              <div class="flex">
                Total views
                <ioio-tooltip
                  text="Total video plays"
                  class="ml-3"
                  initialPostion="bottom-center">

                  <ioio-icon
                    class="insights__infobox"
                    icon="far-circle-info"/>
                </ioio-tooltip>
              </div>
              </th>
              <th>
              <div class="flex">
                Unique viewers
                <ioio-tooltip
                  text="Unique video viewers"
                  class="ml-3"
                  initialPostion="bottom-center">

                  <ioio-icon
                    class="insights__infobox"
                    icon="far-circle-info"/>
                </ioio-tooltip>
              </div>
              </th>
              <th>
                <div class="flex">
                Avg. completion rate
                <ioio-tooltip
                  text="Average completion rate of the video"
                  class="ml-3"
                  initialPostion="bottom-left">

                  <ioio-icon
                    class="insights__infobox"
                    icon="far-circle-info"/>
                </ioio-tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vod in vodList" :key="vod.guid" @click.stop="openVodDetailsPage(vod.guid)">
              <td class="rtable__video">
                <div class="rtable__vthumb">
                  <img v-if="vod.source && vod.source.meta && vod.source.meta.imageUrl" :src="vod.source.meta.imageUrl"/>
                  <img else src="https://qa2.stg.ioio.tv/image/049c2a3e-2d50-432a-b9af-d4a467fb34d0.png"/>
                  <div class="rtable__vduration">
                    {{ parseDuration(vod.source && vod.source.srcDuration || 0) }}
                  </div>
                </div>
                <h4 v-if="vod.name.length < 26" class="rtable__vname">{{vod.name}}</h4>
                <ioio-tooltip v-else :text="vod.name" initialPostion="top-right" class="tooltip-z">
                  <h4 class="rtable__vname">{{vod.name}}</h4>
                </ioio-tooltip>
              </td>
              <td>{{vod.total_views}}</td>
              <td>{{vod.unique_views}}</td>
              <td>{{vod.avg_comp_rate}}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="nodata" v-else-if="!isRequestPending && !isRequestElasticPending && vodList.length === 0">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div class="nodata-text">There is no activity</div>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab>
    <ioio-tab
      name="Assets"
      class="insights__cntnt"
    >
      <div class="insights__rows-data"  v-if="!isRequestPending && assetsStats">

        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-circle-play"/>
          </div>

          <p class="insights__name">Total videos</p>

          <ioio-tooltip text="Total videos in the Library">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <div class="insights__value">{{assetsStats.totalVideos}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-sliders-simple"/>
          </div>

          <p class="insights__name">Uploaded and encoded</p>

          <ioio-tooltip text="Total encoded videos in the Library, percentage of all">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <div class="insights__value">{{assetsStats.encodedVideos}}%</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-circle-video"/>
          </div>

          <p class="insights__name">Playlists </p>

          <ioio-tooltip text="Total number of playlists created">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <div class="insights__value">{{assetsStats.playlists}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-tv"/>
          </div>

          <p class="insights__name">Active channels</p>

          <ioio-tooltip text="Number of active channels">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <div class="insights__value">{{assetsStats.activeChannels}}</div>
        </div>
        <!-- End of row with stats -->
        <!-- Row with stats -->
        <div class="insights__row">
          <div class="insights__icon">
            <ioio-icon icon="far-calendar"/>
          </div>

          <p class="insights__name">Total events</p>

          <ioio-tooltip text="Total number of events created, past and upcoming">
            <ioio-icon
              class="insights__infobox"
              icon="far-circle-info"/>
          </ioio-tooltip>

          <div v-if="eventEnabled" class="insights__value">{{assetsStats.totalEvents}}</div>
          <div v-else class="insights__value">The service is not enabled</div>
        </div>
        <!-- End of row with stats -->
      </div>
      <div class="insights__chart" v-if="!isRequestPending && assetsStats.totalVideos > 0">
        <h3 class="insights__chart-title">State of assets</h3>
        <apexchart width="100%"  type='pie' :options="assetsOptions" :series="assetsSeries" ></apexchart>
      </div>
      <div class="nodata" v-else-if="!isRequestPending">
        <div class="nodata-thumb">
          <ioio-icon icon="far-list"/>
        </div>
        <div class="nodata-text">There is no activity</div>
      </div>
      <div class="nodata-loader" v-else>
        <ioio-loader isShown />
      </div>
    </ioio-tab>
  </ioio-tabs>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  videoConsumptionOptions,
  eventRegistrationsOptions,
  channelsViewsOptions,
  assetsOptions
  } from './charts.config.js'

import  backgroundColors from "@configs/colorsThumbMyHubsDashboard";

import { getHHMMSSFromMS } from "@utils/helpers";

export default {
  data: () => ({

    randomBackgroundColors: backgroundColors,

    hubs: [],

    vodList:[],

    videoConsumptionOptions: videoConsumptionOptions,
    eventRegistrationsOptions: eventRegistrationsOptions,
    channelsViewsOptions: channelsViewsOptions,
    assetsOptions: assetsOptions,

    videoStats: false,
    videoConsumptionSeries: [],

    eventStats: false,
    eventRegistrationsSeries: [],

    channelsStats: false,
    channelsViewsSeries: [],

    assetsStats: false,
    assetsSeries: [55, 5, 15, 17],

    isRequestPending: false,
    isRequestElasticPending: false,

    promisesOrder: {}
  }),
  props: {},

  created () {

  },

  mounted () {

    this.setVideoConsumption();
  },
  methods: {

    navigate(navItem) {

      const { route, queries } = navItem;

      if (!route) {

        return;
      }

      const routeParams = {

        path: route
      };

      queries && (routeParams.query = queries);

        // Navigate to the requested state
      return this.$pushRoute(routeParams);

    },

    openVodDetailsPage(guid) {

      let params = {};

      params.route = "/vod/library";

      params.queries = {

        id: guid
      };

      this.navigate(params);
    },

    setVideoConsumption() {

      this.isRequestPending = true;

      const params = {

        org_id: this.organizationSelected.guid,
        type: 'library',
      };

      this.getDashboardInsightsStats(params)
        .then(stats => {

          // TO REMOVE WHEN IS DONE IN BE !!!!!!
          stats.v_consumption.DTO.yaxis.tickAmount = 8;
          // END
          this.videoConsumptionSeries = [...stats.v_consumption.DTO.series];

          this.videoConsumptionOptions = {
            ...this.videoConsumptionOptions,
            xaxis: {
              ...this.videoConsumptionOptions.xaxis,
              categories: [
                ...stats.v_consumption.DTO.categories
              ]
            },
            yaxis: {
              ...this.videoConsumptionOptions.yaxis,
              ...stats.v_consumption.DTO.yaxis
            },
          };

          const readyStats = this.setStatTypes(stats);

          this.videoStats = {...readyStats};
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setEventRegistrations() {

      this.isRequestPending = true;

      const params = {

        org_id: this.organizationSelected.guid,
        type: 'events',
      };

      this.getDashboardInsightsStats(params)
        .then(stats => {

          this.eventRegistrationsSeries = [...stats.v_consumption.DTO.series];

          this.eventRegistrationsOptions = {
            ...this.eventRegistrationsOptions,
            xaxis: {
              ...this.eventRegistrationsOptions.xaxis,
              categories: [
                ...stats.v_consumption.DTO.categories
              ]
            },
            yaxis: {
              ...this.eventRegistrationsOptions.yaxis,
              ...stats.v_consumption.DTO.yaxis
            },
          };

          const readyStats = this.setStatTypes(stats);

          this.eventStats = {...readyStats};
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setChannelsViews() {

      this.isRequestPending = true;

      const params = {

        org_id: this.organizationSelected.guid,
        type: 'channels',
      };

      this.getDashboardInsightsStats(params)
        .then(stats => {

          this.channelsViewsSeries = [...stats.v_consumption.DTO.series];

          this.channelsViewsOptions = {
            ...this.channelsViewsOptions,
            xaxis: {
              ...this.channelsViewsOptions.xaxis,
              categories: [
                ...stats.v_consumption.DTO.categories
              ]
            },
            yaxis: {
              ...this.channelsViewsOptions.yaxis,
              ...stats.v_consumption.DTO.yaxis
            },
          };

          const readyStats = this.setStatTypes(stats);

          this.channelsStats = {...readyStats};

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setStatTypes(stats) {

      for (let stat in stats) {

        if (stats[stat].percent && stats[stat].percent !== "0") {

          stats[stat].up? stats[stat].type = 'success': stats[stat].type = 'danger';

        } else if (stats[stat].percent == 0) {

          stats[stat].type = 'minimal';
        };
      };

      return stats;
    },

    onChangeAssetTab(newValue) {

      switch (newValue) {

        case "Library":

          this.setVideoConsumption();
          break;

        case "Events":

          this.eventEnabled ? this.setEventRegistrations() : null;
          break;

        case "Channels":

          this.setChannelsViews();
          break;

        case "Hubs":

          this.setHubs();
          break;

        case "Popular":

          this.setPopular();
          break;

        case "Assets":

          this.setAssets();
          break;
      }
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms);
    },

    setAssets() {

      this.isRequestPending = true;

      const searchVideos = {

        type: {

          "resource": "source"
        },

        options: {

          "aggs": {

            "videos": {

              "terms": {

                "field": "status.keyword",
                "size": 500
              }
            }
          },

          "size": 0
        }
      };

      const searchPlaylists = {

        type: {

          "resource": "playlist"
        },

        options: {

          "size": 0
        }
      };

      const searchChannels = {

        type: {

          "resource": "channel"
        },

        options: {

          "aggs": {

            "channels": {

              "terms": {

                "field": "active",
                "size": 500
              }
            }
          },

          "size": 0
        }
      };

      const searchEvents = {

        "size": 0
      };

      let promises = [];

      if (this.$auth('listings_list')) {
        promises.push(this.getVodElastic(searchVideos));
        this.promisesOrder.searchVideos = (promises.length-1);
      }

      if (this.$auth('playlists_list')) {
        promises.push(this.getVodElastic(searchPlaylists));
        this.promisesOrder.searchPlaylists = (promises.length-1);
      }

      if (this.$auth('channels_list')) {
        promises.push(this.getVodElastic(searchChannels));
        this.promisesOrder.searchChannels = (promises.length-1);
      }

      if (this.$auth('events_list')) {
        promises.push(this.getEventsElastic(searchEvents));
        this.promisesOrder.searchEvents = (promises.length-1);
      }


      if (promises.length > 0) {

        Promise.all(promises)
        .then((responces) =>{

          let stats = {

            totalVideos: 0,

            labels: {}
          };

          if (this.$auth('listings_list')) {

            if (responces[this.promisesOrder.searchVideos].hasOwnProperty('aggregations')) {

              responces[this.promisesOrder.searchVideos].aggregations.videos.buckets.forEach(buck =>{

              stats.labels[buck.key] = buck.doc_count;

              stats.totalVideos = stats.totalVideos + buck.doc_count;
            });

            stats.encodedVideos = Math.round((stats.labels.complete / stats.totalVideos) * 100);

            } else if (responces[this.promisesOrder.searchVideos].hasOwnProperty('error')

              && responces[this.promisesOrder.searchVideos].error.reason.includes('no such index')) {

                stats.encodedVideos = 0;
            }

          }


          if (this.$auth('playlists_list')) {

            if (responces[this.promisesOrder.searchPlaylists].hasOwnProperty('hits')) {

              stats.playlists = responces[this.promisesOrder.searchPlaylists].hits.total.value;

            } else if (responces[this.promisesOrder.searchPlaylists].hasOwnProperty('error')

              && responces[this.promisesOrder.searchPlaylists].error.reason.includes('no such index')) {

                stats.playlists = 0;
            }
          }


          if (this.$auth('channels_list')) {

            if (responces[this.promisesOrder.searchChannels].hasOwnProperty('aggregations')) {

              responces[this.promisesOrder.searchChannels].aggregations.channels.buckets.length > 0
                ? stats.activeChannels = responces[this.promisesOrder.searchChannels].aggregations.channels.buckets.find(buck => buck.key === 1).doc_count
                : stats.activeChannels = 0;

            } else if (responces[this.promisesOrder.searchChannels].hasOwnProperty('error')

              && responces[this.promisesOrder.searchChannels].error.reason.includes('no such index')) {

                stats.activeChannels = 0;
            } else if (responces[this.promisesOrder.searchChannels].hasOwnProperty('hits')

              &&  responces[this.promisesOrder.searchChannels].hits.total.value === 0) {

                stats.activeChannels = 0;
            }
          }

          if (this.$auth('events_list')) {

             if (responces[this.promisesOrder.searchEvents].hasOwnProperty('hits')) {

              stats.totalEvents = responces[this.promisesOrder.searchEvents].hits.total.value;

            } else if (responces[this.promisesOrder.searchEvents].hasOwnProperty('error')

              && responces[this.promisesOrder.searchEvents].error.reason.includes('no such index')) {

                stats.totalEvents = 0;
            }

          }

          this.assetsStats = {...stats};

          this.assetsOptions.labels = [...Object.keys(stats.labels)];

          this.assetsSeries = [...Object.values(stats.labels)];
        })

        .finally(() => {

          this.isRequestPending = false;
        });
      } else {

        this.isRequestPending = false;
      }
    },

    setPopular() {

      this.isRequestPending = true;

      this.vodList = [];

      const params = {

        org_id: this.organizationSelected.guid,
        type: 'popular',
      };

      this.getDashboardInsightsStats(params)
        .then(stats => {

          if (stats.items.length > 0) {

            this.isRequestElasticPending = true;

            let list = [...stats.items];

            let vodIds = [...stats.guids];

            list.forEach((item,i) =>{

              item.guid = vodIds[i];

            })

            const from = 0;
            const size = 5;

            const search = {

              type: {

                "resource": "source"
              },

              options: {

                from,
                size,
                query:  {

                  "terms": {
                    "guid.keyword": vodIds
                  }

                }
              }
            };

            this.getVodElastic(search)
              .then((response) => {

                let vodList = response.hits.hits;

                list.forEach(vod =>{

                  let index = vodList.findIndex(el => el._id === vod.guid);

                  if (index !== -1) {

                    vod.source = vodList[index]._source;

                    this.vodList.push(vod);
                  };
                });
              })
              .finally(() => {

                this.isRequestElasticPending = false;
              });

          }

        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setHubs() {

      this.isRequestPending = true;

      let hubs = [];

      const params = {

        org_id: this.organizationSelected.guid,
        type: 'hubs',
      };

      this.getDashboardInsightsStats(params)
        .then(stats => {

          hubs = [...stats.items];

          hubs.forEach((hub,i) =>{

            let name = hub.name.split(' ');

            hub.initials = name[0].charAt(0);

            name[1] ? hub.initials = hub.initials + name[1].charAt(0) : null;

            hub.initials = hub.initials.toUpperCase();

            let colorsLength = this.randomBackgroundColors.backgroundColors.length;

            hub.color = this.randomBackgroundColors.backgroundColors[i-colorsLength*Math.floor(i/colorsLength)];

          });

          this.hubs = hubs;

        })
        .finally(() => {

          this.isRequestPending = false;
        });

    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getDashboardInsightsStats: "statistics/getDashboardInsightsStats",
      getVodElastic: "channelManager/getVodElastic",
      getEventsElastic: "events/getEventsElastic"
    })
  },
  computed: {
    ...mapGetters({
      organizationSelected: 'app/organizationSelected',
      quickActionItems: 'app/quickActionItems',
      availableDeployments: "app/availableDeployments"
    }),
    activeQuickActionItems() {

      let items = this.quickActionItems.filter(item => {

        return (this.$auth(item.permission) && !item.hidden);
      });

      let activeItems = {};

      items.forEach((item,i) => {
        activeItems[item.name] = item;
      });

      return activeItems;
    },
    eventEnabled() {

      return this.availableDeployments.some(deployment => deployment.type === 'events');
    }
  },
}
</script>

<style lang="scss">

// ***********************************************
// Insights
// ***********************************************
.insights {
  &__cntnt {
    @apply
    py-5
    px-6
    grid
    grid-cols-1
    xl:grid-cols-2;
  }

  &__rows-data {
    @apply
    xl:border-r
    xl:border-black-50
    xl:pr-5;

    .ds-label.ds-label--metric .ds-label__icon {
      @apply bg-transparent p-0;
    }
  }

  &__interval {
    @apply
    py-1
    px-2
    bg-black-50
    text-black-500
    text-xs
    rounded-sm;
  }

  &__row {
    @apply mt-5 flex items-center;
  }

  &__icon {
    @apply
    w-8
    h-8
    mr-3
    flex
    flex-shrink-0
    justify-center
    items-center
    rounded-full
    bg-blue-100
    text-blue-600;
  }

  &__name {
    @apply mr-2 whitespace-nowrap text-black-600;
  }

  &__infobox {
    @apply text-black-300;
  }

  &__value {
    @apply ml-auto;
  }

  &__chart {
    @apply
    pl-0
    mt-7
    xl:mt-0
    xl:pl-5;
  }

  &__chart-title {
    @apply text-lg;
  }

  th, label {
    @apply
    font-medium;
  }
}

// ***********************************************
// Box
// ***********************************************
.box {
  @apply py-6;

  &__main-title {
    @apply text-lg pb-3 px-6;
  }
}
.ds-tabs {
  &__nav {
    @apply pl-6 border-b border-black-50;
  }
}

// ***********************************************
// Responsive table
// ***********************************************
.rtable {
  @apply w-full overflow-x-auto px-6;

  &::-webkit-scrollbar {
    @apply w-2 h-2;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded bg-black-500;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    @apply bg-black-300;
  }

  table {
    @apply table w-full;
  }

  tr {
    @apply border-b border-black-100;
  }

  th,
  td {
    @apply px-3 min-w-40 text-left;
  }

  th {
    @apply py-3 text-sm text-black-500;
  }

  td {
    @apply py-5 align-middle;
  }

  &__el {
    @apply
    flex
    items-center;

    &-thumb {
      @apply
      flex
      flex-shrink
      items-center
      justify-center
      min-w-9
      min-h-9
      mr-4
      text-lg
      text-white
      rounded-lg;
    }

    &-name {
      @apply
      flex-1
      text-black-700
      truncate;
    }
  }

  &__video {
    @apply
    flex
    flex-row
    items-center
    justify-center
    cursor-pointer;
  }

  &__vthumb {
    @apply
    relative
    mr-4
    flex-shrink-0
    overflow-hidden
    rounded;
    width: 90px;
    height: 56px;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__vduration {
    @apply
    absolute
    left-0
    bottom-0
    py-1
    px-2
    bg-blue-grey-900
    bg-opacity-60
    text-xs
    text-white;
  }

  &__vname {
    @apply
    inline-block
    w-60
    text-sm
    pr-2
    z-0
    truncate;
  }
  .tooltip-z {
    z-index: 1;
  }
}

// ***********************************************
// No data
// ***********************************************
.nodata {
  @apply
  flex
  justify-center
  items-center
  flex-col
  min-h-44;

  &-thumb {
    @apply
    flex
    justify-center
    items-center
    w-14
    h-14
    mb-4
    rounded-full
    bg-black-50
    text-lg
    text-black-500;
  }

  &-text {
    @apply text-black-500 text-sm;
  }

  &-loader {
    @apply
    relative
    h-10
    mt-5;
  }
}
</style>
