/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'segmentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54 14h6M4 14h40M10 32H4M60 32H20M54 50h6M4 50h40" _stroke="#5c7499" stroke-width="5" stroke-miterlimit="10" stroke-linecap="square"/>'
  }
})
