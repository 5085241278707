import moment from "moment-timezone";

import * as html2canvas from "html2canvas";

import utils from '@ioiotv/ioio-kit-core/src/utils';
import { urlSearchQuery } from "@/store/modules/filterAndPaginate/getters.js";

// The jsPDF library is NOT SSR frindly, will be required as a
// singleton if the method is used on the FE
let jsPDFInstance = null;

export const calcGaps = (props) => {

    const gaps = [];

    const { startStamp, endStamp, gapInterval } = props;

    /**
     * Create the first gap manualy, insuring that it fills in just to the
     * spot of the next predefined service element or real listing place.
     * It's length will be smaller than or, if the last element ends exactly
     * to a predefined spot, equal to the one of an usual service element.
     */
    const fromStartStampToSegmentEnd = Math.abs(gapInterval - (startStamp % gapInterval));

    /**
     * Will be added to the gaps collection after the in-between gaps are in
     */
    const firstGap = {
        isServiceElement: true,
        start: startStamp,
        end: startStamp + fromStartStampToSegmentEnd // determine the closest possible predefined step
    };

    /**
     * Create the last gap manualy, insuring that it fills in just from the
     * end of the prev predefined service element or real listing place to the specified period.
     * It's length will be smaller than or, if the prev element ends exactly
     * to a predefined spot, equal to the one of an usual service element.
     */
    const fromEndStampToPrevSegmentEnd = endStamp % gapInterval;

    /**
     * Will be added to the gaps collection after the in-between gaps are in
     */
    const lastGap = {
        isServiceElement: true,
        start: endStamp - fromEndStampToPrevSegmentEnd,
        end: endStamp // determine the closest possible predefined step
    };

    /**
     * In a situation, where two listings are near each other,
     * (distance is less that the gapInterval) but neigher of them
     * starts or ends at an exact time, create a customGap
     */
    let customGap = null;

    if (endStamp - startStamp < gapInterval) {

        customGap = {
            isServiceElement: true,
            start: startStamp,
            end: endStamp
        };
    }

    /**
     * Calc the durationToFill, adjusted to the gaps, that are filled-in
     * manually. This way only equal sized gaps will be added next.
     */
    const durationToFill = lastGap.start - firstGap.end;

    const gapsCount = durationToFill / gapInterval;

    for (var i = 0; i < gapsCount; i++) {

        gaps.push({
            isServiceElement: true,
            start: firstGap.end + i * gapInterval,
            end: firstGap.end + i * gapInterval + gapInterval
        })
    }

    /**
     * Add the firstGap, lastGap or customGap manually if needed
     */
    if (customGap) {

        gaps.push(customGap);

    } else {


        if (firstGap.end > firstGap.start) {

            gaps.unshift(firstGap);
        }

        if (lastGap.end > lastGap.start) {

            gaps.push(lastGap);
        }
    }

    return gaps;
}

export const paletteColors = () => [
    "#1FBC9C",
    "#1CA085",
    "#2ECC70",
    "#27AF60",
    "#3398DB",
    "#2980B9",
    "#A463BF",
    "#8E43AD",
    "#3D556E",
    "#222F3D",
    "#F2C511",
    "#F39C19",
    "#E84B3C",
    "#C0382B",
];

export const getRandomColor = (props) => {
    const colors = paletteColors();
    return colors[Math.floor(Math.random() * colors.length)];
}

export const getHHMMSSFromMS = (ms, returnFullHour, omitSeconds) => {

  const momentDuration = moment.duration(ms);

  const secondsStr = `${momentDuration.seconds()}`;
  const minutesStr = `${momentDuration.minutes()}`;
  const hoursStr = `${Math.trunc(momentDuration.asHours())}`;

  const seconds = secondsStr.length < 2 ? `0${secondsStr}` : secondsStr;
  const minutes = minutesStr.length < 2 ? `0${minutesStr}` : minutesStr;
  const hours = hoursStr !== '0' ? hoursStr.length < 2 ? `0${hoursStr}` : hoursStr : '';

  if (returnFullHour) {

    return `${hours ? hours + ':' : '00:'}${minutes}${ omitSeconds ? '' : ':' + seconds }`;
  }

  return `${hours ? hours + ':' : ''}${minutes}${ omitSeconds ? '' : ':' + seconds }`;
}

export const getHHMMSSFromTimestamp = (stamp, omitSeconds, use12Hour) => {

  const date = new Date(stamp);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  const timeOfDaySignature = hours >= 12 ? 'PM' : 'AM';

  if (use12Hour) {

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  }

  minutes = minutes < 10 ? '0'+minutes : minutes;
  seconds = seconds < 10 ? '0'+seconds : seconds;

  return `${ hours }:${ minutes }${ omitSeconds ? '' : ':' + seconds + '' } ${ use12Hour ? timeOfDaySignature : ''} `;
}

export const deepClone = (object) => {

    const deepCopy = JSON.parse(JSON.stringify(object));

    return deepCopy;
}

export const getNestedObject = (nestedObj, pathArr) => {

    return pathArr.reduce((obj, key) => {

        let returnObj = undefined;

        if (key.isPartOfArray) {

            returnObj = obj[key.code][key.index];

        } else if (obj && obj[key] && obj[key] !== 'undefined') {

            returnObj = obj[key];
        }

        return returnObj;

    }, nestedObj)
}

export const liveWorkflowsStatusMap = {
  DEFAULT: {
    label: "",
    className: "",
    tag: "default",
    labelType: "default",
    classificationStatus: 'ready',
    icon: ''
  },

  PAUSING: {
    label: "Pausing",
    className: "pausing",
    tag: "warning",
    labelType: "normal",
    classificationStatus: 'pausing',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  PAUSED: {
    label: "Paused",
    className: "paused",
    tag: "warning",
    labelType: "normal",
    classificationStatus: 'paused',
    icon: 'fas-circle-exclamation'
  },

  UNPAUSING: {
    label: "Unpausing",
    className: "unpausing",
    tag: "warning",
    labelType: "normal",
    classificationStatus: 'unpausing',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  READY: {
    label: "Ready",
    className: "not-created",
    tag: "default",
    labelType: "success",
    classificationStatus: 'ready',
    icon: ''
  },

  RUNNING: {
    label: "Running",
    className: "running",
    tag: "action",
    labelType: "normal",
    classificationStatus: 'running',
    icon: 'fas-circle-play'
  },

  DONE: {
    label: "Done",
    className: "done",
    tag: "default",
    labelType: "default",
    classificationStatus: 'done',
    icon: 'fas-circle-check'
  },

  PROVISIONING: {
    label: "Provisioning",
    className: "creating",
    tag: "warning",
    labelType: "normal",
    classificationStatus: 'running',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  STARTING: {
    label: "Starting Encoder",
    className: "starting",
    tag: "warning",
    labelType: "normal",
    classificationStatus: 'running',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  STOPPING: {
    label: "Stopping",
    className: "stopping",
    tag: "process",
    labelType: "accent",
    classificationStatus: 'running',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  DELETING: {
    label: "Destroying",
    className: "deleting",
    tag: "process",
    labelType: "accent",
    classificationStatus: 'running',
    icon: 'fa-solid fa-spinner fa-spin-pulse'
  },

  START_FAILED: {
    label: "Start Failed",
    className: "start-failed",
    tag: "error",
    labelType: "failed",
    classificationStatus: 'failed',
    icon: 'fas-circle-exclamation'
  },

  STOP_FAILED: {
    label: "Stop Failed",
    className: "stop-failed",
    tag: "error",
    labelType: "failed",
    classificationStatus: 'failed',
    icon: 'fas-circle-exclamation'
  },

  PAUSE_FAILED: {
    label: "Pause Failed",
    className: "pause-failed",
    tag: "error",
    labelType: "failed",
    classificationStatus: 'failed',
    icon: 'fas-circle-exclamation'
  },

  UNPAUSE_FAILED: {
    label: "Unpause Failed",
    className: "unpause-failed",
    tag: "error",
    labelType: "failed",
    classificationStatus: 'failed',
    icon: 'fas-circle-exclamation'
  },
};

export const getReadableChannelStatus = (originalStatus) => {

  return liveWorkflowsStatusMap[originalStatus] || liveWorkflowsStatusMap.DEFAULT;
}

export const getLocalTimezone = () => {

  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getPossibleTimezones = () => {

  return ['UTC'];
}

// conversion required since the Design system's dropdown needs key-value pairs
const zonesToArrayOfObjects = [];

const _convertMomentZonesToArrayOfObjects = () => {

  moment.tz.names().forEach(n => {

    zonesToArrayOfObjects.push({ label: `(${moment().tz(n).format('Z z')}) ${n}`, value: n });
  });
}

export const getEventsPossibleTimezones = () => {

  if (!zonesToArrayOfObjects.length) {

    _convertMomentZonesToArrayOfObjects();
  }

  return zonesToArrayOfObjects;
}

export const copyElementInnerText = (domElementID) => {

  /* Get the text field */
  const copyText = document.getElementById(domElementID);

  /* Select the text field */
  copyText.select();

  /* Copy the text inside the text field */
  document.execCommand("copy");
}

export const getMouseClickCoords = (e) => {

  const rect = e.currentTarget.getBoundingClientRect();
  let x = Math.floor(e.clientX - rect.left);
  let y = Math.floor(e.clientY - rect.top);

  return {
    x,
    y
  };
}

export const flattenObject = (value, currentKey) => {

  let result = {};

  Object.keys(value).forEach(key => {

    const tempKey = currentKey ? `${currentKey}.${key}` : key;

    if (typeof value[key] !== 'object') {

      result[tempKey] = value[key];

    } else {

      result = {
        ...result,
        ...flattenObject(value[key], tempKey)
      };
    }
  });

  return result;
}

export const downloadCSV = (csvStr, docName) => {

  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
  hiddenElement.target = '_blank';
  hiddenElement.download = docName
  hiddenElement.click();
}

export const downloadSubs = (files) => {

  const hiddenElement = document.createElement('a');

  files.forEach(file => {

    hiddenElement.href = file.url;
    hiddenElement.target = '_blank';
    hiddenElement.download = file.name;

    hiddenElement.click();
  });
}

export const calcHumanReadableTimeDifference = (previousStamp, currentStamp = new Date().getTime()) => {

  const msPerMinute = 60000;       // 60 * 1000;
  const msPerHour = 3600000;       // msPerMinute * 60;
  const msPerDay = 86400000;       // msPerHour * 24;
  const msPerMonth = 2628000000;   // msPerDay * 30;
  const msPerYear = 31536000000;   // msPerDay * 365;

  const elapsed = currentStamp - previousStamp;

  if (elapsed < msPerMinute) {

    return Math.round(elapsed / 1000) + ` second${elapsed < 1000 ? '' : 's'} ago`;

  } else if (elapsed < msPerHour) {

    return Math.round(elapsed / msPerMinute) + ` minute${elapsed < msPerMinute ? '' : 's'} ago`;

  } else if (elapsed < msPerDay ) {

    return Math.round(elapsed / msPerHour) + ` hour${elapsed < msPerHour ? '' : 's'} ago`;

  } else if (elapsed < msPerMonth) {

    return 'approximately ' + Math.round(elapsed / msPerDay) + ` day${elapsed < msPerDay ? '' : 's'} ago`;

  } else if (elapsed < msPerYear) {

    return 'approximately ' + Math.round(elapsed / msPerMonth) + ` month${elapsed < msPerMonth ? '' : 's'} ago`;

  } else {

    return 'approximately ' + Math.round(elapsed / msPerYear) + ` year${elapsed < msPerYear ? '' : 's'} ago`;
  }
}

export const getNameInitials = (originalName, defaultInitials = 'IO') => {

  const name = originalName || '';
  const nameAsArr = name.split(' ');
  const initials = nameAsArr.length > 1 ?
    `${nameAsArr[0].charAt(0)}${nameAsArr[1].charAt(0)}` :
    nameAsArr[0].length ?
    `${nameAsArr[0].substr(0, 2)}` : defaultInitials;

  return initials;
}

export const downloadPDF = (element, fileName) => {

  // The following code is not SSR compatible
  if (typeof window === "undefined") {

    return;
  }

  if (!jsPDFInstance) {

    /**
     * The jsPDF library is NOT SSR-friendly, thus it can only be
     * required on the client and can not be imported!
     */
    jsPDFInstance =
      require("../../node_modules/jspdf/dist/jspdf.es.min.js").jsPDF;
  }

  html2canvas(element, { allowTaint: true }, fileName)

    .then((canvas) => {

      const HTML_Width = element.offsetWidth;
      const HTML_Height = element.offsetHeight;
      const top_left_margin = 10;
      const PDF_Width = HTML_Width + (top_left_margin * 2);
      const PDF_Height = HTML_Height + (top_left_margin * 2);

      canvas.getContext('2d');

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const pdf = new jsPDFInstance('p', 'pt',  [PDF_Width, PDF_Height]);

      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);

      pdf.save(`${fileName}.pdf`);
    });
}

export const downloadVodFromUrl = (videoUrl) => {

  return new Promise((resolve, reject) => {

    const videoName = videoUrl.split('/').pop();

    const xhr = new XMLHttpRequest();

    xhr.open('GET', videoUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = function() {

      if (!(xhr.status >= 200 && xhr.status < 300)) {

        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });

        return;
      }

      const urlCreator = window.URL || window.webkitURL;
      const videoUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');

      tag.href = videoUrl;
      tag.target = '_blank';
      tag.download = videoName;

      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);

      resolve();
    };

    xhr.onerror = function() {

      console.error(`Error downloading this vod ${err}`);
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send();
  });
}

export const parseVodType = (type) => {

  switch (type) {
    case 'source':

      return 'Video';

    case 'ad':

      return 'Ad';

    case 'Mixed':

      return 'Mixed';

    default:
      return '--';
  }
}

export const getVodPrivacy = (value) => {

  if (value === true) {

    return 'Private'

  } else if (value === 'Mixed') {

    return 'Mixed';

  } else {

    return 'Public';
  }
}

export const getVodFeatured = (value) => {

  if (value === true) {

    return 'Yes'

  } else if (value === 'Mixed') {

    return 'Mixed';

  } else {

    return 'No';
  }
}

export const getVodListedInPortals = (value) => {

  if (value === true) {

    return 'Yes'

  } else if (value === 'Mixed') {

    return 'Mixed';

  } else {

    return 'No';
  }
}

// NOTE: accurately compare only arrays containing primitives;
// indeces should be the same for a match
export const comparePrimitiveArrays = (array1 = [], array2 = []) => {

  if (array1.length !== array2.length) {

    return false;
  }

  for (let i = 0; i < array1.length; i++) {

    if (array1[i] !== array2[i]) {

      return false;
    }
  }

  return true;
}

export const compareObjectArraysByProp = (array1 = [], array2 = [], propName) => {

  if (array1.length !== array2.length) {

    return false;
  }

  for (let i = 0; i < array1.length; i++) {

    if (array1[i][propName] !== array2[i][propName]) {

      return false;
    }
  }

  return true;
}

export const compareObjectArraysByPropAndCombineUniques = (array1 = [], array2 = [], propName)  => {

  const arrayPropsMap = {};

  const maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {

    if (array1[i] && !arrayPropsMap[array1[i][propName]]) {

      arrayPropsMap[array1[i][propName]] = array1[i];
    }

    if (array2[i] && !arrayPropsMap[array2[i][propName]]) {

      arrayPropsMap[array2[i][propName]] = array2[i];
    }
  }

  const resp = [];

  for (let prop in arrayPropsMap) {

    resp.push(arrayPropsMap[prop]);
  }

  return resp;
}

export const comparePrimitiveArraysAndCombineUniques = (array1 = [], array2 = [])  => {

  const arrayPropsMap = {};

  const maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {

    if (array1[i] && !arrayPropsMap[array1[i]]) {

      arrayPropsMap[array1[i]] = array1[i];
    }

    if (array2[i] && !arrayPropsMap[array2[i]]) {

      arrayPropsMap[array2[i]] = array2[i];
    }
  }

  const resp = [];

  for (let prop in arrayPropsMap) {

    resp.push(arrayPropsMap[prop]);
  }

  return resp;
}

export const formatBytes = (bytes, decimals = 2) => {

  if (!+bytes) {

    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const _findInputErrorInComponentTree = (xs) => {

  return xs.flatMap((child) => {
    const resp = [];

    const { $children } = child;

    // prevent further checks since the input is found
    if (child.topic) {

      if (child.errors && child.errors.length) {

        resp.push(child.uuid);
      }

    } else {

      resp.push(..._findInputErrorInComponentTree($children));
    }

    return resp;
  });
}

export const validateIoFormAndGetErrors = (formTopic) => {

  const validatedErrors = utils.submit.validate(formTopic);

  return validatedErrors;
}

export const validateTabsFormAndHighlight = (tabsRef, formTopic) => {

  if (validateIoFormAndGetErrors(formTopic).length) {

    const tabs = tabsRef.$children;

    for (let i = 0; i < tabs.length; i++) {

      const currentTab = tabs[i];
      const currentTabName = currentTab.name;

      const isErrorFound = _findInputErrorInComponentTree(currentTab.$children).length;

      if (isErrorFound) {

        tabsRef.selectTab({ name: currentTabName });

        return true;
      }
    }
  }

  return false;
}

// Use to convert constants that are objects into arrays of object to use in ioio-dropdowns
export const convertObjectKeyValuesToArr = (startObj) => {

  const newArr = [];

  for (let key in startObj) {

    newArr.push({

      label: startObj[key],
      value: key
    });
  }

  return newArr;
}

export const blueprintsMainFiltersConfig = [
  // {
  //   label: 'Type',
  //   applyMethod: 'type',
  //   filterOptionsConfigName: 'typeFiltersConfig'
  // },
  {
    label: 'Input',
    applyMethod: 'input',
    filterOptionsConfigName: 'inputFiltersConfig'
  },
  {
    label: 'Output CDN',
    applyMethod: 'outputCdn',
    filterOptionsConfigName: 'outputCdnFiltersConfig'
  },
  {
    label: 'Closed Captions',
    applyMethod: 'closedCaptions',
    filterOptionsConfigName: 'closedCaptionsFiltersConfig'
  },
  {
    label: 'Real Time Captions',
    applyMethod: 'realTimeCaptions',
    filterOptionsConfigName: 'realTimeCaptionsFiltersConfig'
  },
  {
    label: 'Channel Class',
    applyMethod: 'channelClass',
    filterOptionsConfigName: 'channelClassFiltersConfig'
  },
]

export const singleFilterConfig = {
  typeFiltersConfig: [
    {
      value: 'LIVE',
      label: 'Media Services'
    },
    {
      value: 'ELIVE',
      label: 'Elemental Live'
    },
    {
      value: '',
      label: 'All types (No filter)'
    }
  ],
  inputFiltersConfig: [
    {
      value: 'ZIXI_PUSH',
      label: 'ZIXI_PUSH'
    },
    {
      value: 'RIST',
      label: 'RIST'
    },
    {
      value: 'RTP_FEC',
      label: 'RTP_FEC'
    },
    {
      value: 'RTP',
      label: 'RTP'
    },
    {
      value: 'RTMP_PUSH',
      label: 'RTMP_PUSH'
    },
    {
      value: 'RTMP_PULL',
      label: 'RTMP_PULL'
    },
    {
      value: 'URL_PULL',
      label: 'URL_PULL'
    },
    {
      value: 'MP4',
      label: 'MP4'
    },
    {
      value: 'SRT_LISTENER',
      label: 'SRT_LISTENER'
    },
    {
      value: 'TS',
      label: 'TS'
    },
    {
      value: 'CONNECTED_DEVICE',
      label: 'CONNECTED_DEVICE'
    },
    {
      value: '',
      label: 'All inputs(No filter)'
    }
  ],
  outputCdnFiltersConfig: [
    {
      value: 'CloudFront',
      label: 'CloudFront'
    },
    {
      value: 'Akamai',
      label: 'Akamai'
    },
    {
      value: '',
      label: 'All output CDNs (No filter)'
    }
  ],
  closedCaptionsFiltersConfig: [
    {
      value: true,
      label: 'with Closed Captions'
    },
    {
      value: false,
      label: 'without Closed Captions'
    },
    {
      value: '',
      label: 'All types (No filter)'
    }
  ],
  realTimeCaptionsFiltersConfig: [
    {
      value: true,
      label: 'with Real Time Captions'
    },
    {
      value: false,
      label: 'without Real Time Captions'
    },
    {
      value: '',
      label: 'All types (No filter)'
    }
  ],
  channelClassFiltersConfig: [
    {
      value: 'SINGLE_PIPELINE',
      label: 'SINGLE_PIPELINE'
    },
    {
      value: 'STANDARD',
      label: 'STANDARD'
    },
    {
      value: '',
      label: 'All channel Classes (No filter)'
    }
  ],
}

export const defaultFilters = {
  type: {
    value: "",
    label: "Type",
  },
  input: {
    value: "",
    label: "Input",
  },
  outputCdn: {
    value: "",
    label: "Output CDN",
  },
  closedCaptions: {
    value: "",
    label: "Closed Captions",
  },
  realTimeCaptions: {
    value: "",
    label: "Real Time Captions",
  },
  channelClass: {
    value: "",
    label: "Channel Class",
  },
}