function reset ( task, dependencies, stateData, data ) {
    const 
          { eBus } = dependencies
        , { containerID } = stateData
        ;
    eBus.$emit ( 'reset' )
    task.done ({ 
                      success : true
                    , response : { containerID }
            })
} // reset func.
 
 
 
 export { reset }
 
 
 