function newSubtitles ( task, dependencies, stateData, data ) {
    const
              { vttFiles, videoURL, audioDuration } = stateData
            , fileNames = Object.keys(vttFiles)
            ;
    let
          counter = 0
        , fileTest = /^file.*/
        , name = 'file_'
        ;

    fileNames.forEach ( name => {
                                if ( fileTest.test(name) )   counter++
                            })
    name += counter+1
    let response = {
                      name
                    , cueList: []
                    , videoURL
                    , audioDuration
                };

    task.done ({
                   success: true
                 , response
            })
} // newSubtitles func.



 export { newSubtitles }


