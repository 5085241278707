<template>
<section class="library-tab-subtitles-component flex">

  <tags-and-cc-component :editedVod="editedVod" :editedVodGuid="editedVodGuid" :onReEncode="onReEncode" />

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

  }),
  props: {

    onReEncode: Function,
    fullPreviewVodData: Object
  },
  methods: {
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({

    }),
  },
  computed: {
    ...mapGetters({

      editedVod: 'channelManager/editedVod',
      editedVodGuid: 'channelManager/editedVodGuid',
    })
  },
}
</script>

<style lang="scss">
.library-tab-subtitles-component {
  .rtable {

    color: black;
  }
}

</style>
