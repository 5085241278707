let activeTrackerId = null;

const initPlayingScheduleTracker = ({ state, commit, dispatch }, listingsData) => {

  /**
   * Clear possible active tracker
   */
  clearTimeout(activeTrackerId);

  const { selectedChannelListingsCurrent, selectedChannelListingsAbsolute } = listingsData;
  const nowWithOffsetAdjusted = new Date().getTime() + state.streamToNowOffset;

  let currentlyPlayingListingIndex = null;
  let currentlyPlayingScheduleIndex = null;

  for (var i = 0; i < selectedChannelListingsCurrent.length; i++) {

    const currentListing = selectedChannelListingsCurrent[i];

    if (currentListing.end > nowWithOffsetAdjusted) {

      if (!currentListing.isServiceElement) {

        currentlyPlayingListingIndex = currentListing.absoluteIndex;
      }

      break;
    }
  }

  if (currentlyPlayingListingIndex === null) {

    updatePlayingListingAndScheduleStats(state, {
      currentlyPlayingListingIndex: null,
      currentlyPlayingScheduleIndex: null,
      selectedChannelListingsAbsolute
    });

    return;
  }

  const playingListing = selectedChannelListingsAbsolute[currentlyPlayingListingIndex];

  for (var i = 0; i < playingListing.schedules.length; i++) {

    const schedule = playingListing.schedules[i];

    const scheduleEnd = schedule.start + schedule.totalDuration;

    if (scheduleEnd > nowWithOffsetAdjusted) {

      currentlyPlayingScheduleIndex = i;

      break;
    }
  }

  updatePlayingListingAndScheduleStats(state, {
    currentlyPlayingListingIndex,
    currentlyPlayingScheduleIndex,
    selectedChannelListingsAbsolute
  });
};

const updatePlayingListingAndScheduleStats = (state, listingsData) => {

  const { currentlyPlayingListingIndex, currentlyPlayingScheduleIndex, selectedChannelListingsAbsolute } = listingsData;

  if (currentlyPlayingListingIndex === null) {

    state.currentlyPlayingListing = {};
    state.currentlyPlayingSchedule = {};
    state.currentlyPlayingListingIndex = null;
    state.currentlyPlayingScheduleIndex = null;

    state.nextPlayingListing = {};
    state.nextPlayingSchedule = {};

    return;
  }

  const nowWithOffsetAdjusted = new Date().getTime() + state.streamToNowOffset;

  state.currentlyPlayingListing = selectedChannelListingsAbsolute[currentlyPlayingListingIndex];
  state.currentlyPlayingListingIndex = currentlyPlayingListingIndex;

  state.nextPlayingListing = selectedChannelListingsAbsolute[currentlyPlayingListingIndex + 1];

  state.currentlyPlayingSchedule = state.currentlyPlayingListing.schedules[currentlyPlayingScheduleIndex];
  state.currentlyPlayingScheduleIndex = currentlyPlayingScheduleIndex;

  const nextScheduleInCurrentListing = state.currentlyPlayingListing.schedules[currentlyPlayingScheduleIndex + 1];

  const firstScheduleInNextListing = state.nextPlayingListing ?
    state.nextPlayingListing.schedules[0] : null;

  state.nextPlayingSchedule = nextScheduleInCurrentListing || firstScheduleInNextListing;

  if (!state.nextPlayingSchedule) {

    return;
  }

  let fromNowToNextScheduleStart = state.nextPlayingSchedule.start - nowWithOffsetAdjusted;


  activeTrackerId = setTimeout(() => {

    const nowWithOffsetAdjusted = new Date().getTime() + state.streamToNowOffset;

    let currentlyPlayingListingIndex = state.currentlyPlayingListingIndex;
    let currentlyPlayingScheduleIndex = state.currentlyPlayingScheduleIndex + 1;

    if (nowWithOffsetAdjusted > state.currentlyPlayingListing.end) {

      // NOTE: add logic here to set the selectedListing if needed
      currentlyPlayingListingIndex = state.currentlyPlayingListingIndex + 1;
      currentlyPlayingScheduleIndex = 0;
    }

    updatePlayingListingAndScheduleStats(state, {
      currentlyPlayingListingIndex: currentlyPlayingListingIndex,
      currentlyPlayingScheduleIndex: currentlyPlayingScheduleIndex,
      selectedChannelListingsAbsolute
    });

  }, fromNowToNextScheduleStart);
};


export {
  initPlayingScheduleTracker
}