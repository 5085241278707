<template>
<div
  class="library-side-view-multiple-vods-component flex flex-auto flex-column"
  :class="{ 'is-edit-mode-on' : isVodPanelInEditMode }"
>
  <div class="in-edit-mode-overlay" @click="onCancelEditVods"></div>

  <ioio-loader :isShown="areVodDetailsLoading || isEditInProgress" class="z-3 px-6"/>

  <header
    class="flex justify-between items-center px-6 py-5 border-bottom border-black-50"
    v-if="!isVodPanelInEditMode && !singleVodPreview.guid"
  >

    <h2 class="text-sm">{{ headerText }}</h2>
    <ioio-button
      type="minimal"
      size="small"
      @click="closeEditVodPanel">
      <ioio-icon
        icon="fa-solid fa-xmark"
      />
    </ioio-button>
  </header>

  <header
    class="flex justify-between items-center px-6 py-5 border-bottom border-black-50"
    v-if="isVodPanelInEditMode && !singleVodPreview.guid"
  >
    <h2 class="text-sm">{{ headerText }}</h2>
    <aside v-if="!isEditInProgress">

      <ioio-button
        type="secondary"
        variant="outline"
        size="small"
        @click="onCancelEditVods"
        class="mr-2">
        Cancel
      </ioio-button>
      <ioio-button
        type="primary"
        size="small"
        @click="saveEditedVods">
        Save
      </ioio-button>
    </aside>
  </header>

  <header
    class="flex justify-between items-center px-6 pt-6"
    v-if="singleVodPreview.guid"
  >
    <section class="flex">
      <ioio-button
        type="secondary"
        variant="outline"
        size="small"
        @click="toggleSingleVodPreviewOpened()"
        class="mr-3"
      >
        <ioio-icon icon="fa-arrow-left"  />
        Back
      </ioio-button>
      <ioio-button
        :type="isPreviewVodSelected ? 'primary' : 'secondary'"
        variant="outline"
        size="small"
        @click="toggleIsPreviewVodSelected()"
        class="mr-2">{{ isPreviewVodSelected ? 'Selected' : 'Select' }}
      </ioio-button>
    </section>

  </header>

  <section
    class="flex flex-column flex-auto edited-vod-info pt-4 pl-6 px-2 pb-4"
    v-if="bulkSelectedVodsCount"
  >

    <section v-if="!singleVodPreview.guid" class="flex flex-auto flex-column">

      <section class="flex"> <!-- NOTE: This wrapper is needed to preserve the layout -->
        <section
          class="videos-previews grid grid-cols-2 gap-4 w-full mb-4 overflow-y-auto"
          :class="bulkSelectedVodsCount > 4 ? 'pr-2' : 'pr-4'"
        >

          <div
            v-for="vod in fullVodData"
            :key="vod.guid"
            class="vid-preview"
            :class="vod.meta && vod.meta.imageUrl ? '' : 'border border-black-200 flex items-center justify-center relative'"
            :style="{ background:`url('${vod.meta && vod.meta.imageUrl}?w=254&q=80&f=jpeg') no-repeat center center / contain` }"
          >
            <div class="click-overlay flex justify-center z-1 relative">
              <header class="flex jusity-between flex-auto">
                <h3 class="mr-4 truncate text-xs" >{{ vod.meta.title }}</h3>

                <ioio-icon
                  icon="fa-solid fa-xmark"
                  class="text-white pointer"
                  @click.stop="selectVod(false, { _source: vod })"
                />
              </header>
              <div
                class="view-btn pointer"
                @click="toggleSingleVodPreviewOpened(vod)"
              >View
              </div>
            </div>

            <ioio-icon
              icon="fas fa-video"
              class="no-thumb-svg"
              v-if="!(vod.meta && vod.meta.imageUrl)"
            />

          </div>
        </section>
      </section>

      <section class="border-bottom border-black-200 mb-2 mr-4" v-if="!isVodPanelInEditMode">

        <ioio-button
          type="secondary"
          variant="outline"
          fullWidth
          iconLeft="fa-light fa-pen"
          class="mb-4"
          size="small"
          :disabled="isEditBtnVisible"
          @click.stop.prevent="toggleIsVodPanelInEditMode(true)"
        >Edit
        </ioio-button>
      </section>

      <library-multiple-vods-details-component
        ref="multiVodsEditComponent"
        :selectedVods="fullVodData"
        :selectedVodsCount="bulkSelectedVodsCount"
        :isVodPanelInEditMode="isVodPanelInEditMode"
      />

    </section>

    <section class="flex flex-column flex-auto mr-2" v-else>

      <div class="mr-2">

        <video-player-component
          ref="videoPlayer"
          :previewVod="singleVodPreview"
          shouldLoadSynchronously
          :thumbnail="vodPreviewThumb"
        />
      </div>

      <header>

        <h2 class="text-md text-black-700 mt-2 mb-2 truncate" style="minHeight: 20px">{{ singleVodPreview.meta && singleVodPreview.meta.title }}</h2>
        <p class="edit-vod-description text-sm text-black-500 mb-4 truncate" style="minHeight: 20px">{{ singleVodPreview.meta && singleVodPreview.meta.description || '--' }}</p>
      </header>

      <library-vod-details-overview-component
        :editedVod="singleVodPreview"
      />
    </section>
  </section>
  <p v-else class="p-6">Select videos in order to see additional options</p>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import {
  compareObjectArraysByPropAndCombineUniques,
  comparePrimitiveArraysAndCombineUniques,
  validateTabsFormAndHighlight
} from "@utils/helpers";


export default {

  data: () => ({

    compareObjectArraysByPropAndCombineUniques,
    comparePrimitiveArraysAndCombineUniques,

    areVodDetailsLoading: false,
    isEditInProgress: false,
    fullVodData: {},

    singleVodPreview: {},
    isPreviewVodSelected: true
  }),

  props: {

    bulkSelectedVods: Object,
    bulkSelectedVodsCount: Number,
    isVodPanelInEditMode: Boolean,
    isEditBtnVisible: Boolean,
    isEditVodPadelOpened: Boolean,
    onCloseEditVodPanel: Function,
    hasDeletePermission: Boolean,
    hasArchivePermission: Boolean,
    encodingGuids: Object,
    copyToClipboard: Function,
    toggleIsVodPanelInEditMode: Function,
    triggerEncodeFileRequest: Function,
    selectVod: Function
  },

  mounted() {
    window.b = this;
    this.getFullDetailsForSelectedVods();

    this.setupRedirectGuardIfNeeded();
  },

  methods: {

    setupRedirectGuardIfNeeded() {

      if (this.isVodPanelInEditMode) {

        this.setRedirectGuard({
          redirectMsg: 'Your changes are not saved.',
          redirectSecondaryMsg: 'Are you sure you want to cancel?'
        });

      } else {

        this.setRedirectGuard(false);
      }
    },

    toggleIsPreviewVodSelected() {

      this.isPreviewVodSelected = !this.isPreviewVodSelected;
    },

    toggleSingleVodPreviewOpened(vod = {}) {

      if (!vod.guid) {

        // The preview vod was unselected - apply the change
        // for the entire selection when closing the preview
        if (!this.isPreviewVodSelected) {

          this.selectVod(false, { _source: this.singleVodPreview });

          // Ready the flag for future preview vods
          this.isPreviewVodSelected = true;
        }
      }

      this.singleVodPreview = {

        ...vod
      };
    },

    closeEditVodPanel() {

      this.fullVodData = {};

      this.onCloseEditVodPanel();
    },

    async getFullDetailsForSelectedVods() {

      const fullVodData = {};

      const dataRequests = [];

      this.areVodDetailsLoading = true;

      for (let guid in this.bulkSelectedVods) {

        // The data for this vod has already been fetched before
        if (this.fullVodData[guid]) {
// here???
console.log('would have continued......')
          continue;
        }

        dataRequests.push(this.getFullVodDetails({ guid })
          .then(resp => {

            fullVodData[guid] = resp;
          }));
      }

      await Promise.all(dataRequests);

      this.areVodDetailsLoading = false;

      this.fullVodData = {

        ...this.fullVodData,
        ...fullVodData
      };
    },

    onCancelEditVods() {

      if (this.isActiveConfirmGuardSet && this.$refs.multiVodsEditComponent.isFormDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => {

            this.cancelEditVods();
          }
        });

        this.raiseRedirectFlag(true);

        return;
      }

      this.cancelEditVods();
    },

    cancelEditVods() {

      this.toggleIsVodPanelInEditMode(false);

      this.$refs.multiVodsEditComponent.cleanFormState();
    },

    saveEditedVods() {

      if (validateTabsFormAndHighlight(this.$refs.multiVodsEditComponent.$refs.tabForm, 'editMultipleVods')) {

        return;
      }

      const newVodData = this.$refs.multiVodsEditComponent.gatherFormData();

      console.log('trigger MULTIII save: ', newVodData);
      const categories = newVodData.meta.categories ? [...newVodData.meta.categories] : [];
      const tags = newVodData.meta.tags ? [...newVodData.meta.tags] : [];
      const hubs = newVodData.meta.hubs ? [...newVodData.meta.hubs] : [];

      const savePromises = [];

      for (let guid in this.fullVodData) {

        const currentVod = this.fullVodData[guid];

        let combinedCategories = [];
        let combinedTags = [];
        let combinedHubs = [];
        console.log('CurrentVod cats..', currentVod.meta.categories)
        if (!newVodData.shouldOverwriteCategories && currentVod.meta.categories) {

          combinedCategories = this.comparePrimitiveArraysAndCombineUniques(currentVod.meta.categories, categories);
        }

        console.log('combinedCategories..', combinedCategories)
        if (!newVodData.shouldOverwriteTags && currentVod.meta.tags) {

          combinedTags = this.comparePrimitiveArraysAndCombineUniques(currentVod.meta.tags, tags);
        }

        if (!newVodData.shouldOverwriteHubs && currentVod.meta.hubs) {

          combinedHubs = this.compareObjectArraysByPropAndCombineUniques(hubs, currentVod.meta.hubs, 'name');
        }

        const params = {

          ...currentVod.meta,
          ...newVodData.meta,
          categories: combinedCategories.length ? combinedCategories : categories,
          tags: combinedTags.length ? combinedTags : tags,
          hubs: combinedHubs.length ? combinedHubs : hubs
        };

        console.log('params multi', params.categories)

        // NOTE: if origin and user are missing (old vods that don't have them set upon creation) the BE throws an error
        if (!params.user) {

          params.user = {};
        }

        if (!params.origin) {

          params.origin = {};
        }

        if (params.expires) {

          params.expires = new Date(params.expires).getTime();

        } else {

          delete params.expires;
        }

        const payload = {

          params,
          vodGuid: guid
        };

        this.isEditInProgress = true;

        savePromises.push(this.makeEditVodMetadataRequest(payload));
      }

      Promise.all(savePromises)
        .then((responses) => {

          this.$toasted.success("Your bulk changes were saved successfully");

          this.toggleIsVodPanelInEditMode();
        })
        .finally(() => {

          this.isEditInProgress = false;

          this.onCancelEditVods();
        });
    },

    ...mapMutations({
      setRedirectGuard: 'app/SET_REDIRECT_GUARD',
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      getFullVodDetails: 'channelManager/makeGetVodDetailsRequest',
      makeEditVodMetadataRequest: 'channelManager/makeEditVodMetadataRequest',
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },

  computed: {

    ...mapGetters({
      isActiveConfirmGuardSet: 'app/isActiveConfirmGuardSet',
      msgBusMsgList: 'app/msgBusMsgList',
    }),

    headerText() {

      if (this.isVodPanelInEditMode) {

        return `Editing ${ this.bulkSelectedVodsCount } ${ this.bulkSelectedVodsCount > 1 || this.bulkSelectedVodsCount === 0 ? 'videos' : 'video' }`;
      }

      return `${ this.bulkSelectedVodsCount } ${ this.bulkSelectedVodsCount > 1 || this.bulkSelectedVodsCount === 0 ? 'videos are' : 'video is' } selected`;
    },

    vodPreviewThumb() {

      return this.singleVodPreview && this.singleVodPreview.meta && this.singleVodPreview.meta.imageUrl;
    }
  },

  watch: {

    bulkSelectedVodsCount(newVal, oldVal) {

      // some shortlisted vods were deleted - sync the data
      if (newVal < oldVal) {

        for (let guid in this.fullVodData) {

          if (!this.bulkSelectedVods[guid]) {

            delete this.fullVodData[guid];
          }
        }

        return;
      }

      this.getFullDetailsForSelectedVods();
    },

    isVodPanelInEditMode() {

      this.setupRedirectGuardIfNeeded();
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      const updatedGuid = newestMsg.data.guid;

      if (newestMsg.type === 'vod_meta_updated') {

        // The vod is in the fullVodData map for bulk actions - update the map
        if (this.fullVodData[updatedGuid]) {

          // Update the fullVodData
          this.fullVodData[updatedGuid] = {
            ...newestMsg.data,
            meta: {
              ...newestMsg.data.meta
            }
          };
        }
      }
    }
  }
}
</script>

<style lang="scss">

.library-side-view-multiple-vods-component {

  &.is-edit-mode-on {

    position: relative;

    .in-edit-mode-overlay {

      width: 100vw;
      height: 100%;
      background-color: rgba(53, 55, 65, var(--tw-bg-opacity));
      --tw-bg-opacity: 0.6;
      display: block;
      position: fixed;
      top: 0;
      right: 572px;
      z-index: 1;
    }
  }

  .videos-previews {

    max-height: 308px;
  }

  .vid-preview {

    padding-top: 56.25%;

    @apply
    relative
    rounded-lg
    overflow-hidden;

    .no-thumb-svg {

      height: 26px;
      position: absolute;
      top: calc(50% - 13px);
    }

    &:hover {

      .click-overlay {

        display: flex;
      }
    }
  }

  .click-overlay {

    @apply
    absolute
    p-2
    text-white;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: #0000005e;

    .view-btn {

      position: absolute;
      top: calc(50% - 16px);
      width: 58px;
      height: 32px;
      background-color: #00000099;

      @apply
      rounded
      text-sm
      flex
      items-center
      justify-center;
    }
  }
}

</style>
