/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_upload': {
    width: 40,
    height: 40,
    viewBox: '0 0 36 38',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M26.783 17.625C26.142 14.437 23.392 12 20 12a6.69 6.69 0 00-6.05 3.75C11.108 16.125 9 18.469 9 21.375 9 24.469 11.475 27 14.5 27h11.917C28.983 27 31 24.937 31 22.312c0-2.437-1.925-4.5-4.217-4.687zm-4.95 2.813v3.75h-3.666v-3.75h-2.75L20 15.75l4.583 4.688h-2.75z" _fill="#686B6F"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h40v40H0z"/></clipPath></defs>'
  }
})
