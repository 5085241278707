<template>
<div class="dashboard-quick-actions">
  <div class="qa" v-if="areSiteBuilderAppsLoaded">
    <div class="qa__el"
      @click="navigate(navLink)"
      v-for="navLink in activeQuickActionItems"
      :key="navLink.name"
    >
      <div class="qa__el-thumb">
        <ioio-icon :icon="navLink.icon"/>
      </div>
      <div class="qa__el-text">{{navLink.label}}</div>
    </div>
  </div>

  <div class="relative min-h-36" v-else>
    <ioio-loader isShown />
  </div>

  <ioio-modal
    class="select-hub"
    :show.sync="isHubOptionModalOpened"
    size="medium"
    :noScroll="false"
    headerClasses="border-bottom"
  >

    <template slot="header" >
      <div class="pb-3">
        Select hub
      </div>
    </template>

    <h3 class="select-hub__title">
      Select in which hub you want to create a page
    </h3>

    <div
      v-for="(hubOption, i) in siteBuilderApps"
      :key="i"
      @click="selectedHubUrl = hubOption.hostnames[0]"
      class="select-hub__option"
      :class="{ 'select-hub__option--selected': hubOption.hostnames[0] === selectedHubUrl }"
    >
      <aside class="select-hub__option-container">
        <h3 class="select-hub__option-title">{{ hubOption.name }}</h3>
        <span  class="select-hub__option-disclaimer">
          {{ hubOption.description }}
        </span>
      </aside>

      <span class="select-hub__option-check">
        <ioio-icon
          icon="fas-check"
          v-if="hubOption.hostnames[0] === selectedHubUrl"
        />
      </span>
    </div>

    <template slot="footer">
      <div class="flex justify-end" >
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isHubOptionModalOpened = false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="primary"
          @click.stop.prevent="openHubCreatePage"
        >
          Save
        </ioio-button>
      </div>
    </template>
  </ioio-modal>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isHubOptionModalOpened: false,
    selectedHubUrl: '',

  }),
  props: {},
  methods: {
    navigate(navItem) {

      const { route, isExternalRedirect, queries } = navItem;

      if (!route) {

        return;
      }


      if (!isExternalRedirect) {

        const routeParams = {

          path: route
        };

        queries && (routeParams.query = queries);

        // Navigate to the requested state
        return this.$pushRoute(routeParams);
      }

      if (navItem.name == "quickPage" && this.siteBuilderApps.length > 1) {

        this.isHubOptionModalOpened = true;
        return
      }

      // Open external links into a new window
      window.open(route);
    },

    openHubCreatePage() {

      window.open(this.selectedHubUrl);
      this.isHubOptionModalOpened = false;
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      // E.g. someAction: "app/someAction"
    })
  },
  computed: {
    ...mapGetters({
      quickActionDashboardItems: 'app/quickActionDashboardItems',
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
    }),

    mergedHubsLinks() {

      if (!this.areSiteBuilderAppsLoaded) {

        return this.quickActionDashboardItems;
      }

      const mergedItems = [...this.quickActionDashboardItems];

      if (this.siteBuilderApps.length > 0) {

        let hubs = [];

        this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          hubs = hubs.concat(a.hubs);
          }
        });

          if (hubs.length > 0) {

            mergedItems[2].route = `https://${this.siteBuilderApps[0].hostnames[0]}/vh/recordings?new`;

            mergedItems[3].route = `https://${this.siteBuilderApps[0].hostnames[0]}/vh/pages?new`;

            return mergedItems;
          };
      }

      mergedItems.splice(2, 2);

      return mergedItems;
    },

    activeQuickActionItems() {

      return this.mergedHubsLinks.filter(navLink => {

        return (this.$auth(navLink.permission) && !navLink.hidden);
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.qa {
  @apply
  grid
  gap-5;
  grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );

  &__el {
    @apply
    flex
    flex-col
    items-center
    flex-auto
    min-h-36
    py-6
    px-2
    cursor-pointer
    shadow-card
    border
    border-black-100
    rounded-lg
    bg-white
    transition
    hover:border-blue-300;

    &-thumb {
      @apply
      flex
      justify-center
      items-center
      w-14
      h-14
      mb-4
      rounded-full
      bg-blue-50
      text-blue-600
      text-lg;
    }
  }
}

.select-hub {
  &__title {
    @apply py-6;
  }

  &__option {
    @apply
    relative
    flex
    justify-between
    items-center
    p-5
    border
    border-black-200
    rounded
    cursor-pointer
    mb-5;

    &--selected {
      @apply border-blue-500;

      .select-hub__option-check {
        @apply
        border-blue-500
        bg-blue-500
        text-white;
      }
    }
  }

  &__option-title {
    @apply mb-1;
  }

  &__option-disclaimer {
    @apply text-sm text-black-500;
  }

  &__option-check {
    @apply
    absolute
    right-3
    top-1/2
    transform
    -translate-y-1/2
    w-8
    h-8
    rounded-full
    overflow-hidden
    border
    border-black-500
    flex
    justify-center
    items-center;
  }

}

</style>
