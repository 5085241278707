<template>
<section class="configure-stream-modal-component">
  <ioio-modal
    class="select-theme"
    :show.sync="isSelectThemeModalOpened"
    size="large"
    :noScroll="false"
  >

    <template slot="header" >
      <div class="pb-3">
        Select theme
      </div>
    </template>

    <section class="flex">
      <aside class="select-theme__option-container">
        <div
          v-for="(theme, i) in themeConfig"
          :key="i"
          @click="setCurrentThemeSelection(theme)"
          class="select-theme__option"
          :class="{ 'is--selected': theme.name === currentThemeSelection.name }"
        >
          <img
            v-if="theme.thumbnail"
            class="select-theme__thumb"
            :src="getImgSrcUrl({ cdnPath: theme.images[0].src })" alt=""
          />
          <h3 class="mt-2">{{ theme.label }}</h3>
        </div>
      </aside>

      <section class="select-theme__preview">
        <header class="select-theme__preview-header">
          <h1 class="select-theme__preview-title">{{ currentThemeSelection.label || 'Theme' }}</h1>
          <aside>

            <ioio-button
              type="primary"
              v-if="currentThemeSelection.name !== selectedTheme.name"
              @click.stop.prevent="stageEventTheme"
            >
              Use theme
            </ioio-button>
            <ioio-button
              v-else
              type="minimal"
              disabled
            >
              Current theme
            </ioio-button>
          </aside>
        </header>

        <section class="select-theme__selected-preview-img-container">

        <transition name="slide-fade" >
          <img
            v-if="currentThemeSelection.thumbnail"
            class="select-theme__selected-preview-img"
            :src="getImgSrcUrl({ cdnPath: currentThemeSelectionPreviewSrc })"
            :key="getImgSrcUrl({ cdnPath: currentThemeSelectionPreviewSrc })"
          />
        </transition>
        </section>
        <h3 class="mb-4 mt-6 text-sm">Preview</h3>
        <div class="select-theme__preview-thumbs">

          <img
            class="select-theme__preview-thumb"
            :class="{ 'is--selected': bgImg.src === currentThemeSelectionPreviewSrc }"
            v-for="bgImg in currentThemeSelection.images"
            :key="bgImg.src"
            @click="setCurrentThemeSelectionPreviewSrc(bgImg.src)"
            :src="getImgSrcUrl({ cdnPath: bgImg.src })" alt=""
          />
        </div>
      </section>
    </section>
  </ioio-modal>

  <ioio-modal
    class="select-stream"
    :show.sync="isStreamOptionModalOpened"
    size="medium"
    :noScroll="false"
    headerClasses="border-bottom"
  >

    <template slot="header" >
      <div class="pb-3">
        Select stream source
      </div>
    </template>

    <h3 class="select-stream__title">
      Select how you would want to host & produce the stream for this event
    </h3>

    <div
      v-for="(streamOption, i) in streamOptions"
      :key="i"
      @click="selectedStreamOption = streamOption.value"
      class="select-stream__option"
      :class="{ 'select-stream__option--selected': streamOption.value === selectedStreamOption }"
    >
      <aside class="select-stream__option-container">
        <h3 class="select-stream__option-title">{{ streamOption.title }}</h3>
        <span  class="select-stream__option-disclaimer">
          {{ streamOption.disclaimer }}
        </span>
      </aside>

      <span class="select-stream__option-check">
        <ioio-icon
          icon="fas-check"
          class="event-overview__date-icon"
          v-if="streamOption.value === selectedStreamOption"
        />
      </span>
    </div>



    <template slot="footer">
      <div class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isStreamOptionModalOpened = false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="primary"
          @click.stop.prevent="configureEventStreamOption"
        >
          Save
        </ioio-button>
      </div>
      <ioio-loader v-else isShown></ioio-loader>
    </template>

  </ioio-modal>

  <ioio-modal
    class="stream-assets"
    size="large"
    :show.sync="isStreamAssetsModalOpened"
  >

    <template slot="header">
      Add media

      <aside class="stream-assets__tabs">
        <button-component
          v-for="(tab,i) in assetsTabConfig"
          :key="i"
          size="size-l"
          class="stream-assets__tab"
          :class="{
            'is-active' : tab.name === selectedAssetsTab
          }"
          variant="tab"
          @click="setSelectedAssetsTab(tab)"
        >
          {{ tab.label }}
        </button-component>
      </aside>
    </template>

      <!-- Assets section -->
      <section
        v-if="selectedAssetsTab === 'images'"
        class="flex flex-col min-h-96"
      >

        <p
          v-if="!assignedImagesGuids.length"
          class="stream-assets__nodata"
        >
          <img
            class="mt-20 mb-5"
            src="~@assets/eventStyling/pages/events_creation/frame_19954.svg"
            alt=""
          />
          You haven't added images to this event yet
        </p>
        <div :class="!assignedImagesGuids.length ? 'text-center mt-5 mb-20': 'absolute top-24 right-10 text-right'">
          <ioio-button
            type="primary"
            variant="outline"
            class="whitespace-nowrap"
            iconLeft="plus"
            @click.stop.prevent="openAssignImagesModal('isAssignImagesOpened')"
          >
            Add Images
          </ioio-button>
        </div>

        <ul class="stream-assets__items">
          <li
            class="stream-assets__item"
            :key="asset.guid"
            v-for="(asset, index) in this[selectedAssetsType]"
          >

            <img :src="asset.cdnPath" class="stream-assets__item-image"/>
            <span class="mr-2 truncate">
              {{ asset.meta.title }}
            </span>

            <span class="mr-2 truncate">
              {{ Number(asset.imageMeta.size / 1024 / 1024).toFixed(2) }} MB
            </span>
            <span class="mr-2">{{ asset.format }}</span>

            <span class="mr-2">{{ asset.width }}x{{ asset.height }}</span>

            <ioio-button
              type="secondary"
              variant="outline"
              @click.stop.prevent="removeAssetFromShortlisted(asset.guid, index)"
            >
              Remove
            </ioio-button>
          </li>
        </ul>
      </section>

      <section
        v-if="selectedAssetsTab === 'videos'"
        class="flex flex-col min-h-96"
      >
        <p
          v-if="!assignedSourcesGuids.length"
          class="stream-assets__nodata"
        >
          <img
              class="mt-20 mb-5"
              src="~@assets/eventStyling/pages/events_creation/frame_19954.svg"
              alt=""
            />
          You haven't added videos to this event yet
        </p>
        <div :class="!assignedSourcesGuids.length ? 'text-center mt-5 mb-20': 'absolute top-24 right-10 text-right'">
          <ioio-button
            type="primary"
            variant="outline"
            class="whitespace-nowrap"
            iconLeft="plus"
            @click.stop.prevent="openAssignVidesModal()"
          >
            Add videos
          </ioio-button>
        </div>

        <ul class="stream-assets__items">
          <li
            class="stream-assets__item"
            :key="asset.guid"
            v-for="(asset, index) in this[selectedAssetsType]"
          >

            <img :src="asset.meta.imageUrl" class="stream-assets__item-image"/>
            <span class="mr-2 truncate">{{ asset.meta.title }}
            </span>
            <span class="mr-2 truncate">{{ moment(asset.createdAt).format('MMM Do YYYY h:mma z Z') }}
            </span>
            <span class="mr-2">{{ parseDuration(asset.srcDuration) }}
            </span>
            <ioio-button
              type="secondary"
              variant="outline"
              @click.stop.prevent="removeAssetFromShortlisted(asset.guid, index)"
            >
              Remove
            </ioio-button>
          </li>
        </ul>
      </section>

    <template slot="footer">
      <section class="flex justify-end" v-if="!isRequestPending">
        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelEventConfigure"
        >
          Cancel
        </ioio-button>
        <ioio-button
          @click.stop.prevent="configureEventAssets"
        >
          Save
        </ioio-button>
      </section>
      <ioio-loader
        v-if="isRequestPending"
        isShown
      />
    </template>
  </ioio-modal>

  <section
    size="large"
    class="stream-appearance"
    v-if="isStreamThemeAndOverlaysSectionOpened"
  >

    <header class="stream-appearance__header">

      <ioio-loader v-if="isRequestPending" isShown></ioio-loader>

      <aside class="stream-appearance__header-left">
        <ioio-button
          type="minimal"
          size="small"
          class="mr-2"
          iconLeft="fal-angle-left"
          @click.stop.prevent="cancelEventThemeConfigure"
        >
          Back
        </ioio-button>
        <h2 class="stream-appearance__header-title">Configure stream</h2>
      </aside>

      <aside class="flex" v-if="!isRequestPending">

        <ioio-button
          type="primary"
          class="mr-4"
          size="small"
          @click.stop.prevent="configureEventTheme"
        >
          Save
        </ioio-button>
        <ioio-button
          type="secondary"
          variant="outline"
          size="small"
          @click.stop.prevent="resetEventTheme"
        >
          Reset
        </ioio-button>
      </aside>

    </header>

    <section class="stream-appearance__container">

      <!-- Theme preview -->
      <article class="stream-appearance__preview-container">
        <div class="stream-appearance__preview-disclamer">
          You can customize the background and the appearance of your stream
        </div>

        <article class="stream-appearance__preview">
          <div class="stream-appearance__preview-inner">
            <img
              v-if="selectedBgImage.cdnPath !== 'none'"
              class="stream-appearance__preview-bgr"
              :src="getImgSrcUrl(selectedBgImage)" alt=""
            />

            <span class="stream-appearance__preview-gradient"></span>

            <div
              class="stream-appearance__preview-images mb-8"
              v-if="(overlays.topLeft.srcUrl || overlays.topRight.srcUrl)"
            >
              <img class="stream-appearance__preview-image is-top-left"
                :src="overlays.topLeft.srcUrl" />
              <img class="stream-appearance__preview-image is-top-right"
                :src="overlays.topRight.srcUrl" />
            </div>

            <div class="stream-appearance__preview-text">
              <h1>{{ event.name }}</h1>
              <p class="stream-appearance__preview-date">
                {{ moment(event.startTime).format('LLLL') }} ({{ moment().tz(event.timezone).format('z') }})
              </p>
            </div>

            <div
              class="stream-appearance__preview-images"
              v-if="(overlays.bottomLeft.srcUrl || overlays.bottomRight.srcUrl)"
            >
              <img class="stream-appearance__preview-image is-bottom-left"
                :src="overlays.bottomLeft.srcUrl" />
              <img class="stream-appearance__preview-image is-bottom-right"
                :src="overlays.bottomRight.srcUrl" />
            </div>
          </div>
        </article>
      </article>

      <!-- Overlays && BG select -->
      <aside class="stream-appearance__images">
        <section class="stream-appearance__theme">
          <h3 class="stream-appearance__theme-title">
            Theme
          </h3>

          <div
            class="stream-appearance__theme-img-container"
            v-if="selectedTheme.thumbnail && selectedBgImage.cdnPath">

            <img
              v-if="selectedBgImage.cdnPath !== 'none'"
              class="stream-appearance__theme-img-bgr"
              :src="getImgSrcUrl(selectedBgImage)" alt=""
            />
            <img
              class="stream-appearance__theme-img-thumb"
              :src="getImgSrcUrl({ cdnPath: selectedTheme.thumbnail.src })" alt=""
            />
          </div>
          <div>
            <ioio-button
              @click.stop.prevent="openSelectThemeModal"
              type="secondary"
              variant="outline"
              size="small"
              class="mt-4"
            >
              Change theme
            </ioio-button>

          </div>
        </section>

        <section class="stream-appearance__corners">

            <h3 class="stream-appearance__corners-title">
              Overlays
              <ioio-button

                type="minimal"
                size="small"
                @click.stop.prevent="clearOverlaysData"
              >
                Clear
              </ioio-button>
            </h3>

            <!-- Top Left -->
            <div
              class="stream-appearance__image"
              @click.stop.prevent="openOverlayImagesModal('topLeft')"
            >
              <div class="h-20 stream-appearance__image-el">
                <img v-if="overlays.topLeft.srcUrl" :src="overlays.topLeft.srcUrl" />

                <ioio-icon
                  v-else
                  icon="plus"
                  class="stream-appearance__image-icon"
                />
              </div>

              <p class="stream-appearance__image-text">Top Left</p>
            </div>

            <!-- Top Right -->
            <div
              class="stream-appearance__image"
              @click.stop.prevent="openOverlayImagesModal('topRight')"
            >
              <div class="h-20 stream-appearance__image-el">
                <img v-if="overlays.topRight.srcUrl" :src="overlays.topRight.srcUrl" />

                <ioio-icon
                  v-else
                  icon="plus"
                  class="stream-appearance__image-icon"
                />
              </div>

              <p class="stream-appearance__image-text">Top Right</p>
            </div>

            <!-- Bottom Left -->
            <div
              class="stream-appearance__image"
              @click.stop.prevent="openOverlayImagesModal('bottomLeft')"
            >
              <div class="h-20 stream-appearance__image-el">
                <img v-if="overlays.bottomLeft.srcUrl" :src="overlays.bottomLeft.srcUrl" />

                <ioio-icon
                  v-else
                  icon="plus"
                  class="stream-appearance__image-icon"
                />
              </div>

              <p class="stream-appearance__image-text">Bottom Left</p>
            </div>

            <!-- Bottom Right -->
            <div
              class="stream-appearance__image"
              @click.stop.prevent="openOverlayImagesModal('bottomRight')"
            >
              <div class="h-20 stream-appearance__image-el">
                <img v-if="overlays.bottomRight.srcUrl" :src="overlays.bottomRight.srcUrl" />

                <ioio-icon
                  v-else
                  icon="plus"
                  class="stream-appearance__image-icon"
                />
              </div>

              <p class="stream-appearance__image-text">Bottom Right</p>
            </div>
        </section>

        <section>
          <h3 class="stream-appearance__images-title">
            Backgrounds
          </h3>

          <section class="stream-appearance__images-thumbs">

            <div
              class="h-12 stream-appearance__image-el"
              @click.stop.prevent="openAssignImagesModal('isOverlayBgImagesOpened')"
            >
              <ioio-icon
                icon="plus"
                class="stream-appearance__image-icon"
              />
            </div>

            <!-- Do not delete this block of code.
            This a markup for button witch remove the buttom -->

            <!-- <div class="stream-appearance__images-thumb">
              <img
                @click="setSelectedBgImage(img)"
                src="~@assets/eventStyling/pages/events_creation/no_bgr.png"
                class="stream-appearance__image-el"
              />
            </div> -->
            <div
              v-for="(img, i) in combinedBgImages"
              :key="i"
              class="stream-appearance__images-thumb"
              :class="{ 'is-selected': img.cdnPath === selectedBgImage.cdnPath }"
            >
              <img
                @click="setSelectedBgImage(img)"
                :src="getImgSrcUrl(img)"
                class="stream-appearance__image-el"
              />
            </div>

          </section>
        </section>
      </aside>

    </section>

  </section>

  <ioio-modal
    class="select-prerecord"
    :show.sync="isStreamChannelModalOpened"
  >
    <template slot="header">
      Select pre-record
    </template>


    <section>
      <h3 class="select-prerecord__select">
        Use our channel manager to order the program for your event.
      </h3>

      <ioio-dropdown
        :items="channelsList"
        v-model="streamChannel"
        valueProp="guid"
        titleProp="name"
        required
        fullWidth
        topic="editEventConfigureChannel"
        placeholder="Channel"
        size="medium"
        class="w-full mb-8"
      />

      <div class="select-prerecord__info">
        <h3 class="select-prerecord__info-title">Haven't created a pre-record?</h3>
        <ol class="select-prerecord__info-list">
          <li>
            Go to
            <a href="">
              Channel Manager
              <ioio-icon icon="far-external-link" class="event-overview__social-link-icon "/>
            </a>
          </li>
          <li>Start adding program blocks in the time that you want it to be shown in your event.</li>
          <li>Go to "rundown" and add the records that you want to show in your event.</li>
          <li>Name your channel and select it from here.</li>
        </ol>

      </div>


    </section>
    <template slot="footer">

      <section class="flex justify-end" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          noScroll
          @click.stop.prevent="isStreamChannelModalOpened = false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          topic="editEventConfigureChannel"
          @click.stop.prevent="configureEventStreamChannel">Save</ioio-button>
      </section>
      <div v-else class="overflow-hidden">
        <ioio-loader isShown />
      </div>

    </template>
  </ioio-modal>



  <ioio-modal
    class="stream-url"
    :show.sync="isStreamUrlModalOpened"
  >
    <template slot="header">
      Connect with external production
    </template>

    <h3 class="mt-7">
      Add your encoded HLS stream URL usually ending in .m3u8.
      Your stream will be loaded on the page of the event, once you go live.
    </h3>
    <ioio-field
      type="text"
      required
      topic="editEventConfigureStreamUrl"
      size="medium"
      placeholder="Enter HLS link here"
      v-model="streamUrl"
      class="my-7"></ioio-field>


    <h3 class="overflow-hidden text-black-600">
      Having trouble?
      <a
        href="mailto:support@ioio.tv"
        target="_blank"
        class="text-black-900"
      >Contact us</a>
    </h3>


    <template slot="footer">
      <section class="flex justify-end mt-8" v-if="!isRequestPending">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="isStreamUrlModalOpened = false"
        >
          Cancel
        </ioio-button>
        <ioio-button
          topic="editEventConfigureStreamUrl"
          @click.stop.prevent="configureEventStreamUrl"
        >Save</ioio-button>
      </section>
      <div v-else class="overflow-hidden">
        <ioio-loader isShown />
      </div>
    </template>
  </ioio-modal>




  <assign-images-modal-component

    raiseFlagName="isAssignImagesOpened"
    :getAssignedImages="getAssignedImages"
    :initialImages="assignedImagesMap"
    width="1100"
    height="700" />

  <assign-videos-modal-component
    :eventGuid="$route.query.id"
    :getAssignedVods="getAssignedVods"
    :initialVods="assignedSourcesMap" />


  <assign-images-modal-component
    id="shortlistedBgImagesMap"

    :getAssignedImages="getAssignedOverlayBgImages"
    :initialImages="shortlistedBgImagesMap"


    raiseFlagName="isOverlayBgImagesOpened"
    width="1100"
    height="700" />

  <assign-images-modal-component
    id="editedOverlay"

    :getAssignedImages="getAssignedOverlayImages"
    :initialImages="editedOverlay"
    singleChoise
    addLabel="Select Image"

    raiseFlagName="isOverlayImagesOpened"
    width="1100"
    height="700" />

</section>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import moment from "moment-timezone";

import { getHHMMSSFromMS } from "@utils/helpers";
import themeConfig from "@configs/eventThemesConfig.json";
import themeBackgrounds from "@configs/themeBackgrounds";

export default {
  data: () => ({

    themeConfig: themeConfig.themes,

    isSelectThemeModalOpened: false,

    defaultBackgrounds: themeBackgrounds,

    isRequestPending: false,

    assetsTabConfig: [
      {

        name: 'videos',
        label: 'Videos'
      },
      {
        name: 'images',
        label: 'Images'
      }
    ],
    selectedAssetsTab: 'videos',

    selectedAssetsType: 'assignedSourcesMap', // || assignedImagesMap

    assignedSourcesGuids: [],
    assignedSourcesMap: {},

    assignedImagesGuids: [],
    assignedImagesMap: {},

    pristineAssets: {},

    // Show on configure only if it is not already selected for the event
    // enum: ['ioConnect', 'externalPage', 'noAttendees']
    streamOptions: [
      {
        title: 'Live studio',
        value: 'ioConnect',
        disclaimer: 'Host and produce your live stream on our platform'
      },
      {
        title: 'Pre-record',
        value: 'preRecorded',
        disclaimer: 'Record your stream upfront and play it on the day of the event'
      },
      {
        title: 'External production',
        value: 'liveStream',
        disclaimer: 'Link your event page with evternally produced stream'
      },
    ],

    selectedStreamOption: 'ioConnect',


    streamUrl: '',
    streamChannel: '',


    // overlays
    shortlistedBgImagesGuids: [],
    shortlistedBgImagesMap: {},

    selectedBgImage: {},

    // actual selected theme, that is saved for the event
    selectedTheme: {},

    // The theme that is in draft to be saved in the modal
    currentThemeSelection: {},

    // Used only for preview before theme selection
    currentThemeSelectionPreviewSrc: '',

    overlays: {

      topLeft: {
        guid: '',
        srcUrl: ''
      },
      topRight: {
        guid: '',
        srcUrl: ''
      },
      bottomLeft: {
        guid: '',
        srcUrl: ''
      },
      bottomRight: {
        guid: '',
        srcUrl: ''
      },
    },

    editedOverlay: {},
    editedOverlayPos: '', // e.g. bottomRight

    isStateDirty: false,
  }),
  props: {
    event: Object
  },

  created() {

    this.moment = moment;

    this.event.streamOption && (this.selectedStreamOption = this.event.streamOption);

    this.cachePristineEventAssets(this.event.assets);

    this.streamUrl = this.event.streamUrl;
    this.streamChannel = this.event.streamChannel;

    this.getChannelsList();
  },

  mounted() {

    window.as = this;
  },

  beforeDestroy() {

    this.cancelEventConfigure();
  },

  methods: {

    cachePristineEventAssets(assets) {

      const newAssets = assets || {};

      this.pristineAssets = {
        ...newAssets
      };
    },

    setupEventAssets(assets) {

      if (assets.images) {

        const assignedImagesGuids = [];
        const assignedImagesMap = {};

        assets.images.forEach(i => {

          assignedImagesGuids.push(i.guid);

          assignedImagesMap[i.guid] = {
            ...i
          };
        });

        this.assignedImagesGuids = assignedImagesGuids;
        this.assignedImagesMap = assignedImagesMap;

      }

      if (assets.sources) {

        const assignedSourcesGuids = [];
        const assignedSourcesMap = {};

        assets.sources.forEach(i => {

          assignedSourcesGuids.push(i.guid);

          assignedSourcesMap[i.guid] = {
            ...i
          };
        });

        this.assignedSourcesGuids = assignedSourcesGuids;
        this.assignedSourcesMap = assignedSourcesMap;
      }
    },

    removeAssetFromShortlisted(guid, index) {

      if (this.selectedAssetsTab === 'videos') {

        delete this.assignedSourcesMap[guid];
        this.assignedSourcesGuids.splice(index, 1);

      } else {

        delete this.assignedImagesMap[guid];
        this.assignedImagesGuids.splice(index, 1);
      }
    },

    setCurrentThemeSelection(theme) {

      this.currentThemeSelection = {
        ...theme
      };

      this.setCurrentThemeSelectionPreviewSrc(theme.images[0].src);
    },

    setCurrentThemeSelectionPreviewSrc(imgSrc) {

      this.currentThemeSelectionPreviewSrc = imgSrc;
    },

    getImgSrcUrl(img) {

      if (!img.cdnPath) {

        return;
      }

      if (img.guid) {

        return img.cdnPath;
      }

      if (img.cdnPath === 'none') {

        return require(`@assets/eventStyling/eventThemes/backgrounds/no-bg.png`);
      }

      return require(`@assets/eventStyling/eventThemes/${img.cdnPath}`);
    },

    findSelectedTheme(name) {

      const theme = this.themeConfig.find(t => t.name === name);

      if (!theme) {

        // return default theme
        return this.themeConfig[0];
      }

      return theme;
    },

    stageEventTheme() {

      this.setIsStateDirty(true);

      this.setSelectedTheme(this.currentThemeSelection);

      this.setSelectedBgImage(this.currentThemeSelection.background)

      this.isSelectThemeModalOpened = false;
    },

    setSelectedTheme(theme) {

      this.selectedTheme = {
        ...theme
      };
    },

    setSelectedBgImage(img) {

      this.setIsStateDirty(true);

      if (img.cdnPath) {

        this.selectedBgImage = {

          ...img
        };

      } else {

        this.selectedBgImage = {

          cdnPath: img
        }
      }
    },

    parseDuration(ms) {

      return getHHMMSSFromMS(ms);
    },

    openAssignImagesModal(name) {

      this.toggleAssignImagesModalFlag({

        division: name,
        newVal: true
      });
    },

    openSelectThemeModal() {

      this.isSelectThemeModalOpened = true;

      this.setCurrentThemeSelection(this.selectedTheme);
    },

    clearOverlaysData() {

      this.setIsStateDirty(true);

      this.overlays = {

        topLeft: {
          guid: '',
          srcUrl: ''
        },
        topRight: {
          guid: '',
          srcUrl: ''
        },
        bottomLeft: {
          guid: '',
          srcUrl: ''
        },
        bottomRight: {
          guid: '',
          srcUrl: ''
        }
      };
    },

    clearShortlistedAssets() {

      this.shortlistedBgImagesGuids = [];
      this.shortlistedBgImagesMap = {};
    },

    openOverlayImagesModal(editedOverlayPos) {

      const currentImageData = this.overlays[editedOverlayPos];

      this.editedOverlay = {

        [currentImageData.guid]: {
          cdnPath: currentImageData.srcUrl,
          guid: currentImageData.guid
        }
      };

      this.editedOverlayPos = editedOverlayPos;

      setTimeout(() => {

        this.openAssignImagesModal('isOverlayImagesOpened');
      });
    },

    getAssignedImages(images, imagesMap) {

      this.setIsStateDirty(true);

      this.assignedImagesGuids = images;
      this.assignedImagesMap = imagesMap;

      this.toggleAssignImagesModalFlag({

        division: 'isAssignImagesOpened',
        newVal: false
      });
    },

    getAssignedOverlayBgImages(images, imagesMap) {

      this.setIsStateDirty(true);

      this.shortlistedBgImagesGuids = images;
      this.shortlistedBgImagesMap = imagesMap;

      this.toggleAssignImagesModalFlag({

        division: 'isOverlayBgImagesOpened',
        newVal: false
      });
    },

    getAssignedOverlayImages(images, imagesMap) {

      this.setIsStateDirty(true);

      const selectedImage = imagesMap[images[0]] || {};

      this.overlays = {

        ...this.overlays,

        [this.editedOverlayPos]: {

          guid: selectedImage.guid,
          srcUrl: selectedImage.cdnPath
        }
      };

      this.toggleAssignImagesModalFlag({

        division: 'isOverlayImagesOpened',
        newVal: false
      });
    },

    openAssignVidesModal() {

      this.toggleAssignVideosModalFlag(true);
    },

    getAssignedVods(vods, vodsMap) {

      this.setIsStateDirty(true);

      this.assignedSourcesGuids = vods;
      this.assignedSourcesMap = vodsMap;

      this.toggleAssignVideosModalFlag(false);
    },

    setSelectedAssetsTab(tab) {

      this.selectedAssetsTab = tab.name;

      this.selectedAssetsType = this.selectedAssetsTab === 'videos' ?
        'assignedSourcesMap' : 'assignedImagesMap';
    },

    cancelEventThemeConfigure() {

      // add a guard

      if (this.isStateDirty) {

        this.setupRedirectConfirmGuardLocal({

          successFn: () => this.cancelEventConfigure()
        });

        this.raiseRedirectFlag(true);

      } else {

        this.cancelEventConfigure();
      }
    },

    cancelEventConfigure() {

      this.setIsStateDirty(false);

      this.toggleEventStreamOptionModalOpened(false);

      this.toggleEventStreamAssetsModalOpened(false);

      this.toggleEventStreamOverlaysModalOpened(false);

      this.toggleEventStreamChannelModalOpened(false);

      this.toggleEventStreamUrlModalOpened(false);
    },

    configureEventStreamOption() {

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.event,
          streamOption: this.selectedStreamOption
        },

        guid: this.event.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${ this.$options.filters.truncate(this.event.name, 64, "...")} was configured successfully!`);

          this.getEventById({ guid: this.event.guid });

          this.cancelEventConfigure();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    configureEventStreamDetails() {

      this.isRequestPending = true;

      const selectedStreamOption = this.event.streamOption;


      const updatedEvent = {

        ...this.event
      };

      if (selectedStreamOption === 'ioConnect') {

        delete updatedEvent.streamUrl;

        delete updatedEvent.streamChannel;
      }

      if (selectedStreamOption === 'liveStream') {

        delete updatedEvent.streamChannel;

        updatedEvent.streamUrl = this.streamUrl;
      }

      if (selectedStreamOption === 'preRecorded') {

        delete updatedEvent.streamUrl;

        updatedEvent.streamChannel = this.streamChannel;
      }

      const payload = {

        data: {

          ...updatedEvent
        },

        guid: this.event.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.event.name, 64, "...")} was edited successfully!`);

          this.getEventById({ guid: this.event.guid });

          this.cancelEventConfigure();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    configureEventAssets() {

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.event,
          assets: {
            images: Object.values(this.assignedImagesMap),
            sources: Object.values(this.assignedSourcesMap),
          }
        },

        guid: this.event.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.event.name, 64, "...")} was edited successfully!`);

          this.getEventById({ guid: this.event.guid })
            .then(() => {

              this.cachePristineEventAssets(this.event.assets);

              this.setIsStateDirty(false);

              this.cancelEventConfigure();
            });
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    refreshMixerSettings() {

      if (this.event.streamOption !== 'ioConnect') {

        return;
      }

      this.isRequestPending = true;

      this.getMixerSettings({ eventGuid: this.event.guid })
        .then(resp => {

          // if (!resp) return;

          const {

            selectedBgImage,
            overlays,
            shortlistedBgImages,
            theme

          } = resp;

          const imgsMap = {};
          const imgsArr = [];

          (shortlistedBgImages || []).forEach(img => {

            imgsMap[img.guid] = {
              guid: img.guid,
              cdnPath: img.srcUrl
            };

            imgsArr.push(img.guid);
          });

          this.shortlistedBgImagesGuids = [...imgsArr];
          this.shortlistedBgImagesMap = { ...imgsMap };

          const selectedTheme = this.findSelectedTheme(theme);

          this.setSelectedTheme(selectedTheme);

          if (!(selectedBgImage && selectedBgImage.cdnPath)) {

            this.setSelectedBgImage(this.selectedTheme.background);

          } else {

            this.setSelectedBgImage(selectedBgImage);
          }

          overlays ?
            this.overlays = { ...overlays } :
            this.overlays = {
              topLeft: {
                guid: '',
                srcUrl: ''
              },
              topRight: {
                guid: '',
                srcUrl: ''
              },
              bottomLeft: {
                guid: '',
                srcUrl: ''
              },
              bottomRight: {
                guid: '',
                srcUrl: ''
              },
            };

          this.setIsStateDirty(false);
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    // The reset should delete all overlays and add the default theme, without saving.
    resetEventTheme() {

      this.setIsStateDirty(true);

      this.setSelectedTheme(this.themeConfig[0]);

      this.setSelectedBgImage(this.selectedTheme.background);

      this.clearOverlaysData();

      this.clearShortlistedAssets();
    },

    configureEventTheme() {

      const shortlistedBgs = this.shortlistedBgImagesGuids.map((guid) => {

        return {
          guid,
          srcUrl: this.shortlistedBgImagesMap[guid].cdnPath
        };
      });

      const payload = {

        data: {

          shortlistedBgImages: shortlistedBgs,
          selectedBgImage: this.selectedBgImage, // this can be from local files (without GUID)
          overlays: this.overlays,
          theme: this.selectedTheme.name
        },
        eventGuid: this.event.guid
      };

      this.isRequestPending = true;

      this.updateMixerSettings(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.event.name, 64, "...")} theme was updated successfully!`);

          this.setIsStateDirty(false);

          this.cancelEventConfigure();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    configureEventStreamChannel() {

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.event,
          streamChannel: this.streamChannel
        },

        guid: this.event.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.event.name, 64, "...")} was edited successfully!`);

          this.getEventById({ guid: this.event.guid });

          this.cancelEventConfigure();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    configureEventStreamUrl() {

      this.isRequestPending = true;

      const payload = {

        data: {

          ...this.event,
          streamUrl: this.streamUrl
        },

        guid: this.event.guid
      };

      this.updateEvent(payload)
        .then(() => {

          this.$toasted.success(`Event ${this.$options.filters.truncate(this.event.name, 64, "...")} was edited successfully!`);

          this.getEventById({ guid: this.event.guid });

          this.cancelEventConfigure();
        })
        .finally(() => {

          this.isRequestPending = false;
        });
    },

    setIsStateDirty(newVal) {

      this.isStateDirty = newVal;
    },

    ...mapMutations({
      toggleAssignImagesModalFlag: 'events/TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE',
      toggleAssignVideosModalFlag: 'events/TOGGLE_ASSIGN_VIDEOS_MODAL_VISIBLE',
      toggleEventStreamOptionModalOpened: 'events/TOGGLE_EVENTS_STREAM_OPTION_MODAL_FLAG_VISIBLE',
      toggleEventStreamAssetsModalOpened: 'events/TOGGLE_EVENTS_STREAM_ASSETS_MODAL_FLAG_VISIBLE',
      toggleEventStreamOverlaysModalOpened: 'events/TOGGLE_EVENTS_STREAM_OVERLAYS_MODAL_FLAG_VISIBLE',
      toggleEventStreamChannelModalOpened: 'events/TOGGLE_EVENTS_STREAM_CHANNEL_MODAL_FLAG_VISIBLE',
      toggleEventStreamUrlModalOpened: 'events/TOGGLE_EVENTS_STREAM_URL_MODAL_FLAG_VISIBLE',

      setRedirectGuard: "app/SET_REDIRECT_GUARD",
      raiseRedirectFlag: "app/RAISE_REDIRECT_FLAG",
    }),
    ...mapActions({
      updateEvent: "events/updateEvent",
      getEventById: "events/getEventById",
      getChannelsList: "channelManager/makeGetChannelsListRequest",
      getMixerSettings: "events/getMixerSettings",
      updateMixerSettings: "events/updateMixerSettings",
      setupRedirectConfirmGuardLocal: "app/setupRedirectConfirmGuardLocal",
    })
  },
  computed: {
    ...mapGetters({
      channelsList: "channelManager/channelsList",
      isAssignImagesOpened: 'events/isAssignImagesOpened',
      isOverlayBgImagesOpened: 'events/isOverlayBgImagesOpened',
      isOverlayImagesOpened: 'events/isOverlayImagesOpened',

      isEventStreamOptionModalOpened: 'events/isEventStreamOptionModalOpened',
      isEventStreamAssetsModalOpened: 'events/isEventStreamAssetsModalOpened',
      isEventStreamOverlaysModalOpened: 'events/isEventStreamOverlaysModalOpened',
      isEventStreamChannelModalOpened: 'events/isEventStreamChannelModalOpened',
      isEventStreamUrlModalOpened: 'events/isEventStreamUrlModalOpened',
      msgBusMsgList: 'app/msgBusMsgList',
    }),

    combinedBgImages() {

      const shortlistedBgImagesArr =
        Object.keys(this.shortlistedBgImagesMap).map((key) => this.shortlistedBgImagesMap[key]);

      return [

        // No BG Image
        {
          cdnPath: 'none'
        },

        ...shortlistedBgImagesArr,
        ...this.defaultBackgrounds.themeBackgroundUrls,
        ...this.defaultBackgrounds.additionalBackgroundUrls
      ];
    },

    isStreamOptionModalOpened: {
      get() {
        return this.isEventStreamOptionModalOpened;
      },
      set(newVal) {
        this.toggleEventStreamOptionModalOpened(newVal);
      }
    },

    isStreamAssetsModalOpened: {
      get() {
        return this.isEventStreamAssetsModalOpened;
      },
      set(newVal) {

        this.toggleEventStreamAssetsModalOpened(newVal);
      }
    },

    isStreamThemeAndOverlaysSectionOpened: {
      get() {
        return this.isEventStreamOverlaysModalOpened;
      },
      set(newVal) {

        this.toggleEventStreamOverlaysModalOpened(newVal);
      }
    },

    isStreamChannelModalOpened: {
      get() {
        return this.isEventStreamChannelModalOpened;
      },
      set(newVal) {
        this.toggleEventStreamChannelModalOpened(newVal);
      }
    },

    isStreamUrlModalOpened: {
      get() {
        return this.isEventStreamUrlModalOpened;
      },
      set(newVal) {
        this.toggleEventStreamUrlModalOpened(newVal);
      }
    },


    isTabNavigationDisabled() {

      // raise a flag if something from the section has changed and requires a save
      return false;
    },
  },

  watch: {

    isStreamThemeAndOverlaysSectionOpened(isOpened) {

      if (isOpened) {

        this.refreshMixerSettings();
      }
    },

    isEventStreamAssetsModalOpened(isOpened) {

      if (isOpened) {

        this.setupEventAssets(this.pristineAssets);
      }
    },

    msgBusMsgList() {

      const newestMsg = this.msgBusMsgList[this.msgBusMsgList.length - 1];

      if (newestMsg.type === 'mixer_updated' && newestMsg.data.guid === this.event.guid) {

        for (let i = 0; i < newestMsg.data.updatedTopics.length; i++) {

          const currentEl = newestMsg.data.updatedTopics[i];

          if (currentEl === 'theme' ||
            currentEl === 'background' ||
            currentEl === 'overlays') {

            this.$toasted.info('The theme settings were updated from the Studio.');

            this.refreshMixerSettings();
            break;
          }
        }
      }
    },

    isStateDirty() {

      if (this.isStateDirty) {

        this.setRedirectGuard({
          redirectMsg: 'Are you sure you want to leave this page?',
          redirectSecondaryMsg: 'The information you entered will be lost if you continue.'
        });

      } else {

        this.setRedirectGuard(false);
      }
    }
  }
}
</script>

<style lang="scss">

/*  Animation of the theme select images */
.slide-fade-enter-active,
.slide-fade-leave-active {
  @apply transition-all;
}

.slide-fade-enter,
.slide-fade-leave-to {
  @apply transform bg-opacity-0;
}
.slide-fade-enter {
  @apply translate-x-full;
}
.slide-fade-leave-to {
   @apply -translate-x-full;
}
/*  END Animation of the theme select images */

%custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded bg-black-500;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    @apply bg-black-300;
  }
}
.select-prerecord {
  &__select {
    @apply
    text-black-600
    mb-5
    mt-5;
  }

  &__info {
    @apply
    rounded
    bg-blue-grey-50
    p-6;
  }

  &__info-title {
    @apply text-black-900;
  }

  &__info-list {
    @apply mt-5;

    li {
      @apply
      text-black-600
      list-decimal
      list-inside
      mt-1;

      a {
        @apply text-black-900;
      }
    }
  }
}
.select-stream {
  &__title {
    @apply py-6;
  }

  &__option {
    @apply
    relative
    flex
    justify-between
    items-center
    p-5
    border
    border-black-200
    rounded
    cursor-pointer
    mb-5;

    &--selected {
      @apply border-blue-500;

      .select-stream__option-check {
        @apply
        border-blue-500
        bg-blue-500
        text-white;
      }
    }
  }

  &__option-title {
    @apply mb-1;
  }

  &__option-disclaimer {
    @apply text-sm text-black-500;
  }

  &__option-check {
    @apply
    absolute
    right-3
    top-1/2
    transform
    -translate-y-1/2
    w-8
    h-8
    rounded-full
    overflow-hidden
    border
    border-black-500
    flex
    justify-center
    items-center;
  }
}

.stream-appearance {
  @apply
  fixed
  top-16
  left-0
  right-0
  bottom-0
  flex
  flex-col
  bg-black-100
  z-1;

  .ds-app-loader {
    @apply z-1;
  }

  &__header {
    @apply
    flex
    justify-between
    items-center
    h-14
    p-4
    border-b
    bg-white
    border-black-200;

    &-left {
      @apply flex;
    }

    &-title {
      @apply
      h-8
      flex
      items-center
      text-sm
      text-black-800
      font-medium
      px-6
      border-l
      border-black-100;
    }
  }

  &__container {
    @apply
    flex
    w-full
    h-full;
  }

  &__images {
    @apply
    flex
    flex-col
    bg-white
    px-4
    overflow-y-auto
    py-6;
    width: 348px;

    @extend %custom-scroll;
  }

  &__preview-container {
    @apply
    flex-auto
    px-8;
  }

  &__preview {
    @apply relative mx-auto;
    max-width: 1138px;
    &:before {
      @apply block w-full;
      content: "";
      padding-top: 490px;

      @media screen and (min-width: 1280px) {
        padding-top: calc((321 / 569) * 100%);
      }
    }

    &-inner {
      @apply
      absolute
      inset-0
      p-10
      flex
      flex-col
      col-span-2
      bg-blue-grey-800
      rounded
      overflow-hidden;
    }

    &-disclamer {
      @apply
      mb-6
      mt-12
      mx-auto
      text-black-700;
      max-width: 1138px;
    }

    &-gradient {
      @apply
      absolute
      inset-0
      bg-gradient-to-b
      from-black-900
      to-transparent;
    }

    &-date,
    &-text {
      @apply
      py-4;
      text-shadow: 0 4px 16px rgba(38, 44, 74, 0.6);
    }

    &-text {
      @apply
      relative
      text-white
      flex-auto
      text-xl
      lg:text-2xl
      xl:text-4xl
      rounded;
    }

    &-date {
      @apply
      inline-block
      h-14
      px-6
      bg-black-900
      bg-opacity-25
      rounded-full
      mt-7
      text-sm
      lg:text-base
      xl:text-lg;
    }

    &-images {
      @apply
      flex
      justify-between;

      &.is-bottom {
        @apply self-end;
      }
    }

    &-image {
      @apply
      relative
      h-14
      rounded;
    }

    &-bgr {
      @apply
      absolute
      inset-0
      w-full
      h-full
      object-cover;
    }
  }

  &__theme {
    @apply
    flex
    flex-col
    mb-7;

    &-title {
      @apply mb-4;
    }

    &-img-container {
      @apply
      relative
      w-full
      h-full
      object-cover
      overflow-hidden
      bg-blue-grey-800;
    }

    &-img-bgr {
      @apply absolute inset-0;
    }

    &-img-thumb {
      @apply relative w-full;
    }
  }

  &__corners {
    @apply
    grid
    grid-cols-2
    gap-3
    mb-7;

    &-title {
      @apply
      col-span-2
      flex
      items-center
      justify-between;
    }
  }

  &__image {
    @apply cursor-pointer;
  }

  &__image-el {
    @apply
    h-full
    w-full
    flex
    justify-center
    items-center
    bg-black-200
    rounded
    overflow-hidden
    object-cover
    border-2
    cursor-pointer
    border-dark-blue-100;

    img {
      @apply object-cover;
    }
  }

  &__image-icon {
    @apply
    text-white
    text-xl
    w-4
    h-4
    p-2
    bg-black-400
    rounded-full
    overflow-hidden;
  }

  &__image-text {
    @apply
    mt-1
    text-sm;
  }

  &__images-thumbs {
    @apply
    grid
    grid-cols-3
    gap-2
    max-h-32
    mt-4;
  }

  &__images-thumb {
    @apply
    h-12
    overflow-hidden
    rounded
    object-cover;

    &.is-selected img {
      @apply border-2 border-blue-500;
    }
  }
}

.select-theme {

  &__selected-preview-img-container {
    @apply relative overflow-hidden;
  }

  &__selected-preview-img {
    @apply inset-0 absolute;
  }

  &__selected-preview-img-container {
    height: 410px;
  }

  &__thumb {
    @apply w-full;
  }

  &__preview-thumbs {
    height: 86px;
  }

  &__option-container {
    @apply
    py-4
    pr-6
    border-r
    border-opacity-10
    border-black-900;
  }

  &__selected-theme-img {
    @apply w-full max-w-2xl;
  }

  &__preview-thumb,
  &__option {
    @apply
    opacity-60
    cursor-pointer
    transition-opacity;

    &.is--selected {
      @apply opacity-100;
    }
  }

  &__preview {
    @apply
    w-full
    pl-6;

    &-header {
      @apply
      flex
      items-center
      justify-between
      mb-6;
    }

    &-title {
      @apply text-2xl;
    }

    &-img {
      @apply w-full;
    }

    &-thumbs {
      @apply
      flex
      justify-between
      overflow-auto;

      @extend %custom-scroll;
    }

    &-thumb {
      @apply
      pl-2
      mb-2;

      height: 64px;

      &:first-child {
        @apply pl-0;
      }
    }
  }



  &__option {
    @apply w-44 mb-4;

    &:last-child {
      @apply mb-0;
    }
  }
}

// Overide classes. Should be removed when the markup is changes
.stream-assets {
  &__tabs {
    @apply flex mt-4;
  }
  &__nodata {
    @apply
    flex
    flex-col
    items-center
    justify-center
    text-black-500;

}
  &__items {
    @apply flex flex-col;
  }
  &__item {
    @apply
    flex
    items-center
    justify-between
    py-4
    mb-4
    border-b
    border-dark-blue-100;
  }
  &__item-image {
    @apply
    h-16
    object-cover
    mr-4
    rounded
    overflow-hidden;
    /* min-w-28 */ min-width: 200px;
  }
  &__tab {
    &.tab.btn.size-l {
      @apply
      text-base
      shadow-none
      border-r-0
      text-black-500
      p-0
      min-w-max
      mr-5;

      &.is-active {
        @apply text-blue-500 ;
        box-shadow: 0 1px 0 0 var(--highlightColor),
                    0 -1px 0 0 var(--highlightColor) inset;
      }

    }
  }
}

.configure-stream-modal-component {

  background: var(--panelBgColor);

  .btn.tab {

    border-right-width: 0 !important;
    padding: 0 4px !important;
    margin-right: 12px !important;
    background: none !important;

    color: var(--color-gray-500) !important;
  }

  .btn.tab.active {

    // box-shadow: none;r(--selectedBgColor)
    color: var(--highlightColor) !important;
  }

  .btn.tab.noselect {

    color: var(--color-gray-500) !important;
    cursor: default;
  }

  .btn.tab:not(.active) {

    box-shadow: none;
  }

  .preview-img {
    width: 70px;
    height: 70px;
  }

  .src-preview-img {
    width: 70px;
    // height: 70px;
  }


  .attend-option {
    height: 80px;
    border: 1px solid;
    border-color: var(--color-gray-300);

    &.selected {
      border-width: 2px;
      border-color: var(--color-blue-600);


      .check-icon {
        border: none;
        background-color: var(--color-blue-600);

        .svg-icon {
          color: white;
        }
      }
    }

    &:not(.selected) {

      color: var(--color-gray-400);
    }

    .check-icon {
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      padding-bottom: 0;
      border: 1px solid var(--color-gray-300);
    }

    .svg-icon {
      position: absolute;
      top: 1px;
      left: 4px;
    }
  }

  .disclaimer {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 14px;
    padding-left: 5px;
    border: 1px solid var(--color-gray-400);
    color:  var(--color-gray-400);
  }





  // .theme-preview {
  //   position: relative;
  // }

  // .overlay-preview {

  //   position: absolute;
  //   // width: 20px; auto
  //   height: 50px;

  //   &.top-left {

  //     top: 20px;
  //     left: 20px;
  //   }

  //   &.top-right {

  //     top: 20px;
  //     right: 20px;
  //   }

  //   &.bottom-left {

  //     bottom: 20px;
  //     left: 20px;
  //   }

  //   &.bottom-right {

  //     bottom: 20px;
  //     right: 20px;
  //   }
  // }

  // .overlay-select-box {

  //   img {

  //     height: 70px;
  //   }
  // }

  .background-select-box {
    height: 40px;

    &.selected {

      box-shadow: 0px 0px 2px 2px var(--highlightColor),
        1px 1px 1px 1px var(--highlightColor) inset
    }
  }
}

.configure-stream-modal-component {

  /**
  * @Overwrite styleberry styles. Add this msg anywhere overwrite of styles is needed!
  * Tailwind classes are present in the HTML, so deleting the following lines,
  * when styleberry is deprecated should still work.
  **/

  .border {
    border: 1px solid rgba(238, 239, 241, var(--tw-border-opacity)) !important;
  }

}
</style>
