/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0a12 12 0 1012 12A12.035 12.035 0 0012 0zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5z" _fill="#111"/>'
  }
})
