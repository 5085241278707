<template>
<div class="brightcove-integration-settings-component">
  <ioio-modal
    class=""
    :show.sync="isConfirmDisableModalOpened"
    size="medium"
    noScroll
    headerClasses=""
  >

    <template slot="header" >
      <div class="pb-3">
        Are you sure you want to disable this integration?
      </div>
    </template>

    <h3 class="">
      All integrations with Brightcove will be disabled.
    </h3>


    <template slot="footer">
      <div class="flex justify-end">

        <ioio-button
          type="secondary"
          variant="outline"
          class="mr-4"
          @click.stop.prevent="cancelDisable"
        >
          Cancel
        </ioio-button>
        <ioio-button
          type="danger"
          variant="tonal"
          @click.stop.prevent="confirmDisable"
        >
          Disable
        </ioio-button>
      </div>
    </template>

  </ioio-modal>

  <div v-if="isRequestPending" class="flex items-center flex-auto overflow-hidden">
    <logo-loader-component width="100px" height="100px" />
  </div>

  <section v-if="!isRequestPending" class="flex flex-column flex-auto">

    <vue-form :state="brightcoveFormState">

      <validate :custom="{regexValidator: regexClient}" tag="" class="mb-6">
        <ioio-field
          name="client"
          type="text"
          required
          topic="integrationForm"
          size="medium"
          label="Client ID"
          placeholder="Client ID"
          maxlen="256"
          v-model="brightcoveSettings.clientId"
          class="w-full">
        </ioio-field>
        <field-messages name="client" show="$submitted || $dirty || $touched">
          <div class="size-3" slot="maxlen">The field Client ID length should not be more than 256 characters</div>
          <div class="size-3" slot="regexValidator">The field Client ID should contain only letters, digits and special character dash</div>
        </field-messages>
      </validate>

      <validate :custom="{regexValidator: regexAccount}" tag="" class="mb-6">
        <ioio-field
          name="account"
          type="text"
          required
          topic="integrationForm"
          size="medium"
          label="Account ID"
          placeholder="Account ID"
          v-model="brightcoveSettings.accountId"
          class="w-full">
        </ioio-field>

        <field-messages name="account" show="$submitted || $dirty || $touched">
          <div class="size-3" slot="regexValidator">The field Account ID should contain only digits</div>
        </field-messages>
      </validate>

      <validate :custom="{regexValidator: regexSecret}" tag="" class=" mb-6">
        <ioio-field
          name="secret"
          type="password"
          required
          topic="integrationForm"
          size="medium"
          label="Client Secret"
          placeholder="Client Secret"
          maxlen="256"
          v-model="brightcoveSettings.clientSecret"
          class="w-full pb-4 border-bottom border-black-200">
        </ioio-field>
        <field-messages name="secret" show="$submitted || $dirty || $touched">
          <div class="size-3" slot="maxlen">The field Client Secret length should not be more than 256 characters</div>
          <div class="size-3" slot="regexValidator">The field Client Secret should contain only letters, digits and special characters dash and underscore</div>
        </field-messages>
      </validate>

      <div class="modal-key-error" v-if="wrongSettings">
        Wrong Client ID, Account ID or Client Secret. Please, check again.
      </div>

      <section class="tags-section border-bottom border-black-200 pb-4 mb-6">


        <validate :custom="{customValidator: tagsLength, regexValidator: regexTags}" tag="" class=" mb-6">

          <ioio-tags
            name="tags"
            size="small"
            label="Tags"
            class="w-fll"
            v-model="customMeta.tags"
            :tags="customMeta.tags"
          />

          <field-messages name="tags" show="$submitted || $dirty || $touched">
            <div class="size-3" slot="customValidator">Tags length should not be more than 64 characters</div>
            <div class="size-3" slot="regexValidator">Tags should contain only letters and digits </div>
          </field-messages>

        </validate>

        <validate :custom="{customValidator: categoriesLength, regexValidator: regexCat}" tag="">

          <ioio-tags
            name="categories"
            size="small"
            label="Categories"
            class="w-fll"
            v-model="customMeta.categories"
            :tags="customMeta.categories"
          />

          <field-messages name="categories" show="$submitted || $dirty || $touched">
            <div class="size-3" slot="customValidator">Categories length should not be more than 64 characters</div>
            <div class="size-3" slot="regexValidator">Categories should contain only letters and digits </div>
          </field-messages>

        </validate>
      </section>
    </vue-form>

    <section class="hubs-select-section border-bottom border-black-200 pb-4 mb-6">

      <h1 class="text-md mb-4">Select hubs to integrate with Brightcove</h1>
      <div v-if="combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded">

        <ioio-checkbox
          v-for="hub in combinedSiteBuilderHubs"
          :key="hub.id"
          size="small"
          disclaimer=""
          v-model="addedHubsGuidsMap[hub.id]"
          @input="(newVal) => toggleAddHubToList(hub, newVal)"
        >
          <span class="text-sm">{{ hub.name }}</span>
        </ioio-checkbox>
      </div>
      <h3 v-else-if="!combinedSiteBuilderHubs.length && areSiteBuilderAppsLoaded" class="text-sm text-black-400">No hubs configured</h3>

    </section>


    <ioio-checkbox
      size="small"
      disclaimer="The integration will be disabled"
      v-model="isIntegrationDisabled"
      @input="toggleDisableIntegrationnewVal"
    >
      <span class="text-sm">Disable integration</span>
    </ioio-checkbox>

    <footer class="flex justify-end my-8">

      <ioio-button
        type="primary"
        size="medium"
        @click.stop.prevent="saveBrightcoveSettings()"
        topic="integrationForm"
      >Save
      </ioio-button>
    </footer>

  </section>

</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({

    isConfirmDisableModalOpened: false,
    isRequestPending: false,
    wrongSettings: false,
    brightcoveFormState: {},

    brightcoveSettings: {},

    customMeta: {

      hubs: [],
      tags: [],
      categories: []
    },

    addedHubsGuidsMap: {},
    addedHubsMap: {},

    isIntegrationDisabled: false

  }),
  props: {},
  created() {

    this.setBrightcoveSettings();
  },
  mounted() {

    window.b = this;
  },
  methods: {
    setBrightcoveSettings() {

      this.isRequestPending = true;

      this.getIngestDistributors()
        .then((distributors) => {
          const brightcove = distributors.find(d => d.guid === 'brightcove');

          if (!brightcove) {

            this.$toasted.error('No setup for Brightcove integration.')
            return;
          }

          this.isIntegrationDisabled = !brightcove.enabled;

          this.brightcoveSettings = {

            ...brightcove.brightcoveSettings
          };

          if (this.brightcoveSettings.customMeta) {

            const tags = this.brightcoveSettings.customMeta.tags || [];
            const categories = this.brightcoveSettings.customMeta.categories || [];

            this.customMeta = {

              ...this.brightcoveSettings.customMeta,
              tags: [...tags],
              categories: [...categories]
            };

            this.brightcoveSettings.customMeta.hubs.forEach((hub) => this.toggleAddHubToList(hub, true));
          }

        })

        .finally(() => {

          this.isRequestPending = false;
        });
    },

    checkDistributorStatus() {

      let payloadCheck = {

        distributorGuid: 'brightcove'
      };

      this.checkIngestDistributorStatus(payloadCheck)
        .then(() => {

          this.$toasted.success('Integrated with Brightcove successfully');

          this.wrongSettings = false;
        })
        .catch(errPayload => {

          if (errPayload.code === 422){

            this.wrongSettings = true;

          };
        })
        .finally(() => {

          this.getIngestDistributors();

          this.isRequestPending = false;
        });
    },

    cancelDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(false);
    },

    confirmDisable() {

      this.isConfirmDisableModalOpened = false;

      this.toggleDisableIntegrationnewVal(true, 'force');
    },

    toggleDisableIntegrationnewVal(newVal, force) {

      if (newVal && !force) {

        this.isConfirmDisableModalOpened = true;
        return;
      }

      this.isIntegrationDisabled = newVal;
    },

    toggleAddHubToList(hub, newVal) {

      if (newVal) {

        this.addedHubsGuidsMap[hub.id] = true;

        this.addedHubsMap[hub.id] = {

          id: hub.id,
          name: hub.name,
          permissions: []
        };

      } else {

        this.addedHubsGuidsMap[hub.id] = false;
        delete this.addedHubsMap[hub.id];
      }
    },

    saveBrightcoveSettings() {

      this.brightcoveFormState._submit();
      this.brightcoveFormState._validate();

      if (this.brightcoveFormState.$invalid) {

        return;
      };

      const hubs = [];

      for (const hubId in this.addedHubsMap) {

        if (this.addedHubsMap[hubId]) {

          hubs.push(this.addedHubsMap[hubId]);
        }
      };

      const payload = {

        distributorGuid: 'brightcove',

        data: {

          enabled: !this.isIntegrationDisabled,
          brightcoveSettings: {

            ...this.brightcoveSettings,

            customMeta: {
              ...this.customMeta,
              hubs
            }
          }
        }
      };

      this.isRequestPending = true;

      this.updateIngestDistributors(payload)
        .then(() => {

          this.checkDistributorStatus();
        })
    },

    ...mapMutations({
      setZoomIntegrationRequest: "ingest/SET_ZOOM_INTEGRATION"
    }),
    ...mapActions({
      getIngestDistributors: "ingest/getIngestDistributors",
      updateIngestDistributors: "ingest/updateIngestDistributors",
      checkIngestDistributorStatus: "ingest/checkIngestDistributorStatus"
    })
  },
  computed: {
    ...mapGetters({
      siteBuilderApps: 'app/siteBuilderApps',
      areSiteBuilderAppsLoaded: "app/areSiteBuilderAppsLoaded",
      distributors: 'ingest/ingestDistributors'
    }),

    combinedSiteBuilderHubs() {

      const hubs = [];

      this.siteBuilderApps.forEach(a => {

        if (a.hubs) {

          a.hubs.forEach(hub =>{

             hubs.push(hub);
          });
        }
      });

      return hubs;
    },
    tagsLength() {

      let tags = this.customMeta.tags.reduce((a, b) => a + b,'');

      return tags.length < 64;
    },
    categoriesLength() {

      let categories = this.customMeta.categories.reduce((a, b) => a + b,'');

      return categories.length < 64;
    },
    regexTags() {

      let tags = this.customMeta.tags.reduce((a, b) => a + b,'');

      let regex = /^[a-zA-Z0-9]*$/g;

      return regex.test(tags);
    },
    regexCat () {

      let categories = this.customMeta.categories.reduce((a, b) => a + b,'');

      let regex = /^[a-zA-Z0-9]*$/g;

      return regex.test(categories);
    },
    regexAccount() {

      let regex = /^\d+$/g;

      return regex.test(this.brightcoveSettings.accountId);

    },
    regexClient() {

      let regex = /^[a-zA-Z0-9-]+$/g;

      return regex.test(this.brightcoveSettings.clientId);

    },
    regexSecret() {

      let regex = /^[a-zA-Z0-9-_]+$/g;

      return regex.test(this.brightcoveSettings.clientSecret);

    },
  },
  watch: {
    tagsLength() {
      if(!this.tagsLength){

        this.brightcoveFormState._submit();
      }
    },
    categoriesLength() {
      if(!this.categoriesLength){

        this.brightcoveFormState._submit();
      }
    },
    regexTags() {
      if(!this.regexTags){

        this.brightcoveFormState._submit();
      }
    },
    regexCat() {
      if(!this.regexCat){

        this.brightcoveFormState._submit();
      }
    },
    regexAccount() {
      if(!this.regexAccount){

        this.brightcoveFormState._submit();
      }
    },
    regexClient() {
      if(!this.regexClient){

        this.brightcoveFormState._submit();
      }
    },
    regexSecret() {
      if(!this.regexSecret){

        this.brightcoveFormState._submit();
      }
    }
  },

}
</script>

<style lang="scss" scoped>
  .brightcove-integration-settings-component{
    @apply
    flex
    flex-col
    flex-auto
    overflow-auto
    mt-4;

    .modal-key-error {
      @apply
      text-sm
      font-medium
      text-red-600
      mb-6;
    }
  }

</style>
