/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '+1s': {
    width: 15,
    height: 8,
    viewBox: '0 0 15 8',
    data: '<path pid="0" d="M.81 3.913h1.701V2.122h.756v1.791h1.746v.648H3.267v1.8h-.756v-1.8H.81v-.648zM6.594 6.28h1.395V1.519l-1.54.477-.206-.531L8.196.592 8.835.7v5.58h1.25V7H6.595v-.72zm4.585-.234c.27.114.5.198.693.252.198.054.402.081.612.081.27 0 .48-.057.63-.171.156-.114.234-.282.234-.504a.45.45 0 00-.126-.333.716.716 0 00-.27-.198 7.746 7.746 0 00-.55-.198 17.027 17.027 0 01-.476-.162 1.294 1.294 0 01-.64-.45c-.143-.204-.215-.456-.215-.756 0-.372.144-.684.432-.936.288-.252.71-.378 1.269-.378.234 0 .453.027.657.081.21.054.429.126.657.216l-.225.666a5.58 5.58 0 00-.558-.189 1.924 1.924 0 00-.531-.072c-.282 0-.495.057-.64.171a.513.513 0 00-.215.432c0 .168.057.294.17.378.115.078.298.162.55.252.066.018.126.039.18.063l.216.081c.252.084.453.168.603.252.156.084.288.213.396.387.108.168.162.393.162.675 0 .444-.16.789-.477 1.035-.312.24-.72.36-1.224.36-.294 0-.567-.03-.82-.09a5.377 5.377 0 01-.755-.261l.26-.684z" _fill="#000"/>'
  }
})
