import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

/**
 *
 * filterAndPaginate - add new entry steps needed
 *
 *
 * IN FILE --- filterAndPaginate/index.js
 *
 * 1. Add SearchOptions object e.g.
 *
 * vodSearchOptions: {

    vodStatus: 'complete',
    vodType: '',
    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },
 *
 *
 * 2. Add the same name array entry key to Objects:
 *
 * originalItems
 * clonedItems
 * filteredItems
 * filteredItemsNotPaginated
 *
 *  e.g.
 *  vodLibrary: [],
 *
 *
 * IN FILE --- filterAndPaginate/getters.js
 *
 * Add entries for getters grp 1 to 4 e.g.
 *
 * GRP 1
 * const filteredVodList = (state) => state.filteredItems.vodLibrary;
 *
 * GRP 2
 * const filteredVodListItemsCount = (state) =>
      state.filteredItemsNotPaginated.vodLibrary.length;

 * GRP 3
 * const filteredVodListNotPaginated = (state) => state.filteredItemsNotPaginated.vodLibrary;
 *
 * GRP 4
 * const vodSearchOptions = (state) => state.vodSearchOptions;
 *
 *
 * IN FILE --- filterAndPaginate/mutations.js
 * in Mutation NULL_FILTERS_APPLIED add new entry to null the
 * newly added entry values (based on the ones applicable, provided in index.js) e.g.
 *
 * vodLibrary: {
      name: 'vodSearchOptions',
      initialState: {

        vodStatus: 'complete',
        vodType: '',
        pageNum: 1,
        perPageItems: 25,
        urlSearchQuery: '',
      },
    },
 *
 *
 *
 *
 * IN FILE --- filterAndPaginate/actions.js
 *
 * 1. Add filtering function with the applicable filter logic to the new entry
 * e.g. const _vodLibraryFiltering = (items, searchOpts) => { ...
 *
 * 2. Add entry to the _filterTypesMap object, that will
 * indicate what the filtering fn and options will be for the new entry
 * e.g.
 * vodLibrary: {

    func: _vodLibraryFiltering,
    opts: 'vodSearchOptions'
  },
 *
 */

const state = {

  vodSearchOptions: {

    vodStatus: '',
    vodType: '',
    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  playlistsSearchOptions: {

    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  imagesSearchOptions: {

    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  eventsSearchOptions: {

    upcommingEvents: true,
    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  eventAttendeesSearchOptions: {

    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  eventSpeakersSearchOptions: {

    pageNum: 1,
    perPageItems: 25,
    urlSearchQuery: '',
  },

  eventSessionsSearchOptions: {

    pageNum: 1,
    perPageItems: 10,
    urlSearchQuery: '',
  },
  /**
   * NOTE: these items list will have to be spliced into divisions
   * [VOD, Playlists] if filtering of more than one type, on the same
   * page, at the same time is required in the future.
   *
   */
  originalItems: {
    vodLibrary: [],
    playlistLibrary: [],
    imagesLibrary: [],
    eventsLibrary: [],
    eventAttendeesLibrary: [],
    eventSpeakersLibrary: [],
    eventSessionsLibrary: [],
  },
  clonedItems: {
    vodLibrary: [],
    playlistLibrary: [],
    imagesLibrary: [],
    eventsLibrary: [],
    eventAttendeesLibrary: [],
    eventSpeakersLibrary: [],
    eventSessionsLibrary: [],
  },
  filteredItems: {
    vodLibrary: [],
    playlistLibrary: [],
    imagesLibrary: [],
    eventsLibrary: [],
    eventAttendeesLibrary: [],
    eventSpeakersLibrary: [],
    eventSessionsLibrary: [],
  },
  filteredItemsNotPaginated: {
    vodLibrary: [],
    playlistLibrary: [],
    imagesLibrary: [],
    eventsLibrary: [],
    eventAttendeesLibrary: [],
    eventSpeakersLibrary: [],
    eventSessionsLibrary: [],
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}