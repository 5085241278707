
export const videoConsumptionOptions = {
  chart: {
    id: 'videoConsumptionChart',
    height: 250,
    type: 'area',

    toolbar: {
      show: false,
    }
  },
  // These are the labels
  xaxis: {
    categories: [],
    position: 'bottom',
    axisBorder: {
      show: false
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,
    },

    labels: {
      rotate: 0
    }
  },

  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },

  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },



  stroke: {
    width: 2
  },

  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    }
  },

  colors: ['#0077FF', '#FAC000'],

  // Options for labels on each bar

}

export const eventRegistrationsOptions = {
  chart: {
    id: 'eventRegistrationsChart',
    height: 250,
    type: 'area',

    toolbar: {
      show: false,
    }
  },

  stroke: {
    width: 2
  },
  // These are the labels
  xaxis: {
    categories: [],
    // type: 'datetime',
    // tickAmount: 4,
    position: 'bottom',

    axisBorder: {
      show: false
    },


    labels: {
      // TO USE if the exact number of labels is to be displayed !!!

      // formatter: function(val, timestamp) {
      //   return moment(timestamp).format('DD MMM');
      // },
      rotate: 0
    },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,


    },

  },

  // TO USE if the exact number of labels is to be displayed !!!

  // tooltip: {
  //   enabled: true,
  //   x: {
  //     show: true,

  //     shared: true,

  //     formatter: function(value) {

  //       return moment(value).format('DD MMM');
  //     }
  //   }
  // },
  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {
      // show: false,
      // formatter: (val) => {
      //   return val + "%";
      // }
    }
  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  colors: ['#0077FF', '#FAC000', '#14B67F'],

  fill: {
    type: 'gradient',
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
      // type: "vertical",
      // stops: [0, 50, 70]
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
}

export const channelsViewsOptions = {
  chart: {
    id: 'channelsViewsChart',
    height: 250,
    type: 'area',

    toolbar: {
      show: false,
    }
  },

  stroke: {
    width: 2
  },
  // These are the labels
  xaxis: {
    // TO USE if the exact number of labels is to be displayed !!!
    // type: 'datetime',
    // tickAmount: 4,
    position: 'bottom',

    axisBorder: {
      show: false
    },

    // TO USE if the exact number of labels is to be displayed !!!
    // labels: {

    //   formatter: function(val, timestamp) {
    //     return moment(timestamp).format('DD MMM');
    //   },

    // },


    // NOTE: designs show this line in the center of each bar - pointing to the label - offsetX can only be pixels, so that can not be accurate - investigate if needed, but hide for now
    axisTicks: {
      show: false,
      offsetX: '50%', // can not be %
    },

    // On hover show background for the bars
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },

    tooltip: { // tooltip on hover - kind of redundant - hide for now
      enabled: false,


    },

  },
  // TO USE if the exact number of labels is to be displayed !!!
  // tooltip: {
  //   enabled: true,
  //   x: {
  //     show: true,

  //     shared: true,

  //     formatter: function(value) {

  //       return moment(value).format('DD MMM');
  //     }
  //   }
  // },
  // labels on the left
  yaxis: {
    tickAmount: 0,
    min: 0,
    max: 0,

    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {

    }
  },

  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },

  colors: ['#0077FF', '#FAC000', '#F39290', '#8E7AFF', '#14B67F'],

  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.5,
      opacityTo: 0,
      // type: "vertical",
      // stops: [0, 50, 70]
    }
  },

  // Options for labels on each bar
  dataLabels: {
    enabled: false,
    offsetY: -40,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  }
}

export const assetsOptions = {

  chart: {
    id: 'assetsOptionsChart',
    width: 380,
    type: 'pie',
  },

  legend: {
    offsetY: 30
  },

  labels: ['Uploaded', 'Encoding', 'Failed', 'Encode Later'],

  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200
      },
      legend: {
        position: 'bottom',
      }
    }
  }],

  colors: ['#14B67F', '#8E7AFF', '#EC4C47', '#0077FF','#FAC000','#F39290']
}

