<template>
<div class="signed-urls-component">
  <div class="box bg-white rounded-lg mb-4">
    <div v-if="!isLoadingData && availableServise">
      <div class="flex items-center">
        <span class="text-sm truncate p-3">
          Signed URLs is {{oldStatus ? 'enabled' : 'disabled'}}
        </span>
        <div class="p-3 pb-5">
          <ioio-checkbox
            size="small"
            v-model="defaultData.value"
            @input="(newVal) => defaultData.value = newVal"
          >
          </ioio-checkbox>
        </div>
        <div class="p-3">
          <ioio-button
            type="primary"
            size="small"
            :disabled="impossibleUpdateSignedUrls"
            @click="updateDefaultData('signed-urls')">Update
          </ioio-button>
        </div>
      </div>
      <div class="flex items-center justify-between">

        <ioio-field
          label="Public Key"
          topic="publicKey"
          class="p-3 w-9/12"
          type="text"
          v-model="publicKey"
          placeholder="Enter Public Key"
        />
        <div class="p-3 pt-12">
          <ioio-button
            type="primary"
            topic="publicKey"
            size="small"
            :disabled="impossibleUpdatePublicKey"
            @click="updateDefaultData('client-public-key')">Update
          </ioio-button>
        </div>
      </div>
    </div>

    <div v-if="!isLoadingData && !availableServise" class="flex items-center">
      <span class="text-xs font-bold truncate p-3">
        This service is not available
      </span>
    </div>
    <div class="relative min-h-36" v-else-if="isLoadingData">
      <ioio-loader isShown />
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

export default {
  data: () => ({
    isLoadingData: true,
    defaultData: {},
    oldStatus: true,
    availableServise: false,
    oldKey:'',
    publicKey: ''
  }),
  props: {},
  created () {

    this.getDefaultData();
  },
  methods: {

    getDefaultData() {

      this.getBrokerSettings('signed-urls')

        .then((response) => {

          this.availableServise = true;

          this.defaultData = response;

          this.oldStatus = response.value;

          this.getBrokerSettings('client-public-key')

            .then((response) => {

              this.publicKey = response.value;

              this.oldKey = response.value;
            });

        })
        .catch(err => {

          if (err.code === 404) {

            this.availableServise = false;
          } else {

            this.$toasted.error(err.error);
          };
        })
        .finally(() => {

          this.isLoadingData = false;
        });
    },

    async updateDefaultData(type){

      this.isLoadingData = true;

      const updateVal = type === 'signed-urls' ? this.defaultData.value : this.publicKey;

      this.updateBrokerSettings({

          params: type,

          data: {
            value: updateVal
          }
        })
        .then(() =>{

          this.$toasted.success('Your changes were saved successfully');

          this.getDefaultData();

        })
        .catch(() => {

          this.getDefaultData();
        })
    },
    ...mapMutations({
      // E.g. someMutation: "app/SOME_MUTATION",
    }),
    ...mapActions({
      getBrokerSettings: "channelManager/getBrokerSettings",
      updateBrokerSettings: "channelManager/updateBrokerSettings"
    })
  },
  computed: {

    impossibleUpdateSignedUrls() {

      return this.oldStatus === this.defaultData.value;
    },
    impossibleUpdatePublicKey() {

      return this.oldKey === this.publicKey;
    },
    ...mapGetters({
      // E.g. someGetter: "app/someGetter"
    })
  },
}
</script>

<style lang="scss">


</style>
