const SET_EVENT_DETAILS = (state, eventData) => {

  state.eventDetails = eventData;
};

const SET_MIXER_SETTINGS = (state, settings) => {

  state.mixerSettings = settings;
};

const TOGGLE_ASSIGN_SPEAKERS_TO_EVENT_MODAL_VISIBLE = (state, newVal) => {

  state.isAssignSpeakersToEventModalVisible = newVal;
};

const TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE = (state, { division, newVal }) => {

  state[division] = newVal;
};

const TOGGLE_ASSIGN_VIDEOS_MODAL_VISIBLE = (state, newVal) => {

  state.isAssignVideosModalOpened = newVal;
};



const TOGGLE_EVENTS_STREAM_OPTION_MODAL_FLAG_VISIBLE = (state, newVal) => {

  state.isEventStreamOptionModalOpened = newVal;
};

const TOGGLE_EVENTS_STREAM_ASSETS_MODAL_FLAG_VISIBLE = (state, newVal) => {

  state.isEventStreamAssetsModalOpened = newVal;
};

const TOGGLE_EVENTS_STREAM_OVERLAYS_MODAL_FLAG_VISIBLE = (state, newVal) => {

  state.isEventStreamOverlaysModalOpened = newVal;
};

const TOGGLE_EVENTS_STREAM_CHANNEL_MODAL_FLAG_VISIBLE = (state, newVal) => {

  state.isEventStreamChannelModalOpened = newVal;
};

const TOGGLE_EVENTS_STREAM_URL_MODAL_FLAG_VISIBLE = (state, newVal) => {

  state.isEventStreamUrlModalOpened = newVal;
};

export {

  SET_EVENT_DETAILS,
  SET_MIXER_SETTINGS,
  TOGGLE_ASSIGN_SPEAKERS_TO_EVENT_MODAL_VISIBLE,
  TOGGLE_ASSIGN_IMAGES_MODAL_FLAG_VISIBLE,
  TOGGLE_ASSIGN_VIDEOS_MODAL_VISIBLE,
  TOGGLE_EVENTS_STREAM_OPTION_MODAL_FLAG_VISIBLE,
  TOGGLE_EVENTS_STREAM_ASSETS_MODAL_FLAG_VISIBLE,
  TOGGLE_EVENTS_STREAM_OVERLAYS_MODAL_FLAG_VISIBLE,
  TOGGLE_EVENTS_STREAM_CHANNEL_MODAL_FLAG_VISIBLE,
  TOGGLE_EVENTS_STREAM_URL_MODAL_FLAG_VISIBLE,
}