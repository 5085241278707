// NOTE: use this constant as origin for all others. New deployments should be added here
export const deploymentsFullMap = {
  master: {

    id: 15,
    label: 'Master'
  },
  broker: {

    id: 11,
    label: 'Broker'
  },
  image: {

    id: 45,
    label: 'Image'
  },
  syndication: {

    id: 72,
    label: 'Syndication'
  },
  live: {

    id: 9,
    label: 'Live'
  },
  'live-elemental': {

    id: 158,
    label: 'Elemental Live'
  },
  events: {

    id: 87,
    label: 'Events'
  },
  search: {

    id: 98,
    label: 'Search',
    isDeployedForMasterOrgOnly: true
  },
  networking: {

    id: 117,
    label: 'Networking'
  },
  hubs: {

    id: 125,
    label: 'Hubs'
  },
  'live-observer': {

    id: 127,
    label: 'Live Observer',
    isDeployedForMasterOrgOnly: true
  },
  ingest: {

    id: 120,
    label: 'Ingest'
  },
  'live-blueprints-api': {

    id: 130,
    label: 'Live Blueprints API',
    isDeployedForMasterOrgOnly: true
  },
}

